import api from "src/api/rest";
import { useReportsQuery } from "#reports/hooks/useReportsQuery";

export function useSalesReport() {
    const { data, isLoading, error, refetch } = useReportsQuery(
        "sales-report",
        api.reports.getSalesReport,
    );
    return {
        data: data?.data,
        isLoading,
        error,
        refetch,
    };
}
