import { useMemo, useState } from "react";
import { CashDrawer, CashDrawerWithPrinter } from "@snackpass/snackpass-types";
import moment from "moment";

import DateRangeSelector from "#date-picker/date-range-selector";
import { useCashEvents } from "src/api/rest/useCashEvents";
import CashDrawerSelector from "#financial-reports/screens/cash-report/cashdrawer-selector";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { CashReportContext } from "#financial-reports/screens/cash-report/cash-report-context";
import { isAuditEvent } from "#financial-reports/screens/cash-report/lib";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { toDollarFormatted } from "#reports/sales-summary/lib";

import { CashReportTable } from "./cash-report-table";

const CashReportLayout = () => {
    const [selectedCashDrawer, setSelectedCashDrawer] = useState<
        string | undefined
    >(undefined);
    const { data } = useCashEvents(selectedCashDrawer);
    const { data: cashDrawers } = useCashDrawers();

    const lastAuditsPerDrawer = useMemo(
        () =>
            cashDrawers
                ?.filter(
                    (drawer) =>
                        selectedCashDrawer === undefined ||
                        drawer.id === selectedCashDrawer,
                )
                .map((cashDrawer: CashDrawerWithPrinter) => {
                    const lastAudit = data.find(
                        (event) =>
                            isAuditEvent(event) &&
                            event.cashDrawer.id === cashDrawer.id,
                    );
                    return {
                        cashDrawer,
                        lastAudit,
                    };
                }),
        [cashDrawers, data, selectedCashDrawer],
    );

    // Has multiple cash drawers and not filtering for any selected cash drawer.
    const viewingMultipleCashDrawers =
        cashDrawers &&
        cashDrawers.length > 1 &&
        selectedCashDrawer === undefined;

    const verifiedLabels = lastAuditsPerDrawer?.map(
        ({ cashDrawer, lastAudit }) => {
            const verifiedLabel = lastAudit
                ? `Verified ${moment(lastAudit.createdAt).format(
                      "MM/DD/YY [at] H:mm a",
                  )}`
                : "Never Verified";

            return `${
                viewingMultipleCashDrawers ? `${cashDrawer.name}: ` : ""
            }${verifiedLabel}`;
        },
    );

    const cashDrawerContextValue = useMemo(
        () => ({
            cashDrawer: selectedCashDrawer,
            setCashDrawer: setSelectedCashDrawer,
        }),
        [selectedCashDrawer, setSelectedCashDrawer],
    );

    const totalsPerCashDrawer = useMemo(
        () =>
            data.reduce(
                (acc, { cashDrawer, totalAmount }) =>
                    acc.find((e) => e.cashDrawer.id === cashDrawer.id)
                        ? acc
                        : acc.concat([{ cashDrawer, totalAmount }]),
                [] as {
                    cashDrawer: CashDrawer;
                    totalAmount: number;
                }[],
            ),
        [data],
    );

    const totalAmount = useMemo(
        () =>
            totalsPerCashDrawer.reduce(
                (acc, { totalAmount }) => acc + totalAmount,
                0,
            ),
        [totalsPerCashDrawer],
    );

    return (
        <div className="m-4 md:m-6">
            <CashReportContext.Provider value={cashDrawerContextValue}>
                <div className="mb-4 flex flex-row items-center gap-2 overflow-auto md:mb-6">
                    <h4 className="text-large">Cash</h4>
                    <DateRangeSelector subtitle={"Currently showing "} />
                    <CashDrawerSelector />
                </div>
                <div>
                    <Alert variant="default" className="mb-6">
                        <AlertTitle className="mb-0 text-2xl">
                            {toDollarFormatted(totalAmount)}
                        </AlertTitle>
                        <AlertDescription className="text-sm">
                            {verifiedLabels?.map((label) => <p>{label}</p>)}
                        </AlertDescription>
                    </Alert>
                    <CashReportTable />
                </div>
            </CashReportContext.Provider>
        </div>
    );
};

export default CashReportLayout;
