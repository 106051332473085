import React, { ReactElement } from "react";

import { Note } from "#reusable/text/note";

const alert = {
    title: "Account Setup Incomplete",
    email: "partners@snackpass.co",
    phone: "1-866-868-2146",
};

export const SettingsAlert = ({
    display,
}: {
    display: boolean;
}): ReactElement => {
    if (!display) return <></>;
    return (
        <Note
            noteType="warning"
            noteStyle="subtle"
            note={
                <>
                    You can now set a custom pay period and adjust employee
                    shift text notifications. Please click the settings button
                    below to configure your store's pay period before viewing
                    time cards.
                    <br />
                    <br />
                    If you have any questions, feel free to contact Snackpass
                    support at{" "}
                    <a className="font-bold" href={`mailto:${alert.email}`}>
                        {alert.email}
                    </a>{" "}
                    or <span className="phone-number">{alert.phone}</span>.
                </>
            }
        />
    );
};
