import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

import {
    Breadcrumb,
    BreadcrumbItems,
} from "#menu-editor/multi-menus/styled-components/breadcrumb";
import { Routes } from "#navigation/routes";
import colors from "#reusable/colors/colors.json";

const divider = `1px solid ${colors["neutral-400"]}`;

const ContentWrapper = styled.div<{
    centerContent: boolean | undefined;
}>`
    @media ${ScreenState.MOBILE} {
        padding: 0px 16px;
    }
    padding: 0px 96px 0px 88px;
    display: flex;
    overflow-y: hidden;
    flex: 1;
    flex-direction: column;
    justify-content: ${({ centerContent }) =>
        centerContent ? "center" : undefined};
`;

const ScreenHeaderWrapper = styled.div<{
    pathname: string;
}>`
    display: flex;
    @media ${ScreenState.MOBILE} {
        height: auto;
    }
    height: ${({ pathname }) =>
        pathname === Routes.MultiMenus ? "120px" : "100px"};

    flex-direction: column;
    border-bottom: ${divider};
    .ant-breadcrumb {
        margin-left: -10px;
    }
    @media ${ScreenState.MOBILE} {
        padding: 24px 16px 20px;
    }
    padding: 12px 96px 12px 88px;
`;

const ScreenFooterWrapper = styled.div`
    padding: 12px 96px 75px 88px;
    border-top: ${divider};
    @media ${ScreenState.MOBILE} {
        height: auto;
        padding: 16px;
    }
    height: 50px;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
`;

const OuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media ${ScreenState.MOBILE} {
        height: 100%;
    }
    height: calc(100% - 22px);
`;

interface ScreenLayoutProps {
    breadcrumbItems?: BreadcrumbItems;
    header: React.ReactElement;
    content: React.ReactElement;
    footer?: React.ReactElement;
    isLoading?: boolean;
    centerContent?: boolean;
}

export const ScreenLayout = ({
    breadcrumbItems,
    header,
    content,
    footer,
    isLoading,
    centerContent,
}: ScreenLayoutProps) => {
    const location = useLocation();
    return isLoading ? (
        <SpinnerWrapper>
            <Spin size="large" />
        </SpinnerWrapper>
    ) : (
        <OuterWrapper>
            <ScreenHeaderWrapper pathname={location.pathname}>
                {breadcrumbItems ? (
                    <Breadcrumb items={breadcrumbItems} />
                ) : null}

                {header}
            </ScreenHeaderWrapper>
            <ContentWrapper centerContent={centerContent}>
                {content}
            </ContentWrapper>
            {footer ? (
                <ScreenFooterWrapper>{footer}</ScreenFooterWrapper>
            ) : null}
        </OuterWrapper>
    );
};
