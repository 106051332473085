import { last } from "lodash";
import { useContext } from "react";
import { Spinner } from "react-activity";
import { Col, Row } from "react-bootstrap";

import DetailsSection from "#devices/components/DetailsDrawer/DetailsSection";
import DescriptionList from "#devices/components/DetailsDrawer/DescriptionList";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { getFormattedDate } from "#devices/components/DevicesTable/lib";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { ReactComponent as Tablet } from "src/assets/icons/tablet-small.svg";
import { ReactComponent as Barcode } from "src/assets/icons/barcode.svg";
import { ReactComponent as Calendar } from "src/assets/icons/calendar-small.svg";
import { ReactComponent as AppVersion } from "src/assets/icons/software-version.svg";
import { ReactComponent as Charging } from "src/assets/icons/charging.svg";
import { ReactComponent as Wifi } from "src/assets/icons/wifi.svg";
import { ReactComponent as Internet } from "src/assets/icons/internet.svg";
import { getDeviceStatus } from "#devices/utils/getDeviceStatus";
import { KDSDevice } from "#devices/utils/deviceTypes";
import DeviceLog from "#devices/components/DetailsDrawer/DeviceLog";
import { getDeviceConnection } from "#devices/utils/getDeviceConnection";
import { cleanNetwork } from "#devices/utils/cleanNetworkName";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { NetworkConnectivityHistoryChart } from "#devices/components/DetailsDrawer/network-connectivity-history-chart";

const KDSDetails = () => {
    const {
        device,
        setShowRebootDeviceModal,
        networkReport,
        loadingNetworkReport,
    } = useContext(DevicesPageContext);

    const kdsDevice = device as KDSDevice;

    const deviceStatus = getDeviceStatus(
        kdsDevice,
        last(networkReport)?.status,
    );
    const deviceConnection = getDeviceConnection(kdsDevice.stats);

    return (
        <>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Details">
                        <DescriptionList
                            items={[
                                {
                                    term: "Device",
                                    icon: <Tablet />,
                                    description:
                                        getDeviceTypeName(
                                            kdsDevice.deviceType,
                                        ) || "",
                                },
                                {
                                    term: "Status",
                                    icon: loadingNetworkReport ? (
                                        <Spinner size={8} />
                                    ) : (
                                        <StatusCircleIcon
                                            width={8}
                                            height={8}
                                            fill={deviceStatus.color}
                                        />
                                    ),
                                    description: loadingNetworkReport
                                        ? undefined
                                        : deviceStatus.status,
                                },
                                {
                                    term: "Snack ID",
                                    icon: <Barcode />,
                                    description: kdsDevice.snackId,
                                    copyable: true,
                                },
                                {
                                    term: "Installed",
                                    icon: <Calendar />,
                                    description: getFormattedDate(
                                        kdsDevice.createdAt,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Software Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "App Version",
                                    icon: <AppVersion />,
                                    description:
                                        kdsDevice.stats.appVersion ?? "--",
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Hardware Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Model",
                                    icon: <Tablet />,
                                    description: `${kdsDevice.hardwareMake} ${kdsDevice.hardwareModel}`,
                                    copyable: true,
                                },
                                {
                                    term: "Serial Number",
                                    icon: <Barcode />,
                                    description: kdsDevice.serial,
                                    copyable: true,
                                },
                                {
                                    term: "Battery %",
                                    icon: <Charging />,
                                    description: `${
                                        kdsDevice.stats.batteryPercent ??
                                        "Unknown"
                                    }`,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Quick Actions" bottom>
                        <Row>
                            <Col sm={6}>
                                <p className="mb-4">
                                    <Button
                                        variant="outline"
                                        className="w-full text-base"
                                        onClick={() =>
                                            setShowRebootDeviceModal(true)
                                        }
                                    >
                                        Reboot Device
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DetailsSection heading="Network">
                        <DescriptionList
                            items={[
                                {
                                    term: "Connection",
                                    icon: <Internet />,
                                    description: deviceConnection.type,
                                },
                                {
                                    term: "Network Name",
                                    icon: <Wifi />,
                                    description: cleanNetwork(
                                        deviceConnection.name,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                        {networkReport ? (
                            <NetworkConnectivityHistoryChart
                                report={networkReport}
                            />
                        ) : null}
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DetailsSection heading="Device Log">
                        <DeviceLog logItems={kdsDevice.events} />
                    </DetailsSection>
                </Col>
            </Row>
        </>
    );
};

export default KDSDetails;
