import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { Footer } from "#onboarding/components/shared/Footer";
import { Page } from "#onboarding/components/shared/Page";
import { RowDivider } from "#onboarding/components/shared/RowDivider";
import { FormInput } from "#onboarding/components/shared/controls/FormInput";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import {
    companyNameValidation,
    companyTaxIdValidation,
    phoneValidation,
} from "#onboarding/utils/validation/form";
import { getActiveStore } from "src/redux/selectors";
import { OnboardingStep } from "#onboarding/utils/types";
import { BusinessSchema } from "#onboarding/utils/validation/schema";

export const Business = () => {
    const [form] = useForm();

    const store = useSelector(getActiveStore);

    // Note: Store should only be used to confirm _id exists. This "Business" step
    //       pertains to the store verification record.
    const { verification, loading, patchVerification, handleStepChange } =
        useContext(OnboardingContext);

    const [hasChange, setHasChange] = useState(false);

    const [name, setName] = useState(verification?.name);
    const [taxId, setTaxId] = useState(
        verification?.taxId ? "Provided" : undefined,
    );
    const [phone, setPhone] = useState(verification?.phone?.replace("+1", ""));

    const namePending = /Pending/i.test(name ?? "");
    const nameProvided = /Provided/i.test(name ?? "");
    const taxIdProvided = /Provided/i.test(taxId ?? "");
    const phonePending = /Pending/i.test(phone ?? "");

    const handleName = (value: string) => {
        setHasChange(true);
        setName(value);
        form.setFields([
            {
                name: "name",
                value,
                errors: companyNameValidation(value),
            },
        ]);
    };

    const handleTaxId = (value: string) => {
        setHasChange(true);
        setTaxId(value);
        form.setFields([
            {
                name: "taxId",
                value,
                errors: companyTaxIdValidation(value),
            },
        ]);
    };

    const handlePhone = (value: string) => {
        setHasChange(true);
        setPhone(value);
        const errors = phoneValidation(value);
        form.setFields([
            {
                name: "phone",
                value,
                errors,
            },
        ]);
    };

    const saveDisabled = !store?._id || loading || !hasChange;

    const disabled = !store?._id || loading;
    const isValid =
        !loading &&
        BusinessSchema.safeParse({
            name,
            taxId,
            phone,
        }).success;

    const handleSave = async () => {
        if (!store?._id) return;

        const body: Partial<{
            name: string;
            taxId: string;
            merchantCategoryCode: string;
            phone: string;
        }> = {};
        if (!companyNameValidation(name).length) {
            body.name = name?.trim();
        }
        if (!companyTaxIdValidation(taxId).length) {
            body.taxId = taxId?.trim();
        }
        if (!phoneValidation(phone).length) {
            body.phone = phone?.trim();
        }

        return patchVerification(store._id, body, () => {
            setHasChange(false);
        });
    };

    const handleContinue = async () => {
        if (disabled) return;
        if (!isValid) {
            form.setFields([
                { name: "name", errors: companyNameValidation(name) },
                { name: "taxId", errors: companyTaxIdValidation(taxId) },
                { name: "phone", errors: phoneValidation(phone) },
            ]);
            return;
        }

        if (!hasChange) {
            handleStepChange(OnboardingStep.Summary);
            setHasChange(false);
            return;
        }

        const body: Partial<{
            name: string;
            taxId: string;
            merchantCategoryCode: string;
            phone: string;
        }> = {};
        if (!companyNameValidation(name).length) {
            body.name = name?.trim();
        }
        if (!companyTaxIdValidation(taxId).length) {
            body.taxId = taxId?.trim();
        }
        if (!phoneValidation(phone).length) {
            body.phone = phone?.trim();
        }

        return patchVerification(store._id, body, () => {
            handleStepChange(OnboardingStep.Summary);
            setHasChange(false);
        });
    };

    return (
        <Page
            footer={
                <Footer
                    isValid={isValid}
                    disabled={disabled}
                    saveDisabled={saveDisabled}
                    onSave={handleSave}
                    onContinue={handleContinue}
                    hasChange={hasChange}
                />
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: namePending
                        ? "Pending"
                        : nameProvided
                          ? "Provided"
                          : name,
                    taxId: taxIdProvided ? "Provided" : undefined,
                    phone: phonePending ? "Pending" : phone,
                }}
            >
                <FormInput
                    required
                    disabled={namePending || nameProvided}
                    field="name"
                    label="Legal Business Name"
                    subtitle="The combination of your name and Employer Identification Number (EIN) must exactly match the one listed on your IRS documents (e.g., Letter 147C or SS-4 Confirmation letter), including capitalization and punctuation."
                    placeholder="Business Name"
                    onChange={handleName}
                />
                <RowDivider />
                <FormInput
                    required
                    disabled={taxIdProvided}
                    field="taxId"
                    label="Employer Identification Number (EIN)"
                    subtitle="If you use your Social Security number for business tax purposes, you can enter that instead."
                    placeholder="12-3456789"
                    onChange={handleTaxId}
                />
                <RowDivider />
                <FormInput
                    required
                    disabled={phonePending}
                    value={phone}
                    field="phone"
                    label="Business Phone Number"
                    subtitle="This will not be seen by your customers "
                    placeholder="201-555-0123"
                    onChange={handlePhone}
                    pretext="+1"
                />
            </Form>
        </Page>
    );
};
