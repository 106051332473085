/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from "react";

import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { CashReportContext } from "#financial-reports/screens/cash-report/cash-report-context";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";

const CashDrawerSelector = () => {
    const { data: cashDrawers } = useCashDrawers();
    const { cashDrawer: selectedDrawer, setCashDrawer: setSelectedDrawer } =
        useContext(CashReportContext);

    const selectorLabel = useMemo(
        () =>
            cashDrawers?.find((drawer) => drawer.id === selectedDrawer)?.name ??
            `All Drawers (${cashDrawers?.length ?? 0})`,
        [cashDrawers, selectedDrawer],
    );

    const options = useMemo(
        () => [
            {
                label: "All Drawers",
                value: "ALL",
            },
            ...(cashDrawers
                ? cashDrawers.map((drawer) => ({
                      label: drawer.name,
                      value: drawer.id,
                  }))
                : []),
        ],
        [cashDrawers],
    );

    if (!cashDrawers || cashDrawers.length <= 1) {
        return null;
    }

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">{selectorLabel}</span>
                </Button>
            }
            title="Granularity"
            selectedValues={new Set([selectedDrawer ?? "ALL"])}
            options={options}
            onOptionSelected={function (value: string): void {
                if (value === "ALL") {
                    setSelectedDrawer(undefined);
                } else {
                    setSelectedDrawer(value);
                }
            }}
        />
    );
};

export default CashDrawerSelector;
