import { IPurchase } from "@snackpass/snackpass-types";
import React, { useState } from "react";
import { useGate } from "statsig-react";
import { Briefcase } from "lucide-react";

import config from "#config";
import RefundModal from "#refund-modal";

import { Button } from "src/@/components/ui/button";

type Props = {
    purchase: IPurchase;
};

export const RefundButton = ({ purchase }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const allowMultipleRefunds = useGate(
        config.featureGates.allowMultipleRefunds,
    );

    const show = () => setShowModal(true);
    const hide = () => setShowModal(false);

    if (
        (purchase.refund && !purchase.partialRefund) ||
        (purchase.partialRefund && !allowMultipleRefunds.value)
    ) {
        return null;
    }

    return (
        <>
            <RefundModal
                purchase={purchase}
                isVisible={showModal}
                hide={hide}
            />
            <Button
                variant="outline"
                onClick={show}
                className="flex items-center gap-2"
            >
                <Briefcase className="h-4 w-4" />
                Refund / Adjust Price
            </Button>
        </>
    );
};
