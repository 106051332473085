import React, { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";
import { AccessorKeyColumnDef } from "@tanstack/react-table";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    aggregateItemsByCategory,
    formatForTableExport,
} from "#reports/menu-category-insights/lib";
import { MenuItem } from "#reports/menu-item-insights/types";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { GranularityType } from "#reports/sales-summary/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { MENU_SALES_TOOLTIP_COPY } from "#reports/menu-item-insights/lib";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";

interface TableRow extends MenuItem {
    index: number;
    name: string;
    categoryName: string;
    orders: number;
    sales: number;
}

const CategoryPerformanceTable = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { menuInsightsData, dateRanges } = reportsState;

    const data = aggregateItemsByCategory(menuInsightsData?.itemInsightsData);

    const columnsForExport = [
        {
            title: "",
            dataIndex: "index",
        },
        {
            title: "Category",
            dataIndex: "name",
        },
        {
            title: "Orders",
            dataIndex: "orders",
        },
        {
            title: "Net Sales",
            dataIndex: "sales",
        },
    ];

    const rows = useMemo(() => {
        if (!data) return [];
        return data
            .reduce((prev: MenuItem[], currentRow) => {
                currentRow.items.forEach((item) => {
                    const itemInArray = prev.find((e) => e.id == item.id);
                    if (!itemInArray) {
                        prev.push({ ...item });
                    } else {
                        // aggregate to get totals
                        itemInArray.sales += item.sales;
                        itemInArray.orders += item.orders;
                    }
                });
                return prev;
            }, [])
            .sort((a, b) => b.sales - a.sales)
            .map((category, index) => ({ index: index + 1, ...category }));
    }, [data]);

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            placeholder: "Search by Category",
            global: true,
            defaultSearchValue: "",
        },
    };

    return (
        <div className="mb-20 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Category Performance</h4>
                <DownloadButton
                    rows={formatForTableExport(rows)}
                    columns={columnsForExport}
                    filename={`${
                        activeStore?.name
                    } Category Performance ${formatRange(
                        dateRanges[0],
                        GranularityType.DAILY,
                    )}`}
                />
            </div>
            <div>
                {!menuInsightsData?.itemInsightsDataLoading &&
                (menuInsightsData?.itemInsightsData ||
                    menuInsightsData?.itemInsightsDataFailed) ? (
                    menuInsightsData?.itemInsightsData ? (
                        <DataTable
                            columns={columns}
                            data={rows}
                            toolbar={toolbarOptions}
                            customPageSize={10}
                            showPagination
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

const columns: AccessorKeyColumnDef<TableRow, string | number>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="#"
            />
        ),
        accessorKey: "index",
        id: "index",
        cell: ({ row }) => <div className="ml-3">{row.original.index}</div>,
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 40,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Category"
            />
        ),
        accessorKey: "categoryName",
        id: "categoryName",
        cell: ({ row }) => (
            <div className="ml-3">{row.original.categoryName}</div>
        ),
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: true,
        size: 600,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Orders"
            />
        ),
        accessorKey: "orders",
        id: "orders",
        cell: ({ row }) => (
            <div className="ml-3">
                {isNaN(row.original.orders)
                    ? row.original.orders
                    : formatNumber(row.original.orders)}
            </div>
        ),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 80,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title={
                    <>
                        Net Sales
                        <ReportsTooltip
                            body={MENU_SALES_TOOLTIP_COPY}
                            className="mx-3"
                        />
                    </>
                }
            />
        ),
        accessorKey: "sales",
        id: "sales",
        cell: ({ row }) => (
            <div className="ml-3 flex items-center">
                {isNaN(row.original.sales)
                    ? row.original.sales
                    : toDollarFormatted(row.original.sales)}
            </div>
        ),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 120,
    },
];

export default CategoryPerformanceTable;
