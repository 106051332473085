import React from "react";
import { Popover } from "antd";
import { SystemColors } from "@snackpass/design-system";

type BlackPopoverProps = {
    children: JSX.Element | JSX.Element[];
    onClick: () => void;
    trigger?: "click" | "hover";
    label: string;
};

function BlackPopover({
    children,
    onClick,
    label,
    trigger = "click",
}: BlackPopoverProps) {
    const [visible, setVisible] = React.useState(false);

    const _onClick = React.useCallback(() => {
        onClick();
        setVisible(false);
    }, [onClick]);

    return (
        <Popover
            placement="top"
            trigger={trigger}
            arrowPointAtCenter
            overlayClassName="snackpass__black-popover"
            open={visible}
            onOpenChange={setVisible}
            content={
                <div
                    onClick={_onClick}
                    style={{
                        cursor: "pointer",
                        backgroundColor: SystemColors.v1.toastedSesame,
                        color: SystemColors.v1.milkfoam,
                        padding: "10px 15px",
                        borderRadius: 20,
                        fontWeight: "bold",
                    }}
                >
                    {label}
                </div>
            }
        >
            {children}
        </Popover>
    );
}

export { BlackPopover };
