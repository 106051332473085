import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { DataTable } from "src/@/components/ui/data-table";
import {
    columns,
    formatCentsAsDollars,
} from "#reports/gift-card-purchases/lib";
import { GiftCardPurchaseStatReportRow } from "#reports/gift-card-purchases/types";
import { useGiftCardReportQuery } from "src/api/rest/report-hooks";
import {
    GiftCardPurchaseStats,
    GiftCardReportEndpoints,
} from "src/api/rest/report-hooks/types";

const PurchaseStatTable = () => {
    const { data, isLoading, isError } =
        useGiftCardReportQuery<GiftCardPurchaseStats>({
            endpoint: GiftCardReportEndpoints.PurchaseStats,
        });

    const giftCardPurchaseStats = data?.giftCardPurchaseStats ?? null;

    const rows: GiftCardPurchaseStatReportRow[] = useMemo(() => {
        if (!giftCardPurchaseStats || giftCardPurchaseStats === null) return [];

        const {
            purchaseTotalCount,
            purchaseTotalAmountCents,
            purchaseDigitalCount,
            purchaseDigitalAmountCents,
            purchasePhysicalCount,
            purchasePhysicalAmountCents,
            usageTotalCount,
            usageTotalAmountCents,
            usageDigitalCount,
            usageDigitalAmountCents,
            usagePhysicalCount,
            usagePhysicalAmountCents,
            refundCount,
            refundTotalAmountCents,
            refundDigitalCount,
            refundDigitalAmountCents,
            refundPhysicalCount,
            refundPhysicalAmountCents,
        } = giftCardPurchaseStats;

        return [
            {
                activity: "Activations",
                tooltip:
                    "Activations occur when a gift card is purchased in the selected time period.",
                total: purchaseTotalCount.toString(),
                amount: formatCentsAsDollars(purchaseTotalAmountCents),
                isHeader: true,
            },
            {
                activity: "Digital",
                total: purchaseDigitalCount.toString(),
                amount: formatCentsAsDollars(purchaseDigitalAmountCents),
            },
            {
                activity: "Physical",
                total: purchasePhysicalCount.toString(),
                amount: formatCentsAsDollars(purchasePhysicalAmountCents),
            },
            {
                activity: "Redemptions",
                tooltip:
                    "Redemptions occur when a gift card is used in the selected time period.",
                total: usageTotalCount.toString(),
                amount: formatCentsAsDollars(usageTotalAmountCents),
                isHeader: true,
            },
            {
                activity: "Digital",
                total: usageDigitalCount.toString(),
                amount: formatCentsAsDollars(usageDigitalAmountCents),
            },
            {
                activity: "Physical",
                total: usagePhysicalCount.toString(),
                amount: formatCentsAsDollars(usagePhysicalAmountCents),
            },
            {
                activity: "Outstanding",
                tooltip:
                    "Outstanding represents gift cards that have been purchased but not yet used in the selected time period.",
                total: (purchaseTotalCount - usageTotalCount).toString(),
                amount: formatCentsAsDollars(
                    purchaseTotalAmountCents - usageTotalAmountCents,
                ),
                isHeader: true,
            },
            {
                activity: "Digital",
                total: (purchaseDigitalCount - usageDigitalCount).toString(),
                amount: formatCentsAsDollars(
                    purchaseDigitalAmountCents - usageDigitalAmountCents,
                ),
            },
            {
                activity: "Physical",
                total: (purchasePhysicalCount - usagePhysicalCount).toString(),
                amount: formatCentsAsDollars(
                    purchasePhysicalAmountCents - usagePhysicalAmountCents,
                ),
            },
            {
                activity: "Refunds",
                tooltip:
                    "Refunds occur when a gift card is purchased and then refunded in the selected time period.",
                total: refundCount.toString(),
                amount: formatCentsAsDollars(refundTotalAmountCents),
                isHeader: true,
            },
            {
                activity: "Digital",
                total: refundDigitalCount.toString(),
                amount: formatCentsAsDollars(refundDigitalAmountCents),
            },
            {
                activity: "Physical",
                total: refundPhysicalCount.toString(),
                amount: formatCentsAsDollars(refundPhysicalAmountCents),
            },
        ];
    }, [giftCardPurchaseStats]);

    return (
        <div className="m-2 mb-20 pt-10">
            <div>
                {!isLoading && (isError || data) ? (
                    data ? (
                        <DataTable
                            columns={columns}
                            data={rows}
                            customPageSize={100}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default PurchaseStatTable;
