import { useEffect, useState } from "react";
import { useAsyncDebounce } from "react-table-7";

export const useDebouncedSearch = <T>(
    searchFn: (globalFilter: string) => T,
    delay: number,
): {
    data: T;
    setGlobalFilter: (globalFilter: string) => void;
    globalFilter: string;
} => {
    // State and setters for debounced value
    const [globalFilter, setGlobalFilter] = useState("");
    const [debouncedValue, setDebouncedValue] = useState(
        searchFn(globalFilter),
    );
    const debouncedSetGlobalFilter = useAsyncDebounce(setGlobalFilter, delay);

    useEffect(
        () => setDebouncedValue(searchFn(globalFilter)),
        [globalFilter, searchFn],
    );
    return {
        data: debouncedValue,
        setGlobalFilter: debouncedSetGlobalFilter,
        globalFilter,
    };
};
