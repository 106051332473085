import { BaseStoreDeviceWithNetworkStatus } from "src/api/rest";

export const findAssignedPrepStationDevices = (
    deviceIds: string[],
    storeDevices?: BaseStoreDeviceWithNetworkStatus[],
) => {
    const assignedDevices = storeDevices?.filter((device) =>
        deviceIds.includes(device.deviceDetailsId),
    );

    return assignedDevices?.map((item) => item.name).join(", ");
};
