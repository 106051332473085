import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import DetailsSection from "#devices/components/DetailsDrawer/DetailsSection";
import DescriptionList from "#devices/components/DetailsDrawer/DescriptionList";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { getFormattedDate } from "#devices/components/DevicesTable/lib";
import { ReactComponent as TvIcon } from "src/assets/icons/tv.svg";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { getDeviceStatus } from "#devices/utils/getDeviceStatus";
import { Button } from "src/@/components/ui/button";
import DeviceLog from "#devices/components/DetailsDrawer/DeviceLog";
import { cleanNetwork } from "#devices/utils/cleanNetworkName";
import { ReactComponent as Charging } from "src/assets/icons/charging.svg";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    EditDeviceRotation,
    ImagePreview,
} from "#devices/components/EditDrawer/Settings";
import { SnackTvDevice } from "#devices/utils/deviceTypes";
import { getTvChannel } from "#devices/utils/getTvChannel";

const SnackTvDetails = () => {
    const { setShowRebootDeviceModal, setShowRestartAppModal } =
        useContext(DevicesPageContext);
    const { device } = useContext(DevicesPageContext);

    const snackTvDevice = device as SnackTvDevice;
    const deviceStatus = getDeviceStatus(snackTvDevice);

    const baseType = snackTvDevice?.stats?.networkConnectionType ?? "none";
    const connectionType = baseType
        .slice(0, 1)
        .toUpperCase()
        .concat(baseType.slice(1));

    return (
        <>
            <Row>
                <ImagePreview />
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Details">
                        <DescriptionList
                            items={[
                                {
                                    term: "Device",
                                    icon: <TvIcon />,
                                    description:
                                        getDeviceTypeName(
                                            snackTvDevice?.deviceType,
                                        ) || "",
                                },
                                {
                                    term: "Status · Last Active",
                                    icon: (
                                        <StatusCircleIcon
                                            width={8}
                                            height={8}
                                            fill={deviceStatus.color}
                                        />
                                    ),
                                    description:
                                        deviceStatus.statusWithLastActiveTime,
                                },
                                {
                                    term: "Snack ID",
                                    icon: <TvIcon />,
                                    description: snackTvDevice?.snackId,
                                    copyable: true,
                                },
                                {
                                    term: "Installed",
                                    icon: <TvIcon />,
                                    description: getFormattedDate(
                                        snackTvDevice?.createdAt,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Software Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "App Version",
                                    icon: <TvIcon />,
                                    description:
                                        snackTvDevice.stats?.appVersion ?? "--",
                                    copyable: true,
                                },
                                {
                                    term: "Channel",
                                    icon: <TvIcon />,
                                    description: getTvChannel(
                                        snackTvDevice.deviceDetails?.channel,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Hardware Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Model",
                                    icon: <TvIcon />,
                                    description: `${snackTvDevice?.hardwareMake} ${snackTvDevice?.hardwareModel}`,
                                    copyable: true,
                                },
                                {
                                    term: "Serial Number",
                                    icon: <TvIcon />,
                                    description: snackTvDevice?.serial,
                                    copyable: true,
                                },
                                {
                                    term: "Battery %",
                                    icon: <Charging />,
                                    description: `${
                                        snackTvDevice?.stats?.batteryPercent ??
                                        "Unknown"
                                    }`,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Network">
                        <DescriptionList
                            items={[
                                {
                                    term: "Connection",
                                    icon: <TvIcon />,
                                    description: connectionType,
                                },
                                {
                                    term: "Network Name",
                                    icon: <TvIcon />,
                                    description: cleanNetwork(
                                        snackTvDevice?.stats?.networkName,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DetailsSection heading="Quick Actions" bottom>
                        <Row>
                            <Col sm={6}>
                                <p className="mb-4">
                                    <Button
                                        variant="outline"
                                        className="w-full text-base"
                                        onClick={() =>
                                            setShowRebootDeviceModal(true)
                                        }
                                    >
                                        Reboot Device
                                    </Button>
                                </p>
                            </Col>
                            <Col sm={6}>
                                <p className="mb-4">
                                    <Button
                                        variant="outline"
                                        className="w-full text-base"
                                        onClick={() =>
                                            setShowRestartAppModal(true)
                                        }
                                    >
                                        Restart App
                                    </Button>
                                </p>
                            </Col>
                            <Col sm={6}>
                                <EditDeviceRotation />
                            </Col>
                        </Row>
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DetailsSection heading="Device Log">
                        <DeviceLog logItems={snackTvDevice.events ?? []} />
                    </DetailsSection>
                </Col>
            </Row>
        </>
    );
};

export default SnackTvDetails;
