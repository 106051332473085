/* eslint-disable @typescript-eslint/no-explicit-any*/
import {
    Addon,
    AddonGroup,
    Fulfillment,
    FulfillmentTypeEnum,
    IProduct,
    TaxPolicy,
    TaxPolicyEventType,
    ProductOverrideFields,
} from "@snackpass/snackpass-types";
import { apply } from "json-logic-js";
import { MouseEvent } from "react";

import { removeNulls } from "#core";
import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import {
    RowInstance,
    WithParentId,
} from "#menu-editor/multi-menus/helpers/types";

export const isProduct = (
    maybeProduct: RowInstance,
): maybeProduct is IProduct => !!(maybeProduct as IProduct).addonGroups;
export const isCategory = (
    maybeCat: RowInstance,
): maybeCat is IProductCategoryWithProducts =>
    !!(maybeCat as IProductCategoryWithProducts).productIds;
export const isAddonGroup = (
    maybeGroup: RowInstance,
): maybeGroup is AddonGroup => !!(maybeGroup as AddonGroup).addons;
export const isAddon = (maybeAddon: RowInstance): maybeAddon is Addon =>
    !(
        isCategory(maybeAddon) ||
        isProduct(maybeAddon) ||
        isAddonGroup(maybeAddon)
    );

const addParentId = <T>(subRows: T[], parentId: string): WithParentId<T>[] =>
    subRows.map((row) => ({
        ...row,
        parentId,
    }));

export const getSubRows = (originalRow: RowInstance): RowInstance[] => {
    const parentId = originalRow._id;
    if (isCategory(originalRow))
        return addParentId(originalRow.products, parentId);
    if (isProduct(originalRow))
        return addParentId(originalRow.addonGroups, parentId);
    if (isAddonGroup(originalRow))
        return addParentId(originalRow.addons, parentId);
    // never
    return [];
};

export const getMenuOutlineSubRows = (
    originalRow: RowInstance,
): RowInstance[] => {
    const parentId = originalRow._id;

    if (isCategory(originalRow))
        return addParentId(originalRow.products, parentId);

    return [];
};

export const menuOutlineSearchFunction = (
    rows: any[],
    columnsIds: string[],
    filterValue: string | null | undefined,
): any[] => {
    if (!filterValue) {
        return rows;
    }

    const lowerCaseFilterValue = filterValue.toLowerCase();

    return rows
        .filter((row) => {
            // If the item name matches the filter value, keep the row
            if (
                row.original.name &&
                (row.original.name as string)
                    .toLowerCase()
                    .includes(lowerCaseFilterValue)
            ) {
                return true;
            }

            // If a category row, it should be kept if any of its subRows match the search term
            if (row.depth === 0) {
                return (
                    menuOutlineSearchFunction(
                        row.subRows,
                        columnsIds,
                        filterValue,
                    ).length !== 0
                );
            } else if (row.depth === 1) {
                // If an item row, it should be kept if the search term matches the parent category as in that case
                // the user would be looking for a category
                return row.original.category
                    .toLowerCase()
                    .includes(lowerCaseFilterValue);
            }
            // don't filter addon groups/addons
            return true;
        })
        .map((row) => ({ ...row }));
};

export const selectItemsSearchFunction = <
    T extends IProductCategoryWithProducts | IProduct,
>(
    inputArray: T[],
    searchTerm: string | undefined,
): T[] => {
    if (!searchTerm) return inputArray;
    return removeNulls(
        inputArray.map((el) => {
            if (el.name.toLowerCase().includes(searchTerm.toLowerCase()))
                return el;
            if (isCategory(el)) {
                const matchedProducts = selectItemsSearchFunction(
                    el.products,
                    searchTerm,
                );
                if (matchedProducts.length !== 0)
                    return { ...el, products: matchedProducts };
            }
            if (
                isProduct(el) &&
                el.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
                return el;
            return null;
        }),
    );
};

export const getRowId = (originalRow: RowInstance) => originalRow._id;

export const fulfillmentPropertyMap = (
    fulfillment: Fulfillment,
): keyof NonNullable<ProductOverrideFields["taxes"]> => {
    switch (fulfillment) {
        case "DELIVERY":
            return "delivery";

        case "PICKUP":
            return "pickup";

        case "DINE_IN":
            return "dineIn";
    }
};

export const getRowProps = (row: any, toggleExpandAddonGroups = true) => {
    const baseProps = row.getRowProps();
    if (
        (!toggleExpandAddonGroups && isAddonGroup(row.original)) ||
        isAddon(row.original)
    )
        return baseProps;
    return row.canExpand
        ? { ...baseProps, ...row.getToggleRowExpandedProps() }
        : baseProps;
};

// TODO: Export findPolicyRate in accounting
export const findPolicyRate = (
    fulfillment: Fulfillment,
    taxPolicies: TaxPolicy[],
) => {
    const policy = (taxPolicies || [])
        .sort((a, b) => b.priority - a.priority)
        .find((policy) =>
            apply(policy.conditions, {
                fulfillment,
            }),
        );
    if (!policy) return;
    const event = policy.events.find(
        (event) => event.type === TaxPolicyEventType.setTaxRate,
    );
    if (!event) return;
    return event.taxInfo.rate;
};

export const extractTaxPolicies = ({
    taxPolicies,
}: {
    taxPolicies?: TaxPolicy[];
}) => {
    let deliveryTax: number | undefined;
    let pickUpTax: number | undefined;
    let dineInTax: number | undefined;
    if (taxPolicies?.length) {
        deliveryTax = findPolicyRate(FulfillmentTypeEnum.Delivery, taxPolicies);
        pickUpTax = findPolicyRate(FulfillmentTypeEnum.Pickup, taxPolicies);
        dineInTax = findPolicyRate(FulfillmentTypeEnum.DineIn, taxPolicies);
    }
    return {
        deliveryTax,
        pickUpTax,
        dineInTax,
    };
};

export const getFulfillmentTaxes = (
    productBaseTaxes: number,
    {
        deliveryTax,
        pickUpTax,
        dineInTax,
    }: {
        deliveryTax: number | undefined;
        pickUpTax: number | undefined;
        dineInTax: number | undefined;
    },
    fulfillment: Fulfillment,
): number => {
    switch (fulfillment) {
        case "DELIVERY":
            return deliveryTax ?? productBaseTaxes;
        case "PICKUP":
            return pickUpTax ?? productBaseTaxes;
        case "DINE_IN":
            return dineInTax ?? productBaseTaxes;
    }
};

export const stopPropagation = (e: MouseEvent<HTMLDivElement>) =>
    e.stopPropagation();
