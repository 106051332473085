import { useCallback, useMemo } from "react";

import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";

import { useCreateServiceArea } from "./useCreateServiceArea";
import { useUpdateServiceArea } from "./useUpdateServiceArea";

export function useSaveServiceArea() {
    const editor = useEditorStore();

    const { loading: createLoading, createServiceArea } =
        useCreateServiceArea();
    const { loading: updateLoading, updateServiceArea } =
        useUpdateServiceArea();

    const saveServiceArea = useCallback(async () => {
        const isNew = editor.getState().activeServiceArea?.id === "";

        if (isNew) {
            await createServiceArea();
        } else {
            await updateServiceArea();
        }
    }, [createServiceArea, updateServiceArea, editor]);

    return useMemo(
        () => ({
            loading: createLoading || updateLoading,
            saveServiceArea,
        }),
        [createLoading, updateLoading, saveServiceArea],
    );
}
