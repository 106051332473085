import { Alert, AlertDescription } from "src/@/components/ui/alert";

export const ViewOnlyAlert = ({ show }: { show: boolean }) =>
    show ? (
        <Alert variant="warning" className="ml-2">
            <AlertDescription>
                You currently only have read access to this page and cannot take
                any actions
            </AlertDescription>
        </Alert>
    ) : null;
