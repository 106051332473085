import { GiftCardBalanceDataRow } from "#reports/gift-card-balances/types";
import {
    GiftCardLiabilitiesRow,
    GiftCardPurchaseStatEvents,
} from "#reports/sales-summary/types";

export enum GiftCardReportEndpoints {
    PurchaseStats = "purchase-stats",
    Balances = "balances",
    Liabilities = "liabilities",
}

export type GiftCardLiabilityReport = {
    giftCardLiabilityReport: GiftCardLiabilitiesRow[] | null;
};

export type GiftCardPurchaseStats = {
    giftCardPurchaseStats: GiftCardPurchaseStatEvents | null;
};

export type GiftCardBalanceReport = {
    giftCardBalanceReport: GiftCardBalanceDataRow[] | null;
};
