import { CardTitle } from "src/@/components/ui/card";
import AuditLogTable from "#guestbook/screens/audit-log/AuditLogTable";

export const AuditLog = () => (
    <div className="grid w-full grid-cols-1">
        <div className="px-12 py-4">
            <CardTitle className="text-2xl font-bold">Audit Log</CardTitle>
            <div className="text-gray-600">
                Keep track of customer data adjustments that are manually made
                on the dashboard.
            </div>
            <hr className="border-gray-300" />
        </div>
        <div className="px-12 py-4">
            <AuditLogTable />
        </div>
    </div>
);
