import Constants from "#core/constants";

const fullSerialRegex = /[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4,5}/;

const printerSerialRegex = /[a-zA-Z0-9]/;

export const fullSerial = (serial: string) =>
    `${Constants.ESPER_ID_NAMESPACE}${serial}`;

/** Validate that the serial number follows the convention. Esper serials follow format of
 * XXX-XXX-XXXXX, but currently all use `TTY-NYN-` as the first characters so we can hardcode those
 * and just let the user enter the last 5 */
export const validateScreenSerial = (serial: string) =>
    fullSerialRegex.test(fullSerial(serial));

export const validatePrinterSerial = (serial: string) =>
    printerSerialRegex.test(serial);
