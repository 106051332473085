import React from "react";

import { ResetPassword } from "#settings/components/reset-password";
import { ShowEditContactInfo } from "#settings/components/show-edit-contact-info";
import { ShowEditName } from "#settings/components/show-edit-name-card";
import { ShowEditPicture } from "#settings/components/show-edit-picture";

export const AccountDetailsEdit = () => (
    <div className="flex w-full flex-col space-y-8">
        <ShowEditName />
        <ShowEditPicture />
        <ShowEditContactInfo />
        <ResetPassword />
    </div>
);
