import {
    ITimeRangeSchema,
    SnackpassTimezoneEnum,
} from "@snackpass/snackpass-types";
import _ from "lodash";
import { signInWithCustomToken } from "firebase/auth";
import { toast } from "sonner";

import api from "src/api/rest";
import { firebaseAuth } from "#app/firebase";
import { logAndSendError } from "src/utils/errors";

//add a dollar sign to this input
export const formattedMonetaryInput = (value: string) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// remove the dollar sign and convert it to number type
export const monetaryInputParse = (value: string) =>
    Number(value?.replace(/\$\s?|(,*)/g, ""));

export const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
    if (phoneNumber) {
        const cleaned = phoneNumber.replace(/[^\d]/g, "");
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match && cleaned.length > 10) {
            return cleaned.slice(-10);
        }
        if (match && cleaned.length === 10) {
            return cleaned;
        }
    }
    return null;
};

// Moved the below functions to accounting, under review
// TODO: remove this after accounting PR is approved
export const checkOverlappingHours = (value: ITimeRangeSchema[]) => {
    if (value.length < 2) {
        return true;
    }
    if (value && _.every(value, (i) => i && !_.isNil(i.start))) {
        let counter = 0;
        const localSorted = value.sort((a, b) => {
            if (
                !_.isNil(a.start) &&
                !_.isNil(a.end) &&
                !_.isNil(b.start) &&
                !_.isNil(b.end)
            ) {
                if (a.start > b.start) {
                    return 1;
                } else if (a.start === b.start && a.end > b.end) {
                    return 1;
                }
            }

            return -1;
        });
        while (counter < localSorted.length - 1) {
            const first = localSorted[counter].end;
            const second = localSorted[counter + 1].start;
            if (!_.isNil(first) && !_.isNil(second) && first > second) {
                return false;
            }
            counter += 1;
        }
        return true;
    }
    return true;
};

export const validateHoursStartTimeBeforeEndTime = (
    value: ITimeRangeSchema[],
): boolean => {
    for (const range of value) {
        if (range.start && range.end && range.end <= range.start) {
            return false;
        }
    }
    return true;
};
const IANA_TO_WINDOWS_LONG = {
    [SnackpassTimezoneEnum.chicago]: "Central Time",
    [SnackpassTimezoneEnum.newYork]: "Eastern Time",
    [SnackpassTimezoneEnum.indianapolis]: "Eastern Time",
    [SnackpassTimezoneEnum.denver]: "Mountain Time",
    [SnackpassTimezoneEnum.phoenix]: "Mountain Time",
    [SnackpassTimezoneEnum.la]: "Pacific Time",
    [SnackpassTimezoneEnum.honolulu]: "Hawaii-Aleutian Standard Time",
    [SnackpassTimezoneEnum.juneau]: "Alaska Time",
};

export const getFormattedTimezoneLong = (
    timezone: SnackpassTimezoneEnum,
): string => IANA_TO_WINDOWS_LONG[timezone];

export enum VERIFY_RESULT {
    CANCEL,
    SUCCESS,
    ERROR,
}

/**
 * This will submit a phone verification code previously generated and
 * assign that phone number to the admin. If that phone number is currently
 * assigned to a different user it will be taken from that user
 */
const verifyUserPhoneNumber = async (phoneNumber: string, storeId: string) => {
    // XXX: this should be refactored to show a modal or do something
    // more modern (not prompt)
    const code = prompt(
        "We just sent you a verification code! Please enter it here:",
    );

    if (!code) {
        return VERIFY_RESULT.CANCEL;
    }

    try {
        const verifyPhoneResponse = await api.auth.verifyWithPhoneTakeover(
            phoneNumber,
            code,
            storeId,
        );
        await signInWithCustomToken(
            firebaseAuth,
            verifyPhoneResponse.data.customAuthToken,
        );
        return VERIFY_RESULT.SUCCESS;
    } catch (err) {
        // @ts-expect-error error should contain custom message
        const description = err.response?.data?.message ?? "Unknown error";
        toast.error("Something went wrong", {
            description,
        });
    }
    return VERIFY_RESULT.ERROR;
};

/**
 * This will call an API to verify a phone number for an autheticated admin.
 * Be aware that the resulting call from the method above will take over
 * the phone number from another user if it exists
 * @param phone
 * @returns string - new token if successful
 */
export const sendVerificationCodeForAddingPhone = async (
    phone: string,
    storeId: string,
) => {
    try {
        const response = await api.auth.sendVerification(phone);
        if (response.status !== 200) {
            const description = response.data?.message ?? "Unknown error";
            toast.error("Something went wrong", {
                description,
            });
            logAndSendError(response.data);
            return VERIFY_RESULT.ERROR;
        }
        return await verifyUserPhoneNumber(phone, storeId);
    } catch (err) {
        // @ts-expect-error error should contain custom message
        const description = err.response?.data?.message ?? "Unknown error";
        logAndSendError(err);

        toast.error("Something went wrong", {
            description,
        });
    }
    return VERIFY_RESULT.ERROR;
};
