import { useIsMutating, useMutation } from "@tanstack/react-query";
import { FormProvider, UseFormReset, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { getActiveStore } from "@snackpass/accounting";
import { IStore } from "@snackpass/snackpass-types";
import _ from "lodash";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "src/@/components/ui/button";
import api from "src/api/rest";
import { ScreenLayout } from "#reusable/layout";
import { getActiveStoreId, setActiveStore } from "src/redux/slices";
import { Divider } from "#reusable/divider";
import { OwnershipTransferForm } from "#settings/settings-internal/ownership-transfer";
import DeleteTwilioBrand from "#settings/settings-internal/DeleteTwilioBrand";

import {
    InternalSettingsFormValues,
    MongoVersioned,
    INTERNAL_SETTINGS_SUBMIT_FIELDS,
    useInternalSettingsForm,
    internalSettingsFormDefaults,
    InternalSettingsFormSchema,
} from "./types";
import { InternalSettingsSwitch } from "./switch";
import { InternalSettingsTextInput } from "./text-input";
import { OverlayContainer } from "./overlay-container";
import { logAndSendError } from "src/utils/errors";

/**
 * Save button is disabled if form is pristine or if there is a mutation in progress
 */
function SaveStoreButton() {
    const {
        formState: { isDirty },
        watch,
    } = useInternalSettingsForm();
    const storeId = useSelector(getActiveStoreId);
    const isMutating = !!useIsMutating({ mutationKey: ["update", storeId] });

    // @ts-expect-error No overload matches this call
    const changePayoutPeriodTo = watch("changePayoutPeriodTo");
    const hasChanges = isDirty || changePayoutPeriodTo !== undefined;
    return (
        <Button type="submit" disabled={!hasChanges || isMutating}>
            {isMutating ? "Saving" : hasChanges ? "Save" : "No changes"}
        </Button>
    );
}

type UseUpdateStoreParams = {
    storeId: string;
    reset: UseFormReset<InternalSettingsFormValues>;
};

/**
 * A hook to update store data on the server and reset form on success
 */
function useUpdateStore({ storeId, reset }: UseUpdateStoreParams) {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: ["update", storeId],
        async mutationFn(values: InternalSettingsFormValues) {
            const response = await api.stores.update(
                storeId,
                _.pick(values, INTERNAL_SETTINGS_SUBMIT_FIELDS),
            );
            dispatch(setActiveStore(response.data.store));
            reset(internalSettingsFormDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved",
            });
        },
        onError(error, values) {
            logAndSendError(error);
            toast.error(`${values.name} update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed",
            });
        },
    });
    return mutateAsync;
}

type InternalSettingsFormProps = {
    store: IStore;
};

function formWarningToast(
    message: string,
    id = "internal-settings-form-warning",
) {
    toast.warning(message, {
        className: "bg-warning-light",
        duration: 2000,
        id,
    });
}

/**
 * Render form sections and wire up form submission
 */
function InternalSettingsForm({ store }: InternalSettingsFormProps) {
    const form = useForm<InternalSettingsFormValues>({
        defaultValues: internalSettingsFormDefaults(
            store as MongoVersioned<IStore>,
        ),
        resolver: zodResolver(InternalSettingsFormSchema),
        mode: "all",
    });
    const updateStore = useUpdateStore({
        storeId: store._id,
        reset: form.reset,
    });
    const checkmateEnabled = form.watch("integrations.checkmate.enabled");
    const chowlyEnabled = form.watch("integrations.chowly.enabled");
    const serverAutoAcceptsOrders = form.watch("serverAutoAcceptsOrders");

    function handleServerAutoAcceptsOrdersChange(checked: boolean) {
        if (!checked && checkmateEnabled) {
            formWarningToast(
                "Checkmate integration was disabled because 'Integration auto-accepts orders' was disabled",
                "checkmate-disabled-warning",
            );
            form.setValue("integrations.checkmate.enabled", false);
        }
        if (!checked && chowlyEnabled) {
            formWarningToast(
                "Chowly integration was disabled because 'Integration auto-accepts orders' was disabled",
                "chowly-disabled-warning",
            );
            form.setValue("integrations.chowly.enabled", false);
        }
    }

    return (
        <ScreenLayout
            isLoading={false}
            header={
                <div className="pb-2 text-xlarge font-semibold">
                    Internal Settings
                </div>
            }
            description={
                <div className="text-body text-[#585B5F] md:pr-[20%]">
                    These settings are visible only to snackpass employees
                </div>
            }
            content={
                <>
                    <form
                        onSubmit={form.handleSubmit(async (values) =>
                            updateStore(values),
                        )}
                        className="relative mt-6 h-full w-full space-y-6 overflow-hidden"
                    >
                        <FormProvider {...form}>
                            <div className="flex h-[100%] flex-col space-y-8 overflow-auto pb-8 pl-1 [-ms-overflow-style:'none'] [scrollbar-width:'none'] md:pr-[20%] [&::-webkit-scrollbar]:hidden">
                                <InternalSettingsSwitch
                                    name="serverAutoAcceptsOrders"
                                    label="Integration auto-accepts orders"
                                    description="For stores which do not use the Snackpass tablet for accepting orders. New orders will be automatically received and started (and customer notified as such) without the tablet."
                                    onChange={
                                        handleServerAutoAcceptsOrdersChange
                                    }
                                />
                                <OverlayContainer
                                    overlay={
                                        serverAutoAcceptsOrders ? undefined : (
                                            <div>
                                                Enable{" "}
                                                <strong>
                                                    Integration auto-accepts
                                                    orders
                                                </strong>
                                            </div>
                                        )
                                    }
                                >
                                    <div className="space-y-8">
                                        <div className="text-xlarge font-semibold">
                                            POS Integrations
                                        </div>
                                        <InternalSettingsSwitch
                                            name="integrations.checkmate.enabled"
                                            label="Checkmate"
                                            border={!checkmateEnabled}
                                            onChange={(checked) => {
                                                if (
                                                    checked &&
                                                    !serverAutoAcceptsOrders
                                                ) {
                                                    formWarningToast(
                                                        "Integration auto-accepts orders must be enabled to use Checkmate",
                                                    );
                                                    return false;
                                                }
                                            }}
                                        />
                                        {checkmateEnabled && (
                                            <InternalSettingsTextInput
                                                name="integrations.checkmate.locationId"
                                                label="Checkmate API Key"
                                                emptyValue="null"
                                            />
                                        )}
                                        <InternalSettingsSwitch
                                            name="integrations.chowly.enabled"
                                            label="Chowly"
                                            border={!chowlyEnabled}
                                            onChange={(checked) => {
                                                if (
                                                    checked &&
                                                    !serverAutoAcceptsOrders
                                                ) {
                                                    formWarningToast(
                                                        "Integration auto-accepts orders must be enabled to use Chowly",
                                                    );
                                                    return false;
                                                }
                                            }}
                                        />
                                        {chowlyEnabled && (
                                            <InternalSettingsTextInput
                                                name="integrations.chowly.apiKey"
                                                label="Chowly API Key"
                                                emptyValue="null"
                                            />
                                        )}
                                    </div>
                                </OverlayContainer>
                            </div>
                            <div className="mt-4 flex justify-end pb-4">
                                <SaveStoreButton />
                            </div>
                        </FormProvider>
                    </form>
                </>
            }
        />
    );
}

/**
 * Load initial store data and render the form
 */
export function InternalSettings() {
    const store = useSelector(getActiveStore);
    if (!store) {
        throw new Error("No active store");
    }
    return (
        <div>
            <InternalSettingsForm store={store} />
            <Divider />
            <DeleteTwilioBrand />
            <Divider />
            <OwnershipTransferForm />
        </div>
    );
}
