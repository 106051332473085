import { StoreRelationshipInfo } from "#my-teams/types";

interface Props {
    info: StoreRelationshipInfo[];
}

export const MemberOfTeams = ({ info }: Props) => (
    <div className="my-4 max-w-[900px]">
        {info.map((storeInfo, i) => (
            <div
                key={i}
                className="flex flex-col justify-between border-b p-4 sm:flex-row"
            >
                <RowItem
                    header={storeInfo.name}
                    content={storeInfo.isOwner ? "Owner" : "Member"}
                />
                <RowItem header="Job Title" content={storeInfo.jobTitle} />
                <RowItem header="Identifier" content={storeInfo.identifier} />
                <RowItem header="Pin" content={storeInfo.pin} />
            </div>
        ))}
    </div>
);

type RowItemProps = {
    header: string;
    content: string;
};

const RowItem = ({ header, content }: RowItemProps) => (
    <div className="mb-4 flex-1 flex-col justify-between sm:mb-0">
        <div className="text-sm font-medium">{header}</div>
        <div className="text-sm font-normal text-neutral-600">{content}</div>
    </div>
);
