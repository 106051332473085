import { Canvas, Circle, FabricText } from "fabric";

import { CircleGeometry } from "src/api/graphql/generated/types";
import { Element } from "#table-editor/canvas/Element";
import { TableProperty } from "#table-editor/types";

/**
 * Draws a circle table
 */
export function drawTableCircle(
    canvas: Canvas,
    id: string,
    shape: CircleGeometry,
    properties: TableProperty
) {
    const circle = new Circle({
        top: 0,
        left: 0,
        radius: shape.radius,
        fill: "#dddddd",
        selectable: true
    });

    const text = new FabricText(properties.tableName, {
        fontFamily: "Inter",
        fontSize: 28,
        textAlign: "center",
        fill: "black"
    });

    text.setPositionByOrigin(circle.getCenterPoint(), "center", "center");
    text.setCoords();

    const group = new Element(id, [circle, text], {
        variant: "table",
        top: shape.y,
        left: shape.x,
        width: shape.radius * 2,
        height: shape.radius * 2,
        selectable: true
    });

    group.setControlsVisibility({
        mtr: false,
        mb: false,
        ml: false,
        mt: false,
        mr: false
    });

    canvas.add(group);

    return group;
}
