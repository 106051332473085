import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Moment } from "moment";
import clsx from "clsx";
import {
    ValueType,
    NameType,
} from "recharts/types/component/DefaultTooltipContent";
import {
    TooltipProps,
    Bar,
    BarChart,
    CartesianGrid,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { Legend } from "@tremor/react";
import { match } from "ts-pattern";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartType,
    ChartTypeSelect,
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { GranularityType } from "#reports/sales-summary/types";
import { aggregateMenuInsightsItemRows } from "#reports/menu-item-insights/lib";
import {
    aggregateDatedRows,
    datePointsFromRangeAndGranularity,
    formatNumber,
    getGraphGranularity,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import { ReportsContext } from "#app/reports-context-provider";
import { calculateChartWidth } from "#utils/helpers";
import { ChartConfig, ChartContainer } from "src/@/components/ui/chart";

import { MenuInsightsItemRow, MenuItem } from "../types";

const chartConfig = {} satisfies ChartConfig;

const colors = ["blue", "yellow", "purple", "orange", "pink"];
const tooltipClassNames: string[] = [
    "bg-blue-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-orange-500",
    "bg-pink-500",
];

export interface Props {
    data?: MenuInsightsItemRow[];
    isLoading?: boolean;
    title: string;
}

const TopItemsChart = ({ data, isLoading, title }: Props) => {
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, granularity } = reportsState;
    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    // Attempts to adhere to the granularity that the user selects, unless it results in too few data points.
    // This is used as the granularity for the chart as a whole.
    const highestSuitableGranularity = useMemo(
        () => getGraphGranularity(dateRanges[0], granularity),
        [dateRanges, granularity],
    );

    const dateLabels = useMemo(() => {
        const formatDate = (date: Moment) =>
            highestSuitableGranularity == GranularityType.MONTHLY
                ? date.format("MMM YYYY")
                : date.format("MMM D");

        return datePointsFromRangeAndGranularity(
            dateRanges[0],
            highestSuitableGranularity,
        ).map((e) => formatDate(e));
    }, [dateRanges, highestSuitableGranularity]);

    const aggregatedRows = useMemo(() => {
        if (!data) return null;
        return aggregateDatedRows(
            data,
            aggregateMenuInsightsItemRows,
            highestSuitableGranularity,
        );
    }, [data, highestSuitableGranularity]);

    const valueFormatter = useMemo(
        () =>
            graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber,
        [graphType],
    );

    const topItems = useMemo(() => {
        if (!aggregatedRows) return [];
        return aggregatedRows
            ?.reduce((prev: MenuItem[], currentRow) => {
                currentRow.items.forEach((item) => {
                    const itemInArray = prev.find((e) => e.id == item.id);
                    if (!itemInArray) {
                        prev.push({ ...item });
                    } else {
                        // aggregate stats if already in array
                        itemInArray.sales += item.sales;
                        itemInArray.orders += item.orders;
                    }
                });
                return prev;
            }, [])
            .sort((a, b) =>
                graphType == ChartType.NET_SALES
                    ? b.sales - a.sales
                    : b.orders - a.orders,
            )
            .slice(0, 5);
    }, [aggregatedRows, graphType]);

    const chartData = useMemo(() => {
        if (!aggregatedRows) return [];
        const topItemsSet = new Set(topItems.map((e) => e.id));
        // top 5 items only
        return aggregatedRows.map((row, idx) => ({
            label: dateLabels[idx],
            ...row.items
                .filter((item) => topItemsSet.has(item.id))
                .reduce(
                    (prev, curr) => ({
                        ...prev,
                        // identify by id instead of name in the case of duplicates.
                        [curr.id]:
                            graphType == ChartType.NET_SALES
                                ? curr.sales
                                : curr.orders,
                    }),
                    // passing item names and value formatter into the chart data to use in the tooltip.
                    { itemNames: topItems.map((e) => e.name), valueFormatter },
                ),
        }));
    }, [aggregatedRows, topItems, dateLabels, valueFormatter, graphType]);

    const yWidth = (): number => {
        let maxValue = -Infinity;

        chartData.forEach((obj) => {
            Object.values(obj).forEach((value) => {
                if (typeof value === "number") {
                    maxValue = Math.max(value, maxValue);
                }
            });
        });

        return calculateChartWidth(maxValue, true);
    };

    return (
        <div className="pb-12">
            <div className="mb-3 flex items-center justify-between">
                <h4 className="mb-2 text-large">{title}</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <Legend
                className="mb-6 p-0"
                colors={colors}
                categories={topItems?.map((e) => e.name) ?? []}
            />
            <div className="h-96 w-full">
                {match({ isLoading, data })
                    .with({ isLoading: true }, () => (
                        <Skeleton className="h-full" />
                    ))
                    .with({ data: undefined }, () => (
                        <ErrorChart className="h-full rounded-md" />
                    ))
                    .otherwise(() => (
                        <ChartContainer
                            className="h-full w-full"
                            config={chartConfig}
                        >
                            <BarChart accessibilityLayer data={chartData}>
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={valueFormatter}
                                    width={yWidth()}
                                />
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="label"
                                    tickLine={false}
                                    axisLine={false}
                                />
                                <Tooltip content={CustomTooltip} />
                                {topItems.map((item, idx) => (
                                    <Bar
                                        key={`${item.id}-${idx}`}
                                        dataKey={item.id}
                                        type="linear"
                                        // These colors are in our safelist!
                                        className={`fill-${colors[idx]}-500`}
                                        strokeWidth={2}
                                        radius={4}
                                    />
                                ))}
                            </BarChart>
                        </ChartContainer>
                    ))}
            </div>
        </div>
    );
};

const CustomTooltip = ({
    payload,
    active,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload) return null;

    return (
        <div className="flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            <p className="font-semibold">{label}</p>
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div
                        className={clsx(
                            "flex w-1 flex-col rounded",
                            tooltipClassNames[idx],
                        )}
                    />
                    <div className="flex w-full justify-between">
                        <div className="text-neutral-600">
                            {category.payload["itemNames"][idx]}
                        </div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {category.payload["valueFormatter"](category.value)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TopItemsChart;
