import { DialogDescription } from "@radix-ui/react-dialog";

import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import { Button } from "src/@/components/ui/button";

type SelectSectionConfirmationDialogProps = {
    loading: boolean;
    label: string;
    description: string;
    confirmLabel: string;
    open: boolean;
    onOpenChange(open: boolean): void;
    onConfirm(): void;
};

export function SelectSectionConfirmationDialog({
    label,
    description,
    confirmLabel,
    open,
    onOpenChange,
    onConfirm,
    loading,
}: SelectSectionConfirmationDialogProps) {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="p-0">
                <DialogHeader className="px-6 pt-6">
                    <DialogTitle>{label}</DialogTitle>
                    <DialogDescription>{description}</DialogDescription>
                </DialogHeader>

                <DialogFooter className="border-t border-t-neutral-400 px-6 py-3">
                    <Button
                        type="button"
                        variant="ghost"
                        onClick={() => onOpenChange(false)}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="button"
                        variant="default"
                        onClick={onConfirm}
                        loading={loading}
                        loadingColor="white"
                    >
                        {confirmLabel}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
