/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import { getMultiMenuLoadingState } from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { ConfirmModal } from "#menu-editor/multi-menus/shared-components/confirm-modal";
import { useAppDispatch } from "src/redux/hooks";
import { logAndSendError } from "src/utils/errors";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    menuId: string;
    storeId?: string;
};

export const ResetOverridesModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    menuId,
    storeId,
}) => {
    const dispatch = useAppDispatch();
    const resetState = useSelector(getMultiMenuLoadingState("UpdateOverrides"));

    const handleReset = async () => {
        if (storeId)
            dispatch(multiMenuThunks.resetMenuOverrides({ storeId, menuId }))
                .unwrap()
                .then(() => {
                    toast.success("Overrides have been successfully reversed.");
                    setIsModalOpen(false);
                })
                .catch((err) => {
                    logAndSendError(err);
                });
    };

    return (
        <ConfirmModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            fail={resetState === "failed"}
            isInProcess={resetState === "pending"}
            onClick={handleReset}
            title="Reset Overrides?"
            message="Are you sure you want to reset overrides?"
            action="reset overrides"
        />
    );
};
