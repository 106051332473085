import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

import VisuallyHidden from "#devices/components/VisuallyHidden";

type IconButtonProps = {
    variant: "primary" | "secondary" | "tertiary";
    /** pass in a label that will be visually hidden but available for screen readers */
    label: string;
    size: "large" | "regular" | "small" | "micro";
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledIconButton = styled.button<IconButtonProps>`
    // destructure so we dont have to repeat theme destructure several times
    ${({ theme, variant, size }) => `
      appearance: button;
      cursor: pointer;
      box-shadow: ${theme.buttons.defaultBoxShadow};
      background-color: ${theme.buttons.variant[variant].backgroundColor};
      border: 1px solid ${theme.buttons.variant[variant].borderColor};
      color: ${theme.buttons.variant[variant].color};
      border-radius: ${theme.iconButtons.sizes[size]};
      width: ${theme.iconButtons.sizes[size]};
      height: ${theme.iconButtons.sizes[size]};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all .2s ease-in-out;

      &:hover {
        box-shadow: ${theme.buttons.hoverBoxShadow};
      }

      &:disabled {
        cursor: not-allowed;
        touch-events: none;
        background-color: ${theme.buttons.variant[variant].inactiveBackgroundColor};
        border-color: ${theme.buttons.variant[variant].inactiveBorderColor};
        color: ${theme.buttons.variant[variant].inactiveColor};
      }

      & + button {
        margin-left: ${theme.spacing.base};
      }
       
      & svg {
        fill: currentColor;
      }
  `}
`;

const IconButton = (props: IconButtonProps): JSX.Element => (
    <Tooltip trigger="hover" title={props.label}>
        <StyledIconButton aria-labelledby="button-label" {...props}>
            {props.children}
            <VisuallyHidden id="button-label">{props.label}</VisuallyHidden>
        </StyledIconButton>
    </Tooltip>
);

export default IconButton;
