import { createContext, useContext, useCallback, useState } from "react";
import {
    ThirdPartySource,
    PaymentProvider,
    PurchaseReportTransactionSource,
} from "@snackpass/snackpass-types";
import {
    ComparisonPeriod,
    CurrentPeriod,
    Range,
    Granularity,
} from "#dashboard/types";
import { getStoreTimezone } from "#utils/helpers";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import {
    getCurrentPeriodRange,
    getComparisonRange,
    getComparisonOptions,
} from "../utils/date-range-utils";
import moment from "moment";

type DashboardContextType = {
    // Time range
    currentPeriod: CurrentPeriod;
    currentPeriodStartEnd: Range | null;
    setCurrentPeriod: (newDuration: CurrentPeriod, customRange?: Range) => void;

    // Comparison
    comparisonPeriod: ComparisonPeriod;
    comparisonPeriodStartEnd: Range | null;
    setComparison: (comparison: ComparisonPeriod, range: Range) => void;
    clearComparison: () => void;

    // Granularity
    granularity: Granularity;
    setGranularity: (granularity: Granularity) => void;

    // Transaction filters
    transactionChannels: ThirdPartySource[];
    transactionSources: PurchaseReportTransactionSource[];
    paymentProviders: PaymentProvider[];
    setChannels: (channels: ThirdPartySource[]) => void;
    setSources: (sources: PurchaseReportTransactionSource[]) => void;
    setPaymentTypes: (providers: PaymentProvider[]) => void;
};

// Create context
export const DashboardFiltersContext =
    createContext<DashboardContextType | null>(null);

// Create Provider component
export const DashboardFiltersProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const store = useSelector(getActiveStore);
    const tz = getStoreTimezone(store);

    // Time range state - initialize with LAST7DAYS
    const [currentPeriod, _setCurrentPeriod] = useState<CurrentPeriod>(
        CurrentPeriod.LAST7DAYS,
    );

    const [currentPeriodStartEnd, setCurrentPeriodStartEnd] =
        useState<Range | null>(getCurrentPeriodRange(CurrentPeriod.LAST7DAYS));

    // Initialize comparison with default comparison for LAST7DAYS
    const [comparisonPeriod, setComparisonPeriod] = useState<ComparisonPeriod>(
        ComparisonPeriod.PREVIOUS_7_DAYS,
    );

    const [comparisonPeriodStartEnd, setComparisonPeriodStartEnd] =
        useState<Range | null>(
            getComparisonRange(
                getCurrentPeriodRange(CurrentPeriod.LAST7DAYS),
                ComparisonPeriod.PREVIOUS_7_DAYS,
            ),
        );

    // Filter state
    const [transactionChannels, setTransactionChannels] = useState<
        ThirdPartySource[]
    >([]);
    const [transactionSources, setTransactionSources] = useState<
        PurchaseReportTransactionSource[]
    >([]);
    const [paymentProviders, setPaymentProviders] = useState<PaymentProvider[]>(
        [],
    );

    // Granularity state
    const [granularity, setGranularityState] = useState<Granularity>(() => {
        // Set default based on current period
        switch (currentPeriod) {
            case CurrentPeriod.TODAY:
            case CurrentPeriod.YESTERDAY:
            case CurrentPeriod.LAST7DAYS:
                return Granularity.DAY;
            case CurrentPeriod.LAST30DAYS:
                return Granularity.WEEK;
            case CurrentPeriod.LAST90DAYS:
                return Granularity.MONTH;
            case CurrentPeriod.LAST365DAYS:
                return Granularity.YEAR;
            default:
                return Granularity.DAY;
        }
    });

    const setCurrentPeriod = useCallback(
        (newDuration: CurrentPeriod, customRange?: Range) => {
            if (newDuration === CurrentPeriod.CUSTOM) {
                _setCurrentPeriod(newDuration);
                setCurrentPeriodStartEnd(
                    customRange || {
                        startDate: moment().tz(tz),
                        endDate: moment().tz(tz),
                    },
                );
                setComparisonPeriod(ComparisonPeriod.NONE);
                setComparisonPeriodStartEnd(null);
                setGranularityState(Granularity.DAY); // Always set to daily for custom period
                return;
            }

            // Get new date range based on selected duration
            const newRange = customRange || getCurrentPeriodRange(newDuration);
            _setCurrentPeriod(newDuration);
            setCurrentPeriodStartEnd(newRange);

            // Update granularity based on period
            switch (newDuration) {
                case CurrentPeriod.TODAY:
                case CurrentPeriod.YESTERDAY:
                case CurrentPeriod.LAST7DAYS:
                    setGranularityState(Granularity.DAY);
                    break;
                case CurrentPeriod.LAST30DAYS:
                    setGranularityState(Granularity.WEEK);
                    break;
                case CurrentPeriod.LAST90DAYS:
                    setGranularityState(Granularity.MONTH);
                    break;
                case CurrentPeriod.LAST365DAYS:
                    setGranularityState(Granularity.MONTH);
                    break;
            }

            // Set default comparison period based on duration
            if (newDuration === CurrentPeriod.LAST365DAYS) {
                const comparisonRange = getComparisonRange(
                    newRange,
                    ComparisonPeriod.PREVIOUS_365_DAYS,
                );
                setComparisonPeriod(ComparisonPeriod.PREVIOUS_365_DAYS);
                setComparisonPeriodStartEnd(comparisonRange);
            } else {
                // Get comparison options for the new duration and set the first available option (after NO_COMPARISON)
                const comparisonOptions = getComparisonOptions(newDuration);
                const defaultComparisonOption = comparisonOptions[1]; // Index 1 because index 0 is NO_COMPARISON

                if (defaultComparisonOption) {
                    const comparisonRange = getComparisonRange(
                        newRange,
                        defaultComparisonOption.value,
                    );
                    setComparisonPeriod(defaultComparisonOption.value);
                    setComparisonPeriodStartEnd(comparisonRange);
                } else {
                    setComparisonPeriod(ComparisonPeriod.NONE);
                    setComparisonPeriodStartEnd(null);
                }
            }
        },
        [tz],
    );

    // Filter actions
    const setChannels = useCallback((channels: ThirdPartySource[]) => {
        setTransactionChannels(channels);
    }, []);

    const setSources = useCallback(
        (sources: PurchaseReportTransactionSource[]) => {
            setTransactionSources(sources);
        },
        [],
    );

    const setPaymentTypes = useCallback((providers: PaymentProvider[]) => {
        setPaymentProviders(providers);
    }, []);

    // Comparison actions
    const setComparison = useCallback(
        (comparison: ComparisonPeriod, range: Range) => {
            setComparisonPeriod(comparison);
            setComparisonPeriodStartEnd(range);
        },
        [],
    );

    const clearComparison = useCallback(() => {
        setComparisonPeriod(ComparisonPeriod.NONE);
        setComparisonPeriodStartEnd(null);
    }, []);

    const setGranularity = useCallback((newGranularity: Granularity) => {
        setGranularityState(newGranularity);
    }, []);

    const value = {
        currentPeriod,
        currentPeriodStartEnd,
        setCurrentPeriod,
        comparisonPeriod,
        comparisonPeriodStartEnd,
        setComparison,
        clearComparison,
        transactionChannels,
        transactionSources,
        paymentProviders,
        setChannels,
        setSources,
        setPaymentTypes,
        granularity,
        setGranularity,
    };

    return (
        <DashboardFiltersContext.Provider value={value}>
            {children}
        </DashboardFiltersContext.Provider>
    );
};

// Create hook for easy access
export const useDashboardContext = () => {
    const context = useContext(DashboardFiltersContext);
    if (!context) {
        throw new Error("Must be used within DashboardFiltersContext");
    }
    return context;
};
