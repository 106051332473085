import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

type Props = {
    title: string;
    breadcrumbs: Array<[string, string]>;
};

export const TitleBreadcrumbs = ({ title, breadcrumbs }: Props) => {
    const history = useHistory();
    return (
        <Container>
            <BreadcrumbsContainer>
                {breadcrumbs.map((crumb, index) =>
                    index !== 0 ? (
                        <Crumb key={"crumb-" + index}>
                            &nbsp;{`/ ${crumb[0]}`}
                        </Crumb>
                    ) : (
                        //TODO hook Link up to custom destination from tuple
                        <Crumb
                            onClick={() => history.goBack()}
                            key={"crumb-" + index}
                        >
                            {crumb[0]}
                        </Crumb>
                    ),
                )}
            </BreadcrumbsContainer>
            <Title>{title}</Title>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    padding: 26px 72px;
    @media ${ScreenState.MOBILE} {
        flex-direction: row;
        justify-content: center;
        padding: 26px 16px 22px 16px;
    }
`;

const BreadcrumbsContainer = styled.h2`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    display: flex;
    align-items: center;
    color: ${SystemColors.v2.salt60.light};
    @media ${ScreenState.MOBILE} {
        display: none;
    }
`;

const BreadcrumbsDivider = styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin: 0px 16px;
    color: ${SystemColors.v1.gray60};
`;

const Crumb = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
    color: ${SystemColors.v1.gray40};
    &:hover {
        cursor: pointer;
    }
`;

const Title = styled.h1`
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    @media ${ScreenState.MOBILE} {
        font-size: 20px;
    }
`;
