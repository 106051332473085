import React, { useContext } from "react";

import Text from "#payouts/components/shared/Text";
import theme from "#payouts/utils/theme";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { useRepresentative } from "#payouts/utils/hooks/useRepresentative";
import { RequirementMet } from "#payouts/components/shared/RequirementBlock";

export const EmailRequirementBlock = () => {
    const { editEnabled } = useContext(PayoutsSettingsContext);

    const { hasEligiblePrimary, verifiedOwnersAndExecutives } =
        useRepresentative();

    const emails = verifiedOwnersAndExecutives.map((r) => ({
        name: `${r.firstName} ${r.lastName}`,
        email: r.email,
    }));

    const message = hasEligiblePrimary ? (
        <>Logged in as a verified owner or executive representative</>
    ) : (
        <>
            Only verified representatives can 'Submit' this form. To Accept
            Terms & Conditions, please log in using one of the verified
            representatives below:
            <ul className="mb-0">
                {emails.length ? (
                    emails.map((e) => (
                        <li>
                            {e.name} ({e.email})
                        </li>
                    ))
                ) : (
                    <li>None</li>
                )}
            </ul>
        </>
    );

    return (
        <Text.Subtitle style={{ margin: `${theme.spacing.half} 0` }}>
            <div className="flex flex-row">
                <div>
                    <RequirementMet good={editEnabled && hasEligiblePrimary} />
                </div>
                <div className="flex flex-col">
                    {editEnabled ? (
                        <>
                            <span>{message}</span>
                        </>
                    ) : (
                        <span>{message}</span>
                    )}
                </div>
            </div>
        </Text.Subtitle>
    );
};
