import styled from "styled-components";
import { Divider } from "antd";

import Text from "#payouts/components/shared/Text";
import { ReactComponent as Check } from "#payouts/assets/check.svg";
import theme from "#payouts/utils/theme";

export const Label = ({
    label,
    subtitle,
    note,
    required = false,
    omitRequired,
}: {
    label: string;
    subtitle?: string | React.ReactNode;
    note?: string;
    required?: boolean;
    omitRequired?: boolean;
}) => (
    <LabelContainer>
        <TitleContainer required={required}>
            <Text.SmallLabel>{label}</Text.SmallLabel>
            {!omitRequired ? <Required required={required} /> : null}
        </TitleContainer>
        {subtitle && (
            <SubtitleContainer>
                <Text.Subtitle>{subtitle}</Text.Subtitle>
            </SubtitleContainer>
        )}
        {note && (
            <SubtitleContainer>
                <Text.Subtitle>{note}</Text.Subtitle>
            </SubtitleContainer>
        )}
    </LabelContainer>
);

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    span {
        margin-bottom: ${({ theme }) => theme.spacing.half};
    }
`;

const Required = ({ required }: { required?: boolean }) => (
    <div>
        {required ? (
            <span className="custom-required-mark">*</span>
        ) : (
            <span className="custom-optional-mark">Optional</span>
        )}
    </div>
);

const TitleContainer = styled.div<{ required: boolean }>`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    gap: ${({ theme, required }) =>
        required ? theme.spacing.quarter : theme.spacing.half};

    span {
        color: #0f0f0f;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .custom-required-mark {
        color: red;
        font-weight: 400;
    }

    .custom-optional-mark {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 2px 5px;
        border-radius: 3px;
        font-size: 12px;
        font-family: Inter;
        font-weight: 500;
        line-height: 16px;
    }
`;

const SubtitleContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.half};
    justify-content: aligned;

    h2 {
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    padding-bottom: ${({ theme }) => theme.spacing.half};
`;

export const RowDivider = () => (
    <Divider style={{ marginBottom: theme.spacing.baseAndAHalf }} />
);

export const CheckHeader = () => (
    <CheckContainer>
        <Check className="connect-external-account" />
    </CheckContainer>
);

const CheckContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.base};

    .connect-external-account {
        display: flex;
        flex: 1;
        width: 100%;
    }
`;

export const UploadFileText = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    margin: auto 0;
    justify-content: center;
`;
