import React from "react";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";

import Text from "#payouts/components/shared/Text";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { descriptions } from "#billing/utils/descriptions";

export const Header = (): JSX.Element => {
    const { isMobile } = useWindowDimensions();
    return (
        <Container>
            <TitleContainer>
                <Text.Title2>{descriptions.header.message}</Text.Title2>
                {!isMobile && (
                    <SubtitleContainer>
                        <Text.Subtitle>
                            {descriptions.header.description}
                        </Text.Subtitle>
                    </SubtitleContainer>
                )}
            </TitleContainer>
        </Container>
    );
};

const TitleContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
`;

const SubtitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;

    h2 {
        margin: auto 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    width: 100%;
    justify-content: space-between;

    margin: ${({ theme }) =>
        `${theme.spacing.base} ${theme.spacing.quadruple}`};

    & .container-fluid {
        padding: 0;
    }

    @media ${ScreenState.DESKTOP} {
        max-width: 600px;
    }

    @media ${ScreenState.MOBILE} {
        max-width: 92.5%;
        margin-left: ${({ theme }) => theme.spacing.base};
        margin-right: ${({ theme }) => theme.spacing.base};
    }

    @media ${ScreenState.TABLET} {
        max-width: 95%;
        margin-left: ${({ theme }) => theme.spacing.baseAndAHalf};
        margin-right: ${({ theme }) => theme.spacing.tripe};
    }
`;
