import React, { useContext } from "react";
import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import produce from "immer";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType,
} from "#devices/utils/deviceTypes/SnackTVDevice";

const InputContainer = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
    border: 1px solid #e1e3e6;
    &:hover {
        border: 2px solid #2684ff;
    }
    border-radius: 7px;
    width: 50%;
    padding: 8px 15px;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 0;
    border: none;
    color: ${SystemColors.v1.gray20};

    ::placeholder {
        color: ${SystemColors.v1.gray30};
    }

    :focus {
        box-shadow: none;
        border: none;
    }
`;

const EndAdornment = styled.div`
    & > span {
        font-size: 1rem;
        color: SystemColors.v1.black;
        text-align: center;
    }
`;

const DEFAULT_PAGE_INTERVAL = 15000; // ms

export const SlideIntervalOption = (): JSX.Element => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel,
    );

    const pageInterval =
        channelOptions?.options?.pageInterval ?? DEFAULT_PAGE_INTERVAL;

    return (
        <SettingsSection
            label="Scrolling Speed"
            description="Average duration per category"
        >
            <InputContainer>
                <StyledInput
                    value={pageInterval / 1000}
                    onChange={(e) => {
                        const value =
                            e.target.value.length < 1
                                ? 0
                                : parseInt(e.target.value, 10) * 1000;

                        const updatedOptions = produce(
                            deviceOptions,
                            (draft) => {
                                if (draft) {
                                    const channelOption = draft.find(
                                        (o) => o.channel === channel,
                                    );
                                    if (!channelOption) {
                                        draft.push({
                                            channel: channel as ChannelType,
                                            options: {
                                                pageInterval: value,
                                            },
                                        });
                                    } else {
                                        channelOption.options.pageInterval =
                                            value;
                                    }
                                }
                            },
                        );

                        updateDeviceField("channelOptions", updatedOptions);
                    }}
                />
                <EndAdornment>s</EndAdornment>
            </InputContainer>
        </SettingsSection>
    );
};
