import { zodResolver } from "@hookform/resolvers/zod";
import { IStore } from "@snackpass/snackpass-types";
import { FormProvider, useForm } from "react-hook-form";

import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Header } from "#settings/settings-fees/components/header";
import { MinimumFormValues } from "#settings/settings-fees/utils/types";
import { MinimumFormSchema } from "#settings/settings-fees/utils/schema";
import { FeeInput } from "#settings/settings-fees/components/fee-input";
import { minimumFormDefaults } from "#settings/settings-fees/forms/minimum/minimum-defaults";
import { useUpdateMinimum } from "#settings/settings-fees/utils/hooks/useUpdateMinimum";
import { MinimumFooter } from "#settings/settings-fees/forms/minimum/minimum-footer";

export const MinimumChargeAmountForm = ({ store }: { store: IStore }) => {
    const form = useForm<MinimumFormValues>({
        defaultValues: minimumFormDefaults(store),
        resolver: zodResolver(MinimumFormSchema),
        mode: "all",
    });

    const updateStore = useUpdateMinimum({
        storeId: store._id,
        reset: form.reset,
    });

    return (
        <>
            <form
                onSubmit={form.handleSubmit(async (values) =>
                    updateStore(values),
                )}
                className="relative h-full w-full overflow-hidden"
            >
                <FormProvider {...form}>
                    <Header
                        title="Minimum Charge Amount"
                        titleSubtext="Minimum Charge Amount Cents"
                        subtitle="Do not edit this field without approval from Finance"
                    />
                    <div className="mb-2 flex w-full flex-row">
                        <FormField
                            control={form.control}
                            name="minimumChargeAmountCents"
                            render={({ field }) => (
                                <FormItem className="mr-4 mt-2 flex w-full flex-col">
                                    <FormLabel>Minimum Fee (cents)</FormLabel>
                                    <FormDescription>
                                        This applies to all platforms (app,
                                        kiosk [& register], online ordering).
                                        <br />
                                        Default value if unset is 50 cents.
                                        <br />
                                        Must be between 1 cent and 50 cents,
                                        inclusive.
                                    </FormDescription>
                                    <FormControl>
                                        <div className="flex w-[120px]">
                                            <FeeInput
                                                value={field.value}
                                                prefix="¢"
                                                step={1}
                                                placeholder="0.0"
                                                min={1}
                                                max={50}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value,
                                                        ),
                                                    )
                                                }
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <MinimumFooter />
                </FormProvider>
            </form>
        </>
    );
};
