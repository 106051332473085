import styled from "styled-components";
import { Field } from "formik";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledRow = styled(Row)`
    justify-content: space-between;
    height: fit-content;
    align-items: center;
`;

export const StyledField = styled(Field)`
    border-radius: 8px;
    font-weight: 400;
    width: 100%;
    margin-left: 2px;
    font-size: 16px;
`;

export const RowForSwitch = styled(Row)`
    justify-content: space-between;
`;
