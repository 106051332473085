import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useGetServiceAreasQuery } from "src/api/graphql/generated/types";
import { getActiveStoreId } from "src/redux/slices";
import { useAppSelector } from "src/redux/hooks";
import { fromGQLServiceArea } from "#table-editor/serviceAreas";
import { ServiceArea } from "#table-editor/types";
import { EditorProvider } from "#table-editor/components/Editor/EditorProvider";
import { Header } from "#table-editor/components/Header";
import { SectionSelector } from "#table-editor/components/SectionSelector";
import { Toolbar } from "#table-editor/components/Editor/Toolbar";

import { Editor, ModifyElement } from "./Editor";

export function TablesEditor() {
    const storeId = useAppSelector(getActiveStoreId);

    const {
        data,
        loading: loadingServiceAreas,
        refetch,
    } = useGetServiceAreasQuery({
        variables: {
            storeId,
        },
        refetchWritePolicy: "overwrite",
    });

    const [serviceAreas, setServiceAreas] = useState<ServiceArea[]>();

    useEffect(() => {
        if (loadingServiceAreas || !data) return;

        setServiceAreas(data.serviceAreas.map(fromGQLServiceArea));
    }, [data, loadingServiceAreas]);

    useEffect(() => {
        async function refetchOnFocus() {
            await refetch();
        }

        window.addEventListener("focus", refetchOnFocus);

        return () => {
            window.removeEventListener("focus", refetchOnFocus);
        };
    }, [refetch]);

    const isLoading = loadingServiceAreas || serviceAreas === undefined;

    return (
        <div className="h-full w-full overflow-hidden">
            {isLoading ? (
                <Skeleton className="h-32" />
            ) : (
                <EditorProvider serviceAreas={serviceAreas}>
                    <Header />
                    <SectionSelector />
                    <Editor
                        grid={{
                            size: 30,
                            snap: { gridSize: 30, fraction: 1 },
                        }}
                    >
                        <Toolbar />
                        <ModifyElement />
                    </Editor>
                </EditorProvider>
            )}
        </div>
    );
}
