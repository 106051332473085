export const taxRateMin = 0;
export const taxRateMax = 15;

export const hasFulfillments = (data: {
    dineIn?: number;
    pickup?: number;
    delivery?: number;
}) => {
    const hasDineIn = typeof data.dineIn === "number" && data.dineIn >= 0;
    const hasPickup = typeof data.pickup === "number" && data.pickup >= 0;
    const hasDelivery = typeof data.delivery === "number" && data.delivery >= 0;
    return hasDineIn && hasPickup && hasDelivery;
};
