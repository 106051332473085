import React, { useContext, useState } from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { startCase } from "lodash";

import CloseButton from "#payouts/components/shared/CloseButton";
import { StyledModal, Header } from "#payouts/components/shared/Modal";
import { VerificationsResponse } from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import { handleException } from "#payouts/utils/handleException";
import { UpdatedButton } from "#payouts/components/shared/UpdatedButton";
import api from "src/api/rest";
import { RemovePersonSchema } from "#payouts/utils/validation/schema";
import { confirmRemoveValidation } from "#payouts/utils/validation/form";
import { Label } from "#payouts/components/shared/ModalComponents";

export const ConfirmRemoveModal = () => {
    const [form] = Form.useForm();

    const {
        store,
        storeId,
        removePersonModal,
        removePerson,
        setVerification,
        handleModalChange,
    } = useContext(PayoutsSettingsContext);

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);

    const [confirmRemove, setConfirmRemove] = useState(false);

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        setConfirmRemove(false);
        handleModalChange();
    };

    const storeName = store?.name ?? "this store";

    const first = removePerson?.firstName ?? "";
    const last = removePerson?.lastName ?? "";
    const userName = startCase([first, last].join(" ").toLowerCase());

    const label = "Confirm Removal";
    const action = `Remove ${userName} from ${storeName}`;

    const error = removePersonModal
        ? !RemovePersonSchema.safeParse({
              removePersonId: removePerson?.id,
              confirmRemove,
          }).success
        : false;

    const disabled = !storeId || loading;
    const isValid = hasChange && !error;

    const submitUpdate = async (): VerificationsResponse => {
        if (disabled || !isValid || !removePerson?.id) {
            return Promise.reject();
        }
        setLoading(true);
        return api.verifications.removePerson(storeId, removePerson.id);
    };

    if (!removePersonModal) return <></>;

    return (
        <StyledModal
            open={removePersonModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center="Remove Person"
                />
            }
            footer={
                <>
                    <UpdatedButton
                        block
                        smallRadius
                        variant="tertiary"
                        size="regular"
                        children={<>Cancel</>}
                        disabled={disabled}
                        isValid={true} // cancel is always valid
                        onClick={() => handleModalChange()}
                    />
                    <UpdatedButton
                        block
                        smallRadius
                        variant="danger"
                        size="regular"
                        children={<>Remove</>}
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        onClick={async () => {
                            if (disabled) {
                                return;
                            }
                            if (!isValid) {
                                form.setFields([
                                    {
                                        name: "confirmRemove",
                                        errors: confirmRemoveValidation(
                                            confirmRemove,
                                        ),
                                    },
                                ]);
                                return;
                            }

                            submitUpdate()
                                .then(({ data }) => {
                                    if (data?.success) {
                                        setVerification(data.verification);
                                        setHasChange(false);
                                        setLoading(false);
                                        handleModalChange({});
                                    } else {
                                        notifyFailure(messages.modal.account);
                                        handleException(data);
                                        setLoading(false);
                                    }
                                })
                                .catch((error) => {
                                    notifyFailure(messages.modal.account);
                                    handleException(error);
                                    setLoading(false);
                                });
                        }}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    confirmRemove,
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="confirmRemove"
                            label={<Label required label={label} />}
                        >
                            <Checkbox
                                checked={confirmRemove}
                                children={action}
                                onChange={(e) => {
                                    const value = e.target.checked;
                                    const errors =
                                        confirmRemoveValidation(value);
                                    setHasChange(true);
                                    setConfirmRemove(value);
                                    form.setFields([
                                        {
                                            name: "confirmRemove",
                                            value,
                                            errors,
                                        },
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledModal>
    );
};
