import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { RefCallBack } from "react-hook-form";

import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import { cn } from "src/@/lib/utils";
import { roundToPrecision } from "src/utils/roundToPrecision";

type Props = {
    increment: number;
    onChange: (value: number | string) => void;
    value?: number;
    onBlur?: () => void;
    innerRef: RefCallBack;
    prefix?: string; // added to the start of the text input as grey text
    unitDescription?: string; // added to the end of the text input as grey text
};

function ShadcnNumberInputWithIncrement({
    increment,
    onChange,
    value,
    onBlur,
    innerRef,
    prefix,
    unitDescription,
}: Props) {
    return (
        <div className="flex flex-row">
            <div className="relative flex grow flex-row">
                {prefix && (
                    <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-sm text-gray-500">
                        {prefix}
                    </span>
                )}
                <Input
                    className={cn(
                        "rounded-r-none",
                        // Rough estimate: px-3 is the original, and this works well for 1 character. Note that you can't rely on dynamic tailwind classes to work if you are extending this
                        prefix && `pl-6`,
                    )}
                    value={value}
                    onChange={(event) => {
                        onChange(event.target.value);
                    }}
                    onBlur={onBlur}
                    ref={innerRef}
                />
                {unitDescription && (
                    <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center text-sm text-gray-500">
                        {unitDescription}
                    </div>
                )}
            </div>
            <div className="flex flex-row">
                <Button
                    className="rounded-none"
                    variant={"outline"}
                    onClick={() =>
                        onChange(roundToPrecision(Number(value) - increment, 2))
                    }
                    type="button"
                    size="icon"
                >
                    <MinusIcon className="h-4 w-4" />
                </Button>
                <Button
                    className="rounded-l-none"
                    variant={"outline"}
                    onClick={() =>
                        onChange(roundToPrecision(Number(value) + increment, 2))
                    }
                    type="button"
                    size="icon"
                >
                    <PlusIcon className="h-4 w-4" />
                </Button>
            </div>
        </div>
    );
}

export default ShadcnNumberInputWithIncrement;
