import { z } from "zod";

import { hasFulfillments } from "#settings/settings-tax/utils/helper";
import { TaxFormFields } from "#settings/settings-tax/utils/types";

export const FormSchema = z
    .object({
        taxRate: z.coerce.number().min(0),
        detail: z.boolean().optional().default(false),
        dineIn: z.coerce.number().min(0).optional(),
        pickup: z.coerce.number().min(0).optional(),
        delivery: z.coerce.number().min(0).optional(),
    })
    .refine((data) => (data.detail ? hasFulfillments(data) : true), {
        message: "dineIn, pickup, and delivery are required",
        path: [
            TaxFormFields.dineIn,
            TaxFormFields.pickup,
            TaxFormFields.delivery,
        ],
    });
