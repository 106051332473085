import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

import { FormBody } from "#promotion/components/shared/form-body";

type Props = {
    children: React.ReactNode;
};

export const PromoFormContainer = ({ children }: Props) => (
    <FormContainer>
        <FormBody>{children}</FormBody>
        {/* TODO: Will re-enable once this is built out and working, Kevin Beal 2/21/23
        <FormSideContent>
            <SideNavTitle>ON THIS PAGE</SideNavTitle>
        </FormSideContent> */}
    </FormContainer>
);

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 48px;
    padding: 24px 24px 50px 72px;
    width: 100%;
    @media ${ScreenState.MOBILE} {
        padding: 24px 16px;
    }
`;

const FormSideContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 220px;
    @media ${ScreenState.MOBILE} {
        display: none;
    }
`;

const SideNavTitle = styled.h4`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${SystemColors.v1.gray50};
`;
