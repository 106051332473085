import React from "react";
import "antd/dist/antd.css";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { ScreenState } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import colors from "#reusable/colors/colors.json";

type Props = {
    /**
     * Will usually be a member of FIELD_NAMES except in cases with dynamic fieldName (eg. FormItemsSelector)
     */
    fieldName: string;
    name?: string;
    descriptor?: string;
    inputLabel?: string;
    placeholder?: string;
    required?: boolean;
    recommended?: boolean;
};

type InputProps = {
    error: boolean;
};

export const FormTextInput = ({
    name,
    fieldName,
    descriptor,
    inputLabel,
    placeholder,
    required,
    recommended,
}: Props) => {
    const { getFieldState, register } = useFormContext();

    const { error } = getFieldState(fieldName);

    return (
        <Container>
            {name || descriptor ? (
                <TitleContainer>
                    <FormFieldName
                        name={name}
                        required={required}
                        recommended={recommended}
                    />
                    <FormFieldDescriptor descriptor={descriptor} />
                </TitleContainer>
            ) : null}
            <InputErrorContainer>
                <InputContainer error={!!error}>
                    <Label error={!!error}>{inputLabel}</Label>
                    <TextInput
                        placeholder={placeholder}
                        {...register(fieldName)}
                    />
                </InputContainer>
                {error?.message ? (
                    <ErrorMessage>{error?.message}</ErrorMessage>
                ) : null}
            </InputErrorContainer>
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    display: flex;
    gap: 24px;
    width: 100%;
    margin-bottom: 24px;
    @media ${ScreenState.MOBILE} {
        flex-direction: column;
        gap: 16px;
    }
`;

const InputContainer = styled.div<InputProps>`
    position: relative;
    width: 100%;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 8px;
    ${({ error }) => error && `border-color: ${SystemColors.v1.melon50};`}
`;

const InputErrorContainer = styled.div`
    flex: 1;
`;

const TitleContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Label = styled.p<{ error: boolean }>`
    position: absolute;
    left: 12px;
    top: -6px;
    margin: 0;
    padding: 0 2px 0 2px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${SystemColors.v1.gray50};
    background-color: white;
    z-index: 5;
    ${(props) => props.error && `color: ${SystemColors.v1.melon50}`}
`;

const TextInput = styled.input`
    height: 48px;
    width: 100%;
    border: none;
    border-radius: 8px;
    ::placeholder {
        color: #a0adba;
        font-size: 16px;
        font-weight: 400;
    }
`;

const ErrorMessage = styled.p`
    margin: 0;
    margin-top: 4px;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
`;
