import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { captureException } from "@sentry/react";
import styled from "@emotion/styled";
import useDeepCompareEffect from "use-deep-compare-effect";
import { toast } from "sonner";

import { PromoFormContainer } from "#promotion/components/shared/form-container";
import { FormSectionTitle } from "#promotion/components/shared/form-section-title";
import { PromoDiscountType } from "#promotion/components/promo-discount-type";
import { TitleBreadcrumbs } from "#promotion/components/title-bread-crumbs";
import { FormDropdownSelect } from "#promotion/components/shared/form-dropdown-select";
import {
    FIELD_NAMES,
    FormRewardState,
    FormSubmitState,
    FormUsageTypes,
    RewardPromo,
} from "#promotion/utils/types";
import { ReviewModal } from "#promotion/components/review-modal";
import { PromoName } from "#promotion/components/promo-name";
import {
    DISCOUNT_TYPES,
    DOLLAR_OPTIONS,
    EXPIRATION_DAY_OPTIONS,
    PERCENT_OPTIONS,
    POINTS_OPTIONS,
} from "#promotion/utils/constants";
import { FormSubmit } from "#promotion/components/shared/form-submit";
import {
    RewardDefaultValues,
    RewardPromoValidationSchema,
} from "#promotion/utils/validation/form-schemas/reward";
import { QualifyingItems } from "#promotion/components/qualifying-items";
import { isPromoTypeChangeError } from "#promotion/lib";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { BackButton } from "#promotion/components/back-button";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import { useDiscountRewardAddonsEnabled } from "#navigation/utils";

import { useSubmitPromo } from "./useSubmitPromo";

type Props = RouteComponentProps<any, any, FormRewardState>;

export const RewardPromotionForm = (props: Props) => {
    const { isMobile } = useWindowDimensions();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state } = props.location;
    const history = useHistory();
    const methods = useForm<RewardPromo>({
        // @ts-expect-error see https://stackoverflow.com/questions/69271892/react-hook-form-resolver-type-error-using-yup
        resolver: yupResolver(RewardPromoValidationSchema),
        mode: "all",
        defaultValues: RewardDefaultValues,
    });
    const submitPromo = useSubmitPromo();
    const { handleSubmit, watch, reset } = methods;
    const formDataState = watch();

    const discountRewardAddonsEnabled = useDiscountRewardAddonsEnabled();

    let formData: Partial<RewardPromo> = {};
    let formUsage =
        state.formUsage === "Create"
            ? FormUsageTypes.Create
            : FormUsageTypes.Edit;
    let promoId: string | undefined = state?.promoId || undefined;

    useDeepCompareEffect(() => {
        // If pushed to form with default values, use them!
        // This happens if the user is editing an existing promo
        // or using a shortcut to create a new promo.
        if (state) {
            formData = state.formData;
            formUsage =
                state.formUsage === "Create"
                    ? FormUsageTypes.Create
                    : FormUsageTypes.Edit;
            promoId = state.promoId;
            reset(formData); // This will set new defaultValues
        } else {
            // If no incoming values, then this is a new promo creation, use default values for promo type
            reset(RewardDefaultValues);
        }
    }, [state]);

    const openReviewModal = () => {
        setIsModalOpen(true);
    };

    // Will only be called if the form submission is valid based on the validation schema
    const onSubmitConfirmed = async () => {
        const submitState: FormSubmitState = {
            formUsage,
            success: false,
        };
        if (!isSubmitting) {
            setIsSubmitting(true);
            try {
                await submitPromo(formUsage, formDataState, promoId);
                // Set submit state to true so that notification shows up
                submitState.success = true;
                // Reset the form
                reset(RewardDefaultValues);
                // Go back to list of promos on success
                history.push("/promotion", submitState);
            } catch (err: any) {
                const message: string = err.cause.response?.data?.message;
                // Handle Promo type change attempt/validation error. Not possible with rewards but adding for good measure
                // This occurs when a promotion's derived type during submit is different from its existing type
                if (isPromoTypeChangeError(message)) {
                    toast.error("Promo Type Change Detected", {
                        description:
                            "Fields were changed in a way that resulted in a different promo type being saved, which is not currently supported. \n\nPlease create a new promotion or reach out to support.",
                    });
                } else {
                    // Only log to Sentry for non-handled errors
                    captureException(Error(JSON.stringify(err)));
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const discountType = watch(FIELD_NAMES.DISCOUNT_TYPE);

    return (
        // Using ts-ignore due to error "Type instantiation is excessively deep and possibly infinite"
        //@ts-ignore
        <FormProvider {...methods}>
            <TitleContainer>
                {isMobile ? <BackButton /> : null}
                <TitleBreadcrumbs
                    title="Create a Reward"
                    breadcrumbs={[
                        ["Promos", "promotion"],
                        ["New Promo", ""],
                    ]}
                />
            </TitleContainer>
            <form onSubmit={handleSubmit(openReviewModal)}>
                <PromoFormContainer>
                    <FormSectionTitle title="Details" />
                    <PromoName placeholder="Punchcard Reward 🎟" />
                    <PromoDiscountType />
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount (%)"
                            descriptor="Most restaurants start with 20% - 50% off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT}
                            options={PERCENT_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `${val}%`}
                        />
                    )}
                    {discountType === DISCOUNT_TYPES.DOLLARS_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount ($)"
                            descriptor="Most restaurants start with $1 - $5 off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS}
                            options={DOLLAR_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                        />
                    )}
                    {discountType === DISCOUNT_TYPES.NEW_PRICE && (
                        <FormDropdownSelect
                            name="New Price Amount ($)"
                            descriptor="Sets the price for each item this promotion is applied to"
                            fieldName={
                                FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS
                            }
                            options={DOLLAR_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                            required
                        />
                    )}
                    {/* Snackface only shows this field for promos of type discount, with discount type percent off */}
                    {(discountType === DISCOUNT_TYPES.PERCENT_OFF ||
                        discountType === DISCOUNT_TYPES.FREE_ITEM) &&
                        discountRewardAddonsEnabled && (
                            <FormToggle
                                name="Apply Discount to Add-ons"
                                descriptor="Include add-ons when applying discount"
                                fieldName={FIELD_NAMES.DISCOUNT_ADDONS}
                                divider
                            />
                        )}
                    <FormDropdownSelect
                        name="Points Required"
                        descriptor="Specify the amount of points customers use to redeem reward"
                        fieldName={FIELD_NAMES.POINTS_REQUIRED}
                        options={POINTS_OPTIONS}
                        divider
                        allowCustom
                        customClearValue={1}
                    />
                    <FormDropdownSelect
                        name="Expiration"
                        descriptor="Set how long customers have to use the reward once it’s redeemed"
                        fieldName={FIELD_NAMES.EXPIRATION_DAYS}
                        options={EXPIRATION_DAY_OPTIONS}
                        divider
                        customClearValue={-1}
                        allowCustom
                        formatOptionLabel={(value) =>
                            value > 0
                                ? value === 1
                                    ? `${value} day`
                                    : `${value} days`
                                : "Never"
                        }
                    />

                    <FormSectionTitle title="Reward Item" />
                    <QualifyingItems multiselect={false} />
                    <FormSubmit
                        isEdit={formUsage === FormUsageTypes.Edit}
                        submitting={isSubmitting}
                    />
                </PromoFormContainer>
            </form>
            <ReviewModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={onSubmitConfirmed}
                state={formDataState}
                formUsage={formUsage}
            />
        </FormProvider>
    );
};

const TitleContainer = styled.div`
    position: relative;
`;
