import React from "react";
import { useSelector } from "react-redux";

import {
    getActiveStore,
    getUser,
    getUserSeniorSupportPermission,
} from "src/redux/selectors";
import { ScreenLayout } from "#reusable/layout";
import { useAppSelector } from "src/redux/hooks";
import { ViewOnlyAlert } from "#settings/settings-senior-support/components/view-only-alert";
import { Divider } from "#reusable/divider";
import { UsersTable } from "#settings/settings-senior-support/users";
import { OneOffPayout } from "#settings/settings-senior-support/one-off-payout";

export function SeniorSupportSettings() {
    const user = useAppSelector(getUser);
    const store = useSelector(getActiveStore);
    const canEdit = useAppSelector(getUserSeniorSupportPermission);

    const content =
        !user || !store ? (
            <div className="mt-4">No store selected.</div>
        ) : (
            <div className="mb-4 mt-6">
                <ViewOnlyAlert show={!canEdit} />
                <OneOffPayout store={store} canEdit={canEdit} />
                <Divider />
                <UsersTable store={store} canEdit={canEdit} />
            </div>
        );

    return (
        <ScreenLayout
            header={
                <div className="pb-2 text-xlarge font-semibold">
                    Senior Support
                </div>
            }
            isLoading={false}
            description={
                <div className="text-body text-[#585B5F] md:pr-[20%]">
                    Only Senior Support users have access to these actions
                </div>
            }
            content={content}
        />
    );
}
