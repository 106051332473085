import { Canvas, Line } from "fabric";

/**
 * Draws a grid of size `gridSize` along a given `width` and `height`.
 */
export function drawGrid(
    canvas: Canvas,
    width: number,
    height: number,
    gridSize = 30
) {
    const linesX = Math.ceil(width / gridSize);
    const linesY = Math.ceil(height / gridSize);

    for (let i = 0; i < linesX; i++) {
        const line = new Line([i * gridSize, 0, i * gridSize, height], {
            selectable: false,
            evented: false,
            stroke: "#dadada",
            strokeWidth: 2
        });

        canvas.add(line);
        canvas.sendObjectToBack(line);
    }

    for (let j = 0; j < linesY; j++) {
        const line = new Line([0, j * gridSize, width, j * gridSize], {
            selectable: false,
            evented: false,
            stroke: "#dadada",
            strokeWidth: 2
        });

        canvas.add(line);
        canvas.sendObjectToBack(line);
    }
}
