import { Divider } from "antd";
import React from "react";
import { ThemeProvider } from "styled-components";

import theme from "#payouts/utils/theme";
import { Banner } from "#tax-forms/components/Banner";
import { ContentContainer } from "#tax-forms/components/ContentContainer";
import { Download } from "#tax-forms/components/Download";
import { EmailVerification } from "#tax-forms/components/EmailVerification";
import { PageContainer } from "#tax-forms/components/PageContainer";
import { PageHeader } from "#tax-forms/components/PageHeader";
import { Wrapper } from "#tax-forms/components/Wrapper";

import { TaxFormsProvider } from "./Context";

export const TaxForms = () => (
    <ThemeProvider theme={theme}>
        <TaxFormsProvider>
            <Wrapper>
                <EmailVerification />
                <ContentContainer>
                    <PageHeader />
                    <Divider />
                    <PageContainer>
                        <>
                            <Banner />
                            <Download />
                        </>
                    </PageContainer>
                </ContentContainer>
            </Wrapper>
        </TaxFormsProvider>
    </ThemeProvider>
);
