import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

export const UseGetInvites = () => {
    const store = useSelector(getActiveStore);

    const {
        data: invites,
        isFetching,
        error,
        refetch,
    } = useQuery({
        queryKey: ["invites"],
        queryFn: async () => {
            if (!store) return [];

            return (
                (await api.adminUserInvite.getInvites(store._id)).data
                    .invites ?? []
            );
        },
    });

    return {
        invites,
        isFetching,
        error,
        refetch,
    };
};
