import { HTMLAttributes, PropsWithChildren, ReactNode, useEffect } from "react";
import { Merge } from "type-fest";
import Skeleton from "react-loading-skeleton";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Link, useRouteMatch } from "react-router-dom";

import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { datetimeFormat } from "#utils/datetime-format";
import { Routes } from "#navigation/routes";
import {
    campaignSegmentDisplay,
    campaignTitleDisplay,
    metricsClusterSum,
    metricsOptOutRate,
} from "#guestbook/screens/Campaigns/PastCampaigns";
import { DeliveryStatusPill } from "#guestbook/shared-components/delivery-status-pill";
import { CampaignScreenHeader } from "#guestbook/screens/Campaigns/CampaignScreenHeader";
import GuestbookTooltip from "#guestbook/shared-components/GuestbookTooltip";
import { cn } from "src/@/lib/utils";
import { Badge, BadgeProps } from "src/@/components/ui/badge";
import {
    CampaignWithMetricsAndUserBreakdownFieldsFragment,
    useGetCampaignWithRewardAndMetricsQuery,
} from "src/api/graphql/generated/types";
import { Button } from "src/@/components/ui/button";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";

import { EngagementTable } from "./EngagementTable";
import { ApolloErrorCard } from "./ApolloErrorCard";

const COST_PER_SMS = 0.03;

type CampaignDetailsProps = {
    campaignType: "sms" | "push";
};

export function CampaignDetails({ campaignType }: CampaignDetailsProps) {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when loading
    }, []);
    const {
        params: { campaignId },
    } = useRouteMatch<{ campaignId: string }>();

    const { data, error } = useGetCampaignWithRewardAndMetricsQuery({
        variables: { campaignId },
        skip: !campaignId,
    });

    const campaign = data?.campaignById.campaign;

    useEffect(() => {
        if (campaign) {
            trackSegmentEvent(
                SegmentEvents.Guestbook.Campaigns.VIEWED_CAMPAIGN,
                {
                    store_id: campaign.store.id,
                    store_name: campaign.store.name,
                    campaign_id: campaign.id,
                },
            );
        }
    }, [campaign]);

    return (
        <div className="w-full flex-1 bg-gray-100 ">
            <CampaignScreenHeader
                button={
                    <Button asChild>
                        <Link
                            className="no-underline"
                            to={
                                campaignType === "sms"
                                    ? Routes.GuestbookCampaignsSMS
                                    : Routes.GuestbookCampaignsPush
                            }
                        >
                            Done
                        </Link>
                    </Button>
                }
            >
                Campaign Stats
                {campaign ? (
                    <DeliveryStatusPill
                        deliveryStatus={campaign.deliveryStatus}
                        className="px-3 py-[7px] text-small font-medium"
                    />
                ) : error ? null : (
                    <Skeleton className="rounded-full" width={80} height={32} />
                )}
            </CampaignScreenHeader>
            <div className="mx-auto flex max-w-screen-lg justify-center p-4 sm:p-8">
                {error ? (
                    <ApolloErrorCard
                        error={error}
                        title="Campaign stats load error:"
                    />
                ) : (
                    <div className="flex justify-between gap-x-8">
                        <div className="flex flex-[2] flex-col gap-y-4 sm:gap-y-8">
                            <DetailsCard title="Details" className="gap-4">
                                <div className="flex flex-wrap justify-stretch gap-x-4">
                                    <MetricsCell
                                        className="sm:min-w-full"
                                        label="Campaign Name"
                                        value={
                                            campaign &&
                                            campaignTitleDisplay(campaign)
                                        }
                                    />
                                    <MetricsCell
                                        className="sm:hidden"
                                        label="Message"
                                        value={
                                            (campaign && (
                                                <div className="mt-4 whitespace-pre-wrap ">
                                                    {campaign.message.body}
                                                </div>
                                            )) ??
                                            undefined
                                        }
                                    />
                                    <MetricsCell
                                        label="Sent At"
                                        value={
                                            campaign &&
                                            datetimeFormat(
                                                campaign.createdAt,
                                                "medium-medium",
                                            )
                                        }
                                    />
                                    <MetricsCell
                                        label="Segment Size"
                                        value={campaign?.segmentSizeAtCreation}
                                    />
                                    <MetricsCell
                                        label="Target Audience"
                                        tooltip={
                                            ((campaign?.numberOfUsers &&
                                                (
                                                    (campaign.reachableSizeAtCreation /
                                                        campaign.numberOfUsers) *
                                                    100
                                                ).toFixed(0)) ??
                                                "") +
                                            "% of your Reachable Audience was sent this campaign."
                                        }
                                        value={campaign?.numberOfUsers}
                                    />
                                    <MetricsCell
                                        label="Channel"
                                        value={
                                            campaignType === "sms"
                                                ? "Text Message"
                                                : campaignType === "push"
                                                  ? "Push Notification"
                                                  : undefined
                                        }
                                    />
                                    <MetricsCell
                                        label="Segment"
                                        value={
                                            campaign &&
                                            campaignSegmentDisplay(campaign)
                                        }
                                    />
                                    <MetricsCell
                                        label="Reachable Audience"
                                        tooltip="Reachable represents customers who are both part of the segment, and who have signed up for marketing consent for your store."
                                        value={
                                            campaign?.reachableSizeAtCreation
                                        }
                                    />
                                    <MetricsCell
                                        className="sm:border-0"
                                        label="Estimated Cost"
                                        value={
                                            campaign &&
                                            USDollar.format(
                                                campaign.reachableSizeAtCreation *
                                                    COST_PER_SMS,
                                            )
                                        }
                                    />
                                    <MetricsCell
                                        label="Actual Cost"
                                        tooltip="Cost of campaign may differ from estimated cost if members of your Target Audience are ineligible to receive this campaign due to a carrier restriction."
                                        value={
                                            campaign &&
                                            USDollar.format(
                                                campaign.metrics.sendCount *
                                                    COST_PER_SMS,
                                            )
                                        }
                                    />
                                </div>
                            </DetailsCard>
                            <DetailsCard title="Impact">
                                <div className="flex flex-col flex-wrap justify-stretch gap-4 sm:flex-row">
                                    <ImpactCell
                                        value={
                                            campaign &&
                                            metricsClusterSum(
                                                campaign.metrics.numberOf
                                                    .delivered,
                                            )
                                        }
                                        label="Delivered"
                                        badge={
                                            campaign &&
                                            `${(campaignDeliveryRate(campaign) * 100).toFixed(0)}%`
                                        }
                                        tooltip="Messages that were successfully delivered to the recipient. Messages might fail to deliver due to prohibitive content, inclusion of a URL link, or a carrier issue."
                                    />
                                    <ImpactCell
                                        value={
                                            campaign &&
                                            metricsClusterSum(
                                                campaign.metrics.numberOf
                                                    .optout,
                                            )
                                        }
                                        label="Opt-outs"
                                        badge={
                                            campaign &&
                                            `${(metricsOptOutRate(campaign.metrics.numberOf) * 100).toFixed(0)}%`
                                        }
                                        badgeVariant={
                                            campaign &&
                                            metricsOptOutRate(
                                                campaign.metrics.numberOf,
                                            ) >= 0.2
                                                ? "destructive"
                                                : undefined
                                        }
                                        tooltip="Customers that have opted out from receiving additional text marketing communication after this campaign."
                                    />
                                    <ImpactCell
                                        value={
                                            campaign &&
                                            `$${campaign.metrics.attributedSales.toFixed(2)}`
                                        }
                                        label="Attributed Sales"
                                        tooltip="Total of orders that any customer that received the campaign made in the following 7 days."
                                    />
                                    <ImpactCell
                                        value={
                                            campaign &&
                                            campaign.metrics.attributedOrders
                                        }
                                        label="Attributed Orders"
                                        tooltip="Number of orders that any customer that received the campaign made in the following 7 days."
                                    />
                                </div>
                            </DetailsCard>
                            <DetailsCard title="Customer List">
                                {campaign ? (
                                    <EngagementTable campaign={campaign} />
                                ) : (
                                    <Skeleton count={10} />
                                )}
                            </DetailsCard>
                        </div>
                        <div className="hidden flex-[1] flex-col gap-y-8 sm:flex">
                            <DetailsCard title="Message">
                                {campaign ? (
                                    <div className="relative whitespace-pre-wrap rounded-lg bg-[#f4f5f6] px-4 py-3 text-small font-normal">
                                        {campaign.message.body}
                                        <svg
                                            width="12"
                                            height="27"
                                            viewBox="0 0 12 27"
                                            fill="none"
                                            className="absolute bottom-0 left-[-5px]"
                                        >
                                            <path
                                                d="M5 15.6797V0L12 22.6888C8.21378 26.1806 5.54361 27.2201 0.248651 26.9628C-0.012279 26.9501 -0.0936378 26.6022 0.129974 26.4668C1.43746 25.675 2.39695 24.9124 3.17965 23.9233C4.97284 21.6574 5 18.5723 5 15.6797Z"
                                                fill="#f4f5f6"
                                            />
                                        </svg>
                                    </div>
                                ) : (
                                    <Skeleton count={4} />
                                )}
                            </DetailsCard>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

type ImpactCellProps = Merge<
    HTMLAttributes<HTMLDivElement>,
    {
        value: ReactNode;
        label: ReactNode;
        tooltip?: string;
        badge?: string;
        badgeVariant?: BadgeProps["variant"];
    }
>;

function ImpactCell({
    value,
    label,
    tooltip,
    badge,
    badgeVariant = "secondary",
    className,
    ...rest
}: ImpactCellProps) {
    return (
        <div
            {...rest}
            className={cn(
                "flex-1 relative p-6 border rounded-lg border-neutral-400 min-w-full sm:min-w-[40%]",
                className,
            )}
        >
            <GuestbookTooltip tooltip={tooltip}>
                <div>
                    <div className="text-xlarge font-bold">
                        {typeof value === "undefined" ? (
                            <Skeleton width={60} />
                        ) : (
                            value
                        )}
                    </div>
                    <div className="flex items-center gap-1 text-small text-[#5E6166]">
                        {label}
                        {tooltip && (
                            <ExclamationCircleIcon className="h-4 w-4" />
                        )}
                    </div>
                </div>
            </GuestbookTooltip>

            {badge && (
                <div className="absolute right-6 top-6">
                    <Badge variant={badgeVariant} className="font-normal">
                        {badge}
                    </Badge>
                </div>
            )}
        </div>
    );
}

type MetricsCellProps = Merge<
    HTMLAttributes<HTMLDivElement>,
    {
        label: string;
        value: ReactNode;
        tooltip?: string;
    }
>;

function MetricsCell({
    label,
    value,
    tooltip,
    className,
    ...rest
}: MetricsCellProps) {
    return (
        <div
            {...rest}
            className={cn(
                "flex-1 py-6 text-small border-b border-b-neutral-400 last:border-b-0 min-w-full sm:min-w-[40%]",
                className,
            )}
        >
            <GuestbookTooltip tooltip={tooltip}>
                <div className="flex flex-col gap-1">
                    <div className="flex flex-row items-center gap-1 font-medium text-neutral-950">
                        {label}
                        {tooltip && (
                            <ExclamationCircleIcon className="h-4 w-4 text-[#5E6166]" />
                        )}
                    </div>
                    <div className="text-[#5E6166]">
                        {typeof value === "undefined" ? (
                            <Skeleton width={180} />
                        ) : (
                            value
                        )}
                    </div>
                </div>
            </GuestbookTooltip>
        </div>
    );
}

type DetailsCardProps = PropsWithChildren<
    Merge<
        HTMLAttributes<HTMLDivElement>,
        {
            title: string;
        }
    >
>;

function DetailsCard({
    title,
    children,
    className,
    ...rest
}: DetailsCardProps) {
    return (
        <Card className={className} {...rest}>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>
            <CardContent>{children}</CardContent>
        </Card>
    );
}

const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
});

function campaignDeliveryRate(
    campaign: CampaignWithMetricsAndUserBreakdownFieldsFragment,
) {
    const delivered = metricsClusterSum(campaign.metrics.numberOf.delivered);
    const deliveryRate =
        campaign.numberOfUsers === 0
            ? 0
            : delivered > campaign.numberOfUsers
              ? 1
              : delivered / campaign.numberOfUsers;
    return deliveryRate;
}
