import { Button } from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { FlexRow } from "#menu-editor/multi-menus/styled-components/layout";
import { IconButton } from "#menu-editor/multi-menus/styled-components/icon-button";
import { ReactComponent as SelectAllSVG } from "src/assets/icons/select-all.svg";
import { ButtonsWrapper } from "#menu-editor/multi-menus/styled-components/buttons-wrapper";

interface HeaderProps {
    selectAll: () => void;
    onSubmit: () => void;
}

export const ScreenHeader = ({ selectAll, onSubmit }: HeaderProps) => (
    <FlexRow>
        <Text type="title">Select Items</Text>
        <ButtonsWrapper>
            <IconButton
                icon={SelectAllSVG}
                type="tertiary"
                placement="left"
                size="regular"
                onClick={selectAll}
            >
                Select all
            </IconButton>
            <Button type="primary" size="regular" onClick={onSubmit}>
                Save
            </Button>
        </ButtonsWrapper>
    </FlexRow>
);
