import {
    Fulfillment,
    IStore,
    TransactionSource,
} from "@snackpass/snackpass-types";

import { ChargeFormValues } from "#settings/settings-fees/utils/types";

export const chargeFormDefaults = (store: IStore): ChargeFormValues => {
    // @ts-expect-error Incorrect typing, percentage does exist on this field
    const defaultPercent = store.chargeFeePolicy.percentage;
    // @ts-expect-error Incorrect typing, flat does exist on this field
    const defaultFixed = store.chargeFeePolicy.flat;
    const defaultAllowRefunds =
        // @ts-expect-error Incorrect typing, allowRefunds does exist on this field
        store.chargeFeePolicy.allowRefunds ?? false;

    const findBy = (source: TransactionSource, fulfillment: Fulfillment) => {
        const policy = store.customChargeFeePolicies.find(
            (p) =>
                p.transactionSource === source && p.fulfillment === fulfillment,
        );
        return policy
            ? // @ts-expect-error flat and percentage exist on the policy
              { override: true, fixed: policy.flat, percent: policy.percentage }
            : { override: false, fixed: defaultFixed, percent: defaultPercent };
    };

    const appPickupPolicy = findBy("app", "PICKUP");
    const appDeliveryPolicy = findBy("app", "DELIVERY");
    const appDineInPolicy = findBy("app", "DINE_IN");

    const kioskPickupPolicy = findBy("kiosk", "PICKUP");
    const kioskDineInPolicy = findBy("kiosk", "DINE_IN");

    const onlinePickupPolicy = findBy("online", "PICKUP");
    const onlineDeliveryPolicy = findBy("online", "DELIVERY");
    const onlineDineInPolicy = findBy("online", "DINE_IN");

    return {
        defaultPercent,
        defaultFixed,
        defaultAllowRefunds,
        appPickupOverride: appPickupPolicy.override,
        appPickupFixed: appPickupPolicy.fixed,
        appPickupPercent: appPickupPolicy.percent,
        appDeliveryOverride: appDeliveryPolicy.override,
        appDeliveryFixed: appDeliveryPolicy.fixed,
        appDeliveryPercent: appDeliveryPolicy.percent,
        appDineInOverride: appDineInPolicy.override,
        appDineInFixed: appDineInPolicy.fixed,
        appDineInPercent: appDineInPolicy.percent,
        kioskPickupOverride: kioskPickupPolicy.override,
        kioskPickupFixed: kioskPickupPolicy.fixed,
        kioskPickupPercent: kioskPickupPolicy.percent,
        kioskDineInOverride: kioskDineInPolicy.override,
        kioskDineInFixed: kioskDineInPolicy.fixed,
        kioskDineInPercent: kioskDineInPolicy.percent,
        onlinePickupOverride: onlinePickupPolicy.override,
        onlinePickupFixed: onlinePickupPolicy.fixed,
        onlinePickupPercent: onlinePickupPolicy.percent,
        onlineDeliveryOverride: onlineDeliveryPolicy.override,
        onlineDeliveryFixed: onlineDeliveryPolicy.fixed,
        onlineDeliveryPercent: onlineDeliveryPolicy.percent,
        onlineDineInOverride: onlineDineInPolicy.override,
        onlineDineInFixed: onlineDineInPolicy.fixed,
        onlineDineInPercent: onlineDineInPolicy.percent,
    };
};
