import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";

import {
    SharableBrandRegistration,
    sharableBrandRegistrationsAtom,
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";

export function useSharedSelectedBrand():
    | SharableBrandRegistration
    | undefined {
    const { getValues } = useFormContext();
    const shareableBrands = useRecoilValue(sharableBrandRegistrationsAtom);
    const sharedBrandStoreId = getValues("foreignStoreId");
    return shareableBrands.find(
        (brand) => brand.storeId === sharedBrandStoreId,
    );
}
