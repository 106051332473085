import clsx from "clsx";
import { Spinner } from "react-activity";
import { useFormContext } from "react-hook-form";
import { Badge } from "@tremor/react";
import { Pencil1Icon } from "@radix-ui/react-icons";

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Button } from "src/@/components/ui/button";

export type FormCardProps = {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    sideTitleComponent?: React.ReactNode;
    children?: React.ReactNode | React.ReactNode[];
    footer?: React.ReactNode;
    header?: React.ReactNode;
    onSave?: () => void;
    showSaveButton?: boolean;
    disabled?: boolean;
    isOverride?: boolean;
    fetching?: boolean;
    buttonText?: string;
};

export function FormCard({
    title,
    subtitle,
    sideTitleComponent,
    header,
    footer,
    children,
    onSave,
    disabled,
    isOverride,
    fetching,
    showSaveButton,
    buttonText,
}: FormCardProps) {
    const form = useFormContext();

    const showFooter = !!footer || showSaveButton;

    // if we are in a form context where we are submitting, we should disable the save button unless the form is dirty.
    const isInCleanForm = form ? !form?.formState?.isDirty : false;

    return (
        <Card
            className="w-full border border-neutral-400"
            id={title?.toString()}
        >
            {header}
            <div className="flex w-full items-center justify-between pr-6">
                <CardHeader>
                    <div className="flex justify-between">
                        <CardTitle className="text-large">{title}</CardTitle>
                        {isOverride ? (
                            <Badge color="blue">
                                <Pencil1Icon className="mr-1" />
                                Overridden
                            </Badge>
                        ) : null}
                    </div>
                    <CardDescription>{subtitle}</CardDescription>
                </CardHeader>
                {sideTitleComponent}
            </div>

            {children ? <CardContent>{children}</CardContent> : null}
            {showFooter ? (
                <div className="flex items-center justify-between rounded-b-xl border-t border-neutral-400 bg-neutral-100 px-6 py-4">
                    <div className="text-small text-neutral-600">{footer}</div>
                    <Button
                        className={clsx(!showSaveButton && "invisible")}
                        disabled={disabled || fetching || isInCleanForm}
                        onClick={() => onSave?.()}
                        type="submit"
                    >
                        {fetching ? (
                            <Spinner size={16} />
                        ) : buttonText ? (
                            buttonText
                        ) : (
                            <>Save</>
                        )}
                    </Button>
                </div>
            ) : null}
        </Card>
    );
}
