import { ColumnDef } from "@tanstack/react-table";

import { SeniorSupportUser } from "#settings/settings-senior-support/utils";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { ReactComponent as SeniorSupport } from "#payouts/assets/primary.svg";
import { Button } from "src/@/components/ui/button";

export const getSeniorSupportColumns = (
    canEdit: boolean,
    setPopoverOpen: (userId: string) => void,
    loggedInUserId?: string,
): ColumnDef<SeniorSupportUser>[] => [
    {
        id: "name",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Name" />
        ),
        accessorKey: "name",
        cell: ({ row }) => (
            <div>
                {row.original.firstName} {row.original.lastName}
            </div>
        ),
    },
    {
        id: "email",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Email" />
        ),
        accessorKey: "email",
        cell: ({ row }) => <div>{row.original.email}</div>,
        enableHiding: true,
    },
    {
        id: "isSeniorSupport",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Senior Support" />
        ),
        accessorKey: "isSeniorSupport",
        cell: ({ row }) => (
            <div className="flex justify-center">
                {row.original.isSeniorSupport ? <SeniorSupport /> : <></>}
            </div>
        ),
    },
    {
        id: "actions",
        cell: ({ row }) => (
            <div className="flex justify-center">
                <Button
                    variant="outline"
                    size="sm"
                    disabled={!canEdit || loggedInUserId === row.original.id}
                    onClick={() => {
                        setPopoverOpen(row.original.id);
                    }}
                >
                    <div className="flex min-w-full cursor-pointer items-center rounded-sm p-2">
                        Edit
                    </div>
                </Button>
            </div>
        ),
        enableSorting: false,
        enableHiding: false,
    },
];
