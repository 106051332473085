import { zodResolver } from "@hookform/resolvers/zod";
import { getUser } from "@snackpass/accounting";
import { SystemColors } from "@snackpass/design-system";
import debounce from "lodash/debounce";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";
import { toast } from "sonner";
import { z } from "zod";

import { Button } from "src/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import api from "src/api/rest";
import { useLogout } from "#hooks/use-logout";
import { UserFacingError } from "src/utils/errors";

const FormSchema = z.object({
    verificationCode: z.string().length(6).regex(/^\d+$/),
});

type FormValues = z.infer<typeof FormSchema>;

const INITIAL_FORM_VALUES: FormValues = {
    verificationCode: "",
};

type Props = {
    setHasVerifiedEmail: (hasVerifiedEmail: boolean) => void;
};

/**
 * A form where the user must input a code that they receive via email after
 * initially logging in. This is an enhanced security measure to ensure that
 * the user logging in has access not just to their password and/or phone,
 * but also to the email account associated with their Snackpass account.
 */
export const EmailVerification = memo(({ setHasVerifiedEmail }: Props) => {
    const logout = useLogout();
    const [isLoading, setIsLoading] = useState(false);
    const [shouldCheckCookie, setShouldCheckCookie] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [hasDoneInitialCheck, setHasDoneInitialCheck] = useState(false);
    const user = useSelector(getUser);

    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: INITIAL_FORM_VALUES,
    });

    const handleSendVerificationEmail = debounce(
        async () => {
            if (isLoading) return;
            setIsLoading(true);

            return api.users
                .sendVerificationCode()
                .then(() => setIsDialogOpen(true))
                .catch((error) => {
                    const description =
                        error instanceof UserFacingError
                            ? error.message
                            : "Sorry, we were not able to send you a verification email. Please try again shortly or contact support if this issue persists.";
                    toast.error(description);
                })
                .finally(() => setIsLoading(false));
        },
        1000,
        { leading: true, trailing: false },
    );

    const checkVerificationCode = async ({ verificationCode }: FormValues) => {
        if (isLoading) return;
        setIsLoading(true);

        const handleFailure = (error: unknown) => {
            const description =
                error instanceof UserFacingError
                    ? error.message
                    : "Sorry, we were unable to verify your code. Please try again shortly or contact support if this issue persists.";
            toast.error(description);
        };

        const handleSuccess = () => {
            setShouldCheckCookie(true);
            setIsDialogOpen(false);
        };

        try {
            // Verify the submitted passcode is correct. If so, this API sets
            // the cookie in the browser.
            await api.users.verifyCode({ passcode: verificationCode });
            handleSuccess();
        } catch (e) {
            handleFailure(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const checkVerificationCookie = async () => {
            if (!shouldCheckCookie || isLoading) return;
            setIsLoading(true);
            setShouldCheckCookie(false);

            const handleFailure = (_error: unknown) => {
                if (hasDoneInitialCheck) {
                    toast.error(
                        "Sorry, we were unable to verify your identity. Please try again shortly or contact support if this issue persists.",
                    );
                }
            };

            const handleSuccess = () => {
                setHasVerifiedEmail(true);
            };

            try {
                // Check if the user has a valid verification cookie set in the
                // browser.
                await api.users.checkVerification();
                handleSuccess();
            } catch (e) {
                handleFailure(e);
            } finally {
                if (!hasDoneInitialCheck) setHasDoneInitialCheck(true);
                setIsLoading(false);
            }
        };

        // Automatically check the verification cookie when the page first
        // loads.
        if (shouldCheckCookie && !isLoading) void checkVerificationCookie();
    }, [
        shouldCheckCookie,
        isLoading,
        hasDoneInitialCheck,
        setHasVerifiedEmail,
    ]);

    return !hasDoneInitialCheck ? (
        <div className="mt-4">
            <Spinner color={SystemColors.v1.candy50} size={32} />
        </div>
    ) : (
        <>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Verify Your Identity</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                        <div className="text-base">
                            Please enter the six digit code sent to{" "}
                            {user?.email || "your email"}.
                        </div>
                    </DialogDescription>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(checkVerificationCode)}
                        >
                            <FormField
                                control={form.control}
                                name="verificationCode"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <div className="mt-4 text-sm">
                                <span className="font-medium">
                                    Not seeing the email?{" "}
                                </span>
                                Double check your spam folder, and please wait
                                up to 10 minutes for the email to arrive as some
                                email apps take a while to fetch new emails. If
                                you still haven't received a code, please close
                                this window and click "Send Verification Email"
                                again. For more information and tips, see this{" "}
                                <a
                                    href="https://support.snackpass.co/en/articles/8950536-snackpass-verification-code"
                                    target="_blank"
                                >
                                    help center article
                                </a>
                                .
                            </div>
                            <div>
                                {form.formState.errors.verificationCode && (
                                    <div className="mt-2 text-sm text-red-500">
                                        {
                                            form.formState.errors
                                                .verificationCode.message
                                        }
                                    </div>
                                )}
                            </div>
                            <DialogFooter className="mt-8">
                                <Button
                                    variant="default"
                                    size="default"
                                    disabled={!form.formState.isValid}
                                    loading={isLoading && isDialogOpen}
                                    type="submit"
                                >
                                    Verify
                                </Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
            <div className="mt-2">
                <div className="text-lg font-medium">Verify Your Identity</div>
                <div className="text-base">
                    For your security, we want to make sure it's really you by
                    sending a code to {user?.email || "your email address"}.
                </div>
            </div>
            <Button
                onClick={handleSendVerificationEmail}
                loading={isLoading && !isDialogOpen}
                variant="default"
                size="default"
                className="mt-2 w-full"
            >
                Send Verification Email
            </Button>
            <Button
                onClick={logout}
                variant="outline"
                size="default"
                className="mt-2 w-full"
            >
                Logout
            </Button>
        </>
    );
});
