import { Canvas, Point } from "fabric";

import { Element } from "#table-editor/canvas/Element";

/**
 * Subscribes to `object:scaling` canvas event and properly positions the text
 * component such that it does not stretch and remains centered.
 * @param canvas Fabric canvas instance
 */
export function withElementTextScaling(canvas: Canvas) {
    return canvas.on("object:scaling", (e) => {
        if (
            e.target.isType(Element.type) &&
            // FabricText exists in Element
            (e.target as Element).textIndex >= 0
        ) {
            const node = e.target as Element;

            node.item(node.textIndex).set({
                scaleX: node.width / node.getScaledWidth(),
                scaleY: node.height / node.getScaledHeight()
            });

            node.item(node.textIndex).setPositionByOrigin(
                new Point(0, 0),
                "center",
                "center"
            );
            node.item(node.textIndex).setCoords();
        }
    });
}
