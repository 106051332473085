import { FormLabel } from "src/@/components/ui/form";

export const FeeLabel = ({
    children,
    required = false,
}: {
    children: React.ReactNode;
    required?: boolean;
}) => (
    <div className="flex flex-row">
        <FormLabel>{children}</FormLabel>
        {required && <div className="ml-0.5 text-red-700">*</div>}
    </div>
);
