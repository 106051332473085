import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";

import { CardDescription } from "src/@/components/ui/card";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { GetStoreAudienceResponse } from "src/api/graphql/queries";
import ShadcnNumberInputWithIncrement from "#guestbook/screens/Campaigns/NewCampaign/components/ShadcnNumberInputWithIncrement";
import {
    REACHABLE_AUDIENCE_REQUIRES_ONE,
    REACHABLE_AUDIENCE_TOOLTIP,
} from "#guestbook/screens/Campaigns/NewCampaign/components/reachableAudienceTooltipMessage";
import {
    COST_PER_CUSTOMER,
    CampaignAudienceType,
    getLabelForAudienceType,
    getNumberCustomersFromAudienceType,
    getNumberCustomersFromAudienceTypeIncludingUnreachable,
    newCampaignSMSFormSchema,
} from "#guestbook/screens/Campaigns/NewCampaign/newCampaignFormSchema";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { roundToPrecision } from "src/utils/roundToPrecision";
import EstimatedCostTooltip from "#guestbook/screens/Campaigns/NewCampaign/components/EstimatedCostTooltip";
import { pluralizeNumber } from "src/utils/pluralizeNumber";
import CampaignCardContent from "#guestbook/shared-components/CampaignCardContent";

type Props = {
    audienceData?: GetStoreAudienceResponse;
};

function AudienceCardInput({ audienceData }: Props) {
    const { control, setValue } =
        useFormContext<z.infer<typeof newCampaignSMSFormSchema>>();

    const isLoading = audienceData == null;
    const formWatch = useWatch({
        control: control,
    });
    const { segment, estimatedCost } = formWatch;
    const numberOfCustomers =
        segment == null
            ? 0
            : getNumberCustomersFromAudienceType(segment, audienceData) ?? 0;
    const alignTargetedToEstimatedCost = useCallback(
        (newEstimatedCost: number) => {
            const newNumberOfCustomers = Math.round(
                newEstimatedCost / COST_PER_CUSTOMER,
            );
            const newPercentTarget = roundToPrecision(
                (newNumberOfCustomers / numberOfCustomers) * 100,
                2,
            );
            setValue("percentTarget", newPercentTarget, {
                shouldValidate: true,
                shouldTouch: true,
            });
        },
        [numberOfCustomers, setValue],
    );
    const reconcileEstimatedCostNonDivisible = useCallback(
        (estimatedCost: number) => {
            const numCustomers = roundToPrecision(
                // Rounding to 2 decimal places to handle edge cases like 2.999999 not matching "3"
                estimatedCost / COST_PER_CUSTOMER,
                2,
            );
            // If would result in a partial customer, then we floor the cost to the previous multiple
            // of COST_PER_CUSTOMER
            if (numCustomers !== Math.floor(numCustomers)) {
                const newCost = roundToPrecision(
                    Math.floor(numCustomers) * COST_PER_CUSTOMER,
                    2,
                );
                setValue("estimatedCost", newCost, {
                    shouldValidate: true,
                    shouldTouch: true,
                });
                alignTargetedToEstimatedCost(newCost);
            }
        },
        [alignTargetedToEstimatedCost, setValue],
    );
    const alignEstimatedCostToTargeted = useCallback(
        (newTargeted: number) => {
            const newNumberOfCustomers = Math.round(
                (numberOfCustomers * newTargeted) / 100,
            );
            const newCost = roundToPrecision(
                newNumberOfCustomers * COST_PER_CUSTOMER,
                2,
            );
            setValue("estimatedCost", newCost, {
                shouldValidate: true,
                shouldTouch: true,
            });
        },
        [numberOfCustomers, setValue],
    );
    return (
        <CampaignCardContent>
            <FormField
                control={control}
                name="segment"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <p className="text-xl font-semibold">Audience</p>
                        </FormLabel>
                        <CardDescription>
                            Choose which segment will receive this campaign. You
                            can target between 1% and 100% of the reachable
                            members of the segment. Reachable customers are
                            those that have signed up for marketing consent for
                            your store.
                            <div className="pt-4 text-xs lg:pt-6">
                                <b>Followers</b> are customers who have ordered
                                in-store and signed up or followed your store on
                                the app.
                                <br />
                                <b>Needs Attention</b> are customers who last
                                ordered between 30 to 60 days ago.
                                <br />
                                <b>New Customers</b> are customers who have made
                                their first order in the past 30 days.
                                <br />
                                <b>Recent Customers</b> are customers who have
                                purchased in the last 30 days.
                            </div>
                        </CardDescription>
                        <div className="flex flex-row pt-3">
                            <FormLabel>
                                <p className="font-semibold">Segment</p>
                            </FormLabel>
                            <ReportsTooltip
                                body={`${REACHABLE_AUDIENCE_TOOLTIP} ${REACHABLE_AUDIENCE_REQUIRES_ONE}`}
                            />
                        </div>
                        <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                        >
                            <FormControl>
                                {isLoading ? (
                                    <Skeleton className="h-8" />
                                ) : (
                                    // Select trigger uses whitespace-nowrap which causes elements to overflow their parent.
                                    // A workaround is to use a max width of 320px (aka max-w-80) on phone size. However past the
                                    // 450 point, this will look good as full width
                                    <SelectTrigger className="max-w-80 min-[450px]:max-w-full">
                                        <SelectValue placeholder="Select an Audience" />
                                    </SelectTrigger>
                                )}
                            </FormControl>
                            <SelectContent>
                                {(
                                    Object.values(
                                        CampaignAudienceType,
                                    ) as Array<CampaignAudienceType>
                                )
                                    .filter(
                                        (type) =>
                                            getNumberCustomersFromAudienceType(
                                                type,
                                                audienceData,
                                            ) != undefined, // undefined would indicate this is the "test segment" that should only show up on hogwarts
                                    )
                                    .map((type) => (
                                        <SelectItem
                                            value={type}
                                            key={type}
                                            disabled={
                                                getNumberCustomersFromAudienceType(
                                                    type,
                                                    audienceData,
                                                ) === 0
                                            }
                                        >
                                            <div>{`${getLabelForAudienceType(
                                                type,
                                            )} (${getNumberCustomersFromAudienceTypeIncludingUnreachable(
                                                type,
                                                audienceData,
                                            )} customers, ${getNumberCustomersFromAudienceType(type, audienceData)} reachable)`}</div>
                                        </SelectItem>
                                    ))}
                            </SelectContent>
                        </Select>
                        <FormMessage />
                    </FormItem>
                )}
            />
            {segment != null && (
                <div className="flex flex-row flex-wrap gap-x-4">
                    <FormField
                        control={control}
                        name="percentTarget"
                        render={({ field }) => (
                            <FormItem className="flex min-w-60 shrink grow basis-60 flex-col">
                                <FormLabel>
                                    <p className="pt-4 font-semibold lg:pt-6">
                                        Target
                                    </p>
                                </FormLabel>
                                <FormControl>
                                    <ShadcnNumberInputWithIncrement
                                        increment={1}
                                        value={field.value}
                                        onChange={(newValue) => {
                                            const trueNewValue = Math.min(
                                                100,
                                                Number(newValue),
                                            );
                                            field.onChange(trueNewValue);
                                            alignEstimatedCostToTargeted(
                                                Number(trueNewValue),
                                            );
                                        }}
                                        onBlur={() =>
                                            reconcileEstimatedCostNonDivisible(
                                                estimatedCost ?? 0,
                                            )
                                        }
                                        innerRef={field.ref}
                                        unitDescription="%"
                                    />
                                </FormControl>
                                <div className="text-xs text-muted-foreground">
                                    Currently representing{" "}
                                    {pluralizeNumber(
                                        Math.round(
                                            (estimatedCost ?? 0) /
                                                COST_PER_CUSTOMER,
                                        ),
                                        "customer",
                                    )}
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="estimatedCost"
                        render={({ field }) => (
                            <FormItem className="flex min-w-60 shrink grow basis-60 flex-col">
                                <div className="flex flex-row pt-4 lg:pt-6">
                                    <FormLabel>
                                        <p className="font-semibold">
                                            Estimated Cost
                                        </p>
                                    </FormLabel>
                                    <EstimatedCostTooltip
                                        estimatedCost={estimatedCost ?? 0}
                                    />
                                </div>
                                <FormControl>
                                    <ShadcnNumberInputWithIncrement
                                        increment={COST_PER_CUSTOMER}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                            alignTargetedToEstimatedCost(
                                                Number(newValue),
                                            );
                                        }}
                                        value={field.value}
                                        onBlur={() =>
                                            reconcileEstimatedCostNonDivisible(
                                                estimatedCost ?? 0,
                                            )
                                        }
                                        innerRef={field.ref}
                                        prefix="$"
                                        unitDescription="estimated cost"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            )}
        </CampaignCardContent>
    );
}

export default AudienceCardInput;
