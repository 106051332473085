import { Canvas, FabricText, Point } from "fabric";

import { Element } from "#table-editor/canvas/Element";

export function redrawElementText(
    canvas: Canvas,
    node: Element,
    value: string
) {
    const objects = node.getObjects(FabricText.type);

    objects.forEach((text) => {
        text.set({
            text: value
        });

        // Centers text after updating its contents
        text.setPositionByOrigin(new Point(0, 0), "center", "center");
        text.setCoords();

        canvas.requestRenderAll();
    });
}
