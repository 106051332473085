import { RootState } from "src/redux/store";

/**
 * Base selector functions. Other selectors are composed of these base functions.
 */
export const getCurrentMultiMenu = (state: RootState) =>
    state.multiMenus.currentMenu;

export const getStoreMultiMenus = (state: RootState) =>
    state.multiMenus.storeMenus;
