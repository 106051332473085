import { getActiveStore } from "@snackpass/accounting";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
    IKiosk,
    PrepStation,
    PrinterWithPrepStation,
} from "@snackpass/snackpass-types";
import { Spin, Row } from "antd";
import { debounce } from "radash";

import api from "src/api/rest";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { notifications } from "#devices/components/Notification";
import { BaseStoreDevice } from "#devices/utils/deviceTypes";
import { fetchStoreProducts } from "#menu/api";
import { setLegacyProducts } from "src/redux/slices";
import { useAppDispatch } from "src/redux/hooks";
import { usePrepStations } from "#prep-stations/hooks";

import PrepStationDetail, { PrepStationUpdate } from "./PrepStationDetail";

export const PrepStationAddonView = () => {
    const {
        addon,
        storeDevices,
        setIsAddonDrawerVisible,
        setIsEditDrawerVisible,
        setStoreDevices,
    } = useContext(DevicesPageContext);
    const activeStore = useSelector(getActiveStore);
    const { refetch } = usePrepStations();

    const dispatch = useAppDispatch();

    const [printers, setPrinters] = useState<Array<PrinterWithPrepStation>>([]);
    const [kiosks, setKiosks] = useState<IKiosk[]>([]);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        void debouncedFetch();
    }, [addon, activeStore?._id]);

    const fetchData = async () => {
        if (activeStore?._id) {
            try {
                setHasLoaded(false);

                const [legacyMenu, printersResponse, kioskResponse] =
                    await Promise.all([
                        fetchStoreProducts(activeStore._id),
                        api.printers.getPrinters(activeStore._id),
                        api.kiosk.getKiosksForStore(activeStore._id),
                    ] as const);

                dispatch(setLegacyProducts(legacyMenu.products));
                setPrinters(printersResponse.data.printers);
                setKiosks(kioskResponse.data.devices);

                setHasLoaded(true);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const handleSave = (id: string, prepStation: PrepStationUpdate) => {
        if (activeStore?._id) {
            void api.prepStations
                .updatePrepStation(id, prepStation)
                .then(async ({ data }) => {
                    notifications.success("Successfully saved Prep Station");
                    const updatedStoreDevices = storeDevices?.map((d) => {
                        if (d.deviceDetailsId === data.prepStation.id)
                            d.name = data.prepStation.name;
                        return d;
                    });
                    setIsAddonDrawerVisible(false);
                    setIsEditDrawerVisible(true);
                    setStoreDevices(updatedStoreDevices);
                    await refetch();
                })
                .catch(() => {
                    notifications.error(
                        "Unable to save Prep Station at this time.",
                    );
                });
        }
    };

    const handleCreate = (prepStation: PrepStationUpdate) => {
        if (activeStore?._id) {
            void api.prepStations
                .createPrepStation(prepStation)
                .then(async () =>
                    api.storeDevices.listStoreDevices(activeStore._id),
                )
                .then(async ({ data: { devices } }) => {
                    notifications.success("Successfully created Prep Station");
                    setIsAddonDrawerVisible(false);
                    setIsEditDrawerVisible(true);
                    setStoreDevices(devices);
                    await refetch();
                })
                .catch(() => {
                    notifications.error(
                        "Unable to create Prep Station at this time.",
                    );
                });
        }
    };

    const handleDelete = (prepStationDetailId: string) => {
        if (activeStore?._id) {
            void api.prepStations
                .deletePrepStation(prepStationDetailId)
                .then(async () => {
                    notifications.success("Successfully removed Prep Station");
                    const updatedStoreDevices = storeDevices?.reduce(
                        (acc, d) =>
                            d.deviceDetailsId === prepStationDetailId
                                ? [...acc]
                                : [...acc, d],
                        [] as BaseStoreDevice[],
                    );
                    setIsAddonDrawerVisible(false);
                    setIsEditDrawerVisible(true);
                    setStoreDevices(updatedStoreDevices);
                    await refetch();
                })
                .catch(() => {
                    notifications.error(
                        "Unable to remove Prep Station at this time.",
                    );
                });
        }
    };

    const debouncedFetch = useMemo(
        () => debounce({ delay: 300 }, fetchData),
        [addon, activeStore?._id],
    );

    const debouncedSave = useMemo(
        () => debounce({ delay: 300 }, handleSave),
        [addon, activeStore?._id],
    );

    const debouncedCreate = useMemo(
        () => debounce({ delay: 300 }, handleCreate),
        [addon, activeStore?._id],
    );

    const debouncedDelete = useMemo(
        () => debounce({ delay: 300 }, handleDelete),
        [addon, activeStore?._id],
    );

    const prepStationAddon = addon?.details as PrepStation | undefined;

    if (!hasLoaded) {
        return (
            <Row align="middle" justify="center">
                <Spin />
            </Row>
        );
    }

    return (
        <PrepStationDetail
            storeId={activeStore?._id || ""}
            isCreate={addon?.isCreate ?? false}
            activePrepStation={prepStationAddon}
            printers={printers}
            kiosks={kiosks}
            onSave={debouncedSave}
            onCreate={debouncedCreate}
            onDelete={debouncedDelete}
        />
    );
};
