import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

export const ButtonContainer = styled.button`
    border: 1px solid ${SystemColors.v1.gray60};
    box-sizing: border-box;
    border-radius: 56px;
    flex: none;
    order: 0;
    margin-left: 8px;
    background: none;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px 5px 7px;
    min-height: 35px;

    @media ${ScreenState.MOBILE} {
        padding: 0px;
    }
`;

export const ButtonIcon = styled.img`
    position: "static";
    width: 16px;
    height: 16px;
    left: 16px;
    top: 10px;
    flex: "none";
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
`;

export const Panel = styled.div`
    position: absolute;
    background: ${SystemColors.v1.white};
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 21px;
    margin: 2px 5px;
    padding: 1em;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    top: 40px;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const CancelButton = styled.div`
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    padding: 3px 10px;
`;

export const SaveButton = styled(CancelButton)`
    color: ${SystemColors.v1.white};
    background-color: ${SystemColors.v1.candy50};
    padding: 3px 10px;
    border-radius: 22px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CheckboxRowContainer = styled.div`
    margin: 0.5em 0 0.8em 0;
`;

export const Text = styled.div`
    color: ${SystemColors.v1.sesame};
    font-size: 14px;
    font-weight: bold;
    @media ${ScreenState.MOBILE} {
        display: none;
    }
`;
