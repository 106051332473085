import React, { useContext, useMemo } from "react";
import clsx from "clsx";

import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReportsContext } from "#app/reports-context-provider";
import {
    channelOptionsMap,
    otherChannelOption,
    snackpassChannelOption,
} from "#reports/sales-summary/lib";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { ChannelFilter } from "#reports/sales-summary/types";
import { Button } from "src/@/components/ui/button";

export const ChannelFilterPicker = () => {
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { filter, channels } = reportsState;

    const channelOptions = useMemo(() => {
        const channelOptions = [snackpassChannelOption];

        const mappedChannels = (
            channels
                ? channels.map((e) =>
                      e !== snackpassChannelOption.value &&
                      e !== otherChannelOption.value
                          ? channelOptionsMap[e]
                          : null,
                  )
                : Object.values(channelOptionsMap)
        ).filter((e) => e) as { label: string; value: string }[];

        channelOptions.push(...mappedChannels);

        if (channels.includes(otherChannelOption.value)) {
            channelOptions.push(otherChannelOption);
        }

        return channelOptions;
    }, [channels]);

    const selectedValues = useMemo(
        () => new Set(filter.channel as string[]),
        [filter.channel],
    );

    return (
        <FilterSelect
            title={"Provider"}
            selectedValues={selectedValues}
            options={channelOptions}
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className={clsx(
                        "flex items-center space-x-2",
                        selectedValues.size > 0 && "border-neutral-800",
                    )}
                >
                    <FilterIcon className="h-4 w-4" />
                    <span>Provider</span>
                </Button>
            }
            onOptionSelected={(value: string) => {
                if (selectedValues.has(value)) {
                    selectedValues.delete(value);
                } else {
                    selectedValues.add(value);
                }
                setReportsState((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        channel: Array.from(selectedValues) as ChannelFilter[],
                    },
                }));
            }}
        />
    );
};
