import React from "react";
import "tippy.js/dist/tippy.css";
import Tippy from "@tippyjs/react";
import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";

type Props = {
    message: string;
    multiline?: boolean;
    linegap?: boolean;
    iconSize?: number;
};

const HeaderInfo = ({ message, multiline, linegap, iconSize }: Props) => {
    const content = multiline ? (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            {message.split("\\n").map((fragment, i) => (
                <div
                    key={i}
                    style={{
                        marginTop: !!i && linegap ? "10px" : "0",
                    }}
                >
                    <span>{fragment}</span>
                </div>
            ))}
        </div>
    ) : (
        <span>{message}</span>
    );

    return (
        <Tippy content={content}>
            <Icon className="fas fa-info-circle" iconSize={iconSize} />
        </Tippy>
    );
};

type IconProps = {
    iconSize?: number;
};

const Icon = styled.i<IconProps>`
    margin: auto 0;
    margin-left: 5px;
    color: ${SystemColors.v1.sesame};
    font-size: ${(props) => (props.iconSize ? `${props.iconSize}px` : "")};
`;

export default HeaderInfo;
