import { PurchaseSummaryData } from "@snackpass/snackpass-types";
import { orderBy } from "lodash/fp";
import { useEffect, useState } from "react";

import { ReportGranularity } from "#financial-reports/types";

import { usePurchaseReportTotals } from "src/api/rest/usePurchaseReports";

export const useFinancialReportsData = (granularity: ReportGranularity) => {
    const { days, weeks, months, payouts, isLoading, error } =
        usePurchaseReportTotals();

    const [data, setData] = useState<PurchaseSummaryData[]>([]);

    useEffect(() => {
        switch (granularity) {
            case "week":
                setData(orderBy(["date"], ["desc"], weeks ?? []));
                break;
            case "month":
                setData(orderBy(["date"], ["desc"], months ?? []));
                break;
            case "payout_period":
                setData(orderBy(["date"], ["desc"], payouts ?? []));
                break;
            case "day":
            default:
                setData(orderBy(["date"], ["desc"], days ?? []));
        }
    }, [days, weeks, months, granularity]);

    return { data, isLoading, error };
};
