/** @jsxImportSource @emotion/react */
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { match } from "ts-pattern";
import { Spinner } from "react-activity";

import { getActiveStoreClosedUntil } from "src/redux/selectors";
import { Button } from "src/@/components/ui/button";

import { ReactComponent as PauseIcon } from "../../../../assets/icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/play.svg";

type PauseOrdersButtonProps = {
    handleClick: () => void;
    isLoading?: boolean;
};

export const PauseOrdersButton: FC<PauseOrdersButtonProps> = ({
    handleClick,
    isLoading,
}) => {
    const closedUntil = useSelector(getActiveStoreClosedUntil);
    const label = useMemo(
        () =>
            closedUntil ? (
                <span>Resume Orders</span>
            ) : (
                <span className="text-red-800">Pause Orders</span>
            ),
        [closedUntil],
    );

    const icon = match({ isClosed: !!closedUntil, isLoading })
        .with({ isLoading: true }, () => (
            <span data-testid="spinner" className="flex items-center">
                <Spinner size={8} />
            </span>
        ))
        .with({ isClosed: true }, () => (
            <PlayIcon data-testid="play-icon" className="h-4 w-4" />
        ))
        .with({ isClosed: false }, () => (
            <PauseIcon data-testid="pause-icon" className="h-4 w-4" />
        ))
        .otherwise(() => <span className="h-4 w-4"></span>);

    return (
        <Button
            variant="outline"
            onClick={handleClick}
            size="sm"
            className="flex items-center space-x-1"
            data-testid="button-wrapper"
            disabled={isLoading}
        >
            {icon}
            <span data-testid="button-text" className="hidden md:block">
                {label}
            </span>
        </Button>
    );
};
