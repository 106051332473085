import { useCallback } from "react";
import { toast } from "sonner";

import { StoreMenu } from "src/api/graphql/generated/types";
import { useDeleteMenu } from "#menu-manager/hooks";

import { ConfirmActionDialog } from "./ConfirmActionDialog";

export type ConfirmDeleteMenuDialogProps = {
    menu?: Pick<StoreMenu, "id" | "name">;
    close: () => void;
};

export function ConfirmDeleteMenuDialog({
    menu,
    close: _close,
}: ConfirmDeleteMenuDialogProps) {
    const { deleteMenu, loading } = useDeleteMenu();

    const open = !!menu;

    const close = useCallback(() => {
        if (!loading) _close();
    }, [_close, loading]);

    const confirmArchive = useCallback(async () => {
        if (!menu || loading) {
            return;
        }
        await deleteMenu({
            id: menu.id,
        })
            .then(() => {
                toast.success(`Successfully deleted ${menu?.name}.`);
                close();
            })
            .catch((e) =>
                toast.error("Error deleting menu", {
                    description: e?.message,
                }),
            );
    }, [menu, loading, deleteMenu, close]);

    return (
        <ConfirmActionDialog
            open={open}
            close={close}
            confirm={confirmArchive}
            confirming={loading}
            message={`Are you sure you want to delete ${menu?.name ?? "this menu"}?`}
        />
    );
}
