import "antd/dist/antd.css";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SystemColors } from "@snackpass/design-system";
import _ from "lodash";
import styled from "@emotion/styled";

import { ReactComponent as CheckIcon } from "src/assets/icons/checked.svg";
import { ReactComponent as MinusIcon } from "src/assets/icons/minus.svg";
import { ReactComponent as ChevronRightIcon } from "src/assets/icons/chevron-right.svg";
import { ReactComponent as ChevronDownIcon } from "src/assets/icons/chevron-down.svg";
import { FIELD_NAMES } from "#promotion/utils/types";
import { FormTextInput } from "#promotion/components/shared/form-text-input";
import {
    useCategoriesWithProducts,
    useProductEssentialsByID,
} from "#menu/hooks";
import { CategoryWithProducts } from "#menu/domain";

type SelectorProps = {
    fieldName: FIELD_NAMES;
    index?: number; // Used during render of a list of selectors
    /**
     * Whether each item in list should have a custom label. Should directly correlate to multiselect
     */
    labeled?: boolean;
    placeholder?: string;
    description?: string;
};
type MenuProps = {
    fieldName: FIELD_NAMES;
    categories: CategoryWithProducts[];
    index?: number;
};
type CategoryProps = {
    category: CategoryWithProducts;
    inBetween: boolean;
    open: boolean;
    selected: boolean;
    onClickCategory: (id: string) => void;
    onClickProduct: (c_id: string, p_id: string) => void;
    onToggleCategory: (id: string) => void;
    getProductSelected: (id: string) => boolean;
};
type ProductProps = {
    checked: boolean;
    onClick: (id: string) => void;
    id: string;
};
type CheckmarkProps = {
    checked: boolean;
    inBetween?: boolean;
};

export const Checkmark = ({ checked, inBetween }: CheckmarkProps) => (
    <Checkbox
        style={{
            backgroundColor:
                checked || inBetween
                    ? SystemColors.v1.candy50
                    : SystemColors.v1.white,
        }}
    >
        {checked ? (
            <CheckIcon fill={SystemColors.v1.white} width={12} height={9} />
        ) : inBetween ? (
            <MinusIcon fill={SystemColors.v1.white} width={12} height={3} />
        ) : null}
    </Checkbox>
);

const Product = ({ id, checked, onClick }: ProductProps) => {
    const product = useProductEssentialsByID(id);

    if (!product) {
        return null;
    }

    return (
        <ProductContainer onClick={() => onClick(id)}>
            <Checkmark checked={checked} />
            <ProductTitleContainer>
                <ProductTitle>{product.name}</ProductTitle>
            </ProductTitleContainer>
        </ProductContainer>
    );
};

const Category = ({
    category,
    open,
    inBetween,
    selected,
    onClickProduct,
    onClickCategory,
    onToggleCategory,
    getProductSelected,
}: CategoryProps) => {
    const num = category.productIds.length;

    return (
        <CategoryContainer>
            <CategoryInnerContainer
                onClick={() => {
                    onToggleCategory(category.id);
                }}
            >
                <CategorySelectionContainer
                    onClick={(e) => {
                        onClickCategory(category.id);
                        e.stopPropagation();
                    }}
                >
                    <Checkmark checked={selected} inBetween={inBetween} />
                    <CategoryTitleContainer>
                        <CategoryTitle>{category.name}</CategoryTitle>
                        <Subtitle>
                            {num} {num === 1 ? "Item" : "Items"}
                        </Subtitle>
                    </CategoryTitleContainer>
                </CategorySelectionContainer>
                {open ? (
                    <ChevronDownIcon
                        width={20}
                        height={11}
                        fill={SystemColors.v1.gray50}
                    />
                ) : (
                    <ChevronRightIcon
                        width={20}
                        height={16}
                        fill={SystemColors.v1.gray50}
                    />
                )}
            </CategoryInnerContainer>
            {open
                ? category.productIds.map((id) => (
                      <Product
                          id={id}
                          key={id}
                          checked={getProductSelected(id)}
                          onClick={(p) => onClickProduct(category.id, p)}
                      />
                  ))
                : null}
        </CategoryContainer>
    );
};

const Menu = ({ fieldName, categories, index = 0 }: MenuProps) => {
    const { trigger, setValue, watch, clearErrors } = useFormContext();

    const [openCategories, setOpenCategories] = useState<Array<string>>([]);

    const {
        products: selectedProducts,
        categories: selectedCategories,
        label,
    } = watch(fieldName)[index];

    const getCategoryOpen = (id: string) => openCategories.includes(id);

    const getProductSelected = (id: string) => selectedProducts.includes(id);

    const getCategorySelected = (id: string) => selectedCategories.includes(id);

    const getCategoryHasSomeSelectedProducts = (cat: CategoryWithProducts) =>
        cat.productIds.some((p) => selectedProducts.includes(p));

    /** Given a list of products, checks if all productIds in a category are included */
    const getCategoryHasAllSelectedProducts = (
        cat: CategoryWithProducts,
        products: string[],
    ) => cat?.productIds.every((p) => products.includes(p));

    const revalidate = () => {
        clearErrors(fieldName);
        void trigger(fieldName);
    };

    const toggleProductSelect = (c_id: string, p_id: string) => {
        let nextCategories = selectedCategories;
        let nextProducts = selectedProducts;
        // Selecting/Unselecting a product also selects/unselects its category
        if (nextProducts.includes(p_id)) {
            nextProducts = nextProducts.filter((x: string) => x !== p_id);
            nextCategories = nextCategories.filter((x: string) => x !== c_id);
        } else {
            const category = categories.find((cat) => cat.id === c_id);
            nextProducts = nextProducts.concat(p_id);

            if (
                category &&
                getCategoryHasAllSelectedProducts(category, nextProducts)
            ) {
                nextCategories = nextCategories.concat(c_id);
            }
        }

        setValue(`${fieldName}.${index}`, {
            products: nextProducts,
            categories: nextCategories,
            label,
        });
        revalidate();
    };

    const toggleCategorySelect = (id: string) => {
        const category = categories.find((cat) => cat.id === id);
        let nextCategories = selectedCategories;
        let nextProducts = selectedProducts;
        // Selecting/Unselecting a category also selects/unselects all products in that category
        if (nextCategories.includes(id)) {
            nextCategories = nextCategories.filter((x: string) => x !== id);
            nextProducts = nextProducts.filter(
                (x: string) => !category?.productIds.includes(x),
            );
        } else {
            nextCategories = nextCategories.concat(id);

            nextProducts = _.uniq(
                nextProducts.concat(category?.productIds || []),
            );
        }

        setValue(`${fieldName}.${index}`, {
            products: nextProducts,
            categories: nextCategories,
            label,
        });
        revalidate();
    };

    const toggleCategoryOpen = (id: string) => {
        openCategories.includes(id)
            ? setOpenCategories(openCategories.filter((x) => x !== id))
            : setOpenCategories(openCategories.concat(id));
    };

    return (
        <ScrollContainer>
            <Controller
                name={`${fieldName}.${index}`}
                render={() => (
                    <MenuContainer>
                        {categories.map((cat) => (
                            <Category
                                category={cat}
                                key={cat.id}
                                open={getCategoryOpen(cat.id)}
                                selected={getCategorySelected(cat.id)}
                                inBetween={getCategoryHasSomeSelectedProducts(
                                    cat,
                                )}
                                onToggleCategory={toggleCategoryOpen}
                                onClickCategory={toggleCategorySelect}
                                onClickProduct={toggleProductSelect}
                                getProductSelected={getProductSelected}
                            />
                        ))}
                    </MenuContainer>
                )}
            />
        </ScrollContainer>
    );
};

export const FormItemSelector = ({
    fieldName,
    index,
    labeled = false,
    placeholder,
    description = "",
}: SelectorProps) => {
    const categoriesWithProducts = useCategoriesWithProducts();

    return (
        <Container>
            {labeled ? (
                <FormTextInput
                    fieldName={`${fieldName}.${index}.label`} // Need to target the label subproperty of this field
                    name={"Item Label"}
                    descriptor={description}
                    placeholder={placeholder || "Item Label"}
                />
            ) : null}
            <Menu
                fieldName={fieldName}
                categories={categoriesWithProducts}
                index={index}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ScrollContainer = styled.div`
    overflow: hidden;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
    max-width: 768px;
    max-height: 400px;
`;

const MenuContainer = styled.div`
    max-height: 500px;
    padding-bottom: 100px;
    max-width: 768px;
    overflow-y: scroll;
`;

const CategoryContainer = styled.div`
    &:last-child {
        border-bottom: none;
    }
    &:hover {
        cursor: pointer;
    }
`;

const ProductContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    padding: 11px 32px 11px 56px;
    &:hover {
        cursor: pointer;
    }
`;

const CategoryInnerContainer = styled.div`
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${SystemColors.v1.gray80};
`;

const CategorySelectionContainer = styled.span`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const CategoryTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CategoryTitle = styled.h2`
    margin: 0;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;

const ProductTitle = styled.h3`
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
`;

const ProductImage = styled.img`
    border-radius: 8px;
`;

const EmptyProductImage = styled.div`
    border-radius: 8px;
    height: 40px;
    width: 40px;
    background-color: ${SystemColors.v1.gray80};
`;

const Subtitle = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${SystemColors.v1.gray30};
`;

const Checkbox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${SystemColors.v1.gray70};
    border-radius: 4px;
    padding: 6px;
    width: 20px;
    height: 20px;
`;
