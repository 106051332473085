import { ContentState, EditorState } from "draft-js";
import { all, compose } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    DiscountType,
    VariableKind,
    VariableType,
} from "src/api/graphql/generated/types";
import { DiscountTemplate } from "#guestbook/components/incentives/constants";

import {
    DiscountData,
    getMessageInput,
    getMessageInputVariables,
    MessageVariable,
    ProductData,
    setMessageInputMessage,
    TemplateVariableType,
} from "../../redux";

import { DECORATORS } from "./editor-decorators";

export enum GuestbookInputType {
    CONVERSATION = "Conversation",
    MESSSAGE = "Message",
}

type ValidatorResponse = { isValid: boolean; message: string | null };

export const useInitializeEditorState = () => {
    const dispatch = useDispatch();
    const editorState = useSelector(getMessageInput);
    const setEditorState = compose(dispatch, setMessageInputMessage);

    useEffect(() => {
        if (!editorState) {
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromText(""),
                    DECORATORS,
                ),
            );
        }
    }, [editorState]);
};

const _allVariablesSelected = (variables: MessageVariable[]) =>
    all((v: MessageVariable) => !!v.data, variables);

// Note: does not validate audience, that is done
// separately that way we do not disable the send button
// which would make the user confused about what to do. instead if they click it,
// it will prompt them to select an audience
export const useMessageValidator = (): ValidatorResponse => {
    const message = useSelector(getMessageInput);
    const variables = useSelector(getMessageInputVariables);

    if (!message) {
        return { isValid: false, message: "Please fill out message." };
    }

    if (!variables || !_allVariablesSelected(variables)) {
        return { isValid: false, message: "Please select variables." };
    }

    return { isValid: true, message: null };
};

const KIND_MAPPING: Record<TemplateVariableType, VariableKind> = {
    PRODUCT: VariableKind.Product,
    REWARD: VariableKind.Reward,
    TEXT: VariableKind.Text,
};

const TYPE_MAPPING: Record<TemplateVariableType, VariableType> = {
    PRODUCT: VariableType.Product,
    REWARD: VariableType.Discount,
    TEXT: VariableType.Announcement,
};

const discountToData = (discountTemplate: DiscountTemplate) => ({
    reward: {
        discount: {
            newPrice: discountTemplate.discount.newPrice || 0,
            dollarsOff: discountTemplate.discount.dollarsOff || 0,
            percentOff: discountTemplate.discount.percentOff || 0,
            // Note: only support percent based discounts atm,
            // but if that changes then we'll just need to pass the discount
            // type to message variables and then all the way through to this and then
            // should be good
            type: DiscountType.Percent,
        },
        products: (discountTemplate.products || []).map((p) => ({
            productId: p.id,
            imageUrl: p.imageUrl,
            name: p.label,
        })),
        storewide: discountTemplate.storewide || false,
    },
});

const productToData = (productData: ProductData) => ({
    product: {
        productId: productData.product._id,
        imageUrl: productData.product.image || null,
        name: productData.product.name,
    },
});

const toData = (v: MessageVariable) => {
    switch (v.type) {
        case TemplateVariableType.Product:
            return productToData(v.data as ProductData);
        case TemplateVariableType.Reward:
            return discountToData((v.data as DiscountData).template);
        default:
            return null;
    }
};

export const toVariable = (v: MessageVariable): any => ({
    variableId: v.id,
    variable: {
        kind: KIND_MAPPING[v.type],
        data: toData(v),
        label: v.data?.label,
    },
    placeholder: v.placeholder,
    type: TYPE_MAPPING[v.type],
});
