import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";
import { AdjustedAddonGroup } from "../adjusted-addon";

//TODO: delete we have form Validation
class AddonGroupNameCheck implements IMenuAutomationCheck<AdjustedAddonGroup> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "AddonGroupNameCheck";
    }

    get itemToCheck(): ItemType {
        return "addonGroup";
    }

    checkItems() {
        return [];
    }

    checkItem(item: AdjustedAddonGroup): MenuIssue<AdjustedAddonGroup> | null {
        if (item.name === "New Addon Group") {
            return new MenuIssue(
                "InvalidAddonGroupName",
                "Addon groups cannot be named 'New Addon Group'",
                "critical",
                item,
            );
        }
        return null;
    }
}

export { AddonGroupNameCheck };
