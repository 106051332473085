import { FulfillmentTypeEnum, IProduct } from "@snackpass/snackpass-types";
import { Space } from "antd";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import { TaxOverrideInput } from "#menu-editor/multi-menus/shared-components/tax-override-input";
import {
    extractTaxPolicies,
    fulfillmentPropertyMap,
    getFulfillmentTaxes,
} from "#menu-editor/multi-menus/helpers";
import { isNotUndefined } from "#core";
import {
    StyledTextField,
    textFieldProps,
} from "#menu-editor/multi-menus/screens/edit-overrides-mobile/components/text-field";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";

interface TaxOverrideMobileInput {
    product: IProduct;
}

export const TaxesOverrideMobileInput = ({
    product,
}: TaxOverrideMobileInput) => {
    const activeStore = useSelector(getActiveStore);
    const getProductBaseTaxes = (product: IProduct) =>
        !isNotUndefined(product.taxInfo)
            ? activeStore?.taxRate ?? 0
            : product.taxInfo.rate;
    return (
        <Space
            size={"middle"}
            direction="vertical"
            style={commonStyles.displayFlex}
        >
            {Object.values(FulfillmentTypeEnum).map((fulfillment) => (
                <TaxOverrideInput
                    item={{
                        ...product,
                        taxInfo: {
                            rate: getFulfillmentTaxes(
                                getProductBaseTaxes(product),
                                extractTaxPolicies(product),
                                fulfillment,
                            ),
                        },
                    }}
                    fulfillment={fulfillmentPropertyMap(fulfillment)}
                    inputComponent={StyledTextField}
                    inputComponentProps={textFieldProps}
                />
            ))}
        </Space>
    );
};
