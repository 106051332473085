import { Link } from "react-router-dom";

import { Routes } from "#navigation/routes";
import {
    MenuInsightsItemRow,
    MenuItem,
} from "#reports/menu-item-insights/types";

export const MENU_SALES_TOOLTIP_COPY = (
    <>
        Net sales does not include cart adjustments and fees. See{" "}
        <Link to={Routes.ReportsSalesSummary}>Sales Report</Link> for overall
        net sales figure.
    </>
);

export const aggregateMenuInsightsItemRows = (
    row1: MenuInsightsItemRow,
    row2: MenuInsightsItemRow,
): MenuInsightsItemRow => {
    const mergedItems: MenuItem[] = [];

    // Merge items from row1 into mergedItems
    row1.items.forEach((item1) => {
        const matchingItem = row2.items.find(
            (item2) => item2.categoryName === item1.categoryName,
        );

        if (matchingItem) {
            mergedItems.push({
                id: item1.id,
                name: item1.name,
                categoryName: item1.categoryName,
                sales: item1.sales + matchingItem.sales,
                orders: item1.orders + matchingItem.orders,
                modifiers: item1.modifiers,
            });
        } else {
            mergedItems.push(item1);
        }
    });

    // Add items from row2 that are not already in mergedItems
    row2.items.forEach((item2) => {
        const matchingItem = mergedItems.find(
            (mergedItem) => mergedItem.categoryName === item2.categoryName,
        );

        if (!matchingItem) {
            mergedItems.push(item2);
        }
    });

    return {
        date: row1.date,
        items: mergedItems,
    };
};
