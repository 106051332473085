import React, { useCallback, useState } from "react";
import { Spinner } from "react-activity";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import Skeleton from "react-loading-skeleton";
import { ColumnDef } from "@tanstack/react-table";
import QRCode from "qrcode.react";
import { compareAsc } from "date-fns";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { useTables } from "#qr-code/hooks/useTables";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { DineInTable, formatDownloadLink } from "#qr-code/lib";
import { Button } from "src/@/components/ui/button";
import { downloadQR } from "#utils/download-qr";
import { getActiveStore } from "src/redux/selectors";
import { Input } from "src/@/components/ui/input";
import { useCreateTable } from "#qr-code/hooks/useCreateTable";
import { useRemoveTable } from "#qr-code/hooks/useRemoveTable";

export const TableQrCodeSection = () => {
    const viewOnly = !useHasEditSettingsForActiveStore();
    const [tableString, setTableString] = useState<string>("");
    const [currentlyRemovingTable, setCurrentRemovingTable] = useState<
        string | null
    >(null);

    const activeStore = useSelector(getActiveStore);

    const { tables, isLoading, refetch } = useTables();
    const { createTable, isLoading: isCreating } = useCreateTable();
    const { removeTable, isLoading: isDeleting } = useRemoveTable();

    const removeDineInTable = useCallback(
        async (table: DineInTable) => {
            if (!isDeleting) {
                setCurrentRemovingTable(table?._id);
                void removeTable(table._id).then(async () => refetch());
            }
        },
        [
            currentlyRemovingTable,
            setCurrentRemovingTable,
            isDeleting,
            removeTable,
            refetch,
        ],
    );

    const createDineInTable = useCallback(async () => {
        if (!isCreating) {
            void createTable(tableString).then(() => {
                void refetch();
                setTableString("");
            });
        }
    }, [tableString, isDeleting, createTable, refetch]);

    const columns: ColumnDef<DineInTable>[] = [
        {
            id: "Table Number",
            accessorKey: "tableNumber",
            header: ({ column }) => (
                <DataTableColumnHeader
                    buttonClassName="bg-transparent"
                    hideIconUntilSorted
                    column={column}
                    title="Table Number"
                />
            ),
        },
        {
            id: "Created At",
            accessorKey: "createdAt",
            header: ({ column }) => (
                <DataTableColumnHeader
                    buttonClassName="bg-transparent"
                    hideIconUntilSorted
                    column={column}
                    title="Created At"
                />
            ),
            sortingFn: (a, b) =>
                compareAsc(
                    new Date(a.original.createdAt),
                    new Date(b.original.createdAt),
                ),
            cell: (info) =>
                DateTime.fromISO(info.row.original.createdAt).toFormat(
                    "M/d, h:mm a",
                ),
        },
        {
            header: "",
            id: "Actions",
            cell: ({ row }) => (
                <div className="flex items-center justify-end space-x-1">
                    <Button
                        onClick={() =>
                            downloadQR(
                                row.original.tableNumber,
                                row.original.tableNumber,
                            )
                        }
                    >
                        Download QR
                    </Button>

                    {!viewOnly ? (
                        <Button
                            onClick={() => {
                                void removeDineInTable(row.original);
                            }}
                            disabled={isDeleting}
                            variant="destructive"
                        >
                            {isDeleting &&
                            row.original._id === currentlyRemovingTable ? (
                                <Spinner size={12} />
                            ) : (
                                <>Delete</>
                            )}
                        </Button>
                    ) : null}
                    <div style={{ display: "none" }}>
                        <QRCode
                            id={row.original.tableNumber}
                            value={formatDownloadLink(
                                activeStore,
                                row.original.tableNumber,
                            )}
                            size={160}
                        />
                    </div>
                </div>
            ),
        },
    ];

    if (isLoading) {
        return <Skeleton className="h-96 w-full" />;
    }

    return (
        <div>
            <div className="mb-6 flex items-center justify-between">
                <h3 className="text-large">Dine-in Tables</h3>
                {!viewOnly ? (
                    <div className="flex space-x-2 ">
                        <Input
                            value={tableString}
                            onChange={(e) => setTableString(e.target.value)}
                            placeholder={"New Table Number"}
                            className="w-48"
                            disabled={isCreating}
                        />
                        <Button
                            variant="outline"
                            onClick={createDineInTable}
                            disabled={isCreating || tableString.length <= 0}
                        >
                            {isCreating ? (
                                <Spinner size={12} />
                            ) : (
                                <PlusCircledIcon className="h-4 w-4" />
                            )}
                        </Button>
                    </div>
                ) : (
                    <span></span>
                )}
            </div>

            <DataTable columns={columns} data={tables ?? []} showPagination />
        </div>
    );
};
