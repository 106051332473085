import { Route, Switch } from "react-router-dom";

import { MenuManagerRoutes } from "#menu-manager/routes";
import {
    SpecialMenuScreen,
    NewMenuScreen,
    EditMenuScreen,
} from "#menu-manager/screens";

export function MenuManager() {
    return (
        <Switch>
            <Route
                path={MenuManagerRoutes.NEW_MENU}
                component={NewMenuScreen}
            />
            <Route
                path={MenuManagerRoutes.EDIT_MENU}
                component={EditMenuScreen}
            />
            <Route
                path={MenuManagerRoutes.INDEX}
                component={SpecialMenuScreen}
            />
        </Switch>
    );
}
