import { PayoutHistoryItem } from "src/api/rest";

// A miniature redux reducer for managing the state of the payout history screen.

export type PayoutHistoryState = {
    data: PayoutHistoryItem[];
    isLoading: boolean;
    isGlobalDurationSet: boolean;
    controller: AbortController | null;
    error: boolean;
    errorMessage: string;
};

export enum ActionType {
    IS_GLOBAL_DURATION_SET,
    GET_DATA,
    SET_DATA,
    ERROR,
}

type Action =
    | { type: ActionType.IS_GLOBAL_DURATION_SET }
    | { type: ActionType.GET_DATA; controller: AbortController }
    | { type: ActionType.SET_DATA; data: PayoutHistoryItem[] }
    | { type: ActionType.ERROR; errorMessage: string };

export const payoutHistoryInitialState: PayoutHistoryState = {
    data: [],
    isLoading: true,
    isGlobalDurationSet: false,
    controller: null,
    error: false,
    errorMessage: "",
};

export const payoutHistoryReducer = (
    state: PayoutHistoryState,
    action: Action,
) => {
    switch (action.type) {
        case ActionType.IS_GLOBAL_DURATION_SET:
            return {
                ...state,
                isGlobalDurationSet: true,
            };
        case ActionType.GET_DATA:
            return {
                ...state,
                isLoading: true,
                controller: action.controller,
                error: false,
                errorMessage: "",
            };
        case ActionType.SET_DATA:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                controller: null,
                error: false,
                errorMessage: "",
            };
        case ActionType.ERROR:
            return {
                data: [],
                isLoading: false,
                isGlobalDurationSet: true,
                controller: null,
                error: true,
                errorMessage: action.errorMessage,
            };
        default:
            return state;
    }
};
