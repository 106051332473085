import React from "react";

import { AccountDetailsEdit } from "#settings/components/account-details-edit";
import { PendingInvites } from "#settings/components/pending-invites";
import { UseGetInvites } from "#settings/hooks/use-get-invites";

const AccountDetails = () => {
    const { invites, refetch } = UseGetInvites();

    return (
        <div className="m-4 md:m-12">
            <PendingInvites invites={invites} refetch={refetch} />
            <AccountDetailsEdit />
        </div>
    );
};

export default AccountDetails;
