import { Link } from "react-router-dom";
import { Button } from "src/@/components/ui/button";
import { ChevronRight } from "lucide-react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
} from "src/@/components/ui/card";
import Skeleton from "react-loading-skeleton";

interface ChartCardProps {
    title: string;
    description?: string;
    to?: string;
    isLoading?: boolean;
    children: React.ReactNode;
}

export function ChartCard({
    title,
    description,
    to,
    isLoading,
    children,
    ...props
}: ChartCardProps) {
    return (
        <Card {...props}>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <div>
                        <CardTitle className="text-sm font-medium text-muted-foreground">
                            {title}
                        </CardTitle>
                        {description && (
                            <CardDescription>{description}</CardDescription>
                        )}
                    </div>
                    {to && (
                        <Link to={to} className="no-underline">
                            <Button
                                variant="outline"
                                size="icon"
                                className="h-8 w-8 text-muted-foreground hover:text-muted-foreground"
                            >
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                        </Link>
                    )}
                </div>
            </CardHeader>
            <CardContent>
                {isLoading ? (
                    <Skeleton className="h-[240px] w-full" />
                ) : (
                    children
                )}
            </CardContent>
        </Card>
    );
}
