import * as React from "react";
import { DateRange } from "react-day-picker";
import { useMemo, useState } from "react";
import { isSameDay } from "date-fns";

import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar.svg";
import { FilterSelect } from "src/@/components/ui/filter-select";

import { cn } from "../lib/utils";

import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

/**
 * A reusable range picker component based on the range picker and filter picker components.
 * Includes a custom date option that lets you select a custom range.
 */
export function RangePicker({
    className,
    selected,
    onSelect,
    disableFutureDates = false,
    subtitle,
    options,
}: Props) {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const onSelectCustomValue = (value?: DateRange) => {
        if (!value) {
            // TODO: When react-day-picker supports the "required" prop for range pickers, this case can be removed.
            // by default, react-day-picker un-selects the range when you try to click the start date of the selected range.
            // we can interpret this as the user trying to select only the start date.
            onSelect({
                from: selected.from,
                to: selected.from,
            });
        } else if (!value?.to) {
            // for just a single day, we set our range to be both of one day.
            onSelect({
                from: value.from,
                to: value.from,
            });
        } else {
            onSelect(value);
        }
    };

    const _options = useMemo(
        () => [
            ...options.map((e) => ({
                label: e.label,
                value: e.label,
            })),
            {
                label: "Custom",
                value: CUSTOM_VALUE,
            },
        ],
        [options],
    );

    const selectedOption = useMemo(
        () =>
            options.find(
                (e) =>
                    isSameDay(
                        e.value.from ?? new Date(),
                        selected.from ?? new Date(),
                    ) &&
                    isSameDay(
                        e.value.to ?? new Date(),
                        selected.to ?? new Date(),
                    ),
            )?.label ?? CUSTOM_VALUE,
        [options, selected],
    );

    return (
        <div className={cn("flex items-center", className)}>
            <Popover onOpenChange={setIsDatePickerOpen} open={isDatePickerOpen}>
                <PopoverTrigger className="invisible w-0" />
                <PopoverContent
                    className="w-auto p-0"
                    align="start"
                    avoidCollisions={false}
                    onFocusOutside={(onFocusOutsideEvent) => {
                        onFocusOutsideEvent.preventDefault();
                    }}
                >
                    <Calendar
                        initialFocus
                        defaultMonth={selected.from || new Date()}
                        mode="range"
                        selected={selected}
                        onSelect={onSelectCustomValue}
                        numberOfMonths={2}
                        disabled={
                            disableFutureDates
                                ? {
                                      after: new Date(),
                                  }
                                : undefined
                        }
                    />
                </PopoverContent>
            </Popover>
            <FilterSelect
                customButton={
                    <Button
                        variant="outline"
                        size="sm"
                        className="flex items-center space-x-2"
                    >
                        <CalendarIcon className="h-4 w-4" />
                        <span className="hidden md:block">
                            {selectedOption}
                        </span>
                    </Button>
                }
                title={`${subtitle ? subtitle + " " : ""}${selectedOption}`}
                selectedValues={new Set([selectedOption])}
                options={_options}
                onOptionSelected={(value: string) => {
                    if (value === CUSTOM_VALUE) {
                        setIsDatePickerOpen(true);
                        setIsOpen(false);
                    }
                    const newSelectedOption = options.find(
                        (e) => e.label === value,
                    );
                    if (!newSelectedOption) return;

                    if (newSelectedOption.onSelectOverride) {
                        newSelectedOption.onSelectOverride?.();
                    } else {
                        onSelect(newSelectedOption.value);
                    }
                }}
                open={isOpen}
                onOpenChange={setIsOpen}
            />
        </div>
    );
}

const CUSTOM_VALUE = "Custom";

type Props = {
    className?: string;
    selected: DateRange;
    onSelect: (value: DateRange) => void;
    disableFutureDates?: boolean;
    subtitle?: string;
    options: {
        label: string;
        value: DateRange;
        onSelectOverride?: () => void;
    }[];
};
