import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";
import { ChartContainer, ChartTooltip } from "src/@/components/ui/chart";
import { ChartCard } from "./chart-card";
import { useTopItemsChart } from "#dashboard/hooks/use-top-items-chart";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";

const topItemsChartConfig = {
    total: {
        label: "Total Orders",
        color: "hsl(var(--primary))",
    },
} as const;

export const TopItemsChart = () => {
    const { topItems, isLoading } = useTopItemsChart();
    const { currentPeriodStartEnd } = useDashboardContext();

    const startDate = currentPeriodStartEnd?.startDate?.format("MM/DD/YY");
    const endDate = currentPeriodStartEnd?.endDate?.format("MM/DD/YY");

    return (
        <ChartCard
            title="Top Items"
            description={
                startDate && endDate ? `${startDate} - ${endDate}` : undefined
            }
            to="/item-insights"
            isLoading={isLoading}
        >
            <ChartContainer config={topItemsChartConfig}>
                <BarChart
                    data={topItems}
                    layout="vertical"
                    margin={{
                        top: 0,
                        right: 12,
                        bottom: 0,
                        left: 70,
                    }}
                >
                    <CartesianGrid horizontal={false} />
                    <XAxis
                        type="number"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                        tickFormatter={(value) => `$${value.toLocaleString()}`}
                    />
                    <YAxis
                        type="category"
                        dataKey="displayName"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                    />
                    <ChartTooltip
                        cursor={false}
                        content={({ active, payload }) => {
                            if (!active || !payload?.length) return null;
                            const data = payload[0].payload;
                            return (
                                <div className="space-y-2 rounded-lg border bg-white p-2 shadow-md">
                                    <div className="font-medium">
                                        {data.fullName}
                                    </div>
                                    <div className="text-muted-foreground">
                                        ${data.revenue.toFixed(2)} revenue
                                    </div>
                                    <div className="text-muted-foreground">
                                        {data.orders} orders
                                    </div>
                                </div>
                            );
                        }}
                    />
                    <Bar dataKey="revenue" radius={[0, 4, 4, 0]}>
                        {topItems.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Bar>
                </BarChart>
            </ChartContainer>
        </ChartCard>
    );
};
