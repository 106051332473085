import React, { CSSProperties, useCallback } from "react";
import { SystemColors } from "@snackpass/design-system";
import { css } from "@emotion/css";
import { noop } from "lodash";

import { BlackPopover } from "./black-popover";

type CircleButtonProps = {
    isLoading?: boolean;
    icon: any;
    onClick: () => Promise<void> | void;
    iconStyle?: CSSProperties;
    buttonStyle?: CSSProperties;
    disabled?: boolean;
    disabledColor?: string;
    hasPopover?: boolean;
    popOverTitle?: string;
};

function CircleButton({
    isLoading,
    disabled,
    icon,
    onClick,
    iconStyle,
    buttonStyle,
    disabledColor,
    hasPopover = false,
    popOverTitle,
}: CircleButtonProps) {
    const [loading, setLoading] = React.useState(false);

    const _onSubmit = useCallback(async () => {
        setLoading(true);

        try {
            await onClick();
        } finally {
            setLoading(false);
        }
    }, [onClick]);

    // if the state of this component is loading OR
    // loading is passed as a prop it is loading. this way don't have to pass in loading
    // from outside but you can if you want (ex if there is more loading than just the onclick)
    const blockClicking = disabled || loading || isLoading;

    const Btn = (
        <button
            disabled={blockClicking}
            onClick={() => {
                void _onSubmit();
            }}
            className={styles}
            style={{
                opacity: blockClicking ? 0.5 : 1,
                backgroundColor: disabled ? disabledColor : undefined,
                ...buttonStyle,
            }}
        >
            <img
                style={{
                    width: 17,
                    objectFit: "contain",
                    ...iconStyle,
                }}
                src={icon}
            />
        </button>
    );

    if (hasPopover) {
        return (
            <BlackPopover
                label={popOverTitle || ""}
                onClick={noop}
                trigger="hover"
            >
                {Btn}
            </BlackPopover>
        );
    }

    return Btn;
}

const styles = css`
    border-radius: 25px;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 5px;
    margin: 3px 3px;
    background-color: ${SystemColors.v1.candy50};
    cursor: pointer;
    flex-shrink: 0;
    box-shadow: none;
    &:hover {
        background-color: ${SystemColors.v1.candy50};
        opacity: 0.7;
        box-shadow: none;
    }
`;

export default CircleButton;
