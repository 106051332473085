/** @jsxImportSource @emotion/react */
import { Button, Modal } from "react-bootstrap";
import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { IProduct, Menu as MultiMenu } from "@snackpass/snackpass-types";
import { Checkbox, Row } from "antd";

import {
    getMultiMenuLoadingState,
    getStoreMultiMenus,
} from "#menu-editor/multi-menus/redux/selectors";
import { ReactComponent as XIcon } from "src/assets/icons/close-x.svg";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import colors from "#reusable/colors/colors.json";
import { useAppDispatch } from "src/redux/hooks";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    productInEdit: IProduct | null;
};

const MenuUntrackedFieldsList = ({
    multiMenu,
    productInEdit,
    onToggleProduct,
    onToggleAddon,
}: {
    multiMenu: MultiMenu;
    productInEdit: IProduct;
    onToggleProduct: (menuId: string, productId: string) => void;
    onToggleAddon: (menuId: string, addonId: string) => void;
}) => (
    <>
        <Text type="body-bold">{`"${multiMenu.name}" menu untracked fields:`}</Text>
        <StyledCheckboxGroup>
            {multiMenu.menuOverrides?.products?.[productInEdit._id] ? (
                <Row>
                    <Checkbox
                        value={productInEdit._id}
                        onChange={(e) =>
                            onToggleProduct(multiMenu.id, e.target.value)
                        }
                    >
                        {"Reset product overrides"}
                    </Checkbox>
                </Row>
            ) : null}
            {productInEdit.addonGroups.flatMap(
                ({ name: addonGroupName, addons }) =>
                    addons.map((addon) =>
                        multiMenu.menuOverrides?.addons?.[addon._id] ? (
                            <Row>
                                <Checkbox
                                    value={addon._id}
                                    onChange={(e) =>
                                        onToggleAddon(
                                            multiMenu.id,
                                            e.target.value,
                                        )
                                    }
                                >{`Reset addon "${addonGroupName}-${addon.name}" overrides`}</Checkbox>
                            </Row>
                        ) : null,
                    ),
            )}
        </StyledCheckboxGroup>
    </>
);

export const TrackProductFieldsModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    productInEdit,
}) => {
    const dispatch = useAppDispatch();
    const storeMultiMenus = useSelector(getStoreMultiMenus);
    const loadingState = useSelector(
        getMultiMenuLoadingState("UpdateOverrides"),
    );
    const [fieldsToReset, setFieldsToReset] = useState(
        {} as Record<
            string,
            {
                productId?: string;
                addonIds?: string[];
            }
        >,
    );
    useEffect(() => {
        if (!isModalOpen) setFieldsToReset({});
    }, [isModalOpen]);

    const toggleResetProduct = (menuId: string, productId: string) =>
        setFieldsToReset((prev) => ({
            ...prev,
            [menuId]: {
                ...prev[menuId],
                productId: !prev[menuId]?.productId ? productId : undefined,
            },
        }));

    const toggleResetAddons = (menuId: string, addonId: string) =>
        setFieldsToReset((prev) => ({
            ...prev,
            [menuId]: {
                ...prev[menuId],
                addonIds: prev[menuId]?.addonIds?.includes(addonId)
                    ? prev[menuId]?.addonIds?.filter((id) => id !== addonId)
                    : [...(prev[menuId]?.addonIds || []), addonId],
            },
        }));

    if (!productInEdit) return null;

    const multiMenusWithUntrackedFields = storeMultiMenus.filter(
        ({ menuOverrides }) =>
            !!menuOverrides?.products?.[productInEdit._id] ||
            productInEdit.addonGroups
                .flatMap(({ addons }) => addons.map(({ _id }) => _id))
                .some((addonId) => !!menuOverrides?.addons?.[addonId]),
    );

    const onSubmit = async () =>
        dispatch(
            multiMenuThunks.resetProductFields({
                storeId: productInEdit.store._id,
                menuFields: Object.entries(fieldsToReset)
                    .filter(
                        ([menuId, params]) =>
                            params.productId || params.addonIds?.length,
                    )
                    .map(([menuId, params]) => ({
                        menuId,
                        ...params,
                    })),
            }),
        );

    return (
        <Modal show={isModalOpen} css={modalStyles} centered>
            <Modal.Header>
                <CloseButton
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                >
                    <XIcon />
                </CloseButton>
                <Modal.Title>
                    <p className="header-title">{"Untracked fields"}</p>
                </Modal.Title>
                <div className="header-subtitle-container">
                    <p className={`header-subtitle`}>
                        {`There are some menus with variations of "${productInEdit.name}", do you want to reset them?`}
                    </p>
                    {multiMenusWithUntrackedFields.map((menu) => (
                        <FieldListWrapper>
                            <MenuUntrackedFieldsList
                                multiMenu={menu}
                                productInEdit={productInEdit}
                                onToggleAddon={toggleResetAddons}
                                onToggleProduct={toggleResetProduct}
                            />
                        </FieldListWrapper>
                    ))}
                </div>
            </Modal.Header>
            <Modal.Footer>
                <Button
                    className="okay-btn"
                    onClick={async () => {
                        await onSubmit();
                        setIsModalOpen(false);
                    }}
                >
                    {loadingState !== "pending" ? "Okay" : "Saving ..."}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const modalStyles = css`
    font-family: "Inter";
    font-style: normal;

    .modal-content {
        border-radius: 16px;
    }
    .modal-header {
        display: flex;
        flex-direction: column;
        align-items: inherit;
    }
    .header-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #282d32;
        margin: 24px;
        text-align: center;
    }
    .header-subtitle {
        color: #606c76;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 0px 24px 24px 24px;
    }
    .failed {
        color: #ff3929;
    }
    .okay-btn {
        flex: auto;
        background: #0077ff;
        border-radius: 8px;
    }
    .modal-footer {
        justify-content: start;
    }
`;

const CloseButton = styled.button`
    width: 36px;
    height: 36px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 48px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FieldListWrapper = styled.div`
    margin-left: 50px;
    margin-bottom: 20px;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
    width: 100%;
    margin-left: 10px;
`;
