import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import DropDownSelect, { Options } from "#reusable/select/dropdown";
import colors from "#reusable/colors/colors.json";

type Props = {
    options: Options[];
    fieldName: string;
    name?: string;
    descriptor?: string;
    inputLabel?: string;
    divider?: boolean;
    /** Whether to allow for custom input from user */
    allowCustom?: boolean;
    /** Value to set on clear in null case (only applicable for allowCustom) */
    customClearValue?: any;
    /** Function to set how label should appear for a given value. (Note: This will overwrite label field set on Options) */
    formatOptionLabel?: (value: any) => string;
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    disabled?: boolean;
};

type ContainerProps = {
    divider: boolean;
};

export const FormDropdownSelect = ({
    options,
    name,
    descriptor,
    inputLabel,
    fieldName,
    required = false,
    recommended = false,
    autofilled = false,
    divider = false,
    allowCustom = false,
    disabled = false,
    customClearValue,
    formatOptionLabel,
}: Props) => {
    const { control, trigger } = useFormContext();

    const findOption = (value: string) =>
        options.find((i) => i.value === value) || { label: value, value };

    const _formatOptionLabel = (option: Options) =>
        formatOptionLabel ? formatOptionLabel(option.value) : option.value;

    // Trigger initial validation
    useEffect(() => {
        trigger(fieldName);
    }, []);

    return (
        <Container divider={divider}>
            {name || descriptor ? (
                <TitleContainer>
                    <FormFieldName
                        name={name}
                        required={required}
                        recommended={recommended}
                        autofilled={autofilled}
                    />
                    <FormFieldDescriptor descriptor={descriptor} />
                </TitleContainer>
            ) : null}
            <InputContainer disabled={disabled}>
                {inputLabel ? <Label>{inputLabel}</Label> : null}
                <Controller
                    control={control}
                    name={fieldName}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <>
                            {allowCustom ? (
                                <DropDownSelect
                                    isCreatable
                                    options={options}
                                    formatOptionLabel={_formatOptionLabel}
                                    square
                                    value={findOption(value)}
                                    onChange={(e: Options | null) => {
                                        onChange(
                                            Number(e?.value) ||
                                                (customClearValue ?? ""),
                                        );
                                    }}
                                    placeholder="Select"
                                    height="48px"
                                    isSearchable
                                    disabled={disabled}
                                />
                            ) : (
                                <DropDownSelect
                                    options={options}
                                    square
                                    value={findOption(value)}
                                    onChange={(e: Options) => onChange(e.value)}
                                    placeholder="Select"
                                    height="48px"
                                    isSearchable={false}
                                    disabled={disabled}
                                />
                            )}
                            {error?.message ? (
                                <ErrorMessage>{error?.message}</ErrorMessage>
                            ) : null}
                        </>
                    )}
                />
            </InputContainer>
        </Container>
    );
};

const Container = styled.div<ContainerProps>`
    display: flex;
    flex: 1;
    gap: 16px;
    margin-bottom: 24px;
    align-items: center;
    ${(props) =>
        props.divider &&
        `padding-bottom: 24px; border-bottom: 1px solid ${colors["neutral-400"]};`}
    @media ${ScreenState.MOBILE} {
        flex-direction: column;
        width: 100%;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    max-width: 50%;
    width: 100%;
    flex-direction: column;
    @media ${ScreenState.MOBILE} {
        max-width: 100%;
        width: 100%;
    }
`;

const InputContainer = styled.div<{ disabled: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    min-width: 100px;
    width: 100%;
    ${({ disabled }) =>
        disabled &&
        `&:hover {
        cursor: not-allowed;
    }`}

    /* Really hacky way to target the dropdown border within react-dropdown-select */
    span,
    div {
        border-color: ${colors["neutral-400"]};
    }
`;

const Label = styled.p`
    position: absolute;
    left: 12px;
    top: -6px;
    margin: 0;
    padding: 0 2px 0 2px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${SystemColors.v1.gray50};
    background-color: white;
    z-index: 5;
`;

const ErrorMessage = styled.p`
    margin: 0;
    margin-top: 4px;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
`;
