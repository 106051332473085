import styled, { css } from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";

import colors from "#reusable/colors/colors.json";

// To make this button an icon button
// pick an icon from reusable-component/icons and wrap it in this component.

type Props = {
    variant: "filled" | "outline" | "danger";
    squareBtn?: boolean;
    sm?: boolean;
    fullWidth?: boolean;
    mobileFullWidth?: boolean;
    ml?: number;
    mr?: number;
    checkBox?: boolean;
    checked?: boolean;
    fontSize?: number;
    disabled?: boolean;
};

const Button = styled.span<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid
        ${(props) =>
            props.variant === "danger"
                ? SystemColors.v1.melon50
                : props.checked
                  ? SystemColors.v1.candy50
                  : `${colors["neutral-400"]}`};
    border-radius: ${(props) => (props.squareBtn ? "8px" : "56px")};
    background: ${(props) =>
        props.variant === "filled" && !props.disabled
            ? SystemColors.v1.candy50
            : "none"};
    color: ${(props) =>
        props.disabled
            ? SystemColors.v1.gray40
            : props.variant === "danger"
              ? SystemColors.v1.melon50
              : props.variant === "filled" && !props.disabled
                ? SystemColors.v1.white
                : props.disabled
                  ? SystemColors.v1.gray40
                  : SystemColors.v1.sesame};
    font-weight: 600;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
    padding: 2px 14px;
    margin-left: ${(props) => (props.ml ? `${props.ml}px` : "0")};
    margin-right: ${(props) => (props.mr ? `${props.mr}px` : "0")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    height: ${(props) => (props.sm ? "36px" : "45px")};
    width: ${(props) => (props.fullWidth ? "auto" : "fit-content")};
    @media ${ScreenState.MOBILE} {
        font-size: 1rem;
        height: 36px;
    }
    height: 39px;
    &:hover {
        opacity: ${(props) => (props.disabled ? 1 : 0.8)};
    }
    color: ${(props) =>
        props.checkBox && props.checked ? SystemColors.v1.candy50 : ""};
    ${(props) =>
        props.mobileFullWidth &&
        css`
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        `}
`;

export default Button;
