import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Spinner } from "react-activity";
import { useMutation } from "@tanstack/react-query";
import { CashDrawerWithPrinter, DeviceType } from "@snackpass/snackpass-types";
import { toast } from "sonner";
import { PlusIcon } from "@radix-ui/react-icons";

import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { PrinterDevice } from "#devices/utils/deviceTypes";
import { Button } from "src/@/components/ui/button";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import api from "src/api/rest";
import { ConfirmReplaceAlert } from "#devices/components/CashDrawerEditSection/ConfirmReplaceAlert";

export const CreateOrConnect = ({
    openEditModal: openModal,
    cashDrawers,
}: {
    openEditModal: () => void;
    cashDrawers?: CashDrawerWithPrinter[];
}) => {
    const [selectedCashDrawer, setSelectedCashDrawer] = useState<
        CashDrawerWithPrinter | undefined
    >(undefined);
    const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);

    const { connectCashDrawer, isLoading, error } =
        useConnectExistingCashDrawer(selectedCashDrawer);

    useEffect(() => {
        if (error)
            toast.error("Error connecting cash drawer.", {
                description: error.message,
            });
    }, [error]);

    const { storeDevices } = useContext(DevicesPageContext);

    // The serial of the device already connected to the selected drawer.
    const connectedSerial =
        selectedCashDrawer?.printer?.serial ?? selectedCashDrawer?.device;

    // Name of the device already connected to the selected drawer.
    const connectedDeviceName: string | undefined = useMemo(
        () =>
            connectedSerial
                ? storeDevices?.find(
                      (device) => device.serial === connectedSerial,
                  )?.name
                : undefined,
        [storeDevices, selectedCashDrawer],
    );

    const attemptConnectCashDrawer = useCallback(async () => {
        if (!selectedCashDrawer) {
            toast.error("Please select a cash drawer.");
            return;
        }

        // Even if there is a serial, if we don't find a device, this means the cash drawer was connected to a deleted device, so it's fine to override.
        if (connectedDeviceName) {
            // If there is a connected device, we should prompt the user that they are replacing the connection.
            setIsReplaceDialogOpen(true);
        } else {
            await connectCashDrawer();
        }
    }, [selectedCashDrawer, storeDevices, connectCashDrawer]);

    return (
        <>
            <Select
                value={selectedCashDrawer?.id || ""}
                onValueChange={(value) =>
                    setSelectedCashDrawer(
                        cashDrawers?.find((drawer) => drawer.id === value),
                    )
                }
            >
                <ConfirmReplaceAlert
                    connectedDrawerName={selectedCashDrawer?.name}
                    deviceName={connectedDeviceName}
                    open={isReplaceDialogOpen}
                    close={() => setIsReplaceDialogOpen(false)}
                    onContinue={connectCashDrawer}
                />
                <SelectTrigger className="mb-2">
                    <SelectValue placeholder="No Drawer Connected" />
                </SelectTrigger>
                <SelectContent className="z-[9999]">
                    <SelectGroup>
                        <SelectItem value={""}>No Drawer Connected</SelectItem>
                        {cashDrawers?.map((drawer) => (
                            <SelectItem value={drawer.id} key={drawer.id}>
                                {drawer.name}
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </Select>

            <div className="flex justify-end space-x-1">
                <Button variant="outline" onClick={openModal}>
                    <PlusIcon className="mr-1 h-4 w-4" />
                    Create
                </Button>
                <Button
                    onClick={async () => attemptConnectCashDrawer()}
                    disabled={!selectedCashDrawer}
                >
                    {isLoading ? <Spinner speed={0.5} size={18} /> : "Connect"}
                </Button>
            </div>
        </>
    );
};

const useConnectExistingCashDrawer = (cashDrawer?: CashDrawerWithPrinter) => {
    const { device } = useContext(DevicesPageContext);
    const { refetch } = useCashDrawers();

    const refetchCashDrawers = () => {
        void refetch();
    };
    const { isPending, mutate, isSuccess, error } = useMutation({
        onSuccess: () => {
            refetchCashDrawers();
            toast.success("Successfully connected cash drawer.");
        },
        mutationFn: async () => {
            if (!cashDrawer) {
                throw new Error("No cash drawer.");
            }
            const newCashDrawer = {
                name: cashDrawer.name,
                printer:
                    device?.deviceType === DeviceType.Printer
                        ? (device as PrinterDevice).deviceDetails.id
                        : null,
                device:
                    device?.deviceType === DeviceType.Kiosk
                        ? device.serial
                        : null,
            };
            return api.cashDrawers.updateCashDrawer(
                cashDrawer.id,
                newCashDrawer,
            );
        },
    });
    return {
        isLoading: isPending,
        connectCashDrawer: mutate,
        isSuccess,
        error,
    };
};
