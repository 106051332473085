import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

export const Card = styled.div`
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${SystemColors.v1.gray80};
    margin-bottom: 24px;
`;

export const Title = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${SystemColors.v1.sesame};
    margin-bottom: 16px;
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${SystemColors.v1.sesame};
`;

export const Text = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${SystemColors.v1.gray30};
`;

export const Button = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    width: auto;
    border-radius: 56px;
    border: 1px solid ${SystemColors.v1.gray80};
    color: ${SystemColors.v1.sesame};
    font-size: 16px;
    font-weight: 600;
    font-family: Inter;
`;
