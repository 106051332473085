import styled, { css } from "styled-components";

type TextSize = "xxl" | "xl" | "l" | "m" | "s" | "xs";
type Colors = "black" | "grey" | "white" | "light-grey";
type Weight = "x-bold" | "bold" | "regular" | "thin" | "x-thin";
type Spacing = "xl" | "l" | "m" | "s" | "xs";

const fontWeights = (type: Weight) => {
    switch (type) {
        case "x-bold":
            return 700;
        case "bold":
            return 600;
        case "regular":
            return 500;
        case "thin":
            return 400;
        case "x-thin":
            return 300;
        default:
            return 400;
    }
};

const fontSizes = (type: TextSize) => {
    switch (type) {
        case "xxl":
            return "36px";
        case "xl":
            return "32px";
        case "l":
            return "22px";
        case "m":
            return "18px";
        case "s":
            return "16px";
        case "xs":
            return "14px";
        default:
            return "16px";
    }
};

const lineHeights = (type: TextSize) => {
    switch (type) {
        case "xxl":
            return "44px";
        case "xl":
            return "32px";
        case "l":
            return "28px";
        case "m":
            return "24px";
        case "s":
        case "xs":
            return "20px";
    }
};

const textColor = (color: Colors) => {
    switch (color) {
        case "black":
            return "#282D32";
        case "light-grey":
            return "#606C76";
        case "grey":
            return "#A0ADBA";
        case "white":
            return "#FFFFFF";
    }
};

const textSpacing = (spacing: Spacing) => {
    switch (spacing) {
        case "xl":
            return "24px";
        case "l":
            return "18px";
        case "m":
            return "12px";
        case "s":
            return "6px";
        case "xs":
            return "2px";
    }
};

interface TextProps {
    size: TextSize;
    color?: Colors;
    weight?: Weight;
    spacing?: Spacing;
}

export const TextStyles = ({
    size,
    color = "black",
    weight = "regular",
    spacing = "xs",
}: TextProps) => css`
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: ${textColor(color)};
    font-weight: ${fontWeights(weight)};
    font-size: ${fontSizes(size)};
    line-height: ${lineHeights(size)};
    padding-top: ${textSpacing(spacing)};
    padding-bottom: ${textSpacing(spacing)};
`;

export const Text = styled.div<TextProps>`
    ${({ size, color, weight, spacing }) =>
        TextStyles({ size, color, weight, spacing })};
    display: flex;
    flex-direction: row;
    width: 100%;
`;
