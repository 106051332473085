import { captureException } from "@sentry/react";
import { IStore } from "@snackpass/snackpass-types";
import { Dispatch } from "react";
import { AnyAction } from "redux";

import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { notifyFailure, notifySuccess } from "#onboarding/utils/notify";

export const buildFetchStore =
    (dispatch: Dispatch<AnyAction>, setLoading: (l: boolean) => void) =>
    async (id: string) => {
        setLoading(true);
        return api.stores
            .getOne(id)
            .then(({ data }) => {
                setLoading(false);
                if (data.success) {
                    dispatch(setActiveStore(data.store));
                }
            })
            .catch(() => {
                setLoading(false);
                notifyFailure({
                    description: "Unable to retrieve store",
                });
            });
    };

export const buildPatchStore =
    (dispatch: Dispatch<AnyAction>, setLoading: (l: boolean) => void) =>
    async (id: string, body: Partial<IStore>, cb?: () => void) => {
        setLoading(true);
        return api.stores
            .update(id, body)
            .then(({ data }) => {
                setLoading(false);
                if (data.success) {
                    notifySuccess({
                        description: "Store information saved successfully",
                    });
                    dispatch(setActiveStore(data.store));
                    cb ? cb() : null;
                } else {
                    notifyFailure({
                        description: "Unable to save store information",
                    });
                    captureException(Error(JSON.stringify(data)));
                }
            })
            .catch((e) => {
                setLoading(false);
                const invalidSlug = /slug.*already exists/i.test(
                    e.cause?.response?.data?.message,
                );
                invalidSlug
                    ? notifyFailure({
                          description:
                              "That username is already in use, please use another.",
                          duration: 10,
                      })
                    : notifyFailure({
                          description: "Unable to save store information",
                      });
            });
    };
