import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Button } from "#payouts/components/shared/Button";
import { getUser } from "src/redux/selectors";

export const NoPayoutsAccess = () => {
    const user = useSelector(getUser);
    return (
        <Container>
            <div className="top">
                <div className="center">
                    You do not have access to view this page.
                </div>
                <div className="center">
                    Please contact support if you believe this is an error.
                </div>
                <div className="center contact-button">
                    <Button
                        smallRadius
                        size="micro"
                        variant="primary"
                        children={<>Contact Support</>}
                        onClick={() => {
                            window.Intercom("show");
                        }}
                    />
                </div>
            </div>
            <div className="bottom">
                <div>
                    Currently signed in as{" "}
                    <span className="email">
                        {user?.email ?? "Unknown Email"}
                    </span>
                    . You may need to sign in with a different account.
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.spacing.double};
    border: 1px solid #DFE0E2;
    border-radius: ${theme.spacing.base};
    font-family: Inter;
    font-style: normal;

    .top {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px;
        border-bottom: 1px solid #DFE0E2;
        border-top-left-radius: ${theme.spacing.base};
        border-top-right-radius: ${theme.spacing.base};
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; /* 120% */

        .center {
            display: flex;
            flex: 1;
            width: 100%;
            justify-content: center;
        }

        .contact-button {
            margin-top: ${theme.spacing.base};

            button {
                width: 130px;
            }
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px;
        background-color: #F9FAFB;
        border-bottom-left-radius: ${theme.spacing.base};
        border-bottom-right-radius: ${theme.spacing.base};
        color: #585B5F;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        span.email {
            color: #0F0F0F;
            font-weight: 400;
        }

        span.sign-in {
            text-decoration-line: underline;
        }
    }`}
`;
