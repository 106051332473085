import React, { useMemo, useContext } from "react";

import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { deviceOptions } from "#devices/utils/deviceOptions";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DEFAULT_COUNT } from "#devices/utils/deviceTypes";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuLabel,
} from "src/@/components/ui/dropdown-menu";
import { Checkbox } from "src/@/components/ui/checkbox";
import { Button } from "src/@/components/ui/button";

const DeviceFilter = (): JSX.Element => {
    const { storeDevices, currentFilters, setCurrentFilters } =
        useContext(DevicesPageContext);

    const onCheckedChange = (key: string) => {
        const numericalKey = Number(key);
        const selectedDeviceType = deviceOptions[numericalKey].value;
        const allFilter = deviceOptions[0].value;
        const currentDevices = currentFilters.deviceType.filter(
            (type) => type !== allFilter,
        );

        // If we select "ALL", we want to deselect other options
        const newDeviceTypes =
            selectedDeviceType === allFilter
                ? currentFilters.deviceType.includes(allFilter)
                    ? []
                    : deviceOptions.map((device) => device.value)
                : currentDevices.includes(selectedDeviceType)
                  ? currentDevices.filter((type) => type !== selectedDeviceType)
                  : [...currentDevices, selectedDeviceType];

        setCurrentFilters({
            ...currentFilters,
            deviceType: newDeviceTypes,
        });
    };

    const deviceCountByType = useMemo(() => {
        if (!storeDevices) return DEFAULT_COUNT;

        return storeDevices.reduce(
            (acc, option) => {
                // we have some deprecated device types (e.g. cash drawer that we should not count in the "All" count).
                if (!deviceOptions.some((e) => option.deviceType === e.value))
                    return acc;

                acc[option.deviceType] += 1;
                acc.ALL += 1;
                return { ...acc };
            },
            { ...DEFAULT_COUNT },
        );
    }, [storeDevices]);

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" className="mb-2 h-8 w-fit">
                    <FilterIcon className="mr-2 h-4 w-4" /> Filter
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
                <DropdownMenuGroup>
                    <DropdownMenuLabel>Device Type</DropdownMenuLabel>
                    {deviceOptions.map((deviceType, key) => {
                        const disabled =
                            deviceCountByType[deviceType.value] === 0;
                        const label = disabled
                            ? deviceType.label
                            : `${deviceType.label} (${
                                  deviceCountByType[deviceType.value]
                              })`;
                        const checked =
                            !disabled &&
                            currentFilters.deviceType.includes(
                                deviceType.value,
                            );
                        return (
                            <DropdownMenuItem
                                disabled={disabled}
                                key={key}
                                onSelect={(event) => event.preventDefault()} // prevent dropdown to close when selecting filter
                            >
                                <div className="flex items-center space-x-2">
                                    <Checkbox
                                        id={label}
                                        value={deviceType.value}
                                        disabled={disabled}
                                        checked={checked}
                                        onCheckedChange={() =>
                                            onCheckedChange(key.toString())
                                        }
                                    />
                                    <label className="my-1 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                        {label}
                                    </label>
                                </div>
                            </DropdownMenuItem>
                        );
                    })}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default DeviceFilter;
