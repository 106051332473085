import { useMemo } from "react";

import { useEditorState } from "#table-editor/components/Editor/EditorProvider";
import { Element as Variant } from "src/api/graphql/generated/types";

export function useValidateCanvas() {
    const serviceAreas = useEditorState((state) => state.serviceAreas);
    const elements = useEditorState((state) => state.elements);

    return useMemo(() => {
        const requireOneTable = elements.some(
            (el) => el.properties.type === Variant.Table,
        );

        const allTablesShouldHaveName =
            elements.some(
                (el) =>
                    el.properties.type === Variant.Table &&
                    el.properties.tableName === "",
            ) === false;

        const allLabelsShouldHaveLabel =
            elements.some(
                (el) =>
                    el.properties.type === Variant.Label &&
                    el.properties.label === "",
            ) === false;

        return {
            isValid:
                serviceAreas.length === 0 ||
                (requireOneTable &&
                    allTablesShouldHaveName &&
                    allLabelsShouldHaveLabel),
            errors: [
                !requireOneTable
                    ? "At least 1 table is required for a section"
                    : null,
                elements.length > 0 && !allTablesShouldHaveName
                    ? "All tables must have a unique name"
                    : null,
                elements.length > 0 && !allLabelsShouldHaveLabel
                    ? "All drawn labels must be labeled"
                    : null,
            ].filter(Boolean) as string[],
        };
    }, [serviceAreas, elements]);
}
