import { useCallback, useMemo } from "react";
import {
    READABLE_THIRD_PARTY_MAPPING,
    ThirdPartySource,
} from "@snackpass/snackpass-types";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { useDashboardContext } from "../../hooks/use-dashboard-context";
import { useSelector } from "react-redux";
import { getDashboardTransactionChannelOptions } from "src/redux/slices";

export const ProviderPicker = () => {
    const { transactionChannels, setChannels } = useDashboardContext();
    const channelOptions = useSelector(getDashboardTransactionChannelOptions);

    const selectedChannels = useMemo(
        () => new Set(transactionChannels as string[]),
        [transactionChannels],
    );

    const handleOptionSelected = useCallback(
        (value: string) => {
            const newSelection = new Set(selectedChannels);
            if (newSelection.has(value)) {
                newSelection.delete(value);
            } else {
                newSelection.add(value);
            }
            setChannels(Array.from(newSelection) as ThirdPartySource[]);
        },
        [selectedChannels, setChannels],
    );

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">Provider</span>
                </Button>
            }
            title="Provider"
            selectedValues={selectedChannels}
            options={channelOptions.map((e) => ({
                label: READABLE_THIRD_PARTY_MAPPING[e],
                value: e,
            }))}
            onOptionSelected={handleOptionSelected}
        />
    );
};
