import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";
import { AdjustedAddonGroup } from "../adjusted-addon";
import { hasDuplicates } from "../../helpers/utils";

//TODO: delete, we have form validation already
class AddonGroupOptionsCheck
    implements IMenuAutomationCheck<AdjustedAddonGroup>
{
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "AddonGroupOptionsCheck";
    }

    get itemToCheck(): ItemType {
        return "addonGroup";
    }

    checkItems() {
        return [];
    }

    checkItem(item: AdjustedAddonGroup): MenuIssue<AdjustedAddonGroup> | null {
        if (item.supportsMultiple && item.limit == 1) {
            return new MenuIssue(
                "InvalidAddonGroupOptions",
                "Multiple selection cannot be enabled if modifier group limit is 1",
                "critical",
                item,
            );
        }
        if (hasDuplicates(item.addons, "name")) {
            return new MenuIssue(
                "InvalidAddonGroupOptions",
                "Modifier options can not have duplicate names",
                "critical",
                item,
            );
        }
        return null;
    }
}

export { AddonGroupOptionsCheck };
