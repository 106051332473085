import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import { ReactComponent as ChevronDownIcon } from "src/assets/icons/chevron-down.svg";
import colors from "#reusable/colors/colors.json";

export const DropdownIndicator = () => (
    <ChevronDownIcon fill={SystemColors.v1.black} />
);

export const IndicatorSeparator = () => null;

export const draggableSelectStyles = {
    control: (provided: { height: number }) => ({
        ...provided,
        width: "100%",
        border: `1px solid ${SystemColors.v1.gray80}`,
        backgroundColor: SystemColors.v1.white,
        borderRadius: "7px",
        padding: "5px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: provided.height,
    }),
};

export const SelectWrapper = styled.div`
    width: 98%;
`;

export const ProductBadgeInput = styled.input`
    display: flex;
    flex: 1;
    width: 100%;
    height: 46px;
    border: 1px solid #e1e3e6;
    border-radius: 7px;
    color: ${SystemColors.v1.gray20};

    ::placeholder {
        color: SystemColors.v1.gray30;
    }
    :focus {
        border: 1px solid #2684ff;
        box-shadow: none;
    }
    &:hover {
        border: 2px solid #2684ff;
    }
`;

export const ActionRow = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
    margin: auto 0;
`;

export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-top: ${({ theme }) => theme.spacing.base};
`;

export const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const TableStyles = styled.div`
    border-bottom: 1px solid ${colors["neutral-400"]};
    padding-bottom: 16px;

    .ant-table-cell {
        padding: 0;
        padding-bottom: 5px;
        border-bottom: none;
        border-radius: 25px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background-color: #fff;
    }
`;

export const HandleContainer = styled.div`
    cursor: grab;
`;

export const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    align-items: center;

    &:hover {
        background-color: none;
    }
`;

export const DeleteButton = styled.button`
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 200ms ease-in-out;
    padding: 5px;
    height: 40px;
    width: 40px;
    box-shadow: none;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;
