import { IStore, IUser } from "@snackpass/snackpass-types";
import { debounce } from "radash";
import React, {
    createContext,
    useMemo,
    useCallback,
    useState,
    useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { SaasAccount } from "#billing/domain/types";
import { buildFetchSaasAccount } from "#onboarding/utils/api/saasAccount";
import { buildFetchStore, buildPatchStore } from "#onboarding/utils/api/store";
import { buildPatchUser } from "#onboarding/utils/api/user";
import {
    buildFetchVerification,
    buildPatchVerification,
} from "#onboarding/utils/api/verification";
import { hasEditPermission } from "#onboarding/utils/permission";
import { OnboardingStep } from "#onboarding/utils/types";
import {
    DecryptedStoreVerification,
    StoreVerification,
} from "#payouts/domain/types";
import { getActiveStore, getUser } from "src/redux/selectors";

type OnboardingContextType = {
    verification: StoreVerification | null;
    saasAccount: SaasAccount | null;
    editEnabled: boolean;
    isEmployee: boolean;
    confirmRepresentative: boolean;
    setConfirmRepresentative: React.Dispatch<React.SetStateAction<boolean>>;
    setSaasAccount: (s: SaasAccount) => void;
    resetState: () => void;
    step: OnboardingStep;
    loading: boolean;
    verificationFetched: boolean;
    saasAccountFetched: boolean;
    setLoading: (l: boolean) => void;
    handleStepChange: (s: OnboardingStep) => void;
    patchVerification: (
        id: string,
        body: Partial<
            Pick<
                DecryptedStoreVerification,
                "name" | "taxId" | "merchantCategoryCode" | "phone"
            >
        >,
        cb?: () => void,
    ) => void;
    patchStore: (id: string, body: Partial<IStore>, cb?: () => void) => void;
    patchUser: (id: string, body: Partial<IUser>, cb?: () => void) => void;
};

export const OnboardingContext = createContext<OnboardingContextType>(
    {} as OnboardingContextType,
);

const OnboardingProvider = ({ children }: { children: JSX.Element }) => {
    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);

    const dispatch = useDispatch();

    const editEnabled = hasEditPermission(
        user?.snackpassPermissions.storeIds,
        store?._id ?? "not-set",
    );
    const isEmployee = !!user?.snackpassPermissions.isSnackpassEmployee;

    const [loading, setLoading] = useState(false);
    const [stopPolling, setStepPolling] = useState(false);

    const [step, setStep] = useState(OnboardingStep.Summary);
    const [verification, setVerification] = useState<StoreVerification | null>(
        null,
    );
    const [saasAccount, setSaasAccount] = useState<SaasAccount | null>(null);

    const [verificationFetched, setVerificationFetched] = useState(false);
    const [saasAccountFetched, setSaasAccountFetched] = useState(false);

    const [confirmRepresentative, setConfirmRepresentative] = useState(false);

    const handleSetVerification = (v: StoreVerification) => {
        setVerification(v);
        setVerificationFetched(true);
    };

    const handleSetSaasAccount = (s: SaasAccount) => {
        setSaasAccount(s);
        setSaasAccountFetched(true);
    };

    const handleStepChange = (newStep: OnboardingStep) => {
        setStep(newStep);
    };

    const fetchVerification = debounce(
        { delay: 300 },
        buildFetchVerification(handleSetVerification),
    );

    const fetchSaasAccount = debounce(
        { delay: 300 },
        buildFetchSaasAccount(handleSetSaasAccount),
    );

    const patchVerification = debounce(
        { delay: 300 },
        buildPatchVerification(setVerification, setLoading),
    );

    const fetchStore = debounce(
        { delay: 300 },
        buildFetchStore(dispatch, setLoading),
    );

    const patchStore = debounce(
        { delay: 300 },
        buildPatchStore(dispatch, setLoading),
    );

    const patchUser = debounce({ delay: 300 }, buildPatchUser(setLoading));

    useEffect(() => {
        if (store?._id && !loading) {
            fetchVerification(store._id);
        }
    }, [store?._id]);

    const resetState = useCallback(() => {
        setLoading(false);
        handleStepChange(OnboardingStep.Summary);
    }, []);

    // Note: Make sure this memo is updated appropriately when values change
    const value = useMemo(
        () => ({
            verification,
            saasAccount,
            editEnabled,
            isEmployee,
            resetState,
            step,
            loading,
            setLoading,
            verificationFetched,
            saasAccountFetched,
            handleStepChange,
            setSaasAccount,
            fetchStore,
            fetchVerification,
            fetchSaasAccount,
            patchStore,
            patchVerification,
            patchUser,
            confirmRepresentative,
            setConfirmRepresentative,
        }),
        // Note: https://react.dev/reference/react/useMemo
        //       useMemo uses Object.is(), which would not correctly
        //       update this value if only `verification` is provided
        //       as the sole dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            verification?.id,
            verification?.taxId,
            verification?.merchantCategoryCode,
            verification?.phone,
            saasAccount?.id,
            saasAccount?.onboardingPaymentMethodStatus,
            saasAccount?.customerId,
            editEnabled,
            isEmployee,
            step,
            loading,
            verificationFetched,
            saasAccountFetched,
            confirmRepresentative,
            setLoading,
            fetchStore,
            fetchVerification,
            fetchSaasAccount,
            patchStore,
            patchUser,
            patchVerification,
            resetState,
            setSaasAccount,
        ],
    );

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
};

export default OnboardingProvider;
