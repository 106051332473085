import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DevicesModal } from "#devices/components/Modal";
import { CenteredSpin } from "#devices/components/Loading";

type RebootDeviceModalProps = {
    handleClose: (v: boolean) => void;
    handleReboot: () => void;
    isOpen: boolean;
};

const RebootDeviceModal = ({
    handleClose,
    handleReboot,
    isOpen,
}: RebootDeviceModalProps): JSX.Element => {
    const { rebootingDevice } = useContext(DevicesPageContext);

    const returnToDetails = () => {
        handleClose(false);
    };

    return (
        <DevicesModal
            visible={isOpen}
            handleClose={returnToDetails}
            footer={
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Button
                                variant="outline"
                                className="w-full"
                                onClick={returnToDetails}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                className="w-full"
                                onClick={handleReboot}
                                disabled={rebootingDevice}
                            >
                                {rebootingDevice ? (
                                    <CenteredSpin small />
                                ) : (
                                    "Reboot"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
        >
            <Text.Title2>Reboot Device</Text.Title2>
            <Text.Body>Are you sure you want to reboot this device?</Text.Body>
        </DevicesModal>
    );
};

export default RebootDeviceModal;
