import { ThemeProvider } from "styled-components";

import { PrepStationTable } from "#prep-stations/prep-station-table";
import { PageHeaderOptions } from "#prep-stations/components/PageHeaderOptions";
import AddonDrawer from "#devices/components/AddonDrawer";
import DevicesProvider from "#devices/utils/DevicesPageContext";
import { PageWrapper, theme } from "#devices/components";
import ModalStyles from "#payouts/utils/ModalStyles";

export const PrepStationsPage = () => (
    <ThemeProvider theme={theme}>
        <ModalStyles />
        <DevicesProvider>
            <PageWrapper>
                <PageHeaderOptions />
                <AddonDrawer />
                <PrepStationTable />
            </PageWrapper>
        </DevicesProvider>
    </ThemeProvider>
);
