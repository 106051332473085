import clsx from "clsx";
import { useCallback } from "react";

type Props = {
    titles: string[];
    className?: string;
};

/**
 * A component that will scroll to specified FormCards in a form, by their title.
 * <FormCard /> sets the passed in title prop to the id.
 */
export function AnchorNav({ titles, className }: Props) {
    return (
        <div className={clsx("hidden w-64 lg:block", className)}>
            <div className="fixed flex flex-col space-y-2">
                {titles.map((link) => (
                    <AnchorNavLink key={link} link={link} />
                ))}
            </div>
        </div>
    );
}

function AnchorNavLink({ link }: { link: string }) {
    const scroll = useCallback(() => {
        document.getElementById(link)?.scrollIntoView({ block: "center" });
    }, [link]);
    return (
        <span
            onClick={scroll}
            className="cursor-pointer justify-start text-small text-neutral-600 no-underline hover:font-semibold hover:text-neutral-950"
        >
            {link}
        </span>
    );
}
