import styled from "styled-components";

import Text from "#onboarding/components/shared/Text";
import HeaderInfo from "#table/HeaderInfo";

export const Label = ({
    label,
    subtitle,
    note,
    tooltip,
    required = false,
    omitRequired = false,
    inline = false,
}: {
    label?: string;
    subtitle?: string;
    note?: string;
    tooltip?: string;
    required?: boolean;
    omitRequired?: boolean;
    inline?: boolean;
}) => (
    <LabelContainer>
        <TitleContainer required={required}>
            <Text.SmallLabel>{label}</Text.SmallLabel>
            {!omitRequired ? <Required required={required} /> : null}
            {tooltip ? (
                <HeaderInfo multiline linegap message={tooltip} />
            ) : null}
        </TitleContainer>
        {subtitle && (
            <SubtitleContainer inline={inline}>
                <Text.Subtitle>{subtitle}</Text.Subtitle>
            </SubtitleContainer>
        )}
        {note && (
            <SubtitleContainer inline={inline}>
                <Text.Subtitle>{note}</Text.Subtitle>
            </SubtitleContainer>
        )}
    </LabelContainer>
);

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    span {
        margin-bottom: ${({ theme }) => theme.spacing.half};
    }
`;

const Required = ({ required }: { required?: boolean }) => (
    <div>
        {required ? (
            <span className="custom-required-mark">*</span>
        ) : (
            <span className="custom-optional-mark">Optional</span>
        )}
    </div>
);

const TitleContainer = styled.div<{ required: boolean }>`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    gap: ${({ theme, required }) =>
        required ? theme.spacing.quarter : theme.spacing.half};

    span {
        color: #0f0f0f;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0;
    }

    .custom-required-mark {
        color: red;
        font-weight: 400;
    }

    .custom-optional-mark {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 2px 5px;
        border-radius: 3px;
        font-size: 12px;
        font-family: Inter;
        font-weight: 500;
        line-height: 16px;
    }
`;

const SubtitleContainer = styled.div<{ inline?: boolean }>`
    margin-bottom: ${({ theme, inline }) => (inline ? 0 : theme.spacing.half)};
    justify-content: aligned;

    h2 {
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
`;
