import React, { useContext } from "react";
import { PlusIcon } from "@radix-ui/react-icons";

import { TooltipProvider } from "src/@/components/ui/tooltip";
import { Button } from "src/@/components/ui/button";
import {
    HybridTooltip,
    HybridTooltipTrigger,
} from "src/@/components/ui/HybridTooltip";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DeviceType, PrinterDevice } from "#devices/utils/deviceTypes";
import { PrepStationDeviceDetails } from "#devices/utils/deviceTypes/PrepStationDevice";

const PageHeaderOptions = (): JSX.Element => {
    const {
        device,
        setAddon,
        setIsAddonDrawerVisible,
        setIsEditDrawerVisible,
    } = useContext(DevicesPageContext);

    const printerDevice = device as PrinterDevice;

    const handlePrepStationClick = (params = { isCreate: false }) => {
        if (params.isCreate || printerDevice.deviceDetails.prepStationDetails) {
            setAddon({
                details: params.isCreate
                    ? undefined
                    : (printerDevice.deviceDetails
                          .prepStationDetails as PrepStationDeviceDetails),
                type: DeviceType.PrepStation,
                isCreate: params.isCreate,
            });
            setIsEditDrawerVisible(false);
            setIsAddonDrawerVisible(true);
        }
    };

    return (
        <div className="flex justify-between">
            <div className="text-large font-semibold">Prep Stations</div>
            <div className="mb-5 flex gap-4">
                <TooltipProvider delayDuration={100}>
                    <HybridTooltip>
                        <HybridTooltipTrigger asChild>
                            <Button
                                className="rounded-full px-2 sm:rounded-md"
                                onClick={() =>
                                    handlePrepStationClick({ isCreate: true })
                                }
                            >
                                <PlusIcon className="h-5 w-5 fill-white" />
                                <span className="hidden: ml-2  hidden sm:block">
                                    Prep Station
                                </span>
                            </Button>
                        </HybridTooltipTrigger>
                    </HybridTooltip>
                </TooltipProvider>
            </div>
        </div>
    );
};

export { PageHeaderOptions };
