import { SystemColors } from "@snackpass/design-system";
import React from "react";
import styled from "styled-components";

import { useHeaderText } from "#onboarding/utils/headerText";
import colors from "#reusable/colors/colors.json";

export const Header = () => {
    const text = useHeaderText();

    const Title = () => <div className="step-header-title">{text.title}</div>;

    const DefaultSubtitle = () => (
        <div className="step-header-subtitle">{text.subtitle}</div>
    );

    const WarnSubtitle = () => (
        <div className="step-header-subtitle-warn">
            <Icon className="fas fa-exclamation-circle" iconSize={14} />
            <div>{text.subtitle}</div>
        </div>
    );

    const subtitle = text.warn ? <WarnSubtitle /> : <DefaultSubtitle />;

    return (
        <Container>
            <Title />
            {subtitle}
        </Container>
    );
};

const Icon = styled.i<{
    iconSize?: number;
}>`
    margin: 0 ${({ theme }) => theme.spacing.half};
    margin-left: ${({ theme }) => theme.spacing.quarter};
    padding-top: 2px;
    color: ${SystemColors.v2.candy50.light};
    font-size: ${(props) => (props.iconSize ? `${props.iconSize}px` : "")};
`;

const Container = styled.div`
    ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    padding: auto ${theme.spacing.half};

    .step-header-title {
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: ${theme.spacing.half};
    }

    .step-header-subtitle,
    .step-header-subtitle-warn {
        color: ${SystemColors.v2.salt60.light};
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: ${theme.spacing.half};
        margin-bottom: ${theme.spacing.half};
        margin-left: ${theme.spacing.half};
        margin-right: ${theme.spacing.half};
    }

    .step-header-subtitle {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .step-header-subtitle-warn {
        color: ${SystemColors.v2.salt80.light};
        display: flex;
        flex-direction: row;
        flex: 1;
        background-color: ${colors["neutral-200"]};
        border-radius: ${theme.spacing.quarter};
        padding-top: ${theme.spacing.threeQuarter};
        padding-bottom: ${theme.spacing.threeQuarter};
    }`}
`;
