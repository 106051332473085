import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/table";
import { Edit } from "lucide-react";
import { Button } from "src/@/components/ui/button";
import { Badge } from "src/@/components/ui/badge";
import { InventoryReport } from "src/redux/slices";
import { TopUp } from "./top-up";

type InventoryTableProps = {
    inventory: any[];
    loading: boolean;
    onClickEdit: (item: InventoryReport) => void;
    showTopUp: boolean;
};

export const InventoryTable = ({
    inventory,
    loading,
    onClickEdit,
    showTopUp,
}: InventoryTableProps) => {
    const getStatusBadge = (status: string) => {
        switch (status) {
            case "Out of Stock":
                return <Badge variant="destructive">Out of Stock</Badge>;
            case "Low Stock":
                return <Badge variant="secondary">Low Stock</Badge>;
            default:
                return <Badge variant="default">In Stock</Badge>;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!inventory || inventory.length === 0) {
        return (
            <div className="p-4 text-center text-muted-foreground">
                No inventory items found
            </div>
        );
    }

    return (
        <div className="rounded-md border">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[50px]"></TableHead>
                        <TableHead>Item Name</TableHead>
                        <TableHead>SKU</TableHead>
                        <TableHead>Status</TableHead>
                        {showTopUp && <TableHead>Adjust Quantity</TableHead>}
                        <TableHead>On Hand</TableHead>
                        <TableHead>UOM</TableHead>
                        <TableHead>Price</TableHead>
                        <TableHead>Quantity Sold</TableHead>
                        <TableHead>Total Cost</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {inventory.map((row: any, index: number) => {
                        return (
                            <TableRow key={`row-${index}`}>
                                <TableCell>
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            onClickEdit(row.original)
                                        }
                                    >
                                        <Edit className="h-4 w-4" />
                                    </Button>
                                </TableCell>
                                <TableCell>{row["Item Name"]}</TableCell>
                                <TableCell>{row["SKU"]}</TableCell>
                                <TableCell>
                                    {getStatusBadge(row["Status"])}
                                </TableCell>
                                {showTopUp && (
                                    <TableCell>
                                        <TopUp inventoryReport={row.original} />
                                    </TableCell>
                                )}
                                <TableCell>{row["On Hand"]}</TableCell>
                                <TableCell>{row["UOM"]}</TableCell>
                                <TableCell>{row["Price"]}</TableCell>
                                <TableCell>{row["Quantity Sold"]}</TableCell>
                                <TableCell>{row["Total Cost"]}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};
