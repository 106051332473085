import React from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
    PrinterFormat,
    PrinterUpdateAction,
    SnackpassTimezoneEnum,
} from "@snackpass/snackpass-types";

import Text from "#devices/components/Text";
import { getActiveStore, getUser } from "src/redux/selectors";
import { getFormattedLongDate } from "#devices/components/DevicesTable/lib";
import {
    PrinterEvent,
    printerEventActions,
    printerFormatNames,
} from "#devices/utils/deviceTypes/PrinterDevice";
import { ReactComponent as Failure } from "src/assets/icons/failure.svg";
import { ReactComponent as Checked } from "src/assets/icons/checked.svg";
import { ReactComponent as Warn } from "src/assets/icons/warn.svg";
import { ReactComponent as Gear } from "src/assets/icons/gear.svg";

const DeviceLogListWrapper = styled.div`
    overflow-y: auto;
    padding: ${({ theme }) =>
        `${theme.spacing.double} ${theme.spacing.quarter}`};
    // remove default padding from DetailsSection so shadow is at the bottom border
    margin-bottom: -${({ theme }) => theme.spacing.double};
`;

const DeviceLogList = styled.ol`
    padding-left: ${({ theme }) => theme.spacing.base};
    position: relative;
    list-style-type: none;

    &:before {
        position: absolute;
        inset: -${({ theme }) => theme.spacing.half} 0;
        content: "";
        border-left: 2px solid ${SystemColors.v2.salt80.light};
    }
`;

const ListItem = styled.li`
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.base};

    &:before {
        position: absolute;
        content: "";
        border: 1px solid ${SystemColors.v2.salt80.light};
        left: -19px; // magic number to align properly over the DeviceLogList:before border :(
        width: ${({ theme }) => theme.spacing.half};
        height: ${({ theme }) => theme.spacing.half};
        border-radius: ${({ theme }) => theme.spacing.half};
        top: ${({ theme }) => theme.spacing.half};
        background: white;
        box-shadow: 0px 0px 0px 2px white;
    }
`;

// Arbitrary value that can be updated in the future
const MAX_EVENTS = 10;

const mapActionToFriendly = (action: PrinterUpdateAction) =>
    printerEventActions[action];

const mapFormatToFriendly = (format: PrinterFormat) =>
    printerFormatNames[format];

const PrinterHistoryLog = ({
    histories,
}: {
    histories: PrinterEvent[];
}): JSX.Element => {
    const activeStore = useSelector(getActiveStore);
    const activeUser = useSelector(getUser);

    const timezone = activeStore?.hours.zone ?? SnackpassTimezoneEnum.newYork;

    if (!histories || histories.length < 1) {
        return <p className="mb-4">No device logs currently available.</p>;
    }

    const determineIcon = (action: PrinterUpdateAction) => {
        switch (action) {
            case PrinterUpdateAction.DISCONNECTED:
            case PrinterUpdateAction.FLAKY:
                return <Warn />;
            case PrinterUpdateAction.DEPRECATED:
                return <Failure />;
            case PrinterUpdateAction.CONNECTED:
            case PrinterUpdateAction.CREATED:
                return <Checked />;
            case PrinterUpdateAction.CLONED:
            default:
                return <Gear />;
        }
    };

    const HistoryEventRow = ({ data }: { data: PrinterEvent }) => (
        <ListItem>
            <CommandWrapper>
                <IconWrapper>{determineIcon(data.action)}</IconWrapper>
                <Text.Label>{mapActionToFriendly(data.action)}</Text.Label>
                {activeUser?.snackpassPermissions.isSnackpassEmployee && (
                    <StyledLabel
                        isCurrentStore={
                            !!activeStore?._id &&
                            data.store._id === activeStore?._id
                        }
                    >
                        {data.store.name}
                    </StyledLabel>
                )}
            </CommandWrapper>

            <Text.Small>Device Name: {data.name}</Text.Small>
            <Text.Small>
                Format: {mapFormatToFriendly(data.format as PrinterFormat)}
            </Text.Small>
            <Text.Small>
                {getFormattedLongDate(data.createdAt, timezone)}
            </Text.Small>
        </ListItem>
    );

    return (
        <DeviceLogListWrapper>
            <DeviceLogList>
                {histories.slice(0, MAX_EVENTS).map((event, i) => (
                    <HistoryEventRow data={event} key={`printer-event-${i}`} />
                ))}
            </DeviceLogList>
        </DeviceLogListWrapper>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
`;

const StyledLabel = styled(Text.Label)<{ isCurrentStore?: boolean }>`
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    color: ${({ isCurrentStore, theme }) =>
        isCurrentStore ? theme.buttons.variant["primary"].backgroundColor : ""};
`;

const CommandWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export default PrinterHistoryLog;
