/** @jsxImportSource @emotion/react */
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";

import { gridGap } from "#css";
import colors from "#reusable/colors/colors.json";

const { MOBILE, TABLET, DESKTOP } = ScreenState;
const { MOBILE_GRIDGAP, TABLET_GRIDGAP, DESKTOP_GRIDGAP } = gridGap;

export const GuestbookCampaignGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "Header"
        "Body"
        "Footer";
    width: 100%;
    height: calc(100vh - 50px);

    .divider {
        border-bottom: 1px solid ${colors["neutral-400"]};
        position: sticky;
        top: 0px;
        background: ${SystemColors.v1.white};
        @media ${MOBILE} {
            z-index: 1000;
        }
    }

    @media ${MOBILE} {
        height: -webkit-fill-available;
    }
    @media ${TABLET} {
        /* grid-gap: ${TABLET_GRIDGAP}; */
    }

    @media ${DESKTOP} {
        /* grid-gap: ${DESKTOP_GRIDGAP}; */
    }
`;
