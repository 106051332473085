import { Addon, AddonGroup, IProduct } from "@snackpass/snackpass-types";
import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { PartialDeep } from "type-fest";
import { toast } from "sonner";
import _ from "lodash";

import { MenuTopLevelContext } from "#menu-editor/mobile-friendly/helpers/context";
import { Button } from "src/@/components/ui/button";
import { Checkbox } from "src/@/components/ui/checkbox";
import { useDataTable } from "src/@/components/ui/data-table";
import { DataTableComponent } from "src/@/components/ui/data-table/data-table-component";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import api from "src/api/rest";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { updateLegacyProducts } from "src/redux/slices";
import { getActiveStoreId } from "src/redux/selectors";
import { UpdateModifiersParam } from "#menu-editor/multi-menus/redux/thunk-helpers";

export type ProductAddonGroupUpdate = {
    product: IProduct;
    addonGroup: AddonGroup;
    matchingChangedAddonGroup: AddonGroupEdit;
};

export type AddonGroupEdit = {
    edited: Omit<PartialDeep<AddonGroup>, "addons"> & {
        addons: Omit<Addon, "_id">[];
    };
    original: AddonGroup;
};

export type SyncAddonGroupsModalProps = {
    open: boolean;
    close: () => void;
    /**
     * a handler function to intake information about new / deleted addons to make sure multi menus is up to date after the bulk update.
     */
    onSubmit: (updateModifierParams: UpdateModifiersParam[]) => Promise<void>;
    matchingAddonGroupUpdates: ProductAddonGroupUpdate[];
};

export function SyncAddonGroupsModal({
    open,
    close,
    onSubmit,
    matchingAddonGroupUpdates,
}: SyncAddonGroupsModalProps) {
    const dispatch = useAppDispatch();

    const { isPending, mutate } = useUpdateAddonGroups();

    const _close = useCallback(() => {
        if (!isPending) close();
    }, [close, isPending]);

    const { productInEdit } = useContext(MenuTopLevelContext);

    // this is needed for an edge case because if you hit "esc", you can close the edit product sheet without closing this modal.
    // just doing this workaround because we are rebuilding the menu builder in the near future regardless.
    useEffect(() => {
        if (!productInEdit) close();
    }, [close, productInEdit]);

    const columns = useColumns(productInEdit);

    const table = useDataTable({
        columns,
        data: matchingAddonGroupUpdates,
        customPageSize: 999999, // react table does not support infinite scroll, so setting this to something really high
        getRowId: getPairId,

        defaultSelected: matchingAddonGroupUpdates.reduce(
            (record, pair) => ({
                ...record,
                [getPairId(pair)]: true,
            }),
            {},
        ),
    });

    const changedAddonNames = useMemo(() => {
        const addonNames = new Set<string>();
        matchingAddonGroupUpdates.forEach(({ addonGroup }) =>
            addonNames.add(addonGroup.name),
        );
        return Array.from(addonNames);
    }, [matchingAddonGroupUpdates]);

    const _onSubmit = useCallback(async () => {
        if (isPending) return;
        const selectedPairs = table
            .getSelectedRowModel()
            .flatRows.map((e) => e.original)
            .filter((e) => e.product._id !== productInEdit?._id);
        const result = await mutate(selectedPairs);
        const products: IProduct[] = result
            ? result.flatMap((e) => e.data.products)
            : [];
        dispatch(updateLegacyProducts(products));

        const updateMultiMenusParams = formatSyncAddonGroupMultiMenusParams(
            products,
            selectedPairs,
        );

        // we pass along the multi menus params here because these must be submitted in one pass
        void onSubmit(updateMultiMenusParams);
        close();
    }, [
        close,
        dispatch,
        isPending,
        mutate,
        onSubmit,
        productInEdit?._id,
        table,
    ]);

    return (
        <Dialog open={open} onOpenChange={_close}>
            <DialogContent className="z-[9999]" overlayClassName="z-[9998]">
                <DialogHeader>
                    <DialogTitle>Update Modifier Across Menu Items</DialogTitle>
                    <DialogDescription>
                        You are editing the{" "}
                        {changedAddonNames.length > 1
                            ? "modifiers "
                            : "modifier "}
                        {formatter.format(changedAddonNames)} on{" "}
                        <span className="font-semibold">
                            {productInEdit?.name ?? "Unnamed Product"}.
                        </span>{" "}
                        If you want these changes to apply to the{" "}
                        {formatter.format(changedAddonNames)}{" "}
                        {changedAddonNames.length > 1
                            ? "modifiers"
                            : "modifier"}{" "}
                        on other menu items, please select them as well.
                    </DialogDescription>
                    <div className="max-h-[60vh] overflow-y-auto p-1">
                        <DataTableComponent
                            table={table}
                            toolbar={{
                                search: { global: true },
                            }}
                        />
                    </div>
                </DialogHeader>
                <DialogFooter>
                    <Button
                        type="button"
                        onClick={_onSubmit}
                        disabled={isPending}
                        loading={isPending}
                    >
                        Submit
                    </Button>
                    <Button
                        type="button"
                        variant="outline"
                        onClick={_close}
                        disabled={isPending}
                    >
                        Cancel
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

const getPairId = (pair: ProductAddonGroupUpdate) =>
    `${pair.product._id}-${pair.addonGroup._id}`;

const useColumns = (
    productInEdit: IProduct | null,
): ColumnDef<ProductAddonGroupUpdate>[] =>
    useMemo(
        () => [
            {
                id: "Checkbox",
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onCheckedChange={(v) =>
                            table.getRowModel().flatRows.forEach((row) => {
                                if (
                                    row.original.product._id !==
                                    productInEdit?._id
                                )
                                    row.toggleSelected(!!v);
                            })
                        }
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(v) => row.toggleSelected(!!v)}
                        disabled={
                            row.original.product._id === productInEdit?._id
                        }
                    />
                ),
                size: 40,
            },
            {
                id: "Category",
                header: "Category",
                sortingFn: "alphanumeric",
                accessorFn: (pair) => pair.product.category,
            },
            {
                id: "Product",
                header: "Product",
                sortingFn: "alphanumeric",
                accessorFn: (pair) => pair.product.name,
            },
            {
                id: "Modifier Group",
                header: "Modifier Group",
                accessorFn: (pair) => pair.addonGroup.name,
                sortingFn: "alphanumeric",
            },
        ],
        [productInEdit],
    );

const useUpdateAddonGroups = () => {
    const storeId = useAppSelector(getActiveStoreId);
    const { isPending, mutateAsync, isSuccess, error } = useMutation({
        mutationFn: async (updates: ProductAddonGroupUpdate[]) => {
            if (!storeId) return;

            const addonGroupEdits = updates.reduce<AddonGroupEdit[]>(
                (edits, update) =>
                    edits.some(
                        (e) =>
                            e.original._id ===
                            update.matchingChangedAddonGroup.original._id,
                    )
                        ? edits
                        : [...edits, update.matchingChangedAddonGroup],
                [],
            );

            return Promise.all(
                addonGroupEdits.map(async ({ original, edited }) =>
                    api.products.updateAddonGroups({
                        addonGroup: edited,
                        groupIDs: updates
                            .filter(
                                (e) =>
                                    e.matchingChangedAddonGroup.original._id ===
                                    original._id,
                            )
                            .map((e) => e.addonGroup._id),
                        storeId,
                    }),
                ),
            );
        },
        onSuccess: () => toast.success("Succesfully Updated Modifiers"),
        onError: (e) => {
            toast.error("Error Updating Modifiers", { description: e.message });
        },
    });

    return {
        isPending,
        mutate: mutateAsync,
        isSuccess,
        error,
    };
};

/**
 * Based on the returned products and the submitted updates, returns parameters for our multiMenuThunks.updateModifiers call to send these updates to our multi menus implementation.
 */
export const formatSyncAddonGroupMultiMenusParams = (
    updatedProducts: IProduct[],
    pairs: ProductAddonGroupUpdate[],
) => {
    const updateParams: UpdateModifiersParam[] = _.compact(
        pairs.map((pair) => {
            const matchingUpdatedProduct = updatedProducts.find(
                (product) => pair.product._id === product._id,
            );
            if (!matchingUpdatedProduct) return undefined;
            const matchingUpdatedAddonGroup =
                matchingUpdatedProduct?.addonGroups.find(
                    (e) => e._id === pair.addonGroup._id,
                );
            if (!matchingUpdatedAddonGroup) return undefined;

            // addons that are in the updated addon group but not the original addon group
            const newAddons =
                pair.matchingChangedAddonGroup.edited.addons.filter(
                    (newAddon) =>
                        !pair.matchingChangedAddonGroup.original.addons.find(
                            (originalAddon) =>
                                newAddon.name === originalAddon.name,
                        ),
                );

            const newAddonIds = _.compact(
                newAddons.map(
                    (newAddon) =>
                        matchingUpdatedAddonGroup.addons.find(
                            (addonFromUpdated) =>
                                addonFromUpdated.name === newAddon.name,
                        )?._id,
                ),
            );

            // addons that are in the original addon group but not the updated addon group
            const deletedAddons =
                pair.matchingChangedAddonGroup.original.addons.filter(
                    (originalAddon) =>
                        !pair.matchingChangedAddonGroup.edited.addons.find(
                            (newAddon) => newAddon.name === originalAddon.name,
                        ),
                );

            const deletedAddonIds = _.compact(
                deletedAddons.map(
                    (newAddon) =>
                        matchingUpdatedAddonGroup.addons.find(
                            (addonFromUpdated) =>
                                addonFromUpdated.name === newAddon.name,
                        )?._id,
                ),
            );

            return {
                newAddons: {
                    [pair.addonGroup._id]: {
                        addonIds: newAddonIds,
                    },
                },
                productId: pair.product._id,
                deletedAddons: {
                    [pair.addonGroup._id]: deletedAddonIds,
                },
            };
        }),
    );
    return updateParams;
};

const formatter = new Intl.ListFormat("en-US", {
    style: "long",
    type: "conjunction",
});
