import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getActiveStore } from "@snackpass/accounting";
import { Menu as MultiMenu } from "@snackpass/snackpass-types";
import { useMediaQuery } from "react-responsive";

import {
    getAllStoreMultiMenus,
    getMultiMenuLoadingState,
} from "#menu-editor/multi-menus/redux/selectors";
import { MenusTable } from "#menu-editor/multi-menus/screens/menus-desktop/components/menus-table";
import {
    FilterEnum,
    FilterType,
} from "#menu-editor/multi-menus/screens/menus-desktop/types";
import { ScreenLayout } from "#menu-editor/multi-menus/styled-components/layout";
import { NoContentMessage } from "#menu-editor/multi-menus/shared-components/no-content-message";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import constants from "#core/constants";
import { MobileHeader } from "#menu-editor/multi-menus/shared-components/mobile-header";
import { RoundButton } from "#menu-editor/multi-menus/styled-components/button";
import { ReactComponent as Filter } from "src/assets/icons/menu-filter.svg";
import { FilterDrawer } from "#menu-editor/multi-menus/screens/menus-desktop/components/FilterDrawer";
import { useAppDispatch } from "src/redux/hooks";
import {
    openNotification,
    NotificationPosition,
} from "#menu-editor/multi-menus/shared-components/notification";

import { Header } from "./components/header";

export function Menus() {
    const dispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const storeMenus = useSelector(getAllStoreMultiMenus);
    const loadingState = useSelector(
        getMultiMenuLoadingState("FetchStoreMenus"),
    );
    const history = useHistory();
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });

    const [openDrawer, setOpenDrawer] = useState(false);

    const navigateToNewMenu = useCallback(() => {
        history.push("/multi-menus-create");
    }, [history]);

    const [filter, setFilter] = useState<FilterType>(FilterEnum.AllMenus);

    const [activeMenus, inactiveMenus] = storeMenus.reduce<
        [MultiMenu[], MultiMenu[]]
    >(
        ([active, inactive], menu) => {
            if (menu.enabled) {
                return [[...active, menu], inactive];
            }

            return [active, [...inactive, menu]];
        },
        [[], []],
    );

    useEffect(() => {
        dispatch(multiMenuActions.resetCurrentMenu());
        if (activeStore?._id)
            (async () => {
                dispatch(multiMenuThunks.fetchStoreMultiMenus(activeStore._id))
                    .unwrap()
                    .catch(() => {
                        openNotification({
                            message: "Failed to fetch store menus",
                            description:
                                "Failed to fetch all store menus, please try again later.",
                            position: NotificationPosition.TopCenter,
                        });
                    });
            })();
    }, [activeStore?._id]);

    const getMenusLength = (): number => {
        switch (filter) {
            case FilterEnum.AllMenus:
                return storeMenus.length;
            case FilterEnum.Active:
                return activeMenus.length;
            case FilterEnum.Inactive:
                return inactiveMenus.length;
            default:
                return storeMenus.length;
        }
    };

    return (
        <ScreenLayout
            header={
                isMobile ? (
                    <MobileHeader
                        title={`${getMenusLength()} ${
                            getMenusLength() === 1 ? "Menu" : "Menus"
                        }`}
                        right={
                            <RoundButton onClick={() => setOpenDrawer(true)}>
                                <Filter />
                            </RoundButton>
                        }
                    />
                ) : (
                    <Header
                        menuAmount={getMenusLength()}
                        filter={filter}
                        setFilter={setFilter}
                        navigateToNewMenu={navigateToNewMenu}
                    />
                )
            }
            content={
                storeMenus.length ? (
                    <>
                        <MenusTable
                            activeMenus={activeMenus}
                            inactiveMenus={inactiveMenus}
                            filter={filter}
                            isMobile={isMobile}
                            navigateToNewMenu={navigateToNewMenu}
                        />
                        {isMobile && (
                            <FilterDrawer
                                open={openDrawer}
                                setOpenDrawer={setOpenDrawer}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        )}
                    </>
                ) : (
                    <NoContentMessage
                        title="No Additional Menus"
                        subtitle="Start by creating your first menu"
                        buttonText="Create Menu"
                        onClick={navigateToNewMenu}
                    />
                )
            }
            isLoading={loadingState === "pending"}
            centerContent={!storeMenus.length}
        />
    );
}
