import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { debounce } from "radash";
import { Spinner } from "react-activity";
import { SystemColors } from "@snackpass/design-system";

import config from "#config";
import { Page } from "#payouts/components/shared/Page";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { AccountSummary } from "#payouts/components/summary/Account";
import { CompanySummary } from "#payouts/components/summary/Company";
import { OwnersSummary } from "#payouts/components/summary/owner";
import { SubmitSummary } from "#payouts/components/summary/Submit";
import { NoPayoutsAccess } from "#payouts/components/setup/NoPayoutsAccess";
import { NonCompanyAccess } from "#payouts/components/setup/NonCompanyAccess";
import api from "src/api/rest";
import { useStripeHostedPayoutsOnboardingEnabled } from "#navigation/utils";
import Button from "#reusable/buttons/default-button";

/**
 * Re: Limited Access view below ("<NonCompanyAccess />")
 *
 * Currently the onboarding form only supports "Company" entities in Stripe Connect,
 * but Snackpass does have some stores that are not classified as a company by the IRS.
 * This list will toggle on a limited access view which will hopefully prevent confusion for these
 * stores if their admin is on the RDB > Settings > Payouts page.
 */

// This could also be a feature gate, but that seemed "costly" when only 2 stores will use this view
const nonCompanyStoreIds = [
    "63503e309f68c700bd07b20f",
    "6514dcf9ffec6ed33c733499",
];

const PageContainer = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => <Page>{children}</Page>;

const fetch = async (storeId: string, setUrl: (url?: string) => void) => {
    void api.billing
        .getOnboardingForm(storeId)
        .then(({ data }) => {
            setUrl(data.url);
        })
        .catch((error) => {
            toast.error("Unable to retrieve custom onboarding form", {
                description: error.message,
            });
            setUrl();
        });
};

const debounced = debounce({ delay: 1000 }, fetch);

export const SummaryPage = () => {
    const { storeId, editEnabled, isEmployee, emailVerified, verification } =
        useContext(PayoutsSettingsContext);

    const showStripeOnboarding = useStripeHostedPayoutsOnboardingEnabled();

    const [loadingForm, setLoadingForm] = useState(false);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [clicked, setClicked] = useState(false);

    const callback = useCallback((url?: string) => {
        setUrl(url);
        setLoadingForm(false);
    }, []);

    useEffect(() => {
        if (storeId && showStripeOnboarding && !loadingForm) {
            setLoadingForm(true);
            void debounced(storeId, callback);
        }
    }, [storeId, showStripeOnboarding, callback]);

    // Return nothing if no verification exists to summarize
    if (!verification) return null;

    // If email is not verified in production, do not return a summary
    if (config.isProduction && !emailVerified) return null;

    // Limited Access view for non-company entities. See note above.
    const isNonCompany = nonCompanyStoreIds.includes(storeId ?? "not-set");
    if (isNonCompany) {
        return (
            <PageContainer>
                <NonCompanyAccess />
            </PageContainer>
        );
    }

    // View for users who do not have admin access and are not Snackpass
    // employees
    if (!editEnabled && !isEmployee) {
        return (
            <PageContainer>
                <NoPayoutsAccess />
            </PageContainer>
        );
    }

    if (showStripeOnboarding) {
        if (loadingForm) {
            return (
                <PageContainer>
                    <div className="flex w-full justify-center">
                        <Spinner color={SystemColors.v1.candy50} size={26} />
                    </div>
                </PageContainer>
            );
        }

        return (
            <PageContainer>
                <div className="flex flex-col gap-2">
                    <span>
                        We require additional information to complete your
                        payouts onboarding. Please click the button below and
                        provide the indicated information.
                    </span>
                    <Button
                        squareBtn
                        className="flex w-[200px]"
                        disabled={clicked || !url}
                        variant="filled"
                        onClick={() => {
                            if (url && !clicked) {
                                setClicked(true);
                                window.location.href = url;
                            }
                        }}
                    >
                        {clicked ? (
                            <Spinner
                                color={SystemColors.v1.candy50}
                                size={12}
                            />
                        ) : (
                            "Payouts Onboarding"
                        )}
                    </Button>
                </div>
            </PageContainer>
        );
    }

    // Default view
    return (
        <PageContainer>
            <SubmitSummary />
            <CompanySummary />
            <AccountSummary />
            <OwnersSummary />
        </PageContainer>
    );
};
