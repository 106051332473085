import { Button } from "src/@/components/ui/button";

type ConfirmButtonProps = {
    disabled: boolean;
    loading: boolean;
    onClick: () => void;
    refundType: string;
};

function ConfirmButton({
    disabled,
    loading,
    onClick,
    refundType
}: ConfirmButtonProps) {
    return (
        <Button
            className="w-full"
            disabled={disabled}
            loading={loading}
            onClick={onClick}
        >
            Confirm {refundType}
        </Button>
    );
}

export default ConfirmButton;
