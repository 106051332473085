import { useCategoriesWithProducts } from "#menu/hooks";

/**
 * Returns list of categories to be edited by the menu manager.
 * XXX: For now, this is just a wrapper around our existing redux.
 */
export function useCategories() {
    return useCategoriesWithProducts();
}

// XXX: This type should be surfaced elsewhere once we add support for categories as their own collection
export type Category = {
    id: string;
    name: string;
    productIds: string[];
};
