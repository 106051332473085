import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { toast } from "sonner";
import { TemplateSectionType } from "@snackpass/snackpass-types";
import { RemoveScroll } from "react-remove-scroll";

import { Form } from "src/@/components/ui/form";
import CampaignNameCardInput from "#guestbook/screens/Campaigns/NewCampaign/components/CampaignNameCardInput";
import NewCampaignHeader from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignHeader";
import {
    COST_PER_CUSTOMER,
    getNumberCustomersFromAudienceType,
    newCampaignSMSFormSchema,
} from "#guestbook/screens/Campaigns/NewCampaign/newCampaignFormSchema";
import AudienceCardInput from "#guestbook/screens/Campaigns/NewCampaign/components/AudienceCardInput";
import MessageCardInput from "#guestbook/screens/Campaigns/NewCampaign/components/MessageCardInput";
import {
    NewCampaignStep,
    newCampaignStepAtom,
    storeDisplayNameAtom,
} from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";
import NewSMSFormReview from "#guestbook/screens/Campaigns/NewCampaign/components/NewSMSFormReview";
import { getActiveStore } from "src/redux/selectors";
import {
    GetStoreAudienceQuery,
    GetStoreAudienceResponse,
} from "src/api/graphql/queries";
import { CreateCampaign } from "src/api/graphql/mutations";
import {
    CampaignType,
    CreateCampaignInput,
    GetStoreCampaignsWithMetricsDocument,
} from "src/api/graphql/generated/types";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { constructFinalSMSMessage } from "#guestbook/screens/Campaigns/NewCampaign/constructFinalSMSMessage";
import { roundToPrecision } from "src/utils/roundToPrecision";

function NewCampaignForm() {
    const [step, setStep] = useRecoilState(newCampaignStepAtom);
    useEffect(() => {
        // Reset scroll position when step changes
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, [step]);
    const form = useForm<z.infer<typeof newCampaignSMSFormSchema>>({
        resolver: zodResolver(newCampaignSMSFormSchema),
        mode: "onTouched",
        defaultValues: {
            campaignName: "",
            segment: undefined,
            percentTarget: 100,
            estimatedCost: 0,
            message: "",
        },
    });
    const storeDisplayName = useRecoilValue(storeDisplayNameAtom);
    const [createCampaign, { loading, error }] = useMutation(CreateCampaign);
    const setNewCampaignStep = useSetRecoilState(newCampaignStepAtom);
    const onSubmit = async (
        formValues: z.infer<typeof newCampaignSMSFormSchema>,
    ) => {
        if (step === NewCampaignStep.SMSForm) {
            setStep(NewCampaignStep.SMSReview);
            return;
        }

        const sections = [
            {
                type: TemplateSectionType.Title,
                text: formValues.campaignName,
            },
            {
                type: TemplateSectionType.Body,
                text: constructFinalSMSMessage(
                    storeDisplayName,
                    formValues.message,
                ),
            },
        ];

        const template = {
            sections,
            variables: [],
        };
        const data: CreateCampaignInput = {
            title: formValues.campaignName,
            audienceType: formValues.segment,
            numberOfUsers: Math.round(
                formValues.estimatedCost / COST_PER_CUSTOMER,
            ),
            template,
            type: CampaignType.Announcement,
            isNewCampaignStyle: true,
        };

        const request = {
            storeId: activeStore?._id,
            data,
        };

        // This can throw, but it's not a big deal. "loading" and, "error" above will be updated from the useMutation and thus inform the user.
        const response = await createCampaign({
            variables: request,
            // refresh the main campaign table to include the newly created campaign. Can optimize this later if desired.
            refetchQueries: [GetStoreCampaignsWithMetricsDocument],
        });
        const campaignId = response.data?.createCampaign?.id;

        if (activeStore) {
            trackSegmentEvent(SegmentEvents.Guestbook.Campaigns.SENT, {
                store_id: activeStore._id,
                store_name: activeStore.name,
                campaign_id: campaignId,
            });
        }

        toast.success(
            "Campaign successfully created and will send momentarily",
        );
        setNewCampaignStep(null); // Go back to campaigns index;
    };

    const activeStore = useSelector(getActiveStore);

    const { data: audienceData, error: audienceError } =
        useQuery<GetStoreAudienceResponse>(GetStoreAudienceQuery, {
            variables: { storeId: activeStore?._id },
            skip: !activeStore,
        });
    useEffect(() => {
        if (audienceError) {
            toast.error("Failed to load audience data, please refresh", {
                description: audienceError.message,
            });
        }
    }, [audienceError]);

    useEffect(() => {
        if (error) {
            toast.error("Failed submit campaign, please try again.", {
                description: error.message,
            });
        }
    }, [error]);

    const setFormValue = form.setValue;
    const { segment } = form.watch();
    const numberOfCustomers =
        segment == null
            ? 0
            : getNumberCustomersFromAudienceType(segment, audienceData) ?? 0;
    useEffect(() => {
        if (segment != null) {
            setFormValue("percentTarget", 100, {
                shouldValidate: true,
                shouldTouch: true,
            });
            setFormValue(
                "estimatedCost",
                roundToPrecision(numberOfCustomers * COST_PER_CUSTOMER, 2),
                { shouldValidate: true, shouldTouch: true },
            );
        }
    }, [numberOfCustomers, segment, setFormValue]);
    return (
        <Form {...form}>
            <form
                className="flex flex-col"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <NewCampaignHeader isLoading={loading} />
                <div
                    className={`flex flex-col items-center ${RemoveScroll.classNames.fullWidth}`}
                >
                    {step === NewCampaignStep.SMSForm && (
                        <span className="flex max-w-screen-md flex-col gap-6 p-4">
                            <CampaignNameCardInput />
                            <AudienceCardInput audienceData={audienceData} />
                            <MessageCardInput />
                        </span>
                    )}
                    {step === NewCampaignStep.SMSReview && (
                        <span className="flex flex-col gap-6 p-4">
                            <NewSMSFormReview audienceData={audienceData} />
                        </span>
                    )}
                </div>
            </form>
        </Form>
    );
}

export default NewCampaignForm;
