import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { IProduct } from "@snackpass/snackpass-types";
import styled from "@emotion/styled";

import SparkleIcon from "src/assets/icons/sparkle-black.png";
import { Title } from "#guestbook/components/styled-components";

import { ProductRow } from "./product-row";

type OnSelect = (product: IProduct) => void;

export const RecentItems = ({
    onSelect,
    products,
}: {
    onSelect: OnSelect;
    products: IProduct[];
}) => (
    <div style={{ marginTop: 10, width: "100%" }}>
        <Title icon={SparkleIcon} label="Recently Added" />

        <ProductsContainer>
            {products.map((p, index) => (
                <ProductRow
                    key={p._id}
                    product={p}
                    index={index}
                    onClick={() => onSelect(p)}
                    isLast={products.length - 1 === index}
                />
            ))}
        </ProductsContainer>
    </div>
);

const ProductsContainer = styled.div`
    padding: 0 15px;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 8px;
`;
