/** @jsxImportSource @emotion/react */
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
import { Button, DatePicker, Form, Modal } from "antd";
import moment, { Moment } from "moment-timezone";
import React, { useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { css } from "@emotion/react";

//TODO: Migrating from Moment.js to Luxon

type SoldOutModalProps = {
    onHide: () => void;
    handleOk: (args: {
        soldOut: true;
        soldOutDates: {
            from: Date;
            until: Date;
        };
    }) => void;
    isMarkProductSoldOutModalOpen: boolean;
    storeTimeZone: "" | SnackpassTimezoneEnum;
};

const SoldOutModal = ({
    onHide,
    handleOk,
    isMarkProductSoldOutModalOpen,
    storeTimeZone,
}: SoldOutModalProps) => {
    const [soldOutUntil, setSoldOutUntil] = useState<Moment | null>(null);

    const updateSoldOut = () => {
        if (soldOutUntil) {
            handleOk({
                soldOut: true,
                soldOutDates: {
                    from: new Date(),
                    until: soldOutUntil.toDate(),
                },
            });
        }
    };

    const getDisabledDate = (current: Moment) => {
        const customDate = moment().format("YYYY-MM-DD");
        return current && current < moment(customDate, "YYYY-MM-DD");
    };

    const getDisabledHours = () => {
        if (
            soldOutUntil &&
            !soldOutUntil.isSame(moment().tz(storeTimeZone), "day")
        ) {
            return [];
        }
        const hours = [];
        const maxHour = moment().tz(storeTimeZone).hour();
        for (let i = 0; i < maxHour; i++) {
            hours.push(i);
        }
        return hours;
    };

    const getDisabledMinutes = (selectedHour: number) => {
        if (
            soldOutUntil &&
            !soldOutUntil.isSame(moment().tz(storeTimeZone), "day")
        ) {
            return [];
        }
        const minutes = [];
        if (selectedHour === moment().tz(storeTimeZone).hour()) {
            for (let i = 0; i < moment().tz(storeTimeZone).minute() + 1; i++) {
                minutes.push(i);
            }
        }
        return minutes;
    };

    return (
        <Modal
            centered
            css={ModalCSS}
            open={isMarkProductSoldOutModalOpen}
            onOk={updateSoldOut}
            onCancel={onHide}
        >
            <HeaderRow>
                <span className="header-text" onClick={onHide}>
                    Sold Out Status
                </span>
            </HeaderRow>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 15 }}
                layout="horizontal"
            >
                <>
                    <Row>
                        <Form.Item
                            label="Sold Out Until"
                            style={{
                                marginBottom: "0",
                            }}
                        >
                            <DatePicker
                                placeholder="Select sold out date"
                                showTime
                                size="large"
                                value={soldOutUntil}
                                onSelect={(date) =>
                                    setSoldOutUntil(
                                        date.tz(storeTimeZone, true),
                                    )
                                }
                                format="YYYY-MM-DD hh:mm a"
                                allowClear={false}
                                style={{
                                    borderRadius: "8px",
                                    flex: "1",
                                    width: "100%",
                                }}
                                defaultValue={moment(
                                    new Date(),
                                    "YYYY-MM-DD hh:mm a",
                                ).tz(storeTimeZone)}
                                disabledDate={getDisabledDate}
                                disabledHours={getDisabledHours}
                                disabledMinutes={getDisabledMinutes}
                            />
                        </Form.Item>
                    </Row>
                    <Row>
                        <span className="description">
                            {`Date & time shown are in the STORE'S timezone (${moment
                                .tz(storeTimeZone)
                                .zoneAbbr()})`}
                        </span>
                    </Row>
                    <Row>
                        <Button
                            className="markSoldOutBtn"
                            size="large"
                            style={{
                                borderRadius: "8px",
                                whiteSpace: "normal",
                                height: "auto",
                                marginBottom: "10px",
                                marginRight: "2px",
                            }}
                            onClick={() => {
                                setSoldOutUntil(
                                    moment().tz(storeTimeZone).endOf("day"),
                                );
                            }}
                        >
                            Sold out today
                        </Button>
                        <Button
                            className="markSoldOutBtn"
                            size="large"
                            style={{
                                borderRadius: "8px",
                                whiteSpace: "normal",
                                height: "auto",
                                marginBottom: "10px",
                                marginLeft: "2px",
                            }}
                            onClick={() => {
                                setSoldOutUntil(
                                    moment().tz(storeTimeZone).endOf("week"),
                                );
                            }}
                        >
                            Sold out this week
                        </Button>
                    </Row>
                </>
            </Form>
        </Modal>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    font-size: 16px;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
`;

const HeaderRow = styled.div`
    margin-bottom: 1rem;
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .description {
        margin-top: 5px;
        margin-bottom: 1rem;
        color: #a0adba;
        font-size: 12px;
    }
`;

export default SoldOutModal;
