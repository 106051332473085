import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Card, CardContent, CardDescription } from "src/@/components/ui/card";
import { Checkbox } from "src/@/components/ui/checkbox";
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import { campaignBrandRegistrationSMSFormSchema } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";
import { formatPhoneNumber } from "#settings/helper";
import { CampaignRegInlineStripeBilling } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/InlineStripeForm/CampaignRegInlineStripeBilling";

function Subtitle({ text }: { text: string }) {
    return <p className="text-muted-foreground">{text}</p>;
}

function CampaignBrandRegistrationReviewAndConfirmSMS() {
    const { getValues, control } =
        useFormContext<
            z.infer<typeof campaignBrandRegistrationSMSFormSchema>
        >();
    const values = getValues();
    return (
        <>
            <Card>
                <CardContent className="p-4 lg:p-6">
                    <div className="flex flex-col gap-6">
                        <p className="text-xl font-semibold">
                            Review and Confirm
                        </p>
                        <CardDescription>
                            Please check the information you have provided
                            matches your tax documents exactly. This includes
                            punctuation, spelling, capitalization, spacing, and
                            business structure (ie. INC, LLC, S corp). If the
                            below information does not match exactly, the
                            registration will fail and you will be charged an
                            additional registration fee.
                        </CardDescription>

                        <div>
                            <p className="font-semibold">Legal Business Name</p>
                            <Subtitle text={values.businessName} />
                        </div>
                        <div className="whitespace-pre-line">
                            <p className="font-semibold">Business Address</p>
                            <Subtitle
                                text={`${values.address.line1}${values.address.line2 ? `\n${values.address.line2}` : ""}\n${values.address.city}, ${values.address.state} ${values.address.zip}`}
                            />
                        </div>
                        <div>
                            <p className="font-semibold">Business Type</p>
                            <Subtitle text={values.businessType} />
                        </div>
                        <div>
                            <p className="font-semibold">
                                Federal Employer Identification Number (EIN)
                            </p>
                            <Subtitle text={values.ein} />
                        </div>
                        <div>
                            <p className="font-semibold">
                                Personal Contact Info
                            </p>
                            <Subtitle
                                text={`${values.contactInfo.firstName} ${values.contactInfo.lastName}`}
                            />
                            <Subtitle
                                text={`${values.contactInfo.email} ${formatPhoneNumber(values.contactInfo.phoneNumber)}`}
                            />
                        </div>
                        <div>
                            <p className="font-semibold">Desired Area Code</p>
                            <Subtitle text={values.desiredAreaCode} />
                        </div>
                        <div>
                            <p className="font-semibold">Sender Name</p>
                            <Subtitle text={values.displayName} />
                        </div>
                    </div>
                </CardContent>
                <div className="w-full bg-gray-50 p-4 px-6 text-gray-500">
                    <FormField
                        control={control}
                        name="reviewedInfo"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                                <FormControl>
                                    <Checkbox
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                        ref={field.ref}
                                    />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                    I agree the information provided above is
                                    correct.
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </Card>
            <Card>
                <CardContent className="p-4 lg:p-6">
                    <div className="flex flex-col gap-6">
                        <p className="text-xl font-semibold">Pricing</p>

                        <div>
                            <p className="font-semibold">
                                One-time Registration Fee
                            </p>
                            <Subtitle text={"$100"} />
                        </div>
                        <div>
                            <p className="font-semibold">
                                Monthly Fee<sup>1</sup>
                            </p>
                            <Subtitle text={"$15"} />
                        </div>
                        <div>
                            <p className="font-semibold">
                                Price per SMS<sup>2</sup>
                            </p>
                            <Subtitle text={"$0.03"} />
                        </div>
                        <div>
                            <p className="font-semibold">First Billing</p>
                            <Subtitle
                                text={
                                    "You will not be charged the Monthly Fee until your phone number has been fully registered. This may take up to 14 business days."
                                }
                            />
                        </div>
                        <div className="text-xs text-muted-foreground">
                            <ol className="ps-3">
                                <li>
                                    The monthly fee applies every month that the
                                    phone number is registered, regardless of
                                    SMS campaign usage. Even if you don't send
                                    any text messages one month, you'll still be
                                    charged the $15 monthly fee.
                                </li>
                                <li>
                                    SMS messages are limited to 2 segments.
                                    Depending on message content such as emojis
                                    and special characters, each message can be
                                    up to 300 characters including display name
                                    and opt-out text.
                                </li>
                            </ol>
                        </div>
                    </div>
                </CardContent>
                <div className="w-full bg-gray-50 p-4 px-6 text-gray-500">
                    <FormField
                        control={control}
                        name="agreedToPricing"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                                <FormControl>
                                    <Checkbox
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                        ref={field.ref}
                                    />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                    I agree to pricing terms.
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </Card>
            <CampaignRegInlineStripeBilling />
        </>
    );
}

export default CampaignBrandRegistrationReviewAndConfirmSMS;
