import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { useHistory } from "react-router-dom";
import { Cross2Icon, Pencil1Icon } from "@radix-ui/react-icons";
import _ from "lodash";

import { useMenus, useActiveMenus } from "#menu-manager/hooks";
import { DataTable } from "src/@/components/ui/data-table";
import { Switch } from "src/@/components/ui/switch";
import { StoreMenu } from "src/api/graphql/generated/types";
import { MenuManagerRoutes } from "#menu-manager/routes";
import { Skeleton } from "src/@/components/ui/skeleton";
import { Button } from "src/@/components/ui/button";
import { ConfirmDeleteMenuDialog } from "#menu-manager/components/dialog";
import { useMultipleActiveMenusEnabled } from "#navigation/utils";

export function MenuTable() {
    const { data, loading, error } = useMenus();
    const history = useHistory();

    const multipleActiveEnabled = useMultipleActiveMenusEnabled();

    useEffect(() => {
        if (error) toast.error(error.message);
    }, [error]);

    const { activeMenus, setActiveMenus, isPending } = useActiveMenus();
    const [deletingMenu, setDeletingMenu] = useState<
        Pick<StoreMenu, "id" | "name"> | undefined
    >(undefined);
    const clearDeletingMenu = useCallback(() => setDeletingMenu(undefined), []);

    const toggleMenuActive = useCallback(
        async (id: string, value: boolean) => {
            const newMenus = value
                ? _.union(activeMenus, [id])
                : _.difference(activeMenus, [id]);
            setWorkingActiveMenus(newMenus); // optimistic update
            void setActiveMenus(newMenus).catch((err) => {
                toast.error("Error setting menu active", {
                    description: err?.message,
                });
                setWorkingActiveMenus(activeMenus ?? []); // on failure, go back to actual value
            });
        },
        [activeMenus, setActiveMenus],
    );

    // XXX: When multiple active menus are 100% launched, this can be removed.
    const toggleSingleMenuActive = useCallback(
        async (id: string, checked: boolean) => {
            const newMenus = checked ? [id] : [];
            setWorkingActiveMenus(newMenus); // optimistic update
            void setActiveMenus(newMenus).catch((err) => {
                toast.error("Error setting menu active", {
                    description: err?.message,
                });
                setWorkingActiveMenus(activeMenus ?? []); // on failure, go back to actual value
            });
        },
        [activeMenus, setActiveMenus],
    );

    const [workingActiveMenus, setWorkingActiveMenus] = useState(
        activeMenus ?? [],
    );

    const rows: StoreMenuWithActive[] = useMemo(
        () =>
            data.map((e) => ({
                ...e,
                active: workingActiveMenus.includes(e.id),
                activePending: isPending,
            })),
        [data, isPending, workingActiveMenus],
    );

    const setActiveMenu = multipleActiveEnabled
        ? toggleMenuActive
        : toggleSingleMenuActive;
    const columns = useColumns({ setActiveMenu, setDeletingMenu });

    if (loading)
        return <Skeleton aria-label="skeleton" className="h-96 w-full" />;

    return (
        <>
            <ConfirmDeleteMenuDialog
                close={clearDeletingMenu}
                menu={deletingMenu}
            />
            <DataTable
                data={rows}
                columns={columns}
                className="rounded-none border-x-0"
                emptyText="No menus found."
                getRowClickListener={(row) => () =>
                    history.push(
                        MenuManagerRoutes.EDIT_MENU.replace(
                            ":id",
                            row.original.id,
                        ),
                    )
                }
            />
        </>
    );
}

type UseColumnsOptions = {
    setActiveMenu: (id: string, value: boolean) => void;
    setDeletingMenu: (menu: Pick<StoreMenu, "id" | "name">) => void;
};

// NB: We currently are storing active state of store menus on the store object itself
// if we ever move this state to being on the store object this proxy type can be removed
type StoreMenuWithActive = StoreMenu & {
    active?: boolean;
    activePending?: boolean;
};

function useColumns({
    setActiveMenu: setActiveMenuID,
    setDeletingMenu,
}: UseColumnsOptions): ColumnDef<StoreMenuWithActive>[] {
    return useMemo(
        () => [
            {
                header: () => <span className="md:ml-8">Active</span>,
                cell: ({ row }) => (
                    <Switch
                        className="md:ml-8"
                        disabled={row.original.activePending}
                        checked={row.original.active}
                        aria-label={`toggle whether ${row.original.name} is active`}
                        onCheckedChange={(checked) =>
                            setActiveMenuID(row.original.id, checked)
                        }
                        onClick={
                            (e) =>
                                e.stopPropagation() /* avoid propagating to parent's onclick that navigates away */
                        }
                    />
                ),
                id: "Active",
            },
            {
                header: "Name",
                accessorKey: "name",
            },
            {
                header: "Added",
                accessorKey: "createdAt",
                cell: ({ row }) =>
                    DateTime.fromISO(row.original.createdAt).toFormat(
                        "LLL dd, yyyy h:mma",
                    ),
            },
            {
                header: "Updated",
                accessorKey: "updatedAt",
                cell: ({ row }) =>
                    DateTime.fromISO(row.original.updatedAt).toFormat(
                        "LLL dd, yyyy h:mma",
                    ),
            },
            {
                id: "Actions",
                cell: ({ row }) => (
                    <div className="space-x-1">
                        {/* no need for onclick, the row has on click-- this is just to surface that you can edit to the user. */}
                        <Button variant="outline">
                            <Pencil1Icon />
                        </Button>

                        <Button
                            variant="outline"
                            className="text-red-500"
                            onClick={(e) => {
                                e.stopPropagation(); // prevent from the onclick going to the row click, which navigates away.
                                setDeletingMenu(row.original);
                            }}
                        >
                            <Cross2Icon />
                        </Button>
                    </div>
                ),
                size: 10,
            },
        ],
        [setActiveMenuID, setDeletingMenu],
    );
}
