import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { OrderStatusConfiguration } from "@snackpass/snackpass-types";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Routes } from "#navigation/routes";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";

export const Warnings = () => {
    const store = useSelector(getActiveStore);
    const [payoutsWarning, setPayoutsWarning] = useState<
        null | "warning" | "destructive"
    >(null);

    useEffect(() => {
        const checkIfVerified = async (storeId: string) =>
            api.verifications
                .status(storeId)
                .then(({ data }) =>
                    data.status === "none" ? null : data.status,
                )
                .catch(() => null);

        const checkAndSetPayoutsWarnState = async () => {
            if (store?._id) {
                const warning = await checkIfVerified(store._id);
                setPayoutsWarning(warning);
            }
        };

        void checkAndSetPayoutsWarnState();
    }, [store?._id]);

    const warnables = {
        payouts: Boolean(payoutsWarning),
        autoComplete:
            store?.orderStatusConfiguration ===
                OrderStatusConfiguration.AutoAcceptAutoComplete ||
            store?.orderStatusConfiguration ===
                OrderStatusConfiguration.ManualAcceptAutoComplete,
        defaultPin: !store?.hasEmployeeAuditTracking,
        dynamicWaitTime: store?.waitTimeType === "DYNAMIC",
        specificPickupTime: store?.pickupTimeType === "specific",
    };

    if (Object.values(warnables).includes(true)) {
        return (
            <Alert variant={"destructive"} className="mb-6">
                <AlertTitle className="text-base">
                    <span>Attention Required</span>
                </AlertTitle>
                <AlertDescription>
                    <ul className="mb-0">
                        {warnables.payouts && (
                            <li>
                                Please review the Payouts Settings page&emsp;
                                <Link to={Routes.Payouts}>here</Link> for more
                                information.
                            </li>
                        )}
                        {warnables.autoComplete && (
                            <li>
                                The 'Use ETA To Mark Orders Ready' setting is
                                now deprecated. For better accuracy please turn
                                off this feature&emsp;
                                <Link to={Routes.SettingsBackOfHouse}>
                                    here
                                </Link>
                                &emsp;and manually mark orders as ready for
                                pickup.
                            </li>
                        )}
                        {warnables.defaultPin && (
                            <li>
                                The 'Use Default Pin' setting is now deprecated.
                                To enhance security, please enable 'Require
                                Employee Pin Entry'&emsp;
                                <Link to={Routes.SettingsBackOfHouse}>
                                    here
                                </Link>
                                , ensuring employees use unique PINs for
                                auditable tasks and protecting your device from
                                easily guessed pins.
                            </li>
                        )}
                        {warnables.dynamicWaitTime && (
                            <li>
                                The 'Dynamic Wait Time' setting is now
                                deprecated. Please disable Dynamic Wait
                                Time&emsp;
                                <Link to={Routes.SettingsAllOrders}>here</Link>
                                &emsp;and switch to Manual Time entry for more
                                accurate wait time management at your store.
                            </li>
                        )}
                        {warnables.specificPickupTime && (
                            <li>
                                The 'Specific Wait Time' setting is now
                                deprecated. Please disable Specific Wait
                                Time&emsp;
                                <Link to={Routes.SettingsOnlineOrders}>
                                    here
                                </Link>
                                &emsp;and switch to a time range for more
                                accurate wait time management at your store.
                            </li>
                        )}
                    </ul>
                </AlertDescription>
            </Alert>
        );
    }

    return null;
};
