import { Link } from "react-router-dom";
import styled from "styled-components";

export const UnstyledLink = styled(Link)`
    text-decoration: inherit;
    color: inherit;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: inherit;
        color: inherit;
    }
`;
