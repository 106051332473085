import type { Moment } from "moment";
import { ITimeRangeSchema } from "@snackpass/snackpass-types";

import { convertTimeStringToNumberFormat } from "#menu-editor/utils";

export enum DayNumberEnum {
    Monday = 0,
    Tuesday = 1,
    Wednesday = 2,
    Thursday = 3,
    Friday = 4,
    Saturday = 5,
    Sunday = 6,
}

export enum DayStringEnum {
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
    Sunday = "sunday",
}

export type SelectedDaysType = { [key in DayStringEnum]: boolean } & {
    everyDay: boolean;
};

export type TimeRangeSchema = {
    start: Moment | null | undefined;
    end: Moment | null | undefined;
};
export type MenuHoursType = {
    hours: TimeRangeSchema;
    id: number;
};
export const DAY_OPTIONS = [
    {
        label: "Monday",
        value: 0,
    },
    {
        label: "Tuesday",
        value: 1,
    },
    {
        label: "Wednesday",
        value: 2,
    },
    {
        label: "Thursday",
        value: 3,
    },
    {
        label: "Friday",
        value: 4,
    },
    {
        label: "Saturday",
        value: 5,
    },
    {
        label: "Sunday",
        value: 6,
    },
];

export const constants = {
    DAYS_OF_WEEK_NUMBER: [
        DayNumberEnum.Monday,
        DayNumberEnum.Tuesday,
        DayNumberEnum.Wednesday,
        DayNumberEnum.Thursday,
        DayNumberEnum.Friday,
        DayNumberEnum.Saturday,
        DayNumberEnum.Sunday,
    ],
    DAYS_OF_WEEK_STRING: Object.values(DayStringEnum),
    DEFAULT_SELECTED_DAYS: {
        [DayStringEnum.Monday]: false,
        [DayStringEnum.Tuesday]: false,
        [DayStringEnum.Wednesday]: false,
        [DayStringEnum.Thursday]: false,
        [DayStringEnum.Friday]: false,
        [DayStringEnum.Saturday]: false,
        [DayStringEnum.Sunday]: false,
        everyDay: true,
    },
};

export const getSelectedDays = (days: SelectedDaysType): number[] => {
    if (days.everyDay) return constants.DAYS_OF_WEEK_NUMBER;
    const selectedDays: number[] = [];
    if (days.monday) selectedDays.push(DayNumberEnum.Monday);
    if (days.tuesday) selectedDays.push(DayNumberEnum.Tuesday);
    if (days.wednesday) selectedDays.push(DayNumberEnum.Wednesday);
    if (days.thursday) selectedDays.push(DayNumberEnum.Thursday);
    if (days.friday) selectedDays.push(DayNumberEnum.Friday);
    if (days.saturday) selectedDays.push(DayNumberEnum.Saturday);
    if (days.sunday) selectedDays.push(DayNumberEnum.Sunday);
    return selectedDays;
};

export const buildSelectedDays = (days: Set<number>): SelectedDaysType => {
    const selectedDays: SelectedDaysType = {
        ...constants.DEFAULT_SELECTED_DAYS,
    };
    if (days.size === 7) return selectedDays;
    days.forEach((day) => {
        switch (day) {
            case DayNumberEnum.Monday:
                selectedDays.monday = true;
                break;
            case DayNumberEnum.Tuesday:
                selectedDays.tuesday = true;
                break;
            case DayNumberEnum.Wednesday:
                selectedDays.wednesday = true;
                break;
            case DayNumberEnum.Thursday:
                selectedDays.thursday = true;
                break;
            case DayNumberEnum.Friday:
                selectedDays.friday = true;
                break;
            case DayNumberEnum.Saturday:
                selectedDays.saturday = true;
                break;
            case DayNumberEnum.Sunday:
                selectedDays.sunday = true;
                break;
            default:
                break;
        }
    });
    selectedDays.everyDay = false;
    return selectedDays;
};

export const formatHoursPerDay = (
    hours: MenuHoursType[],
    selectedDays: number[],
) => {
    const newHours: ITimeRangeSchema[] = [];

    selectedDays.forEach((day) => {
        hours.forEach((item) => {
            if (item.hours.start && item.hours.end) {
                newHours.push({
                    start: convertTimeStringToNumberFormat(
                        day,
                        item.hours.start,
                    ),
                    end: convertTimeStringToNumberFormat(day, item.hours.end),
                });
            }
        });
    });

    return newHours;
};
