import {
    MenuFormValues,
    PriceAdjustmentPolarity,
} from "#menu-manager/components/menu-form/schema";
import {
    PriceAdjustmentType,
    StoreMenu,
    StoreMenuInput,
} from "src/api/graphql/generated/types";

export enum MenuFormTitles {
    Name = "Name",
    PriceAdjustment = "Default Price Adjustment",
    Items = "Items",
}

export const DEFAULT_MENU_VALUES: MenuFormValues = {
    name: "",
    categories: [],
    productExclusions: [],
    priceAdjustment: {
        enabled: false,
        polarity: PriceAdjustmentPolarity.MARKUP,
        roundTo: null, // currently unused, though there is sdk support for calculating the adjustment with this value.
        type: PriceAdjustmentType.Percentage, // currently always set to Percentage.
        value: 0,
        adjustModifiers: true,
    },
};

export const formValuesToInputType = ({
    priceAdjustment,
    ...values
}: MenuFormValues): StoreMenuInput => ({
    ...values,
    priceAdjustment: priceAdjustment.enabled
        ? {
              adjustmentType: priceAdjustment.type,
              value:
                  priceAdjustment.value *
                  (priceAdjustment.polarity === PriceAdjustmentPolarity.MARKUP
                      ? 1
                      : -1),
              roundTo: priceAdjustment.roundTo,
              adjustModifiers: priceAdjustment.adjustModifiers,
          }
        : null,
});

export const storeMenuToFormValues = (
    menu: StoreMenu,
): Partial<MenuFormValues> => ({
    ...DEFAULT_MENU_VALUES,
    name: menu.name,
    categories: menu.categories.filter<string>((e): e is string => !!e) ?? [],
    productExclusions:
        menu.productExclusions.filter((e): e is string => !!e) ?? [],
    ...(menu.priceAdjustment
        ? {
              priceAdjustment: {
                  enabled: true,
                  value: Math.abs(menu.priceAdjustment.value),
                  adjustModifiers: menu.priceAdjustment.adjustModifiers,
                  type: menu.priceAdjustment.adjustmentType,
                  roundTo: menu.priceAdjustment.roundTo,
                  polarity:
                      menu.priceAdjustment.value <= 0
                          ? PriceAdjustmentPolarity.MARKDOWN
                          : PriceAdjustmentPolarity.MARKUP,
              },
          }
        : {}),
});
