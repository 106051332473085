import React from "react";
import { ThemeProvider } from "styled-components";

import { Steps } from "#onboarding/components/steps";
import OnboardingProvider from "#onboarding/utils/OnboardingContext";
import Styles from "#onboarding/utils/Styles";
import theme from "#onboarding/utils/theme";

export const Onboarding = () => (
    <ThemeProvider theme={theme}>
        <Styles />
        <OnboardingProvider>
            <Steps />
        </OnboardingProvider>
    </ThemeProvider>
);
