import React, { useMemo } from "react";

import "@djthoms/pretty-checkbox";
import { usePaymentTypePicker } from "#hooks/use-payment-type-picker";
import {
    DashboardPaymentTypes,
    DefinedPaymentTypes,
} from "#payment-type-picker/lib";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReactComponent as WalletIcon } from "src/assets/icons/wallet.svg";
import { Button } from "src/@/components/ui/button";

const PaymentTypePicker = () => {
    const { paymentType, setGlobalPaymentType } = usePaymentTypePicker();

    const selectedPaymentTypes = useMemo(
        () => new Set([paymentType]),
        [paymentType],
    );

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <WalletIcon className="h-4 w-4" />
                    <span className="hidden md:block">Payment Type</span>
                </Button>
            }
            title="Payment Type"
            selectedValues={selectedPaymentTypes}
            options={DefinedPaymentTypes}
            onOptionSelected={function (value: string): void {
                setGlobalPaymentType(value as DashboardPaymentTypes);
            }}
        />
    );
};

export default PaymentTypePicker;
