import { DateTime } from "luxon";
import { parse, addDays, format, isValid } from "date-fns";

export const formatMinutes = (minutes: number): string =>
    DateTime.now().startOf("day").plus({ minutes: minutes }).toFormat("HH:mm");

export const timeToLuxon = (newTime: string) => {
    const [hours, minutes] = newTime.split(":");
    const newMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return DateTime.now().startOf("day").plus({ minutes: newMinutes });
};

export const getDayMinutesFromLuxon = (dateObject: DateTime) =>
    dateObject.diff(dateObject.startOf("day")).as("minutes");

export const formatDateWithNextDay = (dateString: string) => {
    const parsedDate = parse(dateString, "PPP", new Date());
    if (!isValid(parsedDate)) {
        return "Invalid Date";
    }
    const newDate = addDays(parsedDate, 1);
    return format(newDate, "PPP");
};

export const formatDateWithPrevDay = (dateString: string) => {
    const parsedDate = parse(dateString, "PPP", new Date());
    if (!isValid(parsedDate)) {
        return "Invalid Date";
    }
    const newDate = addDays(parsedDate, -1);
    return format(newDate, "PPP");
};
