import { notification } from "antd";

export const notifyFailure = (props: {
    message: string;
    description: string;
}) =>
    notification.error({
        placement: "top",
        message: props.message,
        description: props.description,
    });

export const notifySuccess = (props: {
    message: string;
    description: string;
}) =>
    notification.success({
        placement: "top",
        message: props.message,
        description: props.description,
        duration: 20,
    });
