import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";
import { CartesianGrid, Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatHour,
    formatNumber,
    formatRange,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import { ComparisonType } from "#reports/sales-summary/types";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType,
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { SalesSummaryLegend } from "#reports/sales-summary/components/SalesSummaryLegend";
import { SalesSummaryTooltip } from "#reports/sales-summary/components/SalesSummaryTooltip";
import { calculateChartWidth } from "#utils/helpers";
import { useSalesReport, useDisplayedCategories } from "#reports/hooks";
import { ChartConfig, ChartContainer } from "src/@/components/ui/chart";
import colors from "#reusable/colors/colors.json";

const chartConfig = {
    netSales: {
        label: "Net Sales",
    },
    orders: {
        label: "Orders",
    },
} satisfies ChartConfig;

export const SalesHourlyChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { comparison, dateRanges, granularity } = reportsState;
    const { data: reportsData, isLoading, error } = useSalesReport();
    const data = reportsData?.salesHourlyAggregateReport;
    const compareToData = reportsData?.salesHourlyAggregateReportComparedTo;
    const shouldCompare = comparison != ComparisonType.NONE;

    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    const chartData = useMemo(
        () =>
            data
                ? data.map((row, idx) => ({
                      label: formatHour(idx),
                      range: formatRange(dateRanges[0], granularity),
                      netSales: row?.netSales,
                      orders: row?.orders,

                      ...(shouldCompare
                          ? {
                                compareToRange: formatRange(
                                    dateRanges[1],
                                    granularity,
                                ),
                                compareToNetSales:
                                    compareToData?.[idx]?.netSales,
                                compareToOrders: compareToData?.[idx]?.orders,
                            }
                          : {}),
                  }))
                : [],
        [data, compareToData, shouldCompare, dateRanges, granularity],
    );

    const displayedCategories = useDisplayedCategories(
        shouldCompare,
        graphType,
    );

    const yWidth = (): number => {
        const maxNetSales = Math.max(...chartData.map((item) => item.netSales));
        return calculateChartWidth(maxNetSales, true);
    };

    const valueFormatter =
        graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    return (
        <div className="my-10">
            <div className="flex items-center justify-between">
                <h4 className="mb-2 text-large">Time of Day</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <SalesSummaryLegend />
            <div className="h-96 w-full">
                {match({ isLoading, hasError: !!error })
                    .with({ isLoading: true }, () => (
                        <Skeleton className="h-96" />
                    ))
                    .with({ hasError: true }, () => (
                        <ErrorChart className="h-96 rounded-md" />
                    ))
                    .otherwise(() => (
                        <ChartContainer
                            className="h-full w-full"
                            config={chartConfig}
                        >
                            <BarChart accessibilityLayer data={chartData}>
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={valueFormatter}
                                    width={yWidth()}
                                />
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="label"
                                    tickLine={false}
                                    axisLine={false}
                                    interval={1}
                                    padding={{ left: 18 }}
                                />
                                <Tooltip content={SalesSummaryTooltip} />
                                {displayedCategories.map((category, idx) => (
                                    <Bar
                                        key={category}
                                        dataKey={category}
                                        fill={
                                            idx === 0
                                                ? colors["blue-500"]
                                                : colors["yellow-500"]
                                        }
                                    />
                                ))}
                            </BarChart>
                        </ChartContainer>
                    ))}
            </div>
        </div>
    );
};
