import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
import validator from "validator";
import { z } from "zod";

const ProvidedLiteral = z.string().regex(/Provided/i);
const PendingLiteral = z.string().regex(/Pending/i);

export const CompanyNameSchema = z.union([
    z.string().min(3),
    ProvidedLiteral,
    PendingLiteral,
]);
// Note: This must be kept in line with server-side slug validation
export const CompanySlugSchema = z.string().regex(/^[a-z0-9]+$/);
export const CompanyBioSchema = z.string().min(3);
export const CompanyLogoUrlSchema = z.string().min(3);
export const CompanyImageUrlSchema = z.union([
    z.literal(""),
    z.string().min(3),
]);
export const CompanyTaxIdSchema = z.union([
    z.literal(true),
    ProvidedLiteral,
    z.string().regex(/^\d{2}-?\d{7}$/), // EIN
    z.string().regex(/^\d{3}-\d{2}-\d{4}$/), // SSN
]);

export const Line1Schema = z.string().min(3);
export const Line2Schema = z
    .union([z.string().min(0), z.string().nullish()])
    .optional();
export const CitySchema = z.string().min(3);
export const StateSchema = z.string().length(2);
export const PostalCodeSchema = z.string().regex(/^\d{5}$/);
export const CountrySchema = z.string().min(2).default("USA");
export const AddressSchema = z.object({
    line1: Line1Schema,
    line2: Line2Schema,
    city: CitySchema,
    state: StateSchema,
    zip: PostalCodeSchema,
    country: CountrySchema,
});

export const PhoneSchema = z.union([
    z.string().regex(/^\d{3}[ -]?\d{3}[ -]?\d{4}$/), // 301-996-8000
    z.string().regex(/^\(\d{3}\)[ -]?\d{3}[ -]?\d{4}$/), // (301) 996 8000
    z.string().regex(/^\+1\d{10}$/), // +13019968000
    PendingLiteral,
]);

const decimalRegex = /^(\d+(\.\d{1,3})?)%?$/;
export const TaxRateSchema = z
    .number()
    .min(0)
    .max(20)
    .refine((v) => decimalRegex.test(v.toString()));

export const EmailSchema = z.string().email();

export const KindSchema = z.union([
    z.literal("food_truck"),
    z.literal("brick_and_mortar"),
    z.literal("other"),
]);

// UTC is the default timezone set when a store is created by the
// onboarding-v2 webhook. Force users to set a valid timezone (see:
// src/modules/onboarding/utils/timezones.ts) before they can save.
export const TimezoneSchema = z
    .string()
    .refine((s) =>
        (Object.values(SnackpassTimezoneEnum) as string[]).includes(s),
    );

export const UrlSchema = z.string().min(4);

// https://www.npmjs.com/package/validator
export const urlParse = (v: string) =>
    validator.isURL(v, {
        protocols: ["https"],
        require_protocol: true,
        require_valid_protocol: true,
        require_host: true,
        require_port: false,
        require_tld: true,
        allow_query_components: false,
        allow_fragments: false,
        allow_protocol_relative_urls: false,
    });

const HourSchema = z.object({
    start: z.number(),
    end: z.number(),
});
export const HoursSchema = z.array(HourSchema).min(1);

export const LocationSchema = z.object({
    name: CompanyNameSchema,
    phone: PhoneSchema,
    email: EmailSchema,
    slug: CompanySlugSchema,
    logoUrl: CompanyLogoUrlSchema,
    digitalReceiptUrl: CompanyImageUrlSchema.nullable().optional(),
    tipImageUrl: CompanyImageUrlSchema.nullable().optional(),
    attractScreenUrl: CompanyImageUrlSchema.nullable().optional(),
    thumbnailUrl: CompanyImageUrlSchema.nullable().optional(),
    slugAvailable: z.literal(true),
    address: AddressSchema.required(),
    timezone: TimezoneSchema,
    taxRate: TaxRateSchema,
    hours: HoursSchema,
});

export const BusinessSchema = z.object({
    name: CompanyNameSchema,
    taxId: CompanyTaxIdSchema,
    phone: PhoneSchema,
});

export const ValidNameRegex = /^(?:[a-zA-Z0-9]+['-, ]?\.? ?[a-zA-Z0-9]*)*$/;
export const NameSchema = z.string().min(1).regex(ValidNameRegex);

export const RepresentativeSchema = z.object({
    firstName: NameSchema,
    lastName: NameSchema,
    phone: PhoneSchema,
});
