/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";

export const HeaderTitle = styled.div`
    display: flex;
    flex-direction: row;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${SystemColors.v2.salt30.dark};
    user-select: none;
    gap: 16px;

    @media ${ScreenState.MOBILE} {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
    }
`;

export const BodySectionWrapper = styled.div`
    grid-area: Body;
    display: flex;
    flex-direction: column;
    padding: 40px 48px;
    width: 100%;
    justify-self: center;
    overflow-y: auto;
    align-content: end;
    gap: 20px;

    @media ${ScreenState.MOBILE} {
        padding: 20px 16px;
    }
`;

export const BodySections = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    justify-self: center;
    border: 2px solid red;

    @media ${ScreenState.MOBILE} {
        gap: 20px;
    }
`;

export const BodySection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
`;

export const BodySectionBoldText = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    color: ${SystemColors.v2.salt30.dark};

    @media ${ScreenState.MOBILE} {
        width: 100%;
        font-size: 36px;
        line-height: 44px;
    }
`;

export const BodySectionTitle = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${SystemColors.v2.salt30.dark};
    user-select: none;
`;
export const BodySectionTileTitle = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${SystemColors.v2.salt30.dark};
`;

export const BodySectionRegularText = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${SystemColors.v2.salt30.dark};
`;

export const DescriptionText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${SystemColors.v1.gray30};
`;

export const CharacterCountErrorText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-top: 0px;
    color: ${SystemColors.v1.melon50};
`;

interface BodySectionContentProps {
    directionRow?: boolean;
    mobileDirectionRow?: boolean;
    justifyContent?: string;
}

export const BodySectionContent = styled.div<BodySectionContentProps>`
    display: flex;
    flex-direction: ${({ directionRow }) => (directionRow ? "row" : "column")};
    justify-content: ${({ justifyContent }) =>
        justifyContent ? { justifyContent } : "space-between"};
    gap: 16px;
    width: 100%;
    flex-wrap: wrap;

    @media ${ScreenState.MOBILE} {
        flex-direction: ${({ mobileDirectionRow }) =>
            mobileDirectionRow ? "row" : "column"};
    }
`;

interface TileWrapperProps {
    isSelected?: boolean;
    disabled?: boolean;
    directionRow?: boolean;
}

export const TileWrapper = styled.div<TileWrapperProps>`
    display: flex;
    flex-direction: ${({ directionRow }) => (directionRow ? "row" : "column")};
    justify-content: space-between;
    font-family: "Inter";
    font-style: normal;
    border: ${({ isSelected }) =>
        isSelected
            ? `3px solid ${SystemColors.v2.salt30.dark}`
            : `1px solid ${SystemColors.v1.gray80}`};
    padding: ${({ isSelected }) => (isSelected ? `22px` : `24px`)};
    border-radius: 16px;
    gap: ${({ directionRow }) => (directionRow ? `16px` : `8px`)};
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    flex: 1 0 45%;

    @media ${ScreenState.MOBILE} {
        flex: 1 0 10%;
        padding: ${({ isSelected }) => (isSelected ? `15px` : `17px`)};
    }

    &:hover {
        background-color: ${({ disabled }) =>
            disabled ? "" : `${SystemColors.v1.gray90}`};
    }

    .tile-right {
        display: flex;
        flex-direction: column;
        flex: 1 0 70%;
        gap: 4px;
    }

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${SystemColors.v2.salt30.dark};

    .tile-subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: ${SystemColors.v2.salt30.dark};
    }
`;

interface ButtonProps {
    height?: string;
    width?: string;
    mobileHeight?: string;
    mobileWidth?: string;
    isDisabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 12px;
    gap: 8px;
    background-color: ${({ isDisabled }) =>
        isDisabled
            ? `${SystemColors.v2.apricot5.dark}`
            : `${SystemColors.v2.candy50.light}`};
    height: ${({ height }) => (height ? `${height}` : "fit-content")};
    width: ${({ width }) => (width ? `${width}` : `100%`)};

    @media ${ScreenState.MOBILE} {
        width: ${({ mobileWidth }) =>
            mobileWidth ? `${mobileWidth}` : `100%`};
    }

    cursor: pointer;

    border-radius: 56px;

    &:hover {
        background-color: ${({ isDisabled }) =>
            isDisabled
                ? `${SystemColors.v2.apricot5.dark}`
                : `${SystemColors.v1.candy60}`};
    }

    .desktop {
        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }

    .text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: ${SystemColors.v1.white};
    }
`;

type TypeButtonProps = {
    isSelected: boolean;
    width?: string;
    padding?: string;
};

export const TypeButton = styled.div<TypeButtonProps>`
    margin-top: 10px;
    width: ${({ width }) => (width ? width : "100%")};
    color: ${({ isSelected }) =>
        isSelected ? SystemColors.v1.white : SystemColors.v1.sesame};
    background-color: ${({ isSelected }) =>
        isSelected ? SystemColors.v2.salt30.dark : SystemColors.v1.white};
    border-radius: 8px;
    border: ${({ isSelected }) =>
        isSelected
            ? `3px solid ${SystemColors.v2.salt30.dark}`
            : `1px solid ${SystemColors.v1.gray80}`};
    padding: ${({ padding }) => (padding ? padding : "7px 15px")};
    align-self: stretch;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    font-weight: 600;
    text-align: center;
    &:hover {
        box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.05);
    }
`;
