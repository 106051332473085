import { useState, useCallback } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";
import moment from "moment";
import { getStoreTimezone } from "#utils/helpers";
import api from "src/api/rest";

interface HourlyData {
    hour: string;
    netSales: number;
    tips: number;
    tipPercentage: string;
}

interface HourlyReportResponse {
    data: {
        hour: string;
        netSales: number;
        tips: number;
    }[];
}

interface UseBatchReportsResult {
    isLoading: boolean;
    error: boolean;
    totalTips: number;
    totalSales: number;
    tipPercentage: string;
    hourlyData: HourlyData[];
    fetchBatchReport: (startDate: Date, endDate: Date) => Promise<void>;
}

export const useBatchReports = (): UseBatchReportsResult => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [totalTips, setTotalTips] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [hourlyData, setHourlyData] = useState<HourlyData[]>([]);

    const store = useSelector(getActiveStore);

    const fetchBatchReport = useCallback(
        async (startDate: Date, endDate: Date) => {
            if (!store?._id) {
                console.error("No store ID found");
                return;
            }

            try {
                setIsLoading(true);
                setError(null);

                const params = {
                    storeId: store._id,
                    startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
                    timezone: getStoreTimezone(store),
                    channel: "[]",
                    source: "[]",
                    fulfillment: "[]",
                };

                const response = await api.reports.getSalesByHour(params);
                const data = response.data;
                console.log(data);

                // Transform the response data
                const hourlyData = response.data.data.map((hour) => ({
                    hour: moment(hour.hour).format("MM/DD/YYYY HH:mm"),
                    netSales: hour.netSales,
                    tips: hour.tips,
                    tipPercentage:
                        hour.netSales > 0
                            ? ((hour.tips / hour.netSales) * 100).toFixed(1)
                            : "0.0",
                }));

                setHourlyData(hourlyData);

                // Calculate totals
                const totalSales = hourlyData.reduce(
                    (sum, hour) => sum + hour.netSales,
                    0,
                );
                const totalTips = hourlyData.reduce(
                    (sum, hour) => sum + hour.tips,
                    0,
                );

                setTotalSales(totalSales);
                setTotalTips(totalTips);
            } catch (err) {
                if (!axios.isCancel(err)) {
                    console.error("Error in fetchBatchReport:", err);
                    logAndSendError(err);
                    setError(err as Error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [store],
    );

    // Calculate tip percentage
    const tipPercentage =
        totalSales > 0 ? ((totalTips / totalSales) * 100).toFixed(1) : "0.0";

    return {
        isLoading,
        error: !!error,
        totalTips,
        totalSales,
        tipPercentage,
        hourlyData,
        fetchBatchReport,
    };
};
