import { useSelector } from "react-redux";

import {
    getApplyAdjustmentSelector,
    getPriceAdjustmentSelector,
    getPriceOverrideSelector,
} from "#menu-editor/multi-menus/redux/selectors";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

interface PriceTextProps {
    itemId: string;
    itemPrice: number;
    itemType: "products" | "addons";
}

export const PriceText = ({ itemId, itemPrice, itemType }: PriceTextProps) => {
    const priceOverrideSelector = getPriceOverrideSelector(itemType, itemId);
    const override = useSelector(priceOverrideSelector);

    const priceAdjustmentSelector = getPriceAdjustmentSelector(itemType);
    const priceAdjustment = useSelector(priceAdjustmentSelector);

    const applyAdjustment = useSelector(getApplyAdjustmentSelector);

    const overridePrice =
        applyAdjustment && override
            ? Math.round(override * (priceAdjustment ?? 1) * 100) / 100
            : override;

    const basePrice =
        Math.round(itemPrice * (priceAdjustment ?? 1) * 100) / 100;

    const displayPrice = (overridePrice ?? basePrice).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    return (
        <Text type="body-regular" color={overridePrice ? "black" : "grey"}>
            {displayPrice}
        </Text>
    );
};
