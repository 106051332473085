import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import styledComponents from "styled-components";
import { useEffect, useState } from "react";
import { toast } from "sonner";

import colors from "#reusable/colors/colors.json";
import { Form, FormField } from "src/@/components/ui/form";
import { FormCard } from "src/@/components/form-card";
import { getUser } from "src/redux/selectors";
import { useCloudinaryUploadWidget } from "#reusable/upload/useCloudinaryUploadWidget";
import { ReactComponent as MediaIcon } from "src/assets/icons/media.svg";
import { useUpdateSelf } from "#settings/hooks/use-update-self";

export const ImageSchema = z.object({
    profilePicUrl: z.string().optional(),
});

const MAX_MB = 5000000; // 5MB

export function ShowEditPicture() {
    const user = useSelector(getUser);
    const { isLoading, isSuccess, mutate, error } = useUpdateSelf();
    const [imgUrl, setImgUrl] = useState(user?.profilePicUrl ?? "");

    const form = useForm<z.infer<typeof ImageSchema>>({
        resolver: zodResolver(ImageSchema),
        defaultValues: {
            profilePicUrl: user?.profilePicUrl ?? "",
        },
    });

    const onSubmit = () => {
        mutate(form.getValues());
    };

    useEffect(() => {
        if (error) {
            toast.error("Unable to update profile picture", {
                description: error?.message || "Attempt failed",
            });
        }
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            toast.success("Update saved!");
            // we need to reload the site to get user updates
            // currently refetching the user leaves the site blank
            window.location.reload();
        }
    }, [isSuccess]);

    const { UploadButton } = useCloudinaryUploadWidget({
        preset: "user-profile-image",
        folder: "user-profile-image",
        clientAllowedFormats: ["jpg", "png"],
        maxImageFileSize: MAX_MB,
    });

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full max-w-[900px]"
            >
                <FormCard
                    title="Profile Picture"
                    subtitle="Help teammates recognize you more easily."
                    footer="A profile picture is optional but encouraged."
                    disabled={isLoading || !form.formState.isDirty}
                    showSaveButton
                >
                    <FormField
                        control={form.control}
                        name="profilePicUrl"
                        render={({ field: { onChange } }) => (
                            <div className="flex items-center space-x-3">
                                <Image value={imgUrl} />
                                <Container disabled={false}>
                                    <UploadButton
                                        onUpload={(info) => {
                                            onChange(info.secure_url);
                                            setImgUrl(info.secure_url);
                                        }}
                                        cropping
                                    >
                                        {user?.profilePicUrl
                                            ? "Change Image"
                                            : "Upload Image"}
                                    </UploadButton>
                                </Container>
                            </div>
                        )}
                    />
                </FormCard>
            </form>
        </Form>
    );
}

const Image = ({ value }: { value: string }) => (
    <div
        className="flex h-24 min-h-24 w-24 min-w-24 items-center justify-center rounded-full bg-neutral-200 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${value})` }}
    >
        {!value ? (
            <MediaIcon width={33} height={33} fill={colors["neutral-600"]} />
        ) : null}
    </div>
);

const Container = styledComponents.div<{ disabled: boolean }>`
    .cloudinary-button {
        color: ${colors["black"]};
        cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
        opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
    }
`;
