import { DeviceType } from "#devices/utils/deviceTypes";
import EditSection from "#devices/components/EditDrawer/EditSection";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { DeleteSection } from "#devices/components/EditDrawer/DeleteSection";
import { EditDeviceName } from "#devices/components/EditDrawer/Settings";
import { EditDeviceType } from "#devices/components/EditDrawer/Settings/DeviceType";
import { ToggleField } from "#devices/components/EditDrawer/Settings/ToggleField";
import { CashDrawerEditSection } from "#devices/components/CashDrawerEditSection";
import { SelectField } from "#devices/components/EditDrawer/Settings/SelectField";
import { usePrepStationIndependentCompletionEnabled } from "#navigation/utils";
import { usePrepStations } from "#prep-stations/hooks";

const deviceTypeOptions = [DeviceType.Kiosk].map((option) => ({
    value: option,
    label: getDeviceTypeName(option) || "--",
}));

const generateOptions = (options: { value: string; label: string }[]) =>
    [{ value: "NONE_SELECTED", label: "None Selected" }].concat(options);

export default (): JSX.Element => {
    const independentCompletionEnabled =
        usePrepStationIndependentCompletionEnabled();
    const { prepStations } = usePrepStations();
    const prepStationOptions = generateOptions(
        prepStations?.map((d) => ({ value: d.id, label: d.name })) ?? [],
    );

    return (
        <>
            <EditSection heading="Info">
                <EditDeviceName />
                <EditDeviceType disabled options={deviceTypeOptions} />
            </EditSection>

            <EditSection heading="Settings">
                <ToggleField
                    id="cardPaymentsToggle"
                    label="Allow Card Payment"
                    description="Customers can pay with a debit/credit card when ordering from this kiosk"
                    field="allowCardPayments"
                />
                <ToggleField
                    id="cashModeToggle"
                    label="Allow Cash Payment"
                    description="Customers can pay with cash when ordering from this kiosk"
                    field="allowCashPayments"
                />
                <ToggleField
                    id="otherPaymentsToggle"
                    label="Allow Other Payment"
                    description="Customers can pay with 'other' when ordering from this kiosk"
                    field="allowOtherPayments"
                />
            </EditSection>

            <CashDrawerEditSection />
            {independentCompletionEnabled && (
                <EditSection heading="Prep Station Settings">
                    <SelectField
                        id="prepStationSelect"
                        label="Choose Prep Station"
                        description="Select the prep station to modify Kitchen Display System behavior"
                        options={prepStationOptions}
                        field="prepStation"
                    />
                </EditSection>
            )}
            <EditSection heading="Danger Zone">
                <DeleteSection />
            </EditSection>
        </>
    );
};
