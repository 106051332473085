import styled, { css } from "styled-components";

type TextTypes =
    | "title"
    | "title-mobile"
    | "header"
    | "body-bold"
    | "body-medium"
    | "body-regular"
    | "large-regular";
type Colors = "black" | "grey" | "white" | "light-grey";

export const fontWeights = (type: TextTypes) => {
    switch (type) {
        case "title":
        case "header":
            return 700;
        case "body-bold":
        case "title-mobile":
            return 600;
        case "body-medium":
            return 500;
        case "large-regular":
        case "body-regular":
            return 400;
        default:
            return 400;
    }
};

export const fontSizes = (type: TextTypes) => {
    switch (type) {
        case "title":
            return "36px";
        case "header":
            return "24px";
        case "title-mobile":
            return "20px";
        case "large-regular":
            return "18px";
        default:
            return "16px";
    }
};

export const lineHeights = (type: TextTypes) => {
    switch (type) {
        case "title":
            return "44px";
        case "header":
            return "32px";
        case "title-mobile":
            return "28px";
        case "large-regular":
            return "24px";
        default:
            return "16px";
    }
};

export const textColor = (color: Colors) => {
    switch (color) {
        case "black":
            return "#282D32";
        case "light-grey":
            return "#606C76";
        case "grey":
            return "#A0ADBA";
        case "white":
            return "#FFFFFF";
    }
};

interface TextProps {
    type: TextTypes;
    color?: Colors;
}

export const TextStyles = ({ type, color = "black" }: TextProps) => css`
    font-family: "Inter";
    font-style: normal;
    color: ${textColor(color)};
    font-weight: ${fontWeights(type)};
    font-size: ${fontSizes(type)};
    line-height: ${lineHeights(type)};
`;

export const Text = styled.div<TextProps>`
    ${({ type, color }) => TextStyles({ type, color })};
    display: flex;
`;
