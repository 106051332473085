import { SystemColors } from "@snackpass/design-system";

import colors from "#reusable/colors/colors.json";

const theme: Theme = {
    buttons: {
        defaultBoxShadow: "inset 999px 4px 0px rgba(32, 33, 34, 0)", // transparent via 0 opacity channel
        hoverBoxShadow: "inset 999px 4px 0px rgba(32, 33, 34, 0.08)",
        variant: {
            primary: {
                backgroundColor: "#0099FF",
                borderColor: "#0099FF",
                color: SystemColors.v2.salt10.light,
                inactiveBackgroundColor: SystemColors.v2.salt20.light,
                inactiveBorderColor: SystemColors.v2.salt20.light,
                inactiveColor: SystemColors.v2.salt50.light,
            },
            secondary: {
                backgroundColor: SystemColors.v2.salt20.light,
                borderColor: SystemColors.v2.salt20.light,
                color: SystemColors.v2.salt80.light,
                inactiveBackgroundColor: SystemColors.v2.salt20.light,
                inactiveBorderColor: SystemColors.v2.salt20.light,
                inactiveColor: SystemColors.v2.salt50.light,
            },
            tertiary: {
                backgroundColor: SystemColors.v2.salt10.light,
                borderColor: colors["neutral-400"],
                color: SystemColors.v2.salt80.light,
                inactiveBackgroundColor: SystemColors.v2.salt10.light,
                inactiveBorderColor: colors["neutral-400"],
                inactiveColor: SystemColors.v2.salt50.light,
            },
            danger: {
                backgroundColor: SystemColors.v2.melon50.light,
                borderColor: SystemColors.v2.melon50.light,
                color: SystemColors.v2.salt10.light,
                inactiveBackgroundColor: SystemColors.v2.salt10.light,
                inactiveBorderColor: colors["neutral-400"],
                inactiveColor: SystemColors.v2.salt50.light,
            },
            dangerOutline: {
                backgroundColor: SystemColors.v2.salt10.light,
                borderColor: colors["neutral-400"],
                color: SystemColors.v2.melon50.light,
                inactiveBackgroundColor: SystemColors.v2.salt10.light,
                inactiveBorderColor: colors["neutral-400"],
                inactiveColor: SystemColors.v2.salt50.light,
            },
        },
        sizes: {
            large: {
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "32px",
                width: "96px",
                height: "30px",
            },
            regular: {
                padding: "12px 16px",
                fontSize: "16px",
                lineHeight: "24px",
                width: "100%",
                height: "40px",
            },
            small: {
                padding: "8px 12px",
                fontSize: "14px",
                lineHeight: "20px",
                width: "96px",
                height: "30px",
            },
            micro: {
                padding: "4px 4px",
                fontSize: "12px",
                lineHeight: "18px",
                width: "96px",
                height: "30px",
            },
        },
    },
    spacing: {
        quarter: "4px",
        half: "8px",
        threeQuarter: "12px",
        base: "16px",
        baseAndAHalf: "24px",
        double: "32px",
        doubleAndHalf: "40px",
        triple: "48px",
        quadruple: "64px",
    },
    iconButtons: {
        sizes: {
            large: "56px",
            regular: "48px",
            small: "36px",
            micro: "26px",
        },
    },
    typography: {
        largeTitle: {
            fontSize: "48px",
            lineHeight: "64px",
            fontWeight: 700,
            color: SystemColors.v2.salt80.light,
        },
        title1: {
            fontSize: "36px",
            lineHeight: "44px",
            fontWeight: 700,
            color: SystemColors.v2.salt80.light,
        },
        title2: {
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 700,
            color: SystemColors.v2.salt80.light,
        },
        title3: {
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: 600,
            color: SystemColors.v2.salt80.light,
        },
        largeBody: {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 400,
            color: "#606c76",
        },
        body: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
            color: "#606c76",
        },
        small: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            color: "#606c76",
        },
        label: {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 600,
            color: SystemColors.v2.salt80.light,
        },
        smallLabel: {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 500,
            color: SystemColors.v2.salt80.light,
        },
    },
};

export type ThemeType = typeof theme;

export default theme;

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type BoxVariant = {
    backgroundColor: string;
    borderColor: string;
    color: string;
    inactiveBackgroundColor: string;
    inactiveBorderColor: string;
    inactiveColor: string;
};

type BoxSize = {
    padding: string;
    fontSize: string;
    lineHeight: string;
    height: string;
    width: string;
};

type Typography = {
    fontSize: string;
    lineHeight: string;
    fontWeight: FontWeight;
    color: string;
};

export interface Theme {
    buttons: {
        defaultBoxShadow: string;
        hoverBoxShadow: string;
        variant: {
            primary: BoxVariant;
            secondary: BoxVariant;
            tertiary: BoxVariant;
            danger: BoxVariant;
            dangerOutline: BoxVariant;
        };
        sizes: {
            large: BoxSize;
            regular: BoxSize;
            small: BoxSize;
            micro: BoxSize;
        };
    };
    spacing: {
        quarter: string;
        half: string;
        threeQuarter: string;
        base: string;
        baseAndAHalf: string;
        double: string;
        doubleAndHalf: string;
        triple: string;
        quadruple: string;
    };
    iconButtons: {
        sizes: {
            large: string;
            regular: string;
            small: string;
            micro: string;
        };
    };
    typography: {
        largeTitle: Typography;
        title1: Typography;
        title2: Typography;
        title3: Typography;
        largeBody: Typography;
        body: Typography;
        small: Typography;
        label: Typography;
        smallLabel: Typography;
    };
}
