import { Reference } from "@apollo/client";
import { useCallback } from "react";

import {
    DeleteMenuMutationVariables,
    useDeleteMenuMutation,
} from "src/api/graphql/generated/types";

export function useDeleteMenu() {
    const [deleteMenuMutation, { loading, error, reset, data }] =
        useDeleteMenuMutation();
    const deleteMenu = useCallback(
        async (variables: DeleteMenuMutationVariables) => {
            const result = await deleteMenuMutation({
                variables,
                // https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
                update(cache, { data }) {
                    if (!data?.deleteStoreMenu) return;
                    cache.modify({
                        fields: {
                            storeMenus(
                                existingMenus: Reference[] = [],
                                { readField },
                            ) {
                                return existingMenus.filter(
                                    (ref) =>
                                        readField("id", ref) !==
                                        data.deleteStoreMenu,
                                );
                            },
                        },
                    });
                },
            }).finally(reset);
            return result;
        },
        [deleteMenuMutation, reset],
    );
    return {
        deleteMenu,
        loading,
        error,
        data,
    };
}
