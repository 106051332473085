import * as React from "react";

import { cn } from "src/@/lib/utils";

export interface TextareaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, ...props }, ref) => (
        <textarea
            className={cn(
                "flex min-h-[60px] w-full rounded-md border border-neutral-200 bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300",
                className,
            )}
            ref={ref}
            {...props}
        />
    ),
);
Textarea.displayName = "Textarea";

// This component wraps the Textarea in a className relative to pull off absolute positioning. Because this
// may not always be desired its a separate component.
const CharacterCounterTextarea = React.forwardRef<
    HTMLTextAreaElement,
    TextareaProps
>((props, ref) => (
    <div className="relative">
        <Textarea {...props} ref={ref} />
        <div className="pointer-events-none absolute bottom-2 right-2 text-sm text-gray-500">
            {`${
                (typeof props.value === "string" ||
                typeof props.value === "number"
                    ? props.value.toString().length
                    : null) ?? 0
            }${props.maxLength ? `/${props.maxLength}` : ""}`}
        </div>
    </div>
));

export { Textarea, CharacterCounterTextarea };
