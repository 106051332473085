import { captureException } from "@sentry/react";
import { notification } from "antd";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import api from "src/api/rest";
import config from "#config";
import { TaxFormsContext } from "#tax-forms/Context";

const notifyFailure = () =>
    notification.error({
        placement: "top",
        message: "Page Load Failed",
        description:
            "Please contact Snackpass support if this issue continues to arise.",
    });

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
    const { setEmailVerified, storeId } = useContext(TaxFormsContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading || !storeId) return;
        setLoading(true);

        const checkAuthCookie = async () => {
            if (!config.isProduction) {
                return Promise.resolve();
            }
            return storeId
                ? api.verifications.checkAuthToken(storeId)
                : Promise.reject();
        };

        void checkAuthCookie()
            .then(async () => {
                // If api.verifications.checkAuthToken resolves, the current
                // auth token cookie is valid and the user can proceed.
                setEmailVerified(true);
            })
            .catch((error) => {
                // 403 is expected -- indicates the auth token has expired
                // and user needs to reauthenticate
                if (error.cause?.response?.status !== 403) {
                    notifyFailure();
                    captureException(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [storeId]);

    return <Container>{children}</Container>;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    margin: ${({ theme }) => `${theme.spacing.half} ${theme.spacing.half}`};

    & .form-control,
    & .form-select {
        font-family: "Inter";
    }
`;
