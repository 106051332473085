import { useContext } from "react";

import { ReportsContext } from "#app/reports-context-provider";
import LocationMenuRowChart from "#reports/location-menu-items/shared-components/LocationMenuRowChart";

type Props = {
    sortAscending?: boolean;
    title: string;
};

const ItemsByLocationChart = ({ title, sortAscending }: Props) => {
    const { reportsState } = useContext(ReportsContext);
    const { locationMenuInsightsData } = reportsState;

    return (
        <LocationMenuRowChart
            data={locationMenuInsightsData?.itemInsightsData}
            loading={locationMenuInsightsData?.itemInsightsDataLoading}
            failed={locationMenuInsightsData?.itemInsightsDataFailed}
            title={title}
            sortAscending={sortAscending}
        />
    );
};

export default ItemsByLocationChart;
