import { useFormContext } from "react-hook-form";

import { Card } from "src/@/components/ui/card";
import { FormControl, FormField } from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import CampaignFormItemLayout from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/CampaignFormItemLayout";

function BusinessNameCardInput() {
    const { control } = useFormContext();
    return (
        <Card>
            <FormField
                control={control}
                name="businessName"
                render={({ field }) => (
                    <CampaignFormItemLayout
                        label="Legal Business Name"
                        description={
                            <>
                                The legal name of your business exactly as
                                written on your CP 575 EIN Confirmation Letter.
                            </>
                        }
                        subDescription={
                            <>
                                Pay close attention to punctuation, spacing,
                                capitalization, Inc vs LLC
                            </>
                        }
                    >
                        <FormControl>
                            <Input {...field} />
                        </FormControl>
                    </CampaignFormItemLayout>
                )}
            />
        </Card>
    );
}

export default BusinessNameCardInput;
