import { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { GetInvoicesQuery } from "src/api/graphql/generated/types";
import { CurrentInvoice } from "#invoices/types";
import { formatDate, formatAmount } from "#invoices/utils";

type InvoiceRow = GetInvoicesQuery["invoices"][number];

export const getColumns = (
    onCellSelected: (cell: CurrentInvoice) => void,
): ColumnDef<InvoiceRow>[] => [
    {
        id: "Invoice Number",
        accessorKey: "stripeInvoiceNumber",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Invoice Number" />
        ),
        cell: ({ row }) => (
            <span
                className="cursor-pointer text-blue-600"
                onClick={() => onCellSelected(row?.original)}
            >
                {row?.original?.stripeInvoiceNumber}
            </span>
        ),
        enableGlobalFilter: false,
    },
    {
        id: "Invoice Sent At",
        accessorKey: "createdAt",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Invoice Sent At" />
        ),
        cell: ({ row }) => formatDate(row?.original?.createdAt),
        enableGlobalFilter: false,
    },
    {
        id: "Total Amount",
        accessorKey: "totalAmount",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Total Amount" />
        ),
        cell: ({ row }) => {
            if (row.original?.totalAmount) {
                return formatAmount(row.original.totalAmount);
            }
            return "n/a";
        },
        enableGlobalFilter: false,
    },
    {
        id: "Customer Name",
        accessorKey: "customerName",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Customer Name" />
        ),
        cell: ({ row }) => row?.original?.customerName,
    },
    {
        id: "Customer Email",
        accessorKey: "customerEmail",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Customer Email" />
        ),
        cell: ({ row }) => row?.original?.customerEmail,
    },
    {
        id: "Customer Paid At",
        accessorKey: "customerPaidAt",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Customer Paid At" />
        ),
        cell: ({ row }) => formatDate(row?.original?.customerPaidAt),
        enableGlobalFilter: false,
    },
    {
        id: "Payout Processed At",
        accessorKey: "payoutProcessedAt",
        header: ({ column }) => (
            <DataTableColumnHeader
                column={column}
                title="Payout Processed At"
            />
        ),
        cell: ({ row }) => formatDate(row?.original?.payoutProcessedAt),
        enableGlobalFilter: false,
    },
];
