import React, { useContext } from "react";

import Select from "#devices/components/FormControls/Select";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

type EditDeviceTypeProps = {
    options: { value: string; label: string }[];
    disabled?: boolean;
};

export const EditDeviceType = ({
    options,
    disabled = false,
}: EditDeviceTypeProps) => {
    const { device, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    return (
        <Select
            id="deviceType"
            label="Device Type"
            value={updatedDevice.deviceType ?? device?.deviceType}
            disabled={disabled}
            options={options}
            onChange={(e) => {
                updateDeviceField("deviceType", e.target.value);
            }}
        />
    );
};
