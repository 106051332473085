import { Dialog, Transition } from "@headlessui/react";
import React, { useRef, useState } from "react";

import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";

interface Props {
    rows: {
        [key: string]: string | number;
    }[];
    columns: {
        title: string;
        dataIndex: string;
    }[];
    filename: string;
    useConfirmModal?: boolean;
    confirmModalMessage?: string | JSX.Element;
}

const CustomerDownloadButton = ({ rows, columns, filename }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const downloadCSV = useRef<() => void>(() => {});

    const confirmMessage = (
        <div className="text-neutral-700">
            <h4 className="mb-3">
                In order to receive your customer data list, please review and
                agree
            </h4>

            <p className="mb-3">
                I acknowledge and agree that the customer data list I have
                requested may be used only in accordance with the Snackpass{" "}
                <a
                    target="_blank"
                    href="https://legal.snackpass.co/snackpass-restaurant-terms-of-service"
                >
                    Merchant Agreement
                </a>{" "}
                and{" "}
                <a
                    target="_blank"
                    href="https://legal.snackpass.co/snackpass-privacy-policy"
                >
                    Privacy Policy
                </a>
            </p>

            <p className="mb-3">
                I will not utilize guest contact information for any purposes
                that guests have not explicitly agreed to. I shall not engage in
                the sale or sharing of guest information obtained from Snackpass
                with any third parties unless prior consent has been obtained
                from the guests.
            </p>

            <p className="mb-3">
                Communication with guests shall be exclusively conducted through
                Snackpass's Guestbook conversations and campaigns, and direct
                contact will be avoided.
            </p>

            <p className="mb-3">
                I will not use guest information for marketing purposes in cases
                where guests have opted out of such communications.
            </p>

            <p>
                I acknowledge that mobile phone numbers and emails provided by
                Snackpass may only be used for transactional purposes and should
                not be imported into any automated text messaging or email
                service(s) unless I have separately obtained guest consent as
                required by law.
            </p>

            <div className="mt-5 text-right">
                <button
                    className="rounded-md bg-neutral-400 px-4 py-2 text-neutral-600"
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </button>
                <button
                    className="ml-3 rounded-md bg-success-light px-4 py-2 text-neutral-50"
                    onClick={() => {
                        setIsModalOpen(false);
                        downloadCSV.current();
                    }}
                >
                    Agree and Continue
                </button>
            </div>
        </div>
    );

    return (
        <>
            <Transition show={isModalOpen} as={React.Fragment}>
                <Dialog
                    onClose={() => setIsModalOpen(false)}
                    className="fixed inset-0 z-10 overflow-y-auto"
                >
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <div className="flex min-h-full items-center justify-center">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="z-50 flex items-center justify-center">
                                <Dialog.Panel className="m-3 max-h-[70vh] w-full max-w-5xl overflow-y-scroll rounded-md bg-neutral-50 p-8">
                                    <div className="mt-2">{confirmMessage}</div>
                                </Dialog.Panel>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <DownloadButton
                rows={rows}
                columns={columns}
                filename={filename}
                onClick={(downloadFunction: () => void) => {
                    setIsModalOpen(true);
                    downloadCSV.current = downloadFunction;
                }}
            />
        </>
    );
};

export default CustomerDownloadButton;
