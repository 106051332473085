import { CardTitle } from "src/@/components/ui/card";
import {
    CampaignScreenHeader,
    NewCampaignButton,
} from "#guestbook/screens/Campaigns/CampaignScreenHeader";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import ExternalButtonLink from "#shared-components/ExternalButtonLink";
import { SegmentEvents } from "#utils/segment";

export function PushCampaignsPage() {
    const trackCampaignEvent = useTrackCampaignSegmentEvent();

    return (
        <div className="h-full w-full">
            <CampaignScreenHeader
                button={
                    <NewCampaignButton
                        disabled
                        disabledTooltip="Sign up for push notifications to create a new campaign"
                    />
                }
            />
            <div className="flex justify-center">
                <div className="max-w-screen-md overflow-hidden">
                    <div className="my-[72px] flex flex-col gap-4 text-center">
                        <CardTitle className="text-2xl">
                            Set up Push Notifications
                        </CardTitle>
                        <div className="text-neutral-600">
                            Send messages to customers via your Branded App
                        </div>
                        <div className="flex justify-center">
                            <ExternalButtonLink
                                href="https://airtable.com/appy8eQbMkVKrsnZ4/paglW8OzypSkSbqep/form"
                                target="_blank"
                                onClick={() =>
                                    trackCampaignEvent(
                                        SegmentEvents.Guestbook.Campaigns
                                            .SETUP_PUSH,
                                    )
                                }
                            >
                                Get Started
                            </ExternalButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
