import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRight } from "lucide-react";

interface OverviewItem {
    name: string;
    description: string;
    path: string;
    icon?: ReactNode;
    external?: boolean;
}

interface OverviewSection {
    title: string;
    description: string;
    icon: ReactNode;
    items: OverviewItem[];
}

interface OverviewLayoutProps {
    title: string;
    description: string;
    sections: OverviewSection[];
}

export function OverviewLayout({
    title,
    description,
    sections,
}: OverviewLayoutProps) {
    const history = useHistory();

    const handleClick = (path: string, external: boolean = false) => {
        if (external) {
            window.open(path, "_blank");
        } else {
            history.push(path);
        }
    };

    return (
        <div className="p-8 mb-16 mx-8">
            <div className="mb-4">
                <h1 className="text-2xl font-semibold mb-1">{title}</h1>
                <p className="text-muted-foreground">{description}</p>
            </div>
            <hr className="border-gray-300" />

            <div className="space-y-12 max-w-screen-md">
                {sections.map((section) => (
                    <div key={section.title}>
                        <div className="mb-4">
                            <h2 className="text-xl font-semibold mb-1">
                                {section.title}
                            </h2>
                            <p className="text-muted-foreground">
                                {section.description}
                            </p>
                        </div>

                        <div className="divide-y">
                            {section.items.map((item) => (
                                <div
                                    key={item.name}
                                    onClick={() =>
                                        handleClick(item.path, item.external)
                                    }
                                    className="flex items-center justify-between py-4 cursor-pointer hover:bg-muted/40 -mx-4 transition-colors"
                                >
                                    <div className="flex items-center gap-4">
                                        <div className="w-12 h-12 rounded-full bg-muted/50 flex items-center justify-center">
                                            {item.icon}
                                        </div>
                                        <div>
                                            <div className="font-medium">
                                                {item.name}
                                            </div>
                                            <div className="text-sm text-muted-foreground">
                                                {item.description}
                                            </div>
                                        </div>
                                    </div>
                                    <ArrowRight className="h-5 w-5 text-muted-foreground" />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
