import React from "react";

type Props = {
    descriptor?: string;
};

export const FormFieldDescriptor = ({ descriptor }: Props) =>
    descriptor ? (
        <div className="mb-1 flex flex-col text-small text-neutral-600 md:text-body">
            <div>{descriptor}</div>
        </div>
    ) : null;
