import React from "react";
import { Button } from "src/@/components/ui/button";
import { Check } from "lucide-react";
import { useDispatch } from "react-redux";
import { setInventoryIsFetching } from "src/redux/slices";
import { useFetchInventory } from "../lib";
import api from "src/api/rest";

type TopUpButtonProps = {
    topUp: Record<string, any>;
    toggleTopUp: () => void;
};

const TopUpButton = ({ topUp, toggleTopUp }: TopUpButtonProps) => {
    const dispatch = useDispatch();
    const [fetchInventory] = useFetchInventory();

    const handleApply = async () => {
        const products = Object.keys(topUp);
        await Promise.all(
            products.map(async (productId) =>
                api.inventory.adjust(productId, topUp[productId]),
            ),
        );
        dispatch(setInventoryIsFetching(true));
        setTimeout(() => {
            fetchInventory();
        }, 1000);
        toggleTopUp();
    };

    return (
        <Button
            onClick={handleApply}
            size="sm"
            className="bg-green-600 hover:bg-green-700"
        >
            <Check className="mr-2 h-4 w-4" />
            Apply Changes
        </Button>
    );
};

export default TopUpButton;
