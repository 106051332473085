import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "src/@/components/ui/badge";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { GiftCardLiabilitiesRow } from "#reports/gift-card-liabilities/types";
import { formatCentsAsDollars } from "#reports/gift-card-purchases/lib";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";

export const columns: ColumnDef<GiftCardLiabilitiesRow>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title="Store"
            />
        ),
        id: "name",
        accessorKey: "storeName",
        enableSorting: false,
        cell: ({ row, getValue }) => (
            <div className="my-4 ms-4 flex items-center gap-3">
                {row.getCanExpand() ? (
                    <div>
                        {row.getIsExpanded() ? (
                            <i className="fas fa-chevron-down" />
                        ) : (
                            <i className="fas fa-chevron-right" />
                        )}
                    </div>
                ) : null}
                <div className={row.getCanExpand() ? "font-bold" : "ml-6"}>
                    {row.original.isHeadstore && row.getCanExpand() ? (
                        <Badge variant="outline" className="mr-5">
                            Headstore
                        </Badge>
                    ) : null}
                    {getValue() as string}
                </div>
            </div>
        ),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title={
                    <>
                        Payable
                        <ReportsTooltip
                            body={
                                "Payable is the gift card value loaded at this location that was spent at another location."
                            }
                        />
                    </>
                }
            />
        ),
        id: "payable",
        accessorKey: "payable",
        enableSorting: false,
        cell: ({ row, getValue }) => (
            <div
                className={`${row.getCanExpand() ? "font-bold" : ""} my-4 ms-4`}
            >
                {formatCentsAsDollars(getValue() ? Number(getValue()) : 0)}
            </div>
        ),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title={
                    <>
                        Receivable
                        <ReportsTooltip
                            body={
                                "Receivable is the gift card value spent at this location that was loaded at another location."
                            }
                        />
                    </>
                }
            />
        ),
        id: "receivable",
        accessorKey: "receivable",
        enableSorting: false,
        cell: ({ row, getValue }) => (
            <div
                className={`${row.getCanExpand() ? "font-bold" : ""} my-4 ms-4`}
            >
                {formatCentsAsDollars(getValue() ? Number(getValue()) : 0)}
            </div>
        ),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title="Net Receivable"
            />
        ),
        id: "net",
        accessorKey: "net",
        enableSorting: false,
        cell: ({ row, getValue }) => (
            <div
                className={`${row.getCanExpand() ? "font-bold" : ""} my-4 ms-4`}
            >
                {formatCentsAsDollars(getValue() ? Number(getValue()) : 0)}
            </div>
        ),
    },
];
