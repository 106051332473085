import React, { useContext, useEffect, useState } from "react";
import { Divider, notification } from "antd";
import { captureException } from "@sentry/react";
import { useSelector } from "react-redux";

import PageHeader from "#payouts/components/PageHeader";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import api from "src/api/rest";
import { CenteredSpin } from "#payouts/components/shared/Loading";
import config from "#config";
import { getUser } from "src/redux/selectors";

const notifyFailure = () =>
    notification.error({
        placement: "top",
        message: "Page Load Failed",
        description:
            "Please contact Snackpass support if this issue continues to arise.",
    });

export const PageWrapper = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const user = useSelector(getUser);

    const {
        storeId,
        setVerification,
        setEmailVerified,
        shouldReloadVerification,
        setShouldReloadVerification,
    } = useContext(PayoutsSettingsContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading || (!storeId && !shouldReloadVerification)) return;

        setLoading(true);

        const checkAuthCookie = async () => {
            if (
                !config.isProduction ||
                user?.snackpassPermissions.isSnackpassEmployee
            ) {
                return Promise.resolve();
            }
            return storeId
                ? api.verifications.checkAuthToken(storeId)
                : Promise.reject();
        };

        const fetchAndSetStoreVerification = async () =>
            api.verifications
                .retrieve(storeId ?? "not-set")
                .then(({ data }) => {
                    if (data?.success) {
                        setVerification(data.verification);
                    } else {
                        notifyFailure();
                        captureException(Error(JSON.stringify(data)));
                    }
                })
                .catch(({ cause }) => {
                    if (
                        !cause ||
                        !cause.response.data.error?.includes(
                            "Store verification not found",
                        )
                    ) {
                        notifyFailure();
                        captureException(
                            Error(JSON.stringify(cause.response.data.error)),
                        );
                    }
                });

        void checkAuthCookie()
            .then(async () => {
                // If api.verifications.checkAuthToken resolves, the current
                // auth token cookie is valid and the user can proceed.
                setEmailVerified(true);
                return fetchAndSetStoreVerification();
            })
            .catch((error) => {
                // 403 is expected -- indicates the auth token has expired
                // and user needs to reauthenticate
                if (error.cause?.response?.status !== 403) {
                    notifyFailure();
                    captureException(error);
                }
            })
            .catch(({ cause }) => {
                if (
                    !cause ||
                    !cause.response?.data?.error?.includes(
                        "Store verification not found",
                    )
                ) {
                    notifyFailure();
                    captureException(
                        Error(JSON.stringify(cause.response.data.error)),
                    );
                }
            })
            .finally(() => {
                setLoading(false);
                setShouldReloadVerification(false);
            });
    }, [storeId, shouldReloadVerification]);

    const Loading = (params: { children: JSX.Element | JSX.Element[] }) =>
        loading ? <CenteredSpin /> : <>{params.children}</>;

    return (
        <div>
            <PageHeader />
            <Divider />
            <Loading children={children} />
        </div>
    );
};
