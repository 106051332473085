import { IStore } from "@snackpass/snackpass-types";

import { MiscellaneousFormValues } from "#settings/settings-fees/utils/types";

export const miscellaneousFormDefaults = (
    store: IStore,
): MiscellaneousFormValues => {
    const bagFeePolicy = store.feePolicies?.find(
        (p) => p.name === "BAG_STORE_FEE",
    );
    return bagFeePolicy
        ? { flat: bagFeePolicy.flat, isTaxable: !!bagFeePolicy.isTaxable }
        : { flat: 0, isTaxable: false };
};
