import { DeviceType } from "@snackpass/snackpass-types";

import { StoreDevice } from "#devices/utils/deviceTypes";

// At the moment, the only kiosk models that support directly opening the cash drawer are our telpo models C8 and D4.
const KIOSK_CASH_DRAWER_ENABLED_MODELS = ["C8", "D4", "B4"];

/**
 * A function that determines whether a cash drawer device should be able to be connected to a given store device.
 * This comes down to whether the device has an RJ12 port to open the cash drawer through.
 */
export const shouldHaveCashDrawerAddon = (device: StoreDevice) =>
    device.deviceType === DeviceType.Printer ||
    (device.deviceType === DeviceType.Kiosk &&
        KIOSK_CASH_DRAWER_ENABLED_MODELS.includes(device.hardwareModel));
