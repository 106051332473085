import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { Spinner } from "react-activity";

type SpinProps = {
    small?: boolean;
    large?: boolean;
    color?: string;
};

const sizeMap = {
    small: 16,
    default: 26,
    large: 32,
};

export const Loading = ({ small, large, color }: SpinProps) => (
    <Spinner
        color={color || SystemColors.v1.candy50}
        size={small ? sizeMap.small : large ? sizeMap.large : sizeMap.default}
    />
);
