import { CardTitle } from "src/@/components/ui/card";
import StackedStickyHeader from "#navigation/StackedStickyHeader";

type Props = {
    children?: React.ReactNode;
};

function CampaignBrandRegistrationHeader({ children }: Props) {
    return (
        <StackedStickyHeader>
            <div className="flex shrink-0 flex-row items-center justify-between gap-10 border-b p-4">
                <CardTitle className="text-lg font-bold sm:text-2xl">
                    Set up Text Message Marketing
                </CardTitle>
                <div className="flex gap-2">{children}</div>
            </div>
        </StackedStickyHeader>
    );
}

export default CampaignBrandRegistrationHeader;
