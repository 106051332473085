import { match } from "ts-pattern";
import { Canvas } from "fabric";

import { LayoutElement } from "#table-editor/types";
import { Shape, Element as Variant } from "src/api/graphql/generated/types";

import { drawTableRect } from "./drawTableRect";
import { drawTableCircle } from "./drawTableCircle";
import { drawTableDiamond } from "./drawTableDiamond";
import { drawLabel } from "./drawLabel";
import { drawBarrier } from "./drawBarrier";

/**
 * Given a layout element, draw its appropriate shape
 */
export function drawElement(canvas: Canvas, element: LayoutElement) {
    return match(element)
        .with(
            {
                geometry: { type: Shape.Rect },
                properties: { type: Variant.Table }
            },
            (element) =>
                drawTableRect(
                    canvas,
                    element.id,
                    element.geometry,
                    element.properties
                )
        )
        .with(
            {
                geometry: { type: Shape.Circle },
                properties: { type: Variant.Table }
            },
            (element) =>
                drawTableCircle(
                    canvas,
                    element.id,
                    element.geometry,
                    element.properties
                )
        )
        .with(
            {
                geometry: { type: Shape.Diamond },
                properties: { type: Variant.Table }
            },
            (element) =>
                drawTableDiamond(
                    canvas,
                    element.id,
                    element.geometry,
                    element.properties
                )
        )
        .with(
            {
                geometry: { type: Shape.Rect },
                properties: { type: Variant.Label }
            },
            (element) =>
                drawLabel(
                    canvas,
                    element.id,
                    element.geometry,
                    element.properties
                )
        )
        .with(
            {
                geometry: { type: Shape.Rect },
                properties: { type: Variant.Barrier }
            },
            (element) => drawBarrier(canvas, element.id, element.geometry)
        )
        .otherwise(() => null);
}
