import { useMemo, useContext } from "react";

import { ReportsContext } from "#app/reports-context-provider";
import { aggregateItemsByLocationByCategory } from "#reports/location-menu-categories/lib";
import LocationMenuRowChart from "#reports/location-menu-items/shared-components/LocationMenuRowChart";

type Props = {
    sortAscending?: boolean;
    title: string;
};

const CategoriesByLocationChart = ({ title, sortAscending }: Props) => {
    const { reportsState } = useContext(ReportsContext);
    const { locationMenuInsightsData } = reportsState;

    const data = useMemo(() => {
        if (!locationMenuInsightsData?.itemInsightsData) return undefined;
        return aggregateItemsByLocationByCategory(
            locationMenuInsightsData?.itemInsightsData,
        );
    }, [locationMenuInsightsData]);

    return (
        <LocationMenuRowChart
            data={data}
            loading={locationMenuInsightsData?.itemInsightsDataLoading}
            failed={locationMenuInsightsData?.itemInsightsDataFailed}
            title={title}
            sortAscending={sortAscending}
        />
    );
};

export default CategoriesByLocationChart;
