import { Col, Form, Select } from "antd";

import { Label } from "#onboarding/components/shared/Label";
import { filterOption, filterSort } from "#onboarding/utils/filterOptions";

type Option = { value: string; label: string };

type FormInputProps = {
    value?: string;
    field: string;
    label: string;
    subtitle?: string;
    placeholder: string;
    required?: boolean;
    disabled?: boolean;
    noLabel?: boolean;
    options: Option[];
    onChange?: (v: string) => void;
    onSearch?: (search: string) => void;
    className?: string;
    span?: number;
};

export const FormSelect = ({
    value,
    field,
    label,
    subtitle,
    placeholder,
    required = false,
    disabled = false,
    noLabel = false,
    options = [],
    onChange,
    onSearch,
    className = "",
    span = 24,
}: FormInputProps) => (
    <Col span={span}>
        <Form.Item
            name={field}
            label={
                noLabel ? null : (
                    <Label
                        required={required}
                        label={label}
                        subtitle={subtitle}
                    />
                )
            }
        >
            <Select
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                className={className}
                optionFilterProp="children"
                showSearch
                onSearch={onSearch}
                onChange={(value) => onChange?.(value)}
                filterOption={(input, option) =>
                    filterOption(input, option?.children?.toString())
                }
                filterSort={(a, b) =>
                    filterSort(a?.children?.toString(), b?.children?.toString())
                }
            >
                {options.map((o) => (
                    <Select.Option key={o.value} value={o.value}>
                        {o.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    </Col>
);
