import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStore } from "src/redux/selectors";

export const PayoutAlert = () => {
    const store = useAppSelector(getActiveStore);
    return (
        <Alert variant="info">
            <AlertTitle className="text-base">Payout to Store</AlertTitle>
            <AlertDescription>
                Create a one-off payout for {store?.name} (
                {store?.addressComponents.line1})
            </AlertDescription>
        </Alert>
    );
};
