import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

export const useCreateTable = () => {
    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id ?? "";

    const { mutateAsync: createTable, isPending } = useMutation({
        onError: (err) => {
            toast.error("Couldn't create table", {
                description:
                    (err as AxiosError)?.message ?? "Unknown error. Try again.",
            });
        },
        mutationFn: async (tableString: string) => {
            if (!tableString) {
                return;
            }
            return api.stores.createTable(storeId, {
                tableNumber: tableString,
            });
        },
        onSuccess() {
            toast.success("Added Table", {
                description: `Download the table's QR Code below to set it up.`,
            });
        },
    });

    return {
        createTable,
        isLoading: isPending,
    };
};
