/** @jsxImportSource @emotion/react */
import { useCallback } from "react";
import { PurchaseReportData } from "@snackpass/snackpass-types";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import "react-activity/lib/Spinner/Spinner.css";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment-timezone";
import clsx from "clsx";
import { VariantProps } from "class-variance-authority";
import { toast } from "sonner";

import {
    usePurchaseReportRows,
    usePurchaseReportTotals,
} from "src/api/rest/usePurchaseReports";
import {
    getTotalFieldsRow,
    setPurchasesFields,
} from "#download-purchase-button/utils";
import { useGlobalDate } from "#hooks/use-global-date";
import { usePaymentTypePicker } from "#hooks/use-payment-type-picker";
import { getStoreTimezone } from "#utils/helpers";
import { getActiveStore, getUserTeamPermission } from "src/redux/selectors";
import { Button, buttonVariants } from "src/@/components/ui/button";
import { ReactComponent as DownloadIcon } from "src/assets/icons/download-icon.svg";
import { ExportToCsv } from "#utils/export-to-csv";

enum LoadState {
    unloaded,
    loading,
    loaded,
}

export type SelectedOption = null | string;
export type SelectOption = Dispatch<SetStateAction<SelectedOption>>;

export enum PaymentType {
    ALL = "All",
    ALLEXCEPTCASH = "All Except Cash",
    CASHONLY = "Cash Only",
}

const dateTimeFormat = "YYYY-MMM-DD";

interface Props extends VariantProps<typeof buttonVariants> {
    startDate: Moment;
    endDate: Moment;
    showTotals?: boolean;
    className?: string;
    hideLabel?: boolean;
}

const DownloadPurchasesCSVButton = ({
    startDate,
    endDate,
    showTotals = false,
    variant = "outline",
    hideLabel = false,
    className = "",
}: Props) => {
    const {
        startDate: globalStartDate,
        endDate: globalEndDate,
        hash,
    } = useGlobalDate();
    const purchaseTotals = usePurchaseReportTotals();
    const activeStore = useSelector(getActiveStore);
    const isSnackTeam = useSelector(getUserTeamPermission);
    const { paymentType } = usePaymentTypePicker();
    const [loadState, setLoad] = useState<LoadState>(LoadState.unloaded);
    const { data: rows, error } = usePurchaseReportRows();
    const { total: totals } = showTotals ? purchaseTotals : { total: null };

    const timezone = getStoreTimezone(activeStore);

    useEffect(() => {
        setLoad(LoadState.unloaded);
    }, [activeStore?._id, hash]);

    const downloadPurchases = useCallback(
        (selectedPaymentType: SelectedOption) => {
            const shouldFilterRows =
                !startDate.isSame(globalStartDate) ||
                !endDate.isSame(globalEndDate);

            // Only allow showTotals if we will not be filtering rows...
            if (showTotals && shouldFilterRows)
                throw new Error("Cannot both filter rows and show totals!");

            setLoad(LoadState.loading);

            if ((showTotals && !totals) || !rows || !rows?.length || error) {
                if ((showTotals && !totals) || !rows || !rows?.length) {
                    toast.info("No data to export.");
                } else if (error) {
                    toast.error("Failed to export", {
                        description: error.message,
                    });
                }
                return setLoad(LoadState.loaded);
            }

            // filter the rows between start and end date
            const filteredRows = shouldFilterRows
                ? rows.filter((purchase: PurchaseReportData) => {
                      const dateReceived = moment(purchase.dateReceived).tz(
                          timezone,
                      );
                      return (
                          dateReceived.isSameOrAfter(startDate) &&
                          dateReceived.isBefore(endDate)
                      );
                  })
                : rows;

            const showRemittance = filteredRows.some(
                (summarizedPurchase: PurchaseReportData) =>
                    summarizedPurchase.storeTaxesWithheld,
            );

            // here the filtered rows are made into a more presentable format
            let csvPurchases = setPurchasesFields(
                filteredRows,
                isSnackTeam,
                timezone,
                showRemittance,
                selectedPaymentType,
            );

            // after filtering rows and making it presentable, add totals row at the end if showTotals is true
            if (showTotals && totals) {
                csvPurchases = csvPurchases.concat(
                    getTotalFieldsRow(totals, isSnackTeam, showRemittance),
                );
            }

            const csvExporter = new ExportToCsv({
                filename: `${
                    activeStore?.name
                } - Snackpass Report ${startDate.format(
                    dateTimeFormat,
                )}-${endDate.format(dateTimeFormat)}`,
                fieldSeparator: ",",
                useKeysAsHeaders: true,
            });

            // The last row is the summary total of csv. If there are no purchases, an alert will pop up.
            if (csvPurchases.length > 1) csvExporter.generateCsv(csvPurchases);
            else {
                void toast.info(
                    "There are no purchases that fit these filters",
                );
            }

            setLoad(LoadState.loaded);
        },
        [
            activeStore?._id,
            startDate,
            endDate,
            globalStartDate,
            globalEndDate,
            rows,
            totals,
            error,
        ],
    );

    if (!activeStore?._id) return <></>;

    if (loadState === LoadState.loading) {
        return <Spinner color="black" size={12} />;
    }

    return (
        <Button
            variant={variant}
            onClick={() => downloadPurchases(paymentType)}
            className={clsx("flex space-x-1", className)}
        >
            <DownloadIcon />
            <span className={clsx("hidden", !hideLabel && "md:block")}>
                Export
            </span>
        </Button>
    );
};

export default DownloadPurchasesCSVButton;
