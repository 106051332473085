/** @jsxImportSource @emotion/react */
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { toDollar } from "@snackpass/accounting";
import { CashEventType, Employee } from "@snackpass/snackpass-types";
import { PopulatedCashEvent } from "@snackpass/snackpass-types/src/v1";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import { Spinner } from "react-activity";
import { useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
} from "src/@/components/ui/tooltip";
import { useCashEvents } from "src/api/rest/useCashEvents";
import { getStoreTimezone } from "#utils/helpers";
import { getActiveStore } from "src/redux/selectors";
import { ReactComponent as DotsIcon } from "src/assets/icons/3-horizontal-dots-icon.svg";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { CashReportContext } from "#financial-reports/screens/cash-report/cash-report-context";
import { formatEventType } from "#financial-reports/screens/cash-report/lib";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";

const MAX_REASON_TEXT_LENGTH = 30;
export const CashReportTable = () => {
    const updatedTime = moment().format("h:mm a");

    const { cashDrawer } = useContext(CashReportContext);
    const { data, isLoading } = useCashEvents(cashDrawer);
    const { data: cashDrawers } = useCashDrawers();

    type CellProps = {
        row: {
            original: PopulatedCashEvent;
        };
    };

    const activeStore = useSelector(getActiveStore);
    const timezone = getStoreTimezone(activeStore);

    const columns: ColumnDef<PopulatedCashEvent>[] = React.useMemo(
        () => [
            {
                id: "time",
                header: "Time",
                accessorKey: "time",
                cell: (props: CellProps) => {
                    const time = moment(props.row.original.createdAt).format(
                        "M/DD h:mm a",
                    );
                    return <span>{time}</span>;
                },
                disableSortBy: true,
            },
            {
                id: "employee",
                header: "Employee",
                accessorKey: "employee.id",
                cell: (props: CellProps) => {
                    const employee = props.row.original.employee;
                    return <span>{employee?.name || "--"}</span>;
                },
                disableSortBy: true,
                filterFn: (row, id, value) => value.includes(row.getValue(id)),
            },
            // Hide the cash drawer column if you do not have multiple cash drawers.
            ...(cashDrawers && cashDrawers.length > 1
                ? [
                      {
                          id: "cash drawer",
                          header: "Cash Drawer",
                          accessorKey: "cashDrawer.name",
                          width: 2,
                          cell: (props: CellProps) => {
                              const cashDrawer = props.row.original.cashDrawer;
                              return <span>{cashDrawer?.name || "--"}</span>;
                          },
                          disableSortBy: true,
                      },
                  ]
                : []),
            {
                id: "reason",
                header: "Reason",
                accessorKey: "eventType",
                cell: (props: CellProps) => {
                    const { eventType, reason } = props.row.original;
                    return (
                        <span>
                            {formatEventType(eventType)}
                            {reason
                                ? ` - ${reason.slice(
                                      0,
                                      MAX_REASON_TEXT_LENGTH,
                                  )}`
                                : null}
                            {reason?.length &&
                            reason.length >= MAX_REASON_TEXT_LENGTH ? (
                                <CashEventCustomReasonTooltip body={reason} />
                            ) : null}
                        </span>
                    );
                },
                filterFn: (row, id, value) => value.includes(row.getValue(id)),
                disableSortBy: true,
            },
            {
                id: "purchase ID",
                header: "Purchase ID",
                accessorKey: "reason",
                width: 2,
                cell: (props: CellProps) => {
                    const purchase = props.row.original.purchase;
                    return <span>{purchase || "--"}</span>;
                },
                disableSortBy: true,
            },
            {
                id: "paid in",
                header: "Paid In",
                accessorKey: "paidIn",
                cell: (props: CellProps) => {
                    const { eventType, paidInAmount } = props.row.original;
                    return (
                        <span>
                            {eventType === CashEventType.Audit
                                ? "--"
                                : toDollar(paidInAmount)}
                        </span>
                    );
                },
                disableSortBy: true,
            },
            {
                id: "paid out",
                header: "Paid Out",
                accessorKey: "paidOut",
                cell: (props: CellProps) => {
                    const { eventType, paidOutAmount } = props.row.original;
                    return (
                        <span>
                            {eventType === CashEventType.Audit
                                ? "--"
                                : toDollar(paidOutAmount)}
                        </span>
                    );
                },
                disableSortBy: true,
            },
            {
                id: "amount",
                header: "Amount",
                accessorKey: "amount",
                cell: (props: CellProps) => {
                    const { eventType, totalAmount } = props.row.original;
                    return (
                        <span>{`${toDollar(totalAmount)}${
                            eventType === CashEventType.Audit ? "*" : ""
                        }`}</span>
                    );
                },
                disableSortBy: true,
            },
        ],
        [timezone, cashDrawers],
    );

    const cashEventTableToolbarOptions: DataTableToolbarOptions =
        useMemo(() => {
            const employeesFromData: Employee[] = Object.values(
                data.reduce(
                    (acc, { employee }) => {
                        if (employee && !acc[employee.id])
                            acc[employee.id] = employee;
                        return acc;
                    },
                    {} as Record<string, Employee>,
                ),
            );

            return {
                showColumnFilter: true,
                filters: [
                    {
                        key: "employee",
                        title: "Employee",
                        options: employeesFromData.map((e) => ({
                            label: e.name,
                            value: e.id,
                        })),
                    },
                    {
                        key: "reason",
                        title: "Reason",
                        options: Object.values(CashEventType).map((e) => ({
                            label: formatEventType(e),
                            value: e,
                        })),
                    },
                ],
            };
        }, [data]);

    return (
        <div>
            {isLoading ? (
                <Spinner className="spinner" />
            ) : (
                <DataTable
                    toolbar={cashEventTableToolbarOptions}
                    showPagination
                    columns={columns}
                    data={data}
                    footerElement={
                        <span className="text-small text-neutral-600">
                            Updated {updatedTime}
                        </span>
                    }
                    hiddenColumns={["type"]}
                />
            )}
        </div>
    );
};

const CashEventCustomReasonTooltip = ({ body }: { body: string }) => (
    <TooltipProvider delayDuration={100}>
        <Tooltip>
            <TooltipTrigger asChild>
                <DotsIcon className={"mx-1 h-3 w-3 cursor-pointer"} />
            </TooltipTrigger>

            <TooltipContent
                className="z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                sideOffset={5}
            >
                {body}
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
);
