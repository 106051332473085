/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

import { colors } from "#utils/colors";

export const styledWrapperCSS = css`
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.medGray};
    border-radius: 16px;
    padding: 16px;
    @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
        padding-top: 12px;
    }
    height: auto;
`;

export const StyledWrapper = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.medGray};
    border-radius: 16px;
    padding: 16px;
    height: auto;
`;

export const gridGap = {
    MOBILE_GRIDGAP: "2.5vw",
    TABLET_GRIDGAP: "2vw",
    DESKTOP_GRIDGAP: "1.5vw",
};

export const dateSelectorCSS = css`
    .rdrCalendarWrapper {
        box-sizing: border-box;
        background: #ffffff;
        display: inline-flex;
        flex-direction: column;
        overflow: auto;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .rdrDateDisplay {
        display: flex;
        justify-content: space-between;
    }

    .rdrDateDisplayItem {
        flex: 1 1;
        width: 0;
        text-align: center;
        color: inherit;
    }

    .rdrDateDisplayItem + .rdrDateDisplayItem {
        margin-left: 0.833em;
    }

    .rdrDateDisplayItem input {
        text-align: inherit;
    }

    .rdrDateDisplayItem input:disabled {
        cursor: default;
    }

    .rdrMonthAndYearWrapper {
        box-sizing: inherit;
        display: flex;
        justify-content: space-between;
    }

    .rdrMonthAndYearPickers {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rdrMonths {
        display: flex;
    }

    .rdrMonthsVertical {
        flex-direction: column;
    }

    .rdrMonthsHorizontal > div > div > div {
        display: flex;
        flex-direction: row;
    }

    .rdrMonth {
        width: 27.667em;
    }

    .rdrWeekDays {
        display: flex;
    }

    .rdrWeekDay {
        flex-basis: calc(100% / 7);
        box-sizing: none;
        text-align: center;
    }

    .rdrDays {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0px;
    }

    .rdrMonthName {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #202122;
    }

    .rdrInfiniteMonths {
        overflow: auto;
    }

    .rdrDateRangeWrapper {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .rdrDateInput {
        position: relative;
    }

    .rdrDateInput input {
        outline: none;
    }

    .rdrDateInput .rdrWarning {
        position: absolute;
        font-size: 1.6em;
        line-height: 1.6em;
        top: 0;
        right: 0.25em;
        color: #ff0000;
    }

    .rdrDay {
        box-sizing: none;
        width: calc(100% / 7);
        position: relative;
        font: inherit;
        cursor: pointer;
    }

    .rdrDayNumber {
        display: block;
        position: relative;
    }

    .rdrDayNumber span {
        color: #1d2429;
    }

    .rdrDayDisabled {
        cursor: not-allowed;
    }

    @supports (-ms-ime-align: auto) {
        .rdrDay {
            flex-basis: 14.285% !important;
        }
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
        pointer-events: none;
    }

    .rdrInRange {
        background: currentColor;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        pointer-events: none;
    }

    .rdrDayHovered {
        box-shadow: none;
        border-radius: 8px;
    }

    .rdrDateRangePickerWrapper {
        display: inline-flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .rdrStaticRanges {
        display: flex;
        flex-direction: column;
    }

    .rdrStaticRange {
        font-size: inherit;
    }

    .rdrInputRange {
        display: flex;
    }

    .rdrDateDisplay {
        margin: 10px 5px;
    }

    .rdrDateDisplayItem {
        background-color: rgb(255, 255, 255);
        border: 1px solid #c4c8cc;
        box-sizing: border-box;
        border-radius: 56px;
    }

    .rdrDateDisplayItem input {
        font-style: normal;
        font-weight: bold;
        cursor: pointer;
        height: 2.5em;
        line-height: 2.5em;
        border: 0px;
        background: transparent;
        width: 100%;
        color: #202122;
        box-shadow: none;
    }

    .rdrDateDisplayItemActive {
        border-color: #0088ff;
    }

    .rdrDateDisplayItemActive input {
        color: #0088ff;
    }

    .rdrMonthAndYearWrapper {
        align-items: center;
        height: 40px;
    }

    .rdrMonthAndYearPickers {
        font-weight: 600;
    }

    .rdrMonthAndYearPickers select {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        border: 0;
        background: transparent;
        padding: 10px 30px 10px 10px;
        border-radius: 4px;
        outline: 0;
        color: #3e484f;
        background-position: right 8px center;
        cursor: pointer;
        text-align: center;
    }

    .rdrMonthAndYearPickers select:hover {
        background-color: rgba(0, 0, 0, 0.07);
    }

    .rdrMonthPicker,
    .rdrYearPicker {
        margin: 0 5px;
    }

    .rdrWeekDays {
        padding: 0 0.833em;
    }

    .rdrMonth {
        padding: 0 0.833em 1.666em 0.833em;
    }

    .rdrMonth .rdrWeekDays {
        padding: 0;
    }

    .rdrMonths.rdrMonthsVertical .rdrMonth:first-of-type .rdrMonthName {
        display: none;
    }

    .rdrWeekDay {
        font-weight: 400;
        line-height: 2.667em;
        color: rgb(132, 144, 149);
    }

    .rdrDay {
        background: transparent;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 0;
        padding: 0;
        line-height: 3em;
        height: 4em;
        text-align: center;
        color: #1d2429;
        box-shadow: none;
    }

    .rdrDay:focus {
        outline: 0;
    }

    .rdrDayNumber {
        outline: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        top: 5px;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
    .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
        background: #aeb9bf;
    }

    /* Font color for white tiles  */
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: rgba(0, 0, 0, 0.85);
    }

    /* Font color for starting and ending edge of selection */
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
        color: rgba(255, 255, 255, 0.85);
    }

    .rdrSelected,
    .rdrStartEdge,
    .rdrEndEdge {
        background: #0088ff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .rdrSelected {
        left: 2px;
        right: 2px;
    }

    .rdrStartEdge {
        border-radius: 8px;
    }

    .rdrEndEdge {
        border-radius: 8px;
    }

    .rdrSelected {
        border-radius: 8px;
    }

    .rdrInRange {
        border-radius: 0px;
    }

    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
        left: 2px;
    }

    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
        right: 2px;
    }

    .rdrDayStartOfMonth .rdrDayInPreview,
    .rdrDayStartOfMonth .rdrDayEndPreview,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrDayEndPreview {
        border-left-width: 1px;
        left: 0px;
    }

    .rdrDayEndOfMonth .rdrDayInPreview,
    .rdrDayEndOfMonth .rdrDayStartPreview,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrDayStartPreview {
        border-right-width: 1px;
        right: 0px;
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        background: rgba(255, 255, 255, 0.09);
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        pointer-events: none;
        border: 0px solid currentColor;
        z-index: 1;
    }

    .rdrDayStartPreview {
        border-top-width: 0px;
        border-left-width: 1px;
        border-bottom-width: 0px;
        border-radius: 8px;
        left: 0px;
    }

    .rdrDayInPreview {
        border-top-width: 1px;
        border-bottom-width: 1px;
        background: rgba(240, 247, 254, 0.7);
    }

    .rdrDayEndPreview {
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-radius: 8px;
        right: 2px;
        right: 0px;
    }

    .rdrInputRanges {
        padding: 10px 0;
    }

    .rdrInputRange {
        align-items: center;
        padding: 5px 20px;
    }

    .rdrInputRangeInput {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        text-align: center;
        border: solid 1px rgb(222, 231, 235);
        margin-right: 10px;
        color: rgb(108, 118, 122);
    }

    .rdrInputRangeInput:focus,
    .rdrInputRangeInput:hover {
        border-color: rgb(180, 191, 196);
        outline: 0;
        color: #333;
    }

    .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
        .rdrDayHovered
        .rdrDayNumber:after {
        content: "";
        border-radius: 8px;
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: 0px;
        right: 0px;
        background: transparent;
    }

    .rdrDayPassive {
        pointer-events: none;
    }

    .rdrDayPassive .rdrInRange,
    .rdrDayPassive .rdrStartEdge,
    .rdrDayPassive .rdrEndEdge,
    .rdrDayPassive .rdrSelected,
    .rdrDayPassive .rdrDayStartPreview,
    .rdrDayPassive .rdrDayInPreview,
    .rdrDayPassive .rdrDayEndPreview {
        display: none;
    }

    .rdrDayDisabled .rdrDayNumber span {
        color: #aeb9bf;
    }

    .rdrDayPassive .rdrDayNumber span {
        color: #ffffff;
    }

    .rdrDayDisabled .rdrInRange,
    .rdrDayDisabled .rdrStartEdge,
    .rdrDayDisabled .rdrEndEdge,
    .rdrDayDisabled .rdrSelected,
    .rdrDayDisabled .rdrDayStartPreview,
    .rdrDayDisabled .rdrDayInPreview,
    .rdrDayDisabled .rdrDayEndPreview {
        filter: grayscale(100%) opacity(60%);
    }

    .rdrMonthName {
        text-align: center;
        font-style: normal;
        font-weight: bold;
        color: #202122;
        padding: 0.833em;
    }
`;
