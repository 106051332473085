import { useEffect, useState, useCallback, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
    AttendancePayPeriodStrategy,
    PreSetBreak,
    PayPeriodDurationWeeks,
} from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";
import {
    ReloadIcon,
    CalendarIcon,
    TrashIcon,
    PlusCircledIcon,
} from "@radix-ui/react-icons";
import { toast } from "sonner";
import { Info } from "luxon";
import { compose } from "lodash/fp";
import { format } from "date-fns";
import { NumberInput } from "@tremor/react";

import { invariant } from "src/lib/invariant";
import api from "src/api/rest";
import { getActiveStore, getUserTeamPermission } from "src/redux/selectors";
import { setActiveStore } from "src/redux/slices";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";
import { RadioGroup, RadioGroupItem } from "src/@/components/ui/radio-group";
import { Input } from "src/@/components/ui/input";
import { useAppDispatch } from "src/redux/hooks";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import { cn } from "src/@/lib/utils";
import { Button } from "src/@/components/ui/button";
import { Calendar } from "src/@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";

type BreakType = {
    id: string;
    hasPaidVariant: boolean;
    hasUnpaidVariant: boolean;
    durationMinutes: number;
};

const attendanceSettingsSchema = z
    .object({
        payPeriodDurationWeeks: z.nativeEnum(PayPeriodDurationWeeks),
        hasDailyOvertime: z.boolean(),
        dailyOvertimeThreshold: z.number().optional(),
        dailyOvertimeMultiplier: z.number().optional(),
        hasDailyDoubleOvertime: z.boolean(),
        dailyDoubleOvertimeThreshold: z.number().optional(),
        dailyDoubleOvertimeMultiplier: z.number().optional(),
        hasWeeklyOvertime: z.boolean(),
        weeklyOvertimeThreshold: z.number().optional(),
        weeklyOvertimeMultiplier: z.number().optional(),
        preSetBreaks: z.array(
            z.object({
                id: z.string(),
                hasPaidVariant: z.boolean(),
                hasUnpaidVariant: z.boolean(),
                durationMinutes: z.number(),
            }),
        ),
        strategy: z.enum(["weekly", "every2Weeks", "twiceAMonth", "monthly"]),
        /** Luxon uses Monday = 1. This is for weekly and biweekly */
        startOfWeek: z.string().optional(), // Shadcn requires string for select
        /** This is for monthly and semimonthly */
        startOfMonth: z.number().optional(),
        sendSMSOnEmployeeAction: z.boolean().optional(),
        sendSMSOnAdminAction: z.boolean().optional(),
    })
    .refine(
        (data) =>
            !(data.hasDailyOvertime && data.hasDailyDoubleOvertime) || // must add this condition in case overtimes are not used
            (data?.dailyDoubleOvertimeThreshold || 0) >=
                (data?.dailyOvertimeThreshold || 0),
        {
            path: ["dailyDoubleOvertimeThreshold"],
            message:
                "Daily Double Overtime Threshold must be greater than or equal to Daily Overtime Threshold",
        },
    )
    .refine(
        (data) =>
            !(data.hasDailyOvertime && data.hasWeeklyOvertime) || // must add this condition in case overtimes are not used
            (data?.weeklyOvertimeThreshold || 0) >=
                (data?.dailyOvertimeThreshold || 0),
        {
            path: ["weeklyOvertimeThreshold"],
            message:
                "Weekly Overtime Threshold must be greater than or equal to Daily Overtime Threshold",
        },
    )
    .refine(
        (data) =>
            !(data.hasDailyDoubleOvertime && data.hasWeeklyOvertime) || // must add this condition in case overtimes are not used
            (data?.weeklyOvertimeThreshold || 0) >=
                (data?.dailyDoubleOvertimeThreshold || 0),
        {
            path: ["weeklyOvertimeThreshold"],
            message:
                "Weekly Overtime Threshold must be greater than or equal to Daily Double Overtime Threshold",
        },
    );

const paymentStrategies = {
    weekly: {
        label: "Weekly",
        description: "Payday is once a week on selected day.",
    },
    every2Weeks: {
        label: "Bi-Weekly",
        description: "Payday is every two weeks on selected day.",
    },
    twiceAMonth: {
        label: "Semi-Monthly",
        description:
            "Payday is twice a month on the 15th and the last day of the month.",
    },
    monthly: {
        label: "Monthly",
        description: "Payday is once a month on the selected day.",
    },
};

const weekDays = Info.weekdays();
const weekDayOptions = weekDays.map((day, index) => ({
    label: day,
    value: index + 1,
}));

// For monthly pay period strategy
const currentDate = new Date();

const IconSuffix = (text: string) => (
    <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
        {text}
    </div>
);

const AttendanceSettings = () => {
    const canEdit = useHasEditSettingsForActiveStore();
    const isSnackpassEmployee = useSelector(getUserTeamPermission);
    const inputsDisabled = !isSnackpassEmployee && !canEdit;
    const dispatch = useAppDispatch();
    const setStore = compose(dispatch, setActiveStore);
    const activeStore = useSelector(getActiveStore);
    const [isLoading, setIsLoading] = useState(false);
    const [unpaidBreak, setUnpaidBreak] = useState(0);
    const [paidBreak, setPaidBreak] = useState(0);

    const form = useForm<z.infer<typeof attendanceSettingsSchema>>({
        resolver: zodResolver(attendanceSettingsSchema),
        defaultValues: {
            payPeriodDurationWeeks: 2,
            hasDailyOvertime: false,
            dailyOvertimeThreshold: 0,
            dailyOvertimeMultiplier: 1,
            hasDailyDoubleOvertime: false,
            dailyDoubleOvertimeThreshold: 0,
            dailyDoubleOvertimeMultiplier: 1,
            hasWeeklyOvertime: false,
            weeklyOvertimeThreshold: 0,
            weeklyOvertimeMultiplier: 1,
            preSetBreaks: [],
            strategy: AttendancePayPeriodStrategy.Weekly,
            startOfWeek: "1", // Luxon starts at 1
            startOfMonth: 1,
            sendSMSOnEmployeeAction: true,
            sendSMSOnAdminAction: true,
        },
    });

    const defaultValues: z.infer<typeof attendanceSettingsSchema> =
        useMemo(() => {
            const breaks = activeStore?.attendanceSettings?.preSetBreaks ?? [];
            const newBreaks = breaks.map((breakObj) => {
                // @ts-expect-error - invariant checks if _id is in breakObj
                invariant(breakObj._id, "break ID exists");
                // @ts-expect-error - invariant checks if _id is in breakObj
                const { _id, ...rest } = breakObj;
                return { ...rest, id: _id };
            });

            return {
                payPeriodDurationWeeks:
                    activeStore?.attendanceSettings?.payPeriodDurationWeeks ||
                    PayPeriodDurationWeeks.Two,
                hasDailyOvertime:
                    !!activeStore?.attendanceSettings?.hasDailyOvertime,
                dailyOvertimeThreshold:
                    activeStore?.attendanceSettings?.dailyOvertimeThreshold ||
                    0,
                dailyOvertimeMultiplier:
                    activeStore?.attendanceSettings?.dailyOvertimeMultiplier ||
                    1,
                hasDailyDoubleOvertime:
                    !!activeStore?.attendanceSettings?.hasDailyDoubleOvertime,
                dailyDoubleOvertimeThreshold:
                    activeStore?.attendanceSettings
                        ?.dailyDoubleOvertimeThreshold || 0,
                dailyDoubleOvertimeMultiplier:
                    activeStore?.attendanceSettings
                        ?.dailyDoubleOvertimeMultiplier || 1,
                hasWeeklyOvertime:
                    !!activeStore?.attendanceSettings?.hasWeeklyOvertime,
                weeklyOvertimeThreshold:
                    activeStore?.attendanceSettings?.weeklyOvertimeThreshold ||
                    0,
                weeklyOvertimeMultiplier:
                    activeStore?.attendanceSettings?.weeklyOvertimeMultiplier ||
                    1,
                preSetBreaks: newBreaks,
                strategy:
                    activeStore?.attendanceSettings?.strategy ||
                    AttendancePayPeriodStrategy.Weekly,
                startOfWeek:
                    activeStore?.attendanceSettings?.startOfWeek?.toString() ||
                    "1",
                startOfMonth:
                    activeStore?.attendanceSettings?.startOfMonth || 1,
                sendSMSOnEmployeeAction:
                    !!activeStore?.attendanceSettings?.sendSMSOnEmployeeAction,
                sendSMSOnAdminAction:
                    !!activeStore?.attendanceSettings?.sendSMSOnAdminAction,
            };
        }, [activeStore]);

    const resetForm = useCallback(() => {
        form.reset(defaultValues);
    }, [form, defaultValues]);

    // Auto resets form when new store is selected or when form changes are cancelled
    useEffect(() => {
        if (activeStore) {
            resetForm();
        }
    }, [activeStore, resetForm]);

    const onSubmit = useCallback(
        async (values: z.infer<typeof attendanceSettingsSchema>) => {
            if (!activeStore || !canEdit) return;
            setIsLoading(true);
            api.stores
                // @ts-expect-error Outdated <Maybe /> structure that doesn't fit zod validation
                .setAttendanceSettings(activeStore._id, {
                    ...values,
                    startOfWeek: Number(values.startOfWeek),
                })
                .then(({ data: { store } }) => {
                    setStore(store);
                    toast.success("Changes saved successfully!");
                })
                .catch((error) => {
                    console.error(`Could not set attendance settings`, error);
                    toast.error(
                        "Something went wrong. Could not save changes.",
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [activeStore, canEdit, setStore],
    );

    // client side we need paid/unpaid breaks stored separately
    // but on the server they are stored as 1 entry per break duration
    // So recombine and check for new paid/unpaid entries
    // code from legacy index file
    const updateBreaks = useCallback(
        (variant: "paid" | "unpaid") => {
            const preSetBreaks: Map<number, PreSetBreak> = new Map(
                form
                    .getValues("preSetBreaks")
                    .filter((value) => value.hasPaidVariant)
                    .map((paidBreak) => [
                        paidBreak.durationMinutes,
                        {
                            id: paidBreak.id,
                            durationMinutes: paidBreak.durationMinutes,
                            hasPaidVariant: true,
                            hasUnpaidVariant: false,
                        },
                    ]),
            );
            if (variant === "paid") {
                if (!preSetBreaks.has(paidBreak)) {
                    preSetBreaks.set(paidBreak, {
                        id: "-1",
                        durationMinutes: paidBreak,
                        hasPaidVariant: true,
                        hasUnpaidVariant: false,
                    });
                }
            } else if (variant === "unpaid") {
                if (!preSetBreaks.has(unpaidBreak)) {
                    preSetBreaks.set(unpaidBreak, {
                        id: "-1",
                        durationMinutes: unpaidBreak,
                        hasPaidVariant: false,
                        hasUnpaidVariant: true,
                    });
                } else {
                    const existingBreak = preSetBreaks.get(unpaidBreak);
                    if (existingBreak) {
                        existingBreak.hasUnpaidVariant = true;
                    }
                }
            }
            form.getValues("preSetBreaks")
                .filter((value) => value.hasUnpaidVariant)
                .forEach((unpaidBreak) => {
                    const existingBreak = preSetBreaks.get(
                        unpaidBreak.durationMinutes,
                    );
                    if (existingBreak) {
                        existingBreak.hasUnpaidVariant = true;
                    } else {
                        preSetBreaks.set(unpaidBreak.durationMinutes, {
                            id: unpaidBreak.id,
                            durationMinutes: unpaidBreak.durationMinutes,
                            hasPaidVariant: false,
                            hasUnpaidVariant: true,
                        });
                    }
                });
            if (variant === "unpaid") setUnpaidBreak(0);
            else setPaidBreak(0);
            return Array.from(preSetBreaks.values());
        },
        [form, paidBreak, unpaidBreak],
    );

    const deleteBreaks = useCallback(
        (
            value: BreakType[],
            onChange: (val: BreakType[]) => void,
            index: number,
            variant: "paid" | "unpaid",
        ) => {
            const newBreaks = [...value];
            if (variant === "unpaid") newBreaks[index].hasUnpaidVariant = false;
            else newBreaks[index].hasPaidVariant = false;
            onChange(
                newBreaks.filter(
                    (value: BreakType) =>
                        value.hasPaidVariant || value.hasUnpaidVariant,
                ),
            );
        },
        [],
    );

    const updateNewBreak = useCallback(
        (
            e: React.ChangeEvent<HTMLInputElement>,
            variant: "paid" | "unpaid",
        ) => {
            if (e.target.value === "") {
                if (variant === "unpaid") setUnpaidBreak(0);
                else setPaidBreak(0);
                return;
            }
            const intVal = parseInt(e.target.value, 10);
            if (!isNaN(intVal) && intVal > 0) {
                if (variant === "unpaid") setUnpaidBreak(intVal);
                else setPaidBreak(intVal);
            }
        },
        [setUnpaidBreak],
    );

    const addBreaks = useCallback(
        (onChange: (val: BreakType[]) => void, variant: "paid" | "unpaid") => {
            if (
                (variant === "unpaid" && unpaidBreak === 0) ||
                (variant === "paid" && paidBreak === 0)
            ) {
                return;
            }
            const newBreaks =
                variant === "unpaid"
                    ? updateBreaks("unpaid")
                    : updateBreaks("paid");
            onChange(newBreaks);
        },
        [unpaidBreak, paidBreak, updateBreaks],
    );
    const updateNumber = useCallback(
        (
            e: React.ChangeEvent<HTMLInputElement>,
            field: {
                value: number | undefined;
                onChange: (val: number | undefined) => void;
            },
        ) => {
            field.onChange(Number.parseFloat(e.target.value ?? 0));
        },
        [],
    );

    const updateDate = useCallback(
        (
            date: Date | undefined,
            field: {
                value: number | undefined;
                onChange: (val: number | undefined) => void;
            },
        ) => {
            if (date) {
                field.onChange(date.getDate());
            }
        },
        [],
    );
    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick,
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    const strategy = form.watch("strategy");
    const hasDailyOvertime = form.watch("hasDailyOvertime");
    const hasDailyDoubleOvertime = form.watch("hasDailyDoubleOvertime");
    const hasWeeklyOvertime = form.watch("hasWeeklyOvertime");

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <div className="flex flex-row items-center gap-3">
                            <CardTitle className="text-2xl font-bold">
                                Clock In/Out
                            </CardTitle>
                        </div>
                        <div className="text-gray-600">
                            Manage pay period, shift breaks, and overtime rules.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                SMS Notifications
                            </CardTitle>
                            <CardDescription>
                                Choose when to be notified via text message.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="sendSMSOnEmployeeAction"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Employee Clock Out
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Receive a notification
                                                    anytime an employee ends a
                                                    shift.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="sendSMSOnAdminAction"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Shift Edit
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Receive a notification
                                                    anytime an admin edits a
                                                    completed shift.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Pay Period
                            </CardTitle>
                            <CardDescription>
                                Choose when employees receive payment.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="strategy"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <RadioGroup
                                                defaultValue={"weekly"}
                                                value={field.value}
                                                onValueChange={field.onChange}
                                                disabled={inputsDisabled}
                                            >
                                                {Object.values(
                                                    AttendancePayPeriodStrategy,
                                                ).map((strategy, i) => (
                                                    <div
                                                        className="mb-2 flex items-center justify-between space-x-2"
                                                        key={i}
                                                    >
                                                        <div>
                                                            <CardTitle className="text-base font-medium">
                                                                {
                                                                    paymentStrategies[
                                                                        strategy
                                                                    ].label
                                                                }
                                                            </CardTitle>
                                                            <CardDescription className="pt-0.5">
                                                                {
                                                                    paymentStrategies[
                                                                        strategy
                                                                    ]
                                                                        .description
                                                                }
                                                            </CardDescription>
                                                        </div>
                                                        <RadioGroupItem
                                                            className="data-[state=checked]:bg-slate-950"
                                                            key={strategy}
                                                            value={strategy}
                                                        />
                                                    </div>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            {(strategy === AttendancePayPeriodStrategy.Weekly ||
                                strategy ===
                                    AttendancePayPeriodStrategy.BiWeekly) && (
                                <FormField
                                    control={form.control}
                                    name="startOfWeek"
                                    render={({ field }) => (
                                        <FormItem>
                                            <CardTitle className="text-base font-medium">
                                                Start period on...
                                            </CardTitle>
                                            <FormControl>
                                                <Select
                                                    value={field.value}
                                                    onValueChange={
                                                        field.onChange
                                                    }
                                                    disabled={inputsDisabled}
                                                >
                                                    <SelectTrigger className="w-[220px]">
                                                        <SelectValue placeholder="Select Day" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {weekDayOptions.map(
                                                            (option, _) => (
                                                                <SelectItem
                                                                    key={
                                                                        option.label
                                                                    }
                                                                    value={String(
                                                                        option.value,
                                                                    )}
                                                                >
                                                                    {
                                                                        option.label
                                                                    }
                                                                </SelectItem>
                                                            ),
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}
                            {strategy ===
                                AttendancePayPeriodStrategy.Monthly && (
                                <FormField
                                    control={form.control}
                                    name="startOfMonth"
                                    render={({ field }) => (
                                        <FormItem>
                                            <CardTitle className="text-base font-medium">
                                                Start period on...
                                            </CardTitle>
                                            <FormControl>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button
                                                            variant={"outline"}
                                                            className={cn(
                                                                "w-[280px] justify-start text-left font-normal",
                                                                !field.value &&
                                                                    "text-muted-foreground",
                                                            )}
                                                            disabled={
                                                                inputsDisabled
                                                            }
                                                        >
                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                            {field.value ? (
                                                                format(
                                                                    new Date(
                                                                        currentDate.getFullYear(),
                                                                        currentDate.getMonth(),
                                                                        field.value,
                                                                    ),
                                                                    "do",
                                                                )
                                                            ) : (
                                                                <span>
                                                                    Pick a date
                                                                </span>
                                                            )}
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-auto p-0">
                                                        <Calendar
                                                            mode="single"
                                                            onSelect={(
                                                                date,
                                                            ) => {
                                                                updateDate(
                                                                    date,
                                                                    field,
                                                                );
                                                            }}
                                                            initialFocus
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}
                            {strategy ===
                                AttendancePayPeriodStrategy.SemiMonthly && (
                                <CardTitle className="text-base font-medium">
                                    Period Starts On 1st and 16th by default.
                                </CardTitle>
                            )}
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Daily Overtime Rules
                            </CardTitle>
                            <CardDescription>
                                Choose if and when to offer daily overtime pay.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="hasDailyOvertime"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Daily Overtime
                                                </CardTitle>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="dailyOvertimeThreshold"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                After...
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Hours worked before overtime
                                                starts.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="Set hours"
                                                        min={0}
                                                        step={1}
                                                        className="w-[220px] flex-1 [&>input]:pl-2"
                                                        icon={() =>
                                                            IconSuffix("hours")
                                                        }
                                                        {...field}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            updateNumber(
                                                                e,
                                                                field,
                                                            )
                                                        }
                                                        disabled={
                                                            !hasDailyOvertime ||
                                                            inputsDisabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="dailyOvertimeMultiplier"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Hourly pay is
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This option will be pre-selected
                                                for employees.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="Set pay"
                                                        min={0}
                                                        step={0.1}
                                                        className="w-[220px] flex-1 [&>input]:pl-2"
                                                        icon={() =>
                                                            IconSuffix(
                                                                "× regular pay",
                                                            )
                                                        }
                                                        {...field}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            updateNumber(
                                                                e,
                                                                field,
                                                            )
                                                        }
                                                        disabled={
                                                            !hasDailyOvertime ||
                                                            inputsDisabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Daily Double Overtime Rules
                            </CardTitle>
                            <CardDescription>
                                Choose if and when to offer double overtime pay.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="hasDailyDoubleOvertime"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Daily Double Overtime
                                                </CardTitle>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="dailyDoubleOvertimeThreshold"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                After...
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Hours worked before double
                                                overtime starts.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="Set hours"
                                                        min={0}
                                                        step={1}
                                                        className="w-[220px] flex-1 [&>input]:pl-2"
                                                        icon={() =>
                                                            IconSuffix("hours")
                                                        }
                                                        {...field}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            updateNumber(
                                                                e,
                                                                field,
                                                            )
                                                        }
                                                        disabled={
                                                            !hasDailyDoubleOvertime ||
                                                            inputsDisabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="dailyDoubleOvertimeMultiplier"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Hourly pay is
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This option will be pre-selected
                                                for employees.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="Set pay"
                                                        min={0}
                                                        step={0.1}
                                                        className="w-[220px] flex-1 [&>input]:pl-2"
                                                        icon={() =>
                                                            IconSuffix(
                                                                "× regular pay",
                                                            )
                                                        }
                                                        {...field}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            updateNumber(
                                                                e,
                                                                field,
                                                            )
                                                        }
                                                        disabled={
                                                            !hasDailyDoubleOvertime ||
                                                            inputsDisabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Weekly Overtime Rules
                            </CardTitle>
                            <CardDescription>
                                Choose if and when to offer weekly overtime pay.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="hasWeeklyOvertime"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Weekly Overtime
                                                </CardTitle>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="weeklyOvertimeThreshold"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                After...
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Hours worked before weekly
                                                overtime starts.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="Set hours"
                                                        min={0}
                                                        step={1}
                                                        className="w-[220px] flex-1 [&>input]:pl-2"
                                                        icon={() =>
                                                            IconSuffix("hours")
                                                        }
                                                        {...field}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            updateNumber(
                                                                e,
                                                                field,
                                                            )
                                                        }
                                                        disabled={
                                                            !hasWeeklyOvertime ||
                                                            inputsDisabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="weeklyOvertimeMultiplier"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Hourly pay is
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This option will be pre-selected
                                                for employees.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="Set pay"
                                                        min={0}
                                                        step={0.1}
                                                        className="w-[220px] flex-1 [&>input]:pl-2"
                                                        icon={() =>
                                                            IconSuffix(
                                                                "× regular pay",
                                                            )
                                                        }
                                                        {...field}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            updateNumber(
                                                                e,
                                                                field,
                                                            )
                                                        }
                                                        disabled={
                                                            !hasWeeklyOvertime ||
                                                            inputsDisabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Default Break Options
                            </CardTitle>
                            <CardDescription>
                                Set preset durations for paid or unpaid breaks.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="preSetBreaks"
                                render={({ field }) => (
                                    <FormItem>
                                        <CardTitle className="text-base font-medium">
                                            Unpaid
                                        </CardTitle>
                                        <FormControl>
                                            <>
                                                {field.value?.length > 0
                                                    ? field.value
                                                          .filter(
                                                              (value) =>
                                                                  value.hasUnpaidVariant,
                                                          )
                                                          .map(
                                                              (
                                                                  value,
                                                                  index,
                                                              ) => (
                                                                  <div
                                                                      className="flex items-center space-x-2"
                                                                      key={
                                                                          value.durationMinutes
                                                                      }
                                                                  >
                                                                      <Input
                                                                          className="w-[220px]"
                                                                          disabled
                                                                          value={`${value.durationMinutes} minutes`}
                                                                      />
                                                                      <TooltipProvider>
                                                                          <Tooltip>
                                                                              <TooltipTrigger
                                                                                  asChild
                                                                              >
                                                                                  <Button
                                                                                      type="button"
                                                                                      variant="outline"
                                                                                      onClick={() => {
                                                                                          deleteBreaks(
                                                                                              field.value,
                                                                                              field.onChange,
                                                                                              index,
                                                                                              "unpaid",
                                                                                          );
                                                                                      }}
                                                                                      disabled={
                                                                                          inputsDisabled
                                                                                      }
                                                                                  >
                                                                                      <TrashIcon />
                                                                                  </Button>
                                                                              </TooltipTrigger>
                                                                              <TooltipContent>
                                                                                  <p>
                                                                                      Delete
                                                                                      Break
                                                                                  </p>
                                                                              </TooltipContent>
                                                                          </Tooltip>
                                                                      </TooltipProvider>
                                                                  </div>
                                                              ),
                                                          )
                                                    : null}
                                                <div className="flex items-center space-x-2 pb-2">
                                                    <Input
                                                        className="w-[220px]"
                                                        placeholder="Add break (minutes)"
                                                        onChange={(e) => {
                                                            updateNewBreak(
                                                                e,
                                                                "unpaid",
                                                            );
                                                        }}
                                                        value={String(
                                                            unpaidBreak || "",
                                                        )}
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger
                                                                asChild
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        addBreaks(
                                                                            field.onChange,
                                                                            "unpaid",
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        inputsDisabled
                                                                    }
                                                                >
                                                                    <PlusCircledIcon />
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p>Add Break</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </div>

                                                <hr />
                                                <CardTitle className="text-base font-medium">
                                                    Paid
                                                </CardTitle>
                                                {field.value?.length > 0
                                                    ? field.value
                                                          .filter(
                                                              (value) =>
                                                                  value.hasPaidVariant,
                                                          )
                                                          .map(
                                                              (
                                                                  value,
                                                                  index,
                                                              ) => (
                                                                  <div
                                                                      className="flex items-center space-x-2"
                                                                      key={
                                                                          value.durationMinutes
                                                                      }
                                                                  >
                                                                      <Input
                                                                          className="w-[220px]"
                                                                          disabled
                                                                          value={`${value.durationMinutes} minutes`}
                                                                      />
                                                                      <TooltipProvider>
                                                                          <Tooltip>
                                                                              <TooltipTrigger
                                                                                  asChild
                                                                              >
                                                                                  <Button
                                                                                      type="button"
                                                                                      variant="outline"
                                                                                      onClick={() => {
                                                                                          deleteBreaks(
                                                                                              field.value,
                                                                                              field.onChange,
                                                                                              index,
                                                                                              "paid",
                                                                                          );
                                                                                      }}
                                                                                      disabled={
                                                                                          inputsDisabled
                                                                                      }
                                                                                  >
                                                                                      <TrashIcon />
                                                                                  </Button>
                                                                              </TooltipTrigger>
                                                                              <TooltipContent>
                                                                                  <p>
                                                                                      Delete
                                                                                      Break
                                                                                  </p>
                                                                              </TooltipContent>
                                                                          </Tooltip>
                                                                      </TooltipProvider>
                                                                  </div>
                                                              ),
                                                          )
                                                    : null}
                                                <div className="flex items-center space-x-2">
                                                    <Input
                                                        className="w-[220px]"
                                                        placeholder="Add break (minutes)"
                                                        onChange={(e) => {
                                                            updateNewBreak(
                                                                e,
                                                                "paid",
                                                            );
                                                        }}
                                                        value={String(
                                                            paidBreak || "",
                                                        )}
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger
                                                                asChild
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        addBreaks(
                                                                            field.onChange,
                                                                            "paid",
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        inputsDisabled
                                                                    }
                                                                >
                                                                    <PlusCircledIcon className="h-4 w-4" />
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p>Add Break</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </div>
                                            </>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};

export default AttendanceSettings;
