import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
    CommandDialog,
    CommandItem,
    CommandList,
    CommandEmpty,
    CommandInput,
} from "src/@/components/ui/command";
import { RouteDefinition, useNavMap } from "#navigation/navigations-routes";

export type GlobalSearchCommandPaletteProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const GlobalSearchCommandPalette = ({
    isOpen,
    onClose,
}: GlobalSearchCommandPaletteProps) => {
    const [query, setQuery] = useState<string>("");
    const navMapping = useNavMap();
    const history = useHistory();

    const handleItemClick = (route: RouteDefinition) => {
        route.onClick?.();
        history.push(route.path);
        onClose();
    };

    const topLevelRoutes = useMemo(
        () => navMapping.filter((item) => item.condition !== false),
        [navMapping],
    );

    const allRoutes = useMemo(
        () => findLeafRoutes(topLevelRoutes),
        [topLevelRoutes],
    );

    // if we don't have any query typed in, just use the top level routes.
    const routes = query.length > 0 ? allRoutes : topLevelRoutes;

    return (
        <CommandDialog open={isOpen} onOpenChange={onClose}>
            <CommandInput
                value={query}
                onValueChange={setQuery}
                placeholder="Search for keywords..."
                className="w-full py-3 pl-12 pr-4"
            />
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                {routes.map((route, index) => (
                    <CommandItem
                        key={index}
                        onSelect={() => handleItemClick(route)}
                        className="mt-0.5 space-x-2"
                        keywords={route.searchKeywords}
                    >
                        <div className="w-8 text-center [&>svg]:fill-neutral-500 ">
                            {route?.icon}
                        </div>
                        <div className="flex flex-col">
                            <p className="overflow-hidden text-sm">
                                {route.name}
                            </p>
                            {query.length > 0 && (
                                <p className="text-sm text-gray-500">
                                    <span className="font-bold">
                                        {route.searchKeywords?.find((e) =>
                                            e
                                                .toLowerCase()
                                                .includes(query.toLowerCase()),
                                        )}
                                    </span>
                                </p>
                            )}
                        </div>
                    </CommandItem>
                ))}
            </CommandList>
        </CommandDialog>
    );
};

function findLeafRoutes(
    routes: RouteDefinition[],
    prefix = "",
): RouteDefinition[] {
    return routes.flatMap((e) =>
        e.children && e.children.length > 0
            ? // if children, return keep recursing
              findLeafRoutes(e.children, prefix + e.name + " -> ")
            : // else, return self
              [{ ...e, name: prefix + e.name }],
    );
}
