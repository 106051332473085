/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { usePaymentTypePicker } from "#hooks/use-payment-type-picker";
import { DashboardPaymentTypes } from "#payment-type-picker/lib";

export const FeatureAlert = () => {
    const { paymentType } = usePaymentTypePicker();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (paymentType === DashboardPaymentTypes.CASH_AND_OTHER) {
            setShow(true);
        }
        return () => {
            setShow(false);
        };
    }, [paymentType]);

    if (show) {
        return (
            <Alert
                onClose={() => setShow(false)}
                dismissible
                css={featureAlert}
            >
                🎉 &nbsp;<b>New Feature:</b> Tap the ‘Export’ button to download
                a cash order CSV report.
            </Alert>
        );
    }
    return <></>;
};

const featureAlert = css`
    border-radius: 16px !important;
    font-weight: 600;
`;
