/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useMemo, useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import { useHistory } from "react-router";
import moment from "moment-timezone";
import { EmployeeShiftDetails } from "@snackpass/snackpass-types";
import { Maybe } from "@snackpass/snackpass-types/build/utils/types";
import { toDollar } from "@snackpass/accounting";
import { DateTime, Interval } from "luxon";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColumnDef, Row } from "@tanstack/react-table";
import { GearIcon, PlusIcon } from "@radix-ui/react-icons";
import { Card } from "@tremor/react";
import { DateRange } from "react-day-picker";
import { toast } from "sonner";
import Skeleton from "react-loading-skeleton";
import { Drawer } from "antd";
import _ from "lodash";

import usePrepareExpandableRows, {
    RowData,
} from "#employees/employee-time-cards/hooks/use-prepare-expandable-rows";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron-right.svg";
import { ReactComponent as ClockSVG } from "src/assets/icons/clock.svg";
import { InitialsAvatar } from "#shared-components/InitialsAvatar";
import { TimecardData } from "#time-and-attendance/types";
import TimeCardViewEdit from "#employees/employee-time-cards/time-card-view-edit";
import { PartialEmployee } from "src/api/graphql/generated/types";
import DownloadTimecardsCSVButton from "#download-timecards-button";
import usePaySummary from "#employees/employee-time-cards/hooks/use-pay-summary";
import {
    getActiveStore,
    getActiveStoreAttendanceSettings,
} from "src/redux/selectors";
import {
    setupDateRangeValues,
    setupStartAndEndForStrings,
} from "#employees/helpers";
import { SettingsAlert } from "#employees/employee-time-cards/settings-alert";
import { getStoreTimezone } from "#utils/helpers";
import { DataTable } from "src/@/components/ui/data-table";
import { toDollarFormatted } from "#reports/sales-summary/lib";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import { RangePicker } from "src/@/components/date-range-picker";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import useWindowDimensions from "#hooks/use-window-dimensions";

enum ColumnId {
    Expander = "Expander",
    Avatar = "Avatar",
    Name = "Name",
    Role = "Role",
    Wage = "Wage",
    Shifts = "Shifts",
    Regular = "Regular",
    Overtime = "Overtime",
    DoubleTime = "Double Time",
    UnpaidBreaks = "Unpaid Breaks",
    TotalHours = "Total Hours",
    EstimatedWages = "Estimated Wages",
}

type TimeCardTableProps = {
    overrideEmployeeShiftDetails?: EmployeeShiftDetails[];
    overrideEmployeesWithOpenShifts?: PartialEmployee[];
};

const MOBILE_BREAKPOINT = 768;

const TimeCardTableWithOptionalSummary = ({
    overrideEmployeeShiftDetails,
    overrideEmployeesWithOpenShifts,
}: TimeCardTableProps) => {
    const storeSettings = useSelector(getActiveStoreAttendanceSettings);
    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();
    const startDateRaw = query.get("start");

    const activeStore = useSelector(getActiveStore);
    const storeTimezone = getStoreTimezone(activeStore);

    const endDateRaw = query.get("end");
    const { startDate, endDate, relatedRanges } =
        startDateRaw && endDateRaw
            ? setupStartAndEndForStrings(startDateRaw, endDateRaw)
            : setupDateRangeValues(storeSettings);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [showShiftDetail, setShowShiftDetail] = useState<boolean>(false);
    const [watchValueForRefresh, setWatchValueForRefresh] = useState(0);
    const [reportingStartDate, setReportingStartDate] =
        useState<DateTime>(startDate);
    const [reportingEndDate, setReportingEndDate] = useState<DateTime>(endDate);
    const {
        employeeShiftDetails,
        employeesWithOpenShifts,
        isLoading,
        payPeriodId,
    } = usePaySummary({
        watchValueForRefresh,
        overrideEmployeeShiftDetails,
        overrideEmployeesWithOpenShifts,
        startDate: reportingStartDate,
        endDate: reportingEndDate,
    });
    const [shifts, setShifts] = useState<TimecardData[]>([]);
    const [selectedShiftIndex, setSelectedShiftIndex] =
        useState<Maybe<number>>(null);
    const [isCreatingShift, setIsCreatingShift] = useState<boolean>(false);
    const { makeExpandedData } = usePrepareExpandableRows();
    const history = useHistory();
    const { width } = useWindowDimensions();

    const columns: ColumnDef<RowData>[] = useMemo(
        () => [
            {
                id: ColumnId.Expander,
                header: "",
                cell: ({ row }) => {
                    if (!row.getCanExpand()) return null;

                    return row.getIsExpanded() ? (
                        <ChevronDown className="h-4 w-4 fill-neutral-950" />
                    ) : (
                        <ChevronRight className="h-4 w-4 fill-neutral-950" />
                    );
                },
            },
            {
                id: ColumnId.Avatar,
                header: "",
                cell: ({ row }) => {
                    const isEven = row.index % 2 === 0;
                    return (
                        <div className="flex w-10 items-center justify-center">
                            {row.getCanExpand() ? (
                                <InitialsAvatar
                                    firstName={
                                        row.original.employeeFirstName ?? ""
                                    }
                                    lastName={
                                        row.original.employeeLastName ?? ""
                                    }
                                    backgroundColor={
                                        isEven
                                            ? SystemColors.v1.gray90
                                            : SystemColors.v1.gray80
                                    }
                                />
                            ) : (
                                <ClockSVG className="h-4 w-4 fill-neutral-400" />
                            )}
                        </div>
                    );
                },
            },
            {
                id: ColumnId.Name,
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title={"Name"} />
                ),
                accessorKey: "employeeName",
                cell: ({ row }) => {
                    const name = row.original.employeeName;
                    const shiftDate = moment
                        .tz(row.original.clockedInAt, storeTimezone)
                        .format("dddd, MMM D");
                    return row.getCanExpand() ? name : shiftDate;
                },
            },
            {
                id: "identifier",
                header: () => <>Identifier</>,
                accessorKey: "identifier",
                cell: ({ row }) => {
                    const identifier = row.original.identifier;
                    return row.depth === 0 ? identifier : null;
                },
            },
            {
                id: ColumnId.Role,
                header: () => <>Job Title</>,
                accessorKey: "employeeRole",
                cell: ({ row }) => {
                    const role = row.original.employeeRole;
                    return row.depth === 0 ? role : null;
                },
            },
            {
                id: ColumnId.Wage,
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title={
                            <div>
                                Wage
                                <ReportsTooltip
                                    body={
                                        <>
                                            The wage on each shift is the wage
                                            that was set at the start of that
                                            shift. The wage on the employee's
                                            summary row is the average rate they
                                            received during the pay period.
                                        </>
                                    }
                                />
                            </div>
                        }
                    />
                ),
                accessorKey: "employeeWage",
                cell: ({ row }) => {
                    const blendedWage = toDollar(row.original.blendedWage ?? 0);
                    const wage = toDollar(row.original.wage);
                    return row.depth === 0 ? blendedWage : wage;
                },
            },
            {
                id: ColumnId.Shifts,
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title={"Shifts"} />
                ),
                accessorKey: "totalShifts",
                cell: ({ row }) => {
                    const { clockedInAt, clockedOutAt, totalShifts } =
                        row.original;
                    const shiftStart = moment
                        .tz(clockedInAt, storeTimezone)
                        .format("h:mm a");

                    const shiftEnd = clockedOutAt
                        ? moment
                              .tz(clockedOutAt, storeTimezone)
                              .format("h:mm a")
                        : "ongoing";
                    return row.depth === 0
                        ? totalShifts
                        : `${shiftStart} - ${shiftEnd}`;
                },
            },
            {
                id: ColumnId.Regular,
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title={"Regular"} />
                ),
                accessorKey: "regularHours",
                cell: ({ row }) => {
                    const { regularHours } = row.original;

                    return regularHours;
                },
            },
            {
                id: ColumnId.Overtime,
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title={"Est. Overtime"}
                    />
                ),
                accessorKey: "overtimeHours",
                cell: ({ row }) => {
                    const { overtimeHours } = row.original;
                    return overtimeHours;
                },
            },
            {
                id: ColumnId.DoubleTime,
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title={"Est. Doubletime"}
                    />
                ),
                accessorKey: "doubleOvertimeHours",
                cell: ({ row }) => {
                    const { doubleOvertimeHours } = row.original;
                    return doubleOvertimeHours;
                },
            },
            {
                id: ColumnId.UnpaidBreaks,
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title={"Unpaid Breaks"}
                    />
                ),
                accessorKey: "unpaidBreaks",
                cell: ({ row }) => {
                    const { unpaidBreaks } = row.original;
                    return unpaidBreaks;
                },
            },
            {
                id: ColumnId.TotalHours,
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title={"Total Hours"}
                    />
                ),
                accessorKey: "totalHours",
                cell: ({ row }) => {
                    const { totalHours } = row.original;
                    return totalHours;
                },
            },
            {
                id: ColumnId.EstimatedWages,
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title={"Est. Wages"}
                    />
                ),
                accessorKey: "estWages",
                cell: ({ row }) => {
                    const { estWages } = row.original;
                    return toDollarFormatted(estWages ?? 0);
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            makeExpandedData(employeeShiftDetails).filter((e) =>
                e.employeeName.toLowerCase().includes(nameFilter.toLowerCase()),
            ),
        [employeeShiftDetails, makeExpandedData, nameFilter],
    );

    const getRowClickListener = (row: Row<RowData>): null | (() => void) => {
        if (row.depth === 0) {
            // don't need to add expanding code in this onclick listener, already a part of the component.
            return null;
        }

        // if a subrow (an individual shift), we open up the drawer at that shift.
        return () => {
            setIsCreatingShift(false);
            const matchingEmployeeRow = data.find(
                (e) => e.employeeId === row.original.employeeId,
            );

            const matchingShifts = matchingEmployeeRow?.subRows ?? [];
            setShifts(matchingShifts);
            setSelectedShiftIndex(row.index);
            setShowShiftDetail(true);
        };
    };

    const handleCreateShift = (): void => {
        setShifts([]);
        setSelectedShiftIndex(null);
        setIsCreatingShift(true);
        setShowShiftDetail(true);
    };

    const handleMoveOneShift = ({ advance }: { advance: boolean }): void => {
        if (selectedShiftIndex === null) return;

        if (advance) {
            if (shifts.length - 1 > selectedShiftIndex) {
                setSelectedShiftIndex(selectedShiftIndex + 1);
            }
        } else {
            if (shifts.length > 0 && selectedShiftIndex > 0) {
                setSelectedShiftIndex(selectedShiftIndex - 1);
            }
        }
    };

    const onChangeRangeSelect = (value: DateRange) => {
        const newStartDate = value.from
            ? DateTime.fromJSDate(value.from)
            : reportingStartDate;
        const newEndDate = value.to
            ? DateTime.fromJSDate(value.to)
            : reportingEndDate;
        const duration = Interval.fromDateTimes(newStartDate, newEndDate);
        if (duration.length("days") > 45) {
            toast.error("Date span cannot exceed 45 days.");
        } else {
            setReportingStartDate(newStartDate);
            setReportingEndDate(newEndDate);
        }
    };

    const selectedDate = useMemo(
        () => ({
            from: reportingStartDate.toJSDate(),
            to: reportingEndDate.toJSDate(),
        }),
        [reportingStartDate, reportingEndDate],
    );

    const dateOptions = useMemo(
        () =>
            relatedRanges.map((e) => ({
                label: e.label,
                value: {
                    from: e.value.start.toJSDate(),
                    to: e.value.end.toJSDate(),
                },
            })),
        [relatedRanges],
    );

    return (
        <>
            <SettingsAlert display={!storeSettings?.strategy} />
            {employeesWithOpenShifts.length > 0 && (
                <Card className="my-3">
                    <span className="text-red-500">
                        Employees with ongoing shifts:
                    </span>
                    {employeesWithOpenShifts.map((employeeName, index) => (
                        <div key={index}>{employeeName}</div>
                    ))}
                </Card>
            )}
            <div className="mb-3 mt-6 flex justify-between space-x-2">
                <div className="flex flex-1 items-center space-x-2">
                    <Input
                        placeholder="Search Employees"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setNameFilter(e.target.value);
                        }}
                        className="max-w-[400px]"
                    />
                    <RangePicker
                        onSelect={onChangeRangeSelect}
                        options={dateOptions}
                        selected={selectedDate}
                    />
                </div>
                <div className="flex items-center justify-end space-x-2">
                    <Button
                        variant="outline"
                        onClick={() => history.push(`settings-employees`)}
                        className="space-x-2"
                    >
                        <GearIcon className="h-4 w-4 fill-neutral-950" />
                        <span className="hidden lg:block">Settings</span>
                    </Button>
                    <DownloadTimecardsCSVButton
                        startDate={reportingStartDate}
                        endDate={reportingEndDate}
                        data={data}
                    />
                    <Button onClick={handleCreateShift} className="space-x-2">
                        <PlusIcon className="h-4 w-4 fill-neutral-950" />
                        <span className="hidden lg:block">Time Card</span>
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <Skeleton className="h-96 w-full" />
            ) : (
                <>
                    <DataTable
                        data={data}
                        columns={columns}
                        getRowClickListener={getRowClickListener}
                        customPageSize={
                            data.length +
                            data.reduce(
                                (acc, curr) =>
                                    acc + (curr.subRows?.length ?? 0),
                                0,
                            )
                        }
                    />
                </>
            )}
            <Drawer
                width={width < MOBILE_BREAKPOINT ? width : width / 2}
                placement="right"
                onClose={() => setShowShiftDetail(false)}
                open={
                    showShiftDetail &&
                    (isCreatingShift || !_.isNull(selectedShiftIndex))
                }
                closable={false}
                destroyOnClose={true}
                forceRender={true}
            >
                <TimeCardViewEdit
                    isCreating={isCreatingShift}
                    onHide={() => setShowShiftDetail(false)}
                    timecard={shifts[selectedShiftIndex ?? 0]}
                    currentShiftIndex={selectedShiftIndex}
                    setSelectShiftIndex={setSelectedShiftIndex}
                    shifts={shifts}
                    onMoveOneShift={handleMoveOneShift}
                    onShiftChange={() =>
                        setWatchValueForRefresh((current) => current + 1)
                    }
                    selectedPayPeriodId={payPeriodId}
                />
            </Drawer>
        </>
    );
};

export default TimeCardTableWithOptionalSummary;
