import React, { useContext, useMemo, useState } from "react";
import { Drawer } from "antd";
import { debounce } from "radash";
import { toast } from "sonner";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import EditDrawerHeader from "#devices/components/EditDrawer/EditDrawerHeader";
import EditDrawerFooter from "#devices/components/EditDrawer/EditDrawerFooter";
import UnsavedChangesModal from "#devices/components/EditDrawer/UnsavedChangesModal";
import api from "src/api/rest";
import {
    BaseStoreDevice,
    DeviceType,
    StoreDevice,
} from "#devices/utils/deviceTypes";
import { determineDrawerWidth } from "#devices/utils/drawerWidth";
import DeleteDeviceModal from "#devices/components/EditDrawer/DeleteDeviceModal";
import SaveAndRebootDeviceModal from "#devices/components/EditDrawer/SaveAndRebootDeviceModal";
import { notifications } from "#devices/components/Notification";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { PrinterDeviceDetails } from "#devices/utils/deviceTypes/PrinterDevice";

import DeviceEditView from "./DeviceEdit";

const EditDrawer = (): JSX.Element | null => {
    const {
        storeDevices,
        device,
        deviceHasChanges,
        updatedDevice,
        isEditDrawerVisible,
        openDeleteModal,
        savingDevice,
        deletingDevice,
        setIsDetailsDrawerVisible,
        setIsEditDrawerVisible,
        handleSetDevice,
        setDeviceToView,
        setDeviceHasChanges,
        setUpdatedDevice,
        setSavingDevice,
        setDeletingDevice,
        setStoreDevices,
        resetFilters,
        setOpenDeleteModal,
    } = useContext(DevicesPageContext);

    const [openUnsavedChanges, setOpenUnsavedChanges] =
        useState<boolean>(false);

    const [openConfirmSaveChanges, setOpenConfirmSaveChanges] =
        useState<boolean>(false);

    const resetToDetails = () => {
        setIsDetailsDrawerVisible(true);
        setDeviceHasChanges(false);
        setOpenUnsavedChanges(false);
        setOpenConfirmSaveChanges(false);
        setOpenDeleteModal(false);
        setIsEditDrawerVisible(false);
        setUpdatedDevice({});
        setSavingDevice(false);
    };

    const { width } = useWindowDimensions();

    const updateDeviceRequest = (device: StoreDevice) => {
        if (!device || savingDevice) return;
        setSavingDevice(true);

        const preparedDevice = {
            // set the device type of the old device, before overwriting
            // with any updates to that device type (which may include device type)
            // device type is required by the server to route the store device update.
            deviceType: device.deviceType,
            ...updatedDevice,
            deviceDetails:
                device.deviceType === DeviceType.KDS
                    ? null
                    : {
                          ...updatedDevice.deviceDetails,
                      },
        };

        api.storeDevices
            .updateStoreDevice(device.id, preparedDevice)
            .then((response) => {
                setOpenConfirmSaveChanges(false);
                const updatedStoreDevice = {
                    ...response.data.device,
                    events: [...device.events], // add back original events
                    stats: { ...device.stats }, // add back original stats
                } as unknown as StoreDevice;

                if (device.deviceType === DeviceType.Printer) {
                    const details =
                        device.deviceDetails as PrinterDeviceDetails;
                    const updatedDetails =
                        updatedStoreDevice.deviceDetails as PrinterDeviceDetails;

                    updatedDetails.recentPrints = details.recentPrints;
                    updatedStoreDevice.deviceDetails = updatedDetails;
                }

                const existingStoreDevices = storeDevices ?? [];
                const updatedStoreDevices = existingStoreDevices.map((d) =>
                    d.id === device.id ? { ...d, ...response.data.device } : d,
                );

                toast.success("Device successfully updated.");

                setDeviceHasChanges(false);
                setUpdatedDevice({});
                handleSetDevice(updatedStoreDevice);
                setStoreDevices(updatedStoreDevices);
                setTimeout(() => resetToDetails(), 1000);
            })
            .catch((error) => {
                toast.error(
                    `Unable to update device at this time.\n\n${error.message}`,
                );
                setOpenConfirmSaveChanges(false);
            })
            .finally(() => setSavingDevice(false));
    };

    const deleteDeviceRequest = (deviceToDelete: StoreDevice) => {
        if (!deviceToDelete || deletingDevice) return;

        setDeletingDevice(true);
        api.storeDevices
            .deleteStoreDevice(deviceToDelete.id)
            .then(() => {
                const updatedDevices = storeDevices
                    ? storeDevices.filter(
                          (d: BaseStoreDevice) => d?.id !== deviceToDelete?.id,
                      )
                    : [];

                resetFilters();
                setUpdatedDevice({});
                setStoreDevices([...updatedDevices]);
                setDeviceHasChanges(false);
                handleSetDevice(undefined);
                setDeviceToView(undefined);
                setIsEditDrawerVisible(false);
                setIsDetailsDrawerVisible(false);
                notifications.success("Device successfully removed.");
            })
            .catch((error) => {
                notifications.error(
                    `Unable to delete device at this time.\n\n${error.message}`,
                );
            })
            .finally(() => {
                setOpenDeleteModal(false);
                setDeletingDevice(false);
            });
    };

    const debouncedUpdateDevice = useMemo(
        () => debounce({ delay: 300 }, updateDeviceRequest),
        [device, updatedDevice],
    );

    const debouncedDeleteDevice = useMemo(
        () => debounce({ delay: 300 }, deleteDeviceRequest),
        [device],
    );

    const handleSaveClickFromEdit = () => {
        setOpenConfirmSaveChanges(true);
    };

    const handleDrawerClose = () =>
        deviceHasChanges ? setOpenUnsavedChanges(true) : resetToDetails();

    const handleRebootClose = () => setOpenConfirmSaveChanges(false);

    const handleSave = () => {
        if (device?.id) {
            debouncedUpdateDevice(device);
        }
    };

    const handleDelete = () => {
        if (device?.id) {
            debouncedDeleteDevice(device);
        }
    };

    if (!device) return null;

    return (
        <Drawer
            width={determineDrawerWidth(width)}
            placement="right"
            onClose={handleDrawerClose}
            open={isEditDrawerVisible}
            closable={false} // hides the default close button
            bodyStyle={{
                fontFamily: "Inter", // overwrite font family for everything in drawer
            }}
            footer={
                <EditDrawerFooter
                    onCancel={handleDrawerClose}
                    onSave={handleSaveClickFromEdit}
                />
            }
        >
            <EditDrawerHeader onClose={handleDrawerClose} />
            {isEditDrawerVisible && <DeviceEditView />}
            <SaveAndRebootDeviceModal
                isOpen={openConfirmSaveChanges}
                handleClose={handleRebootClose}
                handleSave={handleSave}
            />
            <UnsavedChangesModal
                isOpen={openUnsavedChanges}
                handleClose={resetToDetails}
                handleSave={setOpenUnsavedChanges}
            />
            <DeleteDeviceModal
                isOpen={openDeleteModal}
                handleClose={setOpenDeleteModal}
                handleDelete={handleDelete}
            />
        </Drawer>
    );
};

export default EditDrawer;
