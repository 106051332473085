import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class MenuHasOneUpsellCheck implements IMenuAutomationCheck<IStore> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "MenuHasUpsellsCheck";
    }

    get itemToCheck(): ItemType {
        return "store";
    }

    checkItems(): MenuIssue<IStore>[] {
        return [];
    }

    checkItem(): MenuIssue<IStore> | null {
        if (this.store.products) {
            let counter = 0;
            this.store.products.forEach((product) => {
                if (product.upsell) {
                    counter++;
                }
            });
            return counter > 0
                ? null
                : new MenuIssue<IStore>(
                      "NoUpsells",
                      "All menus should have at least one upsold product.",
                      "warning",
                      this.store,
                  );
        } else {
            return null;
        }
    }
}

export { MenuHasOneUpsellCheck };
