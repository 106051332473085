import { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowRight, Search } from "lucide-react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Input } from "src/@/components/ui/input";
import { quickShortcuts, iconComponents } from "./quick-shortcuts-data";

export function QuickShortcutsCard() {
    const [searchQuery, setSearchQuery] = useState("");

    const filteredShortcuts = quickShortcuts.filter((shortcut) =>
        shortcut.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    return (
        <Card className="h-full">
            <CardHeader className="pr-8">
                <CardTitle className="text-sm font-medium text-muted-foreground">
                    Quick Actions
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="relative mb-2 pr-2">
                    <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                    <Input
                        type="search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-10 pr-4 bg-muted/50"
                    />
                </div>
                <div className="border-b-gray-200 border-b-2 h-full max-h-[300px] overflow-y-auto pr-2 space-y-2 scrollbar-thin scrollbar-thumb-muted-foreground/20 scrollbar-track-transparent pb-4">
                    {filteredShortcuts.map((shortcut) => {
                        const isExternalLink = shortcut.link.startsWith("http");
                        const LinkComponent = isExternalLink ? "a" : Link;
                        const linkProps = isExternalLink
                            ? {
                                  href: shortcut.link,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                              }
                            : { to: shortcut.link };

                        const IconComponent = iconComponents[shortcut.icon];

                        return (
                            <LinkComponent
                                key={shortcut.id}
                                {...(linkProps as any)}
                                className="flex w-full items-center justify-between rounded-lg border bg-card p-3 text-sm text-card-foreground shadow-sm hover:bg-accent hover:text-accent-foreground no-underline transition-colors"
                            >
                                <div className="flex items-center gap-2">
                                    <IconComponent className="h-4 w-4" />
                                    <span>{shortcut.title}</span>
                                </div>
                                <ArrowRight className="h-4 w-4" />
                            </LinkComponent>
                        );
                    })}
                </div>
            </CardContent>
        </Card>
    );
}
