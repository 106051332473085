import { toDollar } from "@snackpass/accounting";
import moment from "moment";

import { RowData } from "#employees/employee-time-cards/hooks/use-prepare-expandable-rows";

const DATE_FORMAT = "MM/DD/YYYY";
const TIME_FORMAT = "h:mm a";

type ReportRow = {
    Name: string;
    Identifier: string;
    "Shift Date": string;
    "Shift Hours": string;
    "Regular Hours": number;
    "Overtime Hours": number;
    "Double Overtime Hours": number;
    "Total Hours": number;
    "Estimated Wages": string;
};

// We currently combine both summary data and individual shift data in the same structure for display on web
// for the download we want to only show the individual shift data which is contained in the "subrow" of each RowData entry
export const formatRows = (rowData: RowData[]): ReportRow[] => {
    const results: ReportRow[] = [];
    for (const r of rowData) {
        if (r.subRows) {
            for (const sr of r.subRows) {
                const { clockedInAt, clockedOutAt } = sr;
                const shiftStartDate = moment(sr.clockedInAt).format(
                    DATE_FORMAT,
                );
                const shiftEndDate = moment(sr.clockedOutAt).format(
                    DATE_FORMAT,
                );
                let formattedDate = shiftStartDate;
                if (sr.clockedOutAt && shiftStartDate !== shiftEndDate) {
                    formattedDate = `${shiftStartDate} - ${shiftEndDate}`;
                }

                const shiftStartTime = moment(clockedInAt).format(TIME_FORMAT);
                const shiftEndTime = clockedOutAt
                    ? moment(clockedOutAt).format(TIME_FORMAT)
                    : "ongoing";

                const aSubRow = {
                    Name: sr.employeeName,
                    Identifier: sr.identifier ?? "",
                    "Shift Date": formattedDate,
                    "Shift Hours": `${shiftStartTime} - ${shiftEndTime}`,
                    "Regular Hours": sr.regularHours,
                    "Overtime Hours": sr.overtimeHours,
                    "Double Overtime Hours": sr.doubleOvertimeHours,
                    "Total Hours": sr.totalHours,
                    "Estimated Wages": sr.estWages ? toDollar(sr.estWages) : "",
                };
                results.push(aSubRow);
            }
        }
    }
    return results;
};
