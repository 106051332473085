import {
    IDateTimeRangeSchema,
    ITimeRangeSchema,
} from "@snackpass/snackpass-types";
import { DateObjectUnits, DateTime } from "luxon";
import _ from "lodash";

import {
    RegularHours,
    SpecialHours,
} from "#settings/settings-business-info/types";
import { DAY_OPTIONS } from "#reusable/special-hours/helper";

const END_OF_WEEK_MINUTES = 24 * 60 * 7;

/**
 * Converts date to the system's timezone date from a specified timezone
 * @param date
 * @param zone
 * @param fromZone optional timezone of current date
 */
export const convertToLocalTzFrom = (
    date: Date,
    zone: string,
    fromZone = "utc",
) =>
    DateTime.fromJSDate(date, { zone: fromZone })
        .setZone(zone, { keepLocalTime: true })
        .toJSDate();

/**
 * Converts date from a different time zone to UTC while keeping the same information
 * @param date
 * @param fromZone
 */
export const convertToUTCFrom = (date: Date, fromZone: string) =>
    DateTime.fromJSDate(date, { zone: fromZone })
        .setZone("utc", { keepLocalTime: true })
        .toJSDate();

/**
 * Extracts minute offsets of the start of the day with respect to the week.
 * Set the `granularity` to configure what units to use for `from`
 * @param from
 * @param granularity
 * @param zone
 */
const getStartOfDayMinutes = (
    from: number,
    granularity: keyof DateObjectUnits,
    zone?: string,
) => {
    let dt = DateTime.local();

    if (zone) {
        // `keepLocalTime` avoids shifting time to incorrect week
        dt = dt.setZone(zone, { keepLocalTime: true });
    }

    const startDay = dt
        .startOf("week")
        .set({ [granularity]: from })
        .startOf("day");

    return {
        day: startDay.weekday.valueOf(),
        minutes: startDay.diff(dt.startOf("week")).as("minutes"),
    };
};

const getEndOfDayMinutes = (
    from: number,
    granularity: keyof DateObjectUnits,
    zone?: string,
) => {
    let dt = DateTime.local();

    if (zone) {
        // `keepLocalTime` avoids shifting time to incorrect week
        dt = dt.setZone(zone, { keepLocalTime: true });
    }

    const endDay = dt
        .startOf("week")
        .set({ [granularity]: from })
        .endOf("day");

    return {
        day: endDay.weekday.valueOf(),
        minutes: Math.floor(endDay.diff(dt.startOf("week")).as("minutes")),
    };
};

/**
 * Converts ITimeRangeSchema into RegularHours
 * @param hours
 * @param zone
 */
export const timeRangeToRegularHours = (
    hours: ITimeRangeSchema[],
    zone?: string,
): RegularHours[] => {
    // Sort hours by start time
    const sortedHours = hours.sort(
        (a, b) => a.start - b.start || a.end - b.end,
    );

    // Merge time-blocks where the end time is 1 minute after the start time
    const derivedHours: ITimeRangeSchema[] = [];

    // Handle Sunday to Monday case
    if (sortedHours.length > 0) {
        const firstHour = sortedHours[0];
        const lastHour = sortedHours[sortedHours.length - 1];

        if (firstHour.start === 0 && lastHour.end === END_OF_WEEK_MINUTES - 1) {
            derivedHours.push({
                start: lastHour.start,
                end: firstHour.end + END_OF_WEEK_MINUTES,
            });

            // Remove first and last hour
            sortedHours.shift();
            sortedHours.pop();
        }
    }

    const mergedHours = sortedHours.reduce((acc, hour) => {
        const lastHour = acc[acc.length - 1];

        // If the last hour ends 1 minute before the current hour starts, merge them
        // into a single block. Don't merge if the second block is longer than 12 hours
        // (prevents blocks that span multiple days from being merged)
        if (lastHour && lastHour.end === hour.start - 1) {
            lastHour.end = hour.end;
        } else {
            acc.push({ start: hour.start, end: hour.end });
        }

        return acc;
    }, [] as ITimeRangeSchema[]);

    derivedHours.push(...mergedHours);

    // Convert to RegularHours
    return derivedHours.flatMap((hour) => {
        const { day, minutes: startDayMinutes } = getStartOfDayMinutes(
            hour.start,
            "minute",
            zone,
        );

        let end = hour.end - startDayMinutes;

        // Check if end time is on the next day
        if (end >= 24 * 60) {
            const remainingTime = end - 24 * 60;
            const nextDay = day === 7 ? 1 : day + 1;
            end = 24 * 60 - 1; // Limit end to 23:59

            return [
                {
                    weekday: day,
                    range: {
                        start: hour.start - startDayMinutes,
                        end,
                    },
                },
                {
                    weekday: nextDay,
                    range: {
                        start: 0,
                        end: remainingTime,
                    },
                },
            ];
        }

        return {
            weekday: day,
            range: {
                start: hour.start - startDayMinutes,
                end,
            },
        };
    });
};

export const regularHoursToTimeRange = (
    hours: RegularHours[],
    zone?: string,
): ITimeRangeSchema[] => {
    const timeRange: ITimeRangeSchema[] = [];

    for (const hour of hours) {
        const { minutes: startWeekdayStartOfDayMinutes } = getStartOfDayMinutes(
            hour.weekday,
            "weekday",
            zone,
        );

        let endWeekdayStartOfDayMinutes = startWeekdayStartOfDayMinutes;

        // If the end time is before the start time, the end time is the next day
        // Split the range into two blocks
        if (hour.range.start > hour.range.end) {
            const { minutes: startWeekdayEndOfDayMinutes } = getEndOfDayMinutes(
                hour.weekday,
                "weekday",
                zone,
            );

            // If Sunday, set to Monday
            if (hour.weekday === 7) {
                endWeekdayStartOfDayMinutes = getStartOfDayMinutes(
                    1,
                    "weekday",
                    zone,
                ).minutes;
            } else {
                // Get start of next day
                endWeekdayStartOfDayMinutes += 24 * 60;
            }

            // First block is from start time to end of day
            timeRange.push({
                start: startWeekdayStartOfDayMinutes + hour.range.start,
                end: startWeekdayEndOfDayMinutes,
            });
            // Seond block is from start of day to end time
            timeRange.push({
                start: endWeekdayStartOfDayMinutes,
                end: endWeekdayStartOfDayMinutes + hour.range.end,
            });
        } else {
            timeRange.push({
                start: startWeekdayStartOfDayMinutes + hour.range.start,
                end: endWeekdayStartOfDayMinutes + hour.range.end,
            });
        }
    }

    return timeRange;
};

/**
 * Returns true if "Everyday" is selected
 * @param hours
 */
export const regularHoursHasEveryday = (hours: RegularHours[]) =>
    // value + 1 required to convert to ISO week format
    hours.some((hour) => hour.weekday === DAY_OPTIONS[7].value + 1);

/**
 * Converts IDateTimeRangeSchema into SpecialHours
 * @param hours
 * @param localZone
 */
export const dateTimeRangeToSpecialHours = (
    hours: IDateTimeRangeSchema[],
    localZone: string = DateTime.local().zone.name,
): SpecialHours[] =>
    // hour dates are stored in UTC, but Calendar requires local timezone using JSDate
    // we will set each date to UTC first, then switch timezones without shifting time
    hours.map((hour) => ({
        date: convertToLocalTzFrom(new Date(hour.date), localZone),
        isClosed: hour.start === hour.end,
        range: {
            start: hour.start === hour.end ? 0 : hour.start,
            end: hour.start === hour.end ? 24 * 60 - 1 : hour.end,
        },
    }));

/**
 * Converts RegularHours to ITimeRangeSchema
 * @param hours
 * @param zone
 */
export const formatRegularHoursForRequest = (
    hours: RegularHours[],
    zone = "utc",
): ITimeRangeSchema[] => {
    let regularHours = hours;

    // parse hours and reformat "Everyday" hours
    if (regularHoursHasEveryday(regularHours)) {
        const everyDayHours = regularHours[0].range;

        // format each weekday using everyday start and end range
        regularHours = _.range(1, 8).map((index) => ({
            weekday: index,
            range: everyDayHours,
        }));
    }
    return regularHoursToTimeRange(regularHours, zone);
};

/**
 * Converts SpecialHours to IDateTimeRangeSchema
 * @param hours
 * @param fromZone
 */
export const formatSpecialHoursForRequest = (
    hours: SpecialHours[],
    fromZone: string,
): IDateTimeRangeSchema[] =>
    hours.map((hour) => {
        const range: ITimeRangeSchema = hour.range;

        if (hour.isClosed) {
            range.start = 0;
            range.end = 0;
        }

        // make date tz-agnostic without shifting time
        const utcDate = convertToUTCFrom(hour.date, fromZone);

        return {
            date: utcDate,
            start: range.start,
            end: range.end,
        };
    });

/**
 * Extracts the minute offset with respect to the start of the day from
 * a Luxon DateTime object
 * @param dateObject
 */
export const getDayMinutesFromLuxon = (dateObject: DateTime) =>
    dateObject.diff(dateObject.startOf("day")).as("minutes");

/**
 * Converts SpecialHours into RegularHours grouped by week
 * @param specialHours
 */
export const specialHoursToRegularHoursWeek = (
    specialHours: SpecialHours[],
): RegularHours[][] => {
    const specialHoursByWeek = _.groupBy(specialHours, (hour) =>
        DateTime.fromJSDate(hour.date)
            .set({ minute: hour.range.start })
            .toFormat("yyyy-W"),
    );

    return _.map(specialHoursByWeek, (hours) =>
        hours.map((hour) => {
            const weekday = DateTime.fromJSDate(hour.date).weekday;

            return {
                weekday,
                range: hour.range,
            };
        }),
    );
};

/**
 * Converts time string hh:mm a to a Luxon DateTime object for current day w/ time.
 * We only care about the time, so we set the date to the current day.
 * @param newTime
 * @returns luxon DateTime object
 */
export const timeToLuxon = (newTime: string) => {
    const [hours, minutes] = newTime.split(":");
    const newMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return DateTime.now().startOf("day").plus({ minutes: newMinutes });
};
