import { Canvas } from "fabric";

import { Element } from "#table-editor/canvas/Element";

export function clearCanvas(canvas: Canvas) {
    // Only remove Elements
    const elements = canvas.getObjects(Element.type);

    canvas.remove(...elements);
}
