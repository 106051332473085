// Color palette

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const colors = {
    primary: "#202122", // primary/sesame
    watermelon: {
        green: "#00CC44",
        pink: "#FF66AD",
    },
    sesame: "#202122",
    lightGreen: "#E1FDFF",
    blue: "#2fc3ff",
    pastelPurple: "#b997ff",
    apricot: "#ffbe5f",
    warmGrey: "#929292",
    white: "#ffffff",
    blue2: "#5aaff7",
    offWhite: "#f3f4f7",
    paleSalmon: "#ffb1b0",
    black: "#000",
    gray: "#929292",
    lightGray: "#F9FAFB",
    text: "#4e4e4e",
    salmon: "#ff7676",
    purple: "#bc76ff",
    darkishPink: "#e13e89",
    pink: "#f75aa2",
    brownishGrey: "#646464",
    green: "#5FC990",
    red: "#F05454",
    orange: "#FF6969",
    green2: "#36D680",
    gray30: "#626B74",
    gray60: "#C4C8CC",
    gray80: "#e1e3e6",
    gray100: "#F9FAFB",
    parsley50: "#00CC44",
    melon50: "#F20D33",
    melon60: "#FA3A5A",
    medGray: "#E5E5E5",
    medLightGray: "#F1F3F6",
    macaroni40: "#FFBB00",
    candy50: "#0088FF",
};
