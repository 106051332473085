import React from "react";
import { Drawer as AntdDrawer } from "antd";
import { connectModal, InjectedProps } from "redux-modal";
import { useMediaQuery } from "react-responsive";

import useWindowDimensions from "#hooks/use-window-dimensions";

import { CloseButton } from "../styled-components";

import { Templates } from "./templates";

type DrawerProps = {} & InjectedProps;

const TemplatesDrawerComponent = ({ handleHide, show }: DrawerProps) => {
    const isMobile = useMediaQuery({
        query: `(max-width: 990px)`,
    });

    const { width } = useWindowDimensions();

    return (
        <AntdDrawer
            width={isMobile ? width : 400}
            destroyOnClose={false}
            placement="right"
            onClose={handleHide}
            visible={show}
            closable={false}
            forceRender={true}
        >
            <CloseButton onClick={handleHide} />
            <Templates onSelect={handleHide} />
        </AntdDrawer>
    );
};

export const TemplatesDrawer = connectModal({
    name: "TemplatesDrawer",
    destroyOnHide: false,
})(TemplatesDrawerComponent);
