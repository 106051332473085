/** @jsxImportSource @emotion/react */
import { Modal } from "react-bootstrap";
import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import {
    Button,
    RoundButton,
} from "#menu-editor/multi-menus/styled-components/button";
import { ReactComponent as XIcon } from "src/assets/icons/close-x.svg";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    setApplyToOverrides: Dispatch<SetStateAction<boolean>>;
    applyToOverrides: boolean;
    onClick: () => Promise<void>;
    adjustment: string;
    amount: number;
    isLoading: boolean;
};

export const PriceAdjustmentModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    setApplyToOverrides,
    applyToOverrides,
    onClick,
    adjustment,
    amount,
    isLoading,
}) => {
    const onChange = (e: CheckboxChangeEvent) => {
        setApplyToOverrides(e.target.checked);
    };
    const roundedAmount = Math.round(amount * 100) / 100;

    return (
        <Modal
            show={isModalOpen}
            css={warningModalStyles}
            dialogClassName="modal-width"
            centered
        >
            <Modal.Header>
                <RoundButton
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                >
                    <XIcon />
                </RoundButton>
                <Modal.Title>
                    <p className="header-title">Update menu prices</p>
                </Modal.Title>
                <div className="header-subtitle-container">
                    <p className="header-subtitle">
                        Items will now be {adjustment} by {roundedAmount}%
                    </p>
                </div>
                <Checkbox checked={applyToOverrides} onChange={onChange}>
                    Include items with individual price overrides
                </Checkbox>
            </Modal.Header>
            <Modal.Footer>
                <div className="buttons">
                    <Button
                        type="tertiary"
                        size="regular"
                        onClick={() => setIsModalOpen(false)}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="regular"
                        onClick={onClick}
                        disabled={isLoading}
                    >
                        Update
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const warningModalStyles = css`
    font-family: "Inter";
    font-style: normal;

    .modal-content {
        border-radius: 16px;
    }
    .modal-header {
        display: flex;
        flex-direction: column;
        align-items: inherit;
        padding: 24px;
    }
    .header-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #282d32;
        margin-top: 24px;
        margin-bottom: 8px;
    }
    .header-subtitle {
        color: #606c76;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
    .buttons {
        gap: 16px;
        height: auto;
        padding: 4px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
    }

    .buttons > div {
        flex: 1;
    }
`;
