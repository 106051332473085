import React, { ReactNode } from "react";

// All the props that are passed to the view component.
interface IView {
    row?: boolean;
    backgroundColor?: string;
    style?: React.CSSProperties;
    className?: string;
    onPress?: (event: React.MouseEvent<HTMLDivElement>) => void;
    children?: ReactNode;
}

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const View = ({
    row,
    backgroundColor,
    className,
    onPress,
    children,
    style,
}: IView) => (
    <div
        className={className || ""}
        onClick={onPress}
        style={{
            display: "flex",
            backgroundColor: backgroundColor || "",
            alignSelf: "stretch",
            flexDirection: row ? "row" : "column",
            ...style,
        }}
    >
        {children}
    </div>
);
