import React from "react";
import styled from "styled-components";

import Text from "#devices/components/Text";

type EditSectionProps = { heading: string; children: React.ReactNode };

const EditSectionWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.double};
`;

const EditSection = (props: EditSectionProps): JSX.Element => (
    <EditSectionWrapper>
        <Text.Title2>{props.heading}</Text.Title2>
        {props.children}
    </EditSectionWrapper>
);

export default EditSection;
