import { captureException } from "@sentry/react";
import { IUser } from "@snackpass/snackpass-types";

import api from "src/api/rest";
import { notifyFailure, notifySuccess } from "#onboarding/utils/notify";

export const buildPatchUser =
    (setLoading: (l: boolean) => void) =>
    async (id: string, body: Partial<IUser>, cb?: (u: IUser) => void) => {
        setLoading(true);
        return api.admins
            .update(id, body)
            .then(({ data }) => {
                setLoading(false);
                if (data?.success) {
                    notifySuccess({
                        description: "User information saved successfully",
                    });
                    cb ? cb(data.user) : null;
                } else {
                    notifyFailure({
                        description: "Unable to save user information",
                    });
                    captureException(Error(JSON.stringify(data)));
                }
            })
            .catch(() => {
                setLoading(false);
                notifyFailure({
                    description: "Unable to save user information",
                });
            });
    };
