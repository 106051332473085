import moment from "moment";

export const getFormattedTime = (timestamp: Date | number) => {
    const prevYear =
        new Date(timestamp).getFullYear() !== new Date().getFullYear();
    const formattedTime = prevYear
        ? moment(timestamp).format("ddd, MMMM D, YYYY, hh:mm A")
        : moment(timestamp).format("ddd, MMMM D, hh:mm A");
    return formattedTime;
};

export const NUMBER_MESSAGES_PER_LOAD = 20;
