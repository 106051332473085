import { IStore } from "@snackpass/snackpass-types";
import _ from "lodash";
import { useFormContext, useFormState, Path, PathValue } from "react-hook-form";
import { z } from "zod";

type MongoVersion = {
    readonly __tag: unique symbol;
};

export type MongoVersioned<T extends object> = T & {
    __v: MongoVersion;
};

const INTERNAL_SETTINGS_READ_FIELDS = [
    "__v",
    "name",
    "serverAutoAcceptsOrders",
    "integrations",
] as const satisfies Array<keyof MongoVersioned<IStore>>;

type ElementOf<T> = T extends (infer E)[] ? E : never;

export type InternalSettingsFormValues = MongoVersioned<
    Pick<
        MongoVersioned<IStore>,
        ElementOf<typeof INTERNAL_SETTINGS_READ_FIELDS>
    >
>;

export type InternalSettingsFormKeysOfType<T> = Exclude<
    {
        [K in Path<InternalSettingsFormValues>]: Exclude<
            PathValue<InternalSettingsFormValues, K>,
            null | undefined
        > extends T
            ? K
            : never;
    }[Path<InternalSettingsFormValues>],
    null | undefined
>;

export const INTERNAL_SETTINGS_SUBMIT_FIELDS = [
    "__v",
    "serverAutoAcceptsOrders",
    "integrations",
] as const satisfies Array<keyof InternalSettingsFormValues>;

export function useInternalSettingsForm() {
    return useFormContext<InternalSettingsFormValues>();
}

export function useInternalSettingsFormState() {
    return useFormState<InternalSettingsFormValues>();
}

export function internalSettingsFormDefaults(
    store: IStore,
): InternalSettingsFormValues {
    return _.pick(
        store as MongoVersioned<IStore>,
        INTERNAL_SETTINGS_READ_FIELDS,
    );
}

export const InternalSettingsFormSchema = z
    .object({
        serverAutoAcceptsOrders: z.boolean(),
        integrations: z.object({
            checkmate: z
                .object({
                    enabled: z.boolean(),
                    locationId: z.string().nullish(),
                })
                .refine(
                    (value) => !value.enabled || !!value.locationId?.length,
                    {
                        message: "API key is required",
                        path: ["locationId"],
                    },
                ),
            chowly: z
                .object({
                    enabled: z.boolean(),
                    apiKey: z.string().nullish(),
                })
                .refine((value) => !value.enabled || !!value.apiKey?.length, {
                    message: "API key is required",
                    path: ["apiKey"],
                }),
        }),
    })
    .refine(
        ({ serverAutoAcceptsOrders, integrations }) =>
            !integrations.checkmate.enabled || serverAutoAcceptsOrders,
        {
            message:
                "Server auto accepts orders must be enabled to use Checkmate",
            path: ["integrations", "checkmate", "enabled"],
        },
    )
    .refine(
        ({ serverAutoAcceptsOrders, integrations }) =>
            !integrations.chowly.enabled || serverAutoAcceptsOrders,
        {
            message: "Server auto accepts orders must be enabled to use Chowly",
            path: ["integrations", "chowly", "enabled"],
        },
    );
