import { useCallback, useMemo } from "react";
import { PurchaseReportTransactionSource } from "@snackpass/snackpass-types";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { useDashboardContext } from "../../hooks/use-dashboard-context";
import { DEFINED_SOURCES } from "#pickers/transaction-source-picker/lib";

export const ChannelPicker = () => {
    const { transactionSources, setSources } = useDashboardContext();

    const selectedSources = useMemo(
        () => new Set(transactionSources as string[]),
        [transactionSources],
    );

    const handleOptionSelected = useCallback(
        (value: string) => {
            const newSelection = new Set(selectedSources);
            if (newSelection.has(value)) {
                newSelection.delete(value);
            } else {
                newSelection.add(value);
            }
            setSources(
                Array.from(newSelection) as PurchaseReportTransactionSource[],
            );
        },
        [selectedSources, setSources],
    );

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">Channel</span>
                </Button>
            }
            title="Channel"
            selectedValues={selectedSources}
            options={DEFINED_SOURCES}
            onOptionSelected={handleOptionSelected}
        />
    );
};
