import React, { useContext, useState } from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { startCase, truncate } from "lodash";

import CloseButton from "#payouts/components/shared/CloseButton";
import { StyledModal, Header } from "#payouts/components/shared/Modal";
import { VerificationsResponse } from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import { handleException } from "#payouts/utils/handleException";
import { UpdatedButton } from "#payouts/components/shared/UpdatedButton";
import api from "src/api/rest";
import { SetPrimarySchema } from "#payouts/utils/validation/schema";
import {
    confirmRemoveValidation,
    confirmSetPrimaryValidation,
} from "#payouts/utils/validation/form";
import { Label } from "#payouts/components/shared/ModalComponents";
import useWindowDimensions from "#hooks/use-window-dimensions";

export const ConfirmNewPrimary = () => {
    const [form] = Form.useForm();

    const { isDesktop } = useWindowDimensions();

    const {
        verification,
        store,
        storeId,
        makePrimaryModal,
        newPrimary,
        setVerification,
        handleModalChange,
    } = useContext(PayoutsSettingsContext);

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        setConfirm(false);
        handleModalChange();
    };

    const storeName = store?.name ?? "this store";
    const first = newPrimary?.firstName ?? "";
    const last = newPrimary?.lastName ?? "";
    const userName = startCase([first, last].join(" ").toLowerCase());

    const previous = verification?.representatives?.find(
        (r) => r.representative,
    );
    const previousFirst = previous?.firstName ?? "";
    const previousLast = previous?.lastName ?? "";
    const previousUserName = startCase(
        [previousFirst, previousLast].join(" ").toLowerCase(),
    );
    const submitText = truncate(startCase(first), {
        length: 10,
        omission: "...",
    });

    const label = "Confirm New Primary";
    const subtitle = `${previousUserName} will no longer be Primary, but will remain a contact at your store.`;
    const action = `Set ${userName} as Primary for ${storeName}.`;
    const submit = isDesktop ? `Make ${submitText} Primary` : "Make Primary";

    const error = makePrimaryModal
        ? !SetPrimarySchema.safeParse({
              personId: newPrimary?.id,
              confirm,
          }).success
        : false;

    const disabled = !storeId || loading;
    const isValid = hasChange && !error;

    const submitUpdate = async (): VerificationsResponse => {
        if (disabled || !isValid || !newPrimary?.id) {
            return Promise.reject();
        }
        setLoading(true);
        return api.verifications.setNewPrimary(storeId, newPrimary.id);
    };

    if (!makePrimaryModal) return <></>;

    return (
        <StyledModal
            open={makePrimaryModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center="Set New Primary"
                />
            }
            footer={
                <>
                    <UpdatedButton
                        block
                        smallRadius
                        variant="tertiary"
                        size="regular"
                        children={<>Cancel</>}
                        disabled={disabled}
                        isValid={true} // cancel is always valid
                        onClick={() => handleModalChange()}
                    />
                    <UpdatedButton
                        block
                        smallRadius
                        variant="primary"
                        size="regular"
                        children={<>{submit}</>}
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        onClick={async () => {
                            if (disabled) {
                                return;
                            }
                            if (!isValid) {
                                form.setFields([
                                    {
                                        name: "confirm",
                                        errors: confirmSetPrimaryValidation(
                                            confirm,
                                        ),
                                    },
                                ]);
                                return;
                            }

                            submitUpdate()
                                .then(({ data }) => {
                                    if (data?.success) {
                                        setVerification(data.verification);
                                        setHasChange(false);
                                        setLoading(false);
                                        handleModalChange({});
                                    } else {
                                        notifyFailure(messages.modal.account);
                                        handleException(data);
                                        setLoading(false);
                                    }
                                })
                                .catch((error) => {
                                    notifyFailure(messages.modal.account);
                                    handleException(error);
                                    setLoading(false);
                                });
                        }}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    confirm,
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="confirm"
                            label={
                                <Label
                                    required
                                    label={label}
                                    subtitle={subtitle}
                                />
                            }
                        >
                            <Checkbox
                                checked={confirm}
                                children={action}
                                onChange={(e) => {
                                    const value = e.target.checked;
                                    const errors =
                                        confirmRemoveValidation(value);
                                    setHasChange(true);
                                    setConfirm(value);
                                    form.setFields([
                                        {
                                            name: "confirm",
                                            value,
                                            errors,
                                        },
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledModal>
    );
};
