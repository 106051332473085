import { Pencil } from "lucide-react";
import { TrashIcon } from "@radix-ui/react-icons";
import React from "react";

import { PopoverContent } from "src/@/components/ui/popover";

type SectionOptions = {
    onRenameSection(): void;
    onDelete(): void;
};

export function SectionOptions({ onRenameSection, onDelete }: SectionOptions) {
    return (
        <PopoverContent className="flex w-44 flex-col rounded-lg border border-neutral-400 p-0">
            <div className="flex flex-col border-b border-b-neutral-300">
                <div
                    className="flex cursor-pointer flex-row items-center gap-1.5 px-3 py-2 transition hover:bg-neutral-300 hover:ease-in-out"
                    onClick={onRenameSection}
                >
                    <Pencil className="h-4 w-4 text-black" />
                    <p className="text-sm font-medium text-neutral-950">
                        Rename Section
                    </p>
                </div>
            </div>
            <div
                className="flex cursor-pointer flex-row items-center gap-1.5 px-3 py-2 transition hover:bg-neutral-300 hover:ease-in-out"
                onClick={onDelete}
            >
                <TrashIcon className="h-5 w-5 text-critical-a11y-light" />
                <p className="text-sm font-medium text-critical-a11y-light">
                    Delete
                </p>
            </div>
        </PopoverContent>
    );
}
