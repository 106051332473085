import React from "react";

import colors from "#reusable/colors/colors.json";
import theme from "#devices/components/theme";

/** Component to add styling to override the react-bootstrap modal styling with our own */
const ModalStyles = (): JSX.Element => (
    <>
        <style type="text/css">
            {`
                .modal-dialog {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                }
                .modal-content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    justify-content: center;
                    border-radius: ${theme.spacing.base};
                    border: none;
                }
                .modal-header,
                .modal-footer,
                .modal-body {
                    border: none;
                    padding: ${theme.spacing.baseAndAHalf};
                    justify-content: space-between;
                }
                .modal-header {
                    padding-bottom: 0;
                }
                .modal-footer {
                    border-top: 1px solid ${colors["neutral-400"]};
                }
                `}
        </style>
    </>
);

export default ModalStyles;
