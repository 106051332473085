import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "src/@/components/ui/alert-dialog";

type Props = {
    open: boolean;
    closeAlert: () => void;
    onConfirm: () => void;
    onCancel?: () => void;
};

const AddAdminAlertDialog = ({
    open,
    onConfirm,
    onCancel,
    closeAlert,
}: Props) => (
    <AlertDialog
        open={open}
        onOpenChange={() => {
            closeAlert();
        }}
    >
        <AlertDialogContent className="bg-white">
            <AlertDialogHeader>
                <AlertDialogTitle>Add Admin</AlertDialogTitle>
                <AlertDialogDescription>
                    If you add a user with an existing Snackpass account, their
                    name and associated email or phone may show up differently
                    than you entered.
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel
                    onClick={() => {
                        if (onCancel) onCancel();
                    }}
                >
                    Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                    onClick={() => {
                        onConfirm();
                        closeAlert();
                    }}
                >
                    Proceed
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
);

export default AddAdminAlertDialog;
