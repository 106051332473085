import * as yup from "yup";
import moment from "moment";

import { DISCOUNT_TYPES } from "#promotion/utils/constants";
import {
    FIELD_NAMES,
    FulfillmentValues,
    PlatformValues,
    QualifierValues,
    ScheduleDay,
} from "#promotion/utils/types";
import { convertScheduleDateToLuxon } from "#promotion/lib";

// Helper Validators
const menuItemValidation = yup.object({
    products: yup.array().of(yup.string()),
    categories: yup.array().of(yup.string()),
});

// Form Field Validators

export const nameValidation = yup
    .string()
    .min(1, "Name is required")
    .required("Name is required");
export const emailValidation = yup.string().email().required();

export const toggleValidation = yup.boolean();

export const discountTypeValidation = yup
    .string()
    .required()
    .oneOf(Object.keys(DISCOUNT_TYPES));

export const discountAmountPercentValidation = yup
    .number()
    .min(1, "Minimum discount is 1%")
    .max(100, "Maximum discount is 100%")
    .when(FIELD_NAMES.DISCOUNT_TYPE, {
        is: DISCOUNT_TYPES.PERCENT_OFF,
        then: yup.number().required(),
    });

export const discountAmountDollarValidation = yup
    .number()
    .min(0.01, "Minimum dollar discount is $0.01")
    .max(100, "Maximum dollar discount is $100")
    .when(FIELD_NAMES.DISCOUNT_TYPE, {
        is: DISCOUNT_TYPES.DOLLARS_OFF,
        then: yup.number().required(),
    });

export const discountNewPriceDollarValidation = yup
    .number()
    .min(0.01, "Minimum new price is $0.01")
    .max(100, "Maximum new price is $100")
    .when(FIELD_NAMES.DISCOUNT_TYPE, {
        is: DISCOUNT_TYPES.NEW_PRICE,
        then: yup.number().required()
    });

export const promoCodeValidation = yup.string().test({
    message: "Must specify a promo code",
    test: function (value) {
        const promoEnabled = this.parent[FIELD_NAMES.PROMO_CODE_ENABLED];

        if (promoEnabled && (!value || value.length === 0)) {
            return false;
        }

        return true;
    },
});

export const imageValidation = yup.string().url();

// Required Purchase
export const requirePurchaseValidation = yup
    .boolean()
    .when(FIELD_NAMES.DISCOUNT_QUALIFIER, {
        is: QualifierValues.ANY_ITEM,
        then: (schema) =>
            schema.isFalse(
                'Having a required item is not allowed when Qualifying Items is "Any Item"',
            ),
    })
    .required();

export const requiredPurchaseItemsValidation = yup
    .array()
    .of(menuItemValidation)
    .test({
        message: "Must have at least one required item",
        test: function (value) {
            if (!this.parent[FIELD_NAMES.REQUIRED_PURCHASE]) {
                return true;
            } else {
                return !!value?.length;
            }
        },
    })
    .test({
        message:
            "Each required item must have at least one product/category selected",
        test: function (value) {
            if (!this.parent[FIELD_NAMES.REQUIRED_PURCHASE]) {
                return true;
            } else {
                if (value?.length) {
                    const hasSelectedItem = value.every(
                        (item) =>
                            item.products?.length || item.categories?.length,
                    );
                    return hasSelectedItem;
                }
                return false;
            }
        },
    });

// Limit Customers
export const limitUsesValidation = yup.boolean().required();

export const totalUsesValidation = yup
    .number()
    .min(1, "Must be able to use at least once")
    .when(FIELD_NAMES.LIMIT_USES, {
        is: true,
        then: yup.number().required("Number is required"),
    });

export const pointsValidation = yup
    .number()
    .min(1, "Must require at least 1 point")
    .required();

export const scheduleEnabledValidation = yup.boolean().test({
    message: "At least one day must be selected",
    test: function (value) {
        if (!value) {
            return true;
        }

        return (
            this.parent[FIELD_NAMES.SCHEDULE_MONDAY_ENABLED] ||
            this.parent[FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED] ||
            this.parent[FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED] ||
            this.parent[FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED] ||
            this.parent[FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED] ||
            this.parent[FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED] ||
            this.parent[FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED]
        );
    },
});

export const expirationDaysValidation = yup
    .number()
    .test({
        name: "Account for infinite = -1",
        test: (value) => {
            if (value && value !== -1) {
                return value >= 1;
            }
            return true;
        },
        message: "Minimum # of days is 1",
    })
    .max(100, "Maximum # of days is 100");

export const scheduleDayEnabledValidation = (dayFieldName: string) =>
    yup.boolean().test({
        message: "At least one time must be selected for every enabled day",
        test: function (value) {
            if (!value) {
                return true;
            }

            return (
                this.parent[dayFieldName] &&
                this.parent[dayFieldName].length > 0
            );
        },
    });

const scheduleDayShapeValidation: yup.SchemaOf<ScheduleDay> = yup
    .object()
    .shape({
        start: yup.string().required(),
        end: yup.string().required(),
    });

export const scheduleDayValidation: yup.ArraySchema<yup.SchemaOf<ScheduleDay>> =
    yup
        .array()
        .of(scheduleDayShapeValidation)
        .test({
            message: "Start time must be before end time",
            test: function (value) {
                if (!value) {
                    return true;
                }

                // Start and end are required fields for ScheduleDay. Validation will catch if they are not defined first
                return value.every((time) => {
                    const startLuxon = convertScheduleDateToLuxon(time.start!);
                    const endLuxon = convertScheduleDateToLuxon(time.end!);

                    return startLuxon < endLuxon;
                });
            },
        })
        .test({
            message: "Times must not overlap",
            test: function (value) {
                if (!value) {
                    return true;
                }

                const sorted = value.sort((a, b) => {
                    const aStart = convertScheduleDateToLuxon(a.start!);
                    const bStart = convertScheduleDateToLuxon(b.start!);

                    return aStart < bStart ? -1 : 1;
                });

                return sorted.every((time, index) => {
                    const start = convertScheduleDateToLuxon(time.start!);
                    const end = convertScheduleDateToLuxon(time.end!);

                    const nextTime = value[index + 1];

                    if (!nextTime) {
                        return true;
                    }

                    const nextStart = convertScheduleDateToLuxon(
                        nextTime.start!,
                    );
                    const nextEnd = convertScheduleDateToLuxon(nextTime.end!);

                    return end < nextStart || start > nextEnd;
                });
            },
        });

export const durationEnabledValidation = yup.boolean().test({
    message: "Must have at least start date and time",
    test: function (value) {
        if (!value) {
            return true;
        }
        // Check if start date is defined
        return !!this.parent[FIELD_NAMES.DURATION_START_DATE];
    },
});

export const durationStartDateValidation = yup.string().test({
    message: "Start date and time cannot occur before current date and time",
    test: function (value) {
        const durationEnabled = this.parent[FIELD_NAMES.DURATION_ENABLED];
        if (!durationEnabled || !value) {
            return true;
        }
        const startDate = moment(value || null);
        const now = moment();
        if (startDate.isAfter(now)) {
            return true;
        }
        return false;
    },
});

export const durationEndDateValidation = yup
    .string()
    .test({
        message: "End date and time cannot occur before current date and time",
        test: function (value) {
            const durationEnabled = this.parent[FIELD_NAMES.DURATION_ENABLED];
            if (!durationEnabled || !value) {
                return true;
            }
            const endDate = moment(value || null);
            const now = moment();
            if (endDate.isAfter(now)) {
                return true;
            }
            return false;
        },
    })
    .test({
        message: "Cannot have end date and time without start date and time",
        test: function (value) {
            const durationEnabled = this.parent[FIELD_NAMES.DURATION_ENABLED];
            if (!durationEnabled || !value) {
                return true;
            }
            const startDate = this.parent[FIELD_NAMES.DURATION_START_DATE];
            if (startDate) {
                return true;
            }
            return false;
        },
    })
    .test({
        message: "End date and time must be after start date and time",
        test: function (value) {
            const durationEnabled = this.parent[FIELD_NAMES.DURATION_ENABLED];
            if (!durationEnabled || !value) {
                return true;
            }
            const startDate = moment(
                this.parent[FIELD_NAMES.DURATION_START_DATE],
            );
            const endDate = moment(value || null);
            if (startDate !== null && endDate !== null) {
                return endDate.isAfter(startDate);
            }
            return false;
        },
    });

export const discountQuantityValidation = yup.number().min(1);

export const discountQualiferValidation = yup
    .string()
    .oneOf(Object.values(QualifierValues));

export const discountedItemsValidation = yup
    .array()
    .of(menuItemValidation)
    .test({
        message: "Must specify at least one product or category to discount",
        test: function (value) {
            if (
                this.parent[FIELD_NAMES.DISCOUNT_QUALIFIER] ===
                QualifierValues.ANY_ITEM
            ) {
                return true;
            }

            // Verify either products or categories are specified
            if (value?.length) {
                return true;
            }
            return false;
        },
    })
    .test({
        message:
            "Each discounted item must have at least one product/category selected",
        test: function (value) {
            if (
                this.parent[FIELD_NAMES.DISCOUNT_QUALIFIER] ===
                QualifierValues.ANY_ITEM
            ) {
                return true;
            } else {
                if (value?.length) {
                    const hasSelectedItem = value.every(
                        (item) =>
                            item.products?.length || item.categories?.length,
                    );
                    return hasSelectedItem;
                }
                return false;
            }
        },
    });

export const audienceValidation = yup.string().required();

export const fulfillmentValidation = yup
    .string()
    .required()
    .oneOf(Object.values(FulfillmentValues));

export const platformsValidation = yup
    .string()
    .required()
    .oneOf(Object.values(PlatformValues));

export const singleUseValidation = yup.boolean();

export const onePerCartValidation = yup.boolean();

export const cartMinValidation = yup
    .number()
    .min(0, "Cart minimum must be greater than 0")
    .test({
        message: "Cart min must be less than cart max",
        test: function (value) {
            const cartMax = this.parent[FIELD_NAMES.CART_MAXIMUM] as number;
            if (!value || !cartMax) {
                return true;
            }

            return value < cartMax;
        },
    })
    .nullable(true)
    .transform((value) => (value === Number(value) ? value : undefined));

export const cartMaxValidation = yup
    .number()
    .min(0.01, "Cart maximum must be greater than $0.01")
    .test({
        message: "Cart max must be greater than cart min",
        test: function (value) {
            const cartMin = this.parent[FIELD_NAMES.CART_MINIMUM] as number;
            if (!value || !cartMin) {
                return true;
            }

            return value > cartMin;
        },
    })
    .nullable(true)
    .transform((value) => (value === Number(value) ? value : undefined));
