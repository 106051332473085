/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { Tooltip } from "antd";

import { ReactComponent as MenuIssueIcon } from "src/assets/icons/menu-issue-warning.svg";

import { MenuIssue } from "./menu-automation/interfaces";

type Props = {
    isProduct: boolean;
    menuIssues: any;
    style?: Object;
};

const MenuIssueDescriptions = (menuIssues: MenuIssue<any>[]) => (
    <span>
        {menuIssues.map((issue: MenuIssue<any>, index: number) => (
            <span key={index}>
                {index + 1}. {issue.description} <br />
            </span>
        ))}
    </span>
);

const MenuAutomationError = ({ menuIssues, isProduct, style }: Props) => (
    <span
        style={{
            position: "absolute",
            left: isProduct ? 110 : 32,
            top: isProduct ? 6 : 24,
            fontWeight: "bold",
            color: "#000000",
            ...style,
        }}
        css={MenuIssueStyle}
    >
        {menuIssues.length > 0 && (
            <Tooltip title={MenuIssueDescriptions(menuIssues)}>
                <MenuIssueIcon className="warning" />
            </Tooltip>
        )}
    </span>
);

const MenuIssueStyle = css`
    .warning {
        width: 0.8rem;
        height: 0.8rem;
    }
    .critical {
        width: 0.8rem;
        height: 0.8rem;
    }

    .critical path {
        fill: ${SystemColors.v1.melon50};
    }
`;

export default MenuAutomationError;
