/** @jsxImportSource @emotion/react */
import { SystemColors } from "@snackpass/design-system";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import "react-activity/lib/Spinner/Spinner.css";
import { useSelector } from "react-redux";

import { Button } from "src/@/components/ui/button";
import { ReactComponent as DownArrow } from "src/assets/icons/down-arrow-in-circle.svg";
import { formatRows } from "#download-timecards-button/utils";
import { RowData } from "#employees/employee-time-cards/hooks/use-prepare-expandable-rows";
import { ExportToCsv } from "#utils/export-to-csv";
import { getActiveStore } from "src/redux/selectors";

enum LoadState {
    unloaded,
    loading,
    loaded,
}

const dateTimeFormat = "yyyy'-'MM'-'dd";

interface Props {
    startDate: DateTime;
    endDate: DateTime;
    data: RowData[];
}

const DownloadTimecardsCSVButton = ({ startDate, endDate, data }: Props) => {
    const activeStore = useSelector(getActiveStore);
    const [loadState, setLoad] = useState<LoadState>(LoadState.unloaded);

    useEffect(() => {
        setLoad(LoadState.unloaded);
    }, [activeStore?._id]);

    if (!activeStore?._id || data.length === 0) return <></>;

    const downloadTimecards = async () => {
        if (loadState === LoadState.loading) {
            return;
        }
        setLoad(LoadState.loading);
        const csvExporter = new ExportToCsv({
            filename: `${
                activeStore.name
            } - Snackpass Timecards Report ${startDate.toFormat(
                dateTimeFormat,
            )} to ${endDate.toFormat(dateTimeFormat)}`,
            fieldSeparator: ",",
            useKeysAsHeaders: true,
        });
        const formattedData = formatRows(data);
        csvExporter.generateCsv(formattedData);
        setLoad(LoadState.loaded);
    };

    return (
        <Button
            variant="outline"
            onClick={downloadTimecards}
            disabled={loadState === LoadState.loading}
            className="space-x-2"
        >
            {loadState === LoadState.loading ? (
                <Spinner color="black" size={12} />
            ) : (
                <>
                    <DownArrow fill={SystemColors.v1.sesame} />
                    <span className="hidden md:block">Export</span>
                </>
            )}
        </Button>
    );
};
export default DownloadTimecardsCSVButton;
