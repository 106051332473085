import React from "react";
import { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";

import { OptionType } from "#store-selector/types";

export const StoreOption = (props: OptionProps<OptionType, false>) => (
    <components.Option {...props}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <strong style={{ fontSize: "14px" }}>{props.data.label}</strong>
            {props.data.subtitle && <small>{props.data.subtitle}</small>}
        </div>
    </components.Option>
);
