"use client";

import {
    BarChart3,
    ShoppingCart,
    Users,
    Percent,
    TrendingUp,
    ArrowUpDown,
    Grid3X3,
    ListFilter,
    UserCheck,
} from "lucide-react";
import { Routes } from "#navigation/routes";
import { OverviewLayout } from "src/@/components/ui/overview-layout";

const reportShortcuts = [
    {
        title: "Performance",
        description: "Track revenue and analyze business metrics",
        icon: <TrendingUp className="h-5 w-5" />,
        items: [
            {
                name: "Sales Summary",
                description: "View your overall sales performance",
                path: Routes.ReportsSalesSummary,
                icon: <BarChart3 className="h-4 w-4" />,
            },
            {
                name: "Providers",
                description: "Analyze sales across third party channels",
                path: Routes.ReportsSalesChannels,
                icon: <ArrowUpDown className="h-4 w-4" />,
            },
            {
                name: "Promotion Performance",
                description: "Track the success of your promotions",
                path: Routes.ReportsPromotions,
                icon: <Percent className="h-4 w-4" />,
            },
        ],
    },
    {
        title: "Menu Analytics",
        description: "Understand your menu performance and trends",
        icon: <ShoppingCart className="h-5 w-5" />,
        items: [
            {
                name: "Item Insights",
                description: "Track performance of individual menu items",
                path: Routes.ReportsMenuItemInsights,
                icon: <ListFilter className="h-4 w-4" />,
            },
            {
                name: "Category Insights",
                description: "Analyze sales by menu categories",
                path: Routes.ReportsMenuCategoryInsights,
                icon: <Grid3X3 className="h-4 w-4" />,
            },
        ],
    },
    {
        title: "Customer Analytics",
        description: "Get insights about your customer base",
        icon: <Users className="h-5 w-5" />,
        items: [
            {
                name: "Customer Directory",
                description: "View and analyze customer data",
                path: Routes.ReportsCustomerDirectoryInsights,
                icon: <UserCheck className="h-4 w-4" />,
            },
        ],
    },
];

export const ReportsOverview = () => {
    return (
        <OverviewLayout
            title="Reports"
            description="Comprehensive insights and analytics for your business"
            sections={reportShortcuts}
        />
    );
};
