import React from "react";
import styled from "styled-components";

import colors from "#reusable/colors/colors.json";
import Text from "#devices/components/Text";
import theme from "#devices/components/theme";
import useWindowDimensions from "#hooks/use-window-dimensions";

type CalloutStatProps = {
    icon: React.ReactNode;
    iconColor?: string;
    statNumber: string;
    statText: string;
    highlightBorder?: boolean;
};

const CalloutStatWrapper = styled.div<{
    iconColor: string;
    highlightBorder: boolean;
}>`
    margin-bottom: ${theme.spacing.baseAndAHalf};
    padding: ${theme.spacing.baseAndAHalf};
    border-radius: ${theme.spacing.base};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
        props.highlightBorder ? "transparent" : colors["neutral-400"]};
    position: relative;
    display: flex;
    align-items: center;
    // grow to full height of container to match sizes of other callout stats in a Row/Col
    height: calc(100% - ${theme.spacing.baseAndAHalf});

    ${(props) =>
        props.highlightBorder &&
        `
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            inset: 0;
            padding: 1px; // width of border to show
            border-radius: ${theme.spacing.base};
            background: conic-gradient(
                from 177.22deg at 50% 50%,
                #ffdd80 -4.05deg,
                #ea80ff 28.97deg,
                #9580ff 60.11deg,
                #99ccff 102.81deg,
                #82ddff 151.62deg,
                #dad2ff 196.83deg,
                #ffd2e7 221.37deg,
                #fbbcb6 319.13deg,
                #ffd9bc 336.72deg,
                #ffdd80 355.95deg,
                #ea80ff 388.97deg
            );
            -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
                mask: 
                    linear-gradient(#fff 0 0) content-box, 
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
        }
    `}
    &
        svg {
        fill: ${(props) => props.iconColor};
        height: ${theme.spacing.triple};
        width: ${theme.spacing.triple};
        margin-right: ${theme.spacing.baseAndAHalf};
    }

    & h1,
    & p {
        margin-bottom: 0;
    }
`;

const CalloutStat = (props: CalloutStatProps): JSX.Element => {
    const { isMobile } = useWindowDimensions();

    const TitleComponent = isMobile ? Text.Title1 : Text.LargeTitle;

    return (
        <CalloutStatWrapper
            iconColor={props.iconColor || "currentColor"}
            highlightBorder={Boolean(props.highlightBorder)}
        >
            <div>{props.icon}</div>
            <div>
                <TitleComponent>{props.statNumber}</TitleComponent>
                <CustomLargeBody>{props.statText}</CustomLargeBody>
            </div>
        </CalloutStatWrapper>
    );
};

export default CalloutStat;

const CustomLargeBody = styled.div`
    padding-left: 10px;
`;
