import { IStore } from "@snackpass/snackpass-types";

export type DineInTable = {
    _id: string;
    tableNumber: string;
    storeId: string;
    createdAt: string;
    updatedAt: string;
};

export const formatDownloadLink = (
    store: IStore | null,
    tableNumber: string,
) =>
    store
        ? `https://order.snackpass.co/${
              store.slug ?? `$${store._id}`
          }?table_number=${tableNumber}&utm_source=qr`
        : "https://order.snackpass.co/";
