import React from "react";
import styled from "styled-components";

type TextProps = {
    children: React.ReactNode;
    htmlFor?: string;
};

/** A larger than normal h1, but can be any semantic heading using the polymorphic `as` prop from
 * styled-components. See https://styled-components.com/docs/api#as-polymorphic-prop */
const LargeTitle = styled.h1<TextProps>`
    // destructure so we dont have to repeat theme destructure several times
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: ${theme.spacing.base};
        color: ${theme.typography.largeTitle.color};
        font-weight: ${theme.typography.largeTitle.fontWeight};
        font-size: ${theme.typography.largeTitle.fontSize};
        line-height: ${theme.typography.largeTitle.lineHeight};
    `}
`;

/** A standard h1 to be used ideally just once per page, but can be any semantic heading using the
 * polymorphic `as` prop from styled-components. See
 * https://styled-components.com/docs/api#as-polymorphic-prop */
const Title1 = styled.h1<TextProps>`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: ${theme.spacing.base};
        color: ${theme.typography.title1.color};
        font-weight: ${theme.typography.title1.fontWeight};
        font-size: ${theme.typography.title1.fontSize};
        line-height: ${theme.typography.title1.lineHeight};
    `}
`;

/** A standard h2, but can be any semantic heading using the polymorphic `as` prop from
 * styled-components. See https://styled-components.com/docs/api#as-polymorphic-prop */
const Title2 = styled.h2<TextProps>`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: ${theme.spacing.base};
        color: ${theme.typography.title2.color};
        font-weight: ${theme.typography.title2.fontWeight};
        font-size: ${theme.typography.title2.fontSize};
        line-height: ${theme.typography.title2.lineHeight};
    `}
`;

/** A standard h3, but can be any semantic heading using the polymorphic `as` prop from
 * styled-components. See https://styled-components.com/docs/api#as-polymorphic-prop */
const Title3 = styled.h3<TextProps>`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: ${theme.spacing.base};
        color: ${theme.typography.title3.color};
        font-weight: ${theme.typography.title3.fontWeight};
        font-size: ${theme.typography.title3.fontSize};
        line-height: ${theme.typography.title3.lineHeight};
    `}
`;

/** A standard h2, but can be any semantic heading using the polymorphic `as` prop from
 * styled-components. See https://styled-components.com/docs/api#as-polymorphic-prop */
const Subtitle = styled.h2<TextProps>`
    ${({ theme }) => `
     font-family: "Inter";
     white-space: pre-line;
     margin-left: 10px;
     margin-bottom: ${theme.spacing.base};
     color: ${theme.typography.small.color};
     font-weight: ${theme.typography.small.fontWeight};
     font-size: ${theme.typography.small.fontSize};
     line-height: ${theme.typography.title2.lineHeight};
 `}
`;

/** A larger tha normal paragraph element for highlighting something */
const LargeBody = styled.p<TextProps>`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: ${theme.spacing.base};
        color: ${theme.typography.largeBody.color};
        font-weight: ${theme.typography.largeBody.fontWeight};
        font-size: ${theme.typography.largeBody.fontSize};
        line-height: ${theme.typography.largeBody.lineHeight};
    `}
`;

/** A standard paragraph element */
const Body = styled.p<TextProps>`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: ${theme.spacing.base};
        color: ${theme.typography.body.color};
        font-weight: ${theme.typography.body.fontWeight};
        font-size: ${theme.typography.body.fontSize};
        line-height: ${theme.typography.body.lineHeight};
    `}
`;

/** A standard paragraph element */
const Small = styled.p<TextProps>`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: 0;
        color: ${theme.typography.small.color};
        font-weight: ${theme.typography.small.fontWeight};
        font-size: ${theme.typography.small.fontSize};
        line-height: ${theme.typography.small.lineHeight};
    `}
`;

/** A span element that is used as a "label", like a bolded callout or as a form field label  */
const Label = styled.span`
    ${({ theme }) => `
        font-family: "Inter";
        white-space: pre-line;
        margin-bottom: 0;
        color: ${theme.typography.label.color};
        font-weight: ${theme.typography.label.fontWeight};
        font-size: ${theme.typography.label.fontSize};
        line-height: ${theme.typography.label.lineHeight};
    `}
`;

// https://kentcdodds.com/blog/compound-components-with-react-hooks
// Matching up naming to Figma Design spec
/** Compound Component: use one of the following names `LargeTitle`, `Title1`, `Title2`, or `Body`
 * after the Title name, i.e. `Title.Title1` */
const Text = (props: TextProps): React.ReactNode => props.children;

Text.LargeTitle = LargeTitle;
Text.Title1 = Title1;
Text.Title2 = Title2;
Text.Title3 = Title3;
Text.Subtitle = Subtitle;
Text.LargeBody = LargeBody;
Text.Body = Body;
Text.Small = Small;
Text.Label = Label;

export default Text;
