import { IPurchase, PaymentProvider } from "@snackpass/snackpass-types";
import { CountryCode } from "libphonenumber-js";
import {
    Mail,
    Phone,
    ClipboardEdit,
    Clock,
    MapPin,
    Calendar,
    Hash,
    User,
    ChevronDown,
    ChevronUp,
    CheckCircle2,
} from "lucide-react";

import { Card as ShadcnCard, CardContent } from "src/@/components/ui/card";
import { Badge } from "src/@/components/ui/badge";
import { Button } from "src/@/components/ui/button";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "src/@/components/ui/table";
import { humanizePurchaseStatus } from "#order-details/utils";
import {
    getPOSIntegrationUserInfo,
    normalizePhoneNumber,
    purchaseIsThirdPartyDeliverect,
} from "#utils/helpers";

import { PrintButton } from "./print-button";
import { RefundButton } from "./refund-button";
import { RefundSummary } from "./refund-summary";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "src/@/components/ui/collapsible";
import { useState } from "react";
import moment from "moment";

type Props = {
    purchase: IPurchase;
};

export const PurchaseCustomer = ({ purchase }: Props) => {
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const status = humanizePurchaseStatus(purchase, true);
    const userInfoSource = getPOSIntegrationUserInfo(purchase) || purchase;
    const isUnpaid = purchase.paymentProviderId === PaymentProvider.unpaid;

    const fulfillmentType = purchase.fulfillment
        ?.toLowerCase()
        .replace("_", " ");
    const isScheduled = Boolean(purchase.scheduledDate);

    const lastStatus = purchase.status?.[purchase.status.length - 1];

    const formatDate = (date: string | Date) => {
        return new Date(date).toLocaleString(undefined, {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const formatRelativeTime = (date: string | Date) => {
        return moment(date).fromNow();
    };

    return (
        <ShadcnCard>
            <CardContent className="pt-6">
                <div className="border rounded-lg">
                    <Table>
                        <TableBody>
                            {/* Order Number */}
                            <TableRow className="hover:bg-muted/50">
                                <TableCell className="w-48 font-medium">
                                    <div className="flex items-center gap-2">
                                        <Hash className="h-4 w-4 text-muted-foreground" />
                                        Order Number
                                    </div>
                                </TableCell>
                                <TableCell>#{purchase.receiptNumber}</TableCell>
                            </TableRow>

                            {/* Customer Name */}
                            <TableRow className="hover:bg-muted/50">
                                <TableCell className="font-medium">
                                    <div className="flex items-center gap-2">
                                        <User className="h-4 w-4 text-muted-foreground" />
                                        Customer
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {userInfoSource.user?.name}
                                </TableCell>
                            </TableRow>

                            {/* Phone Number */}
                            {userInfoSource.user?.number && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <Phone className="h-4 w-4 text-muted-foreground" />
                                            Phone
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {purchase.user?.countryCode &&
                                        purchase.user.countryCode !== "US" ? (
                                            <div className="flex items-center gap-2">
                                                <Badge
                                                    variant="destructive"
                                                    className="text-xs"
                                                >
                                                    Non-US (
                                                    {purchase.user.countryCode})
                                                </Badge>
                                                {normalizePhoneNumber(
                                                    userInfoSource.user.number,
                                                    purchase.user
                                                        .countryCode as CountryCode,
                                                )}
                                            </div>
                                        ) : (
                                            normalizePhoneNumber(
                                                userInfoSource.user?.number,
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}

                            {/* Email */}
                            {userInfoSource.user?.email && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <Mail className="h-4 w-4 text-muted-foreground" />
                                            Email
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {userInfoSource.user?.email}
                                    </TableCell>
                                </TableRow>
                            )}

                            {/* Fulfillment Type */}
                            {fulfillmentType && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <MapPin className="h-4 w-4 text-muted-foreground" />
                                            Fulfillment
                                        </div>
                                    </TableCell>
                                    <TableCell className="capitalize">
                                        {fulfillmentType}
                                    </TableCell>
                                </TableRow>
                            )}

                            {/* Created Time */}
                            <TableRow className="hover:bg-muted/50">
                                <TableCell className="font-medium">
                                    <div className="flex items-center gap-2">
                                        <Calendar className="h-4 w-4 text-muted-foreground" />
                                        Created
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className="flex flex-col gap-1">
                                        <span>
                                            {formatDate(purchase.createdAt)}
                                        </span>
                                        <span className="text-sm text-muted-foreground">
                                            {formatRelativeTime(
                                                purchase.createdAt,
                                            )}
                                        </span>
                                    </div>
                                </TableCell>
                            </TableRow>

                            {/* Last Status Update */}
                            {status && lastStatus && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <CheckCircle2 className="h-4 w-4 text-muted-foreground" />
                                            Last Status
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex flex-col gap-1">
                                            <span>
                                                {formatDate(
                                                    lastStatus.createdAt,
                                                )}
                                            </span>
                                            <div className="flex items-center gap-2 text-sm">
                                                <span
                                                    className="font-medium capitalize px-2 py-0.5 rounded-md text-white"
                                                    style={{
                                                        backgroundColor:
                                                            status.color,
                                                    }}
                                                >
                                                    {lastStatus.type}
                                                </span>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}

                            {/* Order Notes */}
                            {purchase.notes && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <ClipboardEdit className="h-4 w-4 text-muted-foreground" />
                                            Order Note
                                        </div>
                                    </TableCell>
                                    <TableCell>{purchase.notes}</TableCell>
                                </TableRow>
                            )}

                            {/* Status History */}
                            {purchase.status && purchase.status.length > 0 && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <Clock className="h-4 w-4 text-muted-foreground" />
                                            Status History
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Collapsible
                                            open={isStatusOpen}
                                            onOpenChange={setIsStatusOpen}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-2">
                                                    <span className="capitalize">
                                                        {purchase.status.length}{" "}
                                                        status updates
                                                    </span>
                                                </div>
                                                <CollapsibleTrigger asChild>
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        className="p-0 hover:bg-transparent"
                                                    >
                                                        {isStatusOpen ? (
                                                            <ChevronUp className="h-4 w-4" />
                                                        ) : (
                                                            <ChevronDown className="h-4 w-4" />
                                                        )}
                                                    </Button>
                                                </CollapsibleTrigger>
                                            </div>
                                            <CollapsibleContent className="mt-2">
                                                <div className="flex flex-col gap-2">
                                                    {purchase.status.map(
                                                        (statusItem, index) => {
                                                            // Get the color from humanizePurchaseStatus for this status
                                                            const statusInfo =
                                                                humanizePurchaseStatus(
                                                                    {
                                                                        ...purchase,
                                                                        status: purchase.status.slice(
                                                                            0,
                                                                            index +
                                                                                1,
                                                                        ),
                                                                    },
                                                                    true,
                                                                );

                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="flex items-center justify-between text-sm pl-4"
                                                                >
                                                                    <span
                                                                        className={`capitalize font-medium px-2 py-0.5 rounded-md ${
                                                                            index ===
                                                                            purchase
                                                                                .status
                                                                                .length -
                                                                                1
                                                                                ? "text-white"
                                                                                : "text-muted-foreground"
                                                                        }`}
                                                                        style={{
                                                                            backgroundColor:
                                                                                index ===
                                                                                purchase
                                                                                    .status
                                                                                    .length -
                                                                                    1
                                                                                    ? statusInfo?.color
                                                                                    : "transparent",
                                                                        }}
                                                                    >
                                                                        {
                                                                            statusItem.type
                                                                        }
                                                                    </span>
                                                                    <span className="text-muted-foreground">
                                                                        {formatDate(
                                                                            statusItem.createdAt,
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </CollapsibleContent>
                                        </Collapsible>
                                    </TableCell>
                                </TableRow>
                            )}

                            {/* Scheduled Time */}
                            {purchase.scheduledDate && (
                                <TableRow className="hover:bg-muted/50">
                                    <TableCell className="font-medium">
                                        <div className="flex items-center gap-2">
                                            <Calendar className="h-4 w-4 text-muted-foreground" />
                                            Scheduled Time
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {formatDate(purchase.scheduledDate)}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>

                <RefundSummary purchase={purchase} />

                {/* Action Buttons */}
                <div className="flex items-center gap-2 mt-4">
                    <PrintButton purchase={purchase} />
                    {!isUnpaid && !purchaseIsThirdPartyDeliverect(purchase) && (
                        <RefundButton purchase={purchase} />
                    )}
                </div>
            </CardContent>
        </ShadcnCard>
    );
};
