import { Modal } from "antd";
import React, { SetStateAction } from "react";
import "antd/dist/antd.css";
import { css } from "@emotion/react";

type DeleteCategoryModalProps = {
    isDeleteCategoryModalOpen: boolean;
    setIsDeleteCategoryModalOpen: React.Dispatch<SetStateAction<boolean>>;
    handleOk: () => void;
};

const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({
    handleOk,
    isDeleteCategoryModalOpen,
    setIsDeleteCategoryModalOpen,
}) => (
    <Modal
        centered
        css={ModalCSS}
        title="Remove category? "
        open={isDeleteCategoryModalOpen}
        onOk={handleOk}
        onCancel={() => {
            setIsDeleteCategoryModalOpen(false);
        }}
    >
        Note: removing Favorites also deletes all products in the category.
    </Modal>
);

const ModalCSS = css`
    font-family: "Inter";
    font-size: 16px;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
`;

export default DeleteCategoryModal;
