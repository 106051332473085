import { Col, Row } from "antd";
import { useCallback } from "react";

import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { Button } from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { useAppDispatch } from "src/redux/hooks";

type OutlineChangesTrackerProps = {
    changesCount: number;
    resetOutlineChangesCount: () => void;
    isMobile: boolean;
};

export const OutlineChangesTracker: React.FC<OutlineChangesTrackerProps> = ({
    changesCount,
    resetOutlineChangesCount,
    isMobile,
}) => {
    const dispatch = useAppDispatch();

    const discardChanges = useCallback(() => {
        dispatch(multiMenuThunks.resetCurrentMenu());
        resetOutlineChangesCount();
    }, [dispatch, resetOutlineChangesCount]);

    const applyChanges = useCallback(() => {
        dispatch(multiMenuThunks.persistCurrentMenu());
        resetOutlineChangesCount();
    }, [dispatch, resetOutlineChangesCount]);

    return (
        <Row justify="space-between" align="middle">
            <Col span={isMobile ? 6 : 10}>
                <Row gutter={[0, 4]}>
                    <Col span={24}>
                        <Text type="body-bold">Menu Outline Updated</Text>
                    </Col>
                    <Col span={24}>
                        <Text type="body-regular">{`${changesCount} ${
                            changesCount === 1 ? "Change" : "Changes"
                        }`}</Text>
                    </Col>
                </Row>
            </Col>

            <Col span={isMobile ? 18 : 14}>
                <Row gutter={10} justify="end">
                    <Col>
                        <Button
                            type="tertiary"
                            size="small"
                            onClick={discardChanges}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            size="small"
                            onClick={applyChanges}
                        >
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
