import { notification } from "antd";

export const notifyFailure = (props?: {
    message?: string;
    description?: string;
    duration?: number;
}) =>
    notification.error({
        placement: "top",
        message: props?.message ?? "Failure",
        description:
            props?.description ??
            "Unable to save at this time. If this continues to happen, please contact support.",
        duration: props?.duration ?? 5,
    });

export const notifySuccess = (props?: {
    message?: string;
    description?: string;
    duration?: number;
}) =>
    notification.success({
        placement: "top",
        message: props?.message ?? "Success",
        description: props?.description ?? "Successfully saved information!",
        duration: props?.duration ?? 5,
    });

export const notifyInfo = (props?: {
    message?: string;
    description?: string;
    duration?: number;
}) =>
    notification.info({
        placement: "top",
        message: props?.message ?? "Not Finished",
        description:
            props?.description ??
            "Please start and finish all sections before submitting.",
        duration: props?.duration ?? 5,
    });
