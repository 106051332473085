import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import FilterHeader, {
    FilterType,
} from "#reports/sales-summary/shared-components/FilterHeader";
import { getActiveStore } from "src/redux/selectors";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import CategoriesByLocationChart from "#reports/location-menu-categories/components/CategoriesByLocationChart";
import { ReportsContext } from "#app/reports-context-provider";
import CategoriesByLocationTable from "#reports/location-menu-categories/components/CategoriesByLocationTable";
import api from "src/api/rest";
import { getStoreTimezone } from "#utils/helpers";
import { logAndSendError } from "src/utils/errors";

const LocationMenuCategoryReports = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, filter, stores } = reportsState;
    const trackEvent = useTrackEvent();

    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.locationMenuInsightsData) {
            setReportsState((reportsState) => ({
                ...reportsState,
                locationMenuInsightsData: {
                    itemInsightsDataLoading: true,
                    itemInsightsDataFailed: false,
                    itemInsightsData:
                        reportsState.locationMenuInsightsData?.itemInsightsData,
                },
            }));
            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        const storeIds = stores.length ? filter.storeIds : [activeStore?._id];
        const params = {
            storeId: activeStore?._id,
            storeIds: JSON.stringify(storeIds),
            startDate: dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: dateRanges[0][1].format("YYYY-MM-DD"),
            comparedToStartDate: dateRanges[1][0].format("YYYY-MM-DD"),
            comparedToEndDate: dateRanges[1][1].format("YYYY-MM-DD"),

            channel: JSON.stringify(filter.channel),
            source: JSON.stringify(filter.source),
            fulfillment: JSON.stringify(filter.fulfillment),
            timezone: getStoreTimezone(activeStore),
        };

        if (storeIds.length === 0) {
            // if no stores are inputted, we want to show the "no data" failed state.
            setReportsState((reportsState) => ({
                ...reportsState,
                locationMenuInsightsData: {
                    itemInsightsDataLoading: false,
                    itemInsightsDataFailed: true,
                    itemInsightsData: undefined,
                },
            }));
            return;
        }

        abortController.current = new AbortController();

        api.reports
            .getLocationMenuItemReport(params, abortController.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationMenuInsightsData: {
                        itemInsightsDataLoading: false,
                        itemInsightsDataFailed: false,
                        itemInsightsData: res.data.itemsByStore,
                    },
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationMenuInsightsData: {
                        itemInsightsDataLoading: false,
                        itemInsightsDataFailed: true,
                        itemInsightsData: undefined,
                    },
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);

    const fetchDataDebounced = useCallback(
        debounce(() => fetchDataRef.current?.(), 600),
        [],
    );

    return (
        <div>
            <FilterHeader
                reportType={ReportType.LOCATION_MENU_CATEGORY_REPORT}
                showLocationFilter
                hideFilters={[FilterType.GRANULARITY]}
            />
            <div className="px-6 md:px-24">
                <CategoriesByLocationChart title="Top 5 Categories by Location" />
                <CategoriesByLocationTable />
            </div>
        </div>
    );
};

export default LocationMenuCategoryReports;
