import React from "react";
import { Link } from "react-router-dom";

import { Button } from "src/@/components/ui/button";

const EmptyStateUpsell = () => (
    <>
        <div className="border-x-0 border-b  border-t-0 border-solid border-b-neutral-400 px-12 py-6">
            <h1 className="text-xlarge">Sales Channels</h1>
        </div>

        <div className="mx-12 mt-3 flex max-w-3xl flex-col items-center rounded-md border border-neutral-400 p-3">
            <h6 className="flex-1 p-3 text-center text-large">
                Connect third party platforms to see reports for all of your
                orders in one place.
            </h6>
            <Button
                variant="secondary"
                asChild
                className="mt-6 bg-success-light text-neutral-50 no-underline"
            >
                <Link to="/settings-integrations">Setup Integrations</Link>
            </Button>
        </div>
    </>
);

export default EmptyStateUpsell;
