import React, { SelectHTMLAttributes } from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import Text from "#devices/components/Text";
import VisuallyHidden from "#devices/components/VisuallyHidden";
import colors from "#reusable/colors/colors.json";

type Option = { value: string | number; label: string; disabled?: boolean };
type Group = { label: string; options: Option[] };

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    value?: number | string | Array<string>;
    label?: string;
    id: string;
    helpText?: string | JSX.Element;
    isInvalid?: boolean;
    isValid?: boolean;
    hasUnsetOption?: boolean;
    options: Array<Option>;
    groups?: Array<Group>;
    hiddenLabel?: boolean;
}

const SelectWrapper = styled.div<{ disabled?: boolean }>`
    margin-bottom: ${({ theme }) => theme.spacing.base};
    display: flex;
    flex-direction: column;
    cursor: pointer;

    /* Disabled state */
    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        select {  
            opacity: 0.65;
            pointer-events: none;
        }
        `}
`;

const StyledSelect = styled.select<Omit<SelectProps, "label" | "options">>`
    padding: ${({ theme }) => theme.spacing.base};
    margin-top: ${({ theme }) => theme.spacing.half};
    font-size: 1rem;
    color: ${colors["neutral-600"]};
    background-color: ${colors["neutral-50"]};
    border-radius: ${({ theme }) => theme.spacing.half};
    border: 1px solid ${colors["neutral-400"]};
    transition: box-shadow 0.2s ease-in-out;
    appearance: none;
    position: relative;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjAwMTQgNi41OTA4NkwyLjczMjMxIDAuOTk5NjcyTDIuNjc2NTQgMC45NDM2MDFDMi4wNDgzOSAwLjM1NDcyNyAxLjA5MzkzIDAuMzYwNjIxIDAuNDg3NDE2IDAuOTQ3MDlDLTAuMTQxMjE0IDEuNTU0OTQgLTAuMTY0MjkxIDIuNTYwMTggMC40MzU0OTIgMy4xOTY2OEw2Ljg1Mjk4IDEwLjAwNzFMNi45MTA2IDEwLjA2NDlDNy41NjAyNiAxMC42NzE2IDguNTUyMTUgMTAuNjQxMyA5LjE0OTgxIDEwLjAwNzFMMTUuNTY3MyAzLjE5NjY4TDE1LjYyIDMuMTM3NjVDMTYuMTY2NyAyLjQ4MDM4IDE2LjExNzMgMS41MjkxMSAxNS41MTU0IDAuOTQ3MDkyQzE0Ljg4MTkgMC4zMzQ1NzYgMTMuODc0OSAwLjM1ODMgMTMuMjcwNSAwLjk5OTY4OEw4LjAwMTQgNi41OTA4NloiIGZpbGw9IiMyODJEMzIiLz4KPC9zdmc+Cg==");
    background-position: calc(100% - ${({ theme }) => theme.spacing.base});
    background-repeat: no-repeat;
    cursor: pointer;

    &:focus {
        outline: 0;
        border: 1px solid ${colors["gray-dark"]};
    }

    /* Valid state */
    ${({ isValid }) =>
        isValid &&
        `
        color: ${SystemColors.v2.parsley50.light};
        box-shadow: ${`0px 0px 0px 2px ${SystemColors.v2.parsley50.light}`};
    `}

    /* Invalid state */
    ${({ isInvalid }) =>
        isInvalid &&
        `
        color: ${SystemColors.v2.melon50.light};
        box-shadow: ${`0px 0px 0px 2px ${SystemColors.v2.melon50.light}`};
    `}
`;

const renderLabel = (label: SelectProps["label"], id: SelectProps["id"]) => (
    <Text.Label htmlFor={id} as="label">
        {label}
    </Text.Label>
);

const Select = ({
    value,
    label,
    helpText,
    isInvalid,
    isValid,
    id,
    hasUnsetOption,
    options,
    groups,
    hiddenLabel,
    disabled = false,
    multiple = false,
    ...rest
}: SelectProps): JSX.Element => {
    const displayedLabel = label ? (
        hiddenLabel ? (
            <VisuallyHidden>{renderLabel(label, id)}</VisuallyHidden>
        ) : (
            renderLabel(label, id)
        )
    ) : null;

    return (
        <SelectWrapper disabled={disabled}>
            {displayedLabel}
            {helpText ? <Text.Body as="span">{helpText}</Text.Body> : null}
            <StyledSelect
                value={value}
                id={id}
                isInvalid={isInvalid}
                isValid={isValid}
                multiple={multiple}
                {...rest}
            >
                {hasUnsetOption ? <option value=""> — </option> : null}
                {groups ? (
                    <>
                        {groups.map((group: Group) => (
                            <optgroup label={group.label} key={group.label}>
                                {group.options.map((option: Option) => (
                                    <option
                                        value={option.value}
                                        key={option.value}
                                        disabled={option.disabled}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </>
                ) : (
                    <>
                        <option value="" disabled>
                            Select an option
                        </option>
                        {options.map((option: Option) => (
                            <option
                                value={option.value}
                                key={option.value}
                                disabled={option.disabled}
                            >
                                {option.label}
                            </option>
                        ))}
                    </>
                )}
            </StyledSelect>
        </SelectWrapper>
    );
};

export default Select;
