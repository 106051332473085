import React, { createContext, SetStateAction } from "react";
import {
    AddonGroup,
    IHoursSchema,
    IProduct,
    IProductCategory,
} from "@snackpass/snackpass-types";
import { noop } from "lodash";
import { PartialDeep } from "type-fest";

import { Options } from "#reusable/select/dropdown";

export const ProductNewSharedContext = createContext<ProductSharedContentType>({
    showBottomDrawer: false,
    setShowBottomDrawer: noop,
    showNewModifierForm: false,
    setShowNewModifierForm: noop,
    modifierGroupsOptions: [],
    setModifierGroups: noop,
    setDeletedAddonGroups: noop,
    addDeletedAddons: noop,
    modifierGroups: [],
    isModifierGroupInEdit: false,
    modifierGroupInEdit: undefined,
    setIsModifierGroupInEdit: noop,
    setModifierGroupInEdit: noop,
});

type ProductSharedContentType = {
    showBottomDrawer: boolean;
    setShowBottomDrawer: React.Dispatch<SetStateAction<boolean>>;
    showNewModifierForm: boolean;
    setShowNewModifierForm: React.Dispatch<SetStateAction<boolean>>;
    modifierGroupsOptions: {
        label: string;
        value: string;
        addonGroup: AddonGroup;
    }[];
    setModifierGroups: React.Dispatch<
        SetStateAction<PartialDeep<AddonGroup>[]>
    >;
    setDeletedAddonGroups: React.Dispatch<SetStateAction<string[]>>;
    addDeletedAddons: (addonId: string[], addonGroupId: string) => void;
    modifierGroups: PartialDeep<AddonGroup>[];
    isModifierGroupInEdit: boolean;
    modifierGroupInEdit: PartialDeep<AddonGroup> | undefined;
    setIsModifierGroupInEdit: React.Dispatch<SetStateAction<boolean>>;
    setModifierGroupInEdit: React.Dispatch<
        SetStateAction<PartialDeep<AddonGroup> | undefined>
    >;
};

type MenuTopLevelContextType = {
    openProductEditSlideOver: () => void;
    closeProductEditSlideOver: () => void;
    openNewProductSlideOver: () => void;
    closeNewProductSlideOver: () => void;
    storeHours: IHoursSchema | null;
    categoryOptions: Options[] | undefined;
    modifierGroupsOptions: {
        label: string;
        value: string;
        addonGroup: AddonGroup;
    }[];
    productInDraft: Partial<IProduct> | null;
    setProductInDraft: React.Dispatch<
        React.SetStateAction<Partial<IProduct> | null>
    >;
    productInEdit: IProduct | null;
    setProductInEdit: (value: IProduct) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    setMenuReorderingChanges: React.Dispatch<React.SetStateAction<number>>;
    setShowTableSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    showNewModifierForm: boolean;
    setShowNewModifierForm: React.Dispatch<React.SetStateAction<boolean>>;
    data: IProductCategoryWithProducts[];
    setData: React.Dispatch<
        React.SetStateAction<IProductCategoryWithProducts[]>
    >;
    handleCategoryReorder: (
        value: IProductCategory[],
        callback: () => void,
    ) => void;
    showTableSpinner: boolean;
    incrementMenuOrderChanges: () => void;
    activeStoreId: string;
    isNew: boolean;
    products: IProduct[];
};

export const ProductEditSharedContext = createContext<ProductSharedContentType>(
    {
        showBottomDrawer: false,
        setShowBottomDrawer: noop,
        showNewModifierForm: false,
        setDeletedAddonGroups: noop,
        addDeletedAddons: noop,
        setShowNewModifierForm: noop,
        modifierGroupsOptions: [],
        setModifierGroups: noop,
        modifierGroups: [],
        isModifierGroupInEdit: false,
        modifierGroupInEdit: undefined,
        setIsModifierGroupInEdit: noop,
        setModifierGroupInEdit: noop,
    },
);

export const MenuTopLevelContext = createContext<MenuTopLevelContextType>({
    openProductEditSlideOver: noop,
    closeProductEditSlideOver: noop,
    openNewProductSlideOver: noop,
    closeNewProductSlideOver: noop,
    storeHours: null,
    categoryOptions: undefined,
    modifierGroupsOptions: [],
    productInDraft: null,
    setProductInDraft: noop,
    productInEdit: null,
    setProductInEdit: noop,
    setIsLoading: noop,
    isLoading: true,
    setMenuReorderingChanges: noop,
    setShowTableSpinner: noop,
    showNewModifierForm: false,
    setShowNewModifierForm: noop,
    data: [],
    setData: noop,
    handleCategoryReorder: noop,
    showTableSpinner: true,
    incrementMenuOrderChanges: noop,
    activeStoreId: "",
    isNew: false,
    products: [],
});

export interface IProductCategoryWithProducts {
    name: string;
    productIds: string[];
    _id: string;
    products: IProduct[];
    isArchived?: Date;
}
