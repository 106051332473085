import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { IPayPeriodSummary } from "@snackpass/snackpass-types";

import { MonthOptions } from "#time-and-attendance/types";
import {
    getActiveStore,
    getActiveStoreAttendanceSettings,
} from "src/redux/selectors";
import API from "src/api/graphql";
import {
    TimeRelatedRangeOption,
    setupDateRangeValues,
    setupDateRangeValuesForFullMonthPeriod,
} from "#employees/helpers";
import { mapPaySummaryByDateRangeGQLtoTS } from "#time-and-attendance/utils";

const usePaySummaries = (selectedMonth: MonthOptions) => {
    const activeStore = useSelector(getActiveStore);
    const storeSettings = useSelector(getActiveStoreAttendanceSettings);
    const [loading, setLoading] = useState<boolean>(true);
    const [paySummaries, setPaySummaries] = useState<IPayPeriodSummary[]>([]);

    useEffect(() => {
        if (!activeStore) return;

        const fetchPayPeriodSummaries = async () => {
            setLoading(true);
            const now = DateTime.now();
            let ranges: TimeRelatedRangeOption[] = [];
            const month = selectedMonth.date.get("month") + 1; // Moment uses 0 based months
            // if its the current month then only get up to the current day
            if (
                month === now.month &&
                selectedMonth.date.get("year") === now.year
            ) {
                const { relatedRanges } = setupDateRangeValues(
                    storeSettings,
                    now,
                );
                ranges = relatedRanges.filter(
                    (range) => range.value.end > now.startOf("month"),
                );
            } else {
                // if they have selected a previous month then get the whole month worth of pay periods
                const endDateForRange = DateTime.fromObject({
                    year: selectedMonth.date.get("year"),
                    month,
                    day: 1,
                }).endOf("month");
                ranges = setupDateRangeValuesForFullMonthPeriod(
                    storeSettings,
                    endDateForRange,
                );
            }
            const promises = ranges.map(async (range) =>
                API.stores.getTimecards({
                    storeId: activeStore._id,
                    startDate: range.value.start.toJSDate().toString(),
                    endDate: range.value.end.toJSDate().toString(),
                }),
            );
            Promise.all(promises)
                .then((paySummaries) => {
                    const summariesToAdd = paySummaries.map((paySummary) =>
                        mapPaySummaryByDateRangeGQLtoTS(paySummary),
                    );
                    setPaySummaries(summariesToAdd);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        void fetchPayPeriodSummaries();
    }, [activeStore?._id, selectedMonth.date.toISOString()]);

    return {
        loading,
        paySummaries,
    };
};

export default usePaySummaries;
