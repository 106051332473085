import {
    useTable,
    useExpanded,
    useGlobalFilter,
    UseExpandedInstanceProps,
    UseGlobalFiltersInstanceProps,
    PluginHook,
    TableInstance,
    Renderer,
    UseTableColumnOptions,
    FooterProps,
    HeaderProps,
    CellProps,
    UseTableRowProps,
    UseExpandedRowProps,
    UseExpandedState,
    UseGlobalFiltersState,
    useFilters,
    UseFiltersState,
    UseFiltersInstanceProps,
    TableState,
    UseTableOptions,
    useAbsoluteLayout,
    useBlockLayout,
    useColumnOrder,
    UseColumnOrderInstanceProps,
    UseColumnOrderState,
    UseExpandedHooks,
    UseFiltersColumnOptions,
    UseFiltersColumnProps,
    UseTableColumnProps,
    useFlexLayout,
    useGridLayout,
    useGroupBy,
    UseGroupByInstanceProps,
    UseGroupByHooks,
    UseGroupByState,
    UseGroupByColumnOptions,
    UseGroupByColumnProps,
    UseGroupByRowProps,
    usePagination,
    UsePaginationInstanceProps,
    UsePaginationState,
    UsePaginationOptions,
    useResizeColumns,
    UseResizeColumnsState,
    UseResizeColumnsColumnProps,
    UseResizeColumnsOptions,
    UseResizeColumnsColumnOptions,
    useRowSelect,
    UseRowSelectInstanceProps,
    UseRowSelectHooks,
    UseRowSelectRowProps,
    UseRowSelectState,
    UseRowSelectOptions,
    useRowState,
    UseRowStateInstanceProps,
    UseRowStateRowProps,
    UseRowStateState,
    UseGroupByCellProps,
    UseRowStateCellProps,
    useSortBy,
    UseSortByInstanceProps,
    UseSortByHooks,
    UseSortByState,
    UseSortByColumnOptions,
    UseSortByColumnProps,
    UseSortByOptions,
    UseRowStateOptions,
    UseGlobalFiltersOptions,
    IdType,
} from "react-table-7";
import {
    IterableElement,
    UnionToIntersection,
    OverrideProperties,
} from "type-fest";

type Plugin_InstanceProps<
    Plugin extends PluginHook<Data>,
    Data extends object,
    All_Plugins extends PluginHook<Data>,
> = Plugin extends typeof useExpanded
    ? UseExpandedInstanceProps<Data>
    : Plugin extends typeof useGlobalFilter
      ? UseGlobalFiltersInstanceProps<Data>
      : Plugin extends typeof useFilters
        ? OverrideProperties<
              UseFiltersInstanceProps<Data>,
              {
                  preFilteredRows: UseTable7TypedRowProps<All_Plugins, Data>[];
                  preFilteredFlatRows: UseTable7TypedRowProps<
                      All_Plugins,
                      Data
                  >[];
                  preFilteredRowsById: Record<
                      string,
                      UseTable7TypedRowProps<All_Plugins, Data>
                  >;
                  filteredRows: UseTable7TypedRowProps<All_Plugins, Data>[];
                  filteredFlatRows: UseTable7TypedRowProps<All_Plugins, Data>[];
                  filteredRowsById: Record<
                      string,
                      UseTable7TypedRowProps<All_Plugins, Data>
                  >;
                  rows: UseTable7TypedRowProps<All_Plugins, Data>[];
                  flatRows: UseTable7TypedRowProps<All_Plugins, Data>[];
                  rowsById: Record<
                      string,
                      UseTable7TypedRowProps<All_Plugins, Data>
                  >;
              }
          >
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? UseColumnOrderInstanceProps<Data>
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByInstanceProps<Data>
                    : Plugin extends typeof usePagination
                      ? UsePaginationInstanceProps<Data>
                      : Plugin extends typeof useResizeColumns
                        ? {}
                        : Plugin extends typeof useRowSelect
                          ? UseRowSelectInstanceProps<Data>
                          : Plugin extends typeof useRowState
                            ? UseRowStateInstanceProps<Data>
                            : Plugin extends typeof useSortBy
                              ? UseSortByInstanceProps<Data>
                              : never;

type Plugin_Hooks<
    Plugin extends PluginHook<Data>,
    Data extends object,
> = Plugin extends typeof useExpanded
    ? UseExpandedHooks<Data>
    : Plugin extends typeof useGlobalFilter
      ? {}
      : Plugin extends typeof useFilters
        ? {}
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? {}
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByHooks<Data>
                    : Plugin extends typeof usePagination
                      ? {}
                      : Plugin extends typeof useResizeColumns
                        ? {}
                        : Plugin extends typeof useRowSelect
                          ? UseRowSelectHooks<Data>
                          : Plugin extends typeof useRowState
                            ? {}
                            : Plugin extends typeof useSortBy
                              ? UseSortByHooks<Data>
                              : never;

type Plugin_RowProps<
    Plugin extends PluginHook<Data>,
    Data extends object,
> = Plugin extends typeof useExpanded
    ? UseExpandedRowProps<Data>
    : Plugin extends typeof useGlobalFilter
      ? {}
      : Plugin extends typeof useFilters
        ? {}
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? {}
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByRowProps<Data>
                    : Plugin extends typeof usePagination
                      ? {}
                      : Plugin extends typeof useResizeColumns
                        ? {}
                        : Plugin extends typeof useRowSelect
                          ? UseRowSelectRowProps<Data>
                          : Plugin extends typeof useRowState
                            ? UseRowStateRowProps<Data>
                            : Plugin extends typeof useSortBy
                              ? {}
                              : never;

type Plugin_State<
    Plugin extends PluginHook<Data>,
    Data extends object,
> = Plugin extends typeof useExpanded
    ? UseExpandedState<Data>
    : Plugin extends typeof useGlobalFilter
      ? UseGlobalFiltersState<Data>
      : Plugin extends typeof useFilters
        ? UseFiltersState<Data>
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? UseColumnOrderState<Data>
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByState<Data>
                    : Plugin extends typeof usePagination
                      ? UsePaginationState<Data>
                      : Plugin extends typeof useResizeColumns
                        ? UseResizeColumnsState<Data>
                        : Plugin extends typeof useRowSelect
                          ? UseRowSelectState<Data>
                          : Plugin extends typeof useRowState
                            ? UseRowStateState<Data>
                            : Plugin extends typeof useSortBy
                              ? UseSortByState<Data>
                              : never;

type Plugin_ColumnOptions<
    Plugin extends PluginHook<Data>,
    Data extends object,
> = Plugin extends typeof useExpanded
    ? {}
    : Plugin extends typeof useGlobalFilter
      ? {}
      : Plugin extends typeof useFilters
        ? UseFiltersColumnOptions<Data>
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? {}
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByColumnOptions<Data>
                    : Plugin extends typeof usePagination
                      ? {}
                      : Plugin extends typeof useResizeColumns
                        ? UseResizeColumnsColumnOptions<Data>
                        : Plugin extends typeof useRowSelect
                          ? {}
                          : Plugin extends typeof useRowState
                            ? {}
                            : Plugin extends typeof useSortBy
                              ? UseSortByColumnOptions<Data>
                              : never;

type Plugin_ColumnProps<
    Plugin extends PluginHook<Data>,
    Data extends object,
    All_Plugins extends PluginHook<Data>,
> = Plugin extends typeof useExpanded
    ? {}
    : Plugin extends typeof useGlobalFilter
      ? {}
      : Plugin extends typeof useFilters
        ? OverrideProperties<
              UseFiltersColumnProps<Data>,
              {
                  preFilteredRows: Array<
                      UseTable7TypedRowProps<All_Plugins, Data>
                  >;
                  filteredRows: Array<
                      UseTable7TypedRowProps<All_Plugins, Data>
                  >;
              }
          >
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? {}
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByColumnProps<Data>
                    : Plugin extends typeof usePagination
                      ? {}
                      : Plugin extends typeof useResizeColumns
                        ? UseResizeColumnsColumnProps<Data>
                        : Plugin extends typeof useRowSelect
                          ? {}
                          : Plugin extends typeof useRowState
                            ? {}
                            : Plugin extends typeof useSortBy
                              ? UseSortByColumnProps<Data>
                              : never;

type Plugin_CellProps<
    Plugin extends PluginHook<Data>,
    Data extends object,
> = Plugin extends typeof useExpanded
    ? {}
    : Plugin extends typeof useGlobalFilter
      ? {}
      : Plugin extends typeof useFilters
        ? {}
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? {}
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? UseGroupByCellProps<Data>
                    : Plugin extends typeof usePagination
                      ? {}
                      : Plugin extends typeof useResizeColumns
                        ? {}
                        : Plugin extends typeof useRowSelect
                          ? {}
                          : Plugin extends typeof useRowState
                            ? UseRowStateCellProps<Data>
                            : Plugin extends typeof useSortBy
                              ? {}
                              : never;

type Plugin_Options<
    Plugin extends PluginHook<Data>,
    Data extends object,
    All_Plugins extends PluginHook<Data>,
> = Plugin extends typeof useExpanded
    ? {}
    : Plugin extends typeof useGlobalFilter
      ? OverrideProperties<
            UseGlobalFiltersOptions<Data>,
            {
                globalFilter:
                    | ((
                          rows: UseTable7TypedRowProps<All_Plugins, Data>[],
                          columnIds: IdType<Data>[],
                          filterValue: any,
                      ) => UseTable7TypedRowProps<All_Plugins, Data>[])
                    | string;
            }
        >
      : Plugin extends typeof useFilters
        ? {}
        : Plugin extends typeof useAbsoluteLayout
          ? {}
          : Plugin extends typeof useBlockLayout
            ? {}
            : Plugin extends typeof useColumnOrder
              ? {}
              : Plugin extends typeof useFlexLayout
                ? {}
                : Plugin extends typeof useGridLayout
                  ? {}
                  : Plugin extends typeof useGroupBy
                    ? {}
                    : Plugin extends typeof usePagination
                      ? UsePaginationOptions<Data>
                      : Plugin extends typeof useResizeColumns
                        ? UseResizeColumnsOptions<Data>
                        : Plugin extends typeof useRowSelect
                          ? UseRowSelectOptions<Data>
                          : Plugin extends typeof useRowState
                            ? UseRowStateOptions<Data>
                            : Plugin extends typeof useSortBy
                              ? UseSortByOptions<Data>
                              : never;

export type UseTable7TypedRowProps<
    Plugins extends PluginHook<Data>,
    Data extends object,
> = UseTableRowProps<Data> &
    UnionToIntersection<Plugin_RowProps<Plugins, Data>>;

type OmitRowAndColumn<T> = Omit<T, "row" | "column">;

export type UseTable7TypedCellProps<
    Plugins extends PluginHook<Data>,
    Data extends object,
> = OmitRowAndColumn<CellProps<Data>> &
    OmitRowAndColumn<
        UnionToIntersection<Plugin_InstanceProps<Plugins, Data, Plugins>>
    > &
    OmitRowAndColumn<UnionToIntersection<Plugin_Hooks<Plugins, Data>>> &
    OmitRowAndColumn<UnionToIntersection<Plugin_CellProps<Plugins, Data>>> & {
        row: UseTableRowProps<Data> &
            UnionToIntersection<Plugin_RowProps<Plugins, Data>>;
        column: UseTableColumnProps<Data> &
            UnionToIntersection<Plugin_ColumnProps<Plugins, Data, Plugins>>;
    };

export type UseTable7TypedColumnOptions<
    Plugins extends PluginHook<Data>,
    Data extends object,
> = UnionToIntersection<Plugin_ColumnOptions<Plugins, Data>> &
    Omit<UseTableColumnOptions<Data>, "Header" | "Footer" | "Cell"> & {
        accessor?: keyof Data;
        Header?: Renderer<
            HeaderProps<Data> &
                UnionToIntersection<
                    Plugin_InstanceProps<Plugins, Data, Plugins>
                >
        >;
        Footer?: Renderer<
            FooterProps<Data> &
                UnionToIntersection<
                    Plugin_InstanceProps<Plugins, Data, Plugins>
                >
        >;
        Cell?: Renderer<UseTable7TypedCellProps<Plugins, Data>>;
    };

export type UseTable7TypedOptions<
    Plugins extends PluginHook<Data>,
    Data extends object,
> = Omit<UseTableOptions<Data>, "columns" | "state" | "initialState"> &
    UnionToIntersection<Plugin_Options<Plugins, Data, Plugins>> & {
        columns: UseTable7TypedColumnOptions<Plugins, Data>[];
        initialState?: Partial<
            TableState<Data> & UnionToIntersection<Plugin_State<Plugins, Data>>
        >;
    };

type OmitStateAndRows<T> = Omit<T, "state" | "rows">;

export function useTable7Typed<
    Data extends object,
    Plugins extends PluginHook<Data>[],
>(
    options: UseTable7TypedOptions<IterableElement<Plugins>, Data>,
    ...hook: Plugins
): OmitStateAndRows<TableInstance<Data>> &
    OmitStateAndRows<
        UnionToIntersection<
            Plugin_InstanceProps<
                IterableElement<Plugins>,
                Data,
                IterableElement<Plugins>
            >
        >
    > & {
        state: TableState<Data> &
            UnionToIntersection<Plugin_State<IterableElement<Plugins>, Data>>;
        rows: Array<
            UseTableRowProps<Data> &
                UnionToIntersection<
                    Plugin_RowProps<IterableElement<Plugins>, Data>
                >
        >;
    } {
    return useTable<Data>(options as any, ...hook) as any;
}
