import { SystemColors } from "@snackpass/design-system";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    ConversationVariable,
    getConversationInputVariables,
    getMessageInputVariables,
    MessageVariable,
    removeConversationInputVariable,
    removeMessageInputVariable,
    TemplateVariableType,
    updateConversationInputVariableData,
    updateMessageInputVariableData,
} from "../../redux";

import "draft-js/dist/Draft.css";
import { compose, propOr } from "lodash/fp";
import styled from "@emotion/styled";

import CloseX from "src/assets/icons/close-x.svg";
import PromoWhite from "src/assets/icons/promo-white.png";
import { GuestbookInputType } from "#guestbook/components/message-input/libs";

export const SelectedGifts = ({
    inputType,
}: {
    inputType: GuestbookInputType;
}) => {
    const variables = useSelector(
        inputType === GuestbookInputType.MESSSAGE
            ? getMessageInputVariables
            : getConversationInputVariables,
    );

    const rewardVariables = useMemo(
        () => variables.filter(isReward) || null,
        [variables],
    );

    if (!rewardVariables.length) {
        return null;
    }

    return (
        <div>
            {rewardVariables.map((rv, index) => (
                <RewardVariable
                    rewardVariable={rv}
                    key={index}
                    inputType={inputType}
                />
            ))}
        </div>
    );
};

const isReward = (v: MessageVariable | ConversationVariable) =>
    v.type === TemplateVariableType.Reward && !!v.data;

const RewardVariable = ({
    rewardVariable,
    inputType,
}: {
    rewardVariable: MessageVariable | ConversationVariable;
    inputType: GuestbookInputType;
}) => {
    const dispatch = useDispatch();
    const _removeVariable =
        inputType === GuestbookInputType.MESSSAGE
            ? compose(dispatch, removeMessageInputVariable)
            : compose(dispatch, removeConversationInputVariable);

    const _clearVariableData =
        inputType === GuestbookInputType.MESSSAGE
            ? compose(dispatch, updateMessageInputVariableData)
            : compose(dispatch, updateConversationInputVariableData);

    const _onRemove = useCallback(() => {
        if (!rewardVariable) {
            return;
        }

        // if the reward is invisible, aka it is appended to the message
        // and not part of the template, just remove it completely
        if (rewardVariable.isInvisible) {
            return _removeVariable({ id: rewardVariable.id });
        }

        // otherwise clear the variable's data
        _clearVariableData({
            id: rewardVariable?.id,
            data: null,
        });
    }, [rewardVariable]);

    // pick an image url from a product
    const imageUrl = _getImageUrl(rewardVariable);
    const expiresIn = _getExpiresInDays(rewardVariable);
    const discountText = _getDiscountText(rewardVariable);

    if (!rewardVariable.data) {
        return null;
    }

    return (
        <Container>
            <TextContainer>
                <GiftIcon imageUrl={imageUrl} />

                <PromoDetailContainer>
                    <GiftLabel>{rewardVariable?.data?.label || "--"}</GiftLabel>

                    <div className="expiration-time">
                        Expires in {expiresIn} days
                    </div>
                </PromoDetailContainer>

                <DiscountText>{discountText}</DiscountText>
            </TextContainer>
            <RemoveButton onRemove={_onRemove} />
        </Container>
    );
};

const RemoveButton = ({ onRemove }: { onRemove: () => void }) => (
    <div
        style={{
            fontWeight: "bold",
            paddingRight: 15,
            cursor: "pointer",
            color: SystemColors.v1.black,
        }}
        onClick={onRemove}
    >
        <img
            src={CloseX}
            style={{ width: 20, height: 20, objectFit: "contain" }}
            className="icon"
        />
    </div>
);

const GiftIcon = ({ imageUrl }: { imageUrl: string | null }) => {
    if (imageUrl) {
        return <GiftIconImage src={imageUrl} />;
    }

    return (
        <GiftIconNoImageContainer>
            <img
                src={PromoWhite}
                style={{
                    width: 40,
                    height: 40,
                    objectFit: "contain",
                }}
            />
        </GiftIconNoImageContainer>
    );
};

// Note: atm all the discounts are percent off, may change in the future
// though
const _getDiscountText = (
    rewardVariable: MessageVariable | ConversationVariable,
) => {
    if (!rewardVariable.data || rewardVariable.data?.type !== "discount") {
        return null;
    }

    const discountPercent = rewardVariable.data?.template?.discount?.percentOff;

    return discountPercent + "% off";
};

const _getExpiresInDays = (
    rewardVariable: MessageVariable | ConversationVariable,
) =>
    rewardVariable?.data?.type === "discount"
        ? rewardVariable.data.template.expiresInDays
        : 0;

const _getImageUrl = (
    rewardVariable: MessageVariable | ConversationVariable,
) => {
    if (!rewardVariable) {
        return null;
    }

    if (rewardVariable.data?.type === "discount") {
        return propOr(
            null,
            "template.products[0].imageUrl",
            rewardVariable.data,
        );
    }

    return null;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${SystemColors.v1.white};
`;

const TextContainer = styled.div`
    display: flex;
    padding: 15px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
`;

const PromoDetailContainer = styled.div`
    flex: 1;
    padding-top: 10px;

    .expiration-time {
        font-size: 16;
        color: ${SystemColors.v1.gray30};
        white-space: "nowrap";
    }
`;

const GiftIconNoImageContainer = styled.div`
    background: ${SystemColors.v1.parsley50};
    width: 70px;
    border-radius: 10px;
    height: 70px;
    margin-right: 15px;
    text-align: center;
    padding-top: 15px;
`;

const GiftIconImage = styled.img`
    width: 70px;
    border-radius: 10px;
    height: 70px;
    margin-right: 15px;
    object-fit: cover;
`;

const GiftLabel = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${SystemColors.v1.black};
    margin-bottom: 0;
    white-space: "nowrap";
`;

const DiscountText = styled.div`
    font-weight: bold;
    font-size: 16px;
    padding: 10px 15px 0px 0px;
    color: ${SystemColors.v1.parsley50};
`;
