import React from "react";
import { useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";

import { getActiveStore } from "src/redux/selectors";
import { CustomerPortal } from "#billing/customer-portal";
import { Wrapper } from "#billing/components/Wrapper";
import { descriptions } from "#billing/utils/descriptions";

import theme from "./utils/theme";

const BillingScreen = () => {
    const activeStore = useSelector(getActiveStore);
    return !activeStore?._id ? (
        <Container>{descriptions.loading}</Container>
    ) : (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <CustomerPortal />
            </Wrapper>
        </ThemeProvider>
    );
};

export default BillingScreen;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 12px;
    height: 100%;

    @media ${ScreenState.DESKTOP} {
        margin: 24px 48px;
    }
`;
