import { Canvas } from "fabric";

import { EditorAction, EditorState } from "#table-editor/store";

import { drawGrid } from "./drawGrid";
import { withSnapToGrid } from "./withSnapToGrid";
import { withIsCanvasDirty } from "./withIsCanvasDirty";
import { withElementTextScaling } from "./withElementTextScaling";
import { withDisableObjectControls } from "./withDisableObjectControls";

const DEFAULT_GRID_SIZE = 30;

const SNAP_DEFAULT = {
    gridSize: DEFAULT_GRID_SIZE,
    fraction: 1,
};

type WithCanvasDefaultsOptions = {
    gridSize?: number;
    size: { width: number; height: number };
    snap?: { gridSize?: number; fraction?: number };
};

export function withCanvasDefaults(
    canvas: Canvas,
    editor: () => EditorState & EditorAction,
    options: WithCanvasDefaultsOptions,
) {
    const textScaling = withElementTextScaling(canvas);
    const isDirty = withIsCanvasDirty(canvas, editor);
    const objectRotation = withDisableObjectControls(canvas);

    drawGrid(
        canvas,
        options.size.width,
        options.size.height,
        options.gridSize ?? DEFAULT_GRID_SIZE,
    );

    const snapConfig = {
        ...SNAP_DEFAULT,
        ...(options.snap ? options.snap : {}),
    };

    let snapToGrid: (() => void) | undefined;

    if (options.snap) {
        snapToGrid = withSnapToGrid(
            canvas,
            snapConfig.gridSize,
            snapConfig.fraction,
        );
    }

    return () => {
        textScaling();
        isDirty();
        objectRotation();
        snapToGrid?.();
    };
}
