import React from "react";
import { useSelector } from "react-redux";

import { getActiveStore } from "src/redux/selectors";

import { StoreQrCodeSection } from "./store-qr-code-section";
import { TableQrCodeSection } from "./table-qr-code-section";

const QrCodeScreen = () => {
    const activeStore = useSelector(getActiveStore);
    return (
        <div className="p-6 md:px-24">
            <div className="mb-2 text-heading font-semibold">QR Codes</div>
            <div className="mb-6 text-neutral-600">
                Manage QR Codes for online ordering and dine-in tables
            </div>

            <div className="flex flex-col space-y-6">
                <StoreQrCodeSection />
                <TableQrCodeSection />
            </div>
        </div>
    );
};

export default QrCodeScreen;
