import { PropsWithChildren } from "react";

import { EditorCanvas } from "./EditorCanvas";

type EditorProps = PropsWithChildren<{
    grid: {
        size?: number;
        snap?: { gridSize?: number; fraction?: number };
    };
}>;

export function Editor({ grid, children }: EditorProps) {
    return <EditorCanvas grid={grid}>{children}</EditorCanvas>;
}
