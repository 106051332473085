import { Alert, Button } from "antd";
import { useConfig } from "statsig-react";
import styled from "styled-components";
import { match } from "ts-pattern";

const wrapText = (t = "") => (t.length >= 68 ? "column" : "row");

const Base = styled(Alert)<{ description?: string }>`
    border-radius: ${({ theme }) => theme.spacing.half};
    margin-bottom: ${({ theme }) => theme.spacing.base};

    &.ant-alert-with-description {
        align-items: center;
    }

    .ant-alert-content {
        display: flex;
        flex-direction: ${({ description }) => wrapText(description)};
        flex: 1;
        width: 100%;
    }

    .ant-alert-message,
    .ant-alert-description {
        color: ${({ type }) =>
            match(type)
                .with("info", () => "#0099ff")
                .with("warning", () => "#b85c00")
                .with("error", () => "#b80415")
                .otherwise(() => "black")};
        font-family: Inter;
        line-height: auto;
        font-size: 16px;
        font-weight: 600;
        margin: auto 0;
    }

    .ant-alert-description {
        margin-left: ${({ theme, description }) =>
            match(wrapText(description))
                .with("column", () => "0")
                .otherwise(() => theme.spacing.half)};
        font-weight: 400;
        font-size: 14px;
        align-items: center;
    }
`;

const _Button = styled(Button)`
    border-radius: ${({ theme }) => theme.spacing.half};
`;

export const Banner = () => {
    const { config: taxFormStatus } = useConfig("2023_1099k_status");
    const status = taxFormStatus.get<
        "not_applicable" | "no_w9" | "ineligible" | "eligible"
    >("status", "not_applicable");

    if (!["ineligible", "no_w9"].includes(status)) {
        return null;
    }

    const description =
        status === "ineligible" ? (
            "Our records indicate that your business entity did not meet the IRS threshold needed for issuance of a 1099-K in 2023."
        ) : (
            <>
                In order to generate a 1099-K, we need additional business
                entity information. If you believe you met the 2023 threshold of
                $20,000 and over 200 transactions based on a single TIN, Please
                reach out to{" "}
                <a href="mailto:partners@snackpass.co">partners@snackpass.co</a>{" "}
                or call <a href="tel:+1 (866) 868-2146">+1 (866) 868-2146</a>.
            </>
        );

    return (
        <Base
            description={description}
            action={
                <_Button
                    type="primary"
                    href="https://www.irs.gov/businesses/understanding-your-form-1099-k"
                    target="_blank"
                >
                    Learn More
                </_Button>
            }
            type="info"
        />
    );
};
