/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useContext, useMemo, useState } from "react";
import {
    Addon,
    InventoryUnitType,
    IProduct,
    ScreenState,
} from "@snackpass/snackpass-types";
import { useDrag, useDrop } from "react-dnd";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import styled from "styled-components";
import _, { debounce, differenceWith } from "lodash";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from "react-swipeable-list";

import "react-swipeable-list/dist/styles.css";
import { useSelector } from "react-redux";
import { compose, map, sortBy } from "lodash/fp";
import { Button, Dropdown, InputNumber, Menu } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Column, useTable, UseTableRowProps } from "react-table-7";
import { SystemColors } from "@snackpass/design-system";
import produce from "immer";
import { toast } from "sonner";

import colors from "#reusable/colors/colors.json";
import { DropdownOption } from "#core";
import IngredientsModal from "#menu-editor/mobile-friendly/items/ingredients-modal";
import {
    getActiveStore,
    getActiveStoreIs3PIntegrated,
    getInventoryItems,
} from "src/redux/selectors";
import { getStoreTimezone } from "#utils/helpers";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { ReactComponent as Ingredient } from "src/assets/icons/ingredient.svg";
import { ReactComponent as OutOfStock } from "src/assets/icons/mark-out-of-stock.svg";
import { ReactComponent as InStockIcon } from "src/assets/icons/mark-in-stock.svg";
import { ReactComponent as Duplicate } from "src/assets/icons/duplicate.svg";
import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import { ReactComponent as DragDots } from "src/assets/icons/drag-dots.svg";
import {
    hasDuplicates,
    isServerGeneratedId,
} from "#menu-editor/mobile-friendly/helpers/utils";
import { InventoryItem } from "src/redux/slices";
import { MenuTopLevelContext } from "#menu-editor/mobile-friendly/helpers/context";

import SoldOutModal from "../sold-out-modal";

const MOBILE_BREAKPOINT = 768;

type TableRowHTMLAttributes = React.HTMLAttributes<HTMLTableRowElement>;

type TableRowProps = TableRowHTMLAttributes & {
    children: React.ReactNode;
    row: UseTableRowProps<Addon>;
    index: number;
    onMove?: Function;
    addons: Addon[];
    setAddons: React.Dispatch<SetStateAction<Addon[]>>;
    setDeletedAddonIds: React.Dispatch<SetStateAction<string[]>>;
    handleAddOnReorder: (value: Addon[]) => void;
    productInEdit: IProduct | null;
};

const TableRow: React.FC<TableRowProps> = ({
    children,
    row,
    index,
    onMove,
    addons,
    setAddons,
    setDeletedAddonIds,
    handleAddOnReorder,
    productInEdit,
    ...forwardedProps
}) => {
    // Create a row type out of the row depth and parent index
    // to restrict drag and drop to elements from the same depth + branch.
    const rowType = "row-modifier-option";
    const dropRef = React.useRef<HTMLTableRowElement>(null);
    const dragRef = React.useRef<HTMLTableRowElement>(null);

    //@ts-ignore
    const [__, drop] = useDrop({
        accept: rowType,
        hover: debounce((item, monitor) => {
            if (!dropRef.current) {
                return;
            }
            // global table indeces
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            if (!clientOffset) return;
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            if (onMove) {
                onMove({ dragIndex, hoverIndex, row });
            }
            item.index = hoverIndex;
        }, 100),
        collect: (monitor) => ({
            canAccept: monitor.getItemType() === rowType,
            isOver: monitor.isOver(),
        }),
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: rowType,
        item: { type: rowType, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => handleAddOnReorder(addons),
    });
    React.useEffect(() => {
        preview(drop(dropRef));
        drag(dragRef);
    }, [dragRef, dropRef]);

    const { width } = useWindowDimensions();
    const [name, setName] = useState<string>(row.original.name);
    const [price, setPrice] = useState<number>(row.original.price);
    const [isSoldOut, setIsSoldOut] = useState<boolean>(
        row.original.soldOutDates && row.original.soldOut,
    );
    const activeStore = useSelector(getActiveStore);
    const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);
    const [isMarkAddonSoldOutModalOpen, setIsMarkAddonSoldOutModalOpen] =
        useState(false);
    const [isIngredientsModalOpen, setIsIngredientsModalOpen] = useState(false);
    const timezone = getStoreTimezone(activeStore);
    const allItems = useSelector(getInventoryItems);
    const [formattedOptions, setFormattedOptions] = useState<
        DropdownOption<InventoryItem>[]
    >([]);

    const matchingModifier = useMemo(
        () =>
            productInEdit?.addonGroups
                .flatMap((e) => e.addons)
                .find((e) => e._id === row.original._id),
        [productInEdit, row],
    );

    // We don't want to allow editing sold out status while changing an name, because this can desync the deliverect integration's sold out status'.
    // We also don't want to allow editing sold out before the addon has been submitted, because this will also cause a sold out desync.
    const disableStockChange =
        !matchingModifier || name !== matchingModifier.name;
    const disableNameChange =
        !!matchingModifier?.soldOut !== isSoldOut ||
        (matchingModifier?.soldOutDates?.from ?? null) !==
            row.original.soldOutDates.from ||
        (matchingModifier?.soldOutDates?.until ?? null) !==
            row.original.soldOutDates.until;

    useMemo(() => {
        const activeAddon = addons.filter(
            (addon) => addon._id === row.original._id,
        )[0];

        const options = differenceWith(
            allItems,
            activeAddon.inventoryItems ?? [],
            (a, b) => a.id === b.inventoryItemId,
        );

        const sortedInventory = compose(
            map((item: InventoryItem) => ({
                label: item.name,
                value: item.id,
                data: item,
            })),
            sortBy("name"),
        )(options);

        setFormattedOptions(sortedInventory);
    }, [allItems]);

    const handlePressSubmit = (newModifierOptionName: string) => {
        setName(newModifierOptionName);
        const newAddons = addons.map((addon) => {
            if (addon._id === row.original._id) {
                return { ...addon, name: newModifierOptionName };
            }
            return addon;
        });
        setAddons(newAddons);
    };

    const handlePricePressSubmit = (newModifierOptionPrice: number) => {
        setPrice(newModifierOptionPrice);
        const newAddons = addons.map((addon) => {
            if (addon._id === row.original._id) {
                return { ...addon, price: newModifierOptionPrice };
            }
            return addon;
        });
        setAddons(newAddons);
    };

    const addOnIngredients = () => {
        const activeAddon = addons.filter(
            (addon) => addon._id === row.original._id,
        )[0];

        return activeAddon.inventoryItems ?? [];
    };

    const handleAddIngredients = (
        newIngredient: DropdownOption<InventoryItem>,
    ) => {
        const formatIngredient = {
            inventoryItemId: newIngredient.value,
            unitsConsumed: {
                amount: 0,
                unit: (newIngredient.data?.baseUnit as InventoryUnitType) ?? "",
                precision: 3,
            },
        };
        const newAddons = addons.map((addon) => {
            if (addon._id === row.original._id) {
                const newInventoryItems = addon?.inventoryItems
                    ? addon?.inventoryItems.slice()
                    : [];
                newInventoryItems.push(formatIngredient);
                return {
                    ...addon,
                    inventoryItems: newInventoryItems,
                };
            }

            return addon;
        });
        setAddons(newAddons);
    };
    const handleUpdateIngredients = (
        ingredientId: string,
        newPerUnit: number,
    ) => {
        const newAddons = addons.map((addon) => {
            if (addon._id === row.original._id) {
                const newInventoryItems = addon.inventoryItems?.map((item) => {
                    if (item.inventoryItemId === ingredientId) {
                        return {
                            inventoryItemId: item.inventoryItemId,
                            unitsConsumed: {
                                ...item.unitsConsumed,
                                amount: newPerUnit,
                            },
                        };
                    }
                    return item;
                });
                return {
                    ...addon,
                    inventoryItems: newInventoryItems,
                };
            }
            return addon;
        });
        setAddons(newAddons);
    };
    const handleRemoveIngredients = (ingredientId: string) => {
        const newAddons = addons.map((addon) => {
            if (addon._id === row.original._id) {
                const newInventoryItems = addon.inventoryItems?.filter(
                    (item) => item.inventoryItemId !== ingredientId,
                );
                return {
                    ...addon,
                    inventoryItems: newInventoryItems,
                };
            }
            return addon;
        });
        setAddons(newAddons);
    };
    const handleSoldOut = (
        soldOutProps: Pick<Addon, "soldOut" | "soldOutDates">,
    ) => {
        setAddons(
            addons.map((addon) => {
                if (addon._id === row.original._id) {
                    return {
                        ...addon,
                        ...soldOutProps,
                    };
                }
                return addon;
            }),
        );
        setIsSoldOut(true);
    };

    const handleInStock = () => {
        const newAddons = addons.map((addon) => {
            if (addon._id === row.original._id) {
                return {
                    ...addon,
                    soldOutDates: { from: null, until: null },
                    soldOut: false,
                };
            }
            return addon;
        });
        setAddons(newAddons);
        setIsSoldOut(false);
    };

    const actionMenu = (
        <Menu>
            <Menu.Item
                key="action-menus1"
                onClick={() => {
                    const newAddon: Addon = {
                        name: name,
                        price: price,
                        _id: Date.now().toString(),
                        soldOut: false,
                        soldOutDates: {
                            from: null,
                            until: null,
                        },
                        soldOutToday: false,
                    };
                    setAddons([...addons, newAddon]);
                }}
            >
                <span className="action-menus">Duplicate</span>
            </Menu.Item>
            <Menu.Item key="action-menus2" disabled={disableStockChange}>
                {isSoldOut ? (
                    <span
                        className="action-menus"
                        onClick={() => {
                            if (!disableStockChange) handleInStock();
                            else
                                toast.error(
                                    "Please save edits to this product before editing sold out status.",
                                );
                        }}
                    >
                        Set as In Stock
                    </span>
                ) : (
                    <span
                        className="action-menus"
                        onClick={() => {
                            if (!disableStockChange)
                                setIsMarkAddonSoldOutModalOpen(true);
                            else
                                toast.error(
                                    "Please save edits to this product before editing sold out status.",
                                );
                        }}
                    >
                        Set as Sold Out
                    </span>
                )}
            </Menu.Item>
            <Menu.Item
                key="action-menus3"
                onClick={() => {
                    setIsIngredientsModalOpen(true);
                }}
            >
                <span className="action-menus">Ingredients</span>
            </Menu.Item>
        </Menu>
    );

    const leadingActions = () => (
        <LeadingActions>
            <SwipeAction
                onClick={() => {
                    const newAddon: Addon = {
                        name: name,
                        price: price,
                        _id: Date.now().toString(),
                        soldOut: false,
                        soldOutDates: {
                            from: null,
                            until: null,
                        },
                        soldOutToday: false,
                    };
                    setAddons([...addons, newAddon]);
                }}
            >
                <ActionContent
                    style={{
                        backgroundColor: SystemColors.v2.candy50.light,
                        width: "4rem",
                        justifyContent: "center",
                    }}
                >
                    <ItemColumnCentered>
                        <Duplicate className="swipe-icon" />
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
            <SwipeAction onClick={() => {}}>
                <ActionContent
                    style={{
                        backgroundColor: SystemColors.v2.salt100.light,
                        width: "4rem",
                        justifyContent: "center",
                    }}
                >
                    <ItemColumnCentered>
                        <Ingredient
                            className="swipe-icon"
                            onClick={() => {
                                setIsIngredientsModalOpen(true);
                            }}
                        />
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
        </LeadingActions>
    );

    const trailingActions = () => (
        <TrailingActions>
            <SwipeAction onClick={() => {}}>
                <ActionContent
                    style={{
                        backgroundColor: disableStockChange
                            ? colors["neutral-500"]
                            : SystemColors.v2.blueberry50.light,
                        width: "4rem",
                        justifyContent: "center",
                    }}
                >
                    <ItemColumnCentered>
                        {isSoldOut ? (
                            <OutOfStock
                                className="swipe-icon"
                                onClick={() => {
                                    if (!disableStockChange) handleInStock();
                                    else
                                        toast.error(
                                            "Please save edits to this product before editing sold out status.",
                                        );
                                }}
                            />
                        ) : (
                            <InStockIcon
                                className="swipe-icon"
                                onClick={() => {
                                    if (!disableStockChange)
                                        setIsMarkAddonSoldOutModalOpen(true);
                                    else
                                        toast.error(
                                            "Please save edits to this product before editing sold out status.",
                                        );
                                }}
                            />
                        )}
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
            <SwipeAction
                onClick={() => {
                    const newAddons = addons.filter(
                        (addon) => addon._id !== row.original._id,
                    );
                    setAddons(newAddons);
                }}
            >
                <ActionContent
                    style={{
                        backgroundColor: SystemColors.v2.melon50.light,
                        width: "4rem",
                        justifyContent: "center",
                    }}
                >
                    <ItemColumnCentered>
                        <Delete className="swipe-icon" />{" "}
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );
    return (
        <>
            <tr
                {...forwardedProps}
                ref={dropRef}
                className={classNames({
                    TableRow: true,
                    isDragging,
                    TableRowHeadingCell: true,
                })}
            >
                <span
                    className={classNames({
                        card: true,
                        isMobile: isMobile,
                    })}
                >
                    {isMobile || width <= MOBILE_BREAKPOINT ? (
                        <SwipeableList
                            threshold={0.5}
                            type={ListType.IOS}
                            key={row.original._id}
                        >
                            <SwipeableListItem
                                blockSwipe={thirdPartyEnabled}
                                leadingActions={leadingActions()}
                                trailingActions={trailingActions()}
                            >
                                <span className="modifier-mobile-container">
                                    {!thirdPartyEnabled && (
                                        <span
                                            ref={dragRef}
                                            className={classNames({
                                                TableRowHeadingCellDragHandler:
                                                    true,
                                                isDragging: isDragging,
                                            })}
                                        >
                                            <DragDots />
                                        </span>
                                    )}

                                    <TextArea
                                        size="small"
                                        placeholder="Item Name"
                                        className="modifier-option-name"
                                        style={{
                                            width: "100%",
                                            minHeight: 20,
                                            maxHeight: 200,
                                        }}
                                        autoSize
                                        value={name}
                                        disabled={
                                            thirdPartyEnabled ||
                                            disableNameChange
                                        }
                                        bordered={false}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLTextAreaElement>,
                                        ) => setName(e.target.value)}
                                        onBlur={(
                                            e: React.FocusEvent<HTMLTextAreaElement>,
                                        ) => {
                                            const val = (
                                                e.target as HTMLTextAreaElement
                                            ).value;

                                            handlePressSubmit(val);
                                        }}
                                        onKeyDown={(
                                            e: React.KeyboardEvent<HTMLTextAreaElement>,
                                        ) => {
                                            if (e.key === "Enter") {
                                                e.currentTarget.blur();
                                            }
                                        }}
                                    />

                                    <InputNumber
                                        className={classNames({
                                            modifierPrice: true,
                                        })}
                                        size="small"
                                        value={price}
                                        precision={2}
                                        min={0}
                                        formatter={(value) =>
                                            parseFloat(
                                                // @ts-expect-error value is incorrectly typed in antd as a number when it is actually a string
                                                value || 0,
                                            ).toLocaleString()
                                        }
                                        bordered={false}
                                        onChange={(e) => {
                                            setPrice(e || 0);
                                        }}
                                        onBlur={(
                                            e: React.FocusEvent<HTMLElement>,
                                        ) => {
                                            const val = (
                                                e.target as HTMLInputElement
                                            ).value;
                                            handlePricePressSubmit(
                                                parseFloat(
                                                    val.replaceAll(",", ""),
                                                ),
                                            );
                                        }}
                                        onKeyDown={(
                                            e: React.KeyboardEvent<HTMLInputElement>,
                                        ) => {
                                            if (e.key === "Enter") {
                                                e.currentTarget.blur();
                                            }
                                        }}
                                    />
                                    <span
                                        className={classNames({
                                            soldOutStatus: true,
                                        })}
                                    >
                                        {isSoldOut ? "SOLD OUT" : ""}
                                    </span>
                                    <span className="">{children}</span>
                                </span>
                            </SwipeableListItem>
                        </SwipeableList>
                    ) : (
                        <span className="modifier-desktop-container">
                            {!thirdPartyEnabled && (
                                <span
                                    ref={dragRef}
                                    className={classNames({
                                        TableRowHeadingCellDragHandler: true,
                                        isDragging: isDragging,
                                    })}
                                >
                                    <DragDots />
                                </span>
                            )}
                            <TextArea
                                disabled={
                                    thirdPartyEnabled || disableNameChange
                                }
                                size="small"
                                placeholder="Item Name"
                                className="modifier-option-name"
                                style={{
                                    width: "100%",
                                    minHeight: 20,
                                    maxHeight: 200,
                                }}
                                autoSize
                                value={name}
                                bordered={false}
                                onChange={(
                                    e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) => setName(e.target.value)}
                                onBlur={(
                                    e: React.FocusEvent<HTMLTextAreaElement>,
                                ) => {
                                    const val = (
                                        e.target as HTMLTextAreaElement
                                    ).value;

                                    handlePressSubmit(val);
                                }}
                            />
                            <span
                                className={classNames({
                                    soldOutStatus: true,
                                })}
                            >
                                {isSoldOut ? "SOLD OUT" : ""}
                            </span>
                            <InputNumber
                                disabled={thirdPartyEnabled}
                                className={classNames({
                                    modifierPrice: true,
                                })}
                                size="small"
                                value={price}
                                precision={2}
                                min={0}
                                formatter={(value) =>
                                    //@ts-expect-error value is incorrectly typed in antd as a number when it is actually a string
                                    parseFloat(value || 0).toLocaleString()
                                }
                                bordered={false}
                                onChange={(e) => {
                                    setPrice(e || 0);
                                }}
                                onBlur={(e: React.FocusEvent<HTMLElement>) => {
                                    const val = (e.target as HTMLInputElement)
                                        .value;
                                    handlePricePressSubmit(
                                        parseFloat(val.replaceAll(",", "")),
                                    );
                                }}
                                onKeyDown={(
                                    e: React.KeyboardEvent<HTMLInputElement>,
                                ) => {
                                    if (e.key === "Enter") {
                                        e.currentTarget.blur();
                                    }
                                }}
                            />
                            {!thirdPartyEnabled && (
                                <>
                                    <Dropdown
                                        overlay={actionMenu}
                                        placement="bottomRight"
                                        trigger={["click"]}
                                        className="modifier-option-actions"
                                        overlayStyle={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <Button
                                            shape="circle"
                                            ghost
                                            className="modifier-option-actions-button"
                                        >
                                            ...
                                        </Button>
                                    </Dropdown>
                                    <span className="TableRowHeadingCellButtonActions">
                                        <Delete
                                            className="actions delete-icon"
                                            onClick={() => {
                                                const newAddons = addons.filter(
                                                    (addon) =>
                                                        addon._id !==
                                                        row.original._id,
                                                );
                                                if (
                                                    !isServerGeneratedId(
                                                        row.original._id,
                                                    )
                                                )
                                                    setDeletedAddonIds(
                                                        (prev) => [
                                                            ...prev,
                                                            row.original
                                                                ._id as string,
                                                        ],
                                                    );
                                                setAddons(newAddons);
                                            }}
                                        />
                                    </span>
                                </>
                            )}
                            <span className="">{children}</span>
                        </span>
                    )}
                </span>
            </tr>
            <SoldOutModal
                onHide={() => setIsMarkAddonSoldOutModalOpen(false)}
                handleOk={(soldOutProps) => {
                    handleSoldOut(soldOutProps);
                    setIsSoldOut(true);
                    setIsMarkAddonSoldOutModalOpen(false);
                }}
                isMarkProductSoldOutModalOpen={isMarkAddonSoldOutModalOpen}
                storeTimeZone={timezone}
            />
            <IngredientsModal
                onHide={() => setIsIngredientsModalOpen(false)}
                handleOk={() => {
                    setIsIngredientsModalOpen(false);
                }}
                isIngredientsModalOpen={isIngredientsModalOpen}
                inventoryItems={formattedOptions}
                addOnIngredients={addOnIngredients()}
                handleAddIngredients={handleAddIngredients}
                handleUpdateIngredients={handleUpdateIngredients}
                handleRemoveIngredients={handleRemoveIngredients}
            />
        </>
    );
};

// Table

type TableProps = {
    columns: Column<Addon>[];
    addons: Addon[];
    setAddons: React.Dispatch<SetStateAction<Addon[]>>;
    setDeletedAddonIds: React.Dispatch<SetStateAction<string[]>>;
    children?: React.ReactNode;
    handleAddOnReorder: (value: Addon[]) => void;
};

export const Table = ({
    addons,
    columns,
    children,
    handleAddOnReorder,
    setAddons,
    setDeletedAddonIds,
}: TableProps) => {
    // Show duplicate modifierGroups/addonGroups if exists
    // create a map with the occurrence of each addons
    // if addon no duplicates(ideal), each addon 1 occurrence, otherwise the occurrence should be bigger than 1
    // we will subtract 1 while rendering each row, the idea here is to give every duplicate addon a unique occurrence number so that the react table can show it on the UI
    const occurrenceMap = _.countBy(addons, "_id");
    const getProductOccurrence = (id: string) => {
        occurrenceMap[id] = occurrenceMap[id] - 1;
        return occurrenceMap[id];
    };
    const uniqueId = (addon: Addon) =>
        `${addon._id} ${getProductOccurrence(addon._id)}`;
    const resolveRowId = (addon: Addon) => uniqueId(addon);

    const handleRowMove = React.useCallback(
        ({
            dragIndex,
            hoverIndex,
            row,
        }: {
            dragIndex: number;
            hoverIndex: number;
            row: UseTableRowProps<Addon>;
        }) => {
            const index = row?.index;
            const targetIndex = dragIndex > hoverIndex ? index + 1 : index - 1;
            const original = row?.original;

            setAddons((current) => {
                // remove the duplicate addons if exists
                if (hasDuplicates(current)) {
                    const removeAddonDuplicates = produce(
                        current,
                        (draft) => (draft = _.uniqBy(draft, "_id")),
                    );

                    return removeAddonDuplicates;
                }

                const result = produce(current, (draft) => {
                    draft.splice(index, 1);
                    draft.splice(targetIndex, 0, original);
                });
                // make sure addons have same length after update and the order changed, if not, return original state and don't update
                if (
                    !_.isEqual(current, result) &&
                    current.length === result.length &&
                    !hasDuplicates(result)
                ) {
                    return result;
                }
                return current;
            });
        },
        [],
    );
    const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
        columns,
        data: addons,
        getRowId: resolveRowId,
    });

    const { productInEdit } = useContext(MenuTopLevelContext);

    return (
        <StyleForDnd>
            <table {...getTableProps()}>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        const [cell, ...cells] = row.cells;
                        return (
                            <TableRow
                                {...row.getRowProps()}
                                handleAddOnReorder={handleAddOnReorder}
                                addons={addons}
                                setAddons={setAddons}
                                setDeletedAddonIds={setDeletedAddonIds}
                                index={index}
                                id={row.original._id}
                                row={row}
                                onMove={handleRowMove}
                                productInEdit={productInEdit}
                            >
                                {cells.map((cell: any, idx: number) => (
                                    <td
                                        key={
                                            "modifier-option" +
                                            cell.column.id +
                                            cell.column.name +
                                            idx
                                        }
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </TableRow>
                        );
                    })}
                    <tr className="TableRowHeadingCellChildren">{children}</tr>
                </tbody>
            </table>
        </StyleForDnd>
    );
};

const StyleForDnd = styled.div`
    table {
        border-collapse: collapse;
        width: 100%;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
    }
    .actions {
        cursor: pointer;
    }

    .delete-icon path {
        fill: ${SystemColors.v2.melon50.light} !important;
    }
    .duplicate-icon path {
        fill: ${SystemColors.v2.salt100.light};
    }

    thead th {
        display: none;
    }

    .TableRow.isDragging {
        cursor: grabbing;
        transition: ease-in-out;
    }

    .TableRowHeadingCell {
        text-align: left;
        transition: ease-in-out;
    }

    .TableRowHeadingCell.isDragging {
        opacity: 0.3;
        @media ${ScreenState.MOBILE} {
            opacity: 1;
            transform: scale(1.02);
            border: none;
            box-shadow: 1px 0 5px -2px ${colors["neutral-400"]} !important;
        }
    }

    .swipe-icon path {
        fill: ${colors["neutral-400"]};
    }

    .swipe-icon {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
    }

    .input-number {
        border-radius: 8px;
        font-size: 14px;
    }
    .input-number * {
        font-size: 12px;
        padding-left: 0px;
    }

    .modifierPrice {
        grid-column: 10;
        grid-row: 1/2;
        color: grey;
        align-self: center;
        width: 3rem;
        font-size: 16px !important;
        font-family: "Inter";
    }

    .modifierPrice > div > input {
        font-size: 16px !important;
    }
    .modifierPrice::before {
        content: "$";
        position: absolute;
        top: 0px;
        left: -3px;
        font-size: 16px !important;
        font-family: "Inter" !important;
    }

    .soldOutStatus {
        grid-column: 9;
        grid-row: 1/2;
        align-self: center;
        width: 6em;
        font-size: 16px !important;
        font-family: "Inter";
        text-align: center;
        background-color: ${SystemColors.v2.dragonfruit5.light};
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        color: ${SystemColors.v2.melon50.light};
        border-radius: 6px;
        padding: 0 5px;
        font-family: "Inter";
        font-weight: 800;
        font-size: 12px !important;

        @media ${ScreenState.MOBILE} {
            grid-column: 11/12;
            grid-row: 1/2;
        }
    }

    .modifier-desktop-container {
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 8px;
        margin-bottom: 8px;
        padding-top: 10px;
        padding-bottom: 10px;

        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto 1fr 1fr 1fr 1fr 1fr 1fr auto auto auto auto auto auto;
        grid-auto-rows: minmax(3rem, auto);
        align-items: center;
        justify-content: center;
        padding: 2px 10px !important;
    }

    .modifier-mobile-container {
        border-radius: 8px;
        margin-bottom: 8px;
        padding-top: 10px;
        padding-bottom: 10px;

        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto 1fr 1fr 1fr 1fr 1fr 1fr auto auto auto auto;
        grid-auto-rows: minmax(3rem, auto);
        align-items: center;
        justify-content: center;
        padding: 2px 10px !important;
    }
    .modifier-option-name {
        grid-column: 2/8;
        grid-row: 1/2;
        margin-left: 0.5rem;
        font-size: 16px !important;
        display: flex;
        justify-self: start;
        text-align: left;
        line-height: 20px;
    }

    .modifier-option-actions {
        grid-column: 13;
        grid-row: 1/2;
        color: grey;
        width: 3rem;
    }

    .modifier-option-actions-button {
        font-weight: 900;
    }

    .TableRowHeadingCellButtonActions {
        grid-column: 15;
        grid-row: 1/2;
        color: grey;
        align-self: center;
        width: 2rem;

        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }

    .action-menus {
        margin-left: 10px;
    }

    .card {
        border: none;
    }
    .card .swipeable-list-item {
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 8px;
        margin-bottom: 8px;
        align-items: center;
        justify-content: center;
    }
    .card .swipeable-list-item__content {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .TableRowHeadingCellButtonActions .iconWrapper {
        cursor: pointer;
    }

    .TableRowHeadingCellDragHandler {
        cursor: grab;
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .TableRowHeadingCellDragHandler.isDragging {
        cursor: grabbing;
        grid-column: 1/2;
        grid-row: 1/2;
    }
`;
const ActionContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    box-sizing: border-box;
    color: ${colors["neutral-400"]};
    user-select: none;
`;
const ItemColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
