import { IProduct } from "@snackpass/snackpass-types";
import { Col, Row, Space } from "antd";
import styled from "styled-components";
import {
    CSSProperties,
    RefObject,
    useEffect,
    useRef,
    useState,
    MouseEvent,
} from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useAsyncDebounce } from "react-table-7";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { truncate } from "lodash";

import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import {
    isAddon,
    isCategory,
    isProduct,
    RowInstance,
    WithParentId,
} from "#menu-editor/multi-menus/helpers";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import NoImage from "src/assets/images/no-img.png";
import { ReactComponent as DragDots } from "src/assets/icons/drag-dots.svg";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { PriceText } from "#menu-editor/multi-menus/shared-components/price-text";
import constants from "#core/constants";
import { optimizedImageURL } from "src/utils/image";

export const DRAG_COL_WIDTH = 100;
export const DRAG_COL_PADDING = 24;

const stopPropagation = (e: MouseEvent<HTMLDivElement>) => e.stopPropagation();

type ItemCellProps = {
    /* eslint-disable-next-line */
    row: any;
    rowRef: RefObject<HTMLTableRowElement>;
    setRowIsDragging: (isDragging: boolean) => void;
    shouldDrag: boolean;
    increaseOutlineChangesCount: () => void;
};

type DraggedItem = {
    id: string;
    depth: number;
    index: number;
    originalIndex: number;
};

type DragCollectedProps = {
    isDragging: boolean;
};

type EmptyObject = Record<string, never>;

const getRowType = ({
    original,
}: {
    original: WithParentId<RowInstance>;
}): string => {
    if (isCategory(original)) return "category";
    if (isProduct(original)) return `product-${original.parentId}`;
    if (isAddon(original)) return `addon-${original.parentId}`;
    return "no-set";
};

export const ItemCell: React.FC<ItemCellProps> = ({
    row,
    rowRef,
    setRowIsDragging,
    shouldDrag,
    increaseOutlineChangesCount,
}) => {
    const dispatch = useDispatch();

    const dragRef = useRef(null);

    const [wasExpanded, setWasExpanded] = useState(false);

    const rowType = getRowType(row);

    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });

    const [{ isDragging }, drag, preview] = useDrag<
        DraggedItem,
        EmptyObject,
        DragCollectedProps
    >({
        type: rowType,
        item: {
            id: row.original._id,
            depth: row.depth,
            index: row.index,
            originalIndex: row.index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        isDragging: (monitor) => row.original._id === monitor.getItem().id,
        end: (item) => {
            if (item.index !== item.originalIndex)
                increaseOutlineChangesCount();
        },
    });

    const onHover = useAsyncDebounce(
        (
            item: DraggedItem,
            monitor: DropTargetMonitor<DraggedItem, EmptyObject>,
        ) => {
            if (!monitor.canDrop()) return;

            if (!rowRef.current) return;

            const hoverBoundingBox = rowRef.current.getBoundingClientRect();

            const hoverBoxMidY = hoverBoundingBox.height / 2;

            const clientOffset = monitor.getClientOffset();

            if (clientOffset === null) return;

            const clientHoverY = clientOffset.y - hoverBoundingBox.top;

            // When dragging downwards, move items when dragged beyond 50% of item height
            if (item.index < row.index && clientHoverY < hoverBoxMidY) {
                return;
            }

            // When dragging upwards, move items when dragged beyond 95% of item height
            // to avoid issues with scrolling the container upwards while dragging
            if (
                item.index > row.index &&
                clientHoverY > hoverBoundingBox.height * 0.05
            ) {
                return;
            }

            // This actions actually mutate the monitor object directly, so we must be careful with side effects
            // that is the reason why this whole function is debounced
            if (item.depth === 0) {
                dispatch(
                    multiMenuActions.moveCategory({
                        oldPlace: item.index,
                        newPlace: row.index,
                    }),
                );
            } else if (item.depth === 1) {
                dispatch(
                    multiMenuActions.moveProduct({
                        categoryId: row.original.parentId,
                        oldPlace: item.index,
                        newPlace: row.index,
                    }),
                );
            } else {
                dispatch(
                    multiMenuActions.moveAddon({
                        addonGroupId: row.original.parentId,
                        oldPlace: item.index,
                        newPlace: row.index,
                    }),
                );
            }
            item.index = row.index;
        },
        50,
    );

    const [, drop] = useDrop<DraggedItem, EmptyObject, EmptyObject>({
        accept: rowType,
        hover: onHover,
    });

    const item = row.original as IProductCategoryWithProducts | IProduct;

    useEffect(() => {
        preview(drop(rowRef));
        drag(dragRef);
    }, [rowRef]);

    useEffect(() => {
        setRowIsDragging(isDragging);
        if (isDragging) {
            setWasExpanded(row.isExpanded ?? false);
            row.toggleRowExpanded(false);
        } else {
            row.toggleRowExpanded(wasExpanded);
        }
    }, [isDragging]);

    if (!isCategory(item))
        return (
            <Row
                align="middle"
                style={
                    !isMobile
                        ? styles.itemRowStyles
                        : styles.itemRowStylesMobile
                }
                ref={rowRef}
            >
                <Col flex={`${DRAG_COL_WIDTH}px`} style={styles.dragColStyles}>
                    <Row justify="end">
                        <DragIconCol
                            ref={dragRef}
                            isDragging={isDragging}
                            shouldDrag={shouldDrag}
                            onClick={stopPropagation}
                        >
                            <DragDots />
                        </DragIconCol>
                        {isProduct(item) ? (
                            <div
                                style={{
                                    backgroundImage: `url("${optimizedImageURL(
                                        item.image || NoImage,
                                        {
                                            size: { w: 40 },
                                        },
                                    )}")`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: 40,
                                    height: 40,
                                    borderRadius: 8,
                                }}
                            />
                        ) : null}
                    </Row>
                </Col>
                <Col flex="auto">
                    {isMobile ? (
                        <div style={styles.flexColumn}>
                            <Text type="body-medium">
                                {truncate(row.original.name, { length: 20 })}
                            </Text>
                            <PriceText
                                itemId={row.original._id}
                                itemPrice={row.original.price}
                                itemType={
                                    isProduct(row.original)
                                        ? "products"
                                        : "addons"
                                }
                            />
                        </div>
                    ) : (
                        <Text type="body-medium">{item.name}</Text>
                    )}
                </Col>
            </Row>
        );

    return (
        <Row
            ref={rowRef}
            align="middle"
            className={classNames({ categoryRow: rowType === "category" })}
        >
            <DragIconCol
                ref={dragRef}
                isDragging={isDragging}
                shouldDrag={shouldDrag}
            >
                <DragDots />
            </DragIconCol>

            <Space direction="vertical">
                <Col span={24}>
                    <Text type="body-bold">{item.name}</Text>
                </Col>
                {!isMobile ? (
                    <Col span={24}>
                        <Text type="body-regular">
                            {`${row.subRows.length} ${
                                row.subRows.length === 1 ? "Item" : "Items"
                            }`}
                        </Text>
                    </Col>
                ) : null}
            </Space>
        </Row>
    );
};

const DragIconCol = styled(Col)<{ isDragging: boolean; shouldDrag: boolean }>`
    cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "grab")};

    padding: 6px;
    border-radius: 56px;

    &:hover {
        box-shadow: inset 999px 4px 0px rgba(241, 245, 248, 0.5);
    }

    visibility: ${({ shouldDrag }) => (shouldDrag ? undefined : "hidden")};
    pointer-events: ${({ shouldDrag }) => (shouldDrag ? "all" : "none")};
`;

const styles = {
    itemRowStyles: {
        padding: `8px 0 8px ${DRAG_COL_PADDING}px`,
    } as CSSProperties,
    itemRowStylesMobile: {
        padding: "8px 0",
    } as CSSProperties,
    dragColStyles: { paddingRight: "16px" } as CSSProperties,
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        gap: "3px",
    } as CSSProperties,
};
