import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DevicesModal } from "#devices/components/Modal";
import { CenteredSpin } from "#devices/components/Loading";

type RestartAppModalProps = {
    handleClose: (v: boolean) => void;
    isOpen: boolean;
    handleRestart: () => void;
};

const RestartAppModal = ({
    handleClose,
    isOpen,
    handleRestart,
}: RestartAppModalProps): JSX.Element => {
    const { restartingDevice } = useContext(DevicesPageContext);

    const returnToDetails = () => {
        handleClose(false);
    };

    return (
        <DevicesModal
            visible={isOpen}
            handleClose={returnToDetails}
            footer={
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Button
                                className="w-full"
                                variant="outline"
                                onClick={returnToDetails}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                className="w-full"
                                onClick={handleRestart}
                                disabled={restartingDevice}
                            >
                                {restartingDevice ? (
                                    <CenteredSpin small />
                                ) : (
                                    "Restart"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
        >
            <Text.Title2>Restart Device</Text.Title2>
            <Text.Body>
                Are you sure you want to restart the app currently running on
                this device?
            </Text.Body>
        </DevicesModal>
    );
};

export default RestartAppModal;
