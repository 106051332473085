import {
    styled as muiStyled,
    TextField,
    TextFieldProps,
} from "@material-ui/core";

import colors from "#reusable/colors/colors.json";

export const StyledTextField = muiStyled(TextField)({
    "& .MuiFilledInput-root": {
        background: "transparent",
        border: `1px solid ${colors["neutral-400"]}`,
        borderRadius: "8px",
    },
});

export const textFieldProps = {
    InputLabelProps: {
        shrink: true,
    },
    variant: "filled",
    fullWidth: true,
} as TextFieldProps;
