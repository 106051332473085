import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isDesktop } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import propTypes from "prop-types";

export const GlobalDndContext = (props: any) => {
    if (isDesktop) {
        return (
            <DndProvider backend={HTML5Backend}>{props.children}</DndProvider>
        );
    } else {
        return (
            <DndProvider backend={TouchBackend}>{props.children}</DndProvider>
        );
    }
};

GlobalDndContext.propTypes = { children: propTypes.node };
