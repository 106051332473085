import { PlusIcon } from "@radix-ui/react-icons";
import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    useEditorState,
    useEditorStore,
} from "#table-editor/components/Editor/EditorProvider";
import { Button } from "src/@/components/ui/button";
import { Popover, PopoverTrigger } from "src/@/components/ui/popover";
import { RenameSectionDialog } from "#table-editor/components/RenameSectionDialog";
import { useDeleteServiceArea, useSaveServiceArea } from "#table-editor/api";
import { NewSectionDialog } from "#table-editor/components/NewSectionDialog";
import { useValidateCanvas } from "#table-editor/components/Editor/hooks/useValidateCanvas";
import { SelectSectionConfirmationDialog } from "#table-editor/components/SelectSectionConfirmationDialog";
import { SectionOptions } from "#table-editor/components/SectionOptions";

export function SectionSelector() {
    const editor = useEditorStore();

    const serviceAreas = useEditorState((state) => state.serviceAreas);
    const activeServiceArea = useEditorState(
        (state) => state.activeServiceArea,
        (a, b) => a?.id === b?.id && a?.name === b?.name,
    );

    const [switchToAreaId, setSwitchToAreaId] = useState<string | null>(null);
    const [openConfirmNewSectionDialog, setOpenConfirmNewSectionDialog] =
        useState(false);
    const [openNewSectionDialog, setOpenNewSectionDialog] = useState(false);

    const isDirty = useEditorState((state) => state.isDirty);
    const { isValid } = useValidateCanvas();

    const { loading: loadingSave, saveServiceArea } = useSaveServiceArea();

    const onSelectSection = useCallback(
        (id: string) => {
            if (isDirty || !isValid) {
                setSwitchToAreaId(id);
            } else {
                editor.getState().setServiceArea(id);
            }
        },
        [editor, isDirty, isValid],
    );

    const onOpenNewSection = useCallback(() => {
        if (isDirty || !isValid) {
            setOpenConfirmNewSectionDialog(true);
        } else {
            setOpenNewSectionDialog(true);
        }
    }, [isDirty, isValid]);

    const onSaveServiceAreaAndSwitch = useCallback(async () => {
        await saveServiceArea();

        const id = switchToAreaId;
        setSwitchToAreaId(null);

        if (id) {
            editor.getState().setServiceArea(id);
        }
    }, [editor, saveServiceArea, switchToAreaId]);

    const revertChangesAndSwitch = useCallback(() => {
        if (activeServiceArea && switchToAreaId) {
            const id = switchToAreaId;

            setSwitchToAreaId(null);

            // not sent
            if (activeServiceArea.id === "") {
                editor.getState().removeServiceArea(activeServiceArea.id);
                editor.getState().setServiceArea(id);
            } else {
                editor.getState().revertActiveServiceArea();
                editor.getState().setServiceArea(id);
            }
        }
    }, [activeServiceArea, editor, switchToAreaId]);

    const onSaveServiceAreaAndNew = useCallback(async () => {
        await saveServiceArea();
        setOpenConfirmNewSectionDialog(false);
        setOpenNewSectionDialog(true);
    }, [saveServiceArea]);

    const revertChangesAndNew = useCallback(() => {
        if (activeServiceArea) {
            // not sent
            if (activeServiceArea.id === "") {
                editor.getState().removeServiceArea(activeServiceArea.id);
            } else {
                editor.getState().revertActiveServiceArea();
            }
            setOpenConfirmNewSectionDialog(false);
            setOpenNewSectionDialog(true);
        }
    }, [activeServiceArea, editor]);

    useEffect(() => {
        if (serviceAreas.length === 0) {
            setOpenNewSectionDialog(true);
        }
    }, [serviceAreas]);

    return (
        <div className="flex flex-row items-center gap-2 pl-8 pr-4">
            {serviceAreas.map((area) => (
                <Section
                    key={area.id}
                    id={area.id}
                    isSelected={area.id === activeServiceArea?.id}
                    name={
                        area.id === activeServiceArea?.id
                            ? activeServiceArea.name
                            : area.name
                    }
                    onSelect={onSelectSection}
                />
            ))}

            <NewSection onClickNewSection={onOpenNewSection} />

            <NewSectionDialog
                open={openNewSectionDialog}
                onOpenChange={setOpenNewSectionDialog}
            />

            <SelectSectionConfirmationDialog
                loading={loadingSave}
                label="Save changes?"
                description="There are unsaved changes for this section."
                confirmLabel="Save"
                open={isDirty && switchToAreaId != null}
                onOpenChange={(open) =>
                    setSwitchToAreaId((prev) => (!open ? null : prev))
                }
                onConfirm={onSaveServiceAreaAndSwitch}
            />

            <SelectSectionConfirmationDialog
                loading={false}
                label="Revert changes?"
                description="There were errors found in this section. Reverting will erase all unsaved changes."
                confirmLabel="Revert"
                open={!isValid && switchToAreaId != null}
                onOpenChange={(open) =>
                    setSwitchToAreaId((prev) => (!open ? null : prev))
                }
                onConfirm={revertChangesAndSwitch}
            />

            <SelectSectionConfirmationDialog
                loading={loadingSave}
                label="Save changes?"
                description="There are unsaved changes for this section."
                confirmLabel="Save"
                open={isDirty && openConfirmNewSectionDialog}
                onOpenChange={setOpenConfirmNewSectionDialog}
                onConfirm={onSaveServiceAreaAndNew}
            />

            <SelectSectionConfirmationDialog
                loading={false}
                label="Revert changes?"
                description="There were errors found in this section. Reverting will erase all unsaved changes."
                confirmLabel="Revert"
                open={!isValid && openConfirmNewSectionDialog}
                onOpenChange={setOpenConfirmNewSectionDialog}
                onConfirm={revertChangesAndNew}
            />
        </div>
    );
}

type SectionProps = {
    isSelected: boolean;
    id: string;
    name: string;
    onSelect(id: string): void;
};

function Section({ isSelected, id, name, onSelect }: SectionProps) {
    const editor = useEditorStore();

    const { deleteServiceArea } = useDeleteServiceArea();

    const [openPopover, setOpenPopover] = useState(false);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);

    const onRenameSection = useCallback(
        (name: string) => {
            editor.getState().setActiveServiceAreaName(name);
            setOpenRenameDialog(false);
        },
        [editor],
    );

    const onDeleteServiceArea = useCallback(async () => {
        const activeServiceArea = editor.getState().activeServiceArea;
        if (activeServiceArea) {
            if (activeServiceArea.id === "") {
                editor.getState().removeServiceArea(activeServiceArea.id);
            } else {
                await deleteServiceArea(activeServiceArea.id);
            }
        }
        setOpenPopover(false);
    }, [deleteServiceArea, id]);

    const onSelectSection = useCallback(() => {
        if (isSelected) {
            setOpenPopover(true);
        } else {
            onSelect(id);
        }
    }, [isSelected, onSelect, id]);

    const selectedStyle = isSelected ? "border-b-2 border-b-neutral-950" : "";

    return (
        <>
            <Popover open={openPopover} onOpenChange={setOpenPopover}>
                <div className={selectedStyle}>
                    <ConditionalPopover isSelected={isSelected}>
                        <div
                            className="my-2 rounded-md px-2 py-1.5 hover:cursor-pointer hover:bg-neutral-300"
                            onClick={onSelectSection}
                        >
                            <p className="text-sm font-medium text-neutral-950">
                                {name}
                            </p>
                        </div>
                    </ConditionalPopover>
                    <SectionOptions
                        onRenameSection={() => setOpenRenameDialog(true)}
                        onDelete={onDeleteServiceArea}
                    />
                </div>
            </Popover>
            <RenameSectionDialog
                open={openRenameDialog}
                onOpenChange={setOpenRenameDialog}
                initialName={name}
                onSubmit={onRenameSection}
            />
        </>
    );
}

function ConditionalPopover({
    isSelected,
    children,
}: PropsWithChildren<{ isSelected: boolean }>) {
    if (isSelected) {
        return <PopoverTrigger asChild>{children}</PopoverTrigger>;
    }

    return children;
}

type NewSectionProps = {
    onClickNewSection(): void;
};

function NewSection({ onClickNewSection }: NewSectionProps) {
    return (
        <Button
            variant="secondary"
            type="button"
            className="h-fit gap-2 px-2 py-1.5"
            onClick={onClickNewSection}
        >
            <PlusIcon />
            <p className="text-sm font-medium text-neutral-950">New Section</p>
        </Button>
    );
}
