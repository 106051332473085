import {
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
    useState
} from "react";
import { useStoreWithEqualityFn } from "zustand/traditional";

import { createEditorStore, EditorState } from "#table-editor/store/editor";
import { ServiceArea } from "#table-editor/types";

const EditorContext = createContext<ReturnType<
    typeof createEditorStore
> | null>(null);

type EditorProviderProps = PropsWithChildren<{
    serviceAreas: ServiceArea[];
}>;

export function EditorProvider(props: EditorProviderProps) {
    const [store] = useState(() => createEditorStore(props.serviceAreas));

    return (
        <EditorContext.Provider value={store}>
            {props.children}
        </EditorContext.Provider>
    );
}

export function useEditorStore() {
    const store = useContext(EditorContext);

    if (store == null) {
        throw Error("useEditorStore must be used within EditorProvider");
    }

    return useMemo(
        () => ({
            getState: store.getState,
            setState: store.setState,
            subscribe: store.subscribe
        }),
        [store.getState, store.setState, store.subscribe]
    );
}

export function useEditorState<Slice = unknown>(
    selector: (state: EditorState) => Slice,
    equalityFn?: (a: Slice, b: Slice) => boolean
) {
    const store = useContext(EditorContext);

    if (store == null) {
        throw Error("useEditorState must be used within EditorProvider");
    }

    return useStoreWithEqualityFn(store, selector, equalityFn);
}
