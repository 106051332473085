import { useCallback, useMemo } from "react";
import { toast } from "sonner";
import { GraphQLError } from "graphql/error";

import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { useUpdateServiceAreaMutation } from "src/api/graphql/generated/types";
import {
    fromGQLServiceArea,
    toGQLServiceAreaLayout,
} from "#table-editor/serviceAreas";
import { Element } from "#table-editor/canvas/Element";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStoreId } from "src/redux/slices";
import { logAndSendError } from "src/utils/errors";

export function useUpdateServiceArea() {
    const editor = useEditorStore();

    const storeId = useAppSelector(getActiveStoreId);

    const [update, { loading, reset }] = useUpdateServiceAreaMutation();

    const updateServiceArea = useCallback(async () => {
        const activeServiceArea = editor.getState().activeServiceArea;
        const canvas = editor.getState().canvas;
        const elements = editor.getState().elements;

        if (activeServiceArea && canvas) {
            const layoutElements = toGQLServiceAreaLayout(
                elements,
                canvas.getObjects(Element.type) as Element[],
            );

            try {
                const newLayout = await update({
                    variables: {
                        storeId,
                        input: {
                            id: activeServiceArea.id,
                            name: activeServiceArea.name,
                            layout: layoutElements,
                        },
                    },
                });

                if (newLayout.data?.updateServiceArea) {
                    const serviceArea = fromGQLServiceArea(
                        newLayout.data?.updateServiceArea,
                    );
                    editor.getState().mergeActiveServiceArea(serviceArea);
                    toast.success("Successfully updated service area");
                }
            } catch (err) {
                logAndSendError(err);
                if (err instanceof GraphQLError) {
                    toast.error(`Unexpected error: ${err.message}`);
                } else {
                    toast.error("There was an error completing this action");
                }
            } finally {
                reset();
            }
        }
    }, [reset, editor, storeId, update]);

    return useMemo(
        () => ({
            loading,
            updateServiceArea,
        }),
        [loading, updateServiceArea],
    );
}
