import { DateTime } from "luxon";

export const formatDate = (date?: string) => {
    if (!date) {
        return "Unpaid";
    }
    return DateTime.fromISO(date).toFormat("M/d, h:mm a");
};

export const formatAmount = (amountInCents: number) => {
    const amountInDollars = amountInCents / 100;
    // Don't display cents if amount is whole number in dollars
    const fractionDigits = amountInDollars % 1 === 0 ? 0 : 2;
    return amountInDollars.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: 2,
    });
};
