/** @jsxImportSource @emotion/react */
import "antd/dist/antd.css";
import { Col, Row, Spin } from "antd";
import { isEqual } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ScreenState } from "@snackpass/snackpass-types";

import api from "src/api/rest";
import constants from "#core/constants";
import { createMultiMenu } from "#menu-editor/multi-menus/redux/thunks";
import { getActiveStore } from "src/redux/selectors";
import {
    getCurrentMultiMenu,
    getMultiMenuLoadingState,
    getStoreMultiMenus,
} from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { MenuTimeConflicts } from "#menu-editor/multi-menus/redux/types";
import {
    openNotification,
    NotificationPosition,
} from "#menu-editor/multi-menus/shared-components/notification";
import { ScreenLayout } from "#menu-editor/multi-menus/styled-components/layout";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { useAppDispatch } from "src/redux/hooks";

import { ScreenFooter } from "../../shared-components/footer";
import { MobileHeader } from "../../shared-components/mobile-header";

import { TimeConflictsModal } from "./components/time-conflicts-modal";
import { MenuAlert, rulesDefaultValue } from "./components/rule-alerts";
import { MenuDetailsForm } from "./components/menu-settings-form";
import {
    checkValidationErrors,
    checkValidationWarnings,
} from "./utils/rule-validators";
import { logAndSendError } from "src/utils/errors";

const menuLinks = [
    { label: "Items", to: "/menu-editor" },
    { label: "Menus", to: "/multi-menus" },
    { label: "New Menu", to: "/multi-menus-create" },
];

export const CreateMultiMenus = () => {
    const dispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const currentMenu = useSelector(getCurrentMultiMenu);
    const currentMenus = useSelector(getStoreMultiMenus);
    const fetchState = useSelector(getMultiMenuLoadingState("FetchStoreMenus"));
    const createState = useSelector(
        getMultiMenuLoadingState("CreateMultiMenu"),
    );

    const history = useHistory();
    const topAlerts = useRef<HTMLDivElement>(null);

    const [conflicts, setConflicts] = useState<MenuTimeConflicts[]>([]);
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
    const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
    const [ruleAlertType, setRuleAlertType] =
        useState<MenuAlert>(rulesDefaultValue);
    const [hasValidationErrors, setHasValidationErrors] = useState(
        !isEqual(
            rulesDefaultValue,
            checkValidationErrors(currentMenus, currentMenu),
        ),
    );

    const submitMenu = async (storeId: string) =>
        dispatch(createMultiMenu(storeId))
            .unwrap()
            .then((menu) => history.push(`/multi-menus/${menu.id}`))
            .catch((err) => {
                logAndSendError(err);
                openNotification({
                    message: "Failed to create menu",
                    description:
                        "Failed to create your new menu, please try again later.",
                    position: NotificationPosition.TopCenter,
                });
            });

    const onSubmit = async () => {
        if (!activeStore) return;
        const { type, hours, platforms, enabled, name, priceAdjustment } =
            currentMenu;
        const detectedAlerts = checkValidationErrors(currentMenus, currentMenu);
        const detectedAlertsAndWarnings = checkValidationWarnings(
            detectedAlerts,
            activeStore,
            hours,
            name,
        );
        setRuleAlertType(detectedAlertsAndWarnings);
        if (!isEqual(rulesDefaultValue, detectedAlerts)) {
            topAlerts.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            return;
        }

        if (enabled && type && hours && platforms && platforms.length > 0) {
            const conflictsResponse = await api.multiMenus.checkConflicts(
                activeStore._id,
                {
                    type,
                    hours,
                    platforms,
                },
            );
            if (conflictsResponse.length > 0) {
                setConflicts(conflictsResponse);
                setIsTimeModalOpen(true);
                return;
            }
        }

        if (priceAdjustment && priceAdjustment.value !== 1) {
            setIsPriceModalOpen(true);
            return;
        }
        await submitMenu(activeStore._id);
    };

    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });

    const navigateToMenus = useCallback(async () => {
        await dispatch(multiMenuActions.resetCurrentMenu());
        history.push("/multi-menus");
    }, [history]);

    useMemo(() => {
        setHasValidationErrors(
            !isEqual(
                rulesDefaultValue,
                checkValidationErrors(currentMenus, currentMenu),
            ),
        );
    }, [currentMenus, currentMenu]);

    return (
        <>
            <ScreenLayout
                breadcrumbItems={isMobile ? undefined : menuLinks}
                header={
                    isMobile ? (
                        <MobileHeader title="Create a Menu" />
                    ) : (
                        <Text type="title">Create a Menu</Text>
                    )
                }
                content={
                    <ContentRow>
                        <Col span={isMobile ? 22 : 18} ref={topAlerts}>
                            <Spin
                                spinning={
                                    fetchState === "pending" ||
                                    createState === "pending"
                                }
                                tip="Saving..."
                                size="large"
                            >
                                <MenuDetailsForm
                                    activeStore={activeStore}
                                    ruleAlertType={ruleAlertType}
                                    setRuleAlertType={setRuleAlertType}
                                    setIsPriceModalOpen={setIsPriceModalOpen}
                                    isPriceModalOpen={isPriceModalOpen}
                                    onUpdatePrice={async () => {
                                        setIsPriceModalOpen(false);
                                        activeStore &&
                                            (await submitMenu(
                                                activeStore?._id,
                                            ));
                                    }}
                                    isLoading={createState === "pending"}
                                    isMobile={isMobile}
                                />
                            </Spin>
                        </Col>
                    </ContentRow>
                }
                footer={
                    <ScreenFooter
                        onCancel={navigateToMenus}
                        onSubmit={onSubmit}
                        disabled={
                            fetchState !== "succeeded" ||
                            createState === "pending" ||
                            hasValidationErrors
                        }
                    />
                }
            />

            <TimeConflictsModal
                isModalOpen={isTimeModalOpen}
                setIsModalOpen={setIsTimeModalOpen}
                conflicts={conflicts}
            />
        </>
    );
};

const ContentRow = styled(Row)`
    overflow-y: scroll;
    @media ${ScreenState.MOBILE} {
        height: calc(100% - 250px);
    }
    height: calc(100% - 222px);
    width: -webkit-fill-available;
    width: -moz-available;
`;
