import { FormTextInput } from "#settings-integrations/components/shared/form-text-input";
import { OptionalLabel } from "#settings-integrations/components/shared/reusables";
import { INTEGRATION_FIELD_NAMES } from "#settings-integrations/utils/types";

export const CommentContainer = () => (
    <>
        <div className="py-3 text-large font-bold text-neutral-950">
            Anything we should know?
        </div>
        <div className="text-medium flex flex-row py-1 font-medium text-neutral-600">
            Additional Info <OptionalLabel />
        </div>
        <FormTextInput
            inputLabel="Comments"
            fieldName={INTEGRATION_FIELD_NAMES.COMMENTS}
            placeholder="Existing integrations, point of contact..."
            height={120}
            isTextArea
        />
    </>
);
