import { Col, InputNumber } from "antd";
import React, { useCallback } from "react";

import { Divider } from "#reusable/divider";
import { Text } from "#reusable/text/index";
import { StyledField, StyledRow } from "#settings/components/shared-component";

import { OrdersFormFieldEnum, RowProps } from "../types";

export const PickupTimeMinRow = ({
    setFieldTouched,
    setFieldValue,
    value,
    disabled,
}: RowProps<"defaultPickupMinTime">) => {
    const onDefaultPickupMinTimeChange = useCallback(
        (value: number) => {
            setFieldTouched(OrdersFormFieldEnum.defaultPickupMinTime);
            setFieldValue(OrdersFormFieldEnum.defaultPickupMinTime, value);
        },
        [setFieldValue, setFieldTouched],
    );

    return (
        <>
            <StyledRow>
                <Col>
                    <Text size="m">Lower bound</Text>
                    <Text size="s" color="light-grey" weight="thin">
                        In minutes
                    </Text>
                </Col>
                <Col span={4}>
                    <StyledField
                        name="defaultPickupMinTime"
                        type="number"
                        min={0}
                        component={InputNumber}
                        size="large"
                        value={value ?? 10}
                        onChange={onDefaultPickupMinTimeChange}
                        disabled={disabled}
                    />
                </Col>
            </StyledRow>
            <Divider />
        </>
    );
};
