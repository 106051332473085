import React, { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

import { DataTable } from "src/@/components/ui/data-table";
import { ScreenLayout } from "#reusable/layout";
import api, { SummarizedDevice } from "src/api/rest";
import { columnDefinitions } from "#settings/settings-internal-devices/columnDefinitions";
import { CenteredSpin } from "#devices/components/Loading";
import { Alert, AlertDescription } from "src/@/components/ui/alert";

export const InternalDevices = () => {
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState<SummarizedDevice[]>([]);

    const columns = useMemo(() => columnDefinitions(), []);

    useEffect(() => {
        const fetchDevicesForInternal = async () => {
            if (loading) {
                return;
            }

            try {
                setLoading(true);
                const response =
                    await api.storeDevices.listDevicesForInternal();
                setDevices(response.data.devices);
            } catch (e) {
                toast.error("Unable to fetch devices");
                console.error("error", e);
            } finally {
                setLoading(false);
            }
        };

        void fetchDevicesForInternal();
    }, []);

    const content = loading ? (
        <div className="mt-8">
            <Alert variant="warning">
                <AlertDescription>
                    <b>Loading</b>
                    <br />
                    Please be patient and allow the full device list to load
                </AlertDescription>
            </Alert>
            <div style={{ margin: 70 }}>
                <CenteredSpin />
            </div>
        </div>
    ) : (
        <div className="mt-8">
            <DataTable
                showPagination
                key={devices.length}
                data={devices}
                customPageSize={10}
                columns={columns}
                toolbar={{
                    search: {
                        key: "name",
                        placeholder: "Search Devices",
                    },
                }}
            />
        </div>
    );

    return (
        <ScreenLayout
            header={
                <div className="pb-2 text-xlarge font-semibold">
                    Devices Search
                </div>
            }
            isLoading={false}
            description={
                <div className="text-body text-[#585B5F] md:pr-[20%]">
                    Search for Devices by Store ID, Serial Number, or Snack ID
                </div>
            }
            content={content}
        />
    );
};
