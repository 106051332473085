import clsx from "clsx";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import Switch from "#reusable/input/toggle-input";
import { FormFieldDescriptor } from "#settings-integrations/components/shared/form-field-descriptor";
import { FormFieldName } from "#settings-integrations/components/shared/form-field-name";

type Props = {
    name: string;
    descriptor: string;
    fieldName: string;
    divider?: boolean;
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    disabled?: boolean;
};

export const FormToggle = ({
    name,
    descriptor,
    fieldName,
    divider = false,
    required = false,
    recommended = false,
    autofilled = false,
    disabled = false,
}: Props) => {
    const { control } = useFormContext();

    return (
        <div
            className={clsx(
                "mb-6 mr-3 flex flex-1 items-center justify-between",
                divider && "border-b-2 border-solid border-neutral-400 pb-6",
            )}
        >
            <div className="mr-6 flex flex-col">
                <FormFieldName
                    name={name}
                    required={required}
                    recommended={recommended}
                    autofilled={autofilled}
                />
                <FormFieldDescriptor descriptor={descriptor} />
            </div>
            <Controller
                control={control}
                name={fieldName}
                render={({ field: { onChange, value } }) => (
                    <Switch
                        id={fieldName}
                        isOn={value}
                        onChange={onChange}
                        disabled={disabled}
                        disabledMessage={"Must have specific discounted items"}
                    />
                )}
            />
        </div>
    );
};
