import { useSelector } from "react-redux";

import { getActiveStore, getUser } from "../../redux/selectors";

export const useHasEditSettingsForActiveStore = () => {
    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);

    if (!user || !store) return false;

    if (user.snackpassPermissions.isAdmin) {
        if (
            user.snackpassPermissions.isSnackpassEmployee ||
            user.permissions?.isOwner?.includes(store._id) ||
            user.permissions?.hasSettingsWrite?.includes(store._id)
        ) {
            return true;
        }
    }

    return false;
};
