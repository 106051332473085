import {
    FabricObject,
    FabricText,
    Group,
    GroupProps,
    Rect,
    SerializedGroupProps,
    TClassProperties
} from "fabric";

import { Shape, ShapeStroke } from "src/api/graphql/generated/types";

type ElementProps = {
    variant: "table" | "barrier" | "label";
} & Partial<GroupProps>;

type GeometryProps =
    | {
          type: Shape.Rect;
          x: number;
          y: number;
          width: number;
          height: number;
          stroke: ShapeStroke | null;
          fill: boolean;
      }
    | {
          type: Shape.Circle;
          x: number;
          y: number;
          radius: number;
      };

/**
 * Element defines a grouping of fabric objects to be included as a
 * LayoutElement. This object is required for proper integration into
 * any service area layout element. We take advantage of the Fabric's
 * serialization feature and will require certain fields to properly
 * identify the element.
 *
 * @example
 * ```ts
 * const element = new Element(
 *   <element-id>,
 *   [...objects],
 *   {
 *     variant: "table",
 *     top: geometry.y,
 *     left: geometry.x,
 *     ...other geometry fields
 *   }
 * )
 * ```
 */
export class Element extends Group {
    static type = "Element";

    id: string;
    variant: ElementProps["variant"];
    textIndex: number;

    constructor(
        id: string,
        objects: FabricObject[],
        { variant, ...options }: ElementProps
    ) {
        super(objects, options);
        this.id = id;
        this.variant = variant;
        this.textIndex = objects.findIndex((obj) =>
            obj.isType(FabricText.type)
        );
    }

    toObject<
        T extends Omit<
            GroupProps & TClassProperties<this>,
            keyof SerializedGroupProps
        >,
        K extends keyof T = never
    >(propertiesToInclude: K[] = []): Pick<T, K> & SerializedGroupProps {
        return {
            ...super.toObject(propertiesToInclude),
            elementId: this.id,
            elementVariant: this.variant
        };
    }

    toGeometry(type: "Rect" | "Circle"): GeometryProps | null {
        const shape = this.getObjects(type);
        if (shape.length === 0) return null;

        if (type === "Rect") {
            const rect = shape[0] as Rect;

            return {
                type: Shape.Rect,
                x: this.left,
                y: this.top,
                width: this.getScaledWidth(),
                height: this.getScaledHeight(),
                stroke: rect.stroke ? ShapeStroke.Dash : null,
                fill: !!rect.fill && rect.fill !== "transparent"
            };
        } else if (type === "Circle") {
            return {
                type: Shape.Circle,
                x: this.left,
                y: this.top,
                radius: this.getScaledWidth() / 2
            };
        }

        return null;
    }
}
