import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { match } from "ts-pattern";

import DeviceName from "#devices/components/DevicesTable/DeviceName";
import {
    getFormattedDate,
    lastActiveTime,
} from "#devices/components/DevicesTable/lib";
import { copyAndNotify } from "#utils/helpers";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { DeviceType } from "#devices/utils/deviceTypes";
import { getBaseStoreDeviceStatus } from "#devices/utils/getDeviceStatus";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { filterNulls } from "src/utils/filterNulls";
import { BaseStoreDeviceWithNetworkStatus } from "src/api/rest";

const TableItem = ({ field, value }: { field: string; value: string }) => (
    <div>
        <span
            onClick={() => {
                copyAndNotify({
                    value,
                    field,
                });
            }}
            className="cursor-pointer rounded-md p-1 px-2 transition duration-500 ease-in-out hover:bg-black/10"
        >
            {value}
        </span>
    </div>
);

export const columnDefinitions = ({
    includeStatusColumn,
}: {
    includeStatusColumn: boolean;
}): ColumnDef<BaseStoreDeviceWithNetworkStatus>[] =>
    filterNulls([
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={"Device Name"} />
            ),
            id: "name",
            cell: (props) => <DeviceName device={props.row.original} />,
            // filtering by the device name, serial, and snack id so the search can look at all 3
            filterFn: (row, _, value) =>
                row.original?.name
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                !!row.original.snackId?.includes(value?.toUpperCase()) ||
                !!row.original.serial?.includes(value?.toUpperCase()),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={"Snack ID"} />
            ),
            id: "snackId",
            cell: (props) => {
                const text = match(props.row.original)
                    .with({ deviceType: DeviceType.Printer }, () => "--")
                    .otherwise(() => props.row.original.snackId);
                return <TableItem field={"Snack ID"} value={text} />;
            },
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={"Serial"} />
            ),
            id: "serial",
            cell: (props) => (
                <TableItem field={"Serial"} value={props.row.original.serial} />
            ),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={"Device Type"} />
            ),
            accessorFn: (row) => getDeviceTypeName(row.deviceType),
            id: "deviceType",
        },
        includeStatusColumn
            ? {
                  header: ({ column }) => (
                      <DataTableColumnHeader column={column} title="Status" />
                  ),
                  id: "status",
                  cell: (props) => {
                      const status = getBaseStoreDeviceStatus(
                          props.row.original,
                          props.row.original.latestNetworkStatus,
                      );

                      if (!status) {
                          return <span>--</span>;
                      }

                      return (
                          <div className="flex flex-row items-center">
                              <StatusCircleIcon
                                  width={8}
                                  height={8}
                                  fill={status.color}
                              />
                              <span className="ml-1">{status.status}</span>
                          </div>
                      );
                  },
              }
            : null,
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={"Last Active"} />
            ),
            accessorFn: (row) => lastActiveTime(row.lastPingAt),
            sortingFn: (rowA, rowB) => {
                const rowADate = rowA.original.lastPingAt
                    ? new Date(rowA.original.lastPingAt)
                    : 0;
                const rowBDate = rowB.original.lastPingAt
                    ? new Date(rowB.original.lastPingAt)
                    : 0;
                return rowADate <= rowBDate ? -1 : 1;
            },
            id: "lastPingAt",
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={"Installed"} />
            ),
            accessorFn: (row) => getFormattedDate(row.createdAt),
            id: "createdAt",
        },
    ]);
