import { useCallback, useContext, useState } from "react";
import styled from "@emotion/styled";
import { ChromePicker } from "react-color";
import produce from "immer";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelTheme,
    ChannelType,
    DEFAULT_BG_DARK_COLOR,
    DEFAULT_BG_LIGHT_COLOR,
    DEFAULT_HEADER_DARK_COLOR,
    DEFAULT_HEADER_LIGHT_COLOR,
    DEFAULT_TILE_BG_DARK_COLOR,
    DEFAULT_TILE_BG_LIGHT_COLOR,
} from "#devices/utils/deviceTypes/SnackTVDevice";
import colors from "#reusable/colors/colors.json";
import { Text } from "#devices/components";
import { Button } from "src/@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";

const PICKER_COLORS = [
    // Row 1
    colors["red-medium"],
    colors["orange-medium"],
    colors["yellow-medium"],
    colors["brown-medium"],
    colors["green-medium"],
    colors["light-blue-medium"],
    colors["blue-medium"],
    colors["purple-medium"],
    "#9748ff",
    "#f0339e",
    // Row 2
    "#ff3d1f",
    "#FF7733",
    "#ffc933",
    "#a15a2b",
    "#47d75f",
    "#66ccff",
    "#3377ff",
    "#6647ff",
    "#ae70ff",
    "#ed5eaf",
    // Row 3 (defaults)
    "#f9f6f1",
    "#0f0f0f",
    "#ffffff",
    "#000000",
];

type ColorPickerProps = {
    label: string;
    description: string;
    value?: string;
    onChange(color?: string): void;
    defaultValue: string | undefined;
};

export const ColorPicker = ({
    label,
    value,
    description,
    onChange,
    defaultValue,
}: ColorPickerProps) => {
    const [open, setOpen] = useState(false);

    const onClickReset = useCallback(() => {
        onChange(undefined);
    }, [onChange]);

    const previewColor = value ?? defaultValue;

    return (
        <div className="mb-2 flex flex-col">
            <Text.Label>{label}</Text.Label>
            <Text.Body>{description}</Text.Body>
            <div className="flex w-fit flex-col items-center md:flex-row">
                <Popover onOpenChange={setOpen} open={open}>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="mb-3 flex w-full gap-1 text-left font-normal"
                        >
                            {previewColor !== undefined ? (
                                <div className="mr-3 flex items-center justify-center">
                                    <div
                                        className={"h-5 w-5 rounded-md"}
                                        style={{
                                            backgroundColor: previewColor,
                                        }}
                                    />
                                </div>
                            ) : null}
                            <span>
                                {previewColor?.slice(1).toUpperCase() ??
                                    "Select a Color"}
                            </span>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        className="z-[1001] w-auto p-0"
                        align="start"
                        onFocusOutside={(onFocusOutsideEvent) => {
                            onFocusOutsideEvent.preventDefault();
                        }}
                    >
                        <StyledChromePicker
                            // @ts-expect-error These fields don't exist on the ChromePicker component, but clearly we wanted to set them.
                            width="384px"
                            triangle="hide"
                            color={value ?? defaultValue}
                            onChange={(color: { hex: string }) =>
                                onChange(color.hex)
                            }
                            colors={PICKER_COLORS}
                        />
                        <div className="p-2">
                            <Button
                                variant="outline"
                                className="w-full"
                                onClick={onClickReset}
                            >
                                Reset
                            </Button>
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </div>
    );
};

export const HeaderColorPicker = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const channelOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    const option = channelOptions.find(
        (o: ChannelOption) => o.channel === channel,
    );

    const value = option?.options?.headerColor;

    const theme = option?.options.theme ?? ChannelTheme.LIGHT;

    const defaultColor =
        theme === ChannelTheme.LIGHT
            ? DEFAULT_HEADER_LIGHT_COLOR
            : DEFAULT_HEADER_DARK_COLOR;

    const onChangeColor = (color: string | undefined) => {
        const updatedOptions = produce(channelOptions, (draft) => {
            if (draft) {
                const channelOption = draft.find((o) => o.channel === channel);
                if (!channelOption) {
                    draft.push({
                        channel,
                        options: {
                            headerColor: color,
                        },
                    });
                } else {
                    channelOption.options.headerColor = color;
                }
            }
        });
        updateDeviceField("channelOptions", updatedOptions);
    };

    return (
        <ColorPicker
            label="Header Color"
            description="If not set, headers will use default colors based on the theme"
            defaultValue={defaultColor}
            value={value}
            onChange={onChangeColor}
        />
    );
};

export const MenuBoardBackgroundColorPicker = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const channelOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    const option = channelOptions.find((o) => o.channel === channel);

    const value = option?.options?.menuBoardBackgroundColor;

    const theme = option?.options.theme ?? ChannelTheme.LIGHT;

    const defaultColor =
        theme === ChannelTheme.LIGHT
            ? DEFAULT_BG_LIGHT_COLOR
            : DEFAULT_BG_DARK_COLOR;

    const onChangeColor = (color: string | undefined) => {
        const updatedOptions = produce(channelOptions, (draft) => {
            const channelOption = draft.find((o) => o.channel === channel);
            if (!channelOption) {
                draft.push({
                    channel,
                    options: {
                        menuBoardBackgroundColor: color,
                    },
                });
            } else {
                channelOption.options.menuBoardBackgroundColor = color;
            }
        });
        updateDeviceField("channelOptions", updatedOptions);
    };

    return (
        <ColorPicker
            label="Background Color"
            description="If not set, headers will use default colors based on the theme"
            defaultValue={defaultColor}
            value={value}
            onChange={onChangeColor}
        />
    );
};

export const MenuBoardProductBackgroundColorPicker = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const channelOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    const option = channelOptions.find((o) => o.channel === channel);

    const value = option?.options?.productBackgroundColor;

    const theme = option?.options?.theme ?? ChannelTheme.LIGHT;

    const defaultColor =
        theme === ChannelTheme.LIGHT
            ? DEFAULT_TILE_BG_LIGHT_COLOR
            : DEFAULT_TILE_BG_DARK_COLOR;

    const onChangeColor = (color: string | undefined) => {
        let resetColor = false;

        if (color === undefined) {
            resetColor = true;
        }

        const updatedOptions = produce(channelOptions, (draft) => {
            const channelOption = draft.find((o) => o.channel === channel);
            if (!channelOption) {
                draft.push({
                    channel,
                    options: {
                        productBackgroundColor: resetColor ? undefined : color,
                    },
                });
            } else {
                channelOption.options.productBackgroundColor = resetColor
                    ? undefined
                    : color;
            }
        });
        updateDeviceField("channelOptions", updatedOptions);
    };

    return (
        <ColorPicker
            label="Tile Background Color"
            description="If not set, a transparent background will be used for each product"
            onChange={onChangeColor}
            value={value}
            defaultValue={defaultColor}
        />
    );
};

const StyledChromePicker = styled(ChromePicker)`
    box-shadow: none !important;

    div > input {
        padding: 1px;
    }
`;
