import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

import Text from "#payouts/components/shared/Text";

export const PageHeader = () => (
    <PageHeaderWrapper>
        <TitleContainer>
            <Text.Title2>
                <>Tax Forms</>
            </Text.Title2>
            <SubtitleContainer>
                <Text.Subtitle>
                    <>
                        Snackpass issues 1099-K forms. Form 1099-K summarizes
                        the payments you received for goods or services during
                        the year. The information is provided to the IRS, as
                        well as some US states.
                    </>
                    <br />
                    <br />
                    <>
                        Per IRS guidelines for 2023, a store will qualify for a
                        1099-K if it receives over $20,000 and has over 200
                        transactions based on a single TIN.
                    </>
                </Text.Subtitle>
            </SubtitleContainer>
        </TitleContainer>
    </PageHeaderWrapper>
);

const TitleContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
`;

const SubtitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;

    h2 {
        margin: auto 0;
    }
`;

const PageHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    width: 100%;
    justify-content: space-between;

    margin: ${({ theme }) =>
        `${theme.spacing.base} ${theme.spacing.quadruple}`};

    & .container-fluid {
        padding: 0;
    }

    @media ${ScreenState.DESKTOP} {
        max-width: 600px;
    }

    @media ${ScreenState.MOBILE} {
        max-width: 92.5%;
        margin-left: ${({ theme }) => theme.spacing.base};
        margin-right: ${({ theme }) => theme.spacing.base};
    }

    @media ${ScreenState.TABLET} {
        max-width: 95%;
        margin-left: ${({ theme }) => theme.spacing.baseAndAHalf};
        margin-right: ${({ theme }) => theme.spacing.tripe};
    }
`;
