import { PurchaseReportData } from "@snackpass/snackpass-types";
import { ColumnDef } from "@tanstack/react-table";
import { compareAsc } from "date-fns";
import moment from "moment";

import { OrderHistoryDetailsButton } from "#orders/components/order-history/button";
import { Status } from "#orders/components/order-history/order-status";
import {
    formatPurchaseChannel,
    formatTransactionSource,
    nameToFirstNameLastInitial,
    formatFulfillment,
} from "#utils/helpers";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";

import { Amount } from "./amount";

export const columns: ColumnDef<PurchaseReportData>[] = [
    {
        id: "Details",
        header: () => <></>,
        enableHiding: false,
        cell: (props) => (
            <OrderHistoryDetailsButton
                text={"View"}
                value={props.row.original?.purchaseId ?? ""}
            />
        ),
    },
    {
        id: "Order Number",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Order #"
            />
        ),
        accessorKey: "purchase.receiptNumber",
        cell: (props) => (
            <>#{props.row.original?.purchase?.receiptNumber ?? "--"}</>
        ),
    },
    {
        id: "Ordered At",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Ordered At"
            />
        ),
        sortingFn: (a, b) =>
            compareAsc(
                moment(a.original.dateReceived).toDate(),
                moment(b.original.dateReceived).toDate(),
            ),
        accessorKey: "createdAt",
        cell: (props) => (
            <div className="whitespace-nowrap">
                {moment(props.row.original?.dateReceived).format(
                    "MM/DD/YY, h:mm a",
                )}
            </div>
        ),
    },
    {
        id: "Pickup Time",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Pickup Time"
            />
        ),
        sortingFn: (a, b) =>
            compareAsc(
                moment(a.original.pickupTime).toDate(),
                moment(b.original.pickupTime).toDate(),
            ),
        accessorKey: "pickupTime",
        cell: (props) =>
            props.row.original?.pickupTime
                ? moment(props.row.original?.pickupTime).format("h:mm a ")
                : "—",
    },

    {
        id: "Customer",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Customer"
            />
        ),
        accessorKey: "customerName",
        // filtering by both phone number and customer name so the search will look at both.
        filterFn: (row, _, value) => {
            if (!row.original.customerName) {
                return false;
            }
            return (
                row.original.customerName
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                !!row.original.customerNumber?.includes(value)
            );
        },
        cell: (props) =>
            props.row.original.tableNumber &&
            /^Table/.test(props.row.original.customerName)
                ? "—"
                : nameToFirstNameLastInitial(props.row.original?.customerName),
    },
    {
        id: "Order Status",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Order Status"
            />
        ),
        accessorKey: "status",
        cell: (props) => <Status purchaseReportData={props.row.original} />,
    },
    {
        id: "Channel",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Channel"
            />
        ),
        accessorKey: "source",
        cell: (props) => (
            <div>
                {formatTransactionSource(
                    props.row.original?.source,
                    props.row.original?.purchase?.isPOSMode,
                )}
            </div>
        ),
    },
    {
        id: "Fulfillments",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Fulfillment"
            />
        ),
        accessorKey: "fulfillment",
        cell: (props) => (
            <div>{formatFulfillment(props.row.original?.fulfillment)}</div>
        ),
    },
    {
        id: "Table Number",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Table"
            />
        ),
        accessorKey: "tableNumber",
        cell: (props) => <div>{props.row.original?.tableNumber}</div>,
    },
    {
        id: "Channel",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Channel"
            />
        ),
        accessorKey: "channel",
        cell: (props) => (
            <div>{formatPurchaseChannel(props.row.original?.purchase)}</div>
        ),
    },
    {
        id: "Subtotal",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Subtotal"
            />
        ),
        accessorKey: "subtotal",
        cell: (props) => (
            <Amount
                amount={props.row.original?.subtotal ?? 0}
                isDollar={true}
            />
        ),
    },
    {
        id: "Custom Surcharge",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Custom Surcharge"
            />
        ),
        accessorKey: "customSurchargeTotal",
        cell: (props) => (
            <Amount
                amount={props.row.original?.customSurchargeTotal || 0}
                isDollar={true}
            />
        ),
    },
    {
        id: "Custom Discounts",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Custom Discounts"
            />
        ),
        accessorKey: "customDiscountsTotal",
        cell: (props) => (
            <Amount
                amount={props.row.original?.customDiscountsTotal || 0}
                isDollar={true}
            />
        ),
    },
    {
        id: "Tips",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Tips"
            />
        ),
        accessorKey: "tip",

        cell: (props) => (
            <Amount amount={props.row.original?.tip ?? 0} isDollar={true} />
        ),
    },
    {
        id: "Taxes & Fees",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Taxes & Fees"
            />
        ),
        accessorKey: "taxesAndFees",
        cell: (props) => (
            <div className="flex justify-center items-center w-full">
                <Amount
                    amount={props.row.original?.taxesAndFees ?? 0}
                    isDollar={true}
                />
            </div>
        ),
    },
    {
        id: "Order Tax Rate",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Order Tax Rate"
            />
        ),
        accessorKey: "salesTaxRate",
        cell: (props) => (
            <div className="text-center w-full">
                {`${props.row.original?.salesTaxRate}%`}
            </div>
        ),
    },
    {
        id: "Total",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Total"
            />
        ),
        accessorKey: "total",
        cell: (props) => (
            <Amount amount={props.row.original?.total ?? 0} isDollar={true} />
        ),
    },
    {
        id: "Scheduled",
        header: ({ column }) => (
            <DataTableColumnHeader
                buttonClassName="bg-transparent"
                hideIconUntilSorted
                column={column}
                title="Scheduled"
            />
        ),
        sortingFn: (a, b) =>
            compareAsc(
                moment(a.original.purchase?.scheduledDate || 0).toDate(),
                moment(b.original.purchase?.scheduledDate || 0).toDate(),
            ),
        accessorKey: "purchase.scheduledDate",
        cell: (props) =>
            props.row.original?.purchase?.scheduledDate ? (
                <div className="whitespace-nowrap">
                    {moment(props.row.original.purchase.scheduledDate).format(
                        "MM/DD/YY, h:mm a",
                    )}
                </div>
            ) : (
                "—"
            ),
    },
];
