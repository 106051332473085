import { Divider } from "antd";

import theme from "#onboarding/utils/theme";

export const RowDivider = () => (
    <Divider
        style={{
            marginTop: theme.spacing.base,
            marginBottom: theme.spacing.base,
        }}
    />
);
