import React from "react";
import styled from "styled-components";

import { Loading } from "#onboarding/components/shared/Loading";

type ButtonProps = {
    variant: "primary" | "secondary" | "tertiary" | "danger" | "dangerOutline";
    size?: "large" | "regular" | "small" | "micro";
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    /** whether to make the button display block (full width in its container) or inline; inline is default */
    block?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
    smallRadius?: boolean;
    type?: "button" | "submit";
    isMobile?: boolean;
    loading?: boolean;
    loadingColor?: string;
    backgroundColor?: string;
    style?: Record<string, unknown>;
    isValid?: boolean;
};

const StyledButton = styled.button<ButtonProps>`
    // destructure so we dont have to repeat theme destructure several times
    ${({
        theme,
        variant,
        size,
        block,
        smallRadius,
        isMobile,
        backgroundColor,
        isValid = false,
    }) => `
      opacity: ${isValid ? "1.0" : "0.3"};
      font-family: 'Inter';
      font-size: ${theme.buttons.sizes[size || "regular"].fontSize};
      line-height: ${theme.buttons.sizes[size || "regular"].lineHeight};
      padding: ${theme.buttons.sizes[size || "regular"].padding};
      font-weight: 400;
      appearance: button;
      cursor: pointer;
      box-shadow: ${theme.buttons.defaultBoxShadow};
      background-color: ${
          backgroundColor || theme.buttons.variant[variant].backgroundColor
      };
      border: 1px solid ${
          backgroundColor || theme.buttons.variant[variant].borderColor
      };
      color: ${theme.buttons.variant[variant].color};
      border-radius: ${
          isMobile
              ? "96px"
              : smallRadius
                ? theme.spacing.half
                : theme.spacing.triple
      };
      ${block ? "display: block; width: 100%" : "display: inline-flex"};
      justify-content: center;
      align-items: center;
      gap: ${theme.spacing.half};
      transition: all .2s ease-in-out;
      
      width: ${theme.buttons.sizes[size || "regular"].width};
      height: ${theme.buttons.sizes[size || "regular"].height};

      &:hover {
        box-shadow: ${theme.buttons.hoverBoxShadow};
      }

      &:disabled {
        cursor: not-allowed;
        touch-events: none;
        background-color: ${
            theme.buttons.variant[variant].inactiveBackgroundColor
        };
        border-color: ${theme.buttons.variant[variant].inactiveBorderColor};
        color: ${theme.buttons.variant[variant].inactiveColor};
      }

      & + button {
        margin-left: ${theme.spacing.base};
      }
       
      & svg {
        fill: currentColor;
      }


  `}
`;

export const UpdatedButton = ({
    loadingColor,
    ...props
}: ButtonProps): JSX.Element => (
    <StyledButton {...props}>
        {props.loading ? (
            <Loading small color={loadingColor} />
        ) : (
            <>
                {props.iconLeft || null}
                {props.children}
                {props.iconRight || null}
            </>
        )}
    </StyledButton>
);
