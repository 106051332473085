import { useCallback, useState } from "react";

import {
    fontSizes,
    fontWeights,
    lineHeights,
    textColor,
} from "#menu-editor/multi-menus/styled-components/text";
import SearchBar, { SearchBarStyleProps } from "#reusable/input/search-bar";

type TableSearchBarProps = {
    globalFilter: string;
    debouncedSetGlobalFilter: Function;
};

export const TableSearchBar: React.FC<TableSearchBarProps> = ({
    globalFilter,
    debouncedSetGlobalFilter,
}) => {
    const [value, setValue] = useState(globalFilter);

    const onChange = useCallback(
        (value: string) => {
            setValue(value);
            debouncedSetGlobalFilter(value || null);
        },
        [setValue, debouncedSetGlobalFilter],
    );

    return (
        <SearchBar
            value={value}
            onChange={onChange}
            placeHolder="Search Categories and Items"
            style={searchBarStyles}
        />
    );
};

const searchBarStyles: SearchBarStyleProps = {
    color: textColor("black"),
    placeholderColor: textColor("grey"),
    lineHeight: lineHeights("large-regular"),
    fontSize: fontSizes("large-regular"),
    fontWeight: fontWeights("large-regular"),
    width: 500,
};
