/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";

import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";

const HeaderStyles = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    font-weight: 700;

    .text-string {
        font-size: 24px;
        @media ${ScreenState.TABLET} {
            font-size: 20px;
        }
    }
    .chevron {
        cursor: pointer;
        margin-right: 15px;
    }
`;

interface Props {
    title: string;
    handleClick: () => void;
}

export function Header({ title, handleClick }: Props) {
    return (
        <div css={HeaderStyles}>
            <div className="chevron" onClick={handleClick}>
                <Chevron fill={SystemColors.v1.sesame} />
            </div>
            <span className="text-string">{title}</span>
        </div>
    );
}
