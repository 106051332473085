import React from "react";
import { Spinner } from "react-activity";

import "react-activity/lib/Spinner/Spinner.css";
import { colors } from "#utils/colors";

interface IButton {
    isLoading?: boolean;
    disabled?: boolean;
    label?: string | any;
    labelColor?: string;
    backgroundColor?: string;
    style?: any;
    onPress?: (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void | Promise<void>;
    children?: any;
    containerStyle?: any;
}

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const Button = ({
    isLoading = false,
    label,
    labelColor = "white",
    backgroundColor = colors.blue,
    style,
    disabled = false,
    onPress,
    children,
    containerStyle,
}: IButton) => {
    const isDisabled = disabled || isLoading;
    const computedBackgroundColor = isDisabled ? colors.gray : backgroundColor;

    return (
        <div style={{ display: "inline-block", ...containerStyle }}>
            <button
                className="-snackpass-btn"
                disabled={disabled || isLoading}
                onClick={onPress}
                style={{
                    backgroundColor: computedBackgroundColor,
                    color: labelColor as string,
                    ...style,
                }}
            >
                {isLoading ? <Spinner color="#ffffff" size={12} /> : label}
                {children}
            </button>
        </div>
    );
};
