import { useQuery } from "@tanstack/react-query";

interface LoyaltyTiers {
    total: number;
    inspire: number;
    indulge: number;
    mindful: number;
    basic: number;
    growth: Array<{
        date: string;
        all: number;
        inspire: number;
        indulge: number;
        mindful: number;
        basic: number;
    }>;
}

// Mock data
const mockLoyaltyData: LoyaltyTiers = {
    total: 12345,
    inspire: 2345,
    indulge: 4567,
    mindful: 6789,
    basic: 12345,
    growth: Array.from({ length: 30 }, (_, i) => {
        const date = new Date(Date.now() - (29 - i) * 24 * 60 * 60 * 1000)
            .toISOString()
            .split("T")[0];

        // Base values for each tier
        const baseInspire = 5000;
        const baseIndulge = 3500;
        const baseMindful = 2000;
        const baseBasic = 1000;

        // Random variations
        const variation = () => Math.floor(Math.random() * 500) - 250;

        // Growth trends
        const growthTrend = i * 50;

        const inspire = Math.max(0, baseInspire + variation() + growthTrend);
        const indulge = Math.max(0, baseIndulge + variation() + growthTrend);
        const mindful = Math.max(0, baseMindful + variation() + growthTrend);
        const basic = Math.max(0, baseBasic + variation() + growthTrend);

        return {
            date,
            all: inspire + indulge + mindful + basic,
            inspire,
            indulge,
            mindful,
            basic,
        };
    }),
};

export function useLoyaltyTiers() {
    const { data: tiers, isLoading } = useQuery<LoyaltyTiers>({
        queryKey: ["loyalty-tiers"],
        queryFn: async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return mockLoyaltyData;
        },
    });

    return {
        tiers,
        isLoading,
    };
}
