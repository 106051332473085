import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { genHash } from "#core/utils";
import { ReportGranularity } from "#financial-reports/types";

import {
    getDashboardReportsGranularity,
    setDashboardFilterGranularity,
} from "src/redux/slices";

export const useGranularityPicker = () => {
    const globalGranularity = useSelector(getDashboardReportsGranularity);
    const [granularity, setGranularity] = useState<ReportGranularity>("month");

    useEffect(() => {
        setGranularity(globalGranularity);
    }, [globalGranularity]);

    const dispatch = useDispatch();
    const setGlobalGranularity = (granularity: ReportGranularity) => {
        dispatch(setDashboardFilterGranularity(granularity));
    };

    return {
        granularity,
        setGlobalGranularity,
        hash: genHash(granularity),
    };
};
