import {
    GenericPromo,
    GiftCardPromo,
    RewardPromo,
} from "#promotion/utils/types";

export function isGenericPromo(
    state: GenericPromo | RewardPromo | GiftCardPromo,
): state is GenericPromo {
    return "AUDIENCE" in state;
}

export function isRewardPromo(
    state: GenericPromo | RewardPromo | GiftCardPromo,
): state is RewardPromo {
    return "POINTS_REQUIRED" in state;
}

export function isGiftCardPromo(
    state: GenericPromo | RewardPromo | GiftCardPromo,
): state is GiftCardPromo {
    return "MINIMUM_PRICE" in state;
}
