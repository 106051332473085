export enum BRAND_APP_FIELD_NAMES {
    LOGO_URL = "logoUrl",
    COVER_PHOTO_URL = "coverPhotoUrl",
    EMOJI = "emoji",
    BRAND_COLOR = "brandColor",
    STORE_SLUG = "storeSlug",
    CUSTOMER_PICKUP_SCREEN_MARKETING_URL = "customerPickupScreenMarketingUrl",
    BRAND_SPLASH_URL = "brandSplashUrl",
    KIOSK_SPLASH_VIDEO_URL = "kioskSplashVideoUrl",
    LOYALTY_EMOJI = "loyaltyEmoji",
    LOYALTY_CUSTOM_NAME = "loyaltyCustomName",
    LOYALTY_CARD_BACKGROUND_IMAGE = "loyaltyCardBackgroundImage",
    HIDE_BRANDING_IMAGES_ON_OO = "hideBrandingImagesOnOO",
    HAS_BRAND_SPLASH_OVERLAY_TEXT = "hasBrandSplashOverlayText",
    TIP_IMAGE_URL = "tipImageUrl",
    USE_CORPORATE_MAP = "useCorporateMap",
    MAP_ICON = "mapIcon",
    ABOUT_US = "aboutUs",
}

export type BrandAppearanceFormValues = {
    [BRAND_APP_FIELD_NAMES.LOGO_URL]: string;
    [BRAND_APP_FIELD_NAMES.COVER_PHOTO_URL]: string;
    [BRAND_APP_FIELD_NAMES.EMOJI]: string;
    [BRAND_APP_FIELD_NAMES.BRAND_COLOR]: string;
    [BRAND_APP_FIELD_NAMES.STORE_SLUG]: string;
    [BRAND_APP_FIELD_NAMES.CUSTOMER_PICKUP_SCREEN_MARKETING_URL]: string;
    [BRAND_APP_FIELD_NAMES.BRAND_SPLASH_URL]: string;
    [BRAND_APP_FIELD_NAMES.KIOSK_SPLASH_VIDEO_URL]: string;
    [BRAND_APP_FIELD_NAMES.LOYALTY_EMOJI]: string;
    [BRAND_APP_FIELD_NAMES.LOYALTY_CUSTOM_NAME]: string;
    [BRAND_APP_FIELD_NAMES.LOYALTY_CARD_BACKGROUND_IMAGE]: string;
    [BRAND_APP_FIELD_NAMES.HAS_BRAND_SPLASH_OVERLAY_TEXT]: boolean;
    [BRAND_APP_FIELD_NAMES.KIOSK_SPLASH_VIDEO_URL]: string;
    [BRAND_APP_FIELD_NAMES.HIDE_BRANDING_IMAGES_ON_OO]: boolean;
    [BRAND_APP_FIELD_NAMES.TIP_IMAGE_URL]: string;
    [BRAND_APP_FIELD_NAMES.USE_CORPORATE_MAP]: boolean;
    [BRAND_APP_FIELD_NAMES.MAP_ICON]: string;
    [BRAND_APP_FIELD_NAMES.ABOUT_US]: string;
};
