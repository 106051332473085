import React, { useCallback, useContext } from "react";
import { PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";

import { TooltipProvider } from "src/@/components/ui/tooltip";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { Button } from "src/@/components/ui/button";
import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger,
} from "src/@/components/ui/HybridTooltip";
import { cn } from "src/@/lib/utils";
import { UserFacingError } from "src/utils/errors";

const PageHeaderOptions = (): JSX.Element => {
    const { setAddNewDevicesModalOpen, refetch } =
        useContext(DevicesPageContext);

    const handleAddDeviceClick = useCallback(() => {
        setAddNewDevicesModalOpen(true);
    }, [setAddNewDevicesModalOpen]);

    const handleReload = async () => {
        try {
            await refetch();
            toast.success("Page refreshed!");
        } catch (error) {
            const description =
                error instanceof UserFacingError
                    ? error.message
                    : "Error while refetching. Please try again shortly.";
            toast.error(description);
        }
    };
    return (
        <div className="flex justify-between">
            <div className="text-large font-semibold">Devices</div>
            <div className="mb-2 flex gap-4">
                <TooltipProvider delayDuration={100}>
                    <HybridTooltip>
                        <HybridTooltipTrigger asChild>
                            <Button
                                className="rounded-full px-2"
                                variant="outline"
                            >
                                <ReloadIcon
                                    className="h-5 w-5"
                                    onClick={handleReload}
                                />
                            </Button>
                        </HybridTooltipTrigger>

                        <HybridTooltipContent
                            className={cn(
                                "z-100 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50",
                            )}
                            sideOffset={5}
                            side="bottom"
                        >
                            <p>Refresh</p>
                        </HybridTooltipContent>
                    </HybridTooltip>
                </TooltipProvider>
                <TooltipProvider delayDuration={100}>
                    <HybridTooltip>
                        <HybridTooltipTrigger asChild>
                            <Button
                                className="rounded-full px-2 sm:rounded-md"
                                onClick={handleAddDeviceClick}
                            >
                                <PlusIcon className="h-5 w-5 fill-white" />
                                <span className="hidden: ml-2  hidden sm:block">
                                    Add Device
                                </span>
                            </Button>
                        </HybridTooltipTrigger>

                        <HybridTooltipContent
                            className={cn(
                                "z-100 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50",
                            )}
                            sideOffset={5}
                            side="bottom"
                        >
                            <p>Add Device</p>
                        </HybridTooltipContent>
                    </HybridTooltip>
                </TooltipProvider>
            </div>
        </div>
    );
};

export default PageHeaderOptions;
