import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";
import {
    NameType,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from "recharts";

import { ReportsContext } from "#app/reports-context-provider";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType,
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";
import { CHART_COLORS, loopColorsToLength } from "#reports/location-sales/lib";
import { calculateChartWidth } from "#utils/helpers";
import { ChartConfig, ChartContainer } from "src/@/components/ui/chart";

const chartConfig = {} satisfies ChartConfig;

// the height of one bar in our bar chart
const BAR_HEIGHT = 50;

// the number of rows from the bottom that the tooltip can go-- i.e, the tooltip will remain fixed at 6 below the bottom of the chart for the last 4 rows.
const NUMBER_OF_TOOLTIP_PADDING_ROWS = 4;

const LocationNetSalesChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { locationReportsData, filter, stores } = reportsState;
    const data = locationReportsData?.salesReportData;

    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    const storeIdToName = useMemo(
        () =>
            stores.reduce<Record<string, string>>((acc, store) => {
                acc[store._id] = store.name;
                return acc;
            }, {}),
        [stores],
    );

    const categories = useMemo(
        () => filter.storeIds.map((id) => storeIdToName[id] ?? ""),
        [filter, stores],
    );

    const chartData = useMemo(() => {
        if (!data) return [];
        return Object.entries(data || {})
            .filter(
                ([id, _]) =>
                    filter.storeIds.includes(id) || filter.storeIds.length == 0,
            )
            .map(([id, storeData], index) => {
                const storeName = storeIdToName[id] ?? "";
                return {
                    // passing some values in for tooltip context
                    index,
                    totalStores: filter.storeIds.length,
                    [storeName]:
                        graphType === ChartType.NET_SALES
                            ? storeData.netSales || 0
                            : storeData.orders,
                    storeName,

                    netSales: storeData.netSales,
                    orders: storeData.orders,
                };
            });
    }, [data, graphType, filter, storeIdToName]);

    const colors = useMemo(
        () => loopColorsToLength(filter.storeIds.length),
        [filter],
    );

    const valueFormatter =
        graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    // calculate by store name length
    const yWidth = (): number => {
        const maxStoreNameLength = Math.max(
            ...chartData.map((item) => item.storeName.length),
        );
        return calculateChartWidth(maxStoreNameLength, false);
    };

    return (
        <div className="my-10">
            <div className="flex items-center justify-between pb-4">
                <h4 className="mb-2 text-large">Sales by Location</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <div
                className="w-full overflow-x-scroll"
                style={{ height: BAR_HEIGHT * filter.storeIds.length }}
            >
                {locationReportsData?.salesReportDataLoading ||
                (!locationReportsData?.salesReportData &&
                    !locationReportsData?.salesReportDataFailed) ? (
                    <Skeleton className="h-full rounded-md" />
                ) : !locationReportsData?.salesReportData ? (
                    <ErrorChart className="h-96 rounded-md" />
                ) : (
                    <ChartContainer
                        className="h-full w-full"
                        config={chartConfig}
                    >
                        <BarChart
                            accessibilityLayer
                            data={chartData}
                            layout="vertical"
                        >
                            <XAxis
                                type="number"
                                tickLine={false}
                                axisLine={false}
                                tickFormatter={valueFormatter}
                            />
                            <CartesianGrid vertical={false} />
                            <YAxis
                                type="category"
                                dataKey="storeName"
                                axisLine={false}
                                width={yWidth()}
                            />
                            <Tooltip content={CustomTooltip} />
                            {categories.map((category, idx) => (
                                <Bar
                                    stackId="stack"
                                    key={category}
                                    dataKey={category}
                                    // These colors are in our safelist!
                                    className={`fill-${colors[idx]}-500`}
                                    /* Fill is necessary here because the tooltip
                                    needs it to be set in order to access the `color` attribute */
                                    fill={colors[idx]}
                                    strokeWidth={2}
                                    radius={4}
                                />
                            ))}
                        </BarChart>
                    </ChartContainer>
                )}
            </div>
        </div>
    );
};

const CustomTooltip = ({
    payload,
    active,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload) return null;

    const rowIndex = payload[0].payload.index;
    const totalStores = payload[0].payload.totalStores;

    // By default, tremor's tooltips do not move down with the selected row, because it assumes a fixed chart height.
    // So, we manually move the tooltip down by a calculated offset.
    // We clamp between 0 and the total minus some padding rows to avoid the tooltip from going off of the chart and getting cut off.
    const offset =
        BAR_HEIGHT *
        Math.max(
            Math.min(rowIndex, totalStores - NUMBER_OF_TOOLTIP_PADDING_ROWS),
            0,
        );

    return (
        <div
            style={{ marginTop: offset }}
            className="z-[9999] flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown"
        >
            <p className="font-semibold">{label}</p>
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div
                        className={clsx(
                            "flex w-1 flex-col rounded",
                            CHART_COLORS.find((e) => e.color === category.color)
                                ?.className,
                        )}
                    />
                    <div className="w-full space-y-1">
                        <div className="text-neutral-600">Net Sales</div>
                        <div className="font-medium text-neutral-800">
                            {toDollarFormatted(category.payload.netSales)}
                        </div>
                        <div className="text-neutral-600">Orders</div>
                        <div className="font-medium text-neutral-800">
                            {formatNumber(category.payload.orders)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LocationNetSalesChart;
