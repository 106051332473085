export const PayoutItem = ({
    label,
    value,
}: {
    label: string;
    value?: string;
}) => (
    <div>
        <span className="font-semibold">{label}</span>:{" "}
        <span>{value?.length ? value : "None"}</span>
    </div>
);
