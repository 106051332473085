import React from "react";
import { Select } from "antd";

/**
 * Note that the age limit for Stripe is 13 years, no matter what we
 * allow people to submit here.
 */

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const years = (() => {
    const years = [];
    for (let y = 2023; y > 1900; y--) {
        years.push(y);
    }
    return years;
})();

const days = (() => {
    const days = [];
    for (let d = 1; d <= 31; d++) {
        days.push(d);
    }
    return days;
})();

export const monthOptions = months.map((month, i) => (
    <Select.Option key={month.trim().toLowerCase()} value={i + 1}>
        {month.trim()}
    </Select.Option>
));

export const dayOptions = days.map((day) => (
    <Select.Option key={day} value={day}>
        {day}
    </Select.Option>
));

export const yearOptions = years.map((year) => (
    <Select.Option key={year} value={year}>
        {year}
    </Select.Option>
));
