import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & * {
        font-family: "Inter";
    }

    margin: ${({ theme }) =>
        `${theme.spacing.baseAndAHalf} ${theme.spacing.triple}`};

    @media ${ScreenState.MOBILE} {
        margin: ${({ theme }) =>
            `${theme.spacing.triple} ${theme.spacing.threeQuarter}`};
        margin-top: 20px;
        margin-bottom: 20px;
    }

    & .form-control,
    & .form-select {
        font-family: "Inter";
    }
`;

export default PageWrapper;
