import React from "react";
import styled from "styled-components";
import { Divider } from "antd";

import { Header } from "#billing/components/Header";

export const Wrapper = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => (
    <Container>
        <Header />
        <Divider />
        {children}
    </Container>
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    margin: ${({ theme }) => `${theme.spacing.half} 0`};

    & .form-control,
    & .form-select {
        font-family: "Inter";
    }
`;
