import React, { useCallback, useContext, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import FilterHeader, {
    FilterType,
} from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportsContext } from "#app/reports-context-provider";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import GiftCardTransactionTable from "#reports/gift-card-transactions/components/GiftCardTransactionTable";

const GiftCardTransactionReport = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, filter, stores } = reportsState;

    const trackEvent = useTrackEvent();

    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.giftCardTransactionReportData) {
            // set loading
            setReportsState((reportsState) => ({
                ...reportsState,
                giftCardTransactionReportData: {
                    giftCardTransactionsDataLoading: true,
                    giftCardTransactionsDataFailed: false,
                    giftCardTransactionsData:
                        reportsState?.giftCardTransactionReportData
                            ?.giftCardTransactionsData,
                },
            }));
            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = async () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        const params = {
            storeIds: JSON.stringify(
                stores.length ? filter.storeIds : [activeStore?._id],
            ),
            startDate: reportsState.allTime
                ? undefined
                : dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: (reportsState.allTime
                ? moment()
                : dateRanges[0][1]
            ).format("YYYY-MM-DD"),
            storeId: activeStore?._id,
        };

        api.reports
            .getGiftCardTransactions(params, abortController.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    giftCardTransactionReportData: {
                        giftCardTransactionsDataLoading: false,
                        giftCardTransactionsDataFailed: false,
                        giftCardTransactionsData:
                            res.data.giftCardTransactionReport,
                    },
                }));
            })
            .catch((err) => {
                if (axios.isCancel(err?.cause)) return;
                setReportsState((reportsState) => ({
                    ...reportsState,
                    giftCardTransactionReportData: {
                        giftCardTransactionsDataLoading: false,
                        giftCardTransactionsDataFailed: true,
                        giftCardTransactionsData: undefined,
                    },
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);
    const fetchDataDebounced = useCallback(
        debounce(async () => fetchDataRef.current?.(), 600),
        [],
    );

    return (
        <div>
            <FilterHeader
                hideFilters={[
                    FilterType.CHANNEL,
                    FilterType.GRANULARITY,
                    FilterType.FULFILLMENT,
                    FilterType.PLATFORM,
                ]}
                reportType={ReportType.GIFT_CARD_BALANCE_REPORT}
                showAllTimeRange
                showLocationFilter
            />
            <div className="px-6 md:px-24">
                <h4 className="my-5 text-large sm:mr-5">
                    Gift Card Transactions
                </h4>
                <GiftCardTransactionTable />
            </div>
        </div>
    );
};

export default GiftCardTransactionReport;
