import { Divider } from "antd";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { SettingsBlock } from "#payouts/components/shared/SettingsBlock";
import { Setting, StatusReasons, Statuses } from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import { notifyFailure, notifySuccess } from "#payouts/utils/notify";
import { handleException } from "#payouts/utils/handleException";
import { Button } from "#payouts/components/shared/Button";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { useAccount } from "#payouts/utils/hooks/useAccount";

export const AccountSummary = () => {
    const { isMobile } = useWindowDimensions();

    const { editEnabled, isSeniorSupport, verification, handleModalChange } =
        useContext(PayoutsSettingsContext);

    const account = useAccount();

    const isVerified =
        verification?.status === Statuses.enabled &&
        verification?.statusReason === StatusReasons.approved;

    // Disable once account provided to prevent fraudulent changes, unless
    // additional information required.
    const disabled =
        (!editEnabled || isVerified || account.started) && !account.required;

    const settings: Setting[] = account.started
        ? [
              {
                  field: "routingNumber",
                  label: "Routing Number",
                  value: verification?.routingNumber,
                  encrypted: true,
              },
              {
                  field: "accountNumber",
                  label: "Account Number",
                  value: verification?.accountNumber,
                  encrypted: true,
                  encryptedValue: `*****${verification?.accountLast4}`,
              },
          ]
        : [];

    if (account.started && (account.provided || account.required)) {
        const documentError = verification?.verificationErrors?.find((e) =>
            e.requirement.includes("bank_account_ownership_verification"),
        )?.reason;

        settings.push({
            field: "document",
            label: "Bank Account Verification Documents",
            value: account.required ? undefined : verification?.document?.name,
            error: documentError,
            encrypted: true,
        });
    }

    return (
        <SummaryDiv>
            <SettingsBlock
                start={!account.started}
                disabled={disabled}
                title={
                    <div className="my-auto flex flex-row justify-center gap-2">
                        <span className="my-auto flex flex-col">
                            {isMobile ? "Bank" : "Bank Account"}
                        </span>
                    </div>
                }
                onClick={() => handleModalChange({ account: true })}
                settings={settings}
                tooltip={
                    account.started
                        ? "If you wish to change your banking information, please contact partners@snackpass.co"
                        : undefined
                }
                extraButton={
                    account.started ? (
                        <RemoveBankAccount disabled={!isSeniorSupport} />
                    ) : null
                }
            />
            {account.started ? <Divider /> : <></>}
        </SummaryDiv>
    );
};

const SummaryDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;

const RemoveBankAccount = ({ disabled = false }: { disabled: boolean }) => {
    const store = useSelector(getActiveStore);
    const { verification, setVerification } = useContext(
        PayoutsSettingsContext,
    );
    const [loading, setLoading] = React.useState(false);

    const handleClickRemoveBankAccount = async () => {
        if (!store?._id || loading || !verification) return;
        setLoading(true);

        if (
            !window.confirm(
                `Are you sure you want to remove banking information for ${
                    store?.name || "this store"
                }?`,
            )
        ) {
            setLoading(false);
            return;
        }

        try {
            await api.verifications.removeBankAccount(store?._id);
            setVerification({
                ...verification,
                routingNumber: undefined,
                accountNumber: undefined,
                accountLast4: undefined,
            });
            notifySuccess({
                message: "Success",
                description: "Bank account removed.",
            });
        } catch (e) {
            notifyFailure({
                message: "Error",
                description: "Unable to remove bank account.",
            });
            handleException(e as Record<string, unknown>);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            smallRadius
            variant="dangerOutline"
            size="small"
            disabled={disabled || loading}
            loading={loading}
            loadingColor="red"
            children={<>Remove</>}
            onClick={handleClickRemoveBankAccount}
        />
    );
};
