import { captureException } from "@sentry/react";
import { IStore } from "@snackpass/snackpass-types";

import api from "src/api/rest";
import {
    BrandAppearanceFormValues,
    BRAND_APP_FIELD_NAMES,
} from "#settings/settings-brand-and-appearance/types";

type DefaultValues = Partial<BrandAppearanceFormValues>;

export const getFormDefaultValuesForStore = (
    store: IStore | null,
    defaultValues: DefaultValues,
) => {
    const overwrites: Partial<DefaultValues> = {};
    if (store?.logoUrl) {
        overwrites[BRAND_APP_FIELD_NAMES.LOGO_URL] = store.logoUrl;
    }
    if (store?.thumbnailUrl) {
        // Note: In RDB, Cover Photo maps to store's thumbnailUrl
        // store.coverPhoto is being deprecated
        overwrites[BRAND_APP_FIELD_NAMES.COVER_PHOTO_URL] = store.thumbnailUrl;
    }
    if (store?.emoji) {
        overwrites[BRAND_APP_FIELD_NAMES.EMOJI] = store.emoji;
    }
    if (store?.brandColor) {
        overwrites[BRAND_APP_FIELD_NAMES.BRAND_COLOR] = store.brandColor;
    } else if (store?.color) {
        // Fallback to color if brandColor doesn't exist
        overwrites[BRAND_APP_FIELD_NAMES.BRAND_COLOR] = store.color;
    }
    if (store?.slug) {
        overwrites[BRAND_APP_FIELD_NAMES.STORE_SLUG] = store.slug;
    }
    if (store?.brandCustomerPickupScreenMarketingUrl) {
        overwrites[BRAND_APP_FIELD_NAMES.CUSTOMER_PICKUP_SCREEN_MARKETING_URL] =
            store.brandCustomerPickupScreenMarketingUrl;
    }
    if (store?.brandSplashUrl) {
        overwrites[BRAND_APP_FIELD_NAMES.BRAND_SPLASH_URL] =
            store.brandSplashUrl;
    }
    if (store?.kioskPreferences?.attractScreenUrl) {
        overwrites[BRAND_APP_FIELD_NAMES.KIOSK_SPLASH_VIDEO_URL] =
            store.kioskPreferences.attractScreenUrl;
    }

    if (store?.loyaltyEmoji) {
        overwrites[BRAND_APP_FIELD_NAMES.LOYALTY_EMOJI] = store.loyaltyEmoji;
    }
    if (store?.loyaltyCustomName) {
        overwrites[BRAND_APP_FIELD_NAMES.LOYALTY_CUSTOM_NAME] =
            store.loyaltyCustomName;
    }
    if (store?.loyaltyCardBackgroundImage) {
        overwrites[BRAND_APP_FIELD_NAMES.LOYALTY_CARD_BACKGROUND_IMAGE] =
            store.loyaltyCardBackgroundImage;
    }
    if (store?.hasBrandSplashOverlayText) {
        overwrites[BRAND_APP_FIELD_NAMES.HAS_BRAND_SPLASH_OVERLAY_TEXT] =
            store.hasBrandSplashOverlayText;
    }
    if (store?.tipImageUrl) {
        overwrites[BRAND_APP_FIELD_NAMES.TIP_IMAGE_URL] = store.tipImageUrl;
    }
    if (store?.useCorporateMap) {
        overwrites[BRAND_APP_FIELD_NAMES.USE_CORPORATE_MAP] =
            store.useCorporateMap;
    }
    if (store?.mapIcon) {
        overwrites[BRAND_APP_FIELD_NAMES.MAP_ICON] = store.mapIcon;
    }
    if (store?.aboutUs) {
        overwrites[BRAND_APP_FIELD_NAMES.ABOUT_US] = store.aboutUs;
    }
    return { ...defaultValues, ...overwrites };
};

const translateFormToStoreObject = (
    store: IStore,
    formValues: BrandAppearanceFormValues,
) =>
    ({
        logoUrl: formValues[BRAND_APP_FIELD_NAMES.LOGO_URL],
        thumbnailUrl: formValues[BRAND_APP_FIELD_NAMES.COVER_PHOTO_URL],
        emoji: formValues[BRAND_APP_FIELD_NAMES.EMOJI] || store.emoji,
        brandColor:
            formValues[BRAND_APP_FIELD_NAMES.BRAND_COLOR] || store.brandColor,
        color: formValues[BRAND_APP_FIELD_NAMES.BRAND_COLOR] || store.color,
        slug: formValues[BRAND_APP_FIELD_NAMES.STORE_SLUG] || store.slug,
        brandCustomerPickupScreenMarketingUrl:
            formValues[
                BRAND_APP_FIELD_NAMES.CUSTOMER_PICKUP_SCREEN_MARKETING_URL
            ],
        brandSplashUrl: formValues[BRAND_APP_FIELD_NAMES.BRAND_SPLASH_URL],
        kioskPreferences: {
            ...(store.kioskPreferences ?? {}),
            attractScreenUrl:
                formValues[BRAND_APP_FIELD_NAMES.KIOSK_SPLASH_VIDEO_URL],
        },
        loyaltyEmoji:
            formValues[BRAND_APP_FIELD_NAMES.LOYALTY_EMOJI] ||
            store?.loyaltyEmoji,
        loyaltyCustomName:
            formValues[BRAND_APP_FIELD_NAMES.LOYALTY_CUSTOM_NAME] ||
            store?.loyaltyCustomName,
        loyaltyCardBackgroundImage:
            formValues[BRAND_APP_FIELD_NAMES.LOYALTY_CARD_BACKGROUND_IMAGE],
        hasBrandSplashOverlayText:
            formValues[BRAND_APP_FIELD_NAMES.HAS_BRAND_SPLASH_OVERLAY_TEXT],
        tipImageUrl: formValues[BRAND_APP_FIELD_NAMES.TIP_IMAGE_URL] || "",
        useCorporateMap:
            formValues[BRAND_APP_FIELD_NAMES.USE_CORPORATE_MAP] || false,
        mapIcon: formValues[BRAND_APP_FIELD_NAMES.MAP_ICON],
        aboutUs: formValues[BRAND_APP_FIELD_NAMES.ABOUT_US] || "",
    }) as Partial<IStore>;

export const submitStoreUpdate = async (
    store: IStore,
    data: BrandAppearanceFormValues,
) => {
    try {
        return await api.stores.update(
            store._id,
            translateFormToStoreObject(store, data),
        );
    } catch (err: any) {
        captureException(Error(JSON.stringify(err)));
    }
};
