import { useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "src/@/components/ui/button";
import { hasSeniorSupportPermission } from "#payouts/utils/validation/permissions";
import { OwnershipTransferModal } from "#settings/components/ownership-transfer-modal";
import { getUserTeamPermission, useActiveUser } from "src/redux/selectors";

export const OwnershipTransferForm = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const isSPEmployee = useSelector(getUserTeamPermission);
    const user = useActiveUser();
    const isSeniorSupport = hasSeniorSupportPermission(user);

    if (!isSPEmployee) {
        return null;
    }

    return (
        <div className="mb-20 ml-20 flex flex-col">
            <OwnershipTransferModal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
            />
            <div className="my-2 pb-6 text-xlarge font-semibold leading-6">
                Store Ownership Transfer
            </div>
            <Button
                disabled={!isSeniorSupport}
                type="button"
                className="w-1/3"
                onClick={() => setModalOpen(true)}
            >
                Transfer Ownership
            </Button>
        </div>
    );
};
