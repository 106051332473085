import React from "react";
import { SystemColors } from "@snackpass/design-system";

import ArrowUpIcon from "src/assets/icons/arrow-up-white.png";
import { useConversationValidator } from "#guestbook/components/conversation-input/libs";

import CircleButton from "../styled-components/circle-button";

type SendButtonProps = {
    _onSubmit: () => Promise<void>;
    isLoading: boolean;
};

export const SendButton = ({ _onSubmit, isLoading }: SendButtonProps) => {
    const validation = useConversationValidator();
    return (
        <CircleButton
            disabled={!validation.isValid}
            isLoading={isLoading}
            disabledColor={SystemColors.v1.gray60}
            onClick={_onSubmit}
            icon={ArrowUpIcon}
        />
    );
};
