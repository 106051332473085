/** @jsxImportSource @emotion/react */
import "antd/dist/antd.css";
import _ from "lodash";
import { Button, Checkbox, Divider, Input, Row, Space, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
    IStore,
    ScreenState,
    TransactionSourceTypeEnum,
    MenuPlatform,
    MenuType,
    MenuTypeEnum,
    ITimeRangeSchema,
} from "@snackpass/snackpass-types";
import {
    checkOverlappingHours,
    validateHoursStartTimeBeforeEndTime,
} from "@snackpass/accounting/build/src/utils/Time";

import { getActiveThirdParty } from "#menu-editor/multi-menus/redux/selectors";
import { getCurrentMultiMenu } from "src/redux/selectors";
import { IncludesTypes } from "#menu-editor/multi-menus/redux/types";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import Switch from "#reusable/input/toggle-input";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

import {
    formatDayOfWeek,
    formatSpecialHours,
    formatTime,
    hoursHasCrossedDayRanges,
    parseCrossDayRanges,
    SpecialHoursItemType,
} from "#reusable/special-hours/helper";
import { MenuHourRows } from "#menu-editor/multi-menus/screens/multi-menu-settings/components/menu-hours";

import { checkValidationErrors } from "#menu-editor/multi-menus/screens/multi-menu-settings/utils/rule-validators";
import { useDelayUnmount } from "../hooks/useDelayUnmount";
import { PriceAdjustmentType } from "../types";
import { isThirdPartyMenu } from "../utils/platform-helpers";

import { RuleBanner } from "./rule-banners";
import { PriceAdjustmentModal } from "./price-adjustment-modal";
import {
    MenuAlert,
    menuAlertPropertyMap,
    MenuAlertTypes,
    RuleWarning,
} from "./rule-alerts";
import { Enable3PMenuModal } from "./enable-menu-modal";
import { DropDownSelectAdjustment } from "./price-adjustment-select";
import { logAndSendError } from "src/utils/errors";

const platformOptions = [
    // App and Kiosk disabled until clients have been migrated
    { label: "App", value: TransactionSourceTypeEnum.App, disabled: true },
    { label: "Kiosk", value: TransactionSourceTypeEnum.Kiosk, disabled: true },
    {
        label: "Online",
        value: TransactionSourceTypeEnum.Online,
        disabled: true,
    },
    { label: "Third Party", value: TransactionSourceTypeEnum.ThirdParty },
];

const typesOptions = [
    { label: "Standard", value: MenuTypeEnum.Standard },
    // INFO: 3P delivery platforms are not yet supported for catering.
    //       Currently the only supported platform for multi-menus is "Third Party".
    //       Once multi-menus is supported on app, online-ordering and/or kiosk,
    //       this option can be enabled.
    { label: "Catering", value: MenuTypeEnum.Catering, disabled: true },
];

type MenuDetailsProps = {
    activeStore: IStore | null;
    inEdit?: boolean;
    ruleAlertType: MenuAlert;
    setRuleAlertType: Dispatch<SetStateAction<MenuAlert>>;
    setIsDeleteModalOpen?: Dispatch<SetStateAction<boolean>>;
    setIsResetModalOpen?: Dispatch<SetStateAction<boolean>>;
    setIsPriceModalOpen: Dispatch<SetStateAction<boolean>>;
    isPriceModalOpen: boolean;
    onUpdatePrice: () => Promise<void>;
    isLoading: boolean;
    isMobile: boolean;
};

const ALERTS: MenuAlertTypes[] = [
    "no-name",
    "title-case-name",
    "no-time",
    "bad-price-adjustment",
    "no-platform",
    "third-party",
    "schedule-ahead",
    "has-overlapping-hours",
    "has-hours-start-time-before-end-time",
];

export const MenuDetailsForm: React.FC<MenuDetailsProps> = ({
    activeStore,
    inEdit,
    ruleAlertType,
    setRuleAlertType,
    setIsDeleteModalOpen,
    setIsResetModalOpen,
    setIsPriceModalOpen,
    isPriceModalOpen,
    onUpdatePrice,
    isLoading,
    isMobile,
}) => {
    const dispatch = useDispatch();
    const menuInEdit = useSelector(getCurrentMultiMenu);
    const active3PMenu = useSelector(getActiveThirdParty);

    useEffect(() => {
        if (!inEdit) dispatch(multiMenuActions.resetCurrentMenu());
    }, []);

    // Menu Details
    const [name, setName] = useState<string>("");
    const [enabled, setEnabled] = useState<boolean>(false);
    // Visibility
    const [type, setType] = useState<MenuType>(MenuTypeEnum.Standard);
    // Platforms
    const [platforms, setPlatforms] = useState<MenuPlatform[]>([]);
    // Price adjustment
    const [adjustment, setAdjustment] = useState<number>(
        PriceAdjustmentType.Markup,
    );
    const [includes, setIncludes] = useState<IncludesTypes>("both");
    const [amount, setAmount] = useState<number | null>(0);
    // Menu Hours
    const [localMenuHours, setLocalMenuHours] = useState<
        SpecialHoursItemType[]
    >([]);
    const [applyToOverrides, setApplyToOverrides] = useState<boolean>(false);
    const [is3PModalOpen, setIs3PModalOpen] = useState(false);

    const handleSetMenuHours = () => {
        // Will pick menu hours if they exist, otherwise picks the active store hours.
        const menuHours = menuInEdit.hours || activeStore?.hours;

        if (menuHours) {
            if (!_.isEmpty(menuHours.local)) {
                // check if each time range is within the same day
                // if not separate it to different days
                // this is necessary since RDB time range within a day, Snackface can cross days
                const hours: ITimeRangeSchema[] = [];
                menuHours.local.forEach((item) => {
                    if (hoursHasCrossedDayRanges(item)) {
                        hours.push(...parseCrossDayRanges(item));
                    } else {
                        hours.push(item);
                    }
                });

                const specialHours: SpecialHoursItemType[] = hours.map(
                    (item, i) => ({
                        id: i,
                        dayOfWeek: formatDayOfWeek(item.start),
                        time: {
                            start: formatTime(item.start),
                            end: formatTime(item.end),
                        },
                    }),
                );
                setLocalMenuHours(specialHours);
            } else {
                const hours: ITimeRangeSchema[] = [];
                activeStore?.hours.local.forEach((item) => {
                    if (hoursHasCrossedDayRanges(item)) {
                        hours.push(...parseCrossDayRanges(item));
                    } else {
                        hours.push(item);
                    }
                });

                const specialHours: SpecialHoursItemType[] = hours.map(
                    (item, i) => ({
                        id: i,
                        dayOfWeek: formatDayOfWeek(item.start),
                        time: {
                            start: formatTime(item.start),
                            end: formatTime(item.end),
                        },
                    }),
                );

                setLocalMenuHours(specialHours);
            }
        }
    };

    // Edit Menu fields
    useEffect(() => {
        if (inEdit) {
            setName(menuInEdit.name ?? "");
            setEnabled(menuInEdit.enabled ?? false);
            setType(menuInEdit.type ?? MenuTypeEnum.Standard);
            setPlatforms(menuInEdit.platforms ?? []);
            if (menuInEdit.priceAdjustment) {
                // amountEdit is effectively the percentage adjustment we want to apply to the price
                // 100% adjustment would be no change whereas 110% adjustment is a 10% markup
                // 90% adjustment is a 10% discount
                const amountEdit = menuInEdit.priceAdjustment.value;
                // hence the adjustment is derived purely from the value and is not stored server side
                setAdjustment(
                    amountEdit >= 1
                        ? PriceAdjustmentType.Markup
                        : PriceAdjustmentType.Markdown,
                );
                setIncludes(menuInEdit.priceAdjustment.scope);
                // here we take the percentage adjustment and convert it to what they want to see
                // i.e. 1.2 = 120% so a 20% mark up. So 1.2 - 1 * 100 = 20%
                // 0.8 = 80% so a 20% mark down. So 1 - 0.8 * 100 = 20%
                const difference =
                    (amountEdit > 1 ? amountEdit - 1 : 1 - amountEdit) * 100;
                const newAmount = Math.round(difference * 100) / 100; // Round to 2 decimal places
                setAmount(newAmount);
                setApplyToOverrides(
                    menuInEdit.priceAdjustment.applyToOverrides,
                );
            }
            setRuleAlertType(
                checkValidationErrors([], {
                    platforms: menuInEdit.platforms,
                    name: menuInEdit.name,
                }),
            );
        }
    }, [menuInEdit.id]);

    // Set current menu fields
    useEffect(() => {
        dispatch(multiMenuActions.setMultiMenuName({ name }));

        if (!name) {
            setRuleAlertType((ruleAlertType) => ({
                ...ruleAlertType,
                noName: true,
            }));
        }

        if (ruleAlertType.noName && name)
            setRuleAlertType((ruleAlertType) => ({
                ...ruleAlertType,
                noName: false,
            }));
    }, [name]);

    useEffect(() => {
        dispatch(multiMenuActions.activeMenu({ enabled }));
    }, [enabled]);

    const handleActiveChange = (isOn: boolean) => {
        if (
            !isOn ||
            !active3PMenu ||
            !platforms.includes(TransactionSourceTypeEnum.ThirdParty) ||
            (active3PMenu && active3PMenu.id === menuInEdit.id)
        ) {
            setEnabled(!enabled);
        } else setIs3PModalOpen(true);
    };

    const handleTypeChange = (checkedValues: CheckboxChangeEvent) => {
        setType(checkedValues.target.value);
    };

    useEffect(() => {
        dispatch(multiMenuActions.setMultiMenuType({ type }));
    }, [type]);

    const handlePlatformsChange = (checkedValues: CheckboxValueType[]) => {
        const selectedPlatforms = checkedValues as MenuPlatform[];
        setPlatforms((prev) => {
            if (!isThirdPartyMenu(prev)) {
                return isThirdPartyMenu(selectedPlatforms)
                    ? ["thirdParty"]
                    : selectedPlatforms;
            } else {
                return selectedPlatforms.filter(
                    (platform) => platform !== "thirdParty",
                );
            }
        });

        setRuleAlertType(
            checkValidationErrors([], { platforms: selectedPlatforms, name }),
        );
    };

    useEffect(() => {
        dispatch(multiMenuActions.setMultiMenuPlatform({ platforms }));
        if (ruleAlertType.noPlatform && platforms.length > 0)
            setRuleAlertType({ ...ruleAlertType, noPlatform: false });
    }, [platforms]);

    useEffect(() => {
        if (!activeStore) {
            logAndSendError("No active store");
            return;
        }

        // Will have to format localMenuHours to IHoursSchema
        const hours = {
            zone: activeStore.hours.zone,
            local: formatSpecialHours(localMenuHours),
        };

        const hasOverlappingHours = !checkOverlappingHours(hours.local);
        const hasHoursStartTimeBeforeEndTime =
            !validateHoursStartTimeBeforeEndTime(hours.local);

        setRuleAlertType((ruleAlertType) => ({
            ...ruleAlertType,
            hasOverlappingHours: hasOverlappingHours,
        }));
        setRuleAlertType((ruleAlertType) => ({
            ...ruleAlertType,
            hasHoursStartTimeBeforeEndTime: hasHoursStartTimeBeforeEndTime,
        }));

        dispatch(multiMenuActions.setMultiMenuTime({ hours }));
    }, [localMenuHours, platforms]);

    // On mount, set menu hours and necessary validations.
    useEffect(() => {
        // Will pick menu hours if they exist, otherwise picks the active store hours.
        handleSetMenuHours();
        setRuleAlertType(
            checkValidationErrors([], { platforms, enabled, name }),
        );
    }, []);

    useEffect(() => {
        if (!includes || !adjustment || amount === null || isNaN(amount)) {
            setRuleAlertType({ ...ruleAlertType, badAdjustment: true });
            return;
        }
        const value = Math.abs(adjustment + amount / 100);
        dispatch(
            multiMenuActions.setMultiMenuPriceAdjustment({
                priceAdjustment: {
                    scope: includes,
                    value,
                    applyToOverrides,
                },
            }),
        );
        ruleAlertType.badAdjustment &&
            setRuleAlertType({ ...ruleAlertType, badAdjustment: false });
    }, [amount, includes, adjustment, applyToOverrides]);

    return (
        <FormWrapper>
            {ALERTS.map((ruleKey) => (
                <HidableRuleBanner
                    key={ruleKey}
                    ruleKey={ruleKey}
                    isMounted={ruleAlertType[menuAlertPropertyMap(ruleKey)]}
                    isMobile={isMobile}
                />
            ))}
            <Text type="header" id="create-menu-details">
                Menu Details
            </Text>
            <Label className="mt24">Name</Label>
            <Input
                css={{
                    ...(ruleAlertType.noName && {
                        borderColor: "#FF3929",
                    }),
                    ...(ruleAlertType.titleCaseName && {
                        borderColor: "#ffbb00",
                    }),
                }}
                size="large"
                placeholder="Menu Name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                }
                className="mt16 name-input"
            />
            {ruleAlertType.noName && (
                <RuleWarning type="no-name" isMobile={isMobile} />
            )}
            {ruleAlertType.titleCaseName && (
                <RuleWarning type="title-case-name" isMobile={isMobile} />
            )}
            <RowForSwitch className="mt24">
                <Label>Menu Active</Label>
                <Switch
                    id="enabled"
                    onChange={handleActiveChange}
                    isOn={enabled}
                ></Switch>
            </RowForSwitch>
            {!isThirdPartyMenu(platforms) ? (
                <Extra>
                    Costumers can order from this menu during available times.
                </Extra>
            ) : null}
            <Divider />
            <Text type="header" id="create-menu-visibility">
                Visibility
            </Text>
            <Extra>
                Greyed out options are coming soon. They are not supported at
                the moment.
            </Extra>
            <Label className="mt24 mb16">Type</Label>
            <Space direction="vertical">
                {typesOptions.map((item) => {
                    if (item.value === MenuTypeEnum.Catering) {
                        return (
                            <Tooltip
                                key={item.label}
                                title="3P delivery platforms are not yet supported for catering."
                            >
                                <Checkbox
                                    key={item.label}
                                    onChange={handleTypeChange}
                                    checked={item.value == type}
                                    value={item.value}
                                    disabled={item.disabled}
                                >
                                    {item.label}
                                </Checkbox>
                            </Tooltip>
                        );
                    }

                    return (
                        <Checkbox
                            key={item.label}
                            onChange={handleTypeChange}
                            checked={item.value == type}
                            value={item.value}
                            disabled={item.disabled}
                        >
                            {item.label}
                        </Checkbox>
                    );
                })}
            </Space>
            <Divider />
            <Label className="mb16">Platforms</Label>
            <Space direction="vertical">
                <Checkbox.Group
                    onChange={handlePlatformsChange}
                    options={platformOptions}
                    value={platforms as string[]}
                ></Checkbox.Group>
            </Space>
            {ruleAlertType.noPlatform && (
                <RuleWarning type="no-platform" isMobile={isMobile} />
            )}
            <Divider />
            <div style={fadeInTime}>
                <Text type="header" id="create-menu-time">
                    Time
                </Text>
                <Label className="mt24">Available Days</Label>
                <Extra>
                    Choose which days of the week customers can order from this
                    menu.
                </Extra>
                <div className="flex-1 pt-3">
                    <MenuHourRows
                        menuHours={localMenuHours}
                        setMenuHours={setLocalMenuHours}
                    />
                    {ruleAlertType.hasOverlappingHours && (
                        <RuleWarning
                            type="has-overlapping-hours"
                            isMobile={isMobile}
                        />
                    )}
                    {ruleAlertType.hasHoursStartTimeBeforeEndTime && (
                        <RuleWarning
                            type="has-hours-start-time-before-end-time"
                            isMobile={isMobile}
                        />
                    )}
                </div>
                <Divider />
            </div>
            <Text type="header" id="create-menu-additional-options">
                Additional Options
            </Text>
            <Label className="mt24">Default Price Adjustment</Label>
            <Extra>
                Increase or decrease menu base prices by a percentage.
            </Extra>
            <DropDownSelectAdjustment
                adjustment={adjustment}
                setAdjustment={setAdjustment}
                includes={includes}
                setIncludes={setIncludes}
                amount={amount}
                setAmount={setAmount}
                isMobile={isMobile}
            />
            {ruleAlertType.badAdjustment && (
                <RuleWarning type="bad-price-adjustment" isMobile={isMobile} />
            )}
            {inEdit && (
                <>
                    <Divider />
                    <Text type="header">Danger Zone</Text>
                    <Label className="mt24">Reset Prices to Default</Label>
                    <Extra>
                        This will reset any individual price overrides. The
                        Default Price Adjustment will not be reset.
                    </Extra>
                    <Button
                        className="danger-btn"
                        onClick={() =>
                            setIsResetModalOpen && setIsResetModalOpen(true)
                        }
                        disabled={!activeStore && !inEdit}
                    >
                        Reset Prices
                    </Button>
                    <Label className="mt24">Delete Menu</Label>
                    <Extra>
                        This will remove all overrides but will not any delete
                        items.
                    </Extra>
                    <Button
                        className="danger-btn"
                        onClick={() =>
                            setIsDeleteModalOpen && setIsDeleteModalOpen(true)
                        }
                        disabled={!activeStore && !inEdit}
                    >
                        Delete Menu
                    </Button>
                </>
            )}
            <Enable3PMenuModal
                isModalOpen={is3PModalOpen}
                setIsModalOpen={setIs3PModalOpen}
                setEnabled={setEnabled}
                menu={active3PMenu}
            />
            <PriceAdjustmentModal
                setIsModalOpen={setIsPriceModalOpen}
                isModalOpen={isPriceModalOpen}
                setApplyToOverrides={setApplyToOverrides}
                applyToOverrides={applyToOverrides}
                adjustment={
                    adjustment === PriceAdjustmentType.Markup
                        ? "markup"
                        : "markdown"
                }
                amount={amount ?? 0}
                isLoading={isLoading}
                onClick={onUpdatePrice}
            />
        </FormWrapper>
    );
};

type HidableRuleBannerProps = {
    isMounted: boolean;
    isMobile: boolean;
    ruleKey: MenuAlertTypes;
};

function HidableRuleBanner({
    isMounted,
    ruleKey,
    isMobile,
}: HidableRuleBannerProps) {
    const shouldRenderBanner = useDelayUnmount(isMounted, 490);

    return shouldRenderBanner ? (
        <RuleBanner type={ruleKey} isMobile={isMobile} hide={!isMounted} />
    ) : null;
}

const FormWrapper = styled.div`
    font-family: Inter;
    padding: 24px 0px;
    .mt24 {
        margin-top: 24px;
    }

    .mb24 {
        margin-bottom: 24px;
    }

    .mt16 {
        margin-top: 16px;
    }

    .mb16 {
        margin-bottom: 16px;
    }

    .mr16 {
        margin-right: 16px;
    }

    input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .name-input {
        width: 50%;
        @media ${ScreenState.MOBILE} {
            width: 100%;
        }
    }

    .ant-input {
        border-radius: 8px;
    }

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .danger-btn {
        display: block;
        color: #ff3929;
        padding: 2px 14px;
        cursor: pointer;
        height: 39px;
        border-radius: 8px;
        margin-top: 16px;
    }

    .delete-btn:hover {
        border-color: #ff3929;
    }

    @keyframes fade-out-time {
        0% {
            opacity: 1;
            height: 430px;
        }
        100% {
            opacity: 0;
            height: 0;
        }
    }
    @keyframes fade-in-time {
        0% {
            opacity: 0;
            height: 0;
        }
        100% {
            opacity: 1;
            height: 430px;
        }
    }
`;

const Label = styled.div`
    display: flex;
    align-self: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #282d32;
`;

const RowForSwitch = styled(Row)`
    justify-content: space-between;
`;

const Extra = styled.span`
    color: #606c76;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
`;

const fadeInTime = {
    animation: "fade-in-time 0.4s ease-in",
};
