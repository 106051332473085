export enum OrdersFormFieldEnum {
    allowCustomNotes = "allowCustomNotes",
    autoAccept = "autoAccept",
    autoComplete = "autoComplete",
    defaultBagInCart = "defaultBagInCart",
    defaultPickupMaxTime = "defaultPickupMaxTime",
    defaultPickupMinTime = "defaultPickupMinTime",
    defaultPickupTime = "defaultPickupTime",
    hasEmployeeAuditTracking = "hasEmployeeAuditTracking",
    initialReceiptNumber = "initialReceiptNumber",
    pickupTimeType = "pickupTimeType",
}

export type OrdersType = {
    allowCustomNotes: boolean;
    autoAccept: boolean;
    autoComplete: boolean;
    defaultBagInCart?: number;
    defaultPickupMaxTime?: number;
    defaultPickupMinTime?: number;
    defaultPickupTime?: number;
    hasEmployeeAuditTracking: boolean;
    initialReceiptNumber: number;
    pickupTimeType: "specific" | "range";
};

type OrdersTypeKeys = keyof OrdersType;

export interface RowProps<
    T extends keyof { [K in OrdersTypeKeys]?: OrdersType[K] } = OrdersTypeKeys,
> {
    setFieldValue: (field: T, value: OrdersType[T]) => void;
    setFieldTouched: (field: OrdersTypeKeys) => void;
    value: OrdersType[T];
    disabled?: boolean;
}

/** Produces union type of keys with boolean values. */
export type TogglableKeys = {
    [K in keyof OrdersType]-?: OrdersType[K] extends boolean ? K : never;
}[keyof OrdersType];

export interface ToggleRowProps<T extends TogglableKeys = TogglableKeys>
    extends RowProps<T> {
    field: T;
}
