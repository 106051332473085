import React, { useContext } from "react";
import styled from "styled-components";

import { Button } from "src/@/components/ui/button";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { CenteredSpin } from "#devices/components/Loading";

const DrawerFooter = styled.div<{ width: number }>`
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: right;
    padding: ${({ theme }) =>
        `${theme.spacing.base} ${theme.spacing.baseAndAHalf}`};
`;

type EditDrawerFooterProps = {
    onCancel: () => void;
    onSave: () => void;
};

const EditDrawerFooter = ({
    onCancel,
    onSave,
}: EditDrawerFooterProps): JSX.Element => {
    const { deviceHasChanges, savingDevice, validName, validPinned } =
        useContext(DevicesPageContext);
    const handleCancelClick = () => onCancel();
    const handleSaveClick = () => onSave();

    const { width } = useWindowDimensions();

    const disabled =
        !deviceHasChanges || savingDevice || !validName || !validPinned;
    const spinning = savingDevice;

    const CancelButton = () => (
        <Button
            variant="outline"
            className="mr-4 w-full"
            onClick={handleCancelClick}
        >
            Cancel
        </Button>
    );

    const SubmitButton = () => (
        <Button
            className="w-full"
            onClick={handleSaveClick}
            disabled={disabled}
        >
            {spinning ? <CenteredSpin small /> : "Save"}
        </Button>
    );

    return (
        <DrawerFooter width={width}>
            <CancelButton />
            <SubmitButton />
        </DrawerFooter>
    );
};

export default EditDrawerFooter;
