import { ColumnDef } from "@tanstack/react-table";

import { filterNulls } from "src/utils/filterNulls";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { copyAndNotify } from "#utils/helpers";
import { SummarizedDevice } from "src/api/rest";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";

const TableItem = ({ field, value }: { field: string; value: string }) => (
    <div>
        <span
            onClick={() => {
                copyAndNotify({
                    value,
                    field,
                });
            }}
            className="cursor-pointer rounded-md p-1 px-2 transition duration-500 ease-in-out hover:bg-black/10"
        >
            {value}
        </span>
    </div>
);

export const columnDefinitions = (): ColumnDef<SummarizedDevice>[] =>
    filterNulls([
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Device Name" />
            ),
            id: "name",
            cell: (props) => props.row.original.name,
            // filtering by the store id, device serial, and snack id so the search can look at all 3
            filterFn: (row, _, value) =>
                !!row.original.snackId?.includes(value?.toUpperCase()) ||
                !!row.original.serial?.includes(value?.toUpperCase()) ||
                !!row.original.storeId?.includes(value?.toLowerCase()),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Store ID" />
            ),
            id: "storeId",
            cell: (props) => (
                <TableItem
                    field={"Store ID"}
                    value={props.row.original.storeId}
                />
            ),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Serial" />
            ),
            id: "serial",
            cell: (props) => (
                <TableItem field={"Serial"} value={props.row.original.serial} />
            ),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Snack ID" />
            ),
            id: "snackId",
            cell: (props) => (
                <TableItem
                    field={"Snack ID"}
                    value={props.row.original.snackId}
                />
            ),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Device Type" />
            ),
            id: "deviceType",
            cell: (props) => (
                <TableItem
                    field={"Device Type"}
                    value={
                        getDeviceTypeName(props.row.original.deviceType) || ""
                    }
                />
            ),
        },
    ]);
