import { Col } from "antd";
import React, { useCallback } from "react";

import { Divider } from "#reusable/divider";
import DropDownSelect from "#reusable/select/dropdown";
import { Text } from "#reusable/text/index";
import { StyledField, StyledRow } from "#settings/components/shared-component";

import { OrdersFormFieldEnum, RowProps } from "../types";

const PICKUP_TIME_TYPES = [
    {
        label: "Specific time",
        value: "specific",
    },
    {
        label: "Time range",
        value: "range",
    },
] as const;

export const PickupTimeTypeRow = ({
    setFieldTouched,
    setFieldValue,
    value,
    disabled,
}: RowProps<"pickupTimeType">) => {
    const onPickUpTimeTypeChange = useCallback(
        (option: { value: "specific" | "range" }) => {
            setFieldValue(OrdersFormFieldEnum.pickupTimeType, option.value);
            setFieldTouched(OrdersFormFieldEnum.pickupTimeType);
        },
        [setFieldValue, setFieldTouched],
    );

    return (
        <>
            <StyledRow>
                <Col>
                    <Text size="m">Estimated pickup time</Text>
                    <Text size="s" color="light-grey" weight="thin">
                        Set the estimated pickup time for customers.
                    </Text>
                </Col>
                <Col span={1} />
                <Col xs={10} sm={8} xl={6} xxl={4}>
                    <StyledField
                        name="pickUpTimeType"
                        component={DropDownSelect}
                        height="40px"
                        placeholder={value}
                        square
                        options={PICKUP_TIME_TYPES}
                        value={PICKUP_TIME_TYPES.find(
                            (option) => option.value === value,
                        )}
                        onChange={onPickUpTimeTypeChange}
                        disabled={disabled}
                    />
                </Col>
            </StyledRow>
            <Divider />
        </>
    );
};
