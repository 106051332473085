import { useIsMutating } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

import {
    FeesSettingsMutation,
    OperatingFormValues,
} from "#settings/settings-fees/utils/types";
import {
    getActiveStore,
    getUserFeeSettingsPermission,
} from "src/redux/selectors";
import { Button } from "src/@/components/ui/button";
import { useAppSelector } from "src/redux/hooks";
import { operatingFormDefaults } from "#settings/settings-fees/forms/operating/operating-defaults";

export const OperatingFooter = () => {
    const activeStore = useAppSelector(getActiveStore);
    const writeAccess = useAppSelector(getUserFeeSettingsPermission);
    const {
        reset,
        formState: { isDirty },
    } = useFormContext<OperatingFormValues>();
    const isMutating = !!useIsMutating({
        mutationKey: [FeesSettingsMutation.Operating, activeStore?._id],
    });
    const disabled = !writeAccess || !isDirty || isMutating;
    return (
        <div className="mt-4 flex flex-row justify-end gap-2">
            <Button
                className="mt-2"
                variant="outline"
                type="reset"
                disabled={disabled}
                onClick={() =>
                    activeStore
                        ? reset(operatingFormDefaults(activeStore))
                        : null
                }
            >
                Reset
            </Button>
            <Button className="mt-2" type="submit" disabled={disabled}>
                {isMutating ? "Saving" : isDirty ? "Save" : "No changes"}
            </Button>
        </div>
    );
};
