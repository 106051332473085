"use client";

import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";

export type TipReportRow = {
    hour: string;
    netSales: number;
    tips: number;
    tipPercentage: string;
};

export const columns: ColumnDef<TipReportRow>[] = [
    {
        accessorKey: "hour",
        header: ({ column }) => {
            return (
                <div
                    className="flex items-center cursor-pointer"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    Date/Time
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </div>
            );
        },
    },
    {
        accessorKey: "netSales",
        header: ({ column }) => {
            return (
                <div
                    className="flex items-center justify-end cursor-pointer"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    Net Sales
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </div>
            );
        },
        cell: ({ row }) => {
            const amount = parseFloat(row.getValue("netSales"));
            const formatted = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(amount);

            return <div className="text-right font-medium">{formatted}</div>;
        },
    },
    {
        accessorKey: "tips",
        header: ({ column }) => {
            return (
                <div
                    className="flex items-center justify-end cursor-pointer"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    Tips
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </div>
            );
        },
        cell: ({ row }) => {
            const amount = parseFloat(row.getValue("tips"));
            const formatted = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(amount);

            return <div className="text-right font-medium">{formatted}</div>;
        },
    },
    {
        accessorKey: "tipPercentage",
        header: ({ column }) => {
            return (
                <div
                    className="flex items-center justify-end cursor-pointer"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    Tip %
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </div>
            );
        },
        cell: ({ row }) => {
            return (
                <div className="text-right font-medium">
                    {row.getValue("tipPercentage")}%
                </div>
            );
        },
    },
];
