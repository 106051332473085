import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toast } from "sonner";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { z } from "zod";
import { Spinner } from "react-activity";

import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { Form, FormLabel } from "src/@/components/ui/form";
import api from "src/api/rest";
import {
    BrandRegistrationStep,
    brandRegistrationStatusAtom,
    brandRegistrationStepAtom,
    isUsingChainsAtom,
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import CampaignBrandRegistrationHeader from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationHeader";
import BusinessInfoCardInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/BusinessInfoCardInput";
import BusinessNameCardInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/BusinessNameCardInput";
import ContactInfoCardInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/ContactInfoCardInput";
import DisplayNameCardInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/DisplayNameCardInput";
import PhysicalAddressInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/PhysicalAddressInput";
import { campaignBrandRegistrationSMSFormSchema } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";
import { getActiveStore } from "src/redux/selectors";
import CampaignBrandRegistrationReviewAndConfirmSMS from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/CampaignBrandRegistrationReviewAndConfirmSMS";
import { Button } from "src/@/components/ui/button";
import AreaCodeCardInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/AreaCodeCardInput";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import { SegmentEvents } from "#utils/segment";
import CampaignCardContent from "#guestbook/shared-components/CampaignCardContent";

function SMSReviewHeaderButtons({ isLoading }: { isLoading: boolean }) {
    const setStep = useSetRecoilState(brandRegistrationStepAtom);
    return (
        <>
            <Button
                type="button"
                variant={"outline"}
                onClick={() => setStep(BrandRegistrationStep.SMSForm)}
            >
                Back
            </Button>
            <Button type="submit" disabled={isLoading}>
                {isLoading ? <Spinner size={14} /> : "Submit"}
            </Button>
        </>
    );
}

function SMSFormHeaderButtons() {
    const setStep = useSetRecoilState(brandRegistrationStepAtom);
    const [isUsingChains, setIsUsingChains] = useRecoilState(isUsingChainsAtom);
    const trackCampaignEvent = useTrackCampaignSegmentEvent();
    return (
        <>
            <Button
                type="button"
                variant={"outline"}
                onClick={
                    isUsingChains === false // Indicates user has explicitly chosen to not use chain reg, hence going back here should take them to the chain selector
                        ? () => setIsUsingChains(null)
                        : () => {
                              trackCampaignEvent(
                                  SegmentEvents.Guestbook.Campaigns.SETUP_SMS
                                      .CANCEL,
                              );
                              setStep(BrandRegistrationStep.GetStarted);
                          }
                }
            >
                {isUsingChains === false ? "Back" : "Cancel"}
            </Button>
            <Button type="submit">Continue</Button>
        </>
    );
}

function CampaignBrandRegistrationTextMessageForm() {
    const [step, setStep] = useRecoilState(brandRegistrationStepAtom);
    useEffect(() => {
        // Reset scroll position when step changes
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, [step]);
    const setBrandRegistrationStatus = useSetRecoilState(
        brandRegistrationStatusAtom,
    );
    const [isLoading, setIsLoading] = useState(false);
    const store = useSelector(getActiveStore);
    const trackCampaignEvent = useTrackCampaignSegmentEvent();

    // Conditionally applies refinements based on whether we are the review step or not to check the booleans
    // Idea from https://stackoverflow.com/questions/77121215/conditional-validation-in-zod
    const reviewStepSchema = campaignBrandRegistrationSMSFormSchema.superRefine(
        (data, refinementContext) => {
            if (step === BrandRegistrationStep.SMSForm) {
                return;
            }
            const requiredReviewBooleans = [
                "reviewedInfo",
                "agreedToPricing",
            ] as const;
            for (const field of requiredReviewBooleans) {
                if (data[field] !== true) {
                    refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: `Must agree to the terms`,
                        path: [field],
                    });
                }
            }
            if (data.hasPaymentMethod !== true) {
                refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Must have billing method`,
                    path: ["hasPaymentMethod"],
                });
            }
        },
    );
    const form = useForm<z.infer<typeof reviewStepSchema>>({
        resolver: zodResolver(reviewStepSchema),
        mode: "onTouched",
        defaultValues: {
            businessName: "",
            displayName: "",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "",
                zip: "",
            },
            ein: "",
            contactInfo: {
                businessTitle: "Store Operator",
                jobPosition: "Other",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
            },
            desiredAreaCode: "",
            reviewedInfo: false,
            agreedToPricing: false,
            hasPaymentMethod: false,
        },
    });
    const onSubmit = async (
        formValues: z.infer<typeof campaignBrandRegistrationSMSFormSchema>,
    ) => {
        if (!store) return;
        if (step === BrandRegistrationStep.SMSForm) {
            trackCampaignEvent(
                SegmentEvents.Guestbook.Campaigns.SETUP_SMS.CONTINUE,
            );
            setStep(BrandRegistrationStep.SMSReview);
            return;
        }
        trackCampaignEvent(SegmentEvents.Guestbook.Campaigns.SETUP_SMS.SUBMIT);

        setIsLoading(true);
        api.twilio
            .createTwilioBrandRegistration(store._id, formValues)
            .then((response) => {
                trackCampaignEvent(
                    SegmentEvents.Guestbook.Campaigns.SETUP_SMS.SUCCESS,
                );
                setBrandRegistrationStatus(response.data.status);
                toast.success("Brand submitted");
            })
            .catch((e) => {
                toast.error("Failed to save changes", {
                    description: e.response?.data?.message ?? e.message,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <Form {...form}>
            <form
                className="flex flex-col"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <CampaignBrandRegistrationHeader>
                    <>
                        {step === BrandRegistrationStep.SMSReview ? (
                            <SMSReviewHeaderButtons isLoading={isLoading} />
                        ) : (
                            <SMSFormHeaderButtons />
                        )}
                    </>
                </CampaignBrandRegistrationHeader>
                <div className="flex w-full flex-col items-center overflow-auto">
                    <div className="flex w-full max-w-4xl flex-col space-y-6 p-4">
                        {step === BrandRegistrationStep.SMSForm && (
                            <>
                                <Alert variant="warning">
                                    <ExclamationTriangleIcon className="h-5 w-5 stroke-yellow-800" />
                                    <AlertDescription className="text-xs text-yellow-800">
                                        <p>
                                            The US wireless carriers require all
                                            organizations to provide basic
                                            information about who they are
                                            before they start sending text
                                            messages. The purpose for this
                                            requirement is to help prevent bad
                                            actors from sending spam.
                                        </p>
                                        <br />
                                        <p>
                                            If your store is in a chain, the
                                            business information you provide can
                                            be for one of your locations or the
                                            brand as a whole, however ensure
                                            that the business name, business
                                            address, and business EIN are all
                                            consistent.
                                        </p>
                                    </AlertDescription>
                                </Alert>
                                <BusinessNameCardInput />
                                <CampaignCardContent>
                                    <FormLabel>
                                        <p className="text-xl font-semibold">
                                            Registered Business Address
                                        </p>
                                    </FormLabel>
                                    <div className="pt-4">
                                        <PhysicalAddressInput />
                                    </div>
                                </CampaignCardContent>
                                <BusinessInfoCardInput />
                                <ContactInfoCardInput />
                                <AreaCodeCardInput />
                                <DisplayNameCardInput />
                            </>
                        )}
                        {step === BrandRegistrationStep.SMSReview && (
                            <CampaignBrandRegistrationReviewAndConfirmSMS />
                        )}
                    </div>
                </div>
            </form>
        </Form>
    );
}

export default CampaignBrandRegistrationTextMessageForm;
