import React, { useContext } from "react";
import { Drawer } from "antd";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { determineDrawerWidth } from "#devices/utils/drawerWidth";
import useWindowDimensions from "#hooks/use-window-dimensions";
import AddonDrawerHeader from "#devices/components/AddonDrawer/AddonDrawerHeader";
import DeviceAddonView from "#devices/components/AddonDrawer/DeviceAddon";

const AddonDrawer = (): JSX.Element | null => {
    const {
        isAddonDrawerVisible,
        setIsEditDrawerVisible,
        setIsAddonDrawerVisible,
    } = useContext(DevicesPageContext);

    const resetToEdit = () => {
        setIsEditDrawerVisible(true);
        setIsAddonDrawerVisible(false);
    };

    const { width } = useWindowDimensions();

    // const handleSaveClickFromEdit = () => {
    //     console.log("Click");
    // };

    const handleDrawerClose = () => {
        resetToEdit();
    };

    return (
        <Drawer
            width={determineDrawerWidth(width)}
            placement="right"
            onClose={handleDrawerClose}
            open={isAddonDrawerVisible}
            closable={false} // hides the default close button
            bodyStyle={{
                fontFamily: "Inter", // overwrite font family for everything in drawer
            }}
            // footer={
            //     <AddonDrawerFooter
            //         onCancel={handleDrawerClose}
            //         onSave={handleSaveClickFromEdit}
            //     />
            // }
        >
            <AddonDrawerHeader onClose={handleDrawerClose} />
            {isAddonDrawerVisible && <DeviceAddonView />}
        </Drawer>
    );
};

export default AddonDrawer;
