import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Legend } from "@tremor/react";
import clsx from "clsx";
import { Cell, Label, Pie, PieChart, TooltipProps } from "recharts";
import {
    NameType,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { ReportsContext } from "#app/reports-context-provider";
import {
    addTableChannelDataRows,
    channelLabels,
    emptyTableChannelDataRow,
} from "#reports/sales-channels/lib";
import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";
import { TableChannelDataRow } from "#reports/sales-channels/types";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartType,
    ChartTypeSelect,
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
} from "src/@/components/ui/chart";

const chartConfig = {} satisfies ChartConfig;

const ChannelDonut = () => {
    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    const { reportsState } = useContext(ReportsContext);

    const aggregateDataRow = useMemo(() => {
        if (
            !reportsState.channelsData?.salesChannelReportData ||
            reportsState.channelsData?.salesChannelReportData.length == 0
        )
            return emptyTableChannelDataRow;

        return reportsState.channelsData?.salesChannelReportData.reduce(
            (acc: TableChannelDataRow, row: TableChannelDataRow) =>
                addTableChannelDataRows(acc, row),
            emptyTableChannelDataRow,
        );
    }, [reportsState.channelsData?.salesChannelReportData]);

    const channelLabeledData = useMemo(
        () =>
            channelLabels
                .map((e) => ({
                    ...e,
                    sales: aggregateDataRow.netSales[e.key],
                    totalSales: aggregateDataRow.netSales.total,
                    orders: aggregateDataRow.orders[e.key],
                    totalOrders: aggregateDataRow.orders.total,
                }))
                .filter((e) => e.sales),
        [aggregateDataRow, graphType],
    );

    const totals = useMemo(
        () => ({
            sales: aggregateDataRow?.netSales?.total ?? 0,
            orders: aggregateDataRow?.orders?.total ?? 0,
        }),
        [aggregateDataRow],
    );

    const valueFormatter =
        graphType === ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    return (
        <div className="flex flex-col items-center pb-12 md:items-start">
            <div className="flex w-full justify-between">
                <h4 className="mb-2 text-large">Sales By Provider</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <div className="my-8 h-80 w-80">
                {!reportsState.channelsData?.salesChannelDataLoading ? (
                    reportsState.channelsData?.salesChannelReportData ? (
                        <ChartContainer
                            config={chartConfig}
                            className="aspect-square"
                        >
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={channelLabeledData}
                                    dataKey={
                                        graphType === ChartType.NET_SALES
                                            ? "sales"
                                            : "orders"
                                    }
                                    nameKey="label"
                                    innerRadius={120}
                                    outerRadius={150}
                                    strokeWidth={5}
                                >
                                    <Label
                                        content={({ viewBox }) => {
                                            if (
                                                viewBox &&
                                                "cx" in viewBox &&
                                                "cy" in viewBox
                                            ) {
                                                return (
                                                    <text
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        textAnchor="middle"
                                                        dominantBaseline="middle"
                                                    >
                                                        <tspan
                                                            x={viewBox.cx}
                                                            y={viewBox.cy}
                                                            className="fill-foreground text-2xl font-bold"
                                                        >
                                                            {valueFormatter(
                                                                graphType ===
                                                                    ChartType.NET_SALES
                                                                    ? totals.sales
                                                                    : totals.orders,
                                                            )}
                                                        </tspan>
                                                        <tspan
                                                            x={viewBox.cx}
                                                            y={
                                                                (viewBox.cy ||
                                                                    0) + 24
                                                            }
                                                            className="fill-muted-foreground"
                                                        >
                                                            {graphType ===
                                                            ChartType.NET_SALES
                                                                ? "in sales"
                                                                : "orders"}
                                                        </tspan>
                                                    </text>
                                                );
                                            }
                                        }}
                                    />
                                    {channelLabeledData.map((e, index) => {
                                        const colorName = `${e.color}-500`;
                                        return (
                                            <Cell
                                                key={`cell-${index}`}
                                                // These colors are in our safelist!
                                                className={`fill-${e.color}-500`}
                                                /* Fill is necessary here because the tooltip
                                                needs it to be set in order to access the `color` attribute */
                                                fill={e.color}
                                            />
                                        );
                                    })}
                                </Pie>
                                <ChartTooltip
                                    cursor={false}
                                    content={<CustomTooltip />}
                                />
                            </PieChart>
                        </ChartContainer>
                    ) : (
                        <ErrorChart className="h-80 w-80 rounded-full" />
                    )
                ) : (
                    <Skeleton className="h-80 w-80 rounded-full" />
                )}
            </div>
            <div>
                {!reportsState.channelsData?.salesChannelDataLoading &&
                (reportsState.channelsData?.salesChannelReportData ||
                    reportsState.channelsData?.salesChannelDataFailed) ? (
                    reportsState.channelsData?.salesChannelReportData && (
                        <Legend
                            className="p-0"
                            categories={channelLabeledData.map((e) => e.label)}
                            colors={channelLabeledData.map((e) => e.color)}
                        />
                    )
                ) : (
                    <Skeleton className="w-96 rounded-md" />
                )}
            </div>
        </div>
    );
};

const CustomTooltip = ({
    payload,
    active,
}: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload) return null;
    return (
        <div className="flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div
                        className={clsx(
                            `flex w-1 flex-col rounded`,
                            channelLabels.find(
                                (e) => e.color === category.payload.color,
                            )?.className,
                        )}
                    />
                    <div className="w-full space-y-1">
                        <div className="font-semibold">
                            {category.payload.label}
                        </div>
                        <div className="flex w-full justify-between">
                            <div className="text-neutral-800">Net Sales</div>
                            <div className="font-medium text-neutral-800">
                                {toDollarFormatted(category.payload.sales)}
                            </div>
                        </div>
                        <div className="text-micro text-neutral-600">
                            {calculatePercentage(
                                category.payload.sales,
                                category.payload.totalSales,
                            )}
                        </div>

                        <div className="flex w-full justify-between">
                            <div className="text-neutral-800">Orders</div>
                            <div className="font-medium text-neutral-800">
                                {formatNumber(category.payload.sales)}
                            </div>
                        </div>
                        <div className="text-micro text-neutral-600">
                            {calculatePercentage(
                                category.payload.orders,
                                category.payload.totalOrders,
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const calculatePercentage = (
    numerator: number,
    denominator: number,
): string => {
    if (denominator === 0) {
        return "0%";
    }
    const percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2).replace(/\.?0+$/, "") + "%";
};

export default ChannelDonut;
