/** @jsxImportSource @emotion/react */
import React from "react";
import { isTablet } from "react-device-detect";

import DownloadPurchasesCSVButton from "#download-purchase-button";
import { FeatureAlert } from "#orders/components/alert";
import PaymentTypePicker from "#payment-type-picker";
import TransactionSourcePicker from "#pickers/transaction-source-picker";
import { useGlobalDate } from "#hooks/use-global-date";
import TransactionChannelPicker from "#pickers/transaction-channel-picker";
import { PauseOrders } from "#dashboard/components/pause-orders/pause-orders";
import { ReloadButton } from "#dashboard/components/reload";

import { OrdersDateSelector } from "./components/date-selector";
import { OrderSalesSummary } from "./components/summary";
import { OrderHistory } from "./components/order-history";

const OrdersLayout = () => {
    const { startDate, endDate } = useGlobalDate();
    return (
        <div className="p-6 sm:p-12">
            <div className="mb-4 flex items-center space-x-2 overflow-auto whitespace-nowrap">
                <OrdersDateSelector />
                <TransactionSourcePicker />
                <TransactionChannelPicker />
                <PaymentTypePicker />
                <PauseOrders />
                <ReloadButton />
                {!isTablet && (
                    <div className="flex flex-1 justify-end">
                        <DownloadPurchasesCSVButton
                            startDate={startDate}
                            endDate={endDate}
                            showTotals={true}
                        />
                    </div>
                )}
            </div>
            <FeatureAlert />
            <OrderSalesSummary />
            <OrderHistory />
        </div>
    );
};

export default OrdersLayout;
