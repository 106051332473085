import { CardDescription } from "src/@/components/ui/card";
import {
    FormDescription,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";

type Props = {
    label: string;
    description: React.ReactNode;
    subDescription?: React.ReactNode;
    children: React.ReactNode;
};
function CampaignFormItemLayout({
    label,
    description,
    subDescription,
    children,
}: Props) {
    return (
        <FormItem>
            <div className="flex flex-col gap-y-2 p-4 lg:px-6">
                <FormLabel>
                    <p className="text-xl font-semibold">{label}</p>
                </FormLabel>
                <CardDescription>{description}</CardDescription>
                {children}
                <FormMessage />
            </div>
            {subDescription && (
                <FormDescription className="w-full bg-gray-50 p-4 text-gray-500 lg:px-6">
                    {subDescription}
                </FormDescription>
            )}
        </FormItem>
    );
}

export default CampaignFormItemLayout;
