import { SystemColors } from "@snackpass/design-system";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

import { Text } from "#devices/components";
import colors from "#reusable/colors/colors.json";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    id: string;
    type?: string;
    helpText?: string | JSX.Element;
    isInvalid?: boolean;
    isValid?: boolean;
    error?: string;
}

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.base};
`;

const StyledInput = styled.input<Omit<InputProps, "label">>`
    padding: ${({ theme }) => theme.spacing.base};
    margin-top: ${({ theme }) => theme.spacing.half};
    font-size: 1rem;
    color: ${colors["neutral-600"]};
    background-color: ${colors["neutral-50"]};
    border-radius: ${({ theme }) => theme.spacing.half};
    border: 1px solid ${colors["neutral-400"]};

    &:focus {
        outline: 0;
        border: 1px solid ${colors["gray-dark"]};
        box-shadow: none;
    }

    /* Valid state */
    ${({ isValid }) =>
        isValid &&
        `
        color: ${SystemColors.v2.parsley50.light};
        border: 1px solid ${SystemColors.v2.parsley50.light};
    `}

    /* Invalid state */
    ${({ isInvalid }) =>
        isInvalid &&
        `
        color: ${SystemColors.v2.melon50.light};
        border: 1px solid ${SystemColors.v2.melon50.light};
    `}

    /* Disabled state */
    ${({ disabled }) =>
        disabled &&
        `
          cursor: not-allowed;
          opacity: .65;
          pointer-events: none;
        `}
`;

const Input = ({
    label,
    helpText,
    type,
    isInvalid,
    isValid,
    id,
    error,
    ...rest
}: InputProps): JSX.Element => (
    <InputWrapper>
        <Text.Label htmlFor={id} as="label">
            {label}
        </Text.Label>
        {helpText ? <Text.Body as="span">{helpText}</Text.Body> : null}
        <StyledInput
            id={id}
            type={type ?? "text"}
            isValid={isValid}
            isInvalid={isInvalid}
            {...rest}
        />
        <InvalidFeedback error={error}>{error}</InvalidFeedback>
    </InputWrapper>
);

export default Input;

const InvalidFeedback = styled.div<{ error?: string }>`
    padding-top: 5px;
    padding-left: 5px;
    display: ${({ error }) => (error && error.length > 0 ? "block" : "none")};
    color: red;
    font-size: 16px;
`;
