import { PromoTarget } from "@snackpass/snackpass-types";
import * as yup from "yup";

import {
    defaultMenuItem,
    DISCOUNT_TYPES,
    DOLLAR_OPTIONS,
    PERCENT_OPTIONS,
    NEWPRICE_OPTIONS
} from "#promotion/utils/constants";
import {
    FIELD_NAMES,
    FulfillmentValues,
    PlatformValues,
    QualifierValues,
} from "#promotion/utils/types";
import {
    discountAmountDollarValidation,
    discountAmountPercentValidation,
    discountNewPriceDollarValidation,
    discountQualiferValidation,
    discountTypeValidation,
    nameValidation,
    scheduleDayEnabledValidation,
    scheduleDayValidation,
    scheduleEnabledValidation,
    toggleValidation,
    promoCodeValidation,
    audienceValidation,
    durationEnabledValidation,
    durationEndDateValidation,
    durationStartDateValidation,
    fulfillmentValidation,
    cartMinValidation,
    cartMaxValidation,
    platformsValidation,
    imageValidation,
    requirePurchaseValidation,
    limitUsesValidation,
    singleUseValidation,
    onePerCartValidation,
    requiredPurchaseItemsValidation,
    discountedItemsValidation,
    totalUsesValidation,
} from "#promotion/utils/validation/field-schemas";

export const GenericDefaultValues = {
    [FIELD_NAMES.NAME]: "",
    [FIELD_NAMES.DISCOUNT_TYPE]: DISCOUNT_TYPES.PERCENT_OFF,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: Number(PERCENT_OPTIONS[0].value), // 100%
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: Number(DOLLAR_OPTIONS[0].value), // $10
    [FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS]: Number(
        NEWPRICE_OPTIONS[0].value
    ), // $10

    // Discounted Items
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues.ANY_ITEM,
    [FIELD_NAMES.DISCOUNTED_ITEMS]: [defaultMenuItem],
    [FIELD_NAMES.DISCOUNT_ADDONS]: false,
    // [FIELD_NAMES.DISCOUNT_LEAST_EXPENSIVE]: false,

    // Cart
    [FIELD_NAMES.SINGLE_USE]: false,
    [FIELD_NAMES.ONE_PER_CART]: false,
    [FIELD_NAMES.CART_MINIMUM]: NaN,
    [FIELD_NAMES.CART_MAXIMUM]: NaN,

    // Fulfillment
    [FIELD_NAMES.FULFILLMENT_METHODS]: FulfillmentValues.All,

    // Platforms
    [FIELD_NAMES.PLATFORMS]: PlatformValues.AppAndKioskAndRegister,

    // Audience
    [FIELD_NAMES.AUDIENCE]: PromoTarget.All,

    // Promo code
    [FIELD_NAMES.PROMO_CODE_ENABLED]: false,
    [FIELD_NAMES.PROMO_CODE]: "",

    // Image
    [FIELD_NAMES.IMAGE]: "",

    // Required Purchase
    [FIELD_NAMES.REQUIRED_PURCHASE]: false,
    [FIELD_NAMES.REQUIRED_PURCHASE_ITEMS]: [defaultMenuItem],

    // Limit Customers
    [FIELD_NAMES.LIMIT_USES]: false,
    [FIELD_NAMES.TOTAL_USES]: 500,

    // Schedule
    [FIELD_NAMES.SCHEDULE_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_MONDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_MONDAY]: [],
    [FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_TUESDAY]: [],
    [FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_WEDNESDAY]: [],
    [FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_THURSDAY]: [],
    [FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_FRIDAY]: [],
    [FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_SATURDAY]: [],
    [FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED]: false,
    [FIELD_NAMES.SCHEDULE_SUNDAY]: [],

    // Duration
    [FIELD_NAMES.DURATION_ENABLED]: false,
    [FIELD_NAMES.DURATION_START_DATE]: "",
    [FIELD_NAMES.DURATION_END_DATE]: "",
};

export const GenericPromoValidations = {
    [FIELD_NAMES.NAME]: nameValidation,
    [FIELD_NAMES.DISCOUNT_TYPE]: discountTypeValidation,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: discountAmountPercentValidation,
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: discountAmountDollarValidation,
    [FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS]:
        discountNewPriceDollarValidation,
    [FIELD_NAMES.DISCOUNT_ADDONS]: toggleValidation,
    // [FIELD_NAMES.DISCOUNT_LEAST_EXPENSIVE]: toggleValidation,

    // Discounted Items
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: discountQualiferValidation,
    [FIELD_NAMES.DISCOUNTED_ITEMS]: discountedItemsValidation,

    // Cart size
    [FIELD_NAMES.SINGLE_USE]: singleUseValidation,
    [FIELD_NAMES.ONE_PER_CART]: onePerCartValidation,
    [FIELD_NAMES.CART_MINIMUM]: cartMinValidation,
    [FIELD_NAMES.CART_MAXIMUM]: cartMaxValidation,

    // Fulfillment
    [FIELD_NAMES.FULFILLMENT_METHODS]: fulfillmentValidation,

    // Platforms
    [FIELD_NAMES.PLATFORMS]: platformsValidation,

    // Audience
    [FIELD_NAMES.AUDIENCE]: audienceValidation,

    // Promo code
    [FIELD_NAMES.PROMO_CODE_ENABLED]: toggleValidation,
    [FIELD_NAMES.PROMO_CODE]: promoCodeValidation,

    // Image
    [FIELD_NAMES.IMAGE]: imageValidation,

    // Required Purchase
    [FIELD_NAMES.REQUIRED_PURCHASE]: requirePurchaseValidation,
    [FIELD_NAMES.REQUIRED_PURCHASE_ITEMS]: requiredPurchaseItemsValidation,

    // Limit Customers
    [FIELD_NAMES.LIMIT_USES]: limitUsesValidation,
    [FIELD_NAMES.TOTAL_USES]: totalUsesValidation,

    // Schedule
    [FIELD_NAMES.SCHEDULE_ENABLED]: scheduleEnabledValidation,
    [FIELD_NAMES.SCHEDULE_MONDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_MONDAY,
    ),
    [FIELD_NAMES.SCHEDULE_MONDAY]: scheduleDayValidation,
    [FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_TUESDAY,
    ),
    [FIELD_NAMES.SCHEDULE_TUESDAY]: scheduleDayValidation,
    [FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_WEDNESDAY,
    ),
    [FIELD_NAMES.SCHEDULE_WEDNESDAY]: scheduleDayValidation,
    [FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_THURSDAY,
    ),
    [FIELD_NAMES.SCHEDULE_THURSDAY]: scheduleDayValidation,
    [FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_FRIDAY,
    ),
    [FIELD_NAMES.SCHEDULE_FRIDAY]: scheduleDayValidation,
    [FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_SATURDAY,
    ),
    [FIELD_NAMES.SCHEDULE_SATURDAY]: scheduleDayValidation,
    [FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED]: scheduleDayEnabledValidation(
        FIELD_NAMES.SCHEDULE_SUNDAY,
    ),
    [FIELD_NAMES.SCHEDULE_SUNDAY]: scheduleDayValidation,

    // Duration
    [FIELD_NAMES.DURATION_ENABLED]: durationEnabledValidation,
    [FIELD_NAMES.DURATION_START_DATE]: durationStartDateValidation,
    [FIELD_NAMES.DURATION_END_DATE]: durationEndDateValidation,
};

export const GenericPromoValidationSchema = yup
    .object()
    .shape({ ...GenericPromoValidations });
