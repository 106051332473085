import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import { GiftCardBalanceReportRow } from "#reports/gift-card-balances/types";
import {
    columns,
    columnsForExport,
    formatRowsForExport,
} from "#reports/gift-card-balances/lib";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { useGiftCardReportQuery } from "src/api/rest/report-hooks";
import {
    GiftCardBalanceReport,
    GiftCardReportEndpoints,
} from "src/api/rest/report-hooks/types";

const GiftCardBalanceTable = () => {
    const activeStore = useSelector(getActiveStore);

    const { data, isLoading, isError } = useGiftCardReportQuery({
        endpoint: GiftCardReportEndpoints.Balances,
    });

    const balanceReport = (data as GiftCardBalanceReport)
        ?.giftCardBalanceReport;

    const rows: GiftCardBalanceReportRow[] = useMemo(() => {
        if (!balanceReport || balanceReport === null) return [];

        return balanceReport.map((row) => ({
            storeName: row.storeName,
            code: row.code,
            type: row.type,
            status: row.status,
            firstUseDate: row.firstUseDate,
            lastUseDate: row.lastUseDate,
            purchaseDate: row.purchaseDate,
            maxCreditCents: row.maxCreditCents,
            remainingCreditCents:
                // Refunded gift cards still show their remaining credit cents at the time of refund, but from a restaurant
                // POV, they have no remaining credit. Note that we can't use the enum, because the one in snackpass-types
                // is a graphql numeric enum, whereas we've saved it in the database via a string enum.
                row.status === "REFUNDED" ? 0 : row.remainingCreditCents,
            fromName: row.fromName,
            fromEmail: row.fromEmail,
            toName: row.toName ?? "-",
            toPhone: row.toPhone ?? "-",
            toEmail: row.toEmail ?? "-",
        }));
    }, [balanceReport]);

    const storeNamesSet = new Set<string>(rows.map((row) => row.storeName));
    const storeFilterOptions: { label: string; value: string }[] = [];
    storeNamesSet.forEach((storeName) => {
        storeFilterOptions.push({
            label: storeName,
            value: storeName,
        });
    });

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            placeholder: "Search by Code, Name, or Phone",
            global: true,
        },
    };

    return (
        <div className="mb-20">
            <div className="mb-4 flex items-center justify-between">
                <DownloadButton
                    rows={formatRowsForExport(rows)}
                    columns={columnsForExport}
                    filename={`${activeStore?.name}`}
                />
            </div>
            <div>
                {!isLoading && (isError || balanceReport) ? (
                    balanceReport ? (
                        <DataTable
                            toolbar={toolbarOptions}
                            showPagination
                            columns={columns}
                            data={rows}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default GiftCardBalanceTable;
