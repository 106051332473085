import styled from "@emotion/styled";
import { Radio, Space } from "antd";
import { Controller, useFormContext } from "react-hook-form";

import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FIELD_NAMES, FulfillmentValues } from "#promotion/utils/types";
import colors from "#reusable/colors/colors.json";

const options = [
    { label: "Pick up, dine-in, and delivery", value: FulfillmentValues.All },
    {
        label: "Only pick up and dine-in",
        value: FulfillmentValues.PickupAndDineIn,
    },
    { label: "Only delivery", value: FulfillmentValues.Delivery },
];

export const FulfillmentMethods = () => {
    const { control } = useFormContext();

    return (
        <Container>
            <FormFieldName name="Fulfillment Methods" />
            <Controller
                control={control}
                name={FIELD_NAMES.FULFILLMENT_METHODS}
                render={({ field: { onChange, value } }) => (
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            {options.map(({ value, label }, index) => (
                                <Radio value={value} key={`${value}-${index}`}>
                                    {label}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                )}
            />
        </Container>
    );
};

const Container = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin-bottom: 24px;
`;
