import { TextFieldProps } from "@material-ui/core";
import { isUndefined } from "lodash";
import { useState, useEffect, ComponentType } from "react";
import NumberFormat from "react-number-format";

import { isNotUndefined } from "#core";

export interface NumericOverrideInputProps {
    baseValue: number;
    overrideValue: number | undefined | null;
    setOverride: (overrideValue: number) => void;
    resetOverride: () => void;
    max?: number;
    width?: number;
    prefix?: string;
    suffix?: string;
    disabled?: boolean;
    inputComponent: ComponentType<TextFieldProps>;
    inputComponentProps: Pick<
        TextFieldProps,
        "InputLabelProps" | "variant" | "label" | "fullWidth"
    >;
}

export const NumericOverrideInput = ({
    baseValue,
    overrideValue,
    setOverride,
    resetOverride,
    max,
    prefix,
    suffix,
    width = 10,
    inputComponent,
    inputComponentProps,
}: NumericOverrideInputProps) => {
    const placeholder = `${prefix ?? ""}${baseValue}${suffix ?? ""}`;
    const [value, setValue] = useState<number | undefined>(undefined);
    const valueIsOverridden = isNotUndefined(overrideValue);

    useEffect(() => {
        if (isNotUndefined(overrideValue)) setValue(overrideValue);
        else {
            setValue(undefined);
        }
    }, [overrideValue]);

    const onValueChange = (newValue: number | undefined) => {
        setValue(newValue);
        if (isUndefined(newValue)) return valueIsOverridden && resetOverride();
        if (newValue === baseValue) return resetOverride();
        setOverride(newValue);
    };

    return (
        <NumberFormat
            {...inputComponentProps}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                    e.currentTarget.blur();
                }
            }}
            type="text"
            prefix={prefix}
            suffix={suffix}
            value={value ?? ""}
            allowNegative={false}
            customInput={inputComponent}
            isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (!max) return true;
                return (
                    formattedValue === "" ||
                    (isNotUndefined(floatValue) && floatValue <= max)
                );
            }}
            width={width}
            placeholder={placeholder}
            decimalScale={3}
            onValueChange={(values) => {
                onValueChange(values.floatValue);
            }}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
        />
    );
};
