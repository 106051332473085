// eslint-disable-next-line import/named
import { OptionsType, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import React, { useEffect } from "react";
import { SystemColors } from "@snackpass/design-system";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import colors from "#reusable/colors/colors.json";
import {
    AccountNumberIntegrations,
    INTEGRATION_FIELD_NAMES,
    IntegrationAppOptions,
    IntegrationApps,
    IntegrationLinkOrAccountNumber,
} from "#settings-integrations/utils/types";

interface Options {
    label: string;
    value: string;
}
interface Props {
    fieldName: string;
    placeholder?: string;
    width?: string;
    fontSize?: string;
    ml?: number;
    mr?: number;
    square?: boolean;
    fontWeight?: string;
    color?: string;
    placeHolderColor?: string;
    className?: string;
    disabled?: boolean;
}

const DropDownSelectIntegrations: React.FC<Props> = ({
    fieldName,
    placeholder,
    fontSize,
    width,
    ml,
    mr,
    square,
    fontWeight,
    color,
    placeHolderColor,
    className,
    disabled = false,
}) => {
    const customStyles: StylesConfig<Options, false> = {
        option: (provided, state) => ({
            ...provided,
            fontSize: fontSize ? fontSize : "16px",
            backgroundColor: state.data.color,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: fontWeight ? fontWeight : "400",
            color: SystemColors.v1.sesame,
            "&:hover": {
                background: "rgba(0, 0, 0, 0.035)",
            },
            cursor: "pointer",
        }),

        singleValue: (provided) => ({
            ...provided,
            fontSize: fontSize ? fontSize : "16px",
            fontWeight: fontWeight ? fontWeight : "400",
            borderRadius: "8px",
            fontFamily: "Inter",
            color: disabled ? SystemColors.v1.gray60 : SystemColors.v1.sesame,
            padding: "5px 0px",
            bottom: "20",
            left: "10",
        }),
        menu: (base) => ({
            ...base,
            width: "100%",
            zIndex: 9999,
            fontFamily: "Inter",
            border: `1px solid ${colors["gray-light"]}`,
            borderRadius: "8px",
            fontWeight: fontWeight ? fontWeight : "400",
            color: color ? color : SystemColors.v1.sesame,
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        control: (base: any, state) => ({
            ...base,
            backgroundColor: "white",
            borderRadius: square ? "8px" : "20px",
            // height: isMobile ? "34px" : height ? height : "36px"
            paddingTop: "5px",
            paddingBottom: "5px",
            width: width || "100%",
            minHeight: "30px",
            paddingLeft: ".2rem",
            fontFamily: "Inter",
            fontWeight: fontWeight ? fontWeight : "300",
            color: color ? color : SystemColors.v1.sesame,
            boxShadow: state.isFocused ? " 0 0 5px 1px rgba(0, 0, 0, 0.1)" : 0,
            border: state.isFocused ? "1px solid rgb(220, 220, 220)" : "",
            "&:hover": {
                border: state.isFocused ? "solid 1px #efefef" : "",
            },
            cursor: "pointer",
        }),

        container: (base) => ({
            ...base,
            marginRight: 1,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: placeHolderColor ? placeHolderColor : "#c1c4c8",
            fontSize: fontSize ?? "16px",
            fontFamily: "Inter",
            fontWeight: "400!important",
            lineHeight: "24px",
            paddingBottom: "2px",
        }),
    };

    const { control, trigger, watch, setValue, getValues } = useFormContext();

    const integrations: IntegrationApps[] = watch(
        INTEGRATION_FIELD_NAMES.INTEGRATIONS,
    );

    useEffect(() => {
        void trigger(fieldName);
    }, []);

    useEffect(() => {
        let links: IntegrationLinkOrAccountNumber[] = getValues(
            INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS,
        );
        integrations.map((integration) => {
            if (!links.find((link) => link.integrationApp === integration)) {
                links.push({
                    integrationApp: integration,
                    linkOrAccountNumber: AccountNumberIntegrations.includes(
                        integration,
                    )
                        ? undefined
                        : "",
                });
            }
        });

        links = links.filter((link) =>
            integrations.includes(link.integrationApp as IntegrationApps),
        );

        setValue(
            INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS,
            links,
        );

        void trigger(
            INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS,
        );
    }, [integrations]);

    return (
        <Controller
            control={control}
            name={fieldName}
            render={({
                field: { onChange, value: values },
                fieldState: { error },
            }) => (
                <span
                    className={clsx(
                        "w-full",
                        ml && `ml-[${ml}]px`,
                        mr && `ml-[${mr}]px`,
                    )}
                >
                    <CreatableSelect
                        isMulti
                        placeholder={placeholder}
                        options={IntegrationAppOptions.map(
                            (integrationApp) => ({
                                label: integrationApp,
                                value: integrationApp,
                            }),
                        )}
                        styles={customStyles}
                        square={square}
                        value={integrations.map((integrationApp) => ({
                            label: integrationApp,
                            value: integrationApp,
                        }))}
                        onChange={(
                            value: Options | OptionsType<Options> | null,
                        ) => {
                            if (value && Array.isArray(value)) {
                                onChange(value.map((c) => c.label));
                                void trigger(fieldName);
                            }
                        }}
                        myFontSize={fontSize}
                        className={className}
                        isSearchable={true}
                        isDisabled={disabled}
                    />
                </span>
            )}
        />
    );
};

export default DropDownSelectIntegrations;
