/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { SystemColors } from "@snackpass/design-system";

import { ReactComponent as Alert } from "src/assets/icons/alert.svg";

const NoticeStyle = css`
    display: flex;
    font-weight: 500;
    font-size: 14px;
    font-family: "Inter";
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    svg {
        margin-right: 6px;
        height: 18px;
        width: 18px;
    }
`;

type Props = {
    text: string;
};

export function NoticeText({ text }: Props) {
    return (
        <div css={NoticeStyle}>
            <Alert fill={SystemColors.v1.sesame} />
            {text}
        </div>
    );
}
