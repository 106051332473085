/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";

import { HeaderTitle } from "#guestbook/shared-styled-components";

export const ConversationsHomeHeader = ({
    showConversation,
}: {
    showConversation: boolean;
}) => (
    <ConversationsHomeHeaderWrapper
        className="divider"
        showConversation={showConversation}
    >
        <HeaderTitle className="title">Conversations</HeaderTitle>
        <div className="place-holder"></div>
    </ConversationsHomeHeaderWrapper>
);

const ConversationsHomeHeaderWrapper = styled.div<{
    showConversation: boolean;
}>`
    grid-area: Header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 48px;
    align-items: center;
    gap: 10px;
    // Not sure if this gonna break any override order, but setting this to 1 (same as conversation) will fix select store dropdown is on top on campaign header, tried to increase the select store zindex but it didn't work
    z-index: 1;
    .place-holder {
        width: 180px;
    }

    @media ${ScreenState.MOBILE} {
        display: ${({ showConversation }) =>
            showConversation ? "none" : "flex"};
        flex-wrap: wrap;
        justify-content: center;
        gap: 2px;
        .switcher-wrapper {
            order: 1;
            flex: 1 0 100%;
        }
        .title {
            flex: 1 0 70%;
            text-align: center;
            justify-content: center;
            // this padding is used to center title on mobile
            padding-left: 34px;
        }
        padding: 16px 14px;
        .place-holder {
            width: 40px;
            height: 38px;
        }
    }
`;
