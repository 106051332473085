import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Drawer as AntdDrawer } from "antd";
import { isMobile } from "react-device-detect";
import { toast } from "sonner";
import { PlusIcon } from "@radix-ui/react-icons";

import useWindowDimensions from "#hooks/use-window-dimensions";
import { CreateIntegrationForm } from "#settings-integrations/components/create-integration-form";
import { Button } from "src/@/components/ui/button";

type RequestProps = {
    storeId: string;
    showCreatingIntegrationView?: boolean;
    setShowCreatingIntegrationView?: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    checked: boolean;
};

export const RequestIntegration = ({
    storeId,
    showCreatingIntegrationView,
    setShowCreatingIntegrationView,
    checked,
}: RequestProps) => {
    const [show, setShow] = useState<boolean>(false);

    const handleShow = () => {
        setShow(!show);
        if (setShowCreatingIntegrationView)
            setShowCreatingIntegrationView(true);
    };

    const handleHide = () => {
        setShow(false);
        if (setShowCreatingIntegrationView)
            setShowCreatingIntegrationView(false);
    };

    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);

    const { width } = useWindowDimensions();

    useEffect(() => {
        setIsSuccess(false);
        setIsFailure(false);
    }, [storeId, show]);

    useEffect(() => {
        if (isSuccess) {
            toast.success(
                "We've received your integration request. New integrations may take up to 10 days to appear on your dashboard. Please contact support with any questions",
                { duration: 10000 },
            );
            setIsSuccess(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isFailure) {
            toast.error("Could not successfully request integrations.", {
                duration: 10000,
            });
            setIsFailure(false);
        }
    }, [isFailure]);

    return (
        <div className="flex flex-row">
            <Button
                className="rounded-full px-2 sm:rounded-md"
                onClick={handleShow}
                disabled={!checked}
            >
                <PlusIcon className="h-5 w-5 fill-white" />
                <span className="hidden:ml-2 hidden sm:block">
                    New Integration
                </span>
            </Button>
            {isMobile ? (
                <AntdDrawer
                    width={width}
                    placement="right"
                    onClose={() =>
                        setShowCreatingIntegrationView &&
                        setShowCreatingIntegrationView(false)
                    }
                    open={showCreatingIntegrationView}
                    closable={false}
                    destroyOnClose={true}
                    forceRender={true}
                    bodyStyle={{ padding: "16px" }}
                >
                    <CreateIntegrationForm
                        storeId={storeId}
                        onHide={handleHide}
                        setIsSuccess={setIsSuccess}
                        setIsFailure={setIsFailure}
                    />
                </AntdDrawer>
            ) : (
                <Modal size="xl" animation show={show} onHide={handleHide}>
                    <CreateIntegrationForm
                        storeId={storeId}
                        onHide={handleHide}
                        setIsSuccess={setIsSuccess}
                        setIsFailure={setIsFailure}
                    />
                </Modal>
            )}
        </div>
    );
};
