import React from "react";
import { Modal } from "antd";
import styled from "styled-components";

import useWindowDimensions from "#hooks/use-window-dimensions";
import colors from "#reusable/colors/colors.json";
import ModalCloseButton from "#devices/components/ModalCloseButton";

export const DevicesModal = ({
    visible,
    children,
    footer,
    afterClose,
    handleClose,
}: {
    visible: boolean;
    children: React.ReactNode;
    footer?: React.ReactNode;
    afterClose?: () => void;
    handleClose?: () => void;
}) => {
    const { isMobile } = useWindowDimensions();
    return (
        <StyledModal
            style={isMobile ? { top: 0 } : {}}
            onCancel={handleClose}
            open={visible}
            closable={false} // use custom close button
            destroyOnClose
            footer={footer}
            afterClose={afterClose}
        >
            <Header>
                <ModalCloseButton onClose={handleClose} />
            </Header>
            {children}
        </StyledModal>
    );
};

const Header = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.baseAndAHalf};
`;

const StyledModal = styled(Modal)`
    ${({ theme }) => `
        .ant-modal-root {
            display: flex;
            flex: 1;
            justify-content: center;
        }
        .ant-modal-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            border-radius: ${theme.spacing.base};
            border: none;
        }
        .ant-modal-header,
        .ant-modal-footer,
        .ant-modal-body {
            border: none;
            padding: ${theme.spacing.baseAndAHalf};
            justify-content: space-between;
        }
        .ant-modal-footer {
            border-top: 1px solid ${colors["neutral-400"]};
        }
    `}
`;
