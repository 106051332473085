import { useState } from "react";
import { Redirect } from "react-router-dom";

import { useLogout } from "#hooks/use-logout";
import { ConfirmLogoutAlert } from "#navigation/confirm-logout-alert";
import { Routes } from "#navigation/routes";

export const SignOut = () => {
    const logout = useLogout();
    const [open, setOpen] = useState(true);
    return open ? (
        <ConfirmLogoutAlert
            open={true}
            message="This will log you out of the dashboard"
            close={() => setOpen(false)}
            onContinue={logout}
        />
    ) : (
        <Redirect to={Routes.SettingsAccount} />
    );
};
