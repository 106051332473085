import styled from "styled-components";

type Props = {
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
};

const divider = "1px solid #dde5ee";

export const Divider = styled.span<Props>`
    display: flex;
    border-bottom: ${divider};
    margin-left: ${(props) => (props.ml ? `${props.ml}px` : "0")};
    margin-right: ${(props) => (props.mr ? `${props.mr}px` : "0")};
    margin-top: ${(props) => (props.mt ? `${props.mt}px` : "24px")};
    margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : "24px")};
`;
