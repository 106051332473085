import { useContext } from "react";
import { match } from "ts-pattern";

import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { descriptions } from "#onboarding/utils/descriptions";
import { OnboardingStep } from "#onboarding/utils/types";

export const useHeaderText = () => {
    const { step } = useContext(OnboardingContext);
    return match(step)
        .with(OnboardingStep.Summary, () => descriptions.steps.summary)
        .with(OnboardingStep.Business, () => descriptions.steps.business)
        .with(OnboardingStep.Location, () => descriptions.steps.location)
        .exhaustive();
};
