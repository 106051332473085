import { match } from "ts-pattern";
import { nanoid } from "nanoid";

import {
    Element as Variant,
    Shape,
    ShapeStroke,
} from "src/api/graphql/generated/types";
import {
    BarrierProperty,
    CircleGeometry,
    DiamondGeometry,
    LabelProperty,
    RectGeometry,
    TableProperty,
} from "#table-editor/types";

export function makeElement(
    x: number,
    y: number,
    shape: Shape,
    variant: Variant,
) {
    return match({ shape, variant })
        .with({ shape: Shape.Rect, variant: Variant.Table }, () => ({
            id: nanoid(),
            geometry: makeRect(x, y),
            properties: makeTable(),
        }))
        .with({ shape: Shape.Circle, variant: Variant.Table }, () => ({
            id: nanoid(),
            geometry: makeCircle(x, y),
            properties: makeTable(),
        }))
        .with({ shape: Shape.Diamond, variant: Variant.Table }, () => ({
            id: nanoid(),
            geometry: makeDiamond(x, y),
            properties: makeTable(),
        }))
        .with({ variant: Variant.Label }, () => ({
            id: nanoid(),
            geometry: makeDashRect(x, y),
            properties: makeLabel(),
        }))
        .with({ variant: Variant.Barrier }, () => ({
            id: nanoid(),
            geometry: makeRect(x, y, 180, 90),
            properties: makeBarrier(),
        }))
        .exhaustive();
}

const makeRect = (
    x: number,
    y: number,
    width = 120,
    height = 120,
): RectGeometry => ({
    type: Shape.Rect,
    fill: true,
    width,
    height,
    x,
    y,
});

const makeCircle = (x: number, y: number): CircleGeometry => ({
    type: Shape.Circle,
    radius: 60,
    x,
    y,
});

const makeDiamond = (x: number, y: number): DiamondGeometry => ({
    type: Shape.Diamond,
    size: 82.5,
    x,
    y,
});

const makeDashRect = (x: number, y: number): RectGeometry => ({
    type: Shape.Rect,
    width: 180,
    height: 90,
    x,
    y,
    stroke: ShapeStroke.Dash,
    fill: false,
});

const makeTable = (): TableProperty => ({
    type: Variant.Table,
    tableId: "",
    tableName: "",
    numSeats: 4,
});

const makeLabel = (): LabelProperty => ({
    type: Variant.Label,
    label: "",
});

const makeBarrier = (): BarrierProperty => ({
    type: Variant.Barrier,
});
