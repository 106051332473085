import { captureException } from "@sentry/react";
import React, { PropsWithChildren } from "react";
import { Button } from "src/@/components/ui/button";

// Essentally the same as the other react hook for the upload widget, but replaces the button with shadcn
// Didn't replace the button in the original file because it was referenced a lot
type WidgetProps = {
    /**
     * Runs *only* after successful Cloudinary upload
     * @param result Result data from Cloudinary upload.
     */
    onUpload: (result: any) => void;
    /**
     * Runs on UploadButton click, after widget is opened.
     */
    onClick?: () => void;
    /**
     * Runs when widget closes, regardless of success
     */
    onClose?: () => void;
    cropping?: boolean;
    disabled?: boolean;
    croppingAspectRatio?: number;
};

type HookProps = {
    preset: string;
    folder: string;
    clientAllowedFormats?: string[]; //https://cloudinary.com/documentation/upload_widget_reference
    maxVideoFileSize?: number;
    maxImageFileSize?: number;
};

//TODO: Turn this into upload widget hook
export const useCloudinaryUploadWidget = ({
    preset,
    folder,
    clientAllowedFormats,
    maxVideoFileSize,
    maxImageFileSize,
}: HookProps) => {
    const cloudName = "ddhetmqtt"; // replace with your own cloud name
    const uploadPreset = preset; // replace with your own upload preset

    const openWidget = (
        onUpload?: (result: any) => void,
        cropping?: boolean,
        onClose?: () => void,
        croppingAspectRatio?: number,
    ) =>
        // @ts-ignore Cloudinary is defined globally at index.html
        cloudinary.openUploadWidget(
            {
                cloudName: cloudName,
                uploadPreset: uploadPreset,
                cropping: cropping ? cropping : false, //add a cropping step
                sources: ["local", "url"], // restrict the upload sources to URL and local files
                multiple: false, //restrict upload to a single file
                folder: folder, //upload files to the specified folder
                ...(clientAllowedFormats && {
                    clientAllowedFormats: clientAllowedFormats,
                }), //restrict uploading to image files only
                ...(maxVideoFileSize && {
                    maxVideoFileSize: maxVideoFileSize,
                }),
                ...(maxImageFileSize && {
                    maxImageFileSize: maxImageFileSize,
                }),
                // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
                // theme: "purple", //change to a purple theme,
                showAdvancedOptions: false,
                croppingAspectRatio: croppingAspectRatio,
            },
            // @ts-ignore
            (error, result) => {
                if (!error && result) {
                    if (result.event === "success") {
                        onUpload?.(result.info);
                    }
                    if (result.event === "close") {
                        onClose?.();
                    }
                } else if (error) {
                    captureException(Error("Cloudinary Upload Error"));
                }
            },
        );

    const UploadButton = ({
        onUpload,
        onClick,
        onClose,
        cropping,
        disabled = false,
        children,
        croppingAspectRatio,
    }: PropsWithChildren<WidgetProps>) => (
        <Button
            type="button"
            variant="outline"
            className={"cloudinary-button"}
            disabled={disabled}
            onClick={
                !disabled
                    ? () => {
                          openWidget(
                              onUpload,
                              cropping,
                              onClose,
                              croppingAspectRatio,
                          );
                          onClick && onClick();
                      }
                    : () => {}
            }
        >
            {children ? children : "Upload Video"}
        </Button>
    );

    return {
        UploadButton,
        /**
         * Sends info to onUpload on success.
         * Use `.secure_url` when saving image/video url
         */
        openWidget,
    };
};
