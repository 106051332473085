import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import axios from "axios";

import { getActiveStore } from "src/redux/selectors";
import FilterHeader, {
    FilterType,
} from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportsContext } from "#app/reports-context-provider";
import api from "src/api/rest";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import CustomerDirectoryTable from "#reports/customer-directory-insights/components/CustomerDirectoryTable";
import { processCustomerData } from "#reports/customer-directory-insights/lib";
import { logAndSendError } from "src/utils/errors";

const CustomerPurchaseReport = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges } = reportsState;

    const trackEvent = useTrackEvent();

    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.customerInsightsData) {
            // set loading
            setReportsState((reportsState) => ({
                ...reportsState,
                customerInsightsData: {
                    customerDataLoading: true,
                    customerInsightsDataFailed: false,
                    customerData:
                        reportsState?.customerInsightsData?.customerData,
                },
            }));

            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        const params = {
            storeId: activeStore?._id,
            startDate: reportsState.allTime
                ? undefined
                : dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: (reportsState.allTime
                ? moment()
                : dateRanges[0][1]
            ).format("YYYY-MM-DD"),
        };

        api.reports
            .getCustomerInsights(params, abortController.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    customerInsightsData: {
                        customerDataLoading: false,
                        customerInsightsDataFailed: false,
                        customerData: processCustomerData(res.data.customers),
                    },
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    customerInsightsData: {
                        customerDataLoading: false,
                        customerInsightsDataFailed: true,
                        customerData: undefined,
                    },
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);

    const fetchDataDebounced = useCallback(
        debounce(() => fetchDataRef.current?.(), 600),
        [],
    );

    return (
        <div className="flex flex-col">
            <FilterHeader
                hideFilters={[
                    FilterType.CHANNEL,
                    FilterType.FULFILLMENT,
                    FilterType.GRANULARITY,
                    FilterType.PLATFORM,
                ]}
                showAllTimeRange
                reportType={ReportType.CUSTOMER_DIRECTORY}
            />
            <div className="px-6 md:px-24">
                <CustomerDirectoryTable />
            </div>
        </div>
    );
};

export default CustomerPurchaseReport;
