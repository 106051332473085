import React from "react";

import {
    AlertDialog,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogCancel,
    AlertDialogAction,
    AlertDialogContent,
} from "src/@/components/ui/alert-dialog";
import { buttonVariants } from "src/@/components/ui/button";

type ConfirmReplaceAlertProps = {
    message: string;
    open: boolean;
    close: () => void;
    onContinue: () => void;
    deleteText: string;
};

/**
 * A confirm dialog that appears when a user is trying to delete an item from the inventory.
 */
export const ConfirmDeleteAlert = ({
    message,
    open,
    close,
    onContinue,
    deleteText,
}: ConfirmReplaceAlertProps) => (
    <AlertDialog open={open} onOpenChange={close}>
        <AlertDialogContent className="z-[9999]" overlayClassName="z-[9998]">
            {" "}
            <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>{message}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel onClick={close}>Cancel</AlertDialogCancel>
                <AlertDialogAction
                    onClick={() => {
                        onContinue();
                        close();
                    }}
                    className={buttonVariants({ variant: "destructive" })}
                >
                    {deleteText}
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
);
