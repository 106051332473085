import {
    Break,
    BreakType,
    EmployeeShiftDetails,
} from "@snackpass/snackpass-types";
import { SetOptional } from "type-fest";

import { TimecardData } from "#time-and-attendance/types";

export type RowData = SetOptional<
    TimecardData,
    "id" | "clockedInAt" | "clockedOutAt" | "breaks" | "cashTips"
> & {
    blendedWage?: number;
    unpaidBreaks?: number;
};

const usePrepareExpandableRows = () => {
    const makeExpandedData = (
        employeeShiftDetails: EmployeeShiftDetails[],
    ): RowData[] => {
        const data: RowData[] = [];

        employeeShiftDetails.forEach(
            (employeeShiftDetail: EmployeeShiftDetails) => {
                if (employeeShiftDetail.shifts.length < 1) {
                    return; // this happens if there is a shift in the future
                }
                const unpaidBreaksPerEmployee = (): number => {
                    let unpaidBreaks = 0;
                    employeeShiftDetail.shifts.forEach((shift) => {
                        shift.breaks.forEach((breakItem: Break) => {
                            if (breakItem.type === BreakType.UnpaidBreak) {
                                unpaidBreaks++;
                            }
                        });
                    });
                    return unpaidBreaks;
                };
                const rowData = {
                    // TODO: update ShiftSummary types and API to include unpaid breaks
                    employeeId: employeeShiftDetail.employee.id,
                    employeeName: employeeShiftDetail.employee.name ?? "",
                    employeeFirstName:
                        employeeShiftDetail.employee.firstName ?? "",
                    employeeLastName:
                        employeeShiftDetail.employee.lastName ?? "",
                    employeeRole: employeeShiftDetail.employee.role ?? "",
                    employeeWage: employeeShiftDetail.employee.wage ?? 0,
                    totalShifts: employeeShiftDetail.shifts.length ?? 0,
                    regularHours: employeeShiftDetail.regularHours ?? 0,
                    overtimeHours: employeeShiftDetail.overtimeHours ?? 0,
                    doubleOvertimeHours:
                        employeeShiftDetail.doubleOvertimeHours ?? 0,
                    unpaidBreaks: unpaidBreaksPerEmployee(),
                    totalHours: employeeShiftDetail.totalHours ?? 0,
                    estWages: employeeShiftDetail.estWages ?? 0,
                    wage: 0,
                    identifier: employeeShiftDetail.employee.identifier ?? "",
                    blendedWage: employeeShiftDetail.blendedWage ?? 0,
                    clockedInAt: new Date(),
                    clockedOutAt: new Date(),
                    subRows: employeeShiftDetail.shifts.map((shift) => {
                        const unpaidBreaksPerShift = (): number => {
                            let unpaidBreaks = 0;
                            shift.breaks.forEach((breakItem: Break) => {
                                if (breakItem.type === BreakType.UnpaidBreak) {
                                    unpaidBreaks++;
                                }
                            });
                            return unpaidBreaks;
                        };
                        return {
                            id: shift.id,
                            employeeId: employeeShiftDetail.employee.id,
                            employeeName:
                                employeeShiftDetail.employee.name ?? "",
                            employeeRole:
                                employeeShiftDetail.employee.role ?? "",
                            totalShifts: employeeShiftDetail.shifts.length ?? 0,
                            clockedInAt: new Date(shift.clockedInAt),
                            breaks: shift.breaks,
                            clockedOutAt: shift.clockedOutAt
                                ? new Date(shift.clockedOutAt)
                                : null,
                            regularHours: shift.regularHours ?? 0,
                            overtimeHours: shift.overtimeHours ?? 0,
                            doubleOvertimeHours: shift.doubleOvertimeHours ?? 0,
                            unpaidBreaks: unpaidBreaksPerShift(),
                            totalHours: shift.totalHours ?? 0,
                            cashTips: shift.cashTips ?? 0,
                            estWages: shift.estWages ?? 0,
                            wage: shift.wage,
                            identifier:
                                employeeShiftDetail.employee.identifier ?? "",
                            note: shift.note ?? "",
                        };
                    }),
                };

                data.push(rowData);
            },
        );

        return data;
    };

    return {
        makeExpandedData,
    };
};

export default usePrepareExpandableRows;
