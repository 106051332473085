import { PlusIcon } from "@radix-ui/react-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import { toast } from "sonner";
import Skeleton from "react-loading-skeleton";

import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Sheet, SheetContent } from "src/@/components/ui/sheet";
import { DataTable } from "src/@/components/ui/data-table";
import { Button } from "src/@/components/ui/button";
import { Routes } from "#navigation/routes";
import api from "src/api/rest";
import { InvoiceNew } from "#invoices/components/invoice-new";
import { InvoiceDetail } from "#invoices/components/invoice-detail";
import { useGetInvoicesQuery } from "src/api/graphql/generated/types";
import { getActiveStoreId } from "src/redux/slices";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import { CurrentInvoice } from "#invoices/types";
import { FeatureBadge } from "#navigation/FeatureBadge";

import { getColumns } from "./columns";
import { ConfirmDialog } from "./confirm-dialog";

export const Invoices = () => {
    const [isOpenInvoiceSheet, setIsOpenInvoiceSheet] = useState(false);
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState<
        CurrentInvoice | undefined
    >(undefined);

    const activeStoreId = useSelector(getActiveStoreId);
    const { data, error, refetch, loading } = useGetInvoicesQuery({
        variables: { storeId: activeStoreId },
    });

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            placeholder: "Search by Customer Name or Email",
            global: true,
        },
    };

    useEffect(() => {
        if (error) {
            toast.error(error.message);
        }
    }, [error]);

    const handleInvoiceCreate = useCallback(async () => {
        await refetch();
        setIsOpenInvoiceSheet(false);
    }, [refetch]);

    const closeInvoiceCreate = () => {
        setIsOpenInvoiceSheet(false);
    };

    const handleInvoiceOpenChange = (open: boolean) => {
        setIsOpenInvoiceSheet(open);
        if (!open) {
            setCurrentInvoice(undefined);
        }
    };

    const handleConfirmOpenChange = (open: boolean) => {
        setIsOpenConfirmDialog(open);
    };

    const handleInvoiceNew = () => {
        // Show confirm modal until the user creates their first invoice
        if (!loading && data?.invoices && data.invoices.length === 0) {
            setIsOpenConfirmDialog(true);
        } else {
            setIsOpenInvoiceSheet(true);
        }
    };

    const handleAccept = () => {
        setIsOpenConfirmDialog(false);
        setIsOpenInvoiceSheet(true);
    };

    const handleCellSelected = (invoice: CurrentInvoice) => {
        setCurrentInvoice(invoice);
        setIsOpenInvoiceSheet(true);
    };

    const columns = getColumns(handleCellSelected);

    const [requiresPayouts, setRequiresPayouts] = useState<boolean>(false);

    useEffect(() => {
        const checkIfRequired = async (storeId: string) =>
            api.verifications
                .status(storeId)
                .then(({ data }) => data.status === "destructive")
                .catch(() => false);

        const checkAndSetPayoutsWarnState = async () => {
            if (activeStoreId) {
                const required = await checkIfRequired(activeStoreId);
                setRequiresPayouts(required);
            }
        };

        void checkAndSetPayoutsWarnState();
    }, [activeStoreId]);

    return (
        <div className="p-6 md:px-12">
            <PayoutsAlert show={requiresPayouts} />
            <Sheet
                open={isOpenInvoiceSheet}
                onOpenChange={handleInvoiceOpenChange}
            >
                <div className="mb-4 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <span className="text-large font-semibold">
                            Invoices
                        </span>
                        <FeatureBadge variant="beta" />
                    </div>
                    <Button
                        onClick={handleInvoiceNew}
                        disabled={requiresPayouts}
                    >
                        <PlusIcon className="h-4 w-4 fill-neutral-950" />
                        <span className="hidden lg:block">New Invoice</span>
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <Skeleton className="h-80 w-full rounded-xl" />
                    ) : (
                        <DataTable
                            showPagination
                            toolbar={toolbarOptions}
                            emptyText="No Invoices Sent"
                            columns={columns}
                            data={data?.invoices || []}
                            customPageSize={50}
                        />
                    )}
                </div>
                <SheetContent
                    side="right"
                    overlayClassName="z-[9998] bg-black/40"
                    className="z-[9999] max-h-screen w-full overflow-y-scroll sm:min-w-[750px]"
                >
                    {currentInvoice ? (
                        <InvoiceDetail invoice={currentInvoice} />
                    ) : (
                        <InvoiceNew
                            onCreate={handleInvoiceCreate}
                            close={closeInvoiceCreate}
                        />
                    )}
                </SheetContent>
            </Sheet>
            <ConfirmDialog
                isOpen={isOpenConfirmDialog}
                onOpenChange={handleConfirmOpenChange}
                onAccept={handleAccept}
            />
        </div>
    );
};

const PayoutsAlert = ({ show }: { show: boolean }) =>
    show ? (
        <Alert variant={"destructive"} className="mb-6">
            <AlertTitle className="text-base">
                <span>Attention Required</span>
            </AlertTitle>
            <AlertDescription>
                You must complete payouts onboarding to create an invoice.
                Please review the Payouts Settings page{" "}
                <Link to={Routes.Payouts}>here</Link> for more information.
            </AlertDescription>
        </Alert>
    ) : null;
