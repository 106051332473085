import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getPersistedActiveStoreId } from "#hooks/initial/use-persist-store-id";
import { useAppDispatch } from "src/redux/hooks";
import { getUser } from "src/redux/selectors";
import { setActiveStoreId, setIsLoading } from "src/redux/slices";
import Constants from "#core/constants";

export function useLoadSnackpassStores() {
    const user = useSelector(getUser);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!user) {
            return;
        }
        // [set stores]
        dispatch(setIsLoading(true));
        // either use the cached store or the user's store (of which they're an admin)
        const storeId =
            getPersistedActiveStoreId() ||
            user.snackpassPermissions.storeIds[0];

        if (!storeId && user.snackpassPermissions.isSnackpassEmployee) {
            dispatch(setActiveStoreId(Constants.HOGWARTS_STORE_ID));
            return;
        } else {
            dispatch(setActiveStoreId(storeId ?? null));
        }
    }, [dispatch, user]);
}
