import { Spinner } from "react-activity";

import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "src/@/components/ui/alert-dialog";
import { Button } from "src/@/components/ui/button";

export interface ConfirmActionDialogProps {
    close: () => void;
    confirm: () => void;
    confirming: boolean;
    message: string;
    open: boolean;
}

/**
 * A generic confirmation dialog that confirms the users intention to perform an action.
 */
export function ConfirmActionDialog({
    open,
    close,
    confirm,
    confirming,
    message,
}: ConfirmActionDialogProps) {
    return (
        <AlertDialog open={open} onOpenChange={close}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>{message}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <Button
                        variant={"outline"}
                        onClick={close}
                        disabled={confirming}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={"destructive"}
                        disabled={confirming}
                        onClick={confirm}
                    >
                        {confirming ? <Spinner size={16} /> : "Confirm"}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
