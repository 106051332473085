import React, { useContext, useMemo, useState } from "react";
import { Spinner } from "react-activity";
import { DeviceType } from "@snackpass/snackpass-types";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import EditSection from "#devices/components/EditDrawer/EditSection";
import { shouldHaveCashDrawerAddon } from "#devices/utils/shouldHaveCashDrawerAddon";

import { EditOrDisconnect } from "./EditOrDisconnect";
import { CreateOrConnect } from "./CreateOrConnect";
import CashDrawerUpdateOrCreateModal from "./CashDrawerUpdateOrCreateModal";

export const CashDrawerEditSection = () => {
    const { device, storeDevices } = useContext(DevicesPageContext);
    const { data: cashDrawers, isLoading: isCashDrawerLoading } =
        useCashDrawers();

    const linkedDrawer = useMemo(
        () =>
            cashDrawers?.find(
                (e) =>
                    e.device === device?.serial || // Checking if on device context with matching kiosk
                    (e.printer && e.printer.serial === device?.serial), // Checking if on device context matching printer
            ),
        [cashDrawers, device],
    );

    const printerDeviceWithConnectedDrawer = useMemo(() => {
        const printerSerial = cashDrawers?.find((e) => !!e.printer)?.printer
            ?.serial;

        // We need to find the store device, because the printer on cash drawer's name is not correctly set.
        return printerSerial
            ? storeDevices?.find((e) => e.serial === printerSerial)
            : undefined;
    }, [cashDrawers]);

    const [cashDrawerModalOpen, setCashDrawerModalOpen] = useState(false);

    const shouldAllowCashDrawer = useMemo(
        () => device && shouldHaveCashDrawerAddon(device),
        [device],
    );
    if (!shouldAllowCashDrawer) return null;

    // We only allow 1 printer connected cash drawer to exist, because of how we push cash drawer opens through SDP.
    const shouldDisallowPrinterConnectedCashDrawer =
        device?.deviceType === DeviceType.Printer &&
        printerDeviceWithConnectedDrawer &&
        printerDeviceWithConnectedDrawer.serial !== device.serial;

    return (
        <EditSection heading="Cash Drawer Settings">
            <SettingsSection
                label="Connect Drawer"
                description="Change settings or create a new cash drawer"
            >
                <CashDrawerUpdateOrCreateModal
                    open={cashDrawerModalOpen}
                    setOpen={setCashDrawerModalOpen}
                    cashDrawer={linkedDrawer}
                />
                {!shouldDisallowPrinterConnectedCashDrawer ? (
                    <div className="text-right">
                        {isCashDrawerLoading ? (
                            <Spinner speed={0.5} size={18} />
                        ) : linkedDrawer ? (
                            <EditOrDisconnect
                                openEditModal={() =>
                                    setCashDrawerModalOpen(true)
                                }
                                linkedDrawer={linkedDrawer}
                            />
                        ) : (
                            <CreateOrConnect
                                openEditModal={() =>
                                    setCashDrawerModalOpen(true)
                                }
                                cashDrawers={cashDrawers}
                            />
                        )}
                    </div>
                ) : (
                    <div className="max-w-md flex-1 text-right">
                        Only one printer connected to a cash drawer is
                        supported. Please disconnect the cash drawer from{" "}
                        {printerDeviceWithConnectedDrawer.name} to connect a
                        drawer to this printer.
                    </div>
                )}
            </SettingsSection>
        </EditSection>
    );
};
