import React, { useCallback, useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import { useDispatch, useSelector } from "react-redux";
import { DraftHandleValue, Editor, getDefaultKeyBinding } from "draft-js";
import "draft-js/dist/Draft.css";
import styled from "@emotion/styled";
import { compose } from "lodash/fp";

import { SelectedGifts } from "#guestbook/components/message-input/selected-gifts";
import {
    useConversationValidator,
    useInitializeConversationEditorState,
    InputProps,
} from "#guestbook/components/conversation-input/libs";
import { GuestbookInputType } from "#guestbook/components/message-input/libs";

import { getConversationInput, setConversationInputMessage } from "../../redux";

type SyntheticKeyboardEvent = React.KeyboardEvent<{}>;

export const Input = ({ _onSubmit, isLoading }: InputProps) => {
    const dispatch = useDispatch();
    const editorState = useSelector(getConversationInput);
    const setEditorState = compose(dispatch, setConversationInputMessage);
    const validation = useConversationValidator();
    const [isSending, setIsSending] = useState(false);

    useInitializeConversationEditorState();

    const _onSend = useCallback(async () => {
        setIsSending(true);
        try {
            await _onSubmit();
        } finally {
            setIsSending(false);
        }
    }, [_onSubmit]);

    const keyBindingFn = (e: SyntheticKeyboardEvent) => {
        if (
            e.key === "Enter" &&
            validation.isValid &&
            !isLoading &&
            !isSending
        ) {
            // if shift enter, add a new line
            if (e.nativeEvent.shiftKey) {
                return getDefaultKeyBinding(e);
            } else {
                // else send the message
                return "send";
            }
        }

        return getDefaultKeyBinding(e);
    };

    const handleKeyCommand = (command: string): DraftHandleValue => {
        if (command === "send") {
            void _onSend().then(() => "handled");
        }
        return "not-handled";
    };

    return (
        <InputContainer>
            <SelectedGifts inputType={GuestbookInputType.CONVERSATION} />
            {editorState && (
                <Editor
                    placeholder="Message"
                    editorState={editorState}
                    onChange={setEditorState}
                    spellCheck={true}
                    keyBindingFn={keyBindingFn}
                    handleKeyCommand={handleKeyCommand}
                />
            )}
        </InputContainer>
    );
};

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid ${SystemColors.v1.gray90};
    border-radius: 25px;
    overflow: hidden;
`;
