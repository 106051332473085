import { useFormContext } from "react-hook-form";

import { Card } from "src/@/components/ui/card";
import { FormControl, FormField } from "src/@/components/ui/form";
import { CharacterCounterInput } from "src/@/components/ui/input";
import CampaignFormItemLayout from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/CampaignFormItemLayout";

function DisplayNameCardInput() {
    const { control } = useFormContext();
    return (
        <Card>
            <FormField
                control={control}
                name="displayName"
                render={({ field }) => (
                    <CampaignFormItemLayout
                        label={"Sender name"}
                        description={
                            "This sender name appears at the beginning of each text. Something short and recognizable is recommended. If you are registering a location within a chain, we recommend identifying the location in the sender name."
                        }
                        subDescription={
                            "Please use no more than 24 characters. No \
                                special characters."
                        }
                    >
                        <FormControl>
                            <CharacterCounterInput {...field} maxLength={24} />
                        </FormControl>
                    </CampaignFormItemLayout>
                )}
            />
        </Card>
    );
}

export default DisplayNameCardInput;
