import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";

import { firebaseAuth } from "#app/firebase";
import api from "src/api/rest";
import { useAppDispatch } from "src/redux/hooks";
import { clearUser, setUser } from "src/redux/slices";

import { clearPersistedActiveStoreId } from "./use-persist-store-id";

export function useLoadSnackpassUser() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            async (firebaseUser) => {
                if (!firebaseUser) {
                    dispatch(clearUser());
                    clearPersistedActiveStoreId();
                    return;
                }
                try {
                    // This is needed because the user token on the axios
                    // client doesn't get applied quick enough
                    const idToken = await firebaseUser?.getIdToken();
                    const response = await api.users.getMe(idToken);
                    const { user, hash } = response.data;
                    dispatch(setUser({ ...user, intercomHash: hash }));
                } catch (e) {
                    console.log("Error fetching user: ", e);
                    throw e;
                }
            },
        );
        return unsubscribe;
    }, []);
}
