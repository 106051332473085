import React, { useContext, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DeviceType } from "#devices/utils/deviceTypes";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { ChannelType } from "#devices/utils/deviceTypes/SnackTVDevice";
import EditSection from "#devices/components/EditDrawer/EditSection";
import { DeleteSection } from "#devices/components/EditDrawer/DeleteSection";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import {
    EditDeviceName,
    EditDeviceType,
} from "#devices/components/EditDrawer/Settings";
import { EditChannelDisplay } from "#devices/components/EditDrawer/Settings/ChannelDisplay";
import { MenuBoard } from "#devices/components/EditDrawer/DeviceEdit/SnackTV/MenuBoard";
import { OtherChannelDisplay } from "#devices/components/EditDrawer/DeviceEdit/SnackTV/OtherChannelDisplay";

import { CustomerReadyScreen, FullScreenGallery } from "./SnackTV";

const deviceTypeOptions = [DeviceType.SnackTV].map((option) => ({
    value: option,
    label: getDeviceTypeName(option) || "--",
}));

const SnackTvEdit = () => {
    const { snackTvDevice, updatedDevice } = useContext(DevicesPageContext);

    const channel = (updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails.channel) as ChannelType;

    const channelSettings = useMemo(() => {
        switch (channel) {
            case ChannelType.CustomerReadyScreen:
                return <CustomerReadyScreen />;
            case ChannelType.InfiniteScrollImage:
                return <MenuBoard />;
            case ChannelType.FullScreenGallery:
                return <FullScreenGallery />;
            case ChannelType.Video:
            case ChannelType.StaticImage:
                return <OtherChannelDisplay />;
            default:
                return <div>Unsupported TV Selection</div>;
        }
    }, [snackTvDevice, channel]);

    return (
        <DndProvider backend={HTML5Backend}>
            <EditSection heading="Device Settings">
                <EditDeviceName />
                <EditDeviceType disabled options={deviceTypeOptions} />
                <EditChannelDisplay />
            </EditSection>
            <EditSection heading="Channel Settings">
                {channelSettings}
            </EditSection>
            <EditSection heading="Danger Zone">
                <DeleteSection />
            </EditSection>
        </DndProvider>
    );
};

export default SnackTvEdit;
