/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { findIndex } from "lodash";
import { useSelector } from "react-redux";
import { IPayPeriodSummary, ScreenState } from "@snackpass/snackpass-types";
import moment from "moment";
import { SystemColors } from "@snackpass/design-system";
import { Spinner } from "react-activity";
import { toDollar } from "@snackpass/accounting";
import { useHistory } from "react-router-dom";

import { MonthOptions } from "#time-and-attendance/types";
import { ReactComponent as CalendarBack } from "src/assets/icons/left-arrow.svg";
import { ReactComponent as CalendarForward } from "src/assets/icons/right-arrow.svg";
import { MonthPicker } from "#employees/employee-labor-cost/month-picker";
import {
    getActiveStore,
    getActiveStoreAttendanceSettings,
} from "src/redux/selectors";
import { ReactComponent as SettingsSVG } from "src/assets/icons/settings-gear-v2.svg";
import usePaySummaries from "#employees/employee-labor-cost/use-pay-summaries";
import colors from "#reusable/colors/colors.json";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";

const EmployeeLaborCost = () => {
    const storeSettings = useSelector(getActiveStoreAttendanceSettings);
    const showSettingsMessage = !storeSettings?.strategy;
    const history = useHistory();
    const activeStore = useSelector(getActiveStore);
    const [selectedMonth, setSelectedMonth] = useState<MonthOptions>({
        month: moment().format("MMMM"),
        year: moment().format("Y"),
        date: moment(),
    });
    const [monthOptions, setMonthOptions] = useState<MonthOptions[]>([]);
    const { loading, paySummaries } = usePaySummaries(selectedMonth);

    useEffect(() => {
        const _monthOptions: MonthOptions[] = [];
        for (let i = 11; i >= 0; i -= 1) {
            const monthYear = moment().subtract(i, "months");
            const month = monthYear.format("MMMM");
            const year = monthYear.format("Y");
            _monthOptions.push({ month, year, date: monthYear });
        }
        setMonthOptions(_monthOptions.reverse());
    }, []);

    return (
        <PageBody>
            {showSettingsMessage ? (
                <>
                    <div className="mb-3">
                        To view Labor reports, please visit settings to set your
                        store’s pay period
                    </div>
                    <div
                        className="flex h-9 max-w-fit cursor-pointer content-around items-center rounded-xl border border-neutral-300 bg-white px-3 py-2"
                        onClick={() => history.push(`settings-employees`)}
                    >
                        <SettingsSVG fill={SystemColors.v1.sesame} width={18} />
                        <span className="ml-2 text-body font-semibold">
                            Settings
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className="header">Labor Cost</div>
                    <div className="dropdown-wrapper">
                        <CircleButton
                            onClick={() => {
                                const index = findIndex(
                                    monthOptions,
                                    selectedMonth,
                                );
                                if (index > 0) {
                                    setSelectedMonth(monthOptions[index - 1]);
                                }
                            }}
                        >
                            <CalendarBack />
                        </CircleButton>
                        <MonthPicker
                            months={monthOptions}
                            setSelectedMonth={setSelectedMonth}
                            selectedMonth={selectedMonth}
                        />
                        <CircleButton
                            onClick={() => {
                                const index = findIndex(
                                    monthOptions,
                                    selectedMonth,
                                );
                                if (index < monthOptions.length - 1) {
                                    setSelectedMonth(monthOptions[index + 1]);
                                }
                            }}
                        >
                            <CalendarForward />
                        </CircleButton>
                    </div>
                    <div className="mt-8">
                        {loading ? (
                            <Spinner
                                color={SystemColors.v1.candy50}
                                size={12}
                            />
                        ) : (
                            <>
                                {paySummaries.map((paySummary, index) => (
                                    <PayPeriodSummaryRow
                                        key={index}
                                        data={paySummary}
                                        onClickPayPeriod={() => {
                                            history.push(
                                                `employee-time-cards?start=${paySummary.startDate}&end=${paySummary.endDate}`,
                                            );
                                        }}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}
        </PageBody>
    );
};

type PayPeriodData = {
    data: IPayPeriodSummary;
    onClickPayPeriod: () => void;
};

const PayPeriodSummaryRow = (payPeriodData: PayPeriodData) => {
    const { data, onClickPayPeriod } = payPeriodData;
    const start = moment(data.startDate);
    const end = moment(data.endDate);
    const format = "ddd, MMM D";

    const metrics = [
        {
            data: data.timeCards,
            label: "Time Cards",
        },
        {
            data: toDollar(data.netSales),
            label: "Net Sales",
        },
        {
            data: data.regularHours,
            label: "Regular Hours",
        },
        {
            data: data.overtimeHours,
            label: "Est. OT Hours",
        },
        {
            data: data.totalHours,
            label: "Paid Hours",
        },
        {
            data: toDollar(data.estWages),
            label: "Est. Wages",
        },
    ];

    return (
        <Card
            className="mb-4 max-w-4xl cursor-pointer border-neutral-400"
            onClick={onClickPayPeriod}
        >
            <CardHeader>
                <CardTitle className="text-large">
                    {start.format(format)} - {end.format(format)}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <PayPeriod>
                    {metrics.map((metric, i) => (
                        <section className="metric" key={i}>
                            <span className="data">{metric.data}</span>
                            <span className="label">{metric.label}</span>
                        </section>
                    ))}
                </PayPeriod>
                <BreakdownButton>
                    <div className="button-text">Employee Breakdown</div>
                </BreakdownButton>
            </CardContent>
        </Card>
        // </PayPeriodContainer>
    );
};

const CircleButton = styled.button`
    @media ${ScreenState.DESKTOP}, ${ScreenState.TABLET} {
        display: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 48px;
    height: 48px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 48px;
    background-color: white;
`;

const BreakdownButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 56px;
    background-color: #f1f5f8;
    width: min(310px, 100%);
    margin: 24px auto 0px auto;

    .button-text {
        color: #282d32;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    @media ${ScreenState.DESKTOP} {
        display: none;
    }
`;

const PayPeriod = styled.div`
    display: grid;
    gap: 24px;
    grid-template-areas: "one two three four five six";

    @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
        grid-template-areas:
            "one two"
            "three four"
            "five six";
    }

    .metric {
        display: flex;
        flex-direction: column;
    }

    .metric:nth-child(1) {
        grid-area: one;
    }
    .metric:nth-child(2) {
        grid-area: two;
    }
    .metric:nth-child(3) {
        grid-area: three;
    }
    .metric:nth-child(4) {
        grid-area: four;
    }
    .metric:nth-child(5) {
        grid-area: five;
    }
    .metric:nth-child(6) {
        grid-area: six;
    }

    .data {
        color: #282d32;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
    }

    .label {
        color: #606c76;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
`;

const PageBody = styled.div`
    .header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: ${SystemColors.v1.sesame};
        margin-bottom: 24px;
    }

    margin: 48px 48px 24px 48px;

    @media ${ScreenState.MOBILE} {
        margin: 24px 16px;

        .dropdown-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`;

export default EmployeeLaborCost;
