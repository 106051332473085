import { useFormContext } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { EIN_FORMAT } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";

function SharedBrandRegistrationEINField() {
    const { control } = useFormContext();
    return (
        <FormField
            control={control}
            name="ein"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <p className="pt-6 font-semibold">
                            Federal Employer Identification Number (EIN)
                        </p>
                    </FormLabel>
                    <FormControl>
                        <Input {...field} placeholder={EIN_FORMAT} />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}

export default SharedBrandRegistrationEINField;
