import { useCallback, useMemo } from "react";
import { PaymentProvider } from "@snackpass/snackpass-types";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { useDashboardContext } from "../../hooks/use-dashboard-context";
import { DefinedPaymentTypes } from "#payment-type-picker/lib";

export const DashboardPaymentTypePicker = () => {
    const { paymentProviders, setPaymentTypes } = useDashboardContext();

    const selectedPaymentTypes = useMemo(
        () => new Set(paymentProviders as string[]),
        [paymentProviders],
    );

    const handleOptionSelected = useCallback(
        (value: string) => {
            const newSelection = new Set(selectedPaymentTypes);
            if (newSelection.has(value)) {
                newSelection.delete(value);
            } else {
                newSelection.add(value);
            }
            setPaymentTypes(Array.from(newSelection) as PaymentProvider[]);
        },
        [selectedPaymentTypes, setPaymentTypes],
    );

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">Payment</span>
                </Button>
            }
            title="Payment Type"
            selectedValues={selectedPaymentTypes}
            options={DefinedPaymentTypes}
            onOptionSelected={handleOptionSelected}
        />
    );
};
