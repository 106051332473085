import { Canvas, FabricText, Rect } from "fabric";

import { Element } from "#table-editor/canvas/Element";
import { LabelProperty, RectGeometry } from "#table-editor/types";
import { LABEL_STROKE_WIDTH } from "#table-editor/canvas/defaults";

/**
 * Draws a label element
 */
export function drawLabel(
    canvas: Canvas,
    id: string,
    shape: RectGeometry,
    properties: LabelProperty
) {
    const rect = new Rect({
        top: 0,
        left: 0,
        width: shape.width,
        height: shape.height,
        fill: "transparent",
        stroke: "#ffaa33",
        selectable: true,
        strokeWidth: LABEL_STROKE_WIDTH,
        strokeDashArray: [8],
        strokeUniform: true
    });

    const text = new FabricText(properties.label, {
        fontFamily: "Inter",
        fontSize: 28,
        textAlign: "center",
        fill: "black"
    });

    text.setPositionByOrigin(rect.getCenterPoint(), "center", "center");
    text.setCoords();

    const group = new Element(id, [rect, text], {
        variant: "label",
        top: shape.y,
        left: shape.x,
        width: shape.width,
        height: shape.height,
        selectable: true
    });

    group.setControlsVisibility({ mtr: false });

    canvas.add(group);

    return group;
}
