import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";

export const UserPermissionAlert = ({ show }: { show: boolean }) =>
    show ? (
        <Alert variant="info">
            <AlertTitle className="text-base">
                Manage Senior Support Users
            </AlertTitle>
            <AlertDescription>
                Senior Support permission is unrelated to the selected store
            </AlertDescription>
        </Alert>
    ) : null;
