import React from "react";

import { cn } from "src/@/lib/utils";

// Keep in sync with TopNavBar's height.
const StackedHeaderNavBarTop = "top-12";

type Props = {
    children: React.ReactNode;
};

function StackedStickyHeader({ children }: Props) {
    return (
        <div className={cn("sticky z-10 bg-white", StackedHeaderNavBarTop)}>
            {children}
        </div>
    );
}

export default StackedStickyHeader;
