import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { z } from "zod";
import { useForm } from "react-hook-form";

import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors/activeStore";

const UNLOCK_PHRASE = "DELETE TWILIO";
const FormSchema = z.object({
    unlockPhrase: z.string().refine((value) => value === UNLOCK_PHRASE, {
        message: UNLOCK_PHRASE,
    }),
});

function DeleteTwilioBrand() {
    const store = useSelector(getActiveStore);

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            unlockPhrase: "",
        },
    });

    async function onSubmit() {
        if (!store) {
            return;
        }
        try {
            await api.twilio.deleteTwilioBrandRegistration(store._id);
            toast("Successfully deleted Twilio brand");
        } catch (e) {
            toast.error("Failed to delete Twilio brand", {
                description: e instanceof Error ? e.message : "",
            });
        }
    }

    return (
        <div className="ml-20 mt-6 flex flex-col">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="w-2/3 space-y-6"
                >
                    <div className="text-xlarge font-semibold leading-6">
                        Delete Twilio Registration
                    </div>
                    <FormField
                        control={form.control}
                        name="unlockPhrase"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    Type DELETE TWILIO exactly to unlock
                                </FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormDescription>
                                    This does not verify if the store has an
                                    active twilio or shared twilio registration,
                                    just goes ahead and deletes them both
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit">Delete Twilio</Button>
                </form>
            </Form>
        </div>
    );
}

export default DeleteTwilioBrand;
