import { useSelector } from "react-redux";

import {
    getActiveStore,
    getUser,
    getUserFeeSettingsPermission,
} from "src/redux/selectors";
import { ScreenLayout } from "#reusable/layout";
import { Divider } from "#reusable/divider";
import { useAppSelector } from "src/redux/hooks";
import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { CommissionForm } from "#settings/settings-fees/forms/commission";
import { ChargeForm } from "#settings/settings-fees/forms/charge";
import { ConvenienceFeeForm } from "#settings/settings-fees/forms/convenience";
import { OperatingFeeForm } from "#settings/settings-fees/forms/operating";
import { MiscellaneousForm } from "#settings/settings-fees/forms/miscellaneous";
import { MinimumChargeAmountForm } from "#settings/settings-fees/forms/minimum";
import { CustomFeeLabelsForm } from "#settings/settings-fees/forms/labels/CustomFeeLablesForm";

export function FeeSettings() {
    const store = useSelector(getActiveStore);
    const user = useAppSelector(getUser);

    const writeAccess = useSelector(getUserFeeSettingsPermission);

    const content =
        user && store ? (
            <div className="my-4">
                {!writeAccess && (
                    <Alert variant="warning" className="mb-4">
                        <AlertDescription>
                            You currently only have read access to this page and
                            cannot update any fee settings
                        </AlertDescription>
                    </Alert>
                )}
                {/*
                 * TODO: Once ENG-7215 is done this will no longer be needed.
                 *       We should remove this whole alert block
                 */}
                {writeAccess && (
                    <Alert variant="warning" className="mb-4">
                        <AlertDescription>
                            <b>Manual Restart Required</b>
                            <br />
                            Before these changes will go into effect a manual
                            restart of every device that processes payments will
                            be required. Please remember to coordinate with the
                            store to restart every device that processes
                            payments.
                        </AlertDescription>
                    </Alert>
                )}
                <CommissionForm store={store} />
                <Divider />
                <ChargeForm store={store} />
                <Divider />
                <ConvenienceFeeForm store={store} />
                <Divider />
                <OperatingFeeForm store={store} />
                <Divider />
                <MiscellaneousForm store={store} />
                <Divider />
                <MinimumChargeAmountForm store={store} />
                <Divider />
                <CustomFeeLabelsForm store={store} />
            </div>
        ) : (
            <div className="mt-4">No store selected.</div>
        );

    return (
        <ScreenLayout
            header={
                <div className="pb-2 text-xlarge font-semibold">
                    Money Fee Settings
                </div>
            }
            isLoading={false}
            description={
                <div className="text-body text-[#585B5F] md:pr-[20%]">
                    Fee settings applied to purchases made at the selected store
                </div>
            }
            content={content}
        />
    );
}
