import { formatCurrencyFromCents } from "#billing/domain";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";

type Props = {
    estimatedCost: number;
};
function EstimatedCostTooltip({ estimatedCost }: Props) {
    return (
        <ReportsTooltip
            body={`Campaign is expected to cost ${formatCurrencyFromCents(estimatedCost * 100)} but could decrease if members of your Target Audience are ineligible to receive this campaign due to a carrier restriction`}
        />
    );
}

export default EstimatedCostTooltip;
