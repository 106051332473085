import { atom, selector } from "recoil";

export const LOADING_STATUS_LOADING = "LOADING";
export const LOADING_STATUS_ERROR = "ERROR";

export class LoadingStatus {
    status: typeof LOADING_STATUS_LOADING | typeof LOADING_STATUS_ERROR;
    message?: string;

    constructor(
        status: typeof LOADING_STATUS_LOADING | typeof LOADING_STATUS_ERROR,
        message?: string,
    ) {
        this.status = status;
        this.message = message;
    }
}

export enum BrandRegistrationStep {
    GetStarted,
    SMSForm,
    SMSReview,
}

export enum TwilioBrandRegistrationStatus {
    PENDING_SUBMISSION = "PENDING_SUBMISSION",
    PENDING_BRAND_REVIEW = "PENDING_BRAND_REVIEW",
    PENDING_CAMPAIGN_REVIEW = "PENDING_CAMPAIGN_REVIEW",
    BRAND_REVIEW_FAILED = "BRAND_REVIEW_FAILED",
    CAMPAIGN_REVIEW_FAILED = "CAMPAIGN_REVIEW_FAILED",
    APPROVED = "APPROVED",
}

export const brandRegistrationStepAtom = atom({
    key: "campaignBrandRegistration/step",
    default: BrandRegistrationStep.GetStarted,
});

export const brandRegistrationStatusAtom = atom<
    TwilioBrandRegistrationStatus | null | undefined | LoadingStatus
>({
    key: "campaignBrandRegistration/status",
    default: new LoadingStatus(LOADING_STATUS_LOADING),
});

export enum BrandRegistrationPage {
    GetStarted,
    ShareSMSBrandForm,
    SMSForm,
    PendingTwilioReview,
}

export type SharableBrandRegistration = {
    status: TwilioBrandRegistrationStatus | null;
    displayName: string | null;
    phoneNumber: string | null;
    storeName: string | null;
    storeId: string;
};

export const sharableBrandRegistrationsAtom = atom<SharableBrandRegistration[]>(
    {
        key: "campaignBrandRegistration/shareableBrands",
        default: [],
    },
);

// False if chose to not use chains, null if haven't decided yet
export const isUsingChainsAtom = atom<null | boolean>({
    key: "campaignBrandRegistration/isUsingChain",
    default: null,
});

export const getCouldUseChains = selector({
    key: "campaignBrandRegistration/couldUseChains",
    get: ({ get }) => {
        const shareableBrands = get(sharableBrandRegistrationsAtom);
        return shareableBrands.length > 0;
    },
});

export const smsBrandRegistrationPageSelector = selector({
    key: "campaignBrandRegistration/page",
    get: ({ get }) => {
        const step = get(brandRegistrationStepAtom);
        if (step === BrandRegistrationStep.GetStarted) {
            return BrandRegistrationPage.GetStarted;
        }
        const status = get(brandRegistrationStatusAtom);

        const couldUseChains = get(getCouldUseChains);
        const isUsingChain = get(isUsingChainsAtom);

        switch (status) {
            case undefined:
            case null:
            case TwilioBrandRegistrationStatus.PENDING_SUBMISSION:
                if (couldUseChains && isUsingChain !== false) {
                    return BrandRegistrationPage.ShareSMSBrandForm;
                }
                return BrandRegistrationPage.SMSForm;
            default:
                // PENDING_BRAND_REVIEW, PENDING_CAMPAIGN_REVIEW, BRAND_REVIEW_FAILED, CAMPAIGN_REVIEW_FAILED
                // Notably APPROVED should not show this page.
                return BrandRegistrationPage.PendingTwilioReview;
        }
    },
});
