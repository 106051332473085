import React from "react";

import TimeCardTableWithOptionalSummary from "#employees/employee-time-cards/table-with-optional-summary";

const TimeCard = () => (
    <div className="p-6 md:px-12">
        <div className="text-large font-semibold">Time Cards</div>
        <TimeCardTableWithOptionalSummary />
    </div>
);

export default TimeCard;
