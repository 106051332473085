import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

import { Note } from "#reusable/text/note";

type Props = {
    title: string;
    level?: 1 | 2;
    descriptor?: string;
    note?: string;
};

/** Takes a title and level. Higher level number will be smaller font.
 * @param title string
 * @param level 1 or 2. Default is 1 if not defined
 */
export const FormSectionTitle = ({
    title,
    level = 1,
    descriptor,
    note,
}: Props) => (
    <Container>
        <Title level={level}>{title}</Title>
        {descriptor ? <Description>{descriptor}</Description> : null}
        {note ? (
            <Note noteType="default" noteStyle="subtle" note={note} />
        ) : null}
    </Container>
);

const Container = styled.div`
    flex: 1;
    margin: 24px 0;
    @media ${ScreenState.MOBILE} {
        margin-top: 0;
    }
`;

const Title = styled.h3<{ level: number }>`
    margin: 0;
    font-weight: 600;
    ${(props) =>
        props.level === 1
            ? `font-size: 24px;
        line-height: 32px;`
            : `font-size: 20px;
        line-height: 28px;`}
    @media ${ScreenState.MOBILE} {
        font-size: 20px;
    }
`;

const Description = styled.p`
    margin: 0;
    color: ${SystemColors.v2.salt60.light};
`;
