import {
    IAddress,
    IHoursSchema,
    IStore,
    ITimeRangeSchema,
    StoreKind,
} from "@snackpass/snackpass-types";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { shake } from "radash";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { Footer } from "#onboarding/components/shared/Footer";
import { Page } from "#onboarding/components/shared/Page";
import { RowDivider } from "#onboarding/components/shared/RowDivider";
import { FormAddress } from "#onboarding/components/shared/controls/FormAddress";
import { FormFile } from "#onboarding/components/shared/controls/FormFile";
import { FormHours } from "#onboarding/components/shared/controls/FormHours";
import { FormInput } from "#onboarding/components/shared/controls/FormInput";
import { FormSelect } from "#onboarding/components/shared/controls/FormSelect";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { handleSetStoreHours } from "#onboarding/utils/handleStoreHours";
import { notifyFailure } from "#onboarding/utils/notify";
import { kindOptions } from "#onboarding/utils/kind";
import { timezoneOptions } from "#onboarding/utils/timezones";
import { BusinessStep, OnboardingStep } from "#onboarding/utils/types";
import {
    addressValidation,
    cityValidation,
    companyLogoValidation,
    companyNameValidation,
    companySlugValidation,
    countryValidation,
    emailValidation,
    hoursValidation,
    line1Validation,
    line2Validation,
    phoneValidation,
    stateValidation,
    kindValidation,
    taxRateValidation,
    timezoneValidation,
    zipValidation,
} from "#onboarding/utils/validation/form";
import {
    CompanySlugSchema,
    LocationSchema,
} from "#onboarding/utils/validation/schema";
import { SpecialHoursItemType } from "#reusable/special-hours/helper";
import { ReactComponent as Good } from "#payouts/assets/checkmark.svg";
import { ReactComponent as Bad } from "#payouts/assets/x.svg";
import { getActiveStore, getUser } from "src/redux/selectors";

const hideDefault = (s?: string | null) =>
    /Default/i.test(s ?? "not-set") ? undefined : s;

export const Location = () => {
    const [form] = useForm();

    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);

    const { loading, patchStore, handleStepChange } =
        useContext(OnboardingContext);

    const suggestedSlug = store?.name.replace(/[^a-z0-9]/gi, "").toLowerCase();
    const initialSlug = store?.slug ?? suggestedSlug;

    const storeEmail = store?.email?.split(",")[0];
    const initialEmail = storeEmail ?? user?.email;
    const aboutUs = store?.aboutUs ?? undefined;

    const [hasChange, setHasChange] = useState(false);
    const [name, setName] = useState(store?.name);
    const [phone, setPhone] = useState(store?.phoneNumber?.replace("+1", ""));
    const [email, setEmail] = useState(initialEmail);
    const [slug, setSlug] = useState(initialSlug);
    const [slugAvailable, setSlugAvailable] = useState(false);
    const [checkingSlug, setCheckingSlug] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState<string | undefined>(
        undefined,
    );
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [hours, setHours] = useState<ITimeRangeSchema[]>(
        store?.hours.local ?? [],
    );

    const [logoUrl, setLogo] = useState(store?.logoUrl ?? undefined);
    const [digitalReceiptUrl, setDigitalReceiptUrl] = useState(
        store?.loyaltyCardBackgroundImage ?? undefined,
    );
    const [tipImageUrl, setTipImageUrl] = useState(
        store?.tipImageUrl ?? undefined,
    );
    const [thumbnailUrl, setThumbnailUrl] = useState(
        store?.thumbnailUrl ?? undefined,
    );

    const [localHours, setLocalHours] = useState<SpecialHoursItemType[]>(
        handleSetStoreHours(store?.hours as IHoursSchema),
    );
    const [address, setAddress] = useState<
        Partial<BusinessStep["addressComponents"]>
    >({
        line1: hideDefault(store?.addressComponents.line1),
        line2: hideDefault(store?.addressComponents.line2),
        city: hideDefault(store?.addressComponents.city),
        state: hideDefault(store?.addressComponents.state),
        zip: hideDefault(store?.addressComponents.zip),
        country: hideDefault(store?.addressComponents.country) || "USA",
    });
    const [geolocation, setGeolocation] = useState(store?.geolocation);

    const [timezone, setTimezone] = useState(store?.hours.zone);
    const [taxRate, setTaxRate] = useState<string | undefined>("");
    const [attractScreenUrl, setAttractScreenUrl] = useState(
        store?.kioskPreferences.attractScreenUrl ?? undefined,
    );
    const [hasOverlayText, setHasOverlayText] = useState(
        store?.hasBrandSplashOverlayText ?? false,
    );

    const [kind, setKind] = useState(store?.kind);

    useEffect(() => {
        // Debounce delay in milliseconds
        const debounceDelay = 750;

        // Implement a debounce function
        const debounce = setTimeout(() => {
            setDebouncedValue(slug);
        }, debounceDelay);

        // Clear the timeout if the input value changes before the delay expires
        return () => clearTimeout(debounce);
    }, [slug]);

    useEffect(() => {
        const valid = CompanySlugSchema.safeParse(debouncedValue).success;
        if (!checkingSlug && debouncedValue && valid) {
            // Send a server request when the user stops typing
            // Replace this with your actual server request logic
            void checkSlug(debouncedValue);
        }
    }, [debouncedValue]);

    const handleName = (value: string) => {
        setHasChange(true);
        setName(value);
        const errors = companyNameValidation(value);
        form.setFields([
            {
                name: "storeName",
                value,
                errors,
            },
        ]);
    };

    const handleEmail = (value: string) => {
        setHasChange(true);
        setEmail(value);
        const errors = emailValidation(value);
        form.setFields([
            {
                name: "email",
                value,
                errors,
            },
        ]);
    };

    const handlePhone = (value: string) => {
        setHasChange(true);
        setPhone(value);
        const errors = phoneValidation(value);
        form.setFields([
            {
                name: "phone",
                value,
                errors,
            },
        ]);
    };

    const handleTaxRate = (value: string) => {
        setHasChange(true);
        setTaxRate(value);
        const errors = taxRateValidation(value);
        form.setFields([
            {
                name: "taxRate",
                value,
                errors,
            },
        ]);
    };

    const handleSlug = (value: string) => {
        const lowerValue = value.toLowerCase().trim();
        setHasChange(true);
        setSlugAvailable(false);
        setSlug(lowerValue);
        const errors = companySlugValidation(lowerValue, true);
        form.setFields([
            {
                name: "slug",
                value: lowerValue,
                errors,
            },
        ]);
    };

    const handleLogoImage = (value: string) => {
        setHasChange(true);
        setLogo(value);
        const errors = companyLogoValidation(value);
        form.setFields([
            {
                name: "logoUrl",
                value,
                errors,
            },
        ]);
    };

    const handleTipImage = (value: string) => {
        setHasChange(true);
        setTipImageUrl(value);
        form.setFields([
            {
                name: "tipImageUrl",
                value,
            },
        ]);
    };

    const handleDigitalReceiptImage = (value: string) => {
        setHasChange(true);
        setDigitalReceiptUrl(value);
        form.setFields([
            {
                name: "digitalReceiptUrl",
                value,
            },
        ]);
    };

    const handleThumbnailImage = (value: string) => {
        setHasChange(true);
        setThumbnailUrl(value);
        form.setFields([
            {
                name: "thumbnailUrl",
                value,
            },
        ]);
    };

    const handleHours = (value: ITimeRangeSchema[]) => {
        setHasChange(true);
        setHours([...value]);
    };

    const handleAddress = (
        update: Partial<
            IAddress & {
                geolocation: IStore["geolocation"];
                isAddressValid: boolean;
            }
        >,
    ) => {
        setHasChange(true);
        const newAddress = {
            ...address,
            ...update,
            zip: update.zip?.slice(0, 5),
        };
        form.setFields([
            {
                name: "line1",
                value: newAddress.line1,
                errors: line1Validation(
                    update.isAddressValid,
                    newAddress.line1 ?? undefined,
                ),
            },
            {
                name: "line2",
                value: newAddress.line2,
                errors: line2Validation(newAddress.line2),
            },
            {
                name: "city",
                value: newAddress.city,
                errors: cityValidation(newAddress.city ?? undefined),
            },
            {
                name: "state",
                value: newAddress.state,
                errors: stateValidation(newAddress.state ?? undefined),
            },
            {
                name: "country",
                value: newAddress.country,
                errors: countryValidation(newAddress.country ?? undefined),
            },
            {
                name: "postalCode",
                value: newAddress.zip,
                errors: zipValidation(newAddress.zip ?? undefined),
            },
        ]);
        setAddress(newAddress);
        setGeolocation(update.geolocation);
        setIsAddressValid(update.isAddressValid || false);
    };

    const handleTimezone = (value: string) => {
        setHasChange(true);
        setTimezone(value);
        const errors = timezoneValidation(value);
        form.setFields([
            {
                name: "timezone",
                value,
                errors,
            },
        ]);
    };

    const handleKind = (value: string) => {
        setHasChange(true);
        setKind(value as StoreKind);
        const errors = kindValidation(value);
        form.setFields([
            {
                name: "kind",
                value,
                errors,
            },
        ]);
    };

    const handleAttractScreenUrl = (value: string) => {
        setHasChange(true);
        setAttractScreenUrl(value);
        form.setFields([
            {
                name: "attractScreenUrl",
                value,
            },
        ]);
    };

    const handleOverlayText = (value: boolean) => {
        setHasChange(true);
        setHasOverlayText(value);
        form.setFields([
            {
                name: "hasOverlayText",
                value,
            },
        ]);
    };

    const saveDisabled = !store?._id || loading || !hasChange;

    const disabled = !store?._id || loading;
    const isValid =
        !loading &&
        LocationSchema.safeParse({
            name,
            phone,
            email,
            slug,
            aboutUs,
            logoUrl,
            tipImageUrl,
            attractScreenUrl,
            digitalReceiptUrl,
            thumbnailUrl,
            slugAvailable,
            address,
            timezone,
            taxRate: taxRate
                ? parseFloat(taxRate?.replace("%", ""))
                : undefined,
            hours,
        }).success &&
        isAddressValid;

    const checkSlug = async (s: string) => {
        if (!store?._id) return;
        setCheckingSlug(true);
        void api.stores
            .checkSlug(store._id, s)
            .then(({ data }) => {
                setCheckingSlug(false);
                const available = data.success && data.available;
                setSlugAvailable(available);
                form.setFields([
                    {
                        name: "slug",
                        errors: companySlugValidation(s, available),
                    },
                ]);
            })
            .catch((error) => {
                setCheckingSlug(false);
                setSlugAvailable(false);
                notifyFailure({
                    message: "Unable to check Username",
                    description: error.message,
                });
            });
    };

    const handleSave = async () => {
        if (!store?._id) return;

        let update: Partial<IStore> = {
            logoUrl,
            loyaltyCardBackgroundImage: digitalReceiptUrl ?? "",
            tipImageUrl: tipImageUrl ?? "",
            thumbnailUrl: thumbnailUrl ?? "",
            hasBrandSplashOverlayText: hasOverlayText,
            ...(attractScreenUrl
                ? {
                      kioskPreferences: {
                          ...store?.kioskPreferences,
                          attractScreenUrl,
                      },
                  }
                : {}),
        };
        if (!companyNameValidation(name).length) {
            update = { ...update, name };
        }
        if (!phoneValidation(phone).length) {
            update = { ...update, phoneNumber: phone };
        }
        if (!emailValidation(email).length) {
            update = { ...update, email };
        }
        const unique = !checkingSlug && slugAvailable;
        if (!companySlugValidation(slug, unique).length) {
            update = { ...update, slug };
        }
        if (!addressValidation(address).length && isAddressValid) {
            update = {
                ...update,
                // @ts-expect-error `address` is already validated
                addressComponents: { ...address },
                address: address.full ?? undefined,
                geolocation,
            };
        }
        if (taxRate && !taxRateValidation(taxRate).length) {
            update = {
                ...update,
                taxRate: parseFloat(taxRate.replace("%", "")),
            };
        }
        if (!hoursValidation(hours).length) {
            update = {
                ...update,
                hours: {
                    ...store.hours,
                    local: hours,
                },
            };
        }
        if (timezone && !timezoneValidation(timezone).length) {
            update = {
                ...update,
                hours: {
                    ...store.hours,
                    ...update.hours,
                    zone: timezone,
                },
            };
        }

        if (kind && !kindValidation(kind).length) {
            update = {
                ...update,
                kind,
            };
        }

        return patchStore(store._id, shake(update), () => {
            setHasChange(false);
        });
    };

    const handleContinue = async () => {
        if (disabled) return;
        if (!isValid) {
            const unique = !checkingSlug && slugAvailable;
            form.setFields([
                { name: "storeName", errors: companyNameValidation(name) },
                { name: "phone", errors: phoneValidation(phone) },
                { name: "email", errors: emailValidation(email) },
                { name: "slug", errors: companySlugValidation(slug, unique) },
                { name: "logoUrl", errors: companyLogoValidation(logoUrl) },
                {
                    name: "line1",
                    errors: line1Validation(
                        isAddressValid,
                        address.line1 ?? undefined,
                    ),
                },
                {
                    name: "line2",
                    errors: line2Validation(address.line2),
                },
                {
                    name: "city",
                    errors: cityValidation(address.city ?? undefined),
                },
                {
                    name: "state",
                    errors: stateValidation(address.state ?? undefined),
                },
                {
                    name: "country",
                    errors: countryValidation(address.country ?? undefined),
                },
                {
                    name: "postalCode",
                    errors: zipValidation(address.zip ?? undefined),
                },
                { name: "taxRate", errors: taxRateValidation(taxRate) },
                { name: "hours", errors: hoursValidation(hours) },
                { name: "timezone", errors: timezoneValidation(timezone) },
                { name: "kind", errors: kindValidation(kind) },
            ]);
            return;
        }

        if (!hasChange) {
            handleStepChange(OnboardingStep.Business);
            setHasChange(false);
            return;
        }

        const body = {
            name,
            phoneNumber: phone,
            email,
            slug,
            aboutUs,
            logoUrl,
            // Note: Must use the field on the store doc
            loyaltyCardBackgroundImage: digitalReceiptUrl ?? "",
            tipImageUrl: tipImageUrl ?? "",
            hasBrandSplashOverlayText: hasOverlayText,
            ...(attractScreenUrl
                ? {
                      kioskPreferences: {
                          ...store?.kioskPreferences,
                          attractScreenUrl,
                      },
                  }
                : {}),
            thumbnailUrl: thumbnailUrl ?? "",
            address: address.full ?? undefined,
            addressComponents: {
                ...store.addressComponents,
                ...address,
            },
            geolocation,
            hours: {
                ...store.hours,
                local: hours,
                ...(timezone ? { zone: timezone } : {}),
            },
            ...(taxRate
                ? { taxRate: parseFloat(taxRate.replace("%", "")) }
                : {}),
            kind,
        };

        return patchStore(store._id, body, () => {
            handleStepChange(OnboardingStep.Business);
            setHasChange(false);
        });
    };

    const slugSuffix = slugAvailable ? <Good /> : <Bad />;

    return (
        <Page
            footer={
                <Footer
                    isValid={isValid}
                    disabled={disabled}
                    saveDisabled={saveDisabled}
                    onSave={handleSave}
                    onContinue={handleContinue}
                    hasChange={hasChange}
                />
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    storeName: name,
                    slug,
                    email,
                    phone,
                    aboutUs,
                    hours,
                    timezone,
                    logoUrl,
                    thumbnailUrl,
                    digitalReceiptUrl,
                    tipImageUrl,
                    hasOverlayText,
                    attractScreenUrl,
                    country: address.country,
                    line1: address.line1,
                    line2: address.line2,
                    city: address.city,
                    state: address.state,
                    postalCode: address.zip,
                    isAddressValid,
                    taxRate,
                    kind,
                }}
            >
                <FormInput
                    required
                    field="storeName"
                    label="Name"
                    subtitle="How you want your store to appear to your customers."
                    placeholder="Store name"
                    onChange={handleName}
                />
                <RowDivider />
                <FormInput
                    required
                    className="right"
                    disabled={checkingSlug}
                    field="slug"
                    label="Username"
                    subtitle="This will be your online ordering URL. Username may only contain lowercase, alpha numeric characters (a-z, 0-9)."
                    placeholder={"snackpass"}
                    onChange={handleSlug}
                    pretext="order.snackpass.co/"
                    suffix={slugSuffix}
                />
                <RowDivider />
                <FormInput
                    required
                    value={email}
                    field="email"
                    label="Email"
                    subtitle="Used for important information about payouts and other time sensitive communications."
                    placeholder="name@example.com"
                    onChange={handleEmail}
                />
                <RowDivider />
                <FormInput
                    required
                    value={phone}
                    field="phone"
                    subtitle="Appears on your online ordering and app profile."
                    label="Store Phone Number"
                    placeholder="202-358-0000"
                    onChange={handlePhone}
                    pretext="+1"
                />
                <RowDivider />
                <FormAddress
                    required
                    value={address}
                    label="Address"
                    subtitle="Customers will see this address on your ordering and app page."
                    onChange={handleAddress}
                />
                <RowDivider />
                <FormSelect
                    required
                    className="full"
                    field="timezone"
                    value={timezone}
                    label="Location Timezone"
                    subtitle="Please select the timezone of your location."
                    placeholder="Select your timezone"
                    options={timezoneOptions}
                    onChange={handleTimezone}
                />
                <RowDivider />
                <FormSelect
                    required
                    className="full"
                    field="kind"
                    value={kind}
                    label="Store type"
                    subtitle="Please select the type of your store."
                    placeholder="Select your store type"
                    options={kindOptions}
                    onChange={handleKind}
                />
                <RowDivider />
                <FormInput
                    required
                    value={taxRate}
                    field="taxRate"
                    subtitle="Set the sales tax rate for this location."
                    label="Tax Rate"
                    placeholder="9.5%"
                    onChange={handleTaxRate}
                    postText="%"
                />
                <RowDivider />
                <FormHours
                    required
                    omitRequired={false}
                    onChange={handleHours}
                    localHours={localHours}
                    setLocalHours={setLocalHours}
                    field="hours"
                    label="Hours"
                    subtitle="Open and close hours by day. 'Everyday' can also be selected."
                />
                <RowDivider />
                <FormFile
                    value={logoUrl}
                    required
                    field="logoUrl"
                    label="Logo"
                    subtitle="JPG or PNG. 400x400px recommended."
                    onChange={handleLogoImage}
                    isRounded
                />
                <RowDivider />
                <FormFile
                    value={thumbnailUrl}
                    field="thumbnailUrl"
                    label="Cover Photo"
                    subtitle="Shown on Snack Site and your menu. JPG or PNG. 1920x1080px recommended."
                    onChange={handleThumbnailImage}
                    croppingAspectRatio={104 / 25}
                    height="100px"
                />
                <RowDivider />
                <FormFile
                    value={digitalReceiptUrl}
                    field="digitalReceiptUrl"
                    label="Digital Receipt Image"
                    subtitle="Add a custom background for your virtual loyalty card that appears on receipts and your custom branded app."
                    onChange={handleDigitalReceiptImage}
                    height="200px"
                />
                <RowDivider />
                <FormFile
                    value={tipImageUrl}
                    field="tipImageUrl"
                    label="Tip Image"
                    subtitle="Upload an image to be shown to customers on the kiosk and register tipping screens. A picture of store staff works well to add a personal touch to every transaction and encourage tipping."
                    onChange={handleTipImage}
                    isRounded
                />
                <RowDivider />
                <FormFile
                    value={attractScreenUrl}
                    field="attractScreenUrl"
                    label="Kiosk Splash Screen"
                    subtitle="Play a custom video when Kiosk is idle."
                    onChange={handleAttractScreenUrl}
                    height="200px"
                    isKioskSplashVideo
                    hasOverlayText={hasOverlayText}
                    onOverlayTextChange={handleOverlayText}
                />
            </Form>
        </Page>
    );
};
