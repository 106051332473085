import React from "react";
import { IProduct } from "@snackpass/snackpass-types";

import { Categories } from "./categories";
import { RecentItems } from "./recent-items";
import { useProductRewards } from "./lib";

type OnSelect = (product: IProduct) => void;

type TemplatesComponentProps = {
    onSelect: OnSelect;
};

export const ProductsList = ({ onSelect }: TemplatesComponentProps) => {
    const { categoriesWithProducts, recentProducts } = useProductRewards();

    return (
        <>
            <RecentItems products={recentProducts} onSelect={onSelect} />
            <Categories
                categories={categoriesWithProducts}
                onSelect={onSelect}
            />
        </>
    );
};
