import React, { useContext } from "react";
import { PrinterFormat } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";
import { Col, Row } from "react-bootstrap";

import CalloutStat from "#devices/components/DetailsDrawer/CalloutStat";
import DetailsSection from "#devices/components/DetailsDrawer/DetailsSection";
import DescriptionList from "#devices/components/DetailsDrawer/DescriptionList";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { getFormattedDate } from "#devices/components/DevicesTable/lib";
import { ReactComponent as Tablet } from "src/assets/icons/tablet-small.svg";
import { ReactComponent as Printer } from "src/assets/icons/printer-small.svg";
import { ReactComponent as CalloutPrinter } from "src/assets/icons/printer-green.svg";
import { ReactComponent as PrepStationIcon } from "src/assets/icons/prep-station.svg";
import { ReactComponent as Receipt } from "src/assets/icons/receipt.svg";
import { ReactComponent as Copies } from "src/assets/icons/print-copies.svg";
import { ReactComponent as PrintAuto } from "src/assets/icons/printer-auto-accept.svg";
import { ReactComponent as Barcode } from "src/assets/icons/barcode.svg";
import { ReactComponent as Calendar } from "src/assets/icons/calendar-small.svg";
import { getDeviceStatus } from "#devices/utils/getDeviceStatus";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { PrinterDevice } from "#devices/utils/deviceTypes";
import PrinterHistoryLog from "#devices/components/DetailsDrawer/PrinterHistoryLog";
import { ReactComponent as CashDrawerIcon } from "src/assets/icons/cash-drawer-small.svg";
import {
    PREP_STATION_FORMATS,
    printerFormatNames,
} from "#devices/utils/deviceTypes/PrinterDevice";

const PrinterDetails = () => {
    const { device } = useContext(DevicesPageContext);
    const printerDevice = device as PrinterDevice;
    const deviceStatus = getDeviceStatus(printerDevice);

    const printerFormat =
        printerFormatNames[printerDevice.deviceDetails.format];

    const prepStationFormat = PREP_STATION_FORMATS.includes(
        printerDevice.deviceDetails.format,
    );

    return (
        <>
            <Row>
                <Col>
                    <CalloutStat
                        icon={<CalloutPrinter />}
                        statNumber={printerDevice.deviceDetails.recentPrints
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        statText="Prints in the last Month"
                        highlightBorder
                        iconColor={SystemColors.v1.matcha50}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CalloutStat
                        icon={null}
                        statNumber=""
                        statText="Settings only apply to SDP configured printers, and not printers connected via BT or USB."
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Details">
                        <DescriptionList
                            items={[
                                {
                                    term: "Device",
                                    icon: <Printer />,
                                    description:
                                        getDeviceTypeName(
                                            printerDevice.deviceType,
                                        ) || "",
                                },
                                {
                                    term: "Last Active",
                                    icon: null,
                                    description: deviceStatus.lastActive,
                                },
                                {
                                    term: "Installed",
                                    icon: <Calendar />,
                                    description: getFormattedDate(
                                        printerDevice.createdAt,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Software Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Print Mode",
                                    icon: <PrintAuto />,
                                    description: printerDevice.deviceDetails
                                        .isAutoPrint
                                        ? "Auto"
                                        : "Manual",
                                },
                                {
                                    term: "Print Copies",
                                    icon: <Copies />,
                                    description: `${
                                        printerDevice.deviceDetails
                                            .numberOfPrints ?? 1
                                    }`,
                                },
                                {
                                    term: "Format",
                                    icon: <Receipt />,
                                    description: printerFormat,
                                },
                                {
                                    term: "Display Modifier Groups",
                                    icon: null,
                                    description:
                                        printerDevice.deviceDetails
                                            .displayModifierGroups &&
                                        printerDevice.deviceDetails.format ===
                                            PrinterFormat.LABEL_V2
                                            ? "Yes"
                                            : "No",
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Hardware Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Model",
                                    icon: <Tablet />,
                                    description: `${
                                        printerDevice.hardwareMake
                                    } ${printerDevice.hardwareModel.toUpperCase()}`,
                                    copyable: true,
                                },
                                {
                                    term: "Serial Number",
                                    icon: <Barcode />,
                                    description: printerDevice.serial,
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Additional Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Cash Drawer",
                                    icon: <CashDrawerIcon />,
                                    description:
                                        printerDevice.deviceDetails
                                            .cashDrawerDetails?.name ?? "None",
                                },
                            ].concat(
                                prepStationFormat
                                    ? [
                                          {
                                              term: "Prep Station",
                                              icon: <PrepStationIcon />,
                                              description:
                                                  printerDevice.deviceDetails
                                                      .prepStationDetails
                                                      ?.name ?? "None",
                                          },
                                      ]
                                    : [
                                          {
                                              term: "Prep Station",
                                              icon: <PrepStationIcon />,
                                              description:
                                                  "only available under Kitchen Ticket format",
                                          },
                                      ],
                            )}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DetailsSection heading="Device Log">
                        <PrinterHistoryLog
                            histories={
                                printerDevice.deviceDetails.connectionHistory ??
                                []
                            }
                        />
                    </DetailsSection>
                </Col>
            </Row>
        </>
    );
};

export default PrinterDetails;
