import { useCallback } from "react";
import { toast } from "sonner";
import { useHistory } from "react-router-dom";

import { MenuForm } from "#menu-manager/components";
import { formValuesToInputType } from "#menu-manager/components/menu-form/lib";
import { MenuFormValues } from "#menu-manager/components/menu-form/schema";
import { useCreateMenu } from "#menu-manager/hooks";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStoreId } from "src/redux/selectors";
import { MenuManagerRoutes } from "#menu-manager/routes";

export function NewMenuScreen() {
    const storeId = useAppSelector(getActiveStoreId);
    const { createMenu, loading } = useCreateMenu();
    const history = useHistory();

    const onSubmit = useCallback(
        async (values: MenuFormValues) =>
            createMenu({
                input: formValuesToInputType(values),
                storeId,
            })
                .then((result) => {
                    toast.success(
                        `Successfully created ${result.data?.createStoreMenu.name}`,
                    );
                    history.push(MenuManagerRoutes.INDEX);
                })
                .catch((e) =>
                    toast.error("Error creating menu", {
                        description: e?.message,
                    }),
                ),
        [createMenu, history, storeId],
    );

    return (
        <MenuForm
            onSubmit={onSubmit}
            submitting={loading}
            additionalActionButtons={undefined}
        />
    );
}
