import { Alert } from "antd";
import styled from "styled-components";
import { match } from "ts-pattern";

const wrapText = (t = "") => (t.length >= 68 ? "column" : "row");

export const StyledAlert = styled(Alert)<{ description?: string }>`
    border-radius: ${({ theme }) => theme.spacing.half};
    margin-bottom: ${({ theme }) => theme.spacing.base};
    margin-top: ${({ theme }) => theme.spacing.base};

    .ant-alert-content {
        display: flex;
        flex-direction: ${({ description }) => wrapText(description)};
        flex: 1;
        width: 100%;
    }

    .ant-alert-message,
    .ant-alert-description {
        color: ${({ type }) =>
            match(type)
                .with("info", () => "#0099ff")
                .with("warning", () => "#b85c00")
                .with("error", () => "#b80415") // forgive me bryce, i chose this color
                .otherwise(() => "black")};
        font-family: Inter;
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        margin: auto 0;
    }

    .ant-alert-description {
        margin-left: ${({ theme, description }) =>
            match(wrapText(description))
                .with("column", () => "0")
                .otherwise(() => theme.spacing.half)};
        font-weight: 400;
        font-size: 14px;
    }
`;
