import { useFormContext } from "react-hook-form";

import {
    FormField,
    FormControl,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { CommissionFormValues } from "#settings/settings-fees/utils/types";
import { FeeSwitch } from "#settings/settings-fees/components/fee-switch";
import { FeeInput } from "#settings/settings-fees/components/fee-input";

export const CommissionRow = ({
    label,
    percent,
    fixed,
    override,
}: {
    label: string;
    percent: keyof Pick<
        CommissionFormValues,
        | "appDeliveryPercent"
        | "appDineInPercent"
        | "appPickupPercent"
        | "defaultPercent"
        | "kioskDineInPercent"
        | "kioskPickupPercent"
        | "onlineDeliveryPercent"
        | "onlineDineInPercent"
        | "onlinePickupPercent"
    >;
    fixed: keyof Pick<
        CommissionFormValues,
        | "appDeliveryFixed"
        | "appDineInFixed"
        | "appPickupFixed"
        | "defaultFixed"
        | "kioskDineInFixed"
        | "kioskPickupFixed"
        | "onlineDeliveryFixed"
        | "onlineDineInFixed"
        | "onlinePickupFixed"
    >;
    override: keyof Pick<
        CommissionFormValues,
        | "appDeliveryOverride"
        | "appDineInOverride"
        | "appPickupOverride"
        | "kioskDineInOverride"
        | "kioskPickupOverride"
        | "onlineDeliveryOverride"
        | "onlineDineInOverride"
        | "onlinePickupOverride"
    >;
}) => {
    const { control, getValues, setValue } =
        useFormContext<CommissionFormValues>();
    return (
        <div className="mx-auto flex w-fit flex-col justify-center sm:flex-row">
            <div className="my-auto flex w-[100px]">{label}</div>
            <div className="flex flex-row">
                <div className="flex flex-col gap-2 sm:flex-row">
                    <FormField
                        control={control}
                        name={percent}
                        render={({ field }) => (
                            <FormItem className="mr-4 flex w-[200px] flex-col">
                                <FormControl>
                                    <FeeInput
                                        value={field.value}
                                        suffix="%"
                                        placeholder="0.0"
                                        disabled={!getValues()[override]}
                                        step={0.01}
                                        min={0}
                                        max={20}
                                        onChange={(e) =>
                                            field.onChange(
                                                parseFloat(e.target.value),
                                            )
                                        }
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name={fixed}
                        render={({ field }) => (
                            <FormItem className="mr-4 flex w-[200px] flex-col">
                                <FormControl>
                                    <FeeInput
                                        value={field.value}
                                        prefix="$"
                                        disabled={!getValues()[override]}
                                        placeholder="0.0"
                                        step={0.01}
                                        min={0}
                                        max={1}
                                        onChange={(e) =>
                                            field.onChange(
                                                parseFloat(e.target.value),
                                            )
                                        }
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div>
                    <FormField
                        control={control}
                        name={override}
                        render={({ field }) => (
                            <FormItem className="flex flex-row">
                                <FormLabel className="my-auto mr-2">
                                    Override
                                </FormLabel>
                                <FormControl>
                                    <FeeSwitch
                                        checked={field.value}
                                        onChange={(checked) => {
                                            if (!checked) {
                                                setValue(
                                                    percent,
                                                    getValues().defaultPercent,
                                                );
                                                setValue(
                                                    fixed,
                                                    getValues().defaultFixed,
                                                );
                                            }
                                            return field.onChange(checked);
                                        }}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
