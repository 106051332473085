import { SelectItem } from "src/@/components/ui/select";

type Props = {
    value: string;
    name: string;
    description: string;
};

const AdminRoleSelectItem = ({ value, name, description }: Props) => (
    <SelectItem value={value}>
        <div className=" flex cursor-pointer flex-col items-start rounded hover:bg-neutral-300">
            <div className="text-small font-normal">{name}</div>
            <div className="text-micro font-normal">{description}</div>
        </div>
    </SelectItem>
);

export default AdminRoleSelectItem;
