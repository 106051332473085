import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SystemColors } from "@snackpass/design-system";
import { IProduct } from "@snackpass/snackpass-types";
import { Tooltip } from "antd";
import fileDialog from "file-dialog";
// @ts-expect-error No types for DropToUpload
import DropToUpload from "react-drop-to-upload";
import { toast } from "sonner";

import logo_round from "src/assets/images/logo-round.png";
import { getActiveStoreLogoUrl, getActiveStore } from "src/redux/selectors";
import { ReactComponent as SparkleIcon } from "src/assets/icons/sparkle.svg";
import { ReactComponent as PromoIcon } from "src/assets/icons/promo-guestbook.svg";
import { ReactComponent as UploadIcon } from "src/assets/icons/upload.svg";
import { ReactComponent as Message } from "src/assets/icons/message.svg";
import { CampaignType } from "#guestbook/redux";
import {
    compressThenUpload,
    constants as helperConstants,
} from "#menu-editor/mobile-friendly/helpers/image-upload-helper";
import api from "src/api/rest";

import { useCampaignMessageInput } from "#guestbook/redux/campaign";
import { sendError } from "src/utils/errors";
import { setActiveStore, updateLegacyProduct } from "src/redux/slices";

import * as P from "./styles";

interface IPhoneBodyContext {
    campaignType: CampaignType | null | undefined;
    product: IProduct | null | undefined;
    message: string | undefined;
    storeInfo: {
        name: string | undefined;
        icon: string | undefined;
        cover: string | undefined | null;
    };
    title: string | undefined;
    noProductImage?: boolean;
}
export const PhoneBodyContext = React.createContext<IPhoneBodyContext>({
    campaignType: null,
    product: null,
    message: undefined,
    storeInfo: { name: undefined, icon: undefined, cover: undefined },
    title: undefined,
    noProductImage: false,
});

export type IBodyProps = {
    campaignType?: CampaignType | null;
    product?: IProduct | null | undefined;
    message?: string;
    children?: React.ReactNode;
    coverPhotoURL?: string;
    title?: string;
    noProductImage?: boolean;
};

export const Body = ({
    campaignType,
    product,
    message,
    children,
    coverPhotoURL,
    title,
    noProductImage,
}: IBodyProps) => {
    const activeStoreLogoUrl = useSelector(getActiveStoreLogoUrl);
    const activeStore = useSelector(getActiveStore);
    const icon = activeStoreLogoUrl || logo_round;
    const storeCoverPhoto = activeStore?.thumbnailUrl;
    return (
        <PhoneBodyContext.Provider
            value={{
                campaignType,
                product,
                message,
                storeInfo: {
                    name: activeStore?.name,
                    icon: icon,
                    cover: coverPhotoURL || storeCoverPhoto,
                },
                title,
                noProductImage,
            }}
        >
            <P.Body>
                <P.PhoneBodyWrapper>{children}</P.PhoneBodyWrapper>
            </P.Body>
        </PhoneBodyContext.Provider>
    );
};

const Top = () => {
    const { storeInfo } = React.useContext(PhoneBodyContext);
    return (
        <P.PhoneBodyTop>
            <img
                src={storeInfo?.icon ? storeInfo?.icon : ""}
                className="store-icon"
            />
            <div className="store-name">{storeInfo.name}</div>
        </P.PhoneBodyTop>
    );
};

const Cover = () => {
    const dispatch = useDispatch();
    const { product, campaignType, storeInfo, message, noProductImage } =
        React.useContext(PhoneBodyContext);
    const [productImage, setProductImage] = React.useState(
        product?.image ? product?.image : "",
    );
    const [storeImage, setStoreImage] = React.useState(
        storeInfo?.cover ? storeInfo?.cover : "",
    );
    const messageInput = useCampaignMessageInput();
    const messageString = messageInput?.getCurrentContent().getPlainText();

    const upload = (file: Blob) => {
        if (product?.store._id) {
            const formData = new FormData();
            formData.append("photo", file);
            api.uploads
                .uploadMenuPhoto(formData, product?.store.name)
                .then(async (res) => {
                    setProductImage(
                        res.data.location
                            .split(" ")
                            .join("+")
                            .split("'")
                            .join("%27"),
                    );
                    return api.products.update(product?._id, {
                        image: res.data.location
                            .split(" ")
                            .join("+")
                            .split("'")
                            .join("%27"),
                    });
                })
                .then(async (res) => {
                    dispatch(updateLegacyProduct(res.data.product));
                    return api.stores.getOne(product.store._id);
                })
                .then((res) => {
                    dispatch(setActiveStore(res.data.store));
                })
                .catch((err) => {
                    sendError(err);
                    toast.error("could not upload photo");
                });
        }
    };
    const handleImageUpload = async (file: FileList) => {
        // @ts-ignore
        const image = file[0];
        const isImage = image.type.split("/")[0] === "image";
        if (!isImage) {
            toast.error("That file is not a recognized image type");
            return;
        }

        if (image.size > helperConstants.MAX_FILE_SIZE) {
            compressThenUpload(upload, image);
        } else {
            // prevents needless compression
            upload(image);
        }
    };
    const openFileDialog = () => {
        fileDialog({ multiple: false, accept: "image/*" }).then(
            handleImageUpload,
        );
    };

    return (
        <P.PhoneBodyCover>
            {campaignType === CampaignType.Announcement ? (
                storeImage ? (
                    <img src={storeImage} />
                ) : (
                    <P.BackgroundColor />
                )
            ) : campaignType === CampaignType.Discount ? (
                storeImage ? (
                    <img src={storeImage} />
                ) : (
                    <P.BackgroundColor />
                )
            ) : product ? (
                productImage ? (
                    <Tooltip
                        trigger="hover"
                        title="Click to replace menu item photo"
                    >
                        <P.ImageUpload
                            src={productImage}
                            onClick={() => {
                                openFileDialog();
                            }}
                        />
                    </Tooltip>
                ) : (
                    <P.NoImageWrapper
                        onClick={() => {
                            openFileDialog();
                        }}
                    >
                        <DropToUpload onDrop={handleImageUpload}>
                            <UploadIcon />
                            <div>
                                Upload an image for menu item. Otherwise, store
                                cover image would be used.
                            </div>
                        </DropToUpload>
                    </P.NoImageWrapper>
                )
            ) : noProductImage ? (
                <P.BackgroundColor>
                    Product image displaying on Snackpass App.
                    <br />
                    Preview Unavailable{" "}
                </P.BackgroundColor>
            ) : (
                <P.BackgroundColor />
            )}
        </P.PhoneBodyCover>
    );
};

const Bottom = () => {
    const { campaignType, product, title, storeInfo } =
        React.useContext(PhoneBodyContext);

    return campaignType === CampaignType.Discount ? (
        <P.PhoneBodyBottom>
            <P.IconWrapper>
                <PromoIcon fill={SystemColors.v1.black} />
            </P.IconWrapper>
            <span className="campaignType">
                {title || `Campaign from ${storeInfo.name}`}
            </span>
            <span className="campaignbtn">Claim</span>
        </P.PhoneBodyBottom>
    ) : campaignType === CampaignType.Product ? (
        <P.PhoneBodyBottom>
            <P.IconWrapper>
                <SparkleIcon fill={SystemColors.v1.black} />
            </P.IconWrapper>

            <span className="campaignType">
                {product ? product.name : "Order"}
            </span>
            <span className="campaignbtn">Order</span>
        </P.PhoneBodyBottom>
    ) : (
        <P.PhoneBodyBottom>
            <P.IconWrapper>
                <Message fill={SystemColors.v1.black} />
            </P.IconWrapper>

            <span className="campaignType">
                {`Campaign from ${storeInfo.name}`}
            </span>
            <span className="campaignbtn">Order</span>
        </P.PhoneBodyBottom>
    );
};

Body.Top = Top;
Body.Cover = Cover;
Body.Bottom = Bottom;
