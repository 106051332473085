import { useFormContext } from "react-hook-form";

import { CardDescription } from "src/@/components/ui/card";
import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { useSharedSelectedBrand } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/useSharedSelectedBrand";

function SharedBrandRegistrationAreaCodeInput() {
    const { control } = useFormContext();
    const sharedBrand = useSharedSelectedBrand();
    return (
        <FormField
            control={control}
            name="desiredAreaCode"
            render={({ field }) => (
                <FormItem>
                    <div className="flex flex-col gap-y-2 pt-6">
                        <FormLabel>
                            <p className="font-semibold">
                                Desired Area Code (Optional)
                            </p>
                        </FormLabel>
                        <CardDescription>
                            The number you use to send messages will start with
                            these three numbers. Subject to area code
                            availability. If you do not specify an area code,
                            the phone number{" "}
                            {sharedBrand?.phoneNumber ?? "Unknown"} will be
                            used.
                        </CardDescription>
                        <FormControl>
                            <Input {...field} />
                        </FormControl>
                        <FormDescription className="w-full text-gray-500">
                            If there are no more phone numbers that exist with
                            this area code, we will attempt to choose a phone
                            number whose area code is close to your business
                            address.
                        </FormDescription>
                        <FormMessage />
                    </div>
                </FormItem>
            )}
        />
    );
}

export default SharedBrandRegistrationAreaCodeInput;
