import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { RequestRemovalDropdown } from "#settings-integrations/components/shared/request-removal-dropdown";
import { ChannelStatusIndicator } from "#settings-integrations/components/shared/reusables";
import { ChannelLink } from "#settings-integrations/modules/deliverect";
import { DataTable } from "src/@/components/ui/data-table";
import { IntegrationHeader } from "#settings-integrations/components/IntegrationHeader";

interface LiveIntegrationsProps {
    channels: ChannelLink[];
    storeId: string;
    storeName: string;
    showCreatingIntegrationView?: boolean;
    setShowCreatingIntegrationView?: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export const LiveIntegrations = ({
    channels,
    storeId,
    storeName,
    showCreatingIntegrationView,
    setShowCreatingIntegrationView,
}: LiveIntegrationsProps) => {
    const columns: ColumnDef<ChannelLink>[] = useMemo(
        () => [
            {
                id: "Channel",
                accessorKey: "name",
                header: () => "Channel",
            },
            {
                id: "Status",
                accessorKey: "status",
                header: () => "Status",
                cell: (info) =>
                    ChannelStatusIndicator({
                        status: info.row.original.status,
                    }),
            },
            {
                accessorKey: "",
                id: "edit options",
                cell: (info) => (
                    <div className="flex justify-end">
                        <RequestRemovalDropdown
                            channel={info.row.original}
                            storeName={storeName}
                            storeId={storeId}
                        />
                    </div>
                ),
            },
        ],
        [storeId, storeName],
    );

    return (
        <div className="h-full w-full">
            <IntegrationHeader
                storeId={storeId}
                showCreatingIntegrationView={showCreatingIntegrationView}
                setShowCreatingIntegrationView={setShowCreatingIntegrationView}
            />
            <DataTable columns={columns} data={channels || []} />
        </div>
    );
};
