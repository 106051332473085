import { useContext } from "react";

import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";

export const useCompany = () => {
    const { verification } = useContext(PayoutsSettingsContext);

    const started =
        !!verification?.name ||
        !!verification?.taxId ||
        !!verification?.address?.line1 ||
        !!verification?.phone ||
        !!verification?.merchantCategoryCode;

    const done =
        started &&
        !!verification?.name &&
        !!verification?.taxId &&
        !!verification?.address?.line1 &&
        !!verification?.phone &&
        !!verification?.merchantCategoryCode;

    return { started, done };
};
