import { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { GiftCardPurchaseStatReportRow } from "#reports/gift-card-purchases/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";

export const formatCentsAsDollars = (
    cents: number | null | undefined,
): string => {
    if (!cents || cents == null) return "$0.00";

    // Convert cents to dollars
    const dollars = cents / 100;

    // Format the dollar amount with commas as thousand separators
    const formattedDollars = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(dollars);

    return formattedDollars;
};

export const columns: ColumnDef<GiftCardPurchaseStatReportRow>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title="Activity"
            />
        ),
        id: "activity",
        accessorKey: "activity",
        cell: (info) =>
            info.row.original.isHeader ? (
                <div className="my-4 ml-2 flex items-center">
                    <div className="font-bold">{info.getValue() as string}</div>
                    {info.row.original.tooltip ? (
                        <ReportsTooltip
                            body={info.row.original.tooltip}
                            className="mx-3"
                        />
                    ) : null}
                </div>
            ) : (
                <div className="my-4 ms-4">
                    <div>{info.getValue() as string}</div>
                </div>
            ),
        enableSorting: false,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title="Total"
            />
        ),
        id: "total",
        accessorKey: "total",
        enableSorting: false,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-4"
                column={column}
                title="Amount"
            />
        ),
        id: "amount",
        accessorKey: "amount",
        enableSorting: false,
    },
];
