import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { Spinner } from "react-activity";

const LoadingPage = () => (
    <div className="mt-4 flex w-full justify-center">
        <Spinner color={SystemColors.v1.candy50} size={32} className="my-4" />
    </div>
);

export default LoadingPage;
