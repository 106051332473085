import { CSSProperties } from "react";

export const commonStyles = {
    displayFlex: {
        display: "flex",
    } as CSSProperties,
    displayNone: { display: "none" } as CSSProperties,
    visibilityHidden: { visibility: "hidden" } as CSSProperties,
    fillParentHeight: { height: "100%" } as CSSProperties,
    fillAllWidth: { width: "100%" } as CSSProperties,
};
