import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TimePicker, Tooltip } from "antd";
import moment, { Moment } from "moment";
import { IHoursSchema } from "@snackpass/snackpass-types";

import DropDownSelect from "#reusable/select/dropdown";
import { ReactComponent as Duplicate } from "src/assets/icons/duplicate.svg";
import { ReactComponent as DeleteRed } from "src/assets/icons/delete-red.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import constants from "#core/constants";
import "antd/dist/antd.css";

import colors from "#reusable/colors/colors.json";

import { format12, SpecialHoursItemType } from "../helpers/date-time-helper";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

const DropDownSelectWrapper = styled.div`
    margin-left: 0.5rem;
    flex: 1;
    max-width: 12rem;
    margin-right: 5px;
`;
const DAY_OPTIONS = [
    {
        label: "Monday",
        value: 0,
    },
    {
        label: "Tuesday",
        value: 1,
    },
    {
        label: "Wednesday",
        value: 2,
    },
    {
        label: "Thursday",
        value: 3,
    },
    {
        label: "Friday",
        value: 4,
    },
    {
        label: "Saturday",
        value: 5,
    },
    {
        label: "Sunday",
        value: 6,
    },
];

const DashWrapper = styled.div`
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
`;

const IconsSection = styled.div`
    display: flex;
`;

type SelectedDayOfWeekOptionType = {
    label: string;
    value: number;
};

type IconProps = {
    onClick: Function;
};
const IconWrapper = styled.div<IconProps>`
    display: flex;
    margin-left: 0.1rem;
    border: 1px solid ${colors["neutral-400"]};
    height: 36px;
    width: 36px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

type HourProps = {
    hours?: IHoursSchema | null;
    show: boolean;
    specialHours: SpecialHoursItemType[];
    setSpecialHours: React.Dispatch<
        React.SetStateAction<SpecialHoursItemType[]>
    >;
    removeItem: Function;
    updateItemHours: Function;
    id: number;
    dayOfWeek?: number;
    start?: Moment | null | undefined;
    end?: Moment | null | undefined;
    storeOpenDays: number[];
};

export const SpecialHourRow: React.FC<HourProps> = ({
    specialHours,
    setSpecialHours,
    removeItem,
    updateItemHours,
    id,
    dayOfWeek,
    start,
    end,
    storeOpenDays,
}) => {
    const [selectedDay, setSelectedDay] = React.useState(
        DAY_OPTIONS[storeOpenDays[0]],
    );
    const [activeStoreDayOptions, setActiveStoreDayOptions] =
        React.useState(DAY_OPTIONS);
    const [startTime, setStartTime] = useState<Moment | null>(null);
    const [endTime, setEndTime] = useState<Moment | null>(null);
    const { width } = useWindowDimensions();

    useEffect(() => {
        //Only allow store open days to be selected
        const options = DAY_OPTIONS.filter(
            (day) => storeOpenDays.indexOf(day.value) > -1,
        );
        setActiveStoreDayOptions(options);
    }, []);
    useEffect(() => {
        setSelectedDay(
            dayOfWeek !== undefined
                ? DAY_OPTIONS.filter((option) => option.value === dayOfWeek)[0]
                : DAY_OPTIONS[storeOpenDays[0]],
        );
        setStartTime(start ?? null);
        setEndTime(end ?? null);
    }, [specialHours]);

    const handleStartTime = (dateObject: moment.Moment | null): void => {
        setStartTime(moment(dateObject, format12));
        updateItemHours(id, moment(dateObject, format12), endTime);
    };
    const handleEndTime = (dateObject: moment.Moment | null): void => {
        setEndTime(moment(dateObject, format12));
        updateItemHours(id, start, moment(dateObject, format12));
    };

    const desktopLayOut = () => (
        <Row>
            <DropDownSelectWrapper>
                <DropDownSelect
                    mr={3}
                    square
                    placeholder={"Day"}
                    options={activeStoreDayOptions}
                    value={selectedDay}
                    onChange={(selectedOption: SelectedDayOfWeekOptionType) => {
                        setSelectedDay(selectedOption);
                        setSpecialHours(
                            (existingItems: SpecialHoursItemType[]) =>
                                existingItems.map(
                                    (item: SpecialHoursItemType, j: number) =>
                                        j === id
                                            ? {
                                                  ...item,
                                                  dayOfWeek:
                                                      selectedOption.value,
                                              }
                                            : item,
                                ),
                        );
                    }}
                ></DropDownSelect>
            </DropDownSelectWrapper>
            <TimePicker
                size="large"
                placeholder="Start Time"
                value={moment(startTime, format12)}
                format={format12}
                style={{ borderRadius: "8px", flex: "1" }}
                onChange={handleStartTime}
                allowClear={false}
            />
            <DashWrapper>-</DashWrapper>
            <TimePicker
                size="large"
                placeholder="End Time"
                value={moment(endTime, format12)}
                format={format12}
                style={{ borderRadius: "8px", flex: "1" }}
                onChange={handleEndTime}
                allowClear={false}
            />

            <IconsSection>
                <IconWrapper
                    onClick={() => {
                        setSpecialHours([
                            ...specialHours,
                            {
                                id:
                                    specialHours[specialHours.length - 1].id +
                                    1,
                                dayOfWeek: selectedDay.value,
                                time: specialHours[id].time,
                            },
                        ]);
                    }}
                >
                    <Tooltip title="Duplicate">
                        <Duplicate />
                    </Tooltip>
                </IconWrapper>
                <IconWrapper
                    onClick={() => {
                        removeItem(id);
                    }}
                >
                    {" "}
                    <Tooltip title="Delete">
                        <DeleteRed />
                    </Tooltip>
                </IconWrapper>
            </IconsSection>
        </Row>
    );

    const mobileLayout = () => (
        <>
            <Row>
                <DropDownSelect
                    mr={3}
                    square
                    placeholder={"Day"}
                    options={activeStoreDayOptions}
                    value={selectedDay}
                    onChange={(selectedOption: SelectedDayOfWeekOptionType) => {
                        setSelectedDay(selectedOption);
                        setSpecialHours(
                            (existingItems: SpecialHoursItemType[]) =>
                                existingItems.map(
                                    (item: SpecialHoursItemType, j: number) =>
                                        j === id
                                            ? {
                                                  ...item,
                                                  dayOfWeek:
                                                      selectedOption.value,
                                              }
                                            : item,
                                ),
                        );
                        updateItemHours(id, startTime, endTime);
                    }}
                ></DropDownSelect>
                <IconsSection>
                    <IconWrapper
                        onClick={() => {
                            setSpecialHours([
                                ...specialHours,
                                {
                                    id:
                                        specialHours[specialHours.length - 1]
                                            .id + 1,
                                    dayOfWeek: selectedDay.value,
                                    time: { start: start, end: end },
                                },
                            ]);
                        }}
                    >
                        <Duplicate />
                    </IconWrapper>
                    <IconWrapper
                        onClick={() => {
                            removeItem(id);
                        }}
                    >
                        <DeleteRed />
                    </IconWrapper>
                </IconsSection>
            </Row>
            <Row>
                <TimePicker
                    size="large"
                    value={moment(startTime, format12)}
                    placeholder="Start Time"
                    defaultValue={moment("12:00 am", format12)}
                    format={format12}
                    style={{ borderRadius: "8px", flex: "1" }}
                    onChange={handleStartTime}
                    inputReadOnly={true}
                />
                <DashWrapper>-</DashWrapper>
                <TimePicker
                    size="large"
                    placeholder="Start Time"
                    value={moment(endTime, format12)}
                    format={format12}
                    style={{ borderRadius: "8px", flex: "1" }}
                    defaultValue={moment("11:59 pm", format12)}
                    onChange={handleEndTime}
                    inputReadOnly={true}
                />
            </Row>
        </>
    );

    return width > constants.MOBILE_MAX_WIDTH
        ? desktopLayOut()
        : mobileLayout();
};
