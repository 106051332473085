import React from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

import { Button } from "src/@/components/ui/button";

type DrawerBackButtonProps = {
    onClose: () => void;
};

const DrawerBackButton = (props: DrawerBackButtonProps): JSX.Element => (
    <Button
        variant="outline"
        onClick={props.onClose}
        size="icon"
        className="rounded-full p-0"
    >
        <ArrowLeftIcon className="h-4 w-4" />
    </Button>
);

export default DrawerBackButton;
