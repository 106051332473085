import React, { useContext } from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "src/assets/icons/arrow.svg";
import { descriptions } from "#onboarding/utils/descriptions";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import theme from "#onboarding/utils/theme";
import { UpdatedButton } from "#onboarding/components/shared/UpdatedButton";
import useWindowDimensions from "#hooks/use-window-dimensions";

import OnboardingTooltip from "./OnboardingTooltip";

type FooterProps = {
    isValid?: boolean;
    onSave: () => void;
    onContinue: () => void;
    disabled?: boolean;
    saveDisabled?: boolean;
    hasChange?: boolean;
};

export const Footer = ({
    isValid,
    disabled,
    saveDisabled = true,
    onSave,
    onContinue,
    hasChange,
}: FooterProps) => {
    const { isDesktop } = useWindowDimensions();

    const { resetState, loading } = useContext(OnboardingContext);

    const buttonDescription = isDesktop
        ? descriptions.buttons
        : descriptions.buttons.mobile;

    return (
        <Container>
            <div className="footer-back">
                <UpdatedButton
                    smallRadius
                    isValid={true}
                    disabled={false}
                    size="regular"
                    variant="tertiary"
                    style={{
                        width: theme.spacing.triple,
                        height: theme.spacing.triple,
                    }}
                    children={<Arrow />}
                    onClick={resetState}
                />
            </div>
            <div className="footer-save">
                <UpdatedButton
                    smallRadius
                    loading={loading}
                    isValid={!saveDisabled}
                    disabled={saveDisabled}
                    size="regular"
                    variant="tertiary"
                    style={{ height: "100%" }}
                    children={<>{buttonDescription.save}</>}
                    onClick={onSave}
                />
            </div>
            <OnboardingTooltip
                disabled={!isValid || disabled}
                tooltip={
                    loading ? "Loading" : "Please complete all required fields"
                }
            >
                <div className="footer-continue">
                    <UpdatedButton
                        smallRadius
                        loading={loading}
                        isValid={isValid}
                        disabled={disabled}
                        size="regular"
                        variant="primary"
                        style={{ height: "100%" }}
                        children={
                            <>
                                {hasChange
                                    ? buttonDescription.continue
                                    : buttonDescription.continueNoChange}
                            </>
                        }
                        onClick={onContinue}
                    />
                </div>
            </OnboardingTooltip>
        </Container>
    );
};

const Container = styled.div`
    ${({ theme }) => `
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;

        .footer-back {
            margin: auto 0;
            width: ${theme.spacing.triple};
            height: ${theme.spacing.triple};
        }
        .footer-save {
            width: 30%;
            margin: 0 ${theme.spacing.base};
        }
        .footer-continue {
            flex-grow: 1;
        }
    `}
`;
