import { Canvas, FabricText, Rect } from "fabric";

import { RectGeometry } from "src/api/graphql/generated/types";
import { Element } from "#table-editor/canvas/Element";
import { TableProperty } from "#table-editor/types";

/**
 * Draws a rectangular table
 */
export function drawTableRect(
    canvas: Canvas,
    id: string,
    shape: RectGeometry,
    properties: TableProperty
) {
    const rect = new Rect({
        top: 0,
        left: 0,
        width: shape.width,
        height: shape.height,
        selectable: true,
        fill: "#dddddd"
    });

    const text = new FabricText(properties.tableName, {
        fontFamily: "Inter",
        fontSize: 28,
        fill: "black",
        textAlign: "center"
    });

    text.setPositionByOrigin(rect.getCenterPoint(), "center", "center");
    text.setCoords();

    const group = new Element(id, [rect, text], {
        variant: "table",
        top: shape.y,
        left: shape.x,
        width: shape.width,
        height: shape.height,
        selectable: true
    });

    group.setControlsVisibility({ mtr: false });

    canvas.add(group);

    return group;
}
