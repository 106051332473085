import React from "react";
import { ThemeProvider } from "styled-components";

import PayoutsSettingsProvider from "#payouts/utils/PayoutsSettingsContext";
import theme from "#payouts/utils/theme";
import { PageWrapper } from "#payouts/components/PageWrapper";
import { SummaryPage } from "#payouts/components/summary";
import ModalStyles from "#payouts/utils/ModalStyles";
import { CompanyModal } from "#payouts/components/modal/Company";
import { AccountModal } from "#payouts/components/modal/Account";
import { RepresentativeModal } from "#payouts/components/modal/Representative";
import { SubmitModal } from "#payouts/components/modal/Submit";
import { AccountSetup } from "#payouts/components/setup";
import { EmailVerification } from "#payouts/components/EmailVerification";
import { ConfirmRemoveModal } from "#payouts/components/modal/ConfirmRemove";
import { AdminHoldModal } from "#payouts/components/modal/AdminHold";
import { ConfirmNewPrimary } from "#payouts/components/modal/ConfirmNewPrimary";
import { StatusAlert } from "#payouts/components/status";

const PayoutsSettings = (): JSX.Element => (
    <ThemeProvider theme={theme}>
        <ModalStyles />
        <PayoutsSettingsProvider>
            <PageWrapper>
                <EmailVerification />
                <StatusAlert />
                <AccountSetup />
                <SummaryPage />
                <CompanyModal />
                <AccountModal />
                <RepresentativeModal />
                <SubmitModal />
                <ConfirmRemoveModal />
                <AdminHoldModal />
                <ConfirmNewPrimary />
            </PageWrapper>
        </PayoutsSettingsProvider>
    </ThemeProvider>
);

export default PayoutsSettings;
