import { CashEventType } from "@snackpass/snackpass-types";
import { match } from "ts-pattern";

export const isAuditEvent = (e: { eventType: CashEventType }) =>
    e.eventType === CashEventType.Audit ||
    e.eventType === CashEventType.SessionEnd ||
    e.eventType === CashEventType.SessionStart;

export const formatEventType = (e: CashEventType): string =>
    match(e)
        .with(CashEventType.Audit, () => "Audit")
        .with(CashEventType.TipWithdrawal, () => "Tip Out")
        .with(CashEventType.Withdrawal, () => "Withdrawal")
        .with(CashEventType.Deposit, () => "Deposit")
        .with(CashEventType.CashOrder, () => "Order Paid")
        .with(CashEventType.CashRefund, () => "Order Refunded")
        .with(CashEventType.SessionEnd, () => "Session End")
        .with(CashEventType.SessionStart, () => "Session Start")
        .with(CashEventType.Open, () => "Open")
        .exhaustive();
