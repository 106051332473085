import React, { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { AccessorKeyColumnDef } from "@tanstack/react-table";

import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";

interface TableRow {
    [key: string]: string;
}

const SalesReportTable = () => {
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, locationReportsData, filter, stores, granularity } =
        reportsState;

    const data = locationReportsData?.salesReportData || [];

    const columnsForExport = [
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            width: "10%",
        },
        {
            title: "Net Sales",
            dataIndex: "netSales",
            key: "netSales",
        },
        {
            title: "Orders",
            dataIndex: "orders",
            key: "orders",
            className: "whitespace-nowrap",
        },
        {
            title: "Average Order",
            dataIndex: "averageOrder",
            key: "averageOrder",
            className: "pr-[3rem]",
        },
        {
            title: "Items",
            dataIndex: "items",
            key: "items",
        },
    ];

    const rows: TableRow[] = useMemo(() => {
        if (!data) return [];
        const storeRows = Object.entries(data || {})
            .filter(
                ([id, _]) =>
                    filter.storeIds.includes(id) || filter.storeIds.length == 0,
            )
            .sort(([_id1, a], [_id2, b]) => b.netSales - a.netSales)
            .map(([id, storeData], i) => ({
                location: stores.find((e) => e._id == id)?.name || "",
                netSales: toDollarFormatted(storeData.netSales),
                orders: formatNumber(storeData.orders),
                averageOrder: toDollarFormatted(storeData.avgOrder),
                items: formatNumber(storeData.items),
                key: `${id}-${i}`,
            }));

        const totalItems = Object.values(data).reduce(
            (acc, curr) => acc + curr.items,
            0,
        );
        const totalOrders = Object.values(data).reduce(
            (acc, curr) => acc + curr.orders,
            0,
        );
        const totalSales = Object.values(data).reduce(
            (acc, curr) => acc + curr.netSales,
            0,
        );
        const averageOrder = totalSales / totalOrders;

        const totalRow = {
            location: "Total",
            netSales: isNaN(totalSales) ? "$--" : toDollarFormatted(totalSales),
            orders: isNaN(totalOrders)
                ? formatNumber(0)
                : formatNumber(totalOrders),
            averageOrder: isNaN(averageOrder)
                ? "$--"
                : toDollarFormatted(averageOrder),
            items: formatNumber(totalItems),
            key: "total",
        };

        return [...storeRows, totalRow];
    }, [data]);

    return (
        <div className="mb-20 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Sales Breakdown by Location</h4>
                <DownloadButton
                    rows={rows}
                    columns={columnsForExport}
                    filename={`Sales Report By Location Breakdown ${formatRange(
                        dateRanges[0],
                        granularity,
                    )}`}
                />
            </div>
            <div>
                {locationReportsData?.salesReportDataLoading ||
                (!locationReportsData?.salesReportData &&
                    !locationReportsData?.salesReportDataFailed) ? (
                    <Skeleton className="h-96" />
                ) : !locationReportsData?.salesReportData ? (
                    <ErrorChart className="h-96 rounded-md" />
                ) : (
                    <DataTable
                        columns={columns}
                        data={rows}
                        customPageSize={99999}
                    />
                )}
            </div>
        </div>
    );
};

const columns: AccessorKeyColumnDef<TableRow, string | number>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Location"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.location}</div>,
        accessorKey: "location",
        id: "location",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: true,
        size: 300,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Net Sales"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.netSales}</div>,
        accessorKey: "netSales",
        id: "netSales",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Orders"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.orders}</div>,
        accessorKey: "orders",
        id: "orders",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Average Order"
            />
        ),
        cell: ({ row }) => (
            <div className="ml-3">{row.original.averageOrder}</div>
        ),
        accessorKey: "averageOrder",
        id: "averageOrder",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Items"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.items}</div>,
        accessorKey: "items",
        id: "items",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
    },
];

export default SalesReportTable;
