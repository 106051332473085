import { useMemo } from "react";
import { keyBy } from "lodash";

import { useProductEssentials } from "./useProductEssentials";

/**
 * Provides essential product data from the currently viewed menu in a lookup map (by product ID).
 */
export function useProductEssentialsMap() {
    const productEssentials = useProductEssentials();

    return useMemo(() => keyBy(productEssentials, "id"), [productEssentials]);
}
