import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";

import { ReactComponent as Warning } from "src/assets/icons/warning-menu.svg";
import { ReactComponent as Error } from "src/assets/icons/error-menu.svg";

import { MenuAlertTypes } from "./rule-alerts";

const alertDescription = (type: MenuAlertTypes) => {
    switch (type) {
        case "no-name":
            return "Menu name cannot be left blank.";
        case "no-time":
            return "Available time cannot be left empty.";
        case "no-platform":
            return "Menu platform cannot be left unselected.";
        case "third-party":
            return "Only one third party menu can be active at any time.";
        case "schedule-ahead":
            return "Customers will be unable to schedule ahead outside of selected menu hours.";
        case "has-overlapping-hours":
            return "Menu has hours that are overlapping. Please make sure that the hours for days do not overlap.";
        case "has-hours-start-time-before-end-time":
            return "Menu hours end time must be after start time.";
        default:
            return "";
    }
};

type BannerProps = {
    type: MenuAlertTypes;
    isMobile: boolean;
    hide: boolean;
};

export const RuleBanner: React.FC<BannerProps> = ({ type, isMobile, hide }) => {
    const warning = type === "schedule-ahead" || type === "title-case-name";
    return (
        <BannerWrapper style={hide ? fadeOut : fadeIn}>
            <Row className="mb24">
                <Col
                    span={isMobile ? 4 : 2}
                    className={`banner-icon-common ${
                        warning ? "warning-icon" : "alert-icon"
                    }`}
                >
                    {warning ? <Warning /> : <Error />}
                </Col>
                <Col
                    span={isMobile ? 20 : 22}
                    className={`banner-text-common ${
                        warning ? "warning-text" : "alert-text"
                    }`}
                >
                    <TitleWrapper>{warning ? "Warning" : "Error"}</TitleWrapper>
                    <TextWrapper>{alertDescription(type)}</TextWrapper>
                </Col>
            </Row>
        </BannerWrapper>
    );
};

const BannerWrapper = styled.div`
    .banner-icon-common {
        border: 1px solid;
        border-radius: 8px 0px 0px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .banner-text-common {
        border: 1px solid;
        border-radius: 0px 8px 8px 0px;
        @media ${ScreenState.MOBILE} {
            padding: 12px 16px;
        }
        padding: 15px 15px 15px 24px;
    }
    .alert-icon {
        background: #ff3929;
        border-color: #ff3929;
    }
    .warning-icon {
        border-color: #ffbb00;
        background: #ffbb00;
    }
    .alert-text {
        border-color: #ff3929;
    }
    .warning-text {
        border-color: #ffbb00;
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
            height: 105px;
        }
        100% {
            opacity: 0;
            height: 0;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
            height: 0;
        }
        100% {
            opacity: 1;
            height: 105px;
        }
    }
`;

const TitleWrapper = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;

const TextWrapper = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

const fadeIn = { animation: "fade-in 0.5s ease-in" };
const fadeOut = { animation: "fade-out 0.5s ease-in" };
