import {
    AudioOn,
    FirstInitial,
    EditFont,
    EditVoice,
} from "#devices/components/EditDrawer/Settings";
import { useSnackTvSettingsEnabled } from "#navigation/utils";

export const CustomerReadyScreen = () => {
    const snackTvSettingsEnabled = useSnackTvSettingsEnabled();

    return (
        <>
            <FirstInitial />
            {snackTvSettingsEnabled ? (
                <>
                    <AudioOn />
                    <EditFont />
                    {/* <EditFontSize /> */}
                    {/* <EditLayout /> */}
                    <EditVoice />
                </>
            ) : null}
        </>
    );
};
