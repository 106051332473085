import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RequestIntegration } from "#settings-integrations/components/request-integration";
import { getActiveStore } from "src/redux/selectors";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Checkbox } from "src/@/components/ui/checkbox";

type IntegrationProp = {
    canEdit: boolean;
    showCreatingIntegrationView?: boolean;
    setShowCreatingIntegrationView?: React.Dispatch<
        React.SetStateAction<boolean>
    >;
};

export const IntegrationOverviewInformation = ({
    canEdit,
    showCreatingIntegrationView,
    setShowCreatingIntegrationView,
}: IntegrationProp) => {
    const activeStore = useSelector(getActiveStore);
    const [checked, setChecked] = useState<boolean>(false);

    return (
        <Card className="border-neutral-400">
            <CardHeader>
                <CardTitle className="text-xlarge">Overview</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="mb-5 text-body font-normal text-neutral-600">
                    Integrations allow you to send all of your delivery and
                    pickup orders from third party platforms like Doordash, Uber
                    Eats, and more directly to your Kitchen OS Orders tab or
                    KDS.
                </div>
                <div className="mb-5 text-large font-semibold text-neutral-950">
                    Additional Information
                </div>
                <ul>
                    <li>
                        <div className="text-body font-normal text-neutral-500">
                            Add unlimited delivery integrations for a flat rate
                            of
                            <span className="font-semibold text-neutral-700">
                                {" "}
                                $120/month
                            </span>
                            .
                        </div>
                    </li>
                    <li>
                        <div className="text-body font-normal text-neutral-500">
                            New integrations may take up to 10 days to appear on
                            your dashboard.
                        </div>
                    </li>
                    <li>
                        <div className="text-body font-normal text-neutral-500">
                            Read through our supported integration features and
                            limitations{" "}
                            <a
                                href="https://support.snackpass.co/en/articles/7437684-deliverect-delivery-integration"
                                target="_blank"
                            >
                                here
                            </a>
                        </div>
                    </li>
                </ul>
                <div className="mb-5 mt-10 flex w-full space-x-2">
                    <Checkbox
                        checked={checked}
                        onCheckedChange={() => setChecked(!checked)}
                    />
                    <label className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        I have read and agree to the current scope of supported
                        integration features and limitations found{" "}
                        <a
                            href="https://support.snackpass.co/en/articles/7437684-deliverect-delivery-integration"
                            target="_blank"
                        >
                            here
                        </a>
                    </label>
                </div>
                {canEdit && (
                    <RequestIntegration
                        storeId={activeStore?._id || ""}
                        showCreatingIntegrationView={
                            showCreatingIntegrationView
                        }
                        setShowCreatingIntegrationView={
                            setShowCreatingIntegrationView
                        }
                        checked={checked}
                    />
                )}
            </CardContent>
        </Card>
    );
};
