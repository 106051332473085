import { ICommissionPolicy } from "@snackpass/snackpass-types";

import { CommissionFormValues } from "#settings/settings-fees/utils/types";

export const buildCommissionPolicies = (values: CommissionFormValues) => {
    const policies: ICommissionPolicy[] = [];
    if (values.appPickupOverride) {
        policies.push({
            transactionSource: "app",
            fulfillment: "PICKUP",
            fixed: values.appPickupFixed ?? 0,
            percent: values.appPickupPercent ?? 0,
        });
    }
    if (values.appDeliveryOverride) {
        policies.push({
            transactionSource: "app",
            fulfillment: "DELIVERY",
            fixed: values.appDeliveryFixed ?? 0,
            percent: values.appDeliveryPercent ?? 0,
        });
    }
    if (values.appDineInOverride) {
        policies.push({
            transactionSource: "app",
            fulfillment: "DINE_IN",
            fixed: values.appDineInFixed ?? 0,
            percent: values.appDineInPercent ?? 0,
        });
    }
    if (values.kioskPickupOverride) {
        policies.push({
            transactionSource: "kiosk",
            fulfillment: "PICKUP",
            fixed: values.kioskPickupFixed ?? 0,
            percent: values.kioskPickupPercent ?? 0,
        });
    }
    if (values.kioskDineInOverride) {
        policies.push({
            transactionSource: "kiosk",
            fulfillment: "DINE_IN",
            fixed: values.kioskDineInFixed ?? 0,
            percent: values.kioskDineInPercent ?? 0,
        });
    }
    if (values.onlinePickupOverride) {
        policies.push({
            transactionSource: "online",
            fulfillment: "PICKUP",
            fixed: values.onlinePickupFixed ?? 0,
            percent: values.onlinePickupPercent ?? 0,
        });
    }
    if (values.onlineDeliveryOverride) {
        policies.push({
            transactionSource: "online",
            fulfillment: "DELIVERY",
            fixed: values.onlineDeliveryFixed ?? 0,
            percent: values.onlineDeliveryPercent ?? 0,
        });
    }
    if (values.onlineDineInOverride) {
        policies.push({
            transactionSource: "online",
            fulfillment: "DINE_IN",
            fixed: values.onlineDineInFixed ?? 0,
            percent: values.onlineDineInPercent ?? 0,
        });
    }
    return policies;
};
