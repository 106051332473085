import { PropsWithChildren, useCallback, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import {
    useEditorState,
    useEditorStore,
} from "#table-editor/components/Editor/EditorProvider";
import { useDeleteServiceArea, useSaveServiceArea } from "#table-editor/api";
import { Button } from "src/@/components/ui/button";
import { useValidateCanvas } from "#table-editor/components/Editor/hooks/useValidateCanvas";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { SectionOptions } from "#table-editor/components/SectionOptions";
import { RenameSectionDialog } from "#table-editor/components/RenameSectionDialog";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";

export function Header() {
    const editor = useEditorStore();

    const isDirty = useEditorState((state) => state.isDirty);
    const activeServiceArea = useEditorState(
        (state) => state.activeServiceArea,
        (a, b) => a?.id === b?.id,
    );

    const [openOptionsPopover, setOpenOptionsPopover] = useState(false);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);

    const onRenameSection = useCallback(
        (name: string) => {
            editor.getState().setActiveServiceAreaName(name);
            setOpenRenameDialog(false);
        },
        [editor],
    );

    const { loading: deleteLoading, deleteServiceArea } =
        useDeleteServiceArea();

    const onDeleteServiceArea = useCallback(async () => {
        setOpenOptionsPopover(false);
        if (activeServiceArea) {
            if (activeServiceArea.id === "") {
                editor.getState().revertActiveServiceArea();
                editor.getState().removeServiceArea(activeServiceArea.id);
            } else {
                await deleteServiceArea(activeServiceArea.id);
            }
        }
    }, [activeServiceArea, deleteServiceArea, editor]);

    const onCancelChanges = useCallback(() => {
        editor.getState().revertActiveServiceArea();
    }, [editor]);

    const disableCancel = deleteLoading || !isDirty || !activeServiceArea;

    return (
        <>
            <div className="flex flex-row items-center justify-between pb-2 pl-8 pr-4 pt-4">
                <h1 className="text-2xl font-semibold text-neutral-950">
                    Table Editor
                </h1>

                <div className="flex flex-row items-center gap-3">
                    <Errors />
                    <Options
                        open={openOptionsPopover}
                        onOpenChange={setOpenOptionsPopover}
                        onRenameSection={() => setOpenRenameDialog(true)}
                        onDeleteSection={onDeleteServiceArea}
                    />
                    <Button
                        variant="outline"
                        disabled={disableCancel}
                        onClick={onCancelChanges}
                    >
                        Cancel
                    </Button>
                    <SaveButton disabled={deleteLoading} />
                </div>
            </div>
            <RenameSectionDialog
                open={openRenameDialog}
                onOpenChange={setOpenRenameDialog}
                initialName={activeServiceArea?.name ?? ""}
                onSubmit={onRenameSection}
            />
        </>
    );
}

type OptionsProps = {
    open: boolean;
    onOpenChange(open: boolean): void;
    onRenameSection(): void;
    onDeleteSection(): void;
};

function Options({
    open,
    onOpenChange,
    onRenameSection,
    onDeleteSection,
}: OptionsProps) {
    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    variant="ghost"
                    type="button"
                    className="bg-transparent"
                    size="icon"
                >
                    <DotsHorizontalIcon className="h-5 w-5 text-black" />
                </Button>
            </PopoverTrigger>
            <SectionOptions
                onRenameSection={onRenameSection}
                onDelete={onDeleteSection}
            />
        </Popover>
    );
}

type SaveButtonProps = {
    disabled?: boolean;
};

function SaveButton({ disabled }: SaveButtonProps) {
    const numServiceAreas = useEditorState(
        (state) => state.serviceAreas.length,
    );

    const isDirty = useEditorState((state) => state.isDirty);
    const { isValid, errors } = useValidateCanvas();

    const { loading: saveLoading, saveServiceArea } = useSaveServiceArea();

    const shouldDisable =
        disabled ||
        !isValid ||
        !isDirty ||
        saveLoading ||
        numServiceAreas === 0;

    const shouldOpenTooltip = !isValid || numServiceAreas === 0;

    const errorsList = [
        numServiceAreas === 0 ? "Must have at least 1 section" : "",
        ...errors,
    ].filter(Boolean);

    return (
        <TooltipProvider>
            <Tooltip>
                <ConditionalTooltip open={shouldOpenTooltip}>
                    <div>
                        <Button
                            type="button"
                            variant="default"
                            className="w-full"
                            disabled={shouldDisable}
                            onClick={saveServiceArea}
                            loading={saveLoading}
                            loadingColor="black"
                        >
                            Save Section
                        </Button>
                    </div>
                </ConditionalTooltip>
                <TooltipContent
                    className="border border-critical-a11y-light bg-white p-0 text-black"
                    side="bottom"
                >
                    <ErrorsList errors={errorsList} />
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}

function Errors() {
    const { isValid, errors } = useValidateCanvas();

    return !isValid ? (
        <Popover>
            <PopoverTrigger asChild>
                <div className="flex cursor-pointer flex-row items-center gap-1 rounded-md p-2 hover:bg-critical-a11y-light/5">
                    <ExclamationCircleIcon className="h-5 w-5 animate-warning-pulse text-critical-a11y-light" />
                    <p className="select-none text-sm font-semibold text-critical-a11y-light">
                        Errors
                    </p>
                </div>
            </PopoverTrigger>
            <PopoverContent className="border border-critical-a11y-light p-0">
                <ErrorsList errors={errors} />
            </PopoverContent>
        </Popover>
    ) : null;
}

type ErrorsListProps = {
    errors: string[];
};

function ErrorsList({ errors }: ErrorsListProps) {
    return (
        <div className="flex flex-col gap-1 px-2">
            {errors.map((error, index) => (
                <div
                    key={`error-${index}`}
                    className="my-2 flex flex-row gap-2"
                >
                    <ExclamationTriangleIcon className="h-5 w-5 text-critical-a11y-light" />
                    <p className="text-sm font-semibold">{error}</p>
                </div>
            ))}
        </div>
    );
}

type ConditionalTooltipProps = PropsWithChildren<{
    open: boolean;
}>;

function ConditionalTooltip({ open, children }: ConditionalTooltipProps) {
    if (open) {
        return <TooltipTrigger asChild>{children}</TooltipTrigger>;
    }

    return children;
}
