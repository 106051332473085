import { useTopItems } from "./use-top-items";

export interface TopItemChartData {
    displayName: string;
    fullName: string;
    revenue: number;
    orders: number;
    color: string;
}

export const useTopItemsChart = () => {
    const { topItems, isLoading, error } = useTopItems();

    return {
        topItems,
        isLoading,
        error,
    };
};
