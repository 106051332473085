import { useCallback, useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { ColumnDef } from "@tanstack/react-table";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";

import { DataTable } from "src/@/components/ui/data-table";
import { PrepStationDeviceDetails } from "#devices/utils/deviceTypes/PrepStationDevice";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger,
} from "src/@/components/ui/HybridTooltip";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DeviceType } from "#devices/utils/deviceTypes";
import { usePrepStations } from "#prep-stations/hooks";

export const PrepStationTable = () => {
    const { setAddon, setIsAddonDrawerVisible, setIsEditDrawerVisible } =
        useContext(DevicesPageContext);

    const { prepStations, isPending, isFetching } = usePrepStations();
    const handlePrepStationClick = useCallback(
        (details: PrepStationDeviceDetails) => {
            setAddon({
                details: details,
                type: DeviceType.PrepStation,
                isCreate: false,
            });
            setIsEditDrawerVisible(false);
            setIsAddonDrawerVisible(true);
        },
        [setAddon, setIsAddonDrawerVisible, setIsEditDrawerVisible],
    );

    const columns: ColumnDef<PrepStationDeviceDetails>[] = useMemo(
        () => [
            {
                id: "actions",
                cell: (props) => (
                    <div>
                        <TooltipProvider delayDuration={100}>
                            <HybridTooltip>
                                <HybridTooltipTrigger asChild>
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            handlePrepStationClick(
                                                props.row.original,
                                            )
                                        }
                                    >
                                        <Pencil1Icon className="h-3 w-3" />
                                    </Button>
                                </HybridTooltipTrigger>
                                <HybridTooltipContent
                                    className={
                                        "z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                                    }
                                    sideOffset={5}
                                    side="bottom"
                                >
                                    <p>Edit Prep Station</p>
                                </HybridTooltipContent>
                            </HybridTooltip>
                        </TooltipProvider>
                    </div>
                ),
                size: 60,
            },
            {
                id: "Name",
                accessorKey: "name",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        column={column}
                        title="Name"
                    />
                ),
                enablePinning: false,
                enableHiding: false,
                enableSorting: false,
            },
            {
                id: "Configuration Mode",
                accessorKey: "isInclusive",
                header: () => (
                    <div>
                        Configuration Mode
                        <ReportsTooltip
                            body={
                                "By default, prep-stations are inclusive, meaning that you specify everything that the printer SHOULD print. If you want to instead specify everything that the printer SHOULD NOT print, change to exclusive."
                            }
                        />
                    </div>
                ),
                enableSorting: false,
                cell: (info) =>
                    info.row.original.isInclusive
                        ? "Inclusive"
                        : "Not Inclusive",
            },
            {
                id: "Categories",
                accessorKey: "allowedCategoryList",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        column={column}
                        title="Categories"
                    />
                ),
                enableSorting: false,
                cell: (info) =>
                    info.row.original.isInclusive
                        ? `${info.row.original.allowCategoryList.length} categories`
                        : `${info.row.original.rejectCategoryList.length} categories`,
            },
            {
                id: "Products",
                accessorKey: "allowedProductList",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        column={column}
                        title="Products"
                    />
                ),
                enableSorting: false,
                cell: (info) =>
                    info.row.original.isInclusive
                        ? `${info.row.original.allowProductList.length} item(s)`
                        : `${info.row.original.rejectProductList.length} item(s)`,
            },
        ],
        [handlePrepStationClick],
    );

    if (isFetching || isPending) return <Skeleton className="h-72 w-full" />;

    return (
        <DataTable showPagination columns={columns} data={prepStations || []} />
    );
};
