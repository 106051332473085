import { useCallback } from "react";

import { useAppSelector } from "src/redux/hooks";
import { getActiveStore } from "src/redux/selectors";
import { submitPromo } from "#promotion/utils/submit";
import {
    FormUsageTypes,
    GenericPromo,
    GiftCardPromo,
    RewardPromo,
} from "#promotion/utils/types";
import { useCategoriesWithProductsMap } from "#menu/hooks";

/**
 * Provides a function for submitting a create or edit promo request.
 * This is largely just a wrapper around the `submitPromo` utility function.
 */
export function useSubmitPromo() {
    const store = useAppSelector(getActiveStore);
    const categoriesMap = useCategoriesWithProductsMap();

    return useCallback(
        async (
            formUsage: FormUsageTypes,
            data: GenericPromo | RewardPromo | GiftCardPromo,
            promoId?: string,
        ) => {
            if (!store) {
                throw new Error("No active store found");
            }

            await submitPromo(formUsage, store, categoriesMap, data, promoId);
        },
        [categoriesMap, store],
    );
}
