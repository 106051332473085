import api from "src/api/rest";
import {
    CreateIntegration,
    INTEGRATION_FIELD_NAMES,
    MARKUP_TYPES,
} from "#settings-integrations/utils/types";

export const submitIntegrationRequest = async (
    data: CreateIntegration,
    storeId: string,
): Promise<boolean> => {
    const integrationsToSubmit = data[INTEGRATION_FIELD_NAMES.INTEGRATIONS]
        .map((integration, index) => {
            const integrationLinkOrAccountNumber = data[
                INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS
            ].find(
                (linkOrAccoountNumber) =>
                    linkOrAccoountNumber.integrationApp == integration,
            )?.linkOrAccountNumber;
            return `\tIntegration ${
                index + 1
            }: ${integration}\n\t\tIntegration Restaurant Link / Account Number: ${integrationLinkOrAccountNumber}`;
        })
        .join("\n");

    const shouldMarkupPrices =
        data[INTEGRATION_FIELD_NAMES.SHOULD_MARKUP_PRICES];

    const markupPricesType = data[INTEGRATION_FIELD_NAMES.MARKUP_TYPE];

    const shouldMarkupPricesText = `\n\tMarkup Prices?: ${
        shouldMarkupPrices ? "YES" : "NO"
    }`;

    const markupPriceInformation = `\n\t\tMarkup Amount: ${
        markupPricesType == MARKUP_TYPES.DOLLARS
            ? `$${data[INTEGRATION_FIELD_NAMES.MARKUP_DOLLARS]}`
            : `${data[INTEGRATION_FIELD_NAMES.MARKUP_PERCENT]}%`
    }\n\t\tMarkup comments: ${data[INTEGRATION_FIELD_NAMES.MARKUP_COMMENTS]}\n`;

    const success = api.integrations.request
        .requestIntegration({
            name: data[INTEGRATION_FIELD_NAMES.NAME],
            storeId: storeId,
            message: `\n\n\tUser Email: ${
                data[INTEGRATION_FIELD_NAMES.EMAIL]
            }\n${integrationsToSubmit} ${shouldMarkupPricesText} ${
                shouldMarkupPrices && markupPriceInformation
            }\n\tUser Comments: ${data[INTEGRATION_FIELD_NAMES.COMMENTS]}`,
        })
        .then(({ data: { success } }) => success)
        .catch(() => false);

    return success;
};
