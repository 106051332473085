import type { PurchaseReportTransactionSource } from "@snackpass/snackpass-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { genHash } from "#core/utils";
import {
    getDashboardReportsTransactionSource,
    setDashboardFilterTransactionSource,
} from "src/redux/slices";

export const useTransactionSourcePicker = (): {
    transactionSources: PurchaseReportTransactionSource[];
    setGlobalSources: (xs: PurchaseReportTransactionSource[]) => void;
    hash: string;
} => {
    //globalSource need to come before useState hook to prevent transactionSource to be undefined
    const globalSources = useSelector(getDashboardReportsTransactionSource);
    const [transactionSources, setTransactionSource] = useState<
        PurchaseReportTransactionSource[]
    >([...globalSources]);

    useEffect(() => {
        setTransactionSource([...globalSources]);
    }, [globalSources.join("")]);

    const dispatch = useDispatch();
    const setGlobalSources = (sources: PurchaseReportTransactionSource[]) => {
        dispatch(setDashboardFilterTransactionSource([...sources]));
    };

    return {
        transactionSources,
        setGlobalSources,
        hash: genHash(transactionSources.join(", ")),
    };
};
