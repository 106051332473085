import { SystemColors } from "@snackpass/design-system";
import { useContext } from "react";
import styled from "styled-components";
import { match } from "ts-pattern";

import useWindowDimensions from "#hooks/use-window-dimensions";
import { Button } from "#onboarding/components/shared/Button";
import Text from "#onboarding/components/shared/Text";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { descriptions } from "#onboarding/utils/descriptions";
import { IStep, OnboardingStep } from "#onboarding/utils/types";
import { useIsCompleted } from "#onboarding/utils/validation/completed";

export const SummaryRow = ({ step }: { step: IStep }) => {
    const { isDesktop } = useWindowDimensions();

    const { handleStepChange, verificationFetched } =
        useContext(OnboardingContext);

    step.completed = useIsCompleted(step);

    const disabled = match(step.name)
        .with(OnboardingStep.Business, () => !verificationFetched)
        .otherwise(() => false);

    const buttonDescription = isDesktop
        ? descriptions.buttons
        : descriptions.buttons.mobile;

    const { optional } = step;

    const Optional = () =>
        optional ? (
            <span className="custom-optional-mark">Optional</span>
        ) : (
            <></>
        );

    return (
        <Container>
            <div className="left">
                <div className={`circle${step.completed ? " completed" : ""}`}>
                    {step.completed ? "✔" : step.number}
                </div>
                <div className="labels">
                    <div>
                        <Text.SmallLabel>{step.title}</Text.SmallLabel>
                        <Optional />
                    </div>
                    <Text.Subtitle>{step.subtitle}</Text.Subtitle>
                </div>
            </div>
            <div className="right">
                <Button
                    disabled={disabled}
                    smallRadius
                    size="small"
                    variant={step.completed ? "tertiary" : "primary"}
                    children={
                        <>
                            {step.completed
                                ? buttonDescription.edit
                                : buttonDescription.start}
                        </>
                    }
                    onClick={() => handleStepChange(step.name)}
                />
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing.double};

    .left {
        display: flex;
        flex-direction: row;
    }

    .right {
        margin: auto 0;
        margin-left: ${({ theme }) => theme.spacing.base};
    }

    .circle {
        display: flex;
        flex: 0;
        font-size: 14px;
        min-width: 35px;
        min-height: 35px;
        border-radius: 50%;
        background-color: ${SystemColors.v2.salt20.light};
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto 0;
        margin-right: ${({ theme }) => theme.spacing.base};
    }
    .circle.completed {
        background-color: #0099ff;
        color: white;
    }

    .labels {
        .custom-optional-mark {
            margin-left: ${({ theme }) => theme.spacing.half};
            background-color: rgba(0, 0, 0, 0.1);
            padding: 2px 5px;
            border-radius: 3px;
            font-size: 12px;
            font-family: Inter;
            font-weight: 500;
            line-height: 16px;
        }
    }
`;
