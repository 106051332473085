import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

import colors from "#reusable/colors/colors.json";

export type ButtonProps = {
    type: "primary" | "secondary" | "tertiary" | "quaternary";
    size?: "large" | "regular" | "small" | "micro";
    disabled?: boolean;
};

export const Button = styled.div<ButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media ${ScreenState.MOBILE} {
        flex: auto;
    }

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;

    border-radius: 8px;

    @media ${ScreenState.MOBILE} {
        padding: ${({ size = "regular" }) => {
            switch (size) {
                case "large":
                    return "12px 24px";
                case "regular":
                    return "10px 10px";
                case "small":
                    return "8px 12px";
                case "micro":
                    return "4px 12px";
            }
        }};
    }

    padding: ${({ size = "regular" }) => {
        switch (size) {
            case "large":
                return "12px 24px";
            case "regular":
                return "12px 16px";
            case "small":
                return "8px 12px";
            case "micro":
                return "4px 12px";
        }
    }};

    background: ${({ type, disabled }) => {
        switch (type) {
            case "primary":
                return disabled ? "#F1F5F8" : "#0077FF";
            case "secondary":
                return disabled ? "#F1F5F8" : "#F1F5F8";
            case "tertiary":
            case "quaternary":
                return "#FFF";
        }
    }};

    color: ${({ type, disabled = false }) => {
        if (disabled) return "#A0ADBA";

        return type === "primary" ? "#FFF" : "#282D32";
    }};

    border: ${({ type, disabled = false }) => {
        switch (type) {
            case "primary":
                return `1px solid ${disabled ? "#F1F5F8" : "#0077FF"}`;
            case "secondary":
                return "1px solid #F1F5F8";
            case "tertiary":
                return `1px solid ${colors["neutral-400"]}`;
            case "quaternary":
                return undefined;
        }
    }};

    font-size: ${({ size = "regular" }) => {
        switch (size) {
            case "large":
                return "24px";
            case "regular":
                return "18px";
            case "small":
                return "16px";
            case "micro":
                return "14px";
        }
    }};

    line-height: ${({ size = "regular" }) => {
        switch (size) {
            case "large":
                return "32px";
            case "regular":
                return "24px";
            case "small":
                return "20px";
            case "micro":
                return "18px";
        }
    }};

    cursor: ${({ disabled = false }) => (disabled ? "not-allowed" : "pointer")};

    &:hover {
        box-shadow: ${({ type, disabled = false }) => {
            if (disabled) return undefined;

            return type === "primary"
                ? "inset 999px 4px 0px rgba(0, 0, 0, 0.04), inset 999px 4px 0px rgba(0, 0, 0, 0.16)"
                : "inset 999px 4px 0px rgba(241, 245, 248, 0.5)";
        }};
    }
`;

export const RoundButton = styled.button`
    width: 36px;
    height: 36px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 48px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;
