import React from "react";
import { SnackpassConvenienceFeeStrategy } from "@snackpass/snackpass-types";

import { SelectContent, SelectItem } from "src/@/components/ui/select";

export const StrategyOptions = () => (
    <SelectContent>
        <SelectItem value={SnackpassConvenienceFeeStrategy.FlatAndPercent}>
            Both
        </SelectItem>
        <SelectItem
            value={SnackpassConvenienceFeeStrategy.LesserOfFlatAndPercent}
        >
            Lesser Of
        </SelectItem>
        <SelectItem
            value={SnackpassConvenienceFeeStrategy.GreaterOfFlatAndPercent}
        >
            Greater Of
        </SelectItem>
    </SelectContent>
);
