import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import "tippy.js/dist/tippy.css";
import Skeleton from "react-loading-skeleton";
import { ColumnDef } from "@tanstack/react-table";
import { toNumber } from "lodash";

import { PayoutHistoryItem } from "src/api/rest";
import {
    getArrivalDateText,
    getEarningsPeriodText,
    getPayoutDateText,
} from "#financial-reports/screens/payout-history/lib";
import { PaymentStatusDesktop } from "#financial-reports/screens/payout-history/payment-status";
import { getActiveStore } from "src/redux/selectors";
import { getStoreTimezone } from "#utils/helpers";
import { DataTable } from "src/@/components/ui/data-table";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { toDollarFormatted } from "#reports/sales-summary/lib";

export const PayoutHistoryTable = ({
    data,
    isLoading,
}: {
    data: PayoutHistoryItem[];
    isLoading: boolean;
}) => {
    const activeStore = useSelector(getActiveStore);
    const timezone = getStoreTimezone(activeStore);
    const updatedTime = moment().format("h:mm a");

    const columns: ColumnDef<PayoutHistoryItem>[] = useMemo(
        () => [
            {
                id: "columnId_0_00.3177398036334149",
                header: () => (
                    <div>
                        Payout Period
                        <ReportsTooltip body="The inclusive dates of the pay period" />
                    </div>
                ),

                cell: (props: CellProps) => {
                    const data = props.row.original;
                    const earningsPeriodText = getEarningsPeriodText(
                        data,
                        timezone,
                    );
                    return <span>{earningsPeriodText}</span>;
                },
                disableSortBy: true,
            },
            {
                id: "columnId_0_00.4333424232323",
                header: () => (
                    <div>
                        Amount
                        <ReportsTooltip
                            body={
                                "Amount only includes credit card transactions"
                            }
                        />
                    </div>
                ),

                cell: (props: CellProps) => {
                    const amountPaid = toDollarFormatted(
                        toNumber(props.row.original.netPayout),
                    );

                    return <span>{amountPaid}</span>;
                },
                disableSortBy: false,
            },
            {
                id: "columnId_0_00.3177398036334146",
                header: "Payout Initiated",

                accessor: "payoutDate",

                cell: (props: CellProps) => (
                    <span>
                        {getPayoutDateText(props.row.original, timezone)}
                    </span>
                ),
                disableSortBy: true,
            },
            {
                id: "columnId_0_00.31773980363341464",
                header: () => (
                    <div>
                        Estimated Arrival
                        <ReportsTooltip body="This is an estimate for initiated and in-transit payouts." />
                    </div>
                ),

                accessor: "arrivalDate",

                cell: (props: CellProps) => (
                    <span>
                        {getArrivalDateText(props.row.original, timezone)}
                    </span>
                ),
                disableSortBy: true,
            },
            {
                id: "columnId_0_00.3177398036334126",
                header: () => (
                    <div>
                        Status
                        <ReportsTooltip
                            contentClassName="text-left"
                            body={
                                <ul>
                                    <li>
                                        Initiated: Payout has been sent to our
                                        payout processor.
                                    </li>
                                    <li>
                                        In transit: Payout has been sent from
                                        our processor to your bank.
                                    </li>
                                    <li>
                                        Paid: Payout has arrived at your bank.
                                    </li>
                                </ul>
                            }
                        />
                    </div>
                ),

                cell: (props: CellProps) => (
                    <PaymentStatusDesktop status={props.row.original.status} />
                ),
                disableSortBy: true,
            },
            {
                id: "columnId_0_00438036334146",
                header: "Details",

                cell: (props: CellProps) => {
                    const detailText = props.row.original.description;

                    return (
                        <ReportsTooltip
                            contentClassName="text-left"
                            className="h-4 w-4"
                            body={detailText}
                        />
                    );
                },
                disableSortBy: true,
            },
        ],
        [],
    );
    if (isLoading) return <Skeleton className="h-72 w-full" />;

    return (
        <DataTable
            showPagination
            columns={columns}
            data={data}
            footerElement={
                <span className="text-small text-neutral-600">
                    Updated {updatedTime}
                </span>
            }
        />
    );
};

type CellProps = {
    row: {
        original: PayoutHistoryItem;
    };
};
