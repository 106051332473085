import React from "react";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import clsx from "clsx";

import { downloadQR } from "#utils/download-qr";
import { getActiveStore } from "src/redux/selectors";
import { Button } from "src/@/components/ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";

export const StoreQrCodeSection = () => {
    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id;
    const storeLink = `https://order.snackpass.co/$${storeId}?utm_source=qr`;

    if (!storeId) {
        return null;
    }

    return (
        <div>
            <h3 className="text-large"> Online Ordering</h3>
            <br />
            <div className="flex flex-col items-center justify-center">
                <QRCode
                    id="store-qr-code"
                    value={storeLink}
                    size={160}
                    className="mb-4"
                />
                <Button
                    className="mb-2"
                    onClick={() =>
                        downloadQR(
                            activeStore?.name ?? "Store QR Code",
                            "store-qr-code",
                        )
                    }
                >
                    <p>Download QR Code</p>
                </Button>

                <TooltipProvider delayDuration={100}>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button asChild variant="outline">
                                <a
                                    href={storeLink}
                                    target="_blank"
                                    className="text-black no-underline"
                                >
                                    Check QR Link
                                </a>
                            </Button>
                        </TooltipTrigger>

                        <TooltipContent
                            className={clsx(
                                "z-50 w-96 rounded-md bg-neutral-900 px-3 py-2 text-center text-neutral-50",
                            )}
                            sideOffset={5}
                        >
                            {storeLink}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
        </div>
    );
};
