import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";

import { getUser } from "src/redux/selectors";
import { UseGetInvites } from "#settings/hooks/use-get-invites";
import { PendingInvites } from "#settings/components/pending-invites";
import { MemberOfTeams } from "#my-teams/member-of-teams";
import { StoreRelationshipInfo } from "#my-teams/types";
import api from "src/api/rest";
import { logAndSendError } from "src/utils/errors";

export const MyTeams = () => {
    const [storeInfo, setStoreInfo] = useState<StoreRelationshipInfo[]>([]);
    const user = useSelector(getUser);

    const { invites, refetch } = UseGetInvites();

    const fetchStoresInfo = useCallback(async () => {
        try {
            const result = await api.users.getMyStoreRelationships();
            setStoreInfo(result.data.myStoreInfo);
        } catch (err) {
            logAndSendError(err);
            toast.error("Error getting teams info");
        }
    }, []);

    useEffect(() => {
        void fetchStoresInfo();
    }, [fetchStoresInfo]);

    if (!user) {
        return <div className="m-12">No data</div>;
    }

    return (
        <div className="m-12 flex h-full flex-col">
            {invites?.length ? (
                <div className="mb-8">
                    <div className="text-xl font-semibold">Pending Invites</div>
                    <PendingInvites
                        invites={invites}
                        refetch={refetch}
                        showPromptText={false}
                    />
                </div>
            ) : null}
            <div>
                <div className="text-xl font-semibold">My Teams</div>
                <MemberOfTeams info={storeInfo} />
            </div>
        </div>
    );
};
