import { EmployeePermissions } from "@snackpass/snackpass-types";
import React from "react";

import CheckBox from "#settings/settings-permissions/checkbox";

export type SnackOSPermissionsProps = {
    toggles: EmployeePermissions | undefined;
    setToggles: React.Dispatch<React.SetStateAction<EmployeePermissions>>;
};

export const SnackOSPermissions = ({
    toggles,
    setToggles,
}: SnackOSPermissionsProps) => {
    const current = {
        discounts: toggles?.discounts ?? false,
        orders: toggles?.orders ?? false,
        sales: toggles?.sales ?? false,
        cashDrawer: toggles?.cashDrawer ?? false,
    };
    return (
        <>
            <CheckBox
                title="Keypad Discounts - Can apply custom flat or percentage discounts to an order on the register via the keypad"
                onClick={() => {
                    setToggles({ ...current, discounts: !current.discounts });
                }}
                isChecked={current.discounts}
            />
            <CheckBox
                title="Orders - Can refund, upcharge orders or void orders"
                onClick={() => {
                    setToggles({ ...current, orders: !current.orders });
                }}
                isChecked={current.orders}
            />
            <CheckBox
                title="View Sales"
                onClick={() => {
                    setToggles({ ...current, sales: !current.sales });
                }}
                isChecked={current.sales}
            />
            <CheckBox
                title="Cash Drawer - Can open and interact with cash drawer"
                onClick={() => {
                    setToggles({ ...current, cashDrawer: !current.cashDrawer });
                }}
                isChecked={current.cashDrawer}
            />
        </>
    );
};
