import styled from "@emotion/styled";
import { ScreenState } from "@snackpass/snackpass-types";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FormNumberInput } from "#promotion/components/shared/form-number-input";
import { FIELD_NAMES } from "#promotion/utils/types";

export const CartSize = () => {
    const { clearErrors, watch } = useFormContext();

    const cartMinimum = watch(FIELD_NAMES.CART_MINIMUM);
    const cartMaximum = watch(FIELD_NAMES.CART_MAXIMUM);

    useEffect(() => {
        if (
            (cartMinimum && cartMaximum && cartMinimum < cartMaximum) ||
            (cartMinimum && !cartMaximum) ||
            (!cartMinimum && cartMaximum)
        ) {
            clearErrors(FIELD_NAMES.CART_MINIMUM);
            clearErrors(FIELD_NAMES.CART_MAXIMUM);
        }
    }, [cartMinimum, cartMaximum]);

    return (
        <Container>
            <LabelContainer>
                <FormFieldName name={"Cart Size"} />
            </LabelContainer>
            <InputContainer>
                <FormNumberInput
                    inputLabel="Minimum"
                    fieldName={FIELD_NAMES.CART_MINIMUM}
                    format="dollar"
                    min={0}
                />
                <FormNumberInput
                    inputLabel="Maximum"
                    fieldName={FIELD_NAMES.CART_MAXIMUM}
                    format="dollar"
                    min={0}
                />
            </InputContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    @media ${ScreenState.MOBILE} {
        margin-bottom: 8px;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    @media ${ScreenState.MOBILE} {
        width: 100%;
    }
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    @media ${ScreenState.MOBILE} {
        flex-direction: column;
        gap: 0;
    }
`;
