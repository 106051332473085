/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { css } from "@emotion/react";
import { ProductIngredientT } from "@snackpass/snackpass-types/src/v1";
import { isObject } from "lodash";
import { useSelector } from "react-redux";
import { Col, Form, InputNumber, Modal, Tooltip } from "antd";
import { toast } from "sonner";

import DropDownSelect from "#reusable/select/dropdown";
import { DropdownOption } from "#core";
import { getCombinedIngredientsWithInventory } from "src/redux/selectors";
import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import colors from "#reusable/colors/colors.json";
import { RootState } from "src/redux/store";
import { InventoryItem } from "src/redux/slices";

type IngredientsProps = {
    onHide: () => void;
    handleOk: () => void;
    isIngredientsModalOpen: boolean;
    inventoryItems: DropdownOption<InventoryItem>[];
    addOnIngredients: ProductIngredientT[];
    handleAddIngredients: (value: DropdownOption<InventoryItem>) => void;
    handleUpdateIngredients: (ingredientId: string, newPerUnit: number) => void;
    handleRemoveIngredients: (ingredientId: string) => void;
};

const IngredientsModal: React.FC<IngredientsProps> = ({
    onHide,
    handleOk,
    isIngredientsModalOpen,
    inventoryItems,
    addOnIngredients,
    handleAddIngredients,
    handleUpdateIngredients,
    handleRemoveIngredients,
}) => {
    const ingredientInventoryItems = useSelector((state: RootState) =>
        getCombinedIngredientsWithInventory(state, addOnIngredients ?? []),
    );

    const onFinish = () => {
        onHide();
    };

    const onFinishFailed = (errorInfo: any) => {
        toast.error("Failed: " + errorInfo.toString());
    };

    return (
        <>
            <Modal
                centered
                css={ModalCSS}
                visible={isIngredientsModalOpen}
                onOk={handleOk}
                onCancel={() => {
                    onHide();
                }}
            >
                <HeaderRow>
                    <span className="header-text" onClick={onHide}>
                        Ingredients
                    </span>
                </HeaderRow>
                <Form
                    layout="horizontal"
                    name="IngredientsForm"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="ingredients"
                        label="Ingredients"
                        className="ingredients-select"
                    >
                        <DropDownSelect
                            square
                            options={inventoryItems}
                            value={null}
                            onChange={handleAddIngredients}
                            placeholder="Ingredient"
                            fontSize="16"
                            fontWeight="500"
                        ></DropDownSelect>
                    </Form.Item>
                </Form>
                {addOnIngredients.map(
                    (ingredient: ProductIngredientT, i: number) => (
                        <InventoryItemRow
                            key={i}
                            inventoryItem={ingredientInventoryItems[i][0]}
                            ingredient={ingredient}
                            handleUpdateIngredients={handleUpdateIngredients}
                            handleRemoveIngredients={handleRemoveIngredients}
                        />
                    ),
                )}
            </Modal>
        </>
    );
};

type InventoryItemRowProp = {
    inventoryItem: InventoryItem;
    ingredient: ProductIngredientT;
    handleUpdateIngredients: (ingredientId: string, newPerUnit: number) => void;
    handleRemoveIngredients: (ingredientId: string) => void;
};

const InventoryItemRow: React.FC<InventoryItemRowProp> = ({
    inventoryItem,
    ingredient,
    handleUpdateIngredients,
    handleRemoveIngredients,
}) => {
    const [perUnit, setPerUnit] = useState<any>();

    useEffect(() => {
        setPerUnit(
            isObject(ingredient.unitsConsumed.amount)
                ? ingredient.unitsConsumed.amount["$numberDecimal"]
                : ingredient.unitsConsumed.amount,
        );
    }, []);
    return (
        <Row className="ingredientsRow">
            <Col span={7} className="ingredientName ingredientWrapper">
                {inventoryItem.name}:
            </Col>

            <Col span={5} className="ingredientWrapper">
                <InputNumber
                    className="input-number quantity"
                    formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={perUnit}
                    bordered={false}
                    min={0}
                    onChange={(e) => {
                        setPerUnit(e);
                    }}
                    onBlur={(e: React.FocusEvent<HTMLElement>) => {
                        const val = (e.target as HTMLInputElement).value;
                        handleUpdateIngredients(inventoryItem.id, Number(val));
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur();
                            const val = (e.target as HTMLInputElement).value;

                            handleUpdateIngredients(
                                inventoryItem.id,
                                Number(val),
                            );
                        }
                    }}
                />
            </Col>
            <Col span={8} className="ingredientWrapper">
                <span className="baseUnit">{inventoryItem.baseUnit}</span>
            </Col>
            <Col
                span={1}
                onClick={() => {
                    handleRemoveIngredients(inventoryItem.id);
                }}
            >
                <span className="iconWrapper">
                    <Tooltip title="Delete">
                        <Delete className="delete-icon" />
                    </Tooltip>
                </span>
            </Col>
        </Row>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    font-size: 16px;
    align-items: center;
    justify-content: center;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
    .ant-col.ant-form-item-label {
        padding-bottom: 1px;
        padding-top: 1px;
    }

    .ant-row.ant-form-item {
        margin: 5px;
    }

    .input-number {
        border-radius: 8px;
    }

    input {
        border-radius: 8px;
    }

    .iconWrapper {
        display: flex;
        margin-left: 0.1rem;
        border: 1px solid ${colors["neutral-400"]};
        height: 55px;
        width: 36px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }

    .baseUnit::before {
        font-family: "Inter";
        content: "Unit:";
        position: absolute;
        top: 3px;
        left: 6px;
        font-size: 12px;
        color: rgba(96, 108, 118, 1);
    }

    .quantity::before {
        font-family: "Inter";
        content: "Quantity:";
        position: absolute;
        top: -22px;
        left: 1px;
        font-size: 12px;
        color: rgba(96, 108, 118, 1);
    }

    .ingredientName::before {
        font-family: "Inter";
        content: "Ingredient:";
        position: absolute;
        top: 3px;
        left: 6px;
        font-size: 12px;
        color: rgba(96, 108, 118, 1);
    }

    .ingredientWrapper {
        border-radius: 8px;
        border: 1px solid rgba(221, 229, 238, 1);
        padding-top: 25px;
        padding-left: 4px;
        margin-right: 0.2rem;
    }

    .ingredientName {
        margin-left: 1%;
        inline-size: 115px;
        overflow-wrap: break-word;
    }

    .ingredientsRow {
        padding: 1rem 0px;
        border-bottom: 0.2px solid ${colors["neutral-400"]};
        align-items: center;
    }

    .delete-icon {
        height: 1rem;
        width: 1rem;
        align-self: center;
        cursor: pointer;
    }

    .delete-icon path {
        fill: #ff3929;
    }
`;

const HeaderRow = styled.div`
    margin-bottom: 1rem;
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-bottom: 4px;
    .description {
        margin-top: 5px;
        margin-bottom: 1rem;
        color: #a0adba;
        font-size: 12px;
    }
`;

export default IngredientsModal;
