/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { useExpanded, useTable } from "react-table-7";
import { useHistory, useRouteMatch } from "react-router-dom";
import { css } from "@emotion/react";
import { getActiveStore } from "@snackpass/accounting";
import styled from "styled-components";
import classNames from "classnames";

import { Row, Space, Spin, Col } from "antd";
import { isNotUndefined } from "#core";
import {
    FlexRow,
    ScreenLayout,
} from "#menu-editor/multi-menus/styled-components/layout";
import { ScreenHeader } from "#menu-editor/multi-menus/screens/select-items/components/header";
import { SelectItemsMenuTable } from "#menu-editor/multi-menus/screens/select-items/components/table";

import { PriceText } from "#menu-editor/multi-menus/shared-components/price-text";
import { TaxesText } from "#menu-editor/multi-menus/shared-components/taxes-text";
import { useDebouncedSearch } from "#menu-editor/multi-menus/hooks";
import { TableSearchBar } from "#menu-editor/multi-menus/shared-components/table-search-bar";

import { useMediaQuery } from "react-responsive";

import constants from "#core/constants";
import { MobileHeader } from "#menu-editor/multi-menus/shared-components/mobile-header";
import { ReactComponent as SelectAllSVG } from "src/assets/icons/select-all.svg";
import { RoundButton } from "#menu-editor/multi-menus/styled-components/button";

import { ScreenState } from "@snackpass/snackpass-types";

import {
    TextStyles,
    Text,
} from "#menu-editor/multi-menus/styled-components/text";
import {
    isAnyCategorySelected,
    allChildrenSelectedCategories,
    getMultiMenuLoadingState,
    getCurrentMultiMenu,
    getAllEligibleMenuCategories,
} from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import {
    CellProps,
    extractTaxPolicies,
    getRowId,
    getSubRows,
    HeaderProps,
    isAddon,
    isAddonGroup,
    isCategory,
    isProduct,
    selectItemsSearchFunction,
} from "#menu-editor/multi-menus/helpers";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import { ScreenFooter } from "#menu-editor/multi-menus/shared-components/footer";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";
import { useAppDispatch } from "src/redux/hooks";
import {
    openNotification,
    NotificationPosition,
} from "#menu-editor/multi-menus/shared-components/notification";
import { logAndSendError } from "src/utils/errors";

import { SelectItem, selectItemFactory } from "./components";

export default function EditMultiMenus() {
    const dispatch = useAppDispatch();
    const {
        params: { id: menuId },
    } = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });

    const allActiveProductCategories = useSelector(
        getAllEligibleMenuCategories,
    ) as IProductCategoryWithProducts[];
    const activeStore = useSelector(getActiveStore);
    const loadingStateFetchStoreMenus = useSelector(
        getMultiMenuLoadingState("FetchStoreMenus"),
    );
    const loadingStateUpdateMultiMenu = useSelector(
        getMultiMenuLoadingState("UpdateMultiMenu"),
    );
    const currentMenu = useSelector(getCurrentMultiMenu);

    const searchFn = useCallback(
        (globalFilter: string) =>
            selectItemsSearchFunction(allActiveProductCategories, globalFilter),
        [allActiveProductCategories],
    );

    const {
        data: activeProductCategories,
        globalFilter,
        setGlobalFilter: debouncedSetGlobalFilter,
    } = useDebouncedSearch(searchFn, 200);

    const onSubmit = async () => {
        dispatch(
            multiMenuThunks.persistCurrentMenu(["categories", "menuOverrides"]),
        )
            .unwrap()
            .then(() => history.push(`/multi-menus/${menuId}`))
            .catch((err) => {
                logAndSendError(err);
                openNotification({
                    message: "Failed to update menu",
                    description:
                        "Failed to update current menu, please try again later.",
                    position: NotificationPosition.TopCenter,
                });
            });
    };

    const onCancel = async () => {
        history.push(`/multi-menus/${menuId}`);
    };

    useEffect(() => {
        const selectMenu = async () => {
            dispatch(
                multiMenuThunks.selectMultiMenu({
                    menuId,
                    onMenuNotFound: () => history.replace("/multi-menus"),
                }),
            )
                .unwrap()
                .catch((err) => {
                    logAndSendError(err);
                    openNotification({
                        message: "Failed to fetch current menu",
                        description:
                            "Failed to fetch current menu, please try again later.",
                        position: NotificationPosition.TopCenter,
                    });
                });
        };

        if (loadingStateFetchStoreMenus === "succeeded") void selectMenu();
        else if (loadingStateFetchStoreMenus === "failed")
            openNotification({
                message: "Failed to fetch store menus",
                description:
                    "Failed to fetch all store menus, please try again later.",
                position: NotificationPosition.TopCenter,
            });
        return () => void dispatch(multiMenuActions.resetCurrentMenu());
    }, [menuId, loadingStateFetchStoreMenus]);

    const columns = React.useMemo(
        () =>
            [
                {
                    id: "item",
                    Header: ({
                        getToggleAllRowsExpandedProps,
                        isAllRowsExpanded,
                    }: HeaderProps) => (
                        <SelectItemWrapper depth={0} isMobile={isMobile}>
                            <SelectItem
                                isSelectedSelector={isAnyCategorySelected(
                                    activeProductCategories.map(
                                        ({ _id }) => _id,
                                    ),
                                )}
                                allChildrenSelectedSelector={allChildrenSelectedCategories(
                                    activeProductCategories,
                                )}
                                discardAction={multiMenuThunks.discardAll}
                                selectAction={multiMenuThunks.selectAll}
                                getActionArgs={() => ({
                                    productCategories: activeProductCategories,
                                })}
                            >
                                <FlexRow
                                    style={{ gap: "8px", alignItems: "center" }}
                                >
                                    <Text type="body-bold">Items</Text>
                                    <span
                                        {...getToggleAllRowsExpandedProps({
                                            className: classNames({
                                                isNotExpanded:
                                                    !isAllRowsExpanded,
                                            }),
                                        })}
                                    >
                                        <ChevronDown className="chevron-down" />
                                    </span>
                                </FlexRow>
                            </SelectItem>
                        </SelectItemWrapper>
                    ),
                    Cell: ({ row, rowsById }: CellProps) => (
                        <SelectItemWrapper
                            isMobile={isMobile}
                            depth={row.depth}
                            className={classNames({
                                categoryRow: isCategory(row.original),
                            })}
                        >
                            {selectItemFactory({ row, rowsById, isMobile })}
                        </SelectItemWrapper>
                    ),
                },
                {
                    id: "Price Override",
                    with: 1,
                    Header: () => (
                        <TableHeaderWrapper>Price Override</TableHeaderWrapper>
                    ),
                    Cell: ({ row: { original: originalRow } }: CellProps) => {
                        if (!(isProduct(originalRow) || isAddon(originalRow)))
                            return null;
                        return (
                            <Row justify="end">
                                <PriceText
                                    itemId={originalRow._id}
                                    itemPrice={originalRow.price}
                                    itemType={
                                        isProduct(originalRow)
                                            ? "products"
                                            : "addons"
                                    }
                                />
                            </Row>
                        );
                    },
                },
                {
                    id: "Tax Override",
                    with: 1,
                    Header: () => (
                        <TableHeaderWrapper>Tax Override</TableHeaderWrapper>
                    ),
                    Cell: ({ row: { original: originalRow } }: CellProps) => {
                        if (isAddonGroup(originalRow)) {
                            const numOptions = originalRow.addons.length;
                            return (
                                <Row justify="end" align="middle">
                                    {originalRow.required ? (
                                        <Text
                                            type="body-regular"
                                            color="white"
                                            style={styles.requiredText}
                                        >
                                            Required
                                        </Text>
                                    ) : null}
                                    <Text type="body-regular">{`${numOptions} ${
                                        numOptions > 1 ? "Options" : "Option"
                                    }`}</Text>
                                </Row>
                            );
                        }
                        if (!isProduct(originalRow)) return null;
                        const productBaseTaxes = !isNotUndefined(
                            originalRow.taxInfo,
                        )
                            ? activeStore?.taxRate ?? 0
                            : originalRow.taxInfo.rate;
                        const taxPolicies = extractTaxPolicies(originalRow);

                        return (
                            <Row justify="end">
                                <TaxesText
                                    productId={originalRow._id}
                                    productBaseTaxes={productBaseTaxes}
                                    {...taxPolicies}
                                />
                            </Row>
                        );
                    },
                },
                {
                    // Build our expander column
                    id: "chevron", // Make sure it has an ID
                    Cell: ({ row }: CellProps) => {
                        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                        // to build the toggle for expanding a row
                        const { isExpanded } = row;
                        const isNotExpanded = !isExpanded;

                        return row.canExpand ? (
                            <Row
                                align="middle"
                                justify="end"
                                gutter={8}
                                style={!isMobile ? styles.lastCellPadding : {}}
                            >
                                {(isMobile && isAddonGroup(row.original)) ||
                                isCategory(row.original) ? (
                                    <Col style={styles.subItemsCol}>
                                        <Text
                                            type="body-regular"
                                            style={{
                                                marginLeft: "auto",
                                            }}
                                        >{`${
                                            isAddonGroup(row.original)
                                                ? row.original.addons.length
                                                : row.original.products.length
                                        }`}</Text>
                                    </Col>
                                ) : null}
                                <Col>
                                    <span
                                        {...row.getToggleRowExpandedProps({
                                            className: classNames({
                                                isNotExpanded,
                                            }),
                                        })}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <ChevronDown className="chevron-down" />
                                    </span>
                                </Col>
                            </Row>
                        ) : null;
                    },
                },
            ].filter(
                ({ id }: { id: string }) =>
                    !isMobile ||
                    !["Tax Override", "Price Override"].includes(id),
            ),
        [activeProductCategories, isMobile],
    );

    const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
        useTable(
            {
                // @ts-expect-error this table is currently untyped
                columns,
                data: activeProductCategories,
                getRowId,
                getSubRows,
                //@ts-ignore
                autoResetExpanded: false,
            },
            useExpanded,
        );

    useEffect(() => {
        (rows as any[]).forEach(
            ({ original, toggleRowExpanded }) =>
                isCategory(original) &&
                toggleRowExpanded(
                    activeProductCategories.length !==
                        allActiveProductCategories.length,
                ),
        );
    }, [activeProductCategories.length, allActiveProductCategories.length]);

    const onSelectAll = () =>
        void dispatch(
            multiMenuThunks.selectAll({
                productCategories: allActiveProductCategories,
            }),
        );

    return (
        <ScreenLayout
            header={
                !isMobile ? (
                    <ScreenHeader selectAll={onSelectAll} onSubmit={onSubmit} />
                ) : (
                    <MobileHeader
                        title="Select Items"
                        right={
                            <RoundButton onClick={onSelectAll}>
                                <SelectAllSVG />
                            </RoundButton>
                        }
                    />
                )
            }
            content={
                <div css={bodyStyle}>
                    <Spin
                        spinning={loadingStateUpdateMultiMenu === "pending"}
                        tip="Please wait..."
                    >
                        <Space
                            size={"middle"}
                            direction="vertical"
                            style={commonStyles.displayFlex}
                        >
                            {!isMobile ? (
                                <TableSearchBar
                                    debouncedSetGlobalFilter={
                                        debouncedSetGlobalFilter
                                    }
                                    globalFilter={globalFilter}
                                />
                            ) : null}
                            <SelectItemsMenuTable
                                getTableBodyProps={getTableBodyProps}
                                getTableProps={getTableProps}
                                headerGroups={headerGroups}
                                prepareRow={prepareRow}
                                rows={rows}
                            />
                        </Space>
                    </Spin>
                </div>
            }
            footer={
                <ScreenFooter
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    disabled={loadingStateUpdateMultiMenu === "pending"}
                />
            }
            breadcrumbItems={
                !isMobile
                    ? [
                          {
                              label: "Items",
                              to: "/mobile-friendly-menu-editor",
                          },
                          { label: "Menus", to: "/multi-menus" },
                          {
                              label: currentMenu.name as string,
                              to: `/multi-menus/${currentMenu.id}`,
                          },
                          {
                              label: "Select Items",
                              to: `/multi-menus-edit/${menuId}`,
                          },
                      ]
                    : []
            }
            isLoading={loadingStateFetchStoreMenus === "pending"}
        />
    );
}

const borderStyles = "1px solid #e1e3e6";

const bodyStyle = css`
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 24px 0px;

    table {
        width: 100%;
    }

    td:nth-child(1) {
        width: 55%;
    }

    td:nth-child(2) {
        width: 15%;
    }

    td:nth-child(3) {
        width: 22.5%;
    }

    @media ${ScreenState.MOBILE} {
        td:nth-child(1) {
            width: 87.5%;
        }
    }

    th {
        padding: 16px 0 8px 0;
        border-bottom: ${borderStyles};
    }

    td {
        height: 56px;
        padding: 0;
        border-bottom: ${borderStyles};
        border-top: ${borderStyles};
    }

    .chevron-down {
        transition: 0.3s;
    }

    .categoryRow {
        height: 80px;
    }

    .isNotExpanded .chevron-down {
        transform: rotate(-0.25turn);
    }
`;

const TableHeaderWrapper = styled.div`
    ${TextStyles({ type: "body-bold" })};
    display: flex;
    justify-content: flex-end;
`;

const SelectItemWrapper = styled(FlexRow)<{ depth: number; isMobile: boolean }>`
    padding-left: ${({ depth, isMobile }) =>
        !isMobile ? `${depth * 48}px` : `${depth * 16}px`};
    align-items: center;
`;

const styles = {
    requiredText: {
        backgroundColor: "#181B1E",
        padding: "4px 12px",
        borderRadius: "56px",
        marginRight: "8px",
    } as CSSProperties,
    subItemsCol: {
        display: "flex",
    } as CSSProperties,
    lastCellPadding: { paddingRight: "20px" } as CSSProperties,
};
