import React, { useEffect, useState } from "react";
import styled from "styled-components";

const VisuallyHiddenWrapper = styled.span`
    display: inline-block;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
`;

type VisuallyHiddenProps = {
    children: React.ReactNode;
    id?: string;
};

/** Use VisuallyHidden to add text next to an icon but hide it visually, but keep it there for screen readers */
const VisuallyHidden = ({
    children,
    ...delegated
}: VisuallyHiddenProps): JSX.Element => {
    const [forceShow, setForceShow] = useState(false);

    useEffect(() => {
        // If you hold down the Alt key (⌥), the typically-hidden text springs into life, letting you verify that you haven't missed anything
        if (process.env.NODE_ENV !== "production") {
            const handleKeyDown = (ev: KeyboardEvent) => {
                if (ev.key === "Alt") {
                    setForceShow(true);
                }
            };
            const handleKeyUp = (ev: KeyboardEvent) => {
                if (ev.key === "Alt") {
                    setForceShow(false);
                }
            };
            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("keyup", handleKeyUp);
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
                window.removeEventListener("keyup", handleKeyUp);
            };
        }
    }, []);

    if (forceShow) {
        return <>{children}</>;
    }

    return (
        <VisuallyHiddenWrapper {...delegated}>{children}</VisuallyHiddenWrapper>
    );
};
export default VisuallyHidden;
