import React from "react";
import styled from "styled-components";

type IconButtonProps = {
    variant: "primary" | "secondary" | "tertiary";
    size: "large" | "regular" | "small" | "micro";
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledIconButton = styled.button<IconButtonProps>`
    ${({ theme, variant, size }) => `
      appearance: button;
      cursor: pointer;
      box-shadow: ${theme.buttons.defaultBoxShadow};
      background-color: ${theme.buttons.variant[variant].backgroundColor};
      border: none;
      color: ${theme.buttons.variant[variant].color};
      border-radius: ${theme.iconButtons.sizes[size]};
      width: ${theme.iconButtons.sizes[size]};
      height: ${theme.iconButtons.sizes[size]};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all .2s ease-in-out;
      margin-left: ${theme.spacing.half};
      

      &:hover {
        box-shadow: ${theme.buttons.hoverBoxShadow};
      }

      &:disabled {
        cursor: not-allowed;
        touch-events: none;
        background-color: ${theme.buttons.variant[variant].inactiveBackgroundColor};
        border-color: ${theme.buttons.variant[variant].inactiveBorderColor};
        color: ${theme.buttons.variant[variant].inactiveColor};
      }

      & + button {
        margin-left: ${theme.spacing.base};
        padding-top: 0;
        padding-bottom: 0;
      }
       
      & svg {
        fill: currentColor;
      }
  `}
`;

const IconButton = (props: IconButtonProps): JSX.Element => (
    <StyledIconButton aria-labelledby="button-label" {...props}>
        {props.children}
    </StyledIconButton>
);

export default IconButton;
