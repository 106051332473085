import React, { useContext } from "react";

import Select from "#devices/components/FormControls/Select";
import {
    ChannelDisplay,
    ChannelDisplayGroup,
    channelDisplayOptions,
    ChannelType,
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

type EditChannelTypeProps = {
    disabled?: boolean;
};

const getDefaultDisplayOption = (channel: ChannelDisplay) => {
    switch (channel) {
        case ChannelDisplay.CustomerPickupScreen:
            return ChannelType.CustomerReadyScreen;
        case ChannelDisplay.MenuBoard:
            return ChannelType.InfiniteScrollImage;
        case ChannelDisplay.GalleryBoard:
            return ChannelType.FullScreenGallery;
        case ChannelDisplay.Other:
            return ChannelType.StaticImage;
    }
};

export const EditChannelDisplay = ({ disabled }: EditChannelTypeProps) => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    return (
        <Select
            id="channelDisplay"
            label="Board Type"
            value={ChannelDisplayGroup[channel]}
            disabled={disabled}
            options={channelDisplayOptions}
            onChange={(e) => {
                updateDeviceField(
                    "channel",
                    getDefaultDisplayOption(e.target.value as ChannelDisplay),
                );
            }}
        />
    );
};
