import { Addon, IProduct } from "@snackpass/snackpass-types";

import {
    StyledTextField,
    textFieldProps,
} from "#menu-editor/multi-menus/screens/edit-overrides-mobile/components/text-field";
import { PriceOverrideInput } from "#menu-editor/multi-menus/shared-components/price-override-input";

interface PriceOverrideMobileInputProps {
    item: IProduct | Addon;
}

export const PriceOverrideMobileInput = ({
    item,
}: PriceOverrideMobileInputProps) => (
    <PriceOverrideInput
        item={item}
        inputComponent={StyledTextField}
        inputComponentProps={{
            label: "Price",
            ...textFieldProps,
        }}
    />
);
