import React from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import Text from "#devices/components/Text";
import { ReactComponent as Failure } from "src/assets/icons/failure.svg";
import { ParsedDeviceEvent } from "#devices/utils/deviceTypes/PrinterDevice";

type DeviceLogProps = {
    logItems: ParsedDeviceEvent[];
};

const DeviceLogListWrapper = styled.div`
    overflow-y: auto;
    padding: ${({ theme }) =>
        `${theme.spacing.double} ${theme.spacing.quarter}`};
    // remove default padding from DetailsSection so shadow is at the bottom border
    margin-bottom: -${({ theme }) => theme.spacing.double};
`;

const DeviceLogList = styled.ol`
    padding-left: ${({ theme }) => theme.spacing.base};
    position: relative;
    list-style-type: none;

    &:before {
        position: absolute;
        inset: -${({ theme }) => theme.spacing.half} 0;
        content: "";
        border-left: 2px solid ${SystemColors.v2.salt80.light};
    }
`;

const ListItem = styled.li`
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.base};

    &:before {
        position: absolute;
        content: "";
        border: 1px solid ${SystemColors.v2.salt80.light};
        left: -19px; // magic number to align properly over the DeviceLogList:before border :(
        width: ${({ theme }) => theme.spacing.half};
        height: ${({ theme }) => theme.spacing.half};
        border-radius: ${({ theme }) => theme.spacing.half};
        top: ${({ theme }) => theme.spacing.half};
        background: white;
        box-shadow: 0px 0px 0px 2px white;
    }
`;

// Arbitrary value that can be updated in the future
const MAX_EVENTS = 10;

const DeviceLog = ({ logItems }: DeviceLogProps): JSX.Element => {
    if (!logItems || logItems.length < 1) {
        return <p className="mb-4">No device logs currently available.</p>;
    }

    return (
        <DeviceLogListWrapper>
            <DeviceLogList>
                {logItems.slice(0, MAX_EVENTS).map((item) => (
                    <ListItem key={item.id}>
                        <CommandWrapper>
                            {item.status.toLowerCase() === "failure" && (
                                <IconWrapper>
                                    <Failure />
                                </IconWrapper>
                            )}
                            <Text.Label>{item.title}</Text.Label>
                        </CommandWrapper>
                        <Text.Small>
                            <>{item.date}</>
                        </Text.Small>
                        {/* <Text.Small>{item.user}</Text.Small> */}
                    </ListItem>
                ))}
            </DeviceLogList>
        </DeviceLogListWrapper>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
`;

const CommandWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export default DeviceLog;
