import { Link } from "react-router-dom";
import { LucideIcon, TrendingUp, Loader2 } from "lucide-react";

import { Card, CardContent } from "src/@/components/ui/card";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { ComparisonPeriod, CurrentPeriod } from "#dashboard/types";

interface MetricCardProps {
    title: string;
    value: string;
    changeValue?: number;
    icon: LucideIcon;
    isLoading?: boolean;
    to?: string;
}

const MetricChangeText = ({
    value,
    invisible,
}: {
    value: number;
    invisible?: boolean;
}) => {
    const { currentPeriod, comparisonPeriod } = useDashboardContext();
    const isPositive = value > 0;

    const getPeriodText = () => {
        if (comparisonPeriod) {
            switch (comparisonPeriod) {
                case ComparisonPeriod.PREVIOUS_DAY:
                    return "from previous day";
                case ComparisonPeriod.PREVIOUS_7_DAYS:
                    return "from previous 7 days";
                case ComparisonPeriod.PREVIOUS_30_DAYS:
                    return "from previous 30 days";
                default:
                    return "from previous period";
            }
        }

        // Fallback to duration-based text if no comparison is selected
        switch (currentPeriod) {
            case CurrentPeriod.LAST7DAYS:
                return "from last week";
            case CurrentPeriod.LAST30DAYS:
                return "from last month";
            case CurrentPeriod.LAST90DAYS:
                return "from last quarter";
            case CurrentPeriod.LAST365DAYS:
                return "from last year";
            case CurrentPeriod.TODAY:
                return "from yesterday";
            case CurrentPeriod.YESTERDAY:
                return "from previous day";
            case CurrentPeriod.CUSTOM:
                return "from previous period";
            default:
                return "from previous period";
        }
    };

    return (
        <p
            className={`flex items-center gap-1 text-xs text-muted-foreground ${invisible ? "invisible" : ""}`}
        >
            {isPositive ? "+" : ""}
            {value}% {getPeriodText()}{" "}
            {isPositive && <TrendingUp className="h-3 w-3 text-emerald-500" />}
        </p>
    );
};

const MetricCardContent = ({
    title,
    value,
    changeValue,
    icon: Icon,
    isLoading = false,
}: Omit<MetricCardProps, "to">) => (
    <CardContent className="p-6">
        <div className="flex items-start justify-between">
            <div className="space-y-1">
                <h3 className="text-sm font-medium text-muted-foreground">
                    {title}
                </h3>
                {isLoading ? (
                    <div className="flex items-center space-x-2">
                        <Loader2 className="h-4 w-4 animate-spin" />
                    </div>
                ) : (
                    <>
                        <div className="text-2xl font-bold">{value}</div>
                        {changeValue !== undefined && changeValue !== null ? (
                            <MetricChangeText value={changeValue} />
                        ) : (
                            <MetricChangeText value={10} invisible />
                        )}
                    </>
                )}
            </div>
            <Icon className="h-4 w-4 text-muted-foreground" />
        </div>
    </CardContent>
);

export function MetricCard(props: MetricCardProps) {
    const { to, ...contentProps } = props;

    if (to) {
        return (
            <Link to={to} className="block no-underline">
                <Card className="cursor-pointer transition-shadow duration-200 hover:shadow-md">
                    <MetricCardContent {...contentProps} />
                </Card>
            </Link>
        );
    }

    return (
        <Card>
            <MetricCardContent {...contentProps} />
        </Card>
    );
}
