import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { css } from "@emotion/css";

// Icons
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { isUndefined } from "lodash";
import styled from "@emotion/styled";
import { compose } from "lodash/fp";

import {
    GetStoreAudienceQuery,
    GetStoreAudienceResponse,
} from "src/api/graphql/queries";
import { getActiveStore } from "src/redux/selectors";
import { CampaignAudienceType } from "src/api/graphql/generated/types";
import AlertIcon from "src/assets/icons/alert-white.png";
import SparkleIcon from "src/assets/icons/sparkle-white.png";
import HeartIcon from "src/assets/icons/heart-white.png";

import { setMessageInputAudience } from "../../redux";

type TargetAudienceProps = {
    onSelectAudience: () => void;
};

export const TargetAudience = ({ onSelectAudience }: TargetAudienceProps) => {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const _setAudience = compose(dispatch, setMessageInputAudience);

    const { data, refetch } = useQuery<GetStoreAudienceResponse>(
        GetStoreAudienceQuery,
        {
            variables: { storeId: activeStore?._id },
            skip: !activeStore,
        },
    );

    const onSelect = React.useCallback(
        (label: string, audienceType: any, numberOfUsers: number) => () => {
            _setAudience({
                label,
                type: audienceType,
                numberOfUsers,
            });
            onSelectAudience();
        },
        [],
    );

    React.useEffect(() => {
        window.addEventListener("focus", (...params) => {
            void refetch(...params);
        });

        return () =>
            window.removeEventListener("focus", (...params) => {
                void refetch(...params);
            });
    }, []);

    const audience = data?.storeAudience;

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            {shouldRenderSegment(audience?.followers) && (
                <SuggestedAudience
                    label="Followers"
                    subtext={`${audience?.followers} customers`}
                    icon={HeartIcon}
                    color={SystemColors.v1.snackpass60}
                    onClick={onSelect(
                        `${audience?.followers} followers`,
                        CampaignAudienceType.Followers,
                        audience?.followers || 0,
                    )}
                />
            )}

            {shouldRenderSegment(audience?.newCustomers) && (
                <SuggestedAudience
                    label="New Customers"
                    subtext={`${audience?.newCustomers} customers today`}
                    icon={SparkleIcon}
                    color={SystemColors.v1.matcha40}
                    onClick={onSelect(
                        `${audience?.newCustomers} recent customers`,
                        CampaignAudienceType.NewCustomers,
                        audience?.newCustomers || 0,
                    )}
                />
            )}

            {shouldRenderSegment(audience?.needAttentionCustomers) && (
                <SuggestedAudience
                    label="Needs Attention"
                    subtext={`${audience?.needAttentionCustomers} customers`}
                    icon={AlertIcon}
                    color={SystemColors.v1.melon60}
                    onClick={onSelect(
                        `${audience?.needAttentionCustomers} need attention`,
                        CampaignAudienceType.NeedsAttention,
                        audience?.needAttentionCustomers || 0,
                    )}
                />
            )}
        </div>
    );
};

const shouldRenderSegment = (val: any) => !isUndefined(val) && val > 0;

type SuggestedAudienceProps = {
    label: string;
    subtext: string;
    icon: any;
    color: string;
    onClick: () => void;
};

const SuggestedAudience = ({
    label,
    subtext,
    icon,
    color,
    onClick,
}: SuggestedAudienceProps) => (
    <div onClick={onClick} className={audienceContainerStyle}>
        <SuggestedAudienceImageContainer
            style={{
                backgroundColor: color,
            }}
        >
            <img style={{ width: 20, objectFit: "cover" }} src={icon} />
        </SuggestedAudienceImageContainer>
        <div style={{ flex: 1 }}>
            <SuggestedAudienceLabel>{label}</SuggestedAudienceLabel>
            <div style={{ color: SystemColors.v1.gray40 }}>{subtext}</div>
        </div>
    </div>
);

const audienceContainerStyle = css`
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    align-item: center;
    &:hover {
        background-color: ${SystemColors.v1.gray90};
    }
`;

const SuggestedAudienceImageContainer = styled.div`
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    text-align: center;
    margin-right: 10px;
`;

const SuggestedAudienceLabel = styled.div`
    font-weight: bold;
    font-size: 16px;
    color: ${SystemColors.v1.black};
`;
