import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { IProduct } from "@snackpass/snackpass-types";
import styled from "@emotion/styled";

import BarsBlack from "src/assets/icons/bars-filled-black.png";
import { Title } from "#guestbook/components/styled-components";

import { ProductRow } from "./product-row";
import { useProductRewards } from "./lib";

type OnSelect = (product: IProduct) => void;

export const PopularItems = ({ onSelect }: { onSelect: OnSelect }) => {
    const { topProducts } = useProductRewards();

    return (
        <div style={{ marginTop: 25 }}>
            <Title icon={BarsBlack} label="Popular Products" />
            <TopProductsContainer>
                {topProducts.map((p: IProduct, index: number) => (
                    <ProductRow
                        index={index}
                        onClick={onSelect}
                        product={p}
                        key={p._id}
                    />
                ))}
            </TopProductsContainer>
        </div>
    );
};

const TopProductsContainer = styled.div`
    padding: 0 15px;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
`;
