import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { debounce } from "radash";
import { useCallback, useEffect } from "react";
import { TrashIcon } from "@radix-ui/react-icons";

import { Element as Variant } from "src/api/graphql/generated/types";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { LabelProperty } from "#table-editor/types";
import { Input } from "src/@/components/ui/input";
import { Button } from "src/@/components/ui/button";

type ModifyLabelProps = {
    id: string;
    properties: LabelProperty;
};

const modifyLabelSchema = z.object({
    label: z.string().min(1, "Label cannot be empty"),
});

export function ModifyLabel({ id, properties }: ModifyLabelProps) {
    const editor = useEditorStore();

    const { formState, watch, register, setValue } = useForm({
        resolver: zodResolver(modifyLabelSchema),
        values: {
            label: properties.label,
        },
        mode: "onChange",
    });

    const updateLabel = debounce({ delay: 100 }, (label?: string) => {
        editor.getState().setLabelData(id, {
            type: Variant.Label,
            label: label ?? "",
        });
    });

    useEffect(
        () =>
            watch((value) => {
                updateLabel(value.label);
            }).unsubscribe,
        [watch, updateLabel],
    );

    useEffect(() => {
        setValue("label", properties.label);
    }, [setValue, properties]);

    const onClickDelete = useCallback(() => {
        editor.getState().removeSelected(id);
    }, [editor, id]);

    return (
        <div className="flex w-80 flex-col gap-3 p-3">
            <h4 className="text-sm font-medium text-neutral-600">Edit Label</h4>
            <Input
                {...register("label")}
                placeholder="Label"
                className="w-full"
            />
            {formState.errors.label ? (
                <p className="text-base text-critical-a11y-light">
                    {formState.errors.label.message}
                </p>
            ) : null}

            <Button
                type="button"
                variant="destructive"
                onClick={onClickDelete}
                className="gap-2"
            >
                <TrashIcon className="h-4 w-4" />
                <span className="select-none">Delete</span>
            </Button>
        </div>
    );
}
