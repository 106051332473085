import React, { useContext } from "react";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import useWindowDimensions from "#hooks/use-window-dimensions";

export const DeleteSection = () => {
    const { setOpenDeleteModal } = useContext(DevicesPageContext);

    const { isMobile } = useWindowDimensions();
    const description = isMobile
        ? "Permanently remove device"
        : "Permanently remove the device from your store";
    return (
        <SettingsSection label="Delete Device" description={description}>
            <Button
                variant="outline-destructive"
                onClick={() => setOpenDeleteModal(true)}
            >
                Delete
            </Button>
        </SettingsSection>
    );
};
