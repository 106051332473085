import styled from "@emotion/styled";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FormTextInput } from "#promotion/components/shared/form-text-input";
import { FIELD_NAMES } from "#promotion/utils/types";

type Props = {
    placeholder?: string;
    descriptor?: string;
};

export const PromoName = ({
    placeholder = "Promo Name",
    descriptor = "This will appear on the menu and customer receipts",
}: Props) => (
    <Container>
        <TitleContainer>
            <FormFieldName name="Name" required />
            <FormFieldDescriptor descriptor={descriptor} />
        </TitleContainer>
        <FormTextInput
            inputLabel={"Promo Name"}
            fieldName={FIELD_NAMES.NAME}
            placeholder={placeholder}
            required
        />
    </Container>
);
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleContainer = styled.div`
    margin-bottom: 16px;
`;
