import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import api from "src/api/rest";
import { getStoreTimezone } from "#utils/helpers";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import { useDashboardContext } from "./use-dashboard-context";
import { logAndSendError } from "src/utils/errors";

interface TopItemData {
    name: string;
    displayName: string;
    fullName: string;
    total: number;
    revenue: number;
    color: string;
}

const COLORS = [
    "#60A5FA", // blue
    "#F59E0B", // yellow
    "#8B5CF6", // purple
    "#F97316", // orange
    "#10B981", // green
];

const truncateName = (name: string, maxLength: number = 14) => {
    if (name.length <= maxLength) return name;
    return `${name.slice(0, maxLength)}...`;
};

export const useTopItems = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [topItems, setTopItems] = useState<TopItemData[]>([]);

    const store = useSelector(getActiveStore);
    const {
        currentPeriodStartEnd,
        transactionChannels,
        transactionSources,
        paymentProviders,
    } = useDashboardContext();

    const abortController = useRef<AbortController | null>(null);

    const fetchTopItems = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        try {
            setIsLoading(true);
            setError(null);

            const params = {
                storeId: store._id,
                startDate: currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                timezone: getStoreTimezone(store),
                channel: JSON.stringify(transactionChannels),
                source: JSON.stringify(transactionSources),
                paymentProviders: JSON.stringify(paymentProviders),
                fulfillment: JSON.stringify([]),
            };

            const response = await api.reports.getMenuItemInsights(
                params,
                abortController.current,
            );

            if (!response.data?.items) {
                setTopItems([]);
                return;
            }

            // Create a map to aggregate quantities and revenue across all days and items
            const itemTotals = new Map<
                string,
                { orders: number; revenue: number }
            >();

            // Loop through each day
            response.data.items.forEach((day: any) => {
                if (Array.isArray(day.items)) {
                    day.items.forEach((item: any) => {
                        if (
                            item.name &&
                            typeof item.orders === "number" &&
                            typeof item.sales === "number"
                        ) {
                            const current = itemTotals.get(item.name) || {
                                orders: 0,
                                revenue: 0,
                            };
                            itemTotals.set(item.name, {
                                orders: current.orders + item.orders,
                                revenue: current.revenue + item.sales,
                            });
                        }
                    });
                }
            });

            // Convert the map to an array and sort by revenue
            const transformedData = Array.from(itemTotals.entries())
                .map(([name, data]) => ({
                    name: name,
                    displayName: truncateName(name),
                    fullName: name,
                    total: data.revenue,
                    revenue: data.revenue,
                    orders: data.orders,
                }))
                .filter((item) => item.revenue > 0)
                .sort((a, b) => b.revenue - a.revenue)
                .slice(0, 7)
                .map((item, index) => ({
                    ...item,
                    color: COLORS[index % COLORS.length],
                }));

            setTopItems(transformedData);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error("Error fetching top items:", err);
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [
        store,
        currentPeriodStartEnd,
        transactionChannels,
        transactionSources,
        paymentProviders,
    ]);

    useEffect(() => {
        fetchTopItems();

        return () => {
            if (abortController.current) {
                abortController.current.abort();
            }
        };
    }, [fetchTopItems]);

    return {
        topItems,
        isLoading,
        error,
        refetch: fetchTopItems,
    };
};
