import type { Moment } from "moment";
import moment from "moment";
import { IHoursSchema } from "@snackpass/snackpass-types";
import _ from "lodash";

export interface TimeRangeSchema {
    start: Moment | null | undefined;
    end: Moment | null | undefined;
}

export interface SpecialHoursItemType {
    id: number;
    dayOfWeek: number;
    time: TimeRangeSchema;
}

export const helpers = {
    TIME_TO_DISPLACEMENT: (minutes: number) =>
        Math.floor(
            ((minutes % constants.MINUTES_IN_DAY) / constants.MINUTES_IN_DAY) *
                100,
        ),
    TIME_TO_DAY_OF_WEEK: (minutes: number) =>
        Math.floor(minutes / constants.MINUTES_IN_DAY),
    MIN_TO_TIME: (minutes: number) => {
        const hours = Math.floor(
            (minutes % constants.MINUTES_IN_DAY) / constants.MINUTES_IN_HOUR,
        );
        const min = minutes % constants.MINUTES_IN_HOUR;
        const isAM = hours < 12 || hours === 24;
        const standardHour = hours % 12 ? hours % 12 : 12;
        const standardMin = min ? min : "00";
        return `${standardHour}:${standardMin} ${isAM ? "AM" : "PM"}`;
    },
    MIN_TODAY: (minutes: number) =>
        Math.floor(minutes % constants.MINUTES_IN_DAY),
    MIN_TO_DAY_OF_WEEK: (minutes: number) =>
        Math.floor(minutes / constants.MINUTES_IN_DAY),
};

export const constants = {
    MINUTES_IN_DAY: 60 * 24,
    MINUTES_IN_HOUR: 60,
    DAYS_OF_WEEK_NUMBER: [0, 1, 2, 3, 4, 5, 6],
    MINUTES_IN_WEEK: 60 * 24 * 7,
    MAX_TIME: 24 * 60 * 7 - 1,
};

export const convertTimeStringToNumberFormat = (
    dayOfWeek: number,
    time: Moment,
) => {
    //Here we are formatting a time string (e.g. "12:00 am") along with day of week(e.g. 0-6 representing Mon-Sun) to a representations of a time range as minutes from the beginning of the week and not a Unix time.
    const day = dayOfWeek;
    const formatedTime =
        day * 24 * 60 + moment.duration(time.format("HH:mm")).asMinutes();
    return isNaN(formatedTime) ? 0 : formatedTime;
};

export const getStoreOpenDays = (storeHours: IHoursSchema) => {
    if (storeHours?.local) {
        const storeOpensDays = new Set<number>();
        for (let i = 0; i < storeHours?.local.length; i++) {
            const time = storeHours?.local[i];
            const startDay = formatDayOfWeek(time.start);
            const endDay = formatDayOfWeek(time.end) + 1;
            if (startDay !== endDay) {
                const days = _.range(startDay, endDay);
                days.forEach((day) => {
                    storeOpensDays.add(day);
                });
            } else {
                storeOpensDays.add(startDay);
            }
        }
        return Array.from(storeOpensDays);
    }

    return [];
};

export const formatTime = (minutes: number) => {
    const format = "hh:mm a";
    const time = moment().startOf("day").minutes(minutes).format(format);
    return moment(time, format);
};

export const formatDayOfWeek = (minutes: number): number =>
    moment.duration(minutes, "minutes").days();

export const format12 = "hh:mm a";
