import { useState, useEffect } from "react";
import { format } from "date-fns";
import { Calendar } from "src/@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { cn } from "src/@/lib/utils";
import { CalendarIcon } from "lucide-react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import { SectionHeader } from "../insights/section-header";
import { MetricCard } from "../insights/metric-card";
import { DollarSign, Percent } from "lucide-react";
import { useBatchReports } from "./use-batch-reports";
import { DataTable } from "./data-table";
import { columns } from "./columns";

interface TimeInputProps {
    value: string;
    onChange: (value: string) => void;
    label: string;
}

const TimeInput = ({ value, onChange, label }: TimeInputProps) => (
    <div className="flex flex-col gap-1">
        <label className="text-sm font-medium text-gray-700">{label}</label>
        <Input
            type="time"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="w-32"
        />
    </div>
);

const DatePickerWithButton = ({
    date,
    setDate,
    label,
}: {
    date: Date | undefined;
    setDate: (date: Date | undefined) => void;
    label: string;
}) => {
    return (
        <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-gray-700">{label}</label>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant={"outline"}
                        className={cn(
                            "w-[140px] justify-start text-left font-normal",
                            !date && "text-muted-foreground",
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, "MMM dd, yyyy") : "Pick date"}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                    <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        initialFocus
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
};

export const TipReporting = () => {
    // Initialize with default dates (1 week ago to today)
    const [startDate, setStartDate] = useState<Date>(() => {
        const date = new Date();
        date.setDate(date.getDate() - 7); // 7 days ago
        return date;
    });
    const [startTime, setStartTime] = useState("00:00"); // Start of day
    const [endDate, setEndDate] = useState<Date>(() => new Date()); // Today
    const [endTime, setEndTime] = useState("23:59"); // End of day
    const [showMetrics, setShowMetrics] = useState(true); // Start with metrics visible

    const {
        isLoading,
        totalTips,
        totalSales,
        tipPercentage,
        hourlyData,
        fetchBatchReport,
    } = useBatchReports();

    // Fetch data on component mount
    useEffect(() => {
        if (startDate && endDate) {
            const start = new Date(startDate);
            start.setHours(
                parseInt(startTime.split(":")[0]),
                parseInt(startTime.split(":")[1]),
            );

            const end = new Date(endDate);
            end.setHours(
                parseInt(endTime.split(":")[0]),
                parseInt(endTime.split(":")[1]),
            );

            fetchBatchReport(start, end);
        }
    }, []); // Empty dependency array for mount-only

    const handleSearch = () => {
        if (startDate && endDate && startTime && endTime) {
            const start = new Date(startDate);
            start.setHours(
                parseInt(startTime.split(":")[0]),
                parseInt(startTime.split(":")[1]),
            );

            const end = new Date(endDate);
            end.setHours(
                parseInt(endTime.split(":")[0]),
                parseInt(endTime.split(":")[1]),
            );

            fetchBatchReport(start, end);
            setShowMetrics(true);
        }
    };

    // Format currency helper
    const formatCurrency = (value: number) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(value);
    };

    const isValid = startDate && startTime && endDate && endTime;

    return (
        <div className="my-16 hidden">
            <SectionHeader
                className="mb-4"
                title="Tip Reporting [TEMP THIS WILL BE MOVED]"
            />
            <Card>
                <CardHeader>
                    <CardTitle className="text-sm font-medium">
                        Select Date Range
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex flex-wrap items-end gap-4">
                        <div className="flex gap-4">
                            <DatePickerWithButton
                                date={startDate}
                                setDate={(date) => setStartDate(date as Date)}
                                label="Start Date"
                            />
                            <TimeInput
                                label="Start Time"
                                value={startTime}
                                onChange={setStartTime}
                            />
                        </div>
                        <div className="flex gap-4">
                            <DatePickerWithButton
                                date={endDate}
                                setDate={(date) => setEndDate(date as Date)}
                                label="End Date"
                            />
                            <TimeInput
                                label="End Time"
                                value={endTime}
                                onChange={setEndTime}
                            />
                        </div>
                        <Button onClick={handleSearch} disabled={!isValid}>
                            Submit
                        </Button>
                    </div>
                </CardContent>
            </Card>

            {showMetrics && (
                <>
                    <div className="grid gap-4 md:grid-cols-3 mt-4">
                        <MetricCard
                            title="Total Tips"
                            value={formatCurrency(totalTips)}
                            icon={DollarSign}
                            isLoading={isLoading}
                        />
                        <MetricCard
                            title="Tip Percentage"
                            value={`${tipPercentage}%`}
                            icon={Percent}
                            isLoading={isLoading}
                        />
                        <MetricCard
                            title="Total Sales"
                            value={formatCurrency(totalSales)}
                            icon={DollarSign}
                            isLoading={isLoading}
                        />
                    </div>

                    <Card className="mt-4">
                        <CardHeader>
                            <CardTitle className="text-sm font-medium">
                                Hourly Breakdown
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={hourlyData}
                                />
                            )}
                        </CardContent>
                    </Card>
                </>
            )}
        </div>
    );
};
