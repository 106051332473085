import last from "lodash/fp/last";
import React from "react";
import styled from "@emotion/styled";
import { IPurchase, PurchaseStatus } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { SystemColors } from "@snackpass/design-system";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { getActiveStore, toDollar } from "@snackpass/accounting";

import { ReactComponent as ReceiptIcon } from "src/assets/icons/receipt-black.svg";
import { capitalizeFirstChar, getStoreTimezone } from "#utils/helpers";
import StatusTag from "#legacy-components/purchases/status-tag";
import colors from "#reusable/colors/colors.json";

export const OrderHistoryCard = ({ purchase }: { purchase: IPurchase }) => {
    const {
        createdAt,
        receiptNumber,
        transactionSource,
        items,
        status,
        subtotal,
        _id,
        refund,
        partialRefund,
        isPOSMode,
    } = purchase;

    const activeStore = useSelector(getActiveStore);

    const orderStatus =
        refund || partialRefund ? PurchaseStatus.canceled : last(status)?.type;

    const transactionTime = moment(createdAt)
        .tz(getStoreTimezone(activeStore))
        .format("MMM D, h:mm a");
    const orderNumber = `${capitalizeFirstChar(
        isPOSMode && transactionSource === "kiosk"
            ? "Register"
            : transactionSource,
    )} Order #${receiptNumber}`;

    const totalAmount = toDollar(subtotal || 0);

    const dispatch = useDispatch();
    const _onClick = () => {
        dispatch(show("OrderDetailsDrawer", { purchaseId: _id }));
    };

    return (
        <CardWrapper>
            <div className="top-row">
                <div className="transaction-time">{transactionTime}</div>
                {orderStatus && (
                    <StatusTag
                        style={StatusStyle}
                        text={orderStatus}
                        status={orderStatus}
                    />
                )}
            </div>
            <div className="order-number">{orderNumber}</div>
            <div className="items-ordered">
                {items.map((item, i) => (
                    <div key={i}>{item.product?.name}</div>
                ))}
            </div>
            <div className="total-row">
                <div>Total</div>
                <div>{totalAmount}</div>
            </div>

            <Divider className="divider" />

            <ViewOrderButton className="hover" onClick={() => {}}>
                <ReceiptIcon fill={SystemColors.v1.sesame} />
                <div className="view-order-button-text" onClick={_onClick}>
                    View Order
                </div>
            </ViewOrderButton>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    gap: 4px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 16px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;

    .top-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
    }

    .transaction-time {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: ${SystemColors.v1.sesame};
    }

    .order-number {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 25px;
        font-feature-settings: "salt" on;
    }
    .items-ordered {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 5px 0px;
    }

    .total-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 4px;
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }

    .divider {
        margin: 10px 0px;
    }
`;

const ViewOrderButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 15px 5px 12px;
    border: 1px solid ${SystemColors.v1.gray90};
    background: ${SystemColors.v1.gray90};
    border-radius: 56px;
    min-height: 35px;
    cursor: pointer;

    .view-order-button-text {
        margin-left: 8px;
        color: ${SystemColors.v1.sesame};
        font-size: 14px;
        font-weight: 700;
    }
`;

//customize stylings on shared components
const StatusStyle = { padding: 0 };
