/** @jsxImportSource @emotion/react */
import React, {
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { css } from "@emotion/react";
import { Col, Form, List, Modal, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    Addon,
    AddonGroup,
    IProduct,
    IStore,
} from "@snackpass/snackpass-types";
import { toast } from "sonner";

import DropDownSelect from "#reusable/select/dropdown";
import { getActiveStore, getLegacyProducts } from "src/redux/selectors";
import api from "src/api/rest";
import { sendError } from "src/utils/errors";
import { setActiveStore, setLegacyProducts } from "src/redux/slices";
import { fetchStoreProducts } from "#menu/api";
import { useMyAdminStores } from "#store-selector/hooks";

function getNewProduct(
    product: IProduct,
    storeId: string,
): IProduct & { storeId: string } {
    // strip _id, firebase
    // Not all of these fields exist for the Addon and AddonGroup types.
    const removeFields = [
        "_id",
        "__v",
        "firebaseKey",
        "updatedAt",
        "createdAt",
        "purchaseCount",
        "viewCount",
        "soldOut",
        "soldOutToday",
        "soldOutDates",
    ];
    const ret = { ...product };
    removeFields.forEach((field) => {
        delete ret[field as keyof IProduct];
    });
    // @ts-ignore
    delete ret.store;
    ret.addonGroups.forEach((addonGroup: AddonGroup) => {
        removeFields.forEach((field) => {
            delete addonGroup[field as keyof AddonGroup];
        });
        addonGroup.addons.forEach((addon: Addon) => {
            removeFields.forEach((field) => {
                delete addon[field as keyof Addon];
            });
        });
    });
    return {
        ...ret,
        storeId,
    };
}

type DuplicateMenuProps = {
    isDuplicateMenuModalOpen: boolean;
    setIsDuplicateMenuModalOpen: React.Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const DuplicateMenu: React.FC<DuplicateMenuProps> = ({
    isDuplicateMenuModalOpen,
    setIsDuplicateMenuModalOpen,
    isLoading,
    setIsLoading,
}) => {
    const products = useSelector(getLegacyProducts) as IProduct[];
    const activeStore = useSelector(getActiveStore) as IStore;
    const dispatch = useDispatch();
    const { stores } = useMyAdminStores();
    const [selectedStoreId, setSelected] = useState<string | null>(null);
    const [newProducts, setNewProducts] = useState<IProduct[]>([]);

    const onFinishFailed = () => {
        toast.error("Menu Duplicate Failed");
    };

    const handleGetProducts = useCallback(() => {
        if (!activeStore) {
            return;
        }
        if (selectedStoreId) {
            fetchStoreProducts(selectedStoreId)
                .then(({ products }) => {
                    const newProductsMap = products.map((product: IProduct) =>
                        getNewProduct(product, activeStore._id),
                    );
                    setNewProducts(newProductsMap);
                })
                .catch((err) => {
                    toast.error(err);
                });
        }
    }, [activeStore, selectedStoreId]);

    const handleSelectStore = (selectedOption: {
        id: string;
        value: string;
        label: string;
    }) => {
        setNewProducts([]);
        setSelected(selectedOption.value);
    };

    const duplicateProducts = async () => {
        if (!activeStore._id || !selectedStoreId) {
            return;
        }
        await api.stores.duplicateMenu(activeStore._id, selectedStoreId);
        const { products } = await fetchStoreProducts(activeStore._id);
        dispatch(setLegacyProducts(products));
        const pictureMenuProductIds = products
            .filter((product) => product.image)
            .map((product) => product._id);
        await api.stores.update(activeStore._id, {
            pictureMenuProductIds,
        });

        //Get all duplicated products.
        // TODO: Not sure why we are fetching products again here.
        const productsRes = await fetchStoreProducts(activeStore._id);
        dispatch(setLegacyProducts(productsRes.products));

        const updatedStore = await api.stores.getOne(activeStore._id);
        dispatch(setActiveStore(updatedStore.data.store));
        toast.success("Success ✅");
    };
    const handleSubmit = async () => {
        if (!activeStore) return;

        if (products.length > 1) {
            toast.error(
                "You must delete all products in this store before duplicating another menu.",
            );
            return;
        }
        if (isLoading) {
            return;
        }
        const r = window.confirm("Are you sure you want to duplicate?");
        if (!r) return;
        setIsLoading(true);
        try {
            await duplicateProducts();
        } catch (err) {
            const { products } = await fetchStoreProducts(activeStore._id);
            if (products.length > 0) {
                //some products duplication failed
                dispatch(setLegacyProducts(products));
                const updatedStore = await api.stores.getOne(activeStore._id);
                dispatch(setActiveStore(updatedStore.data.store));
                toast.error("Failed to duplicate some items");
            } else {
                toast.error("Failed to duplicate Menu");
            }
            sendError(err);
        }
        setIsLoading(false);
        setNewProducts([]);
        setSelected(null);
        setIsDuplicateMenuModalOpen(false);
    };

    const storeOptions = useMemo(
        () =>
            stores?.map((store: IStore) => ({
                value: store?._id,
                label: store?.name,
                id: store?._id,
            })),
        [stores],
    );

    useEffect(() => {
        handleGetProducts();
    }, [handleGetProducts, selectedStoreId]);

    return (
        <>
            <Modal
                centered
                css={ModalCSS}
                open={isDuplicateMenuModalOpen}
                onOk={() => {
                    handleSubmit();
                }}
                bodyStyle={{
                    overflowY: "auto",
                    minHeight: "calc(30vh)",
                    maxHeight: "calc(80vh)",
                }}
                onCancel={() => {
                    setIsDuplicateMenuModalOpen(false);
                    setNewProducts([]);
                    setSelected(null);
                }}
                okButtonProps={{
                    disabled:
                        newProducts.length === 0 && selectedStoreId !== null,
                }}
                destroyOnClose
            >
                <HeaderRow>
                    <span
                        className="header-text"
                        onClick={() => setIsDuplicateMenuModalOpen(false)}
                    >
                        Menu Duplicator
                    </span>
                    <Extra>
                        Copy another store's menu into this one. Afterwards,
                        editing these items will not affect the other store's
                        menu
                    </Extra>
                </HeaderRow>

                <Form
                    layout="horizontal"
                    name="duplicateMenu"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    onFinish={() => {
                        handleSubmit();
                    }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="menuDuplicator"
                                label="Store"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DropDownSelect
                                    square
                                    options={storeOptions}
                                    value={null}
                                    onChange={handleSelectStore}
                                    placeholder={
                                        storeOptions.length > 0
                                            ? "Select Store"
                                            : "Loading..."
                                    }
                                ></DropDownSelect>
                            </Form.Item>
                        </Col>
                    </Row>

                    {selectedStoreId && newProducts.length === 0 ? (
                        <div className="spinner">
                            <Spin size="small" />
                        </div>
                    ) : (
                        <List
                            size="small"
                            bordered
                            locale={{ emptyText: "No Data" }}
                            dataSource={newProducts}
                            renderItem={(product) => (
                                <List.Item>
                                    <span className="product">
                                        {product.name}
                                    </span>
                                </List.Item>
                            )}
                        />
                    )}
                </Form>
            </Modal>
        </>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    font-size: 18px;
    align-items: center;
    justify-content: center;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 18px;
        margin-top: 5px;
        margin-right: 1rem;
    }
    .product {
        margin-right: 1rem;
        font-size: 18px;
    }

    .ant-list-bordered {
        border: none;
    }
    .spinner {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
`;

const HeaderRow = styled.div`
    margin-bottom: 1rem;
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

const Extra = styled.span`
    color: rgba(96, 108, 118, 1);
    font-family: Inter;
`;

export default DuplicateMenu;
