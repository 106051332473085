import { IFixedChargeFeePolicy } from "@snackpass/snackpass-types";

import { ChargeFormValues } from "#settings/settings-fees/utils/types";

const buildPolicy = (
    fields: Pick<
        IFixedChargeFeePolicy,
        "transactionSource" | "fulfillment" | "flat" | "percentage"
    >,
): IFixedChargeFeePolicy => ({
    policyType: "FixedChargeFeePolicy",
    allowRefunds: false,
    ...fields,
});

export const buildChargePolicies = (values: ChargeFormValues) => {
    const policies: IFixedChargeFeePolicy[] = [];
    if (values.appPickupOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "app",
                fulfillment: "PICKUP",
                flat: values.appPickupFixed ?? 0,
                percentage: values.appPickupPercent ?? 0,
            }),
        );
    }
    if (values.appDeliveryOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "app",
                fulfillment: "DELIVERY",
                flat: values.appDeliveryFixed ?? 0,
                percentage: values.appDeliveryPercent ?? 0,
            }),
        );
    }
    if (values.appDineInOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "app",
                fulfillment: "DINE_IN",
                flat: values.appDineInFixed ?? 0,
                percentage: values.appDineInPercent ?? 0,
            }),
        );
    }
    if (values.kioskPickupOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "kiosk",
                fulfillment: "PICKUP",
                flat: values.kioskPickupFixed ?? 0,
                percentage: values.kioskPickupPercent ?? 0,
            }),
        );
    }
    if (values.kioskDineInOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "kiosk",
                fulfillment: "DINE_IN",
                flat: values.kioskDineInFixed ?? 0,
                percentage: values.kioskDineInPercent ?? 0,
            }),
        );
    }
    if (values.onlinePickupOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "online",
                fulfillment: "PICKUP",
                flat: values.onlinePickupFixed ?? 0,
                percentage: values.onlinePickupPercent ?? 0,
            }),
        );
    }
    if (values.onlineDeliveryOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "online",
                fulfillment: "DELIVERY",
                flat: values.onlineDeliveryFixed ?? 0,
                percentage: values.onlineDeliveryPercent ?? 0,
            }),
        );
    }
    if (values.onlineDineInOverride) {
        policies.push(
            buildPolicy({
                transactionSource: "online",
                fulfillment: "DINE_IN",
                flat: values.onlineDineInFixed ?? 0,
                percentage: values.onlineDineInPercent ?? 0,
            }),
        );
    }
    return policies;
};
