import { IProduct, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

// Curly with number x means run this check x times.
// const hasNumber = /\d{2}/;

const hasNumber = /\d/;

class ProductDescriptionCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductDescriptionCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems(): MenuIssue<IProduct>[] {
        return [];
    }

    checkItem(item: IProduct): MenuIssue<IProduct> | null {
        const description = item.description;
        if (
            hasNumber.test(description || "") &&
            description?.indexOf("$") != -1
        ) {
            return new MenuIssue<IProduct>(
                "DescriptionHasPrice",
                "Descriptions shouldn't have prices in them.",
                "warning",
                item,
            );
        }
        return null;
    }
}

export { ProductDescriptionCheck };
