import { useEffect, useRef, useState } from "react";
import { match } from "ts-pattern";

import { useModifyElementPosition } from "../hooks";
import { Element as Variant } from "src/api/graphql/generated/types";
import { ModifyTable } from "./ModifyTable";
import { ModifyLabel } from "./ModifyLabel";
import { ModifyBarrier } from "./ModifyBarrier";

export function ModifyElement() {
    const element = useModifyElementPosition();
    const ref = useRef<HTMLDivElement>(null);

    const [position, setPosition] = useState<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });
    const clamp = (n: number, min: number, max: number) =>
        Math.max(min, Math.min(n, max));
    const VERTICAL_PADDING = 20;

    useEffect(() => {
        if (ref.current && element) {
            const rect = ref.current.getBoundingClientRect();
            const editor = ref.current.parentElement;

            if (editor) {
                const editorRect = editor.getBoundingClientRect();
                const viewportWidth = editorRect.width;
                const viewportHeight = editorRect.height;
                // Calculate left position to center modify element popover to
                // canvas element
                const x = element.x + element.width / 2 - rect.width / 2;

                let y = element.y + element.height + VERTICAL_PADDING;
                // NOTE: This makes sure it doesn't clip out of window and places it
                // above the shape if it goes below.
                if (y + rect.height > viewportHeight) {
                    y = element.y - rect.height - VERTICAL_PADDING;
                }
                const adjustedX = clamp(x, 0, viewportWidth - rect.width);
                const adjustedY = clamp(y, 0, viewportHeight - rect.height);

                setPosition({
                    x: adjustedX,
                    y: adjustedY,
                });
            }
        }
    }, [element]);
    if (element == null) return;

    return (
        <div
            ref={ref}
            className="absolute w-80 rounded-lg border-2 border-neutral-400 bg-white shadow-xl"
            style={{
                top: position.y,
                left: position.x,
            }}
        >
            {match(element.properties)
                .with({ type: Variant.Table }, (property) => (
                    <ModifyTable id={element.id} properties={property} />
                ))
                .with({ type: Variant.Label }, (property) => (
                    <ModifyLabel id={element.id} properties={property} />
                ))
                .with({ type: Variant.Barrier }, () => (
                    <ModifyBarrier id={element.id} />
                ))
                .otherwise(() => null)}
        </div>
    );
}
