import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import axios from "axios";

import { getActiveStore } from "src/redux/selectors";
import FilterHeader, {
    FilterType,
} from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportsContext } from "#app/reports-context-provider";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import PromotionPerformanceTable from "#reports/promotions/components/PromotionPerformanceTable";
import api from "src/api/rest";

const PromotionsReport = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, filter } = reportsState;

    const trackEvent = useTrackEvent();

    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.promotionsReportData) {
            // set loading
            setReportsState((reportsState) => ({
                ...reportsState,
                promotionsReportData: {
                    promotionsDataLoading: true,
                    promotionsDataFailed: false,
                    promotionsData:
                        reportsState?.promotionsReportData?.promotionsData,
                },
            }));
            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = async () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        const params = {
            storeId: activeStore?._id,
            startDate: reportsState.allTime
                ? undefined
                : dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: (reportsState.allTime
                ? moment()
                : dateRanges[0][1]
            ).format("YYYY-MM-DD"),
            source: JSON.stringify(filter.source),
        };

        api.reports
            .getPromotionsReport(params, abortController.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    promotionsReportData: {
                        promotionsDataLoading: false,
                        promotionsDataFailed: false,
                        promotionsData: res.data.promotionReport,
                    },
                }));
            })
            .catch((err) => {
                if (axios.isCancel(err?.cause)) return;
                setReportsState((reportsState) => ({
                    ...reportsState,
                    promotionsReportData: {
                        promotionsDataLoading: false,
                        promotionsDataFailed: true,
                        promotionsData: undefined,
                    },
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);
    const fetchDataDebounced = useCallback(
        debounce(async () => fetchDataRef.current?.(), 600),
        [],
    );

    return (
        <div>
            <FilterHeader
                hideFilters={[
                    FilterType.CHANNEL,
                    FilterType.GRANULARITY,
                    FilterType.FULFILLMENT,
                ]}
                reportType={ReportType.PROMOTIONS_REPORT}
                showAllTimeRange
            />
            <div className="px-6 md:px-24">
                <PromotionPerformanceTable />
            </div>
        </div>
    );
};

export default PromotionsReport;
