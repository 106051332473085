import { forwardRef, useCallback, useEffect, useState } from "react";
import { MinusIcon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { debounce } from "radash";
import { Element as Variant } from "src/api/graphql/generated/types";
import { TableProperty } from "#table-editor/types";
import { Input, InputProps } from "src/@/components/ui/input";
import { Button } from "src/@/components/ui/button";

type ModifyTableProps = {
    id: string;
    properties: TableProperty;
};

export function ModifyTable({ id, properties }: ModifyTableProps) {
    const editor = useEditorStore();

    const [existingTables, setExistingTables] = useState<Set<string>>(
        new Set(),
    );

    const modifyTableSchema = z.object({
        tableName: z
            .string()
            .min(1, "Table name cannot be empty")
            .max(4)
            .refine((val) => {
                const isValid = !existingTables.has(val);
                editor.getState().setHasDataErrors(!isValid);
                return isValid;
            }, "Table name must be unique"),
        numSeats: z.coerce
            .number({
                required_error: "Please enter a number.",
                invalid_type_error: "Please enter a number",
            })
            .min(1, { message: "Number must be at least 1." }),
    });

    const { formState, watch, register, getValues, setValue } = useForm({
        resolver: zodResolver(modifyTableSchema),
        values: {
            tableName: properties.tableName,
            numSeats: properties.numSeats,
        },
        mode: "onChange",
    });

    const updateTable = debounce(
        { delay: 100 },
        ({ name, seats }: { name?: string; seats?: number }) => {
            if (!formState.errors.tableName && !formState.errors.numSeats) {
                editor.getState().setTableData(id, {
                    type: Variant.Table,
                    tableId: properties.tableId,
                    tableName: name ?? getValues("tableName"),
                    numSeats: seats ?? getValues("numSeats"), // NOTE: Defaults to last valid value
                });
            }
        },
    );

    useEffect(() => {
        setValue("tableName", properties.tableName);
        setValue("numSeats", properties.numSeats);
    }, [setValue, properties]);

    useEffect(
        () =>
            watch((value) => {
                updateTable({ name: value.tableName, seats: value.numSeats });
            }).unsubscribe,
        [watch, updateTable],
    );

    useEffect(
        () =>
            editor.subscribe((state) => {
                const namesInOtherServiceAreas = state.serviceAreas
                    .filter((el) => el.id !== state.activeServiceArea?.id)
                    .flatMap((el) => el.layout)
                    .filter((el) => el.properties.type === Variant.Table)
                    .map((el) => (el.properties as TableProperty).tableName);

                const otherTableNames = state.elements
                    .filter(
                        (el) =>
                            el.properties.type === Variant.Table &&
                            el.id !== id,
                    )
                    .map((el) => (el.properties as TableProperty).tableName);

                setExistingTables(
                    new Set([...namesInOtherServiceAreas, ...otherTableNames]),
                );
            }),
        [editor, id],
    );

    const onClickDelete = useCallback(() => {
        editor.getState().removeSelected(id);
    }, [editor, id]);

    return (
        <div className="flex w-80 flex-col gap-3 p-3">
            <h4 className="text-sm font-medium text-neutral-600">Edit Table</h4>
            <Input
                {...register("tableName")}
                placeholder="Table Number"
                maxLength={4}
                className="w-full"
            />
            {formState.errors.tableName ? (
                <p className="w-full text-wrap text-base text-critical-a11y-light">
                    {formState.errors.tableName.message}
                </p>
            ) : null}

            <NumSeatsInput
                {...register("numSeats", { valueAsNumber: true })}
                onIncrement={() =>
                    setValue("numSeats", getValues("numSeats") + 1)
                }
                onDecrement={() => {
                    if (getValues("numSeats") > 1) {
                        setValue("numSeats", getValues("numSeats") - 1);
                    }
                }}
            />
            {formState.errors.numSeats ? (
                <p className="text-base text-critical-a11y-light">
                    {formState.errors.numSeats.message}
                </p>
            ) : null}

            <Button
                type="button"
                variant="destructive"
                onClick={onClickDelete}
                className="gap-2"
            >
                <TrashIcon className="h-4 w-4" />{" "}
                <span className="select-none">Delete</span>
            </Button>
        </div>
    );
}

type NumSeatsInputProps = InputProps & {
    onIncrement(): void;
    onDecrement(): void;
};

const NumSeatsInput = forwardRef<HTMLInputElement, NumSeatsInputProps>(
    ({ onIncrement, onDecrement, ...props }, ref) => (
        <div className="flex h-9 w-full flex-row items-center justify-between gap-1 rounded-md border border-input bg-background">
            <div className="flex h-full w-4/5 flex-row items-center justify-between">
                <input
                    ref={ref}
                    {...props}
                    className="h-full w-full text-sm rounded-l-md rounded-r-none border-none placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring"
                />
                <div className="flex h-full items-center justify-center">
                    <span className="mr-3 select-none text-sm font-normal text-neutral-600">
                        seats
                    </span>
                </div>
            </div>
            <div className="flex h-full w-1/5 flex-row items-center border-l border-l-input">
                <div
                    className="flex h-full w-1/2 cursor-pointer items-center justify-center border-r border-r-input p-2 hover:bg-neutral-300"
                    onClick={onDecrement}
                >
                    <MinusIcon />
                </div>
                <div
                    className="flex h-full w-1/2 cursor-pointer items-center justify-center p-2 hover:bg-neutral-300"
                    onClick={onIncrement}
                >
                    <PlusIcon />
                </div>
            </div>
        </div>
    ),
);
