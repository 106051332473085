import { IPurchase } from "@snackpass/snackpass-types";
import { Divider } from "antd";
import clsx from "clsx";
import Dinero from "dinero.js";
import { last } from "lodash";
import moment from "moment";

export function RefundSummary({ purchase }: { purchase: IPurchase }) {
    const hasRefunds = purchase.refunds.length > 0;
    const hasMultipleRefunds = purchase.refunds.length > 1;
    const hasUpcharge = !!purchase.upCharge;

    if (!hasRefunds && !hasUpcharge) return null;

    return (
        <>
            <div className="mt-4 rounded-lg bg-gray-100 p-3">
                {hasRefunds && (
                    <>
                        <div className="mb-2 font-semibold">Refund History</div>
                        <div className="text-sm italic">
                            Reason: {purchase.refundReason}
                        </div>
                        {purchase.refunds.map((refund, index) => (
                            <div
                                key={`refund-${index}`}
                                className={clsx(
                                    "flex justify-between py-2",
                                    hasMultipleRefunds ? "border-b" : "",
                                )}
                            >
                                <div className="text-sm">
                                    {moment(refund.refundedAt).format(
                                        "MMM D, YYYY h:mm A",
                                    )}
                                </div>
                                <div className="text-sm font-semibold text-red-600">
                                    {Dinero({
                                        amount: refund._amount,
                                        currency: "USD",
                                    }).toFormat("$0,0.00")}
                                </div>
                            </div>
                        ))}
                        {hasMultipleRefunds && (
                            <div
                                key="refund-total"
                                className="flex justify-between py-2"
                            >
                                <div className="text-sm">Total Refunded</div>
                                <div className="text-sm font-semibold text-red-600">
                                    {Dinero({
                                        amount:
                                            (purchase.refundedAmount ?? 0) *
                                            100,
                                        currency: "USD",
                                    }).toFormat("$0,0.00")}
                                </div>
                            </div>
                        )}
                    </>
                )}
                {hasUpcharge && (
                    <>
                        <div>Upcharge History</div>
                        <div>
                            <div className="text-sm">
                                {moment(
                                    last(purchase.charges)?.createdAt,
                                ).format("MMM D, YYYY h:mm A")}
                            </div>
                            <div className="text-sm font-semibold text-green-400">
                                {Dinero({
                                    amount: Math.round(
                                        (purchase.upChargeAmount || 0) * 100,
                                    ),
                                    currency: "USD",
                                }).toFormat("$0,0.00")}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Divider />
        </>
    );
}
