/* eslint-disable @typescript-eslint/no-explicit-any */ // No types for react-table-7 lead to explicit any's
import { createRef, RefObject, useEffect, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import constants from "#core/constants";
import {
    isAddon,
    isAddonGroup,
    isCategory,
    isProduct,
    MenuOutlineCellTypeEnum,
} from "#menu-editor/multi-menus/helpers";
import { useResumeTableState } from "#menu-editor/multi-menus/hooks/useResumeTableState";

interface BaseTableProps {
    getTableProps: Function;
    headerGroups: any;
    rows: any[];
    rowsById: any;
    getTableBodyProps: Function;
    prepareRow: Function;
    shouldDrag: boolean;
    globalFilterApplied: boolean;
    increaseOutlineChangesCount: () => void;
    menuId: string;
}

export const BaseTable: React.FC<BaseTableProps> = ({
    getTableProps,
    getTableBodyProps,
    rows,
    rowsById,
    prepareRow,
    headerGroups,
    shouldDrag,
    increaseOutlineChangesCount,
    globalFilterApplied,
    menuId,
}) => {
    const [rowRefs, setRowRefs] = useState<RefObject<HTMLTableRowElement>[]>(
        [],
    );
    const [draggingStates, setDraggingStates] = useState<
        Record<string, boolean>
    >({});
    const [overrideCellStates, setOverrideCellStates] = useState<
        Record<string, { price: boolean; taxes: boolean }>
    >({});
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });
    const history = useHistory();

    const shouldExpandRow = useResumeTableState(rowRefs, rows, rowsById);

    const togglePriceOverrideCell = (cellId: string) => () =>
        setOverrideCellStates((prev) => ({
            ...prev,
            [cellId]: {
                price: prev[cellId] ? !prev[cellId].price : true,
                taxes: false,
            },
        }));
    const toggleTaxesOverrideCell = (cellId: string) => () =>
        setOverrideCellStates((prev) => ({
            ...prev,
            [cellId]: {
                price: false,
                taxes: prev[cellId] ? !prev[cellId].taxes : true,
            },
        }));

    const getOnCellClick = (
        cellType: MenuOutlineCellTypeEnum,
        rowId: string,
        itemType: "category" | "product" | "addon" | "no-set",
        parentId: string,
    ) =>
        cellType === MenuOutlineCellTypeEnum.Item && isMobile
            ? () => {
                  switch (itemType) {
                      case "product":
                          return history.push(
                              `/multi-menus-overrides/${menuId}/products/${rowId}`,
                          );
                      case "addon":
                          return history.push(
                              `/multi-menus-overrides/${menuId}/products/${parentId}/addons/${rowId}`,
                          );
                      default:
                          break;
                  }
              }
            : null;

    const getCellCustomProps = (
        cellType: MenuOutlineCellTypeEnum,
        rowIndex: number,
        rowId: string,
    ) => {
        switch (cellType) {
            case MenuOutlineCellTypeEnum.Item:
                return {
                    rowRef: rowRefs[rowIndex],
                    setRowIsDragging: (isDragging: boolean) => {
                        setDraggingStates({
                            ...draggingStates,
                            [rowId]: isDragging,
                        });
                    },
                    shouldDrag,
                    increaseOutlineChangesCount,
                };
            case MenuOutlineCellTypeEnum.Overrides:
                return {
                    overrideCellStates,
                    togglePriceOverrideCell: togglePriceOverrideCell(rowId),
                    toggleTaxesOverrideCell: toggleTaxesOverrideCell(rowId),
                    setOverrideCellStates,
                };
            case MenuOutlineCellTypeEnum.Actions:
                return {
                    overrideCellStates,
                    togglePriceOverrideCell: togglePriceOverrideCell(rowId),
                    toggleTaxesOverrideCell: toggleTaxesOverrideCell(rowId),
                };
        }
    };

    useEffect(() => {
        setRowRefs((rowRefs) =>
            Array(rows.length)
                .fill(null)
                .map((_, i) => rowRefs[i] || createRef<HTMLTableRowElement>()),
        );
    }, [rows.length]);

    useEffect(() => {
        setDraggingStates(
            rows.reduce((obj, row) => {
                const wasDragging = draggingStates[row.original._id];

                return {
                    ...obj,
                    [row.original._id]: wasDragging ?? false,
                };
            }, draggingStates),
        );
        rows.forEach((row: any) => {
            if (isAddonGroup(row.original) || shouldExpandRow(row))
                row.toggleRowExpanded(true);
        });
    }, [rows]);

    useEffect(() => {
        rows.forEach(
            ({ original, toggleRowExpanded }) =>
                isCategory(original) && toggleRowExpanded(globalFilterApplied),
        );
    }, [globalFilterApplied]);

    return (
        <table {...getTableProps()}>
            {!isMobile ? (
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
            ) : null}
            <tbody {...getTableBodyProps()}>
                {rows.map((row: any, indx: number) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            ref={rowRefs[indx]}
                            className={classNames({
                                tableRowDragging:
                                    draggingStates[row.original._id],
                                addonGroupRow: isAddonGroup(row.original),
                            })}
                        >
                            {row.cells.map((cell: any) => {
                                const customProps = getCellCustomProps(
                                    cell.column.id,
                                    indx,
                                    row.original._id,
                                );

                                const itemType =
                                    (isProduct(row.original) && "product") ||
                                    (isCategory(row.original) && "category") ||
                                    (isAddon(row.original) && "addon") ||
                                    "no-set";
                                const parentId =
                                    (itemType === "product" &&
                                        (row.original.parentId as string)) ||
                                    (itemType === "addon" &&
                                        (rowsById[row.original.parentId]
                                            .original.parentId as string)) ||
                                    "no-set";
                                const onCellClick = getOnCellClick(
                                    cell.column.id,
                                    row.original._id,
                                    itemType,
                                    parentId,
                                );

                                return (
                                    <td
                                        {...cell.getCellProps(
                                            onCellClick
                                                ? {
                                                      onClick: onCellClick,
                                                  }
                                                : {},
                                        )}
                                    >
                                        {cell.render("Cell", customProps)}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
