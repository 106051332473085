import { PrinterFormat, PrinterModelType } from "@snackpass/snackpass-types";

import { printerFormatNames } from "#devices/utils/deviceTypes/PrinterDevice";

const receiptPrinterModels = [
    PrinterModelType.TM_M30I,
    PrinterModelType.TM_M30II,
];

const receiptPrinterOptions = receiptPrinterModels.map((m: PrinterModelType) =>
    m.toLowerCase(),
);

/**
 * Receipt Format: Receipt, Chit, Customer
 */
const receipt = [
    PrinterFormat.RECEIPT,
    PrinterFormat.CHIT,
    PrinterFormat.CUSTOMER,
].map((value) => ({
    label: printerFormatNames[value],
    value,
}));

/**
 * Label Format: Condensed, Full
 */
const label = [PrinterFormat.LABEL_IMAGE, PrinterFormat.LABEL_V2].map(
    (value) => ({
        label: printerFormatNames[value],
        value,
    }),
);

export const getPrinterFormatOptions = (model?: string) =>
    model
        ? receiptPrinterOptions.includes(model.toLowerCase())
            ? receipt
            : label
        : [];
