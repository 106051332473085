import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

type Props = {
    descriptor?: string | React.ReactElement;
    error?: string;
    disabled?: boolean;
};

type DescriptorProps = {
    disabled: boolean;
};

export const FormFieldDescriptor = ({
    descriptor,
    error,
    disabled = false,
}: Props) =>
    descriptor || error ? (
        <Container>
            <Descriptor disabled={disabled}>{descriptor}</Descriptor>
            {error ? <Error>* {error}</Error> : null}
        </Container>
    ) : null;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Descriptor = styled.label<DescriptorProps>`
    color: ${SystemColors.v2.salt60.light};
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    @media ${ScreenState.MOBILE} {
        font-size: 14px;
    }
    ${({ disabled }) => disabled && `color: ${SystemColors.v1.gray60};`}
`;

export const Error = styled.p`
    margin-left: 8px;
    color: ${SystemColors.v1.melon50};
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    @media ${ScreenState.MOBILE} {
        font-size: 14px;
    }
`;
