import React, { useContext, useMemo, useState } from "react";
import moment, { Moment } from "moment";

import { Button } from "src/@/components/ui/button";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReportsContext } from "#app/reports-context-provider";
import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar.svg";
import { rangePresets } from "#reports/sales-summary/lib";
import { DateRangePicker } from "src/@/components/DateRangePicker";

const ALL_TIME_LABEL = "All Time";
const CUSTOM_LABEL = "Custom";

export const DateFilterPicker = ({
    handleDateChange,
    showAllTimeRange,
}: {
    handleDateChange: (value: Moment[], type: DateChangeType) => void;
    showAllTimeRange: boolean;
}) => {
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, allTime } = reportsState;

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const selectedRangeLabel = useMemo(() => {
        if (allTime && showAllTimeRange) return ALL_TIME_LABEL;
        return (
            rangePresets.find(
                (e) =>
                    e.value[0].diff(dateRanges[0][0], "days") == 0 &&
                    e.value[1].diff(dateRanges[0][1], "days") == 0,
            )?.label ?? "Custom"
        );
    }, [dateRanges, allTime, showAllTimeRange]);

    const selectedValues = useMemo(
        () => new Set([selectedRangeLabel]),
        [selectedRangeLabel],
    );

    const options = useMemo(
        () => [
            ...(showAllTimeRange
                ? [
                      {
                          label: ALL_TIME_LABEL,
                          value: ALL_TIME_LABEL,
                          onClickOverride: () =>
                              setReportsState({
                                  ...reportsState,
                                  allTime: true,
                              }),
                      },
                  ]
                : []),
            ...rangePresets.map((e) => ({
                value: e.label,
                label: e.label,
            })),
            {
                label: CUSTOM_LABEL,
                value: CUSTOM_LABEL,
                onClickOverride: () => setIsDatePickerOpen(true),
            },
        ],
        [dateRanges],
    );

    return (
        <>
            <DateRangePicker
                className="invisible w-0"
                disableFutureDates
                onSelect={(value) => {
                    if (!value) {
                        // TODO: When react-day-picker supports the "required" prop for range pickers, this case can be removed.
                        // day picker attempts to unselect the range-- in this case, we want to leave it just as the single day.
                        handleDateChange(
                            [dateRanges[0][0], dateRanges[0][0]],
                            DateChangeType.CUSTOM,
                        );
                    } else if (!value?.to) {
                        // for just a single day, we set our range to be both of one day.
                        handleDateChange(
                            [moment(value?.from), moment(value?.from)],
                            DateChangeType.CUSTOM,
                        );
                    } else {
                        handleDateChange(
                            [moment(value?.from), moment(value?.to)],
                            DateChangeType.CUSTOM,
                        );
                    }
                }}
                selected={{
                    from: dateRanges[0][0].toDate(),
                    to: dateRanges[0][1].toDate(),
                }}
                open={isDatePickerOpen}
                onOpenChange={setIsDatePickerOpen}
            />
            <FilterSelect
                title="Comparison"
                customButton={
                    <Button
                        variant="outline"
                        size="sm"
                        className="flex space-x-2"
                    >
                        <CalendarIcon className="h-4 w-4" />
                        <span>{selectedRangeLabel}</span>
                    </Button>
                }
                selectedValues={selectedValues}
                options={options}
                onOptionSelected={(value: string) => {
                    const newDate = rangePresets.find(
                        (e) => e.label === value,
                    )?.value;
                    if (newDate) {
                        handleDateChange(newDate, DateChangeType.SUGGESTED);
                    }
                }}
            />
        </>
    );
};

enum DateChangeType {
    SUGGESTED = "SUGGESTED",
    CUSTOM = "CUSTOM",
}
