import { Col, Row, Tooltip } from "antd";

import { Text } from "#menu-editor/multi-menus/styled-components/text";

export const OverridesHeader: React.FC = () => (
    <Row>
        <Col span={8}>
            <Row justify="end">
                <Tooltip
                    title="This is the new price with all adjustments."
                    color={"#0077ff"}
                >
                    <Text type="body-bold" className="content-right">
                        Price Override
                    </Text>
                </Tooltip>
            </Row>
        </Col>

        <Col span={16}>
            <Row justify="end">
                <Text type="body-bold" className="content-right">
                    Tax Override
                </Text>
            </Row>
        </Col>
    </Row>
);
