import styled, { css } from "styled-components";

type Size = {
    vertical?: string;
    horizontal?: string;
};

const verticalSize = (type: string) => {
    switch (type) {
        case "xxl":
            return "16px";
        case "xl":
            return "14px";
        case "l":
            return "12px";
        case "m":
            return "10px";
        case "s":
            return "6px";
        case "xs":
            return "0px";
    }
};
const horizontalSize = (type: string) => {
    switch (type) {
        case "xxl":
            return "8px";
        case "xl":
            return "6px";
        case "l":
            return "5px";
        case "m":
            return "4px";
        case "s":
            return "3px";
        case "xs":
            return "2px";
        default:
            return "6px";
    }
};

export const SpaceStyles = ({ vertical = "l", horizontal = "xs" }: Size) => css`
    margin: ${verticalSize(vertical)} ${horizontalSize(horizontal)};
`;

export const Space = styled.div<Size>`
    ${({ vertical, horizontal }) => SpaceStyles({ vertical, horizontal })};
    display: flex;
    flex-direction: row;
`;
