import { useMutation } from "@tanstack/react-query";
import { getActiveStore } from "@snackpass/accounting";

import api from "src/api/rest";
import { invariant } from "src/lib/invariant";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setActiveStore } from "src/redux/slices";

/**
 * Provides active menu state stored on the store object + a mutation fn to set it
 */
export function useActiveMenus() {
    const store = useAppSelector(getActiveStore);
    const activeMenus = store?.activeMenus;
    const dispatch = useAppDispatch();

    const { mutateAsync: setActiveMenus, isPending } = useMutation({
        mutationFn: async (menuIDs: string[]) => {
            invariant(store, "store is defined");
            return api.stores
                .updateActiveMenus(store._id, menuIDs)
                .then((res) => {
                    dispatch(setActiveStore(res.data.store));
                    return res.data.store;
                });
        },
    });

    return {
        setActiveMenus,
        activeMenus,
        isPending,
    };
}
