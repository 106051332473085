import { zodResolver } from "@hookform/resolvers/zod";
import { IStore } from "@snackpass/snackpass-types";
import { FormProvider, useForm } from "react-hook-form";

import {
    FormField,
    FormControl,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import { Header } from "#settings/settings-fees/components/header";
import { CommissionFormValues } from "#settings/settings-fees/utils/types";
import { CommissionFormSchema } from "#settings/settings-fees/utils/schema";
import { FeeLabel } from "#settings/settings-fees/components/fee-label";
import { commissionDefaults } from "#settings/settings-fees/forms/commission/commission-defaults";
import { CommissionRow } from "#settings/settings-fees/forms/commission/commission-row";
import { CommissionFooter } from "#settings/settings-fees/forms/commission/commission-footer";
import { useUpdateCommission } from "#settings/settings-fees/utils/hooks/useUpdateCommission";
import { FeeInput } from "#settings/settings-fees/components/fee-input";

export const CommissionForm = ({ store }: { store: IStore }) => {
    const form = useForm<CommissionFormValues>({
        defaultValues: commissionDefaults(store),
        resolver: zodResolver(CommissionFormSchema),
        mode: "all",
    });

    const updateStore = useUpdateCommission({
        storeId: store._id,
        reset: form.reset,
    });

    return (
        <>
            <form
                onSubmit={form.handleSubmit(async (values) =>
                    updateStore(values),
                )}
                className="relative h-full w-full overflow-hidden"
            >
                <FormProvider {...form}>
                    <Header title="Commission Settings" />
                    <div className="mx-auto my-2 flex w-fit flex-col justify-center gap-2 sm:flex-row">
                        <FormField
                            control={form.control}
                            name="defaultPercent"
                            render={({ field }) => (
                                <FormItem className="flex w-[200px] flex-col">
                                    <FeeLabel required>
                                        Default Commission (%)
                                    </FeeLabel>
                                    <FormControl>
                                        <FeeInput
                                            value={field.value}
                                            placeholder="0.0"
                                            suffix="%"
                                            step={0.01}
                                            max={20}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseFloat(e.target.value),
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="defaultFixed"
                            render={({ field }) => (
                                <FormItem className="mr-4 flex w-[200px] flex-col">
                                    <FeeLabel required>
                                        Default Commission ($)
                                    </FeeLabel>
                                    <FormControl>
                                        <FeeInput
                                            value={field.value}
                                            placeholder="0.0"
                                            prefix="$"
                                            step={0.01}
                                            max={1}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseFloat(e.target.value),
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div>App Commission</div>
                        <CommissionRow
                            label="Pickup"
                            override="appPickupOverride"
                            percent="appPickupPercent"
                            fixed="appPickupFixed"
                        />
                        <CommissionRow
                            label="Delivery"
                            override="appDeliveryOverride"
                            percent="appDeliveryPercent"
                            fixed="appDeliveryFixed"
                        />
                        <CommissionRow
                            label="Dine In"
                            override="appDineInOverride"
                            percent="appDineInPercent"
                            fixed="appDineInFixed"
                        />
                        <div>Kiosk Commission</div>
                        <CommissionRow
                            label="Pickup"
                            override="kioskPickupOverride"
                            percent="kioskPickupPercent"
                            fixed="kioskPickupFixed"
                        />
                        <CommissionRow
                            label="Dine In"
                            override="kioskDineInOverride"
                            percent="kioskDineInPercent"
                            fixed="kioskDineInFixed"
                        />
                        <div>Online Commission</div>
                        <CommissionRow
                            label="Pickup"
                            override="onlinePickupOverride"
                            percent="onlinePickupPercent"
                            fixed="onlinePickupFixed"
                        />
                        <CommissionRow
                            label="Delivery"
                            override="onlineDeliveryOverride"
                            percent="onlineDeliveryPercent"
                            fixed="onlineDeliveryFixed"
                        />
                        <CommissionRow
                            label="Dine In"
                            override="onlineDineInOverride"
                            percent="onlineDineInPercent"
                            fixed="onlineDineInFixed"
                        />
                    </div>
                    <CommissionFooter />
                </FormProvider>
            </form>
        </>
    );
};
