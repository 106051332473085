import { useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { SystemColors } from "@snackpass/design-system";

import { defaultMenuItem } from "#promotion/utils/constants";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import { FormItemsMultiSelector } from "#promotion/components/shared/form-items-multi-selector";
import { FIELD_NAMES, QualifierValues } from "#promotion/utils/types";
import colors from "#reusable/colors/colors.json";

type Props = {
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    disabled?: boolean;
    disabledMessage?: string;
};

export const RequirePurchase = ({
    required,
    recommended,
    autofilled,
}: Props) => {
    const { watch, setValue, getFieldState, trigger } = useFormContext();
    const { error } = getFieldState(FIELD_NAMES.REQUIRED_PURCHASE_ITEMS);
    const disabled =
        watch(FIELD_NAMES.DISCOUNT_QUALIFIER) === QualifierValues.ANY_ITEM;
    const requiredPurchase = watch(FIELD_NAMES.REQUIRED_PURCHASE);

    useEffect(() => {
        if (!requiredPurchase || disabled) {
            setValue(FIELD_NAMES.REQUIRED_PURCHASE, false);
            setValue(FIELD_NAMES.REQUIRED_PURCHASE_ITEMS, [defaultMenuItem]);
        }
        void trigger(FIELD_NAMES.REQUIRED_PURCHASE_ITEMS);
    }, [requiredPurchase, disabled]);

    return (
        <Container>
            {disabled ? (
                <Note>
                    <NoteText>
                        <b>Note: </b>{" "}
                        {
                            'To enable Require Purchase, please set the discounted Qualifying Items to "Specific items or categories"'
                        }
                    </NoteText>
                </Note>
            ) : null}
            <FormToggle
                name="Require Purchase (Buy One, Get One or Combo Deals)"
                descriptor="Customers must buy at least one full-price item to use this promo"
                fieldName={FIELD_NAMES.REQUIRED_PURCHASE}
                divider={!requiredPurchase}
                required={required}
                recommended={recommended}
                autofilled={autofilled}
                disabled={disabled}
                disabledMessage={
                    'Must have specific discounted items. "Any Item" is not allowed.'
                }
            />
            {error ? <ErrorMessage>* {error?.message}</ErrorMessage> : null}
            {requiredPurchase ? (
                <FormItemsMultiSelector
                    fieldName={FIELD_NAMES.REQUIRED_PURCHASE_ITEMS}
                    itemLabelDescription={
                        "Choose something that represents the required item. (ex. Sandwich, Large Drink)"
                    }
                    itemLabelPlaceholder={"Required Entrée"}
                    addItemLabel={"Add Additional Required Item"}
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    margin-bottom: 24px;
`;

const ErrorMessage = styled.p`
    margin: 4px 0 24px 0;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
`;

const Note = styled.div`
    margin-top: 12px;
    margin-bottom: 16px;
    height: 48px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 20px 16px;
`;
const NoteText = styled.p`
    color: ${SystemColors.v2.salt60.light};
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
`;
