/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction } from "react";
import { useSelector } from "react-redux";
import {
    UseExpandedRowProps,
    UseTableCellProps,
    UseTableRowProps,
    useExpanded,
    useGlobalFilter,
} from "react-table-7";
import { AddonGroup, ScreenState } from "@snackpass/snackpass-types";
import { useDrag, useDrop } from "react-dnd";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import styled from "styled-components";
import _, { debounce } from "lodash";
import { PartialDeep } from "type-fest";
import { produce } from "immer";

import { ReactComponent as DragDots } from "src/assets/icons/drag-dots.svg";
import colors from "#reusable/colors/colors.json";
import { getActiveStoreIs3PIntegrated } from "src/redux/selectors";
import {
    UseTable7TypedColumnOptions,
    useTable7Typed,
} from "src/utils/useTable7Typed";

type TableRowHeadingCellHTMLAttributes =
    React.HTMLAttributes<HTMLTableCellElement>;

type TableRowHeadingCellProps = TableRowHeadingCellHTMLAttributes & {
    depth: number;
    isDragging: boolean;
    canExpand: boolean;
    isExpanded: boolean;
    getToggleRowExpandedProps: Function;
    dragRef: React.RefObject<HTMLTableCellElement>;
    isProductInEditMode: boolean;
};

const TableRowHeadingCell = React.forwardRef<
    HTMLTableCellElement,
    TableRowHeadingCellProps
>(
    (
        {
            depth,
            canExpand,
            getToggleRowExpandedProps,
            isExpanded,
            isDragging,
            children,
            dragRef,
            isProductInEditMode,
            ...props
        },
        ref,
    ) => {
        const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);

        return (
            <span
                className={classNames({
                    card: true,
                    isSubRow: depth !== 0,
                    isNotSubRow: depth == 0,
                    isExpanded: isExpanded,
                    isMobile: isMobile,
                })}
            >
                <th
                    ref={ref}
                    {...props}
                    className={classNames({
                        TableRowHeadingCell: true,
                        isDragging: isDragging,
                        isExpanded: isExpanded,
                    })}
                    scope="row"
                    role="rowheader"
                >
                    <span
                        className="TableRowHeadingCellWrapper"
                        style={{
                            // @ts-ignore
                            "--depth": depth,
                        }}
                    >
                        {!thirdPartyEnabled && (
                            <span
                                ref={dragRef}
                                className={classNames({
                                    TableRowHeadingCellDragHandler: true,
                                    isDragging: isDragging,
                                    isSubRow: depth !== 0,
                                })}
                            >
                                <DragDots />
                            </span>
                        )}
                        <span className="TableRowHeadingCellChildren">
                            {children}
                        </span>
                    </span>
                </th>
            </span>
        );
    },
);

type TableRowHTMLAttributes = React.HTMLAttributes<HTMLTableRowElement>;

type TableRowProps = TableRowHTMLAttributes & {
    children: React.ReactNode;
    row: UseTableRowProps<AddonGroup> & UseExpandedRowProps<AddonGroup>;
    index: number;
    onMove?: Function;
    headerCell: UseTableCellProps<AddonGroup>;
    parentIndex?: string;
    isProductInEditMode: boolean;
};

const TableRow: React.FC<TableRowProps> = ({
    children,
    row,
    index,
    onMove,
    headerCell,
    parentIndex,
    isProductInEditMode,
    ...forwardedProps
}) => {
    // Create a row type out of the row depth and parent index
    // to restrict drag and drop to elements from the same depth + branch.
    const rowType = React.useRef(`${row.depth}-${parentIndex}`).current;
    const dropRef = React.useRef<HTMLTableRowElement>(null);
    const dragRef = React.useRef<HTMLTableCellElement>(null);

    //@ts-ignore
    const [, drop] = useDrop({
        accept: rowType,
        hover: debounce((item, monitor) => {
            if (!dropRef.current) {
                return;
            }
            // global table indices
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            if (clientOffset === null) {
                return;
            }

            // @ts-ignore
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            if (onMove) {
                onMove({ dragIndex, hoverIndex, row });
            }

            item.index = hoverIndex;
        }, 100),
        collect: (monitor) => ({
            canAccept: monitor.getItemType() === rowType,
            isOver: monitor.isOver(),
        }),
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: rowType,
        item: { type: rowType, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    React.useEffect(() => {
        preview(drop(dropRef));
        drag(dragRef);
    });

    return (
        <tr
            {...forwardedProps}
            ref={dropRef}
            className={classNames({
                TableRow: true,
                isDragging,
            })}
        >
            <TableRowHeadingCell
                {...headerCell.getCellProps()}
                depth={row.depth}
                canExpand={row.canExpand}
                getToggleRowExpandedProps={row.getToggleRowExpandedProps}
                isExpanded={row.isExpanded}
                isDragging={isDragging}
                dragRef={dragRef}
                isProductInEditMode={isProductInEditMode}
            >
                {headerCell.render("Cell")}
            </TableRowHeadingCell>
            {children}
        </tr>
    );
};

// Table

type TableProps<T extends object> = {
    columns: UseTable7TypedColumnOptions<
        typeof useGlobalFilter | typeof useExpanded,
        AddonGroup
    >[];
    data: AddonGroup[];
    setData: React.Dispatch<SetStateAction<PartialDeep<AddonGroup>[]>>;
    children?: React.ReactNode;
    isProductInEditMode: boolean;
};

export const Table = <T extends object>({
    data,
    columns,
    setData,
    isProductInEditMode,
}: TableProps<T>) => {
    // Show duplicate modifierGroups/addonGroups if exists
    // create a map with the occurrence of each addonGroup
    // if addon no duplicates(ideal), each addonGroup 1 occurrence, otherwise the occurrence should be bigger than 1
    // we will subtract 1 while rendering each row, the idea here is to give every duplicate addonGroup a unique occurrence number so that the react table can show it on the UI
    const occurrenceMap = _.countBy(data, "_id");
    const getProductOccurrence = (id: string) => {
        occurrenceMap[id] = occurrenceMap[id] - 1;
        return occurrenceMap[id];
    };
    const resolveRowId = (row: AddonGroup) =>
        `${row?._id} ${row?.name} ${getProductOccurrence(row._id)}`;

    const handleRowMove = React.useCallback(
        ({
            dragIndex,
            hoverIndex,
            row,
        }: {
            dragIndex: number;
            hoverIndex: number;
            row: UseTableRowProps<AddonGroup> & UseExpandedRowProps<AddonGroup>;
        }) => {
            const index = row?.index;
            const targetIndex = dragIndex > hoverIndex ? index + 1 : index - 1;
            const original = row?.original;
            const hasDuplicates = (arr: PartialDeep<AddonGroup>[]) =>
                _.uniqBy(arr, "_id").length !== arr.length;
            setData((current) => {
                // remove the duplicate addonGroups if exists
                if (hasDuplicates(current)) {
                    const removeAddonGroupDuplicates = produce(
                        current,
                        (draft) => (draft = _.uniqBy(draft, "_id")),
                    );

                    return removeAddonGroupDuplicates;
                }

                const result = produce(current, (draft) => {
                    draft.splice(index, 1);
                    draft.splice(targetIndex, 0, original);
                });
                // make sure addonGroups have same length and no duplicates after update and the order changed, if not, return original state and don't update
                if (
                    !_.isEqual(current, result) &&
                    current.length === result.length &&
                    !hasDuplicates(result)
                ) {
                    return result;
                }
                return current;
            });
        },
        [],
    );

    const { getTableProps, getTableBodyProps, rows, prepareRow } =
        useTable7Typed(
            {
                columns,
                data,
                getRowId: resolveRowId,
                // @ts-expect-error
                autoResetExpanded: false,
            },
            useGlobalFilter,
            useExpanded,
        );

    return (
        <StyleForDnd>
            <table {...getTableProps()}>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        const [cell, ...cells] = row.cells;
                        return (
                            <TableRow
                                {...row.getRowProps()}
                                isProductInEditMode={isProductInEditMode}
                                index={index}
                                id={row.id}
                                row={row}
                                onMove={handleRowMove}
                                headerCell={cell}
                            >
                                {cells.map((cell) => (
                                    <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </TableRow>
                        );
                    })}
                </tbody>
            </table>
        </StyleForDnd>
    );
};

const StyleForDnd = styled.div`
    table {
        border-collapse: collapse;
        width: 100%;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
    }

    td {
        font-size: 16px;
        font-weight: normal;
        line-height: 1rem;
        padding: 2rem 7rem;
    }

    thead th {
        display: none;
    }

    thead th:first-of-type {
        border-left: none;
    }

    thead th:last-of-type {
        border-right: none;
    }

    thead th.no-wrap {
        white-space: nowrap;
    }

    td,
    th[scope="row"],
    th[scope="rowgroup"],
    [role="rowheader"] {
        color: var(--grey-12);
    }

    td {
        text-align: right;
    }

    th[scope="rowgroup"],
    th[scope="row"],
    [role="rowheader"] {
        min-width: 320px;
        min-height: 4rem;
        max-height: 15rem;
    }

    .TableRow.isDragging {
        cursor: grabbing;
        transition: ease-in-out;
    }

    .TableRowHeadingCell {
        text-align: left;
        transition: ease-in-out;
    }

    .TableRowHeadingCell.isDragging {
        opacity: 0.3;
        @media ${ScreenState.MOBILE} {
            opacity: 1;
            transform: scale(1.02);
            border: none;
            box-shadow: 1px 0 5px -2px #888;
        }
    }

    .TableRowHeadingCellWrapper {
        --depth: 1;
    }

    .TableRowHeadingCellButtonActions.deleteIcon {
        position: absolute;
        top: 30%;
        right: 10px;
        cursor: pointer;

        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }
    .TableRowHeadingCellButtonActions.editIcon {
        position: absolute;
        top: 30%;
        right: 40px;
        cursor: pointer;

        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }

    .card {
        position: relative;
    }

    .card.isSubRow {
        border: none;
        border-bottom: 1px solid ${colors["neutral-400"]};
    }

    .card.isNotSubRow {
        border: none;
        border-bottom: 1px solid ${colors["neutral-400"]};
        border-radius: 0;
    }

    .TableRowHeadingCellDragHandler {
        cursor: grab;
        position: absolute;
        top: 30%;
        left: 0;
        width: 3rem;
        height: 3rem;
        text-align: center;
    }

    .TableRowHeadingCellDragHandler.isDragging {
        cursor: grabbing;
    }
`;
