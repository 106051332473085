import React, { useState } from "react";

import { toast } from "sonner";

import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
} from "src/@/components/ui/dialog";
import { Button } from "src/@/components/ui/button";

type SearchProps = {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (customPickupTime: number) => Promise<void>;
};

export const CustomTimeModal = ({ isOpen, onClose, onSelect }: SearchProps) => {
    const [value, setValue] = useState(0);

    const handleSelect = async (value: number) => {
        try {
            await onSelect(value);
            onClose();
        } catch (error) {
            toast.error(
                "Failed to update the time. Please check your connection and try again.",
            );
        }
    };
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Delay Scheduled Time By</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col py-4">
                    <input
                        value={value}
                        onChange={(e) => setValue(parseInt(e.target.value))}
                        placeholder="10 mins..."
                        className="w-full rounded-md p-3"
                        type="number"
                        min={0}
                    />
                </div>
                <DialogFooter className="flex w-full flex-1 gap-2">
                    <Button
                        className="flex-1"
                        onClick={onClose}
                        variant="outline"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="flex-1"
                        onClick={async () => await handleSelect(value)}
                    >
                        Confirm
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
