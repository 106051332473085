import { ArrowTopRightIcon } from "@radix-ui/react-icons";

import { Button } from "src/@/components/ui/button";

interface OrderGiftCardAccessoryButtonProps {
    link: string;
    title: string;
}

const OrderGiftCardAccessoryButton: React.FC<
    OrderGiftCardAccessoryButtonProps
> = ({ link, title }) => (
    <Button
        type="button"
        className="cursor-pointer bg-black text-white"
        onClick={() => {
            window.open(link, "_blank");
        }}
    >
        {title}
        <ArrowTopRightIcon className="h-6 w-6 pl-2" />
    </Button>
);

export default OrderGiftCardAccessoryButton;
