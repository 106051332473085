import api from "src/api/rest";
import { useReportsQuery } from "#reports/hooks/useReportsQuery";

export function useSalesSummary() {
    const { data, isLoading, error, refetch } = useReportsQuery(
        "sales-summary",
        api.reports.getSalesSummary,
    );
    return {
        data: data?.data.salesSummary,
        isLoading,
        error,
        refetch,
    };
}
