export enum ChannelType {
    CustomerReadyScreen = "CustomerReadyScreen",
    FullScreenGallery = "FullScreenGallery",
    InfiniteScrollImage = "InfiniteScrollImage",
    Video = "Video",
    StaticImage = "StaticImage",
}

export enum ChannelDisplay {
    CustomerPickupScreen = "CustomerPickupScreen",
    MenuBoard = "MenuBoard",
    GalleryBoard = "GalleryBoard",
    Other = "Other",
}

export const ChannelDisplayGroup = {
    [ChannelType.CustomerReadyScreen]: ChannelDisplay.CustomerPickupScreen,
    [ChannelType.InfiniteScrollImage]: ChannelDisplay.MenuBoard,
    [ChannelType.FullScreenGallery]: ChannelDisplay.GalleryBoard,
    [ChannelType.StaticImage]: ChannelDisplay.Other,
    [ChannelType.Video]: ChannelDisplay.Other,
};

export const ChannelName = {
    [ChannelType.CustomerReadyScreen]: "Pickup Board",
    [ChannelType.FullScreenGallery]: "Gallery Board",
    [ChannelType.Video]: "Video",
    [ChannelType.StaticImage]: "Static Image",
    [ChannelType.InfiniteScrollImage]: "Tiles",
};

export const CHANNEL_TYPE_ENUM = Object.values(ChannelType);

export const OrdersChannelOptions = [
    {
        value: ChannelType.CustomerReadyScreen,
        label: "Customer Ready Screen",
    },
];

export const DMBChannelOptions = [
    { value: ChannelType.InfiniteScrollImage, label: "Tiles" },
];

export const GalleryBoardOptions = [
    { value: ChannelType.FullScreenGallery, label: "Full Screen Gallery" },
];

export const OtherChannelOptions = [
    { value: ChannelType.Video, label: "Video" },
    { value: ChannelType.StaticImage, label: "Image" },
];

export const channelDisplayOptions = [
    {
        label: "Pickup Board",
        value: ChannelDisplay.CustomerPickupScreen,
    },
    {
        label: "Menu Board",
        value: ChannelDisplay.MenuBoard,
    },
    // {
    //     label: "Gallery Board",
    //     value: ChannelDisplay.GalleryBoard
    // },
    {
        label: "Content Board",
        value: ChannelDisplay.Other,
    },
];

export const groupedChannelOptions = [
    {
        label: "Customer Pickup Screen",
        options: OrdersChannelOptions,
    },
    {
        label: "Menu Board",
        options: DMBChannelOptions,
    },
    {
        label: "Other",
        options: OtherChannelOptions,
    },
];

export enum ChannelTheme {
    LIGHT = "Light",
    DARK = "Dark",
}

export const DMBThemeOptions = [
    { value: ChannelTheme.LIGHT, label: "Light" },
    { value: ChannelTheme.DARK, label: "Dark" },
];

export enum Font {
    Inter = "Inter",
    Literata = "Literata",
    Satoshi = "Satoshi",
    Zodiak = "Zodiak",
    PilcrowRounded = "PilcrowRounded",
    MonaSans = "Mona-Sans",
    Unbounded = "Unbounded",
    Bitter = "Bitter",
    Vollkorn = "Vollkorn",
    Antonio = "Antonio",
    SplineSans = "Spline-Sans",
    Supreme = "Supreme",
    Alpino = "Alpino",
    Bevellier = "Bevellier",
    PlusJakartaSans = "Plus-Jakarta",
    Teko = "Teko",
}

export enum Voice {
    LIANNA = "lhaxjiPTIcNO0GNEl4uM",
    DAVID = "zKwoXTAlqUwPULnc39aL",
    TYRELL = "WaCUI7bZkGODe2MPSAMu",
    DAKOTA = "P7x743VjyZEOihNNygQ9",
    VALERIA = "9oPKasc15pfAbMr7N6Gs",
    ANTONIO = "UfCRQShYrGoa6BYs8jnn",
    AMELIA = "w75jSo40IU4IPsNaIM8W",
    COCO = "ngiiW8FFLIdMew1cqwSB",
    VIKRANT = "y6Ao4Y93UrnTbmzdVlFc",
    PRIYA = "2zRM7PkgwBPiau2jvVXc",
}

const DEFAULT_VOICE = Voice.LIANNA;

export type VoiceDetails = {
    name: string;
    origin: string;
};

export const voiceDetails: Record<Voice, VoiceDetails> = {
    [Voice.LIANNA]: { name: "Lianna", origin: "American" },
    [Voice.DAVID]: { name: "David", origin: "American" },
    [Voice.TYRELL]: { name: "Tyrell", origin: "American" },
    [Voice.DAKOTA]: { name: "Dakota", origin: "American, Southern" },
    [Voice.VALERIA]: { name: "Valeria", origin: "Argentine" },
    [Voice.ANTONIO]: { name: "Antonio", origin: "Latin American" },
    [Voice.AMELIA]: { name: "Amelia", origin: "British" },
    [Voice.COCO]: { name: "Coco", origin: "Chinese" },
    [Voice.VIKRANT]: { name: "Vikrant", origin: "Indian" },
    [Voice.PRIYA]: { name: "Priya", origin: "Indian" },
};

export enum Layout {
    CLASSIC = "CLASSIC",
    // MODERN = "MODERN"
}

type AllChannelOptions = {
    onlyShowFirstInitial?: boolean;
    imageURL?: string | null;
    videoURL?: string | null;
    videoThumbnailURL?: string | null;
    cloudinaryResult?: unknown;
    pinnedCategories?: { category: string; order: number }[];
    pinnedProducts?: { productId: string; badge: string; order: number }[];
    pageInterval?: number;
    hideSoldOut?: boolean;
    hideDescription?: boolean;
    font?: Font;
    fontSize?: number;
    layout?: Layout;
    audioOn?: boolean;
    voice?: Voice;
    theme?: ChannelTheme;
    headerColor?: string;
    menuBoardBackgroundColor?: string;
    productBackgroundColor?: string;
    enableScroll?: boolean;
    columns?: number;
    imageHeight?: number;
    imageWidth?: number;
};

export type ChannelOption = {
    channel: ChannelType;
    options: Partial<AllChannelOptions>;
};

export type SnackTVDeviceDetails = {
    id: string;
    store: string;
    serial: string;
    screenName: string;
    channel: ChannelType;
    channelOptions: ChannelOption[];
    lastPing: Date;
};

export const MAX_FILE_SIZE = 10000 * 1024; // 10 MB

export type CustomerPickupScreenOptions = {
    onlyShowFirstInitial: boolean;
    font: Font;
    fontSize: number;
    layout: Layout;
    audioOn: boolean;
    voice: Voice;
};

export const DEFAULT_CHANNEL_OPTIONS: CustomerPickupScreenOptions = {
    fontSize: 32,
    font: Font.Satoshi,
    audioOn: true,
    voice: DEFAULT_VOICE,
    layout: Layout.CLASSIC,
    onlyShowFirstInitial: false,
};

const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const FONT_OPTIONS = Object.entries(Font)
    .map(([key, value]) => ({
        label: key,
        value: value as Font,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const LAYOUT_OPTIONS = Object.entries(Layout)
    .map(([key, value]) => ({
        label: capitalize(key),
        value: value as Layout,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const VOICE_OPTIONS = Object.entries(Voice).map(([key, value]) => ({
    label: `${voiceDetails[value].name} (${voiceDetails[value].origin})`,
    value: value as Voice,
}));

export const DEFAULT_HEADER_LIGHT_COLOR = "#0f0f0f";
export const DEFAULT_HEADER_DARK_COLOR = "#ffffff";
export const DEFAULT_BG_LIGHT_COLOR = "#FBF9F5";
export const DEFAULT_BG_DARK_COLOR = "#000000";
export const DEFAULT_TILE_BG_LIGHT_COLOR = "#F5F2EA";
export const DEFAULT_TILE_BG_DARK_COLOR = "#2C2C2C";
