import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";

import { firebaseAuth } from "#app/firebase";
import { useAppDispatch } from "src/redux/hooks";
import { clearUser } from "src/redux/slices";

export function useFirebaseAuth() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (updatedUser) => {
            if (!updatedUser) {
                dispatch(clearUser());
                return;
            }
        });
        return unsubscribe;
    }, [dispatch]);
}
