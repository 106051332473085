import { useCallback, useMemo } from "react";
import { GraphQLError } from "graphql/error";
import { toast } from "sonner";

import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStoreId } from "src/redux/slices";
import { useCreateServiceAreaMutation } from "src/api/graphql/generated/types";
import {
    fromGQLServiceArea,
    toGQLServiceAreaLayout,
} from "#table-editor/serviceAreas";
import { Element } from "#table-editor/canvas/Element";
import { logAndSendError } from "src/utils/errors";

export function useCreateServiceArea() {
    const editor = useEditorStore();
    const storeId = useAppSelector(getActiveStoreId);

    const [create, { loading, reset }] = useCreateServiceAreaMutation();

    const createServiceArea = useCallback(async () => {
        const activeServiceArea = editor.getState().activeServiceArea;
        const canvas = editor.getState().canvas;
        const elements = editor.getState().elements;

        if (activeServiceArea && canvas) {
            const layoutElements = toGQLServiceAreaLayout(
                elements,
                canvas.getObjects(Element.type) as Element[],
            );

            try {
                const newLayout = await create({
                    variables: {
                        storeId,
                        input: {
                            name: activeServiceArea.name,
                            layout: layoutElements,
                        },
                    },
                });

                if (newLayout.data?.createServiceArea) {
                    const serviceArea = fromGQLServiceArea(
                        newLayout.data.createServiceArea,
                    );
                    editor.getState().mergeActiveServiceArea(serviceArea);
                    toast.success("Successfully saved service area");
                }
            } catch (err) {
                logAndSendError(err);
                if (err instanceof GraphQLError) {
                    toast.error(`Unexpected error: ${err.message}`);
                } else {
                    toast.error(`There was an error completing this action`);
                }
            } finally {
                reset();
            }
        }
    }, [reset, create, editor, storeId]);

    return useMemo(
        () => ({
            loading,
            createServiceArea,
        }),
        [loading, createServiceArea],
    );
}
