import { styled } from "styled-components";
import { SystemColors } from "@snackpass/design-system";

import { getInitials } from "#employees/helpers";

const Initials = styled.div<{ backgroundColor?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-family: Inter;
    font-style: italic;
    font-size: 0.875rem;
    line-height: 18px;
    color: ${SystemColors.v1.gray40};
    border-radius: 48px;
    width: 40px;
    height: 40px;
    background: ${({ backgroundColor }) =>
        backgroundColor ?? SystemColors.v1.melon20};
`;

type InitialsAvatarProps = {
    firstName: string;
    lastName: string;
    backgroundColor?: string;
};

export const InitialsAvatar = ({
    firstName,
    lastName,
    backgroundColor,
}: InitialsAvatarProps) => (
    <Initials backgroundColor={backgroundColor}>
        {getInitials(firstName, lastName)}
    </Initials>
);
