import { useFormContext } from "react-hook-form";

import { Card } from "src/@/components/ui/card";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import CampaignFormItemLayout from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/CampaignFormItemLayout";

function ContactInfoCardInput() {
    const { control } = useFormContext();
    return (
        <Card>
            <CampaignFormItemLayout
                label="Personal Contact Info"
                description={
                    "We'll contact you in case the Campaign Registry needs to \
                        collect additional information."
                }
            >
                <div className="flex flex-row gap-3 pt-3">
                    <div className="flex w-1/2 flex-col gap-3">
                        <FormLabel>
                            <p className="font-semibold">First Name</p>
                        </FormLabel>
                        <FormField
                            control={control}
                            name="contactInfo.firstName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="First Name"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="flex w-1/2 flex-col gap-3">
                        <FormLabel>
                            <p className="font-semibold">Last Name</p>
                        </FormLabel>
                        <FormField
                            control={control}
                            name="contactInfo.lastName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Last Name"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <FormField
                    control={control}
                    name="contactInfo.email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <p className="py-3 font-semibold">Email</p>
                            </FormLabel>
                            <FormControl>
                                <Input {...field} placeholder="Email" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={control}
                    name="contactInfo.phoneNumber"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <p className="py-3 font-semibold">
                                    Phone Number
                                </p>
                            </FormLabel>
                            <FormControl>
                                <Input {...field} placeholder="555-555-5555" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </CampaignFormItemLayout>
        </Card>
    );
}

export default ContactInfoCardInput;
