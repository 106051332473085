import { DateTime } from "luxon";
import { IDiscount, IPromotion, PromoTypes } from "@snackpass/snackpass-types";
import moment from "moment-timezone";

import { PromoShortcut } from "#promotion/utils/types";
import {
    Baby,
    Copy,
    Gift,
    GraduationCap,
    Hourglass,
    PartyPopper,
    PlusCircle,
    Share,
    TicketPercent,
    Trophy,
    LucideIcon,
} from "lucide-react";
import { LightningBoltIcon } from "@radix-ui/react-icons";

const iconProps = {
    size: 20,
    strokeWidth: 1.5,
};

const IconWrapper = ({
    Icon,
}: {
    Icon: LucideIcon | typeof LightningBoltIcon;
}) => (
    <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
        <Icon {...iconProps} />
    </div>
);

export const getInfoForShortcutType = (
    type: PromoShortcut,
): [React.ReactElement, string, string, boolean, string] => {
    switch (type) {
        case PromoShortcut.StartFromScratch:
            return [
                <IconWrapper Icon={PlusCircle} />,
                "Start From Scratch",
                "Create a custom promotion.",
                true,
                "",
            ];
        case PromoShortcut.Bogo:
            return [
                <IconWrapper Icon={Copy} />,
                "Buy One, Get One",
                "Offer a discount to customers that make a qualifying purchase.",
                true,
                "",
            ];
        case PromoShortcut.HappyHour:
            return [
                <IconWrapper Icon={Hourglass} />,
                "Happy Hour",
                "Boost sales during slower times of the day with a recurring discount.",
                true,
                "",
            ];
        case PromoShortcut.PromoCode:
            return [
                <IconWrapper Icon={TicketPercent} />,
                "Promo Code",
                "Create a discount code to share on social media or marketing materials.",
                true, // This was previously using a hook, we'll need to handle this differently
                "",
            ];
        case PromoShortcut.FirstTime:
            return [
                <IconWrapper Icon={Baby} />,
                "First-time Customer",
                "Attract new business and convert curious guests into customers.",
                true,
                "",
            ];
        case PromoShortcut.DealDrop:
            return [
                <IconWrapper Icon={LightningBoltIcon as LucideIcon} />,
                "Deal Drop",
                "Create hype and drive sales with a max-use, limited-time promotion.",
                true,
                "",
            ];
        case PromoShortcut.Student:
            return [
                <IconWrapper Icon={GraduationCap} />,
                "Student",
                "Become a campus favorite and an easy choice for young consumers.",
                true,
                "",
            ];
        case PromoShortcut.Referral:
            return [
                <IconWrapper Icon={Share} />,
                "Referral",
                "Go viral and get your customers to market to their friends for you.",
                false, // TODO: Remove this once we have the new Referral flow
                "Please reach out to support via the Help Center in the Resources tab (above Settings) to create this type of promotion.",
            ];
        case PromoShortcut.Reward:
            return [
                <IconWrapper Icon={Trophy} />,
                "Punchcard Reward",
                "Increase customer retention with a loyalty points program.",
                true,
                "",
            ];
        case PromoShortcut.Party:
            return [
                <IconWrapper Icon={PartyPopper} />,
                "Party (Group Buy)",
                "Encourage customers to invite friends for a discount.",
                false, // TODO: Remove this once we have the new Party flow
                "Please reach out to support via the Help Center in the Resources tab (above Settings) to create this type of promotion.",
            ];
        case PromoShortcut.GiftCard:
            return [
                <IconWrapper Icon={Gift} />,
                "Gift Cards",
                "Offer discounts on gift cards to drive sales.",
                true,
                "",
            ];
    }
};

export const convertScheduleDateToLuxon = (date: string): string =>
    DateTime.fromJSDate(moment(date).toDate()).toFormat("HH:mm");

export const isPromoTypeChangeError = (message: string) =>
    /**
     * There is a very specific validation error thrown for the 'type' field
     * when a promo's derived type is different from the promo's current type
     * If/when support for changing a promo's type after creation is added, then this can be removed.
     */
    message.includes("Can't change promotion type!");

export const formatPromoType = (row: IPromotion) => {
    let type = "";

    switch (row.type) {
        case PromoTypes.Discount:
            if (row.hours) {
                type = `Discount · Happy Hour`;
                break;
            }
            type = "Discount";
            break;
        case PromoTypes.Deal:
            if (row.hours) {
                type = `Deal · Happy Hour`;
                break;
            }
            type = "Deal";
            break;
        case PromoTypes.PromoCode:
            type = `Promo Code · ${row.code}`;
            break;
        case PromoTypes.Reward:
            type = `Reward · ${row.pointsRequired} points`;
            break;
        case PromoTypes.Referral:
            type = "Referral";
            break;
        case PromoTypes.Birthday:
            type = "Birthday";
            break;
        case PromoTypes.Retargeting:
            type = "Retargeting";
            break;
        case PromoTypes.TimeBomb:
            type = "Time Bomb";
            break;
        case PromoTypes.Party:
            type = "Party";
            break;
    }

    return type;
};

export const formatPromoDetails = (row: IPromotion) => {
    if (row.type === PromoTypes.Deal) {
        const discountedDealItems = row.dealItems.filter(
            (deal) => deal.discount !== undefined && !!deal.discount,
        );
        return `${discountedDealItems.length} item(s)`;
    }
    if (row.type === PromoTypes.GiftCard) {
        return "Gift Cards";
    }

    return row.storewide
        ? `All items`
        : `${
              row.categories.length
                  ? `${row.categories.length} ${
                        row.categories.length > 1 ? "categories" : "category"
                    } ${row.productIds.length ? "· " : ""}`
                  : ""
          } ${
              row.productIds.length
                  ? `${row.productIds.length} ${
                        row.productIds.length > 1 ? "products" : "product"
                    }`
                  : ""
          }`;
};

const getDiscount = (discount: IDiscount | null | undefined) => {
    if (discount?.dollarsOff !== undefined) {
        return `$${discount?.dollarsOff} off`;
    } else if (discount?.percentOff !== undefined) {
        return `${discount?.percentOff}% off`;
    } else if (discount?.newPrice !== undefined) {
        return `$${discount?.newPrice} each`;
    } else if (discount?.isDoublePoints !== undefined) {
        return "Double Points";
    }
};

export const formatPromoDiscount = (row: IPromotion) => {
    if (row.type === PromoTypes.Deal) {
        const discountedDealItems = row.dealItems.filter(
            (deal) => deal.discount !== undefined && !!deal.discount,
        );
        if (discountedDealItems.length > 1) {
            return "Multiple discounts";
        }
        return getDiscount(discountedDealItems[0]?.discount) || "";
    }

    return getDiscount(row.discount) || "";
};
