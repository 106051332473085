import React from "react";
import { Button } from "src/@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu";
import { PlusIcon } from "@radix-ui/react-icons";
import { PromoShortcut } from "#promotion/utils/types";
import { getInfoForShortcutType } from "#promotion/lib";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { getActiveStore, useActiveUser } from "src/redux/selectors";
import { useAppSelector } from "src/redux/hooks";
import { usePromotionForm } from "#promotion/utils/edit";

export const CreatePromoButton = () => {
    const activeStore = useAppSelector(getActiveStore);
    const user = useActiveUser();
    const { editPromotionFormFromShortcut } = usePromotionForm();

    const promoOptions = [
        PromoShortcut.StartFromScratch,
        PromoShortcut.Bogo,
        PromoShortcut.HappyHour,
        PromoShortcut.PromoCode,
        PromoShortcut.FirstTime,
        PromoShortcut.DealDrop,
        PromoShortcut.Reward,
        PromoShortcut.Student,
        PromoShortcut.GiftCard,
    ];

    const onClickShortcut = (type: PromoShortcut) => {
        trackSegmentEvent(SegmentEvents.CLICKED_PROMO_SHORTCUT, {
            store_id: activeStore?._id || null,
            user: { id: user?._id, name: user?.name },
            type,
        });
        editPromotionFormFromShortcut(type);
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button>
                    <PlusIcon className="w-4 h-4 mr-2" />
                    Create Promo
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className="w-80 max-h-[80vh] overflow-y-auto"
                align="start"
                sideOffset={4}
            >
                {promoOptions.map((type) => {
                    const [Icon, title, description, enabled, disabledText] =
                        getInfoForShortcutType(type);
                    return (
                        <DropdownMenuItem
                            key={type}
                            className="flex items-start p-2 space-x-3 cursor-pointer data-[disabled]:opacity-50"
                            disabled={!enabled}
                            onClick={() => enabled && onClickShortcut(type)}
                        >
                            {Icon}
                            <div className="flex flex-col flex-grow min-w-0">
                                <span className="font-medium truncate">
                                    {title}
                                </span>
                                <span className="text-sm text-muted-foreground line-clamp-2">
                                    {description}
                                </span>
                                {!enabled && (
                                    <span className="text-sm text-red-500 line-clamp-2">
                                        {disabledText}
                                    </span>
                                )}
                            </div>
                        </DropdownMenuItem>
                    );
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
