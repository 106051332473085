import { isTablet } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { ChevronRightIcon } from "@radix-ui/react-icons";

import DownloadPurchasesCSVButton from "#download-purchase-button";
import { Button } from "src/@/components/ui/button";
import { Routes } from "#navigation/routes";

import { ReferralButton } from "./components/referral";
import { Warnings } from "./components/warnings";
import { Insights } from "./components/insights";
import { FeatureGrid } from "./components/feature-grid";
import { ComparisonPicker } from "./components/filters/comparison-picker";
import { ProviderPicker } from "./components/filters/provider-picker";
import { ChannelPicker } from "./components/filters/channel-picker";
import { DashboardPaymentTypePicker } from "./components/filters/payment-type-picker";
import { CurrentPeriodPicker } from "./components/filters/current-period-picker";
import { SectionHeader } from "./components/insights/section-header";
import {
    DashboardFiltersProvider,
    useDashboardContext,
} from "./hooks/use-dashboard-context";

const DashboardContent = () => {
    const history = useHistory();
    const { currentPeriodStartEnd } = useDashboardContext();
    return (
        <div className="space-y-8 sm:p-12 pt-4 pb-8 p-6 mb-20">
            <ReferralButton />
            <Warnings />
            <div className="space-y-4">
                <div className="relative flex items-center space-x-2 whitespace-nowrap">
                    <CurrentPeriodPicker />
                    <ComparisonPicker />
                    <ChannelPicker />
                    <ProviderPicker />
                    <DashboardPaymentTypePicker />
                    {!isTablet && currentPeriodStartEnd && (
                        <div className="flex flex-1 justify-end">
                            <DownloadPurchasesCSVButton
                                startDate={currentPeriodStartEnd?.startDate}
                                endDate={currentPeriodStartEnd?.endDate}
                                showTotals={true}
                            />
                        </div>
                    )}
                </div>
                <Insights />
            </div>

            <div className="flex justify-end">
                <Button
                    variant="outline"
                    onClick={() => history.push(Routes.ReportsOverview)}
                >
                    View All Reports
                    <ChevronRightIcon className="ml-2 h-4 w-4" />
                </Button>
            </div>
            <div className="space-y-8">
                <SectionHeader title="Featured" />
                <FeatureGrid />
            </div>
        </div>
    );
};

export const DashboardGrid = () => {
    return (
        <DashboardFiltersProvider>
            <DashboardContent />
        </DashboardFiltersProvider>
    );
};
