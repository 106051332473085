import React, { Dispatch, SetStateAction } from "react";
import type { Moment } from "moment";
import { isMobile } from "react-device-detect";

import { SpecialHoursItemType } from "#reusable/special-hours/helper";
import { DesktopMenuHourRow } from "#menu-editor/multi-menus/screens/multi-menu-settings/components/desktop-menu-hours-row";
import { MobileMenuHourRow } from "#menu-editor/multi-menus/screens/multi-menu-settings/components/mobile-menu-hours-row";

type SpecialHourProps = {
    setMenuHours: Dispatch<SetStateAction<SpecialHoursItemType[]>>;
    menuHours: SpecialHoursItemType[];
};

export const MenuHourRows: React.FC<SpecialHourProps> = ({
    setMenuHours,
    menuHours,
}) => {
    const removeRow = (id: number) => {
        if (menuHours.length > 1) {
            const removedArr = [...menuHours].filter((item) => item.id !== id);
            setMenuHours(removedArr);
        }
    };

    const updateRowHours = (idx: number, start?: Moment, end?: Moment) => {
        setMenuHours((existingItems: SpecialHoursItemType[]) =>
            existingItems.map((item, j) => {
                const updatedItem = JSON.parse(JSON.stringify(item));
                updatedItem.time.start = start;
                updatedItem.time.end = end;
                return j === idx ? updatedItem : item;
            }),
        );
    };

    return (
        <div className="flex flex-col">
            {menuHours.map((element: SpecialHoursItemType) => (
                <div key={`menu-hour-row + ${element.id}`}>
                    {isMobile ? (
                        <MobileMenuHourRow
                            id={element.id}
                            dayOfWeek={element.dayOfWeek}
                            menuHours={menuHours}
                            setMenuHours={setMenuHours}
                            removeRow={removeRow}
                            updateRowHours={updateRowHours}
                            start={element.time.start}
                            end={element.time.end}
                        />
                    ) : (
                        <DesktopMenuHourRow
                            id={element.id}
                            dayOfWeek={element.dayOfWeek}
                            menuHours={menuHours}
                            setMenuHours={setMenuHours}
                            removeRow={removeRow}
                            updateRowHours={updateRowHours}
                            start={element.time.start}
                            end={element.time.end}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};
