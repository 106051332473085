import React, { useEffect, useState } from "react";
import { getActiveStore } from "@snackpass/accounting";
import { useSelector } from "react-redux";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { Loading } from "#onboarding/components/shared/Loading";
import { Page } from "#billing/components/Page";
import { onBilling } from "#billing/utils/fetch";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";

export const CustomerPortal = () => {
    const activeStore = useSelector(getActiveStore);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const callback = (l: boolean, f: boolean) => {
        setLoading(l);
        setSuccess(f);
    };

    const checkAndSetBillingWarnState = async () => {
        if (activeStore?._id) {
            setLoading(true);
            onBilling(activeStore._id, callback);
        }
    };

    useEffect(() => {
        void checkAndSetBillingWarnState();
    }, [activeStore?._id]);

    if (!loading && !success) {
        return (
            <Page>
                <Alert variant="warning">
                    <AlertTitle className="text-base">
                        <ExclamationTriangleIcon className="mr-2" />
                        Unable to Retrieve Information
                    </AlertTitle>
                    <AlertDescription className="text-sm">
                        We were not able to retrieve your billing information at
                        this time. If this issue continues to persist, please
                        reach out to Snackpass support.
                    </AlertDescription>
                </Alert>
            </Page>
        );
    }

    return (
        <Page>
            <Loading />
        </Page>
    );
};
