import React, { useContext } from "react";
import { Tooltip } from "antd";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import { ReactComponent as TimesIcon } from "src/assets/icons/times.svg";
import { ReactComponent as DimensionIcon } from "src/assets/icons/dimensions.svg";
import orderReadyScreenStaticImage from "src/assets/images/order-ready-screen.png";
import dynamicMenuBoardStaticImage from "src/assets/images/dynamic-menu-board.png";
import fullScreenImage from "src/assets/images/full_screen_gallery.png";
import {
    ChannelOption,
    ChannelType,
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

const NoImageFound = styled.span`
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: ${SystemColors.v1.gray70};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoImageFoundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${SystemColors.v1.gray40};
`;

// if there is an image, use a white background in case it has transparency, otherwise use shade of gray for empty state
const StyledImagePreview = styled.div<{ $src: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    margin-bottom: ${({ theme }) => theme.spacing.base};
    border-radius: 16px;
    height: auto;
    aspect-ratio: 2;
    background-color: ${({ $src }) =>
        $src ? SystemColors.v1.white : SystemColors.v1.gray90};
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url("${({ $src }) => $src}");
    position: relative;
    border: 1px solid ${SystemColors.v1.gray80};
`;

export const OpenImageButton = styled.button`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: ${SystemColors.v1.candy50};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        background-color: ${SystemColors.v1.candy60};
    }
`;

type ImagePreviewProps = {
    setShowImageModal?: (value: boolean) => void;
};

export const ImagePreview = ({
    setShowImageModal,
}: ImagePreviewProps): JSX.Element => {
    const { snackTvDevice, updatedDevice } = useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel = (updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel) as ChannelType;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel,
    );

    const staticImageUrl = channelOptions?.options?.imageURL;
    const videoImageThumbnail = channelOptions?.options?.videoThumbnailURL;

    const sourceOptions = {
        [ChannelType.CustomerReadyScreen]: orderReadyScreenStaticImage,
        [ChannelType.FullScreenGallery]: fullScreenImage,
        [ChannelType.InfiniteScrollImage]: dynamicMenuBoardStaticImage,
        [ChannelType.Video]: videoImageThumbnail,
        [ChannelType.StaticImage]: staticImageUrl,
    };

    if (!snackTvDevice || !channel) {
        // use $src instead of src so it doesn't appear in dom, since this is a div and not an img
        // see https://styled-components.com/docs/api#transient-props
        return <StyledImagePreview $src="" />;
    }

    return (
        <StyledImagePreview $src={sourceOptions[channel] ?? ""}>
            {channel === ChannelType.StaticImage && (
                <>
                    {screen && (
                        <>
                            {staticImageUrl && setShowImageModal ? (
                                <Tooltip
                                    trigger="hover"
                                    title="View Larger"
                                    mouseEnterDelay={1}
                                >
                                    <OpenImageButton
                                        onClick={() => setShowImageModal(true)}
                                    >
                                        <DimensionIcon
                                            fill={SystemColors.v1.white}
                                        />
                                    </OpenImageButton>
                                </Tooltip>
                            ) : null}
                            {!staticImageUrl && (
                                <NoImageFoundWrapper>
                                    <Tooltip
                                        trigger="hover"
                                        title="No Image Found"
                                        mouseEnterDelay={1}
                                    >
                                        <NoImageFound>
                                            <TimesIcon
                                                fill={SystemColors.v1.white}
                                            />{" "}
                                        </NoImageFound>
                                    </Tooltip>
                                    <small>No Image Found</small>
                                </NoImageFoundWrapper>
                            )}
                        </>
                    )}
                </>
            )}
        </StyledImagePreview>
    );
};
