import { useEffect } from "react";
import { Toaster } from "sonner";
import { StyleSheetManager, WebTarget } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

import { useAppSelector } from "src/redux/hooks";
import {
    getActiveStore,
    getIsLoading,
    getMfaPrecheck,
    getUser,
} from "src/redux/selectors";
import Navigation from "#app/main-page";
import CacheBusting from "#cache-busting";
import SignIn from "#sign-in";
import { useFirebaseAuth } from "#hooks/initial/use-firebase-auth";
import { useHideIntercomLauncher } from "#hooks/initial/use-hide-intercom-launcher";
import { useMFA } from "#hooks/initial/use-mfa";
import { useLoadSnackpassUser } from "#hooks/initial/use-load-snackpass-user";
import { usePersistStoreId } from "#hooks/initial/use-persist-store-id";
import { useLoadSnackpassStores } from "#hooks/initial/use-load-snackpass-stores";
import { useSyncActiveStore } from "#hooks/initial/use-sync-active-store";
import { useQueryPageAccess } from "#hooks/initial/use-query-page-access";
import { useIdentify } from "#hooks/initial/use-identify";
import { Routes } from "#navigation/routes";
import { AcceptInvite } from "#accept-invite";
import { pathBeginsWithRoute } from "#app/helpers";

import { Providers } from "./Providers";

/**
 * This function will produce one of 3 outcomes:
 * 1. If user is attempting to accept an invite (so they do not have any FB auth yet) it will render <AcceptInvite>
 * 2. If user hasn't authed in a while they will be prompted for MFA
 * 3. If they pass the first 2 checks they will see our normal <Navigation>
 */
const DetermineNavigation = () => {
    const { setHasVerifiedEmail, showEmailVerification, showSignIn } = useMFA();

    const mfaPrecheck = useAppSelector(getMfaPrecheck);
    const activeUser = useAppSelector(getUser);
    const activeStore = useAppSelector(getActiveStore);
    const isLoading = useAppSelector(getIsLoading);

    // waiting for redux to reconcile
    // `mfaPrecheck` being true indicates we can assume
    // user and store are going to be loaded
    const waitForRedux =
        mfaPrecheck && (!activeUser || !activeStore || isLoading);

    // remove loading spinner when we ready to render the app
    useEffect(() => {
        if (!waitForRedux) {
            document.getElementById("rai-preloader-container")?.remove();
            window.PartnerAppJsApi?.signalReady();
        }
    }, [waitForRedux]);

    if (waitForRedux) {
        return null;
    }

    const path = window.location.pathname;
    if (pathBeginsWithRoute(path, Routes.NewUserAcceptInvite)) {
        const nonce = path.slice(Routes.NewUserAcceptInvite.length + 1);
        return <AcceptInvite nonce={nonce} />;
    }

    if (showSignIn) {
        return (
            <SignIn
                showEmailVerification={showEmailVerification}
                setHasVerifiedEmail={setHasVerifiedEmail}
            />
        );
    }
    return <Navigation />;
};

// prevent these hooks from causing the entire app to
// re-render since they will be siblings instead of all in the parent.
const Hooks = () => {
    useFirebaseAuth();
    useLoadSnackpassUser();
    useHideIntercomLauncher();
    useIdentify();
    useLoadSnackpassStores();
    usePersistStoreId();
    useSyncActiveStore();
    useQueryPageAccess();
    return null;
};

const App = () => (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Providers>
            <CacheBusting>
                <Toaster />
                <Hooks />
                <DetermineNavigation />
            </CacheBusting>
        </Providers>
    </StyleSheetManager>
);

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: WebTarget) {
    if (typeof target === "string") {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

export default App;
