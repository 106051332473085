import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";
import { AccessorKeyColumnDef } from "@tanstack/react-table";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { GranularityType } from "#reports/sales-summary/types";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";
import { ShowLegendSwitch } from "#reports/location-sales/components/ShowLegendSwitch";
import {
    aggregateItemsByLocationByCategory,
    doesQueryMatch,
} from "#reports/location-menu-categories/lib";
import { LocationMenuGenericRow } from "#reports/location-menu-categories/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { MENU_SALES_TOOLTIP_COPY } from "#reports/menu-item-insights/lib";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { DataTable } from "src/@/components/ui/data-table";

type TableRow = {
    name: string;
    orders: string;
    sales: string;
    index?: number;
};

const CategoriesByLocationTable = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { locationMenuInsightsData, dateRanges, stores } = reportsState;

    const [searchQuery, setSearchQuery] = useState("");
    const [shouldUseExactMatch, setShouldUseExactMatch] = useState(true);

    const data: { [storeId: string]: LocationMenuGenericRow[] } | undefined =
        useMemo(() => {
            if (!locationMenuInsightsData?.itemInsightsData) return undefined;

            return aggregateItemsByLocationByCategory(
                locationMenuInsightsData?.itemInsightsData,
            );
        }, [locationMenuInsightsData]);

    const columnsForExport = [
        {
            title: "",
            dataIndex: "index",
        },
        {
            title: "Category",
            dataIndex: "name",
        },
        {
            title: "Orders",
            dataIndex: "orders",
        },
        {
            title: "Net Sales",
            dataIndex: "sales",
        },
    ];

    const rowsForExport = useMemo(() => {
        if (!data) return [];
        return Object.entries(data).reduce(
            (acc, [storeId, categories]) =>
                acc.concat([
                    {
                        name: stores.find((e) => e._id == storeId)?.name || "",
                        sales: "",
                        orders: "",
                    },
                    ...categories
                        .sort((a, b) => b.netSales - a.netSales)
                        .map((e, i) => ({
                            name: e.name,
                            index: i + 1,
                            sales: toDollarFormatted(e.netSales),
                            orders: formatNumber(e.orders),
                        })),
                ]),
            [] as TableRow[],
        );
    }, [data]);

    const tables = useMemo(() => {
        if (!data) return [];
        return [
            ...Object.entries(data).map(([storeId, categories], i) => {
                const storeName =
                    stores.find((e) => e._id == storeId)?.name || "";
                const rows = categories
                    .sort((a, b) => b.netSales - a.netSales)
                    .filter((e) =>
                        doesQueryMatch(
                            e.name,
                            searchQuery,
                            !shouldUseExactMatch,
                        ),
                    )
                    .map((e, i) => ({
                        name: e.name,
                        index: i + 1,
                        sales: toDollarFormatted(e.netSales),
                        orders: formatNumber(e.orders),
                    }));

                return (
                    <div key={i}>
                        <div className="mb-2 mt-4 font-semibold">
                            {storeName}
                        </div>
                        <DataTable
                            columns={columns}
                            data={rows}
                            customPageSize={10}
                            showPagination
                        />
                    </div>
                );
            }),
        ];
    }, [data, searchQuery, shouldUseExactMatch]);

    return (
        <div className="mb-6 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Categories by Location</h4>
                <DownloadButton
                    rows={rowsForExport}
                    columns={columnsForExport}
                    filename={`${
                        activeStore?.name
                    } Categories by Location ${formatRange(
                        dateRanges[0],
                        GranularityType.DAILY,
                    )}`}
                />
            </div>
            <div className="mb-6 flex items-center">
                <div className="flex h-10 w-96 items-center rounded-lg border border-neutral-400 bg-white">
                    <div className="pl-4">
                        <SearchIcon className="h-4 w-4 fill-neutral-400" />
                    </div>
                    <input
                        type="text"
                        className="h-full w-full rounded-lg border-none px-2 outline-none focus:shadow-none"
                        placeholder="Search Categories"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="pl-6">
                    <ShowLegendSwitch
                        value={shouldUseExactMatch}
                        onChange={setShouldUseExactMatch}
                        label={"Show Exact Matches"}
                    />
                </div>
            </div>
            <div className="overflow-x-scroll">
                {!locationMenuInsightsData?.itemInsightsDataLoading &&
                (locationMenuInsightsData?.itemInsightsData ||
                    locationMenuInsightsData?.itemInsightsDataFailed) ? (
                    locationMenuInsightsData?.itemInsightsData ? (
                        tables
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default CategoriesByLocationTable;

const columns: AccessorKeyColumnDef<TableRow, string | number>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="#"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.index}</div>,
        accessorKey: "index",
        id: "index",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 40,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Category"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.name}</div>,
        accessorKey: "name",
        id: "category",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: true,
        size: 300,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title="Orders"
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.orders}</div>,
        accessorKey: "orders",
        id: "orders",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 80,
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="my-2 ml-2"
                column={column}
                title={
                    <>
                        Net Sales
                        <ReportsTooltip
                            body={MENU_SALES_TOOLTIP_COPY}
                            className="mx-3"
                        />
                    </>
                }
            />
        ),
        cell: ({ row }) => <div className="ml-3">{row.original.sales}</div>,
        accessorKey: "sales",
        id: "sales",
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 120,
    },
];
