import Dinero, { Currency } from "dinero.js";

const fromCents = (cents: number | null, currency: Currency = "USD") =>
    Dinero({
        amount: cents ?? 0,
        currency,
        precision: 2,
    });

const fromDollars = (
    dollars: number | null | undefined,
    currency: Currency = "USD",
) =>
    Dinero({
        amount: Math.round((dollars ?? 0) * 100),
        currency,
        precision: 2,
    });

export const Money = {
    from: { cents: fromCents, dollars: fromDollars },
};
