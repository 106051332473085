import React from "react";
import { Maybe } from "@snackpass/snackpass-types/src/utils/types";

import { DeviceType } from "#devices/utils/deviceTypes";
import { ReactComponent as KioskIcon } from "src/assets/icons/kiosk.svg";
import { ReactComponent as TabletIcon } from "src/assets/icons/tablet.svg";
import { ReactComponent as TvIcon } from "src/assets/icons/tv.svg";
import { ReactComponent as PrinterIcon } from "src/assets/icons/printer-small.svg";

export type DeviceOptionsType = {
    label: string;
    value: DeviceType;
    icon: React.ReactNode;
    createLabel: string;
};

const IconStyle = { height: 25, width: 25 };

export const deviceOptions: DeviceOptionsType[] = [
    {
        // Used for table view, but ignored as option for new device
        // by skipping the first entry in this array
        label: "All",
        value: DeviceType.All,
        icon: <TabletIcon style={IconStyle} />,
        createLabel: "",
    },
    {
        label: "SnackOS",
        value: DeviceType.Kiosk,
        icon: <KioskIcon style={IconStyle} />,
        createLabel: "SnackOS",
    },
    {
        label: "Stripe Terminal",
        value: DeviceType.StripeTerminal,
        icon: <TabletIcon style={IconStyle} />,
        createLabel: "Stripe Terminal",
    },
    {
        label: "Printer",
        value: DeviceType.Printer,
        icon: <PrinterIcon style={IconStyle} />,
        createLabel: "Printer",
    },
    {
        label: "Order Hub Legacy",
        value: DeviceType.OrderHub,
        icon: <TabletIcon style={IconStyle} />,
        createLabel: "Order Hub Legacy",
    },
    {
        label: "KDS Legacy",
        value: DeviceType.KDS,
        icon: <TabletIcon style={IconStyle} />,
        createLabel: "Kitchen Display Legacy",
    },
    {
        label: "SnackTV Legacy",
        value: DeviceType.SnackTV,
        icon: <TvIcon style={IconStyle} />,
        createLabel: "SnackTV Legacy",
    },
];

export const addonOptions = [
    {
        label: "Cash Drawer",
        value: DeviceType.CashDrawer,
        icon: <PrinterIcon style={IconStyle} />,
        createLabel: "Cash Drawer",
    },
    {
        label: "Prep Station",
        value: DeviceType.PrepStation,
        icon: <PrinterIcon style={IconStyle} />,
        createLabel: "Prep Station",
    },
];

export const getDeviceCreateLabel = (value?: string, isMobile?: boolean) => {
    const deviceOption = deviceOptions.find(
        (d: DeviceOptionsType) => d.value === value,
    );
    if (!deviceOption) return "";
    return isMobile ? deviceOption.label : deviceOption.createLabel;
};

export const getDeviceTypeName = (value?: string): Maybe<string> => {
    if (value === DeviceType.All) return "Device";
    const match = deviceOptions.find((device) => device.value === value);
    return match ? match.label : null;
};

export const getAddonTypeName = (value?: string): Maybe<string> => {
    if (value === DeviceType.All) return "Device";
    const match = addonOptions.find((device) => device.value === value);
    return match ? match.label : null;
};

export const getDeviceIcon = (value?: string): Maybe<React.ReactNode> => {
    const match = deviceOptions.find((device) => device.value === value);
    return match ? match.icon : <TabletIcon style={IconStyle} />;
};
