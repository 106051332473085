import { DependencyList, useEffect, useRef } from "react";
import { Canvas } from "fabric";

import { useEditorState } from "#table-editor/components/Editor/EditorProvider";

export function useCanvasEffect(
    effect: (canvas: Canvas) => void | (() => void),
    deps: DependencyList = [],
    equalityFn?: (a: DependencyList, b: DependencyList) => boolean
) {
    const canvas = useEditorState((state) => state.canvas);
    const redrawRef = useRef<DependencyList>(deps);

    if (!equalityFn || !equalityFn(deps, redrawRef.current)) {
        redrawRef.current = deps;
    }

    useEffect(() => {
        if (!canvas) return;

        return effect(canvas);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, effect, redrawRef.current]);
}
