import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";

export const PhoneContainer = styled.div<{
    phoneWidth: number;
    phoneHeight: number;
}>`
    margin: 0rem 2rem;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    ${({ phoneWidth }) => phoneWidth && `font-size: ${phoneWidth / 20}px;`}
    --globalFontSize: ${(props) => props.phoneWidth / 20};
`;

export const Phone = styled.img`
    width: 100%;
    height: auto;
`;

// Header Components
export const Header = styled.div`
    position: absolute;
    top: 1%;
    left: 6%;
    width: 88%;
    height: 10%;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

export const PhoneHeaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-weight: 700;
`;

export const PhoneHeaderLead = styled.div`
    margin-right: 30%;
    padding-left: 0.5em;
    svg {
        width: 0.8em;
        height: auto;
        @media ${ScreenState.MOBILE} {
            width: 1.6rem;
        }
    }
`;

export const PhoneHeaderCenter = styled.div`
    justify-content: center;
    color: ${SystemColors.v2.salt100.light};
    font-size: var(--font-size);
`;
export const PhoneHeaderTail = styled.div`
    margin-left: auto;
`;

export const Space = styled.div`
    flex: 1;
`;

// Body components
export const Body = styled.div`
    position: absolute;
    top: 11%;
    left: 6%;
    width: 88%;
    height: 10%;
    height: 50%;
    overflow: hidden;
`;

export const PhoneBodyWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
`;

export const PhoneBodyTop = styled.div`
    position: absolute;
    top: 1%;
    left: 0;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 14%;

    .store-icon {
        flex: 1;
        margin: auto;
        margin-right: 0.5em;
        width: 2.2em;
        height: 2.5em;
        border-radius: 50%;
    }

    .store-name {
        flex: 6;
        margin: auto;
        line-height: 1em;
        font-size: 0.8em;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
`;
export const PhoneBodyCover = styled.div`
    width: 100%;
    height: 85%;
    position: absolute;
    top: 15%;
    left: 0;
    text-align: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const NoImageWrapper = styled.div`
    cursor: pointer;
    margin: auto;
    margin-top: 25%;
    svg {
        width: 3em;
        height: 3em;
    }
    svg path {
        fill: ${SystemColors.v1.black} !important;
    }
`;

export const PhoneBodyBottom = styled.div`
    width: 100%;
    height: 15%;
    position: absolute;
    top: 85%;
    left: 0;
    padding-left: 0.3em;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(54, 54, 54, 0.4822522759103641) 44%,
        rgba(153, 153, 153, 0.24975927871148462) 78%,
        rgba(255, 255, 255, 0) 100%
    );
    font-size: 0.9em;

    color: ${SystemColors.v1.black};

    svg {
        width: 1.2em;
        height: auto;
    }
    .campaignType {
        padding-left: 0.8em;
        padding-bottom: 0.3em;
        font-weight: 600;
        color: ${SystemColors.v1.white};
        width: 60%;
    }

    .campaignbtn {
        font-weight: 600;
        margin-left: auto;
        border-radius: 18px;
        background: ${SystemColors.v2.salt10.light};
        border-radius: 12px;
        text-align: center;
        padding: 0.1em 0.5em;
        margin-right: 0.4em;
    }
`;
export const IconWrapper = styled.div`
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    background: ${SystemColors.v2.salt10.light};
    display: flex;
    justify-content: center;
`;

export const BackgroundColor = styled.div<{ color?: string }>`
    width: 100%;
    height: 100%;
    background-color: ${({ color }) =>
        color ? `${color}` : `${SystemColors.v2.salt20.light}`};
    display: flex;
    align-items: center;
    content: "";
`;

export const AnnoucementMessage = styled.div`
    font-weight: 800;
    font-size: 1em;
    line-height: 2em;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: ${SystemColors.v1.white};
`;

export const ImageUpload = styled.img`
    cursor: pointer;
`;
// Footer Components
export const Footer = styled.div`
    position: absolute;
    top: 61%;
    left: 6%;
    width: 88%;
    height: 35%;
    overflow: hidden;
    font-weight: 400;
`;

export const MessageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-items: center;
`;

// Skeleton
export const SkeletonWrapper = styled.div`
    margin-top: 1.4em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    .skeleton {
        border-radius: 16px;
    }
`;

export const PhoneBodySkeletonWrapper = styled.div`
    padding-top: 3.8em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    .skeleton {
        border-radius: 16px;
    }
`;
