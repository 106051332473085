import { useMutation } from "@tanstack/react-query";
import { UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { setActiveStore } from "src/redux/slices";
import api from "src/api/rest";
import { buildCommissionPolicies } from "#settings/settings-fees/utils/build-commission-policy";
import {
    CommissionFormValues,
    FeesSettingsMutation,
} from "#settings/settings-fees/utils/types";
import { commissionDefaults } from "#settings/settings-fees/forms/commission/commission-defaults";

export const useUpdateCommission = ({
    storeId,
    reset,
}: {
    storeId: string;
    reset: UseFormReset<CommissionFormValues>;
}) => {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: [FeesSettingsMutation.Commission, storeId],
        async mutationFn(values: CommissionFormValues) {
            const policies = buildCommissionPolicies(values);
            const response = await api.stores.updateCommissionPolicy(storeId, {
                defaultCommissionPolicy: {
                    percent: values.defaultPercent,
                    fixed: values.defaultFixed,
                },
                customCommissionPolicies: policies,
            });
            dispatch(setActiveStore(response.data.store));
            reset(commissionDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved",
            });
        },
        onError() {
            toast.error(`Update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed",
            });
        },
    });
    return mutateAsync;
};
