import { Canvas, Rect } from "fabric";

import { Element } from "#table-editor/canvas/Element";
import { RectGeometry } from "#table-editor/types";

/**
 * Draws a barrier
 */
export function drawBarrier(canvas: Canvas, id: string, shape: RectGeometry) {
    const rect = new Rect({
        top: 0,
        left: 0,
        width: shape.width,
        height: shape.height,
        selectable: true,
        fill: "#dddddd"
    });

    const group = new Element(id, [rect], {
        variant: "barrier",
        top: shape.y,
        left: shape.x,
        width: shape.width,
        height: shape.height,
        selectable: true
    });

    group.setControlsVisibility({ mtr: false });

    canvas.add(group);

    return group;
}
