import { ApolloProvider } from "@apollo/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { Provider as ReduxProvider } from "react-redux";

import { apolloClient } from "src/api/graphql/apollo";
import { reactQueryClient } from "src/lib/clients";
import { store } from "src/redux/store";
import { StatsigReduxProvider } from "src/statsig/StatsigReduxProvider";
import { MenuServiceProvider } from "#menu/MenuServiceProvider";

/**
 * Global state providers for the RDB app.
 */
export function Providers({ children }: { children: ReactNode | ReactNode[] }) {
    // NB: only put _global_ providers here!
    return (
        <QueryClientProvider client={reactQueryClient}>
            <ApolloProvider client={apolloClient}>
                <ReduxProvider store={store}>
                    <StatsigReduxProvider>
                        <MenuServiceProvider>{children}</MenuServiceProvider>
                    </StatsigReduxProvider>
                </ReduxProvider>
            </ApolloProvider>
        </QueryClientProvider>
    );
}
