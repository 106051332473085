import React, { useContext, useEffect, useState } from "react";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from "react-swipeable-list";
import classNames from "classnames";
import styled from "styled-components";
import { UseExpandedRowProps, UseTableRowProps } from "react-table-7";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { SystemColors } from "@snackpass/design-system";
import { toast } from "sonner";

import api from "src/api/rest";
import "react-swipeable-list/dist/styles.css";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import { ReactComponent as DragDots } from "src/assets/icons/drag-dots.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
import { ReactComponent as InStockIcon } from "src/assets/icons/mark-in-stock.svg";
import { ReactComponent as OutOfStock } from "src/assets/icons/mark-out-of-stock.svg";
import { ReactComponent as Duplicate } from "src/assets/icons/duplicate.svg";
import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import constants from "#core/constants";
import { getStoreTimezone } from "#utils/helpers";
import {
    getActiveStore,
    getActiveStoreIs3PIntegrated,
} from "src/redux/selectors";
import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import MenuAutomationError from "#menu-editor/mobile-friendly/menu-automation-error";
import {
    IProductCategoryWithProducts,
    MenuTopLevelContext,
} from "#menu-editor/mobile-friendly/helpers/context";
import { sendError } from "src/utils/errors";
import { setActiveStore, updateLegacyProduct } from "src/redux/slices";
import { useMenuService } from "#menu/hooks/useMenuService";

import SoldOutModal from "../sold-out-modal";
import DeleteCategoryModal from "../delete-category-modal";

import { IProduct } from "@snackpass/snackpass-types";

type TableRowHeadingCellHTMLAttributes =
    React.HTMLAttributes<HTMLTableCellElement>;

type TableRowHeadingCellProps = TableRowHeadingCellHTMLAttributes & {
    depth: number;
    isDragging: boolean;
    canExpand: boolean;
    isExpanded: boolean;
    getToggleRowExpandedProps: Function;
    dragRef: React.RefObject<HTMLTableCellElement>;
    row: UseTableRowProps<IProductCategoryWithProducts | IProduct> &
        UseExpandedRowProps<IProductCategoryWithProducts | IProduct>;

    menuAutomation: MenuAutomation;
};

export const TableRowHeadingCell = React.forwardRef<
    HTMLTableCellElement,
    TableRowHeadingCellProps
>(
    (
        {
            depth,
            canExpand,
            row,
            getToggleRowExpandedProps,
            isExpanded,
            isDragging,
            children,
            dragRef,
            menuAutomation,
            ...props
        },
        ref,
    ) => {
        const {
            activeStoreId,
            openProductEditSlideOver,
            productInEdit,
            setProductInEdit,
            setProductInDraft,
            setIsLoading,
            openNewProductSlideOver,
        } = useContext(MenuTopLevelContext);
        const dispatch = useDispatch();
        const activeStore = useSelector(getActiveStore);
        const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);
        const menuService = useMenuService();
        const { width } = useWindowDimensions();
        const [soldOut, setSoldOut] = React.useState<boolean>(
            !!productInEdit?.soldOut,
        );

        const [rowIsActive, setRowIsActive] = useState<string>("");
        useEffect(() => {
            if (productInEdit?._id === row.original._id) {
                setRowIsActive(row.original._id);
            }
            setSoldOut(!!(row.original as IProduct).soldOut);
        }, [row]);

        const productIssues =
            depth === 1
                ? menuAutomation.issuesByProductId(row.original._id)
                : [];
        const categoryIssues =
            depth === 0
                ? menuAutomation.issuesByProductCategory(row.original.name)
                : [];

        const timezone = getStoreTimezone(activeStore);
        const [
            isMarkProductSoldOutModalOpen,
            setIsMarkProductSoldOutModalOpen,
        ] = useState(false);
        const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] =
            useState<boolean>(false);

        const refreshStore = (storeId: string) => {
            api.stores
                .getOne(storeId)
                .then((response) => {
                    dispatch(setActiveStore(response.data.store));
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const handleDeleteCategory = () => {
            if (!activeStore) {
                return;
            }
            setIsLoading(true);
            setIsDeleteCategoryModalOpen(false);
            api.stores
                .removeProductCategory(activeStore._id, row.original._id)
                .then(async () => {
                    refreshProducts();
                    return api.stores.getOne(activeStore._id);
                })
                .then((response) => {
                    dispatch(setActiveStore(response.data.store));
                })
                .catch((err) => {
                    sendError(err);
                    toast.error("Could not remove category");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        const refreshProducts = () => {
            void menuService.fetchProducts();
        };
        const markSoldOut = async (
            props: Pick<IProduct, "soldOut" | "soldOutDates">,
        ) => {
            if (!activeStoreId) {
                return;
            }

            try {
                const { data } = await api.products.update(
                    row.original._id,
                    props,
                );
                dispatch(updateLegacyProduct(data.product));
                refreshStore(activeStoreId);
            } catch (error) {
                toast.error("Failed to update products");
            }
        };

        const markInStock = async () => {
            if (!activeStoreId) {
                return;
            }
            const newSoldOutDates = {
                soldOutDates: { from: null, until: null },
                soldOut: false,
            };
            toast.info("Updating Sold Out Status..");
            try {
                const { data } = await api.products.update(
                    row.original._id,
                    newSoldOutDates,
                );
                dispatch(updateLegacyProduct(data.product));
                refreshStore(activeStoreId);
            } catch (error) {
                toast.error("Failed to update products");
            }
        };

        const handleDuplicate = () => {
            if (!row.original) {
                toast.error(
                    "You're trying to duplicate a non-existent product",
                );
                return;
            }
            setIsLoading(true);
            const post = {
                ...row.original,
                name: `${row.original.name}`,
                _id: undefined,
                purchaseCount: 0,
                storeId: activeStoreId,
                soldOutDates: { from: null, until: null },
            };

            setProductInDraft(post);
            setIsLoading(false);
            openNewProductSlideOver();
        };

        const leadingActions = () => (
            <LeadingActions>
                <SwipeAction onClick={() => {}}>
                    <ActionContent
                        style={{
                            backgroundColor: `${SystemColors.v2.candy50.light}`,
                            width: "4rem",
                            justifyContent: "center",
                        }}
                    >
                        <ItemColumnCentered>
                            <Duplicate
                                className="swipe-icon"
                                onClick={handleDuplicate}
                            />
                        </ItemColumnCentered>
                    </ActionContent>
                </SwipeAction>
            </LeadingActions>
        );

        const trailingActions = () => (
            <TrailingActions>
                <SwipeAction onClick={() => {}}>
                    <ActionContent
                        style={{
                            backgroundColor: `${SystemColors.v2.blueberry50.light}`,
                            width: "4rem",
                            justifyContent: "center",
                        }}
                    >
                        <ItemColumnCentered>
                            <span
                                onClick={() => {
                                    if (soldOut) {
                                        markInStock();
                                    }
                                    if (!soldOut) {
                                        setIsMarkProductSoldOutModalOpen(true);
                                    }
                                }}
                            >
                                {soldOut ? (
                                    <OutOfStock className="actions swipe-icon" />
                                ) : (
                                    <InStockIcon className="actions swipe-icon" />
                                )}
                            </span>
                        </ItemColumnCentered>
                    </ActionContent>
                </SwipeAction>
            </TrailingActions>
        );

        return (
            <>
                <td
                    className={classNames({
                        card: true,
                        isSubRow: depth === 1,
                        isNotSubRow: depth === 0,
                        isExpanded: isExpanded,
                        isMobile: isMobile,
                    })}
                >
                    <table>
                        <>
                            <tbody>
                                <tr>
                                    <td
                                        ref={ref}
                                        {...props}
                                        className={classNames({
                                            TableRowHeadingCell: true,
                                            rowIsActive: rowIsActive,
                                            isDragging: isDragging,
                                            isExpanded: isExpanded,
                                        })}
                                        scope="row"
                                        role="rowheader"
                                    >
                                        <table
                                            className="TableRowHeadingCellWrapper"
                                            style={{
                                                // @ts-ignore
                                                "--depth": depth,
                                            }}
                                        >
                                            <tbody>
                                                {depth == 0 ? (
                                                    <>
                                                        {!thirdPartyEnabled && (
                                                            <>
                                                                <tr>
                                                                    <td
                                                                        ref={
                                                                            dragRef
                                                                        }
                                                                        className={classNames(
                                                                            {
                                                                                TableRowHeadingCellDragHandler:
                                                                                    true,
                                                                                isDragging:
                                                                                    isDragging,
                                                                                isSubRow:
                                                                                    depth !==
                                                                                    0,
                                                                            },
                                                                        )}
                                                                    >
                                                                        <DragDots />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <span className="category-iconWrapper">
                                                                            <Delete
                                                                                className="delete-category"
                                                                                onClick={() => {
                                                                                    setIsDeleteCategoryModalOpen(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}

                                                        <MenuAutomationError
                                                            menuIssues={
                                                                categoryIssues
                                                            }
                                                            isProduct={false}
                                                        />

                                                        {canExpand &&
                                                        getToggleRowExpandedProps ? (
                                                            <tr
                                                                {...getToggleRowExpandedProps(
                                                                    {
                                                                        className:
                                                                            classNames(
                                                                                {
                                                                                    TableRowHeadingCellButton:
                                                                                        true,
                                                                                    isExpanded:
                                                                                        isExpanded,
                                                                                },
                                                                            ),
                                                                    },
                                                                )}
                                                            >
                                                                <td>
                                                                    <ChevronDown
                                                                        className="chevron-down p-4"
                                                                        fill={
                                                                            SystemColors
                                                                                .v2
                                                                                .salt30
                                                                                .dark
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                        <tr
                                                            {...getToggleRowExpandedProps(
                                                                {
                                                                    className:
                                                                        classNames(
                                                                            {
                                                                                isExpanded:
                                                                                    isExpanded,
                                                                            },
                                                                        ),
                                                                },
                                                            )}
                                                        >
                                                            <tr className="TableRowHeadingCellChildren">
                                                                {children}
                                                            </tr>
                                                        </tr>
                                                    </>
                                                ) : width >
                                                  constants.TABLET_MAX_WIDTH ? (
                                                    <>
                                                        {!thirdPartyEnabled && (
                                                            <tr>
                                                                <td
                                                                    ref={
                                                                        dragRef
                                                                    }
                                                                    className={classNames(
                                                                        {
                                                                            TableRowHeadingCellDragHandler:
                                                                                true,
                                                                            isDragging:
                                                                                isDragging,
                                                                            isSubRow:
                                                                                depth !==
                                                                                0,
                                                                        },
                                                                    )}
                                                                >
                                                                    <DragDots />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <MenuAutomationError
                                                            menuIssues={
                                                                productIssues
                                                            }
                                                            isProduct={true}
                                                        />
                                                        <tr className="TableRowHeadingCellButtonActions">
                                                            <td>
                                                                {!thirdPartyEnabled && (
                                                                    <span
                                                                        className="iconWrapper"
                                                                        onClick={() => {
                                                                            if (
                                                                                soldOut
                                                                            ) {
                                                                                markInStock();
                                                                            }
                                                                            if (
                                                                                !soldOut
                                                                            ) {
                                                                                setIsMarkProductSoldOutModalOpen(
                                                                                    true,
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {soldOut ? (
                                                                            <Tooltip title="Mark as In Stock">
                                                                                <OutOfStock className="actions" />{" "}
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Mark as Sold Out">
                                                                                <InStockIcon className="actions" />{" "}
                                                                            </Tooltip>
                                                                        )}
                                                                    </span>
                                                                )}
                                                                <Tooltip
                                                                    title={
                                                                        thirdPartyEnabled
                                                                            ? "View Item"
                                                                            : "Edit Item"
                                                                    }
                                                                >
                                                                    <span className="iconWrapper">
                                                                        <EditIcon
                                                                            className="actions"
                                                                            fill={
                                                                                SystemColors
                                                                                    .v2
                                                                                    .salt30
                                                                                    .dark
                                                                            }
                                                                            onClick={() => {
                                                                                setRowIsActive(
                                                                                    row
                                                                                        .original
                                                                                        ._id,
                                                                                );
                                                                                setProductInEdit(
                                                                                    row.original as IProduct,
                                                                                );
                                                                                openProductEditSlideOver();
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                                {!thirdPartyEnabled && (
                                                                    <Tooltip title="Duplicate Item">
                                                                        <span className="iconWrapper">
                                                                            <Duplicate
                                                                                className="actions"
                                                                                onClick={
                                                                                    handleDuplicate
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                            </td>
                                                        </tr>

                                                        <tr className="TableRowHeadingCellChildren">
                                                            {children}
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <SwipeableList
                                                        threshold={0.5}
                                                        type={ListType.IOS}
                                                    >
                                                        <SwipeableListItem
                                                            blockSwipe={
                                                                thirdPartyEnabled
                                                            }
                                                            leadingActions={leadingActions()}
                                                            trailingActions={trailingActions()}
                                                        >
                                                            {!thirdPartyEnabled && (
                                                                <tr>
                                                                    <td
                                                                        ref={
                                                                            dragRef
                                                                        }
                                                                        className={classNames(
                                                                            {
                                                                                TableRowHeadingCellDragHandler:
                                                                                    true,
                                                                                isDragging:
                                                                                    isDragging,
                                                                                isSubRow:
                                                                                    depth !==
                                                                                    0,
                                                                            },
                                                                        )}
                                                                    >
                                                                        <DragDots />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <MenuAutomationError
                                                                style={{
                                                                    top: 22,
                                                                    left: 100,
                                                                }}
                                                                menuIssues={
                                                                    productIssues
                                                                }
                                                                isProduct={
                                                                    false
                                                                }
                                                            />
                                                            <tr className="TableRowHeadingCellChildren">
                                                                {children}
                                                            </tr>
                                                        </SwipeableListItem>
                                                    </SwipeableList>
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    </table>
                </td>
                <SoldOutModal
                    onHide={() => setIsMarkProductSoldOutModalOpen(false)}
                    handleOk={(soldOutProps) => {
                        toast.info("Updating Sold Out Status...");
                        setIsMarkProductSoldOutModalOpen(false);
                        void markSoldOut(soldOutProps);
                    }}
                    isMarkProductSoldOutModalOpen={
                        isMarkProductSoldOutModalOpen
                    }
                    storeTimeZone={timezone}
                />
                <DeleteCategoryModal
                    isDeleteCategoryModalOpen={isDeleteCategoryModalOpen}
                    setIsDeleteCategoryModalOpen={setIsDeleteCategoryModalOpen}
                    handleOk={handleDeleteCategory}
                />
            </>
        );
    },
);

const ActionContent = styled.div`
    height: 100%;
    width: 100px;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    box-sizing: border-box;
    color: ${SystemColors.v2.salt10.light};
    user-select: none;
`;

const ItemColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
