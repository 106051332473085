import {
    Break,
    EmployeeShiftDetails,
    IPayPeriod,
    IPayPeriodSummary,
    IShiftSummary,
} from "@snackpass/snackpass-types";
import { Maybe } from "@snackpass/snackpass-types/build/utils/types";

import {
    Break as GQLBreak,
    EmployeeShiftDetails as GQLShiftDetails,
    PayPeriod,
    PayPeriodSummary,
    PaySummaryByDateRange,
    ShiftSummary,
} from "src/api/graphql/generated/types";

export const mapPayPeriodGQLtoTS = (payPeriod: PayPeriod): IPayPeriod => {
    const {
        dailyDoubleOvertimeMultiplier,
        dailyDoubleOvertimeThreshold,
        dailyOvertimeMultiplier,
        dailyOvertimeThreshold,
        weeklyOvertimeMultiplier,
        weeklyOvertimeThreshold,
    } = payPeriod;
    return {
        dailyDoubleOvertimeMultiplier:
            dailyDoubleOvertimeMultiplier as Maybe<number>,
        dailyDoubleOvertimeThreshold:
            dailyDoubleOvertimeThreshold as Maybe<number>,
        dailyOvertimeMultiplier: dailyOvertimeMultiplier as Maybe<number>,
        dailyOvertimeThreshold: dailyOvertimeThreshold as Maybe<number>,
        weeklyOvertimeMultiplier: weeklyOvertimeMultiplier as Maybe<number>,
        weeklyOvertimeThreshold: weeklyOvertimeThreshold as Maybe<number>,
        ...payPeriod,
        startDate: new Date(payPeriod.startDate),
        endDate: new Date(payPeriod.endDate),
    };
};

export const mapBreakGQLtoTS = (br: GQLBreak): Break => ({
    ...br,
    actualDuration: br.actualDuration as Maybe<number>,
    start: new Date(br.start),
    end: br.end ? new Date(br.end) : null,
});

export const mapPayPeriodSummariesGQLtoTS = (
    pps: PayPeriodSummary,
): IPayPeriodSummary => {
    const employeeShiftDetails = pps.employeeShiftDetails.map(
        mapEmployeeShiftDetailsGQLtoTS,
    );
    const payPeriodSummary: IPayPeriodSummary = {
        ...pps,
        weeklyOvertimeHours: pps.weeklyOvertimeHours as Maybe<number>,
        estWages: pps.estWages,
        startDate: new Date(pps.startDate),
        endDate: new Date(pps.endDate),
        employeeShiftDetails,
    };
    return payPeriodSummary;
};

export const mapPaySummaryByDateRangeGQLtoTS = (
    pps: PaySummaryByDateRange,
): IPayPeriodSummary => {
    const employeeShiftDetails = pps.employeeShiftDetails.map(
        mapEmployeeShiftDetailsGQLtoTS,
    );
    const payPeriodSummary: IPayPeriodSummary = {
        ...pps,
        weeklyOvertimeHours: pps.weeklyOvertimeHours as Maybe<number>,
        estWages: pps.estWages,
        startDate: new Date(pps.startDate),
        endDate: new Date(pps.endDate),
        employeeShiftDetails,
    };
    return payPeriodSummary;
};

export const mapEmployeeShiftDetailsGQLtoTS = (
    esd: GQLShiftDetails,
): EmployeeShiftDetails => {
    const shifts = esd.shifts.map((shift: ShiftSummary): IShiftSummary => {
        const breaks = shift.breaks.map(mapBreakGQLtoTS);
        return {
            ...shift,
            weeklyOvertimeHours: shift.weeklyOvertimeHours as Maybe<number>,
            estWages: shift.estWages,
            clockedInAt: new Date(shift.clockedInAt),
            clockedOutAt: shift.clockedOutAt as Maybe<Date>,
            cashTips: shift.cashTips as Maybe<number>,
            breaks,
            note: shift.note ?? undefined,
        };
    });
    return {
        ...esd,
        weeklyOvertimeHours: esd.weeklyOvertimeHours as Maybe<number>,
        estWages: esd.estWages,
        shifts,
    };
};
