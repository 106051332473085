import { useContext } from "react";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { ChannelType } from "#devices/utils/deviceTypes/SnackTVDevice";
import Select from "#devices/components/FormControls/Select";

type Option = { value: ChannelType; label: string };

type Props = {
    options: Array<Option>;
};

export const ChannelDisplayLayout = ({ options }: Props) => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    return (
        <div className="mb-5">
            <Select
                id={"channelDisplayLayout"}
                label="Layout"
                options={options}
                value={channel}
                onChange={(e) => {
                    updateDeviceField("channel", e.target.value);
                }}
            />
        </div>
    );
};
