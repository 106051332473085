import { Dispatch } from "redux";

import { setInventoryItems, setInventoryReports } from "src/redux/slices";

import api from "../api/rest";

export function fetchInventoryReports(
    storeId: string,
    startDate: Date,
    endDate: Date,
) {
    return async (dispatch: Dispatch) =>
        api.inventory
            .getReports(storeId, startDate.toISOString(), endDate.toISOString())
            .then((response) =>
                dispatch(setInventoryReports(response.data.reports)),
            )
            .catch((err) => {});
}

export function fetchInventoryItems(storeId: string) {
    return async (dispatch: Dispatch) =>
        api.inventory
            .get(storeId)
            .then((response) =>
                dispatch(setInventoryItems(response.data.inventoryItems)),
            )
            .catch((err) => {});
}
