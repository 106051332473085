import React, { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useDispatch } from "react-redux";
import { SystemColors } from "@snackpass/design-system";
import { Alert, Modal } from "antd";
import moment from "moment-timezone";
import {
    IPurchase,
    PurchaseStatus,
    FulfillmentTypeEnum,
} from "@snackpass/snackpass-types";
import { toast } from "sonner";

import { Button } from "#legacy-components";
import api, { UpdateStatusParams } from "src/api/rest";
import { setActivePurchase } from "src/redux/slices";
import Constants from "#core/constants";
import { ErrorWithCause } from "src/utils/errors";

interface Props extends InjectedProps {
    purchase: IPurchase;
}

function DelayModal({ handleHide, show, purchase }: Props) {
    const pickupTime = purchase?.pickupTime;
    const isDelivery = purchase?.fulfillment === FulfillmentTypeEnum.Delivery;

    const [isLoading, setLoading] = useState(false);
    const [delayDuration, setDelayDuration] = useState<number>(0);

    const dispatch = useDispatch();
    const _setActivePurchase = (purchase: IPurchase) => {
        dispatch(setActivePurchase(purchase));
    };

    const onDelayOrder = async () => {
        setLoading(true);
        try {
            await delayPurchase(purchase, delayDuration, _setActivePurchase);
            handleHide();
        } catch (err) {
            toast.error("Could not delay purchase.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={
                <h3 className="mb-2" style={{ color: SystemColors.v1.sesame }}>
                    Delay Order
                </h3>
            }
            footer={
                <div>
                    <Button
                        label="Delay"
                        disabled={!delayDuration}
                        isLoading={isLoading}
                        backgroundColor={SystemColors.v1.melon50}
                        onPress={onDelayOrder}
                    />
                </div>
            }
            onCancel={handleHide}
            open={show}
        >
            {Constants.DELAY_OPTIONS.map((delay) => (
                <DelayOption
                    isSelected={delayDuration === delay}
                    key={delay}
                    delayTime={delay}
                    onClick={() => setDelayDuration(delay)}
                />
            ))}

            {delayDuration > 0 && (
                <Alert
                    style={{
                        marginTop: 15,
                        backgroundColor: SystemColors.v1.macaroni90,
                        color: SystemColors.v1.sesame,
                        border: "none",
                        fontSize: 14,
                    }}
                    showIcon={true}
                    icon={
                        <i
                            style={{
                                marginRight: 15,
                                color: SystemColors.v1.sesame,
                            }}
                            className="fas fa-clock"
                        />
                    }
                    message={
                        <div>
                            This will notify the customer
                            {isDelivery
                                ? " that delivery will be "
                                : " to pickup their order "}
                            at{" "}
                            <b>
                                {moment(pickupTime)
                                    .add(delayDuration, "minutes")
                                    .format("h:mm a")}
                            </b>
                            .
                        </div>
                    }
                />
            )}
        </Modal>
    );
}

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
const DelayOption = ({
    isSelected,
    delayTime,
    onClick,
}: {
    isSelected: boolean;
    delayTime: number;
    onClick: any;
}) => (
    <div
        onClick={onClick}
        style={{
            cursor: "pointer",
            borderRadius: 10,
            margin: 5,
            fontSize: 18,
            color: isSelected ? SystemColors.v1.white : SystemColors.v1.sesame,
            backgroundColor: isSelected
                ? SystemColors.v1.melon50
                : SystemColors.v1.gray80,
            padding: "5px 10px",
            display: "inline-block",
        }}
    >
        {delayTime} min
    </div>
);

async function delayPurchase(
    purchase: IPurchase,
    delayTime: number,
    setPurchase: (purchase: IPurchase) => void,
) {
    const body: UpdateStatusParams = {
        status: PurchaseStatus.started,
        isDelayed: true,
        delayDuration: delayTime,
    };

    const res = await api.purchases
        .updateStatus(purchase._id, body)
        .catch((cause) => {
            throw new ErrorWithCause(
                "delayPurchase: error updating purchase status",
                cause,
            );
        });

    setPurchase(res.data.purchase);
}

/** @deprecated */
export default connectModal({ name: "DelayModal" })(DelayModal);
