import React, { useLayoutEffect, useRef, useState } from "react";

import phone from "src/assets/images/phone.png";
import useWindowDimensions from "#hooks/use-window-dimensions";

import * as P from "./styles";
import { Header } from "./header";
import { Body } from "./body";
import { Footer } from "./footer";

import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";

interface ICustomPreviewContext {
    phoneWidth: number;
    phoneHeight: number;
}
export const CustomPreviewContext = React.createContext<ICustomPreviewContext>({
    phoneWidth: 0,
    phoneHeight: 0,
});
type PhoneFramePreviewProps = {
    children?: React.ReactNode;
};

const CustomerPreview = ({ children }: PhoneFramePreviewProps) => {
    const ref = useRef<HTMLImageElement>(null);
    const [phoneWidth, setPhoneWidth] = useState(0);
    const [phoneHeight, setPhoneHeight] = useState(0);
    const { width } = useWindowDimensions();

    useLayoutEffect(() => {
        if (ref.current) {
            setPhoneWidth(ref.current.offsetWidth);
            setPhoneHeight(ref.current?.offsetHeight);
        }
    }, [width]);

    return (
        <CustomPreviewContext.Provider value={{ phoneWidth, phoneHeight }}>
            <SkeletonTheme width="100%">
                <P.PhoneContainer
                    phoneWidth={phoneWidth}
                    phoneHeight={phoneHeight}
                >
                    <P.Phone src={phone} alt="Phone" ref={ref} />
                    {children}
                </P.PhoneContainer>
            </SkeletonTheme>
        </CustomPreviewContext.Provider>
    );
};

CustomerPreview.Header = Header;
CustomerPreview.Body = Body;
CustomerPreview.Footer = Footer;

export default CustomerPreview;
