export const descriptions = {
    loading: "Loading ...",
    header: {
        message: "Billing",
        description: "Manage payment methods and view invoice history",
    },
    fetch: {
        cleanUrl: "Unable to access billing page.",
        error: "An error occurred on the billing page.",
    },
};
