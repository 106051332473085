import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { PaginationState, SortingState } from "@tanstack/react-table";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

// Get punchcards in a paginated manner
export const usePunchcardPagination = (
    pagination: PaginationState,
    sorting: SortingState,
    search: string,
) => {
    const store = useSelector(getActiveStore);

    // modifying sorting directly messes up its id on the datatable
    const refineSorting = (sortState: SortingState) => {
        if (sortState?.length > 0) {
            const finalState = [
                { id: sortState[0].id, desc: sortState[0].desc },
            ];
            if (sortState[0].id === "points Balance") {
                finalState[0].id = "pointsBalance";
            }
            return finalState;
        }
        return [];
    };
    const isNotPhoneNumberSearch = isNaN(
        Number(search.replace(/[^a-zA-Z0-9]/g, "")), // Remove everything not alphanumeric
    );
    const { pageSize, pageIndex } = pagination;
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: [
            `${store?._id}_${pageIndex}_${pageSize}_punchcards_${search}_${sorting?.length > 0 ? `${sorting[0].desc}_${sorting[0].id}` : ""}`,
        ],
        queryFn: async () => {
            if (!store) return {};
            try {
                const response = await api.punchCards.getPaginatedPunchcards(
                    store?._id ?? "",
                    pageSize,
                    pageIndex,
                    refineSorting(sorting),
                    isNotPhoneNumberSearch
                        ? search
                        : search.replace(/[^\d]/g, ""), // only allow numbers if phone number
                    isNotPhoneNumberSearch ? "user.name" : "user.number",
                );
                return {
                    punchcards: response.data?.punchcards,
                };
            } catch (err) {
                console.error(err);
            }
            return {};
        },
    });

    return {
        data,
        isLoading,
        error,
        refetch,
    };
};
