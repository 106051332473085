import { useContext, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { SystemColors } from "@snackpass/design-system";

import CalloutStat from "#devices/components/DetailsDrawer/CalloutStat";
import DetailsSection from "#devices/components/DetailsDrawer/DetailsSection";
import DescriptionList from "#devices/components/DetailsDrawer/DescriptionList";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { getFormattedDate } from "#devices/components/DevicesTable/lib";
import { ReactComponent as Tablet } from "src/assets/icons/tablet-small.svg";
import { ReactComponent as GroceryCart } from "src/assets/icons/grocery-cart.svg";
import { ReactComponent as Barcode } from "src/assets/icons/barcode.svg";
import { ReactComponent as Calendar } from "src/assets/icons/calendar-small.svg";
import { ReactComponent as Filter } from "src/assets/icons/device-setting.svg";
import { ReactComponent as CashDrawerIcon } from "src/assets/icons/cash-drawer-small.svg";
import { KioskDevice } from "#devices/utils/deviceTypes";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { shouldHaveCashDrawerAddon } from "#devices/utils/shouldHaveCashDrawerAddon";

const StripeTerminalDetails = () => {
    const { device } = useContext(DevicesPageContext);
    const shouldShowCashDrawer = useMemo(
        () => device && shouldHaveCashDrawerAddon(device),
        [device],
    );

    const kioskDevice = device as KioskDevice;

    const { data: cashDrawers, isLoading: isCashDrawerLoading } =
        useCashDrawers();
    const linkedDrawer = useMemo(
        () => cashDrawers?.find((e) => e.device === device?.serial),
        [cashDrawers, device],
    );

    return (
        <>
            <Row>
                <Col>
                    <CalloutStat
                        icon={<GroceryCart />}
                        statNumber={`${kioskDevice.deviceDetails.purchaseCount}`}
                        statText="Lifetime Orders"
                        highlightBorder
                        iconColor={SystemColors.v1.snackpass50}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <DetailsSection heading="Details">
                        <DescriptionList
                            items={[
                                {
                                    term: "Device",
                                    icon: <Tablet />,
                                    description:
                                        getDeviceTypeName(
                                            kioskDevice?.deviceType,
                                        ) || "",
                                },
                                {
                                    term: "Installed",
                                    icon: <Calendar />,
                                    description: getFormattedDate(
                                        kioskDevice?.createdAt,
                                    ),
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
                <Col sm={6}>
                    <DetailsSection heading="Hardware Info">
                        <DescriptionList
                            items={[
                                {
                                    term: "Model",
                                    icon: <Tablet />,
                                    description: `${kioskDevice?.hardwareMake} ${kioskDevice?.hardwareModel}`,
                                    copyable: true,
                                },
                                {
                                    term: "Serial Number",
                                    icon: <Barcode />,
                                    description: kioskDevice?.serial,
                                    copyable: true,
                                },
                            ]}
                        />
                    </DetailsSection>
                </Col>
            </Row>
            <DetailsSection heading="Software Info">
                <DescriptionList
                    items={[
                        {
                            term: "Allow Card Payments",
                            icon: <Filter />,
                            description: kioskDevice.deviceDetails
                                .allowCardPayments
                                ? "On"
                                : "Off",
                        },
                        {
                            term: "Allow Cash Payments",
                            icon: <Filter />,
                            description: kioskDevice.deviceDetails
                                .allowCashPayments
                                ? "On"
                                : "Off",
                        },
                        {
                            term: "Allow Other Payments",
                            icon: <Filter />,
                            description: kioskDevice.deviceDetails
                                .allowOtherPayments
                                ? "On"
                                : "Off",
                        },
                    ]}
                />
            </DetailsSection>
            {!isCashDrawerLoading && shouldShowCashDrawer ? (
                <Row>
                    <Col sm={6}>
                        <DetailsSection heading="Additional Info">
                            <DescriptionList
                                items={[
                                    {
                                        term: "Cash Drawer",
                                        icon: <CashDrawerIcon />,
                                        description:
                                            linkedDrawer?.name ?? "None",
                                    },
                                ]}
                            />
                        </DetailsSection>
                    </Col>
                </Row>
            ) : null}
        </>
    );
};

export default StripeTerminalDetails;
