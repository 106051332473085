import React from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { formatCentsAsDollars } from "#reports/gift-card-purchases/lib";
import { useGiftCardReportQuery } from "src/api/rest/report-hooks";
import {
    GiftCardPurchaseStats,
    GiftCardReportEndpoints,
} from "src/api/rest/report-hooks/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";

const Tile = ({
    title,
    value,
    tooltip,
}: {
    title: string;
    value: string;
    tooltip?: string;
}) => (
    <div className="m-2 rounded-xl border border-neutral-400 p-6">
        <h1 className="mb-0 text-large leading-[48px] xl:text-xlarge">
            {value}
        </h1>
        <h6 className="mb-[8px]">
            {title}{" "}
            {tooltip ? (
                <ReportsTooltip body={tooltip} className="mx-3" />
            ) : null}
        </h6>
    </div>
);

const PurchaseStatTiles = () => {
    const last30DaysStartDate = moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
    const last30DaysEndDate = moment().format("YYYY-MM-DD");

    const { data: last30Data, isLoading: last30Loading } =
        useGiftCardReportQuery<GiftCardPurchaseStats>({
            endpoint: GiftCardReportEndpoints.PurchaseStats,
            useCustomDateRange: true,
            customStartDate: last30DaysStartDate,
            customEndDate: last30DaysEndDate,
        });

    const {
        purchaseTotalAmountCents: last30PurchaseTotalAmountCents,
        usageTotalAmountCents: last30UsageTotalAmountCents,
    } = last30Data?.giftCardPurchaseStats ?? {};

    const { data: allTimeData, isLoading: allTimeLoading } =
        useGiftCardReportQuery<GiftCardPurchaseStats>({
            endpoint: GiftCardReportEndpoints.PurchaseStats,
            useCustomDateRange: true,
            customStartDate: undefined,
            customEndDate: last30DaysEndDate,
        });

    const {
        purchaseTotalAmountCents: allTimePurchaseTotalAmountCents,
        usageTotalAmountCents: allTimeUsageTotalAmountCents,
    } = allTimeData?.giftCardPurchaseStats ?? {};

    return (
        <div className="mt-4">
            <h4 className="ml-3 text-large sm:mr-5">Overview</h4>
            <div className="mt-5 grid grid-cols-1 justify-center gap-2 md:grid-cols-3">
                {!last30Loading && !allTimeLoading ? (
                    last30Data && allTimeData ? (
                        <>
                            <Tile
                                title="Activations (30 days)"
                                value={formatCentsAsDollars(
                                    last30PurchaseTotalAmountCents,
                                )}
                                tooltip={
                                    "Activations (30 days) represent gift cards purchased in the last 30 days. This includes both digital and physical gift cards."
                                }
                            />
                            <Tile
                                title="Redemptions (30 days)"
                                value={formatCentsAsDollars(
                                    last30UsageTotalAmountCents,
                                )}
                                tooltip={
                                    "Redemptions (30 days) represent gift cards used in the last 30 days. This includes both digital and physical gift cards."
                                }
                            />
                            <Tile
                                title="Outstanding (all time)"
                                value={formatCentsAsDollars(
                                    (allTimePurchaseTotalAmountCents ?? 0) -
                                        (allTimeUsageTotalAmountCents ?? 0),
                                )}
                                tooltip={
                                    "Outstanding (all time) represents the total value of gift cards that have been purchased but not yet used for all time."
                                }
                            />
                        </>
                    ) : (
                        Array.from({ length: 3 }, (_, i) => (
                            <ErrorChart key={i} className="m-2 h-32" />
                        ))
                    )
                ) : (
                    Array.from({ length: 3 }, (_, i) => (
                        <Skeleton key={i} className="m-2 h-32" />
                    ))
                )}
            </div>
        </div>
    );
};

export default PurchaseStatTiles;
