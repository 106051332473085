import React, { useContext } from "react";
import { Divider } from "antd";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";

import CloseButton from "#onboarding/components/shared/CloseButton";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { descriptions } from "#onboarding/utils/descriptions";
import { Header } from "#onboarding/components/shared/Header";
import theme from "#onboarding/utils/theme";
import useWindowDimensions from "#hooks/use-window-dimensions";

export const Page = ({
    children,
    footer,
}: {
    children: JSX.Element | JSX.Element[];
    footer: JSX.Element;
}) => {
    const { resetState } = useContext(OnboardingContext);
    const { isMobile } = useWindowDimensions();
    return (
        <Container>
            <div className="header">
                <div className="header-left">
                    <CloseButton onClose={resetState} />
                </div>
                <div className="header-center">{descriptions.steps.header}</div>
                <div className="header-right"></div>
            </div>
            {isMobile ? (
                <div className="mb-2"></div>
            ) : (
                <Divider className="mb-0" />
            )}
            <div className="page-container">
                <div className="content-container">
                    <div className="content-header">
                        <Header />
                    </div>
                    <div className="content-body">{children}</div>
                    <Divider style={{ margin: `${theme.spacing.base} 0` }} />
                    <div className="content-footer">{footer}</div>
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .page-container {
        display: flex;
        flex: 1;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin: auto 0;
        padding: ${theme.spacing.double} 0;
        background-color: ${SystemColors.v2.salt20.light};
        overflow: hidden;

        @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
            padding: 0 0;
        }

        .content-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100vh - 160px);
            overflow: hidden;
            background-color: white;
            width: 600px;
            border: 1px solid ${SystemColors.v2.salt30.light};
            border-radius: ${theme.spacing.base};
         
            @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
                border-radius: 0;
            }

            .content-header {
                padding-top: ${theme.spacing.base};
            }
            .content-header, .content-footer, .content-body {
                padding-left: ${theme.spacing.base};
                padding-right: ${theme.spacing.base};
            }
            .content-body {
                flex-grow: 1;
                overflow-y: auto;
                padding: 0 ${theme.spacing.baseAndAHalf};
            }
            .content-footer {
                padding-bottom: ${theme.spacing.base};
            }
        }
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: ${theme.spacing.half};
    }
    .header-center {
        justify-content: center;
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
    }
    .header-left,
    .header-center,
    .header-right {
        display: flex;
        flex: 1;
    }`}
`;
