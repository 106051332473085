import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import Step1 from "#devices/components/AddNewDeviceModal/Step1";
import Step2 from "#devices/components/AddNewDeviceModal/Step2";
import Step3 from "#devices/components/AddNewDeviceModal/Step3";
import Step4 from "#devices/components/AddNewDeviceModal/Step4";
import ModalCloseButton from "#devices/components/ModalCloseButton";

const AddNewDeviceModal = (): JSX.Element => {
    const {
        setAddNewDevicesModalOpen,
        setAddNewScreenSerialValue,
        addNewDevicesModalCurrentStep,
        addNewDevicesModalOpen,
        setAddNewDeviceName,
        setAddNewDevicesModalCurrentStep,
        setAddNewDeviceTypeValue,
    } = useContext(DevicesPageContext);

    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (
            queryParams &&
            queryParams.has("setup") &&
            queryParams.get("setup") === "true"
        ) {
            setAddNewDevicesModalOpen(true);
            if (queryParams.has("setupCode")) {
                setAddNewScreenSerialValue(queryParams.get("setupCode") ?? "");
            }
        }
    }, []);

    const handleClose = () => {
        setAddNewDevicesModalOpen(false);
        setAddNewDevicesModalCurrentStep(1);
        setAddNewScreenSerialValue("");
        setAddNewDeviceTypeValue("");
        setAddNewDeviceName("");
    };

    const renderStep = () => {
        switch (addNewDevicesModalCurrentStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            default:
                return <Step1 />;
        }
    };

    return (
        <Modal onHide={handleClose} show={addNewDevicesModalOpen}>
            <Modal.Header>
                <ModalCloseButton onClose={handleClose} />
            </Modal.Header>

            {renderStep()}
        </Modal>
    );
};

export default AddNewDeviceModal;
