import { cva, VariantProps } from "class-variance-authority";
import { useShallow } from "zustand/react/shallow";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { match, P } from "ts-pattern";

import {
    useEditorState,
    useEditorStore,
} from "#table-editor/components/Editor/EditorProvider";
import { cn } from "src/@/lib/utils";
import { Shape, Element as Variant } from "src/api/graphql/generated/types";
import { useCanvasEffect } from "#table-editor/components/Editor/hooks";
import { makeElement } from "#table-editor/canvas/makeElement";

const tool = cva("rounded-sm border-neutral-500", {
    variants: {
        border: {
            solid: "border-2 border-solid",
            dashed: "border-2 border-dashed",
        },
        fill: {
            solid: "bg-neutral-200",
            empty: "bg-transparent",
        },
        draw: {
            square: "h-9 w-9",
            rect: "h-5 w-16",
            diamond: "h-8 w-8 rotate-45",
            circle: "h-9 w-9 rounded-full",
        },
        selected: {
            borderFill: "border-success-dark bg-success-accent-light/20",
            border: "border-success-a11y-dark",
            false: "",
        },
    },
    defaultVariants: {
        border: "solid",
        fill: "solid",
    },
});

export function Toolbar() {
    const editor = useEditorStore();

    const controls = useEditorState(useShallow((state) => state.controls));

    useEffect(() => {
        function deselectTool(e: KeyboardEvent) {
            if (e.key === "Escape") {
                editor.getState().setEditMode();
            }
        }

        window.addEventListener("keydown", deselectTool);

        return () => {
            window.removeEventListener("keydown", deselectTool);
        };
    }, [editor]);

    useCanvasEffect(
        (canvas) =>
            canvas.on("mouse:down", (e) => {
                const controls = editor.getState().controls;

                if (controls.mode === "draw" && e.target == null) {
                    const newElement = makeElement(
                        e.viewportPoint.x,
                        e.viewportPoint.y,
                        controls.shape,
                        controls.variant,
                    );

                    editor.getState().addElement(newElement);
                    editor.getState().setEditMode();
                }
            }),
        [editor],
    );

    return (
        <>
            {controls.mode !== "edit" ? (
                <div className="absolute bottom-32 left-1/2 -translate-x-1/2 rounded-md bg-neutral-950 p-3 shadow-md">
                    <p className="text-sm text-white">
                        Tap on the canvas to draw
                    </p>
                </div>
            ) : null}
            <div className="absolute bottom-12 left-1/2 flex -translate-x-1/2 flex-row items-center rounded-md border-2 border-neutral-400 bg-white shadow-md">
                <Segment name="Table" className="border-r border-r-neutral-400">
                    <Tool
                        shape={Shape.Rect}
                        variant={Variant.Table}
                        draw="square"
                        border="solid"
                        fill="solid"
                    />
                    <Tool
                        shape={Shape.Diamond}
                        variant={Variant.Table}
                        draw="diamond"
                        border="solid"
                        fill="solid"
                    />
                    <Tool
                        shape={Shape.Circle}
                        variant={Variant.Table}
                        draw="circle"
                        border="solid"
                        fill="solid"
                    />
                </Segment>
                <Segment name="Layout">
                    <Tool
                        shape={Shape.Rect}
                        variant={Variant.Barrier}
                        draw="rect"
                        border="solid"
                        fill="solid"
                    />
                    <Tool
                        shape={Shape.Rect}
                        variant={Variant.Label}
                        draw="rect"
                        border="dashed"
                        fill="empty"
                        className="flex flex-row items-center justify-center"
                    >
                        <p className="select-none text-sm font-medium text-neutral-950">
                            Label
                        </p>
                    </Tool>
                </Segment>
            </div>
        </>
    );
}

type ToolProps = PropsWithChildren<
    Omit<VariantProps<typeof tool>, "selected">
> & {
    className?: string;
    shape: Shape;
    variant: Variant;
};

function Tool({
    fill,
    border,
    shape,
    draw,
    variant,
    className,
    children,
}: ToolProps) {
    const editor = useEditorStore();
    const controls = useEditorState(useShallow((state) => state.controls));

    const onClick = useCallback(() => {
        if (
            controls.mode === "draw" &&
            controls.shape === shape &&
            controls.variant === variant
        ) {
            editor.getState().setEditMode();
        } else {
            editor.getState().setDrawMode(shape, variant);
        }
    }, [controls, editor, shape, variant]);

    const isSelected = useMemo(
        () =>
            controls.mode === "draw" &&
            controls.shape === shape &&
            controls.variant === variant,
        [controls, shape, variant],
    );

    const selected = match({ shape, variant })
        .with(
            {
                shape: P.union(Shape.Rect, Shape.Diamond, Shape.Circle),
                variant: P.union(Variant.Table, Variant.Barrier),
            },
            () => "borderFill" as const,
        )
        .with(
            { shape: Shape.Rect, variant: Variant.Label },
            () => "border" as const,
        )
        .otherwise(() => "borderFill" as const);

    return (
        <div
            className={cn(
                tool({
                    fill,
                    border,
                    draw,
                    selected: isSelected ? selected : false,
                }),
                "cursor-pointer",
                className,
            )}
            children={children}
            onClick={onClick}
        />
    );
}

type SegmentProps = PropsWithChildren<{
    name: string;
    className?: string;
}>;

function Segment({ name, className, children }: SegmentProps) {
    return (
        <div
            className={cn(
                "flex flex-row items-center gap-6 px-6 py-3",
                className,
            )}
        >
            <h3 className="text-sm font-semibold text-neutral-600">{name}</h3>
            {children}
        </div>
    );
}
