import { Col, Row } from "antd";
import React, { CSSProperties } from "react";
import styled from "styled-components";

import { ReactComponent as Error } from "src/assets/icons/rules-alert.svg";
import { ReactComponent as Warning } from "src/assets/icons/warn-schedule-ahead-ability.svg";

export type MenuAlertTypes =
    | "no-name"
    | "title-case-name"
    | "no-time"
    | "bad-price-adjustment"
    | "no-platform"
    | "third-party"
    | "schedule-ahead"
    | "has-overlapping-hours"
    | "has-hours-start-time-before-end-time";

export type MenuAlert = {
    noName: boolean;
    titleCaseName: boolean;
    badAdjustment: boolean;
    noTime: boolean;
    noPlatform: boolean;
    noThirdParty: boolean;
    scheduleAhead: boolean;
    hasOverlappingHours: boolean;
    hasHoursStartTimeBeforeEndTime: boolean;
};

export const menuAlertPropertyMap = (
    alertType: MenuAlertTypes,
): keyof MenuAlert => {
    switch (alertType) {
        case "no-name":
            return "noName";
        case "title-case-name":
            return "titleCaseName";
        case "bad-price-adjustment":
            return "badAdjustment";
        case "no-time":
            return "noTime";
        case "no-platform":
            return "noPlatform";
        case "third-party":
            return "noThirdParty";
        case "schedule-ahead":
            return "scheduleAhead";
        case "has-overlapping-hours":
            return "hasOverlappingHours";
        case "has-hours-start-time-before-end-time":
            return "hasHoursStartTimeBeforeEndTime";
    }
};

export const rulesDefaultValue: MenuAlert = {
    noName: false,
    titleCaseName: false,
    badAdjustment: false,
    noTime: false,
    noPlatform: false,
    noThirdParty: false,
    scheduleAhead: false,
    hasOverlappingHours: false,
    hasHoursStartTimeBeforeEndTime: false,
};

const alertDescription = (type: MenuAlertTypes) => {
    switch (type) {
        case "no-name":
            return "You must provide a menu name.";
        case "title-case-name":
            return "Name should be title cased (first letter of each word capitalized).";
        case "bad-price-adjustment":
            return "You must specify an Amount. Set it to 0 if you don't want the price to change";
        case "no-time":
            return "You must select the time in which the menu will be available.";
        case "no-platform":
            return "You must select the platform in which the menu will be available.";
        case "third-party":
            return "You can only have one third party menu active at a time.";
        case "schedule-ahead":
            return "Menu hours do not match store hours and may affect ability to schedule ahead.";
        case "has-overlapping-hours":
            return "Menu has hours that are overlapping. Please make sure that the hours for days do not overlap.";
        case "has-hours-start-time-before-end-time":
            return "Menu hours end time must be after start time.";
        default:
            return "";
    }
};

type WarningProps = {
    type: MenuAlertTypes;
    isMobile: boolean;
};

export const RuleWarning: React.FC<WarningProps> = ({ type, isMobile }) => {
    const warning = type === "schedule-ahead" || type === "title-case-name";
    const textColor = {
        color: warning ? "#826f21" : "#ff3929",
    } as CSSProperties;

    return (
        <Row>
            <Col span={isMobile ? 2 : 1} style={warningIcon}>
                {warning ? <Warning /> : <Error />}
            </Col>
            <Col span={isMobile ? 21 : 22} style={warningText}>
                <TextWrapper style={textColor}>
                    {alertDescription(type)}
                </TextWrapper>
            </Col>
        </Row>
    );
};

const warningIcon = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4px",
} as CSSProperties;

const warningText = {
    marginTop: "4px",
} as CSSProperties;

const TextWrapper = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ff3929;
`;
