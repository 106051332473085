import { Addon, AddonGroup, IProduct } from "@snackpass/snackpass-types";
import _ from "lodash";
import compose from "lodash/fp/compose";

import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";

export const alertAxiosError = compose(
    alert,
    (msg) => `ERROR: ${msg}`,
    ({ response }) => response.data.message,
);

/**
 * Checks whether the id is a real server id or a temporary one
 * @param id
 * @returns
 */
export const isServerGeneratedId = (id: string) => id.length < 23;

export const hasDuplicates = (
    arr: (
        | IProductCategoryWithProducts
        | Partial<IProduct>
        | Partial<AddonGroup>
        | Partial<Addon>
        | string
    )[],
    identity?: string,
) => {
    if (identity) {
        return _.uniqBy(arr, identity).length !== arr.length;
    }

    if (arr.length === 0) {
        return false;
    }
    if (typeof arr[0] === "string") {
        return _.uniq(arr).length !== arr.length;
    }

    return _.uniqBy(arr, "_id").length !== arr.length;
};

export const isCategoryNameTaken = (
    newCategoryName: string,
    products: IProduct[],
    isCatering: boolean,
) => {
    const existingCategoryNames = new Set(
        products.map(
            (product) =>
                product.isCatering === isCatering &&
                product.category.toLowerCase(),
        ),
    );
    return existingCategoryNames.has(newCategoryName.toLowerCase());
};

export const isValidCateringCategoryName = (name: string) => {
    const pattern = new RegExp(/\(Catering\)$/);
    return pattern.test(name);
};
/**
 *  Takes in a number and converts it to a string with commas and fixed number of decimal places.
 *  Default precision is 2
 */
export const toLocaleFixed = (num: number, n = 2) =>
    num.toLocaleString(undefined, {
        minimumFractionDigits: n,
        maximumFractionDigits: n,
    });
