import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";

import CoinSVG from "src/assets/icons/coin.svg";

export const Subtitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;

type AdjustmentAmountProps = {
    width?: string;
};

export const AdjustmentAmount = styled.label<AdjustmentAmountProps>`
    position: relative;
    width: ${(props) => props?.width || "100%"};
    :before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 40px;
        height: 34px;
        background: url(${CoinSVG}) center no-repeat;
        margin-top: 3px;
    }
    input {
        color: ${SystemColors.v1.gray30};
        border: 1.5px solid ${SystemColors.v1.gray80};
        border-radius: 8px;
        margin-top: 3px;
        float: left;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
        outline: none;
        padding: 0 0 0 35px;
        width: 100%;
    }
`;
