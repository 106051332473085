import React, { useContext, useState } from "react";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";
import { debounce } from "radash";
import { useSelector } from "react-redux";

import { SummaryRow } from "#onboarding/components/shared/SummaryRow";
import { OnboardingSteps } from "#onboarding/utils/steps";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { descriptions } from "#onboarding/utils/descriptions";
import Text from "#onboarding/components/shared/Text";
import { useIsCompleted } from "#onboarding/utils/validation/completed";
import { OnboardingStep } from "#onboarding/utils/types";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import api from "src/api/rest";
import {
    notifyFailure,
    notifyInfo,
    notifySuccess,
} from "#onboarding/utils/notify";
import { UpdatedButton } from "#onboarding/components/shared/UpdatedButton";
import { getActiveStore, getUser } from "src/redux/selectors";

export const Summary = () => {
    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);
    const { isMobile } = useWindowDimensions();
    const { loading, setLoading } = useContext(OnboardingContext);
    const [completed, setCompleted] = useState(false);
    const allDone = useIsCompleted({ name: OnboardingStep.Summary });

    const isValid = allDone && !completed && !loading;
    const disabled = completed || !allDone;

    const handleClick = () => {
        if (disabled || !isValid) {
            notifyInfo();
            return;
        }
        void debouncedFinish();
    };

    const submitFinished = () => {
        if (store?._id && !loading) {
            setLoading(true);
            void api.stores
                .finishOnboarding(store._id)
                .then(({ data }) => {
                    setLoading(false);
                    if (data.success) {
                        setCompleted(true);
                        notifySuccess({
                            message: "🎉 Success! 🎉",
                            description:
                                "Submission received! The dashboard will reload for you in a moment.",
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    } else {
                        notifyFailure({
                            message: "Unable to Submit",
                            description:
                                "If this continues to happen, please contact support.",
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    notifyFailure({
                        message: "Unable to Submit",
                        description: error.message,
                    });
                });
        }
    };

    const debouncedFinish = debounce({ delay: 300 }, submitFinished);

    return (
        <Container>
            <div className="steps-header">
                <Text.Title2>{descriptions.summary.header.title}</Text.Title2>
                {!isMobile && (
                    <Text.Title2>
                        {descriptions.summary.header.title2}
                    </Text.Title2>
                )}
                {!isMobile && (
                    <Text.Subtitle className="subtitle-wrapper">
                        {descriptions.summary.header.subtitle}
                    </Text.Subtitle>
                )}
            </div>
            <div className="steps-container">
                {OnboardingSteps.map((step) => (
                    <SummaryRow key={step.name} step={step} />
                ))}
                <div className="steps-finish">
                    <UpdatedButton
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        smallRadius
                        size="regular"
                        style={{ width: "200px" }}
                        variant="primary"
                        children={<>{descriptions.buttons.finish}</>}
                        onClick={handleClick}
                    />
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex: 1;

    margin: ${theme.spacing.triple} 0;

    @media ${ScreenState.MOBILE}, ${ScreenState.TABLET}, {
        margin: ${theme.spacing.base} 0;
    }

    & .form-control,
    & .form-select {
        font-family: "Inter";
    }

    .steps-header {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        margin: ${theme.spacing.base} auto;
    
        h2 {
            margin: 0 auto;
            justify-content: center;
        }
    
        h2.subtitle-wrapper {
            display: flex;
            flex-direction: row;
            flex: 1;
            width: 100%;
            justify-content: center;
            margin-top: ${theme.spacing.half};
        }
    
        @media ${ScreenState.DESKTOP} {
            max-width: 600px;
        }
    
        @media ${ScreenState.MOBILE} {
            max-width: 92.5%;
            margin-left: ${theme.spacing.base};
            margin-right: ${theme.spacing.base};
        }
    
        @media ${ScreenState.TABLET} {
            max-width: 95%;
            margin-left: ${theme.spacing.baseAndAHalf};
            margin-right: ${theme.spacing.tripe};
        }
    }

    .steps-finish {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        max-width: 600px;
        margin: 0 auto;
        margin-top: ${theme.spacing.base};
        margin-bottom: ${theme.spacing.base};
    }

    .steps-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;
        max-width: 600px;

        margin: ${theme.spacing.double} auto;
        justify-content: center;
        
        @media ${ScreenState.TABLET}, ${ScreenState.MOBILE} {
            margin: 0;
            padding: ${theme.spacing.half} ${theme.spacing.base};
            max-width: 100%;
            width: 100%;
        }

        @media ${ScreenState.TABLET} {
            margin-bottom: ${theme.spacing.triple};
        }
        

        & .container-fluid {
            padding: 0;
        }
    }
    `}
`;
