import printJS from "print-js";

export function printHTML(html: string) {
    const now = Date.now().toString();
    const elem = document.createElement("div");
    elem.innerHTML = html;
    elem.setAttribute("id", now);
    document.getElementsByTagName("body")[0].appendChild(elem);

    printJS({
        printable: now,
        type: "html",
    });

    elem.remove();
}
