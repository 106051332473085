/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from "react";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DeviceType } from "#devices/utils/deviceTypes";
import { CenteredSpin } from "#devices/components/Loading";
import { DataTable } from "src/@/components/ui/data-table";
import { useStoreDevices } from "#devices/hooks/useStoreDevices";

import { columnDefinitions } from "./columnDefinitions";

export const DevicesTable = (): JSX.Element => {
    const { storeDevices, currentFilters } = useContext(DevicesPageContext);
    const { isLoading } = useStoreDevices();

    const data = useMemo(() => {
        if (!storeDevices) return [];
        const filteredAndSortedDevices = storeDevices
            .filter(
                (d) =>
                    ![DeviceType.CashDrawer, DeviceType.PrepStation].includes(
                        d.deviceType,
                    ) &&
                    (currentFilters.deviceType.includes(DeviceType.All) ||
                        currentFilters.deviceType.includes(d.deviceType)),
            )
            .sort((a, b) => a.deviceType.localeCompare(b.deviceType));

        return filteredAndSortedDevices;
    }, [storeDevices, currentFilters]);

    const includeStatusColumn = data.some((d) => !!d.latestNetworkStatus);
    const columns = useMemo(
        () => columnDefinitions({ includeStatusColumn }),
        [includeStatusColumn],
    );

    if (isLoading) {
        return (
            <div style={{ margin: 70 }}>
                <CenteredSpin />
            </div>
        );
    }

    return (
        <DataTable
            key={data.length}
            data={data}
            customPageSize={data.length}
            columns={columns}
            toolbar={{
                search: {
                    key: "name",
                    placeholder: "Search Device Name or ID",
                },
            }}
        />
    );
};
