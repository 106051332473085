import { ApolloError } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";

import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Button } from "src/@/components/ui/button";

type ApolloErrorCardProps = {
    title: string;
    error: ApolloError;
};

export function ApolloErrorCard({ title, error }: ApolloErrorCardProps) {
    const clickHandler = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <Alert
            variant="destructive"
            className="flex max-w-screen-sm flex-col gap-1"
        >
            <ExclamationCircleIcon className="h-5 w-5" />
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription className="flex flex-col gap-2">
                <p>{error.message}</p>
                <div className="flex justify-end">
                    <Button onClick={clickHandler}>Reload</Button>
                </div>
            </AlertDescription>
        </Alert>
    );
}
