import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "react-activity";
import { SystemColors } from "@snackpass/design-system";

import ExternalButtonLink from "#shared-components/ExternalButtonLink";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "src/@/components/ui/dialog";
import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors/activeStore";
import { Routes } from "#navigation/routes";
import { Button } from "src/@/components/ui/button";

type Props = {
    billingEnabledChild: React.ReactElement;
    billingDisabledChild: React.ReactElement;
};

function RequiresPaymentMethod({
    billingDisabledChild,
    billingEnabledChild,
}: Props) {
    const store = useSelector(getActiveStore);
    const { data, error } = useQuery({
        queryKey: ["billingMethods", store?._id],
        queryFn: async () => api.billing.getHasBillingMethods(store!._id),
        enabled: store?._id != null,
        retry: false,
    });

    // Manually controlled instead of dialog trigger so we can take the
    // styles of primary button. The child should also mark its button as
    // "asChild" to avoid nested button elements causing a dom warning
    const [open, setOpen] = useState(false);

    const hasBillingMethodData = data?.data;

    if (hasBillingMethodData == null && error == null) {
        return <Spinner color={SystemColors.v1.candy50} size={12} />;
    }

    if (hasBillingMethodData?.hasExistingMethods) {
        return billingEnabledChild;
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <Button type="button" onClick={() => setOpen(true)}>
                {billingDisabledChild}
            </Button>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Payment Method Required</DialogTitle>
                    <DialogDescription>
                        You need to have a payment method on file to use this
                        feature
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <ExternalButtonLink
                        href={hasBillingMethodData?.portalUrl ?? Routes.Billing}
                    >
                        Go to Billing
                    </ExternalButtonLink>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default RequiresPaymentMethod;
