import React, { useContext } from "react";
import { DateTime } from "luxon";

import { PersonVerification, Setting } from "#payouts/domain/types";
import { formatPhone } from "#payouts/utils/formatPhone";
import { RepresentativeBlock } from "#payouts/components/shared/RepresentativeBlock";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";

export const Representative = ({ r }: { r: PersonVerification }) => {
    const { isEmployee, editEnabled } = useContext(PayoutsSettingsContext);

    const roles: string[] = [];
    r?.representative ? roles.push("Primary") : null;
    r?.owner ? roles.push("Owner") : null;
    r?.director ? roles.push("Director") : null;
    r?.executive ? roles.push("Executive") : null;

    if (!roles.length) {
        roles.push("No role provided");
    }

    if (r.address && /Not-Provided/i.test(r.address.line2 ?? "")) {
        r.address.line2 = undefined;
    }

    const settings: Setting[] = [
        {
            field: "legalName",
            label: "Legal Name",
            value: r?.firstName ? `${r.firstName} ${r.lastName}` : undefined,
        },
        {
            field: "role",
            label: "Role",
            value: roles.join(", "),
        },
        {
            field: "title",
            label: "Title",
            value: r?.title,
        },
        {
            field: "email",
            label: "Email",
            value: r?.email,
        },
    ];

    if (editEnabled || isEmployee) {
        settings.push({
            field: "dateOfBirth",
            label: "Date of Birth",
            value: r.dateOfBirth
                ? DateTime.fromSeconds(r.dateOfBirth)
                      .setZone("UTC")
                      .toLocaleString(DateTime.DATE_MED)
                : undefined,
        });
        settings.push({
            field: "phone",
            label: "Phone Number",
            value: formatPhone(r?.phone),
        });
        settings.push({
            field: "address",
            label: "Address",
            value: r?.address,
        });
    }

    settings.push({
        field: "idNumber",
        label: "Full Social Security Number",
        value: r?.idNumber,
        encrypted: true,
    });

    return <RepresentativeBlock settings={settings} />;
};
