import React, { useContext } from "react";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DeviceType } from "#devices/utils/deviceTypes";

import { PrepStationAddonView } from "./PrepStationAddon";

const DeviceAddonView = (): JSX.Element => {
    const { addon } = useContext(DevicesPageContext);

    switch (addon?.type) {
        case DeviceType.PrepStation:
            return <PrepStationAddonView />;
        default:
            return <div>Unknown Addon</div>;
    }
};

export default DeviceAddonView;
