import React from "react";
import { ISelectedAddon } from "@snackpass/snackpass-types";
import { toDollar } from "@snackpass/accounting";

import { colors } from "#utils/colors";
import { Text, View } from "#legacy-components";
import { PurchaseItemGrouping } from "src/redux/Types";

type AddonsProps = {
    itemGrouping: PurchaseItemGrouping;
};

export default function Addons({ itemGrouping }: AddonsProps) {
    const firstItem = itemGrouping.items[0];

    if (firstItem.selectedAddons.length === 0) {
        return null;
    }

    return (
        <View style={{ paddingLeft: 0, alignSelf: "stretch", flex: 1 }}>
            {firstItem.selectedAddons.map(renderAddon)}
        </View>
    );
}

const renderAddonPrice = (addon: ISelectedAddon) => {
    const addonHasDiscount = addon.price != addon.priceAfterDiscount;

    if (addon.price && addon.price > 0) {
        return (
            <Text small color={colors.gray}>
                (+
                <Text
                    small
                    lineThrough={addonHasDiscount ? true : false}
                    color={colors.gray}
                >
                    {toDollar(addon.price * addon.quantity)}
                </Text>
                {addonHasDiscount && addon.priceAfterDiscount ? (
                    <Text style={{ marginLeft: 5 }} small color={colors.gray}>
                        {toDollar(addon.priceAfterDiscount * addon.quantity)}
                    </Text>
                ) : null}
                )
            </Text>
        );
    }

    return null;
};

const renderAddonQuantity = (addon: ISelectedAddon) => {
    if (addon.quantity != 1) {
        return (
            <Text small color={colors.gray}>
                {addon.quantity}x&nbsp;
            </Text>
        );
    }

    return null;
};

const renderAddon = (addon: ISelectedAddon, index: number) => (
    <View style={{ flexDirection: "row" }} key={index}>
        <Text small color={colors.gray} style={{ flex: 3 }}>
            {renderAddonQuantity(addon)}
            {addon.name} {renderAddonPrice(addon)}
        </Text>
    </View>
);
