import React, { useContext } from "react";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";

import Text from "#devices/components/Text";
import colors from "#reusable/colors/colors.json";
import DrawerBackButton from "#devices/components/DrawerBackButton";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { getAddonTypeName } from "#devices/utils/deviceOptions";

const DrawerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin: ${({ theme }) =>
        `-${theme.spacing.baseAndAHalf} -${theme.spacing.baseAndAHalf} ${theme.spacing.baseAndAHalf} -${theme.spacing.baseAndAHalf}`};
    padding: ${({ theme }) => theme.spacing.baseAndAHalf};

    & h3 {
        margin-bottom: 0;
    }

    @media ${ScreenState} {
        padding-top: ${({ theme }) => theme.spacing.base};
        padding-bottom: ${({ theme }) => theme.spacing.base};
    }
`;

type AddonDrawerHeaderProps = {
    onClose: () => void;
};

const AddonDrawerHeader = ({
    onClose,
}: AddonDrawerHeaderProps): JSX.Element => {
    const { addon } = useContext(DevicesPageContext);

    const friendlyType = getAddonTypeName(addon?.type);

    const action = addon?.isCreate ? "Create" : "Edit";
    const description = addon?.isCreate
        ? friendlyType
        : addon?.details?.name ?? "Device";

    return (
        <DrawerHeader>
            <DrawerBackButton onClose={onClose} />
            <Text.Title3>{`${action} ${description}`}</Text.Title3>
            {/* empty div so we can center the <Text> with flex justify space-between */}
            <div />
        </DrawerHeader>
    );
};

export default AddonDrawerHeader;
