import React, { useCallback } from "react";
import { SystemColors } from "@snackpass/design-system";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { css } from "@emotion/css";
import { ScreenState } from "@snackpass/snackpass-types";

import GiftIcon from "src/assets/icons/gift-black.png";
import ChatIcon from "src/assets/icons/chat/black.png";
import CircleButton from "#guestbook/components/styled-components/circle-button";

import { SendButton } from "./send-button";
import { Input } from "./input";

const MessageInput = ({
    setShowCompose,
}: {
    setShowCompose: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
    <div className={styles}>
        <div className="input-container">
            <Input />
        </div>
        <MessageActions setShowCompose={setShowCompose} />
    </div>
);

const MessageActions = ({
    setShowCompose,
}: {
    setShowCompose: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const dispatch = useDispatch();

    const onClickChat = useCallback(() => {
        dispatch(show("TemplatesDrawer"));
    }, []);

    const onPickReward = useCallback(() => {
        dispatch(show("IncentivesDrawer"));
    }, []);

    return (
        <div className="message-actions">
            <CircleButton
                icon={ChatIcon}
                buttonStyle={{
                    border: "1px solid " + SystemColors.v1.gray80,
                    backgroundColor: SystemColors.v1.white,
                }}
                onClick={onClickChat}
                hasPopover
                popOverTitle="Select Message Template"
            />

            <CircleButton
                icon={GiftIcon}
                buttonStyle={{
                    border: "1px solid " + SystemColors.v1.gray80,
                    backgroundColor: SystemColors.v1.white,
                }}
                onClick={onPickReward}
                hasPopover
                popOverTitle="Select Reward"
            />

            <SendButton setShowCompose={setShowCompose} />
        </div>
    );
};

const styles = css`
    display: flex;
    flex-direction: row;
    padding: 10px 25px;
    justify-content: center;
    align-items: flex-end;

    .input-container {
        flex: 1;
        margin-right: 10px;
    }
    .message-actions {
        @media ${ScreenState.MOBILE} {
            display: flex;
            flex-direction: column;
        }
    }
`;

export { MessageInput };
