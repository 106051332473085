import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { PromoTarget } from "@snackpass/snackpass-types";
import { captureException } from "@sentry/react";
import styled from "@emotion/styled";
import useDeepCompareEffect from "use-deep-compare-effect";
import { toast } from "sonner";

import { PromoFormContainer } from "#promotion/components/shared/form-container";
import { FormSectionTitle } from "#promotion/components/shared/form-section-title";
import { PromoDiscountType } from "#promotion/components/promo-discount-type";
import { TitleBreadcrumbs } from "#promotion/components/title-bread-crumbs";
import { FormDropdownSelect } from "#promotion/components/shared/form-dropdown-select";
import {
    FIELD_NAMES,
    FormFirstTimeState,
    FormStudentState,
    FormSubmitState,
    FormUsageTypes,
    GenericPromo,
    PlatformValues,
} from "#promotion/utils/types";
import { ReviewModal } from "#promotion/components/review-modal";
import { PromoName } from "#promotion/components/promo-name";
import {
    AUDIENCE_OPTIONS,
    DISCOUNT_TYPES,
    DOLLAR_OPTIONS,
    Forms,
    PERCENT_OPTIONS,
} from "#promotion/utils/constants";
import { FormSubmit } from "#promotion/components/shared/form-submit";
import { QualifyingItems } from "#promotion/components/qualifying-items";
import { ScheduleSelector } from "#promotion/components/schedule-selector";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import { PromoImage } from "#promotion/components/promo-image";
import { AdvancedContainer } from "#promotion/components/shared/form-advanced-container";
import { RequirePurchase } from "#promotion/components/require-purchase";
import { CartSize } from "#promotion/components/cart-size";
import { LimitTotalUses } from "#promotion/components/limit-total-uses";
import { Duration } from "#promotion/components/duration";
import { Platforms } from "#promotion/components/platforms";
import { FulfillmentMethods } from "#promotion/components/fulfillment-methods";
import { isPromoTypeChangeError } from "#promotion/lib";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { BackButton } from "#promotion/components/back-button";
import {
    GenericDefaultValues,
    GenericPromoValidationSchema,
} from "#promotion/utils/validation";

import { useSubmitPromo } from "./useSubmitPromo";

type Props = RouteComponentProps<
    any,
    any,
    FormFirstTimeState | FormStudentState
>;

export const AudienceSpecificPromotionForm = (props: Props) => {
    const { isMobile } = useWindowDimensions();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state } = props.location;
    const history = useHistory();
    const submitPromo = useSubmitPromo();
    const methods = useForm<GenericPromo>({
        // @ts-expect-error see https://stackoverflow.com/questions/69271892/react-hook-form-resolver-type-error-using-yup
        resolver: yupResolver(GenericPromoValidationSchema),
        mode: "all",
        defaultValues: GenericDefaultValues,
    });
    const { handleSubmit, watch, reset } = methods;
    const formDataState = watch();

    let formData: Partial<GenericPromo> = {};
    let formUsage = FormUsageTypes.Create; // This form is only used for creating, not editing
    let promoId: string | undefined = state?.promoId || undefined;

    const audiencePromoFieldDetails = (audience: PromoTarget) => {
        switch (audience) {
            case PromoTarget.Students:
                return {
                    promoTitle: "Student",
                    namePlaceholder: "Student Promo 🎓",
                };
            case PromoTarget.FirstTime:
                return {
                    promoTitle: "First-time Customer",
                    namePlaceholder: "First-Time Customer Promo ⭐️",
                };
            default:
                return {
                    promoTitle: "Audience",
                    namePlaceholder: "Audience Promo",
                };
        }
    };

    useDeepCompareEffect(() => {
        // If pushed to form with default values, use them!
        // This happens if the user is editing an existing promo
        // or using a shortcut to create a new promo.
        if (state) {
            formData = state.formData;
            formUsage =
                state.formUsage === "Create"
                    ? FormUsageTypes.Create
                    : FormUsageTypes.Edit;
            promoId = state.promoId;
            reset(formData); // This will set new defaultValues
        } else {
            // If no incoming values, then this is a new promo creation, use default values for promo type
            reset(GenericDefaultValues);
        }
    }, [state]);

    const openReviewModal = () => {
        setIsModalOpen(true);
    };

    // Will only be called if the form submission is valid based on the validation schema
    const onSubmitConfirmed = async () => {
        const submitState: FormSubmitState = {
            formUsage,
            success: false,
        };
        if (!isSubmitting) {
            setIsSubmitting(true);
            try {
                await submitPromo(formUsage, formDataState, promoId);
                // Set submit state to true so that notification shows up
                submitState.success = true;
                // Reset the form
                reset(GenericDefaultValues);
                // Go back to list of promos on success
                history.push("/promotion", submitState);
            } catch (err: any) {
                const message: string = err.cause.response?.data?.message;
                // Handle Promo type change attempt/validation error
                // This occurs when a promotion's derived type during submit is different from its existing type
                if (isPromoTypeChangeError(message)) {
                    toast.error("Promo Type Change Detected", {
                        description:
                            "Fields were changed in a way that resulted in a different promo type being saved, which is not currently supported. \n\nPlease create a new promotion or reach out to support.",
                    });
                } else {
                    // Only log to Sentry for non-handled errors
                    captureException(Error(JSON.stringify(err)));
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const discountType = watch(FIELD_NAMES.DISCOUNT_TYPE);
    const { promoTitle, namePlaceholder } = audiencePromoFieldDetails(
        watch(FIELD_NAMES.AUDIENCE),
    );

    return (
        // Using ts-ignore due to error "Type instantiation is excessively deep and possibly infinite"
        //@ts-ignore
        <FormProvider {...methods}>
            <TitleContainer>
                {isMobile ? <BackButton /> : null}
                <TitleBreadcrumbs
                    title={`Create a ${promoTitle} Promotion`}
                    breadcrumbs={[
                        ["Promos", "promotion"],
                        ["New Promo", ""],
                    ]}
                />
            </TitleContainer>
            <form onSubmit={handleSubmit(openReviewModal)}>
                <PromoFormContainer>
                    <FormSectionTitle title="Details" />
                    <PromoName placeholder={namePlaceholder} />
                    <PromoDiscountType />
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount (%)"
                            descriptor="Most restaurants start with 20% - 50% off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT}
                            options={PERCENT_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `${val}%`}
                            required
                        />
                    )}
                    {discountType === DISCOUNT_TYPES.DOLLARS_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount ($)"
                            descriptor="Most restaurants start with $1 - $5 off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS}
                            options={DOLLAR_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                            required
                        />
                    )}
                    {discountType === DISCOUNT_TYPES.NEW_PRICE && (
                        <FormDropdownSelect
                            name="New Price Amount ($)"
                            descriptor="Sets the price for each item this promotion is applied to"
                            fieldName={
                                FIELD_NAMES.DISCOUNT_NEWPRICEAMOUNT_DOLLARS
                            }
                            options={DOLLAR_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                            required
                        />
                    )}
                    <FormDropdownSelect
                        name="Audience (App & Online Only)"
                        descriptor="Choose who can use this promo"
                        fieldName={FIELD_NAMES.AUDIENCE}
                        options={AUDIENCE_OPTIONS}
                        divider
                        autofilled
                        disabled
                    />
                    <FormSectionTitle title="Discounted Items" />
                    <QualifyingItems />
                    {/* Snackface only shows this field for promos of type discount, with discount type percent off */}
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormToggle
                            name="Apply Discount to Add-ons"
                            descriptor="Include add-ons when applying discount"
                            fieldName={FIELD_NAMES.DISCOUNT_ADDONS}
                            divider
                        />
                    )}
                    <PromoImage />
                    {/** Students and FirstTime use the same advancedFields, so doesn't matter which one goes here */}
                    <AdvancedContainer fields={Forms.STUDENTS.advancedFields}>
                        <SectionContainer>
                            <FormSectionTitle title="Cart Rules" level={2} />
                            <RequirePurchase />
                            <CartSize />
                            <FormToggle
                                name="One Per Cart"
                                descriptor="Customers can only redeem this promo once per order"
                                fieldName={FIELD_NAMES.ONE_PER_CART}
                                divider
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <FormSectionTitle
                                title="Customer Rules"
                                level={2}
                            />
                            <FormToggle
                                name="Single-use (App & Online Only)"
                                descriptor="Customers can only redeem this promo once"
                                fieldName={FIELD_NAMES.SINGLE_USE}
                                divider
                            />
                            <LimitTotalUses />
                        </SectionContainer>
                        <SectionContainer>
                            <FormSectionTitle title="Duration" level={2} />
                            <ScheduleSelector />
                            <Duration />
                        </SectionContainer>
                        <SectionContainer>
                            <FormSectionTitle title="Availability" level={2} />
                            <Platforms
                                include={[
                                    PlatformValues.OnlyApp,
                                    PlatformValues.OnlyRegister,
                                    PlatformValues.AppAndKioskAndRegister,
                                ]}
                            />
                            <FulfillmentMethods />
                        </SectionContainer>
                    </AdvancedContainer>
                    <FormSubmit isEdit={false} submitting={isSubmitting} />
                </PromoFormContainer>
            </form>
            <ReviewModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={onSubmitConfirmed}
                state={formDataState}
                formUsage={formUsage}
            />
        </FormProvider>
    );
};

const SectionContainer = styled.div`
    margin-bottom: 48px;
`;

const TitleContainer = styled.div`
    position: relative;
`;
