import React from "react";

import { StoreDevice } from "#devices/utils/deviceTypes";

type DetailsProps = {
    device: StoreDevice | undefined;
};

export default ({ device }: DetailsProps) => (
    <div>{`Unknown Device Type: ${device?.deviceType}`}</div>
);
