import { SystemColors } from "@snackpass/design-system";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Dinero from "dinero.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";

import api from "src/api/rest";
import { Text } from "#legacy-components";
import {
    InventoryReport,
    setInventoryIsFetching,
    updateLegacyProduct,
} from "src/redux/slices";
import { useFetchInventory } from "#inventory/lib";
import { getLegacyProducts } from "src/redux/selectors";
import { sendError } from "src/utils/errors";
import { ConfirmDeleteAlert } from "#inventory/components/confirm-delete-alert";

type Props = {
    show: boolean;
    onHide: () => void;
    editItem: InventoryReport | null;
};

const Label = ({ label }: { label: string }) => (
    <p style={styles.label}>{label}</p>
);

export const EditItemForm = ({ show, onHide, editItem }: Props) => {
    const [fetchInventory] = useFetchInventory();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const products = useSelector(getLegacyProducts);

    const productsUsingItem = useMemo(() => {
        const { id = "" } = editItem?.inventoryItem || {};
        return products.filter((product) => {
            const { inventoryItems = [] } = product;
            return inventoryItems.some((item) => item.inventoryItemId === id);
        });
    }, [editItem, products]);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [deleteWarningMessage, setDeleteWarningMessage] =
        useState<string>("");

    useEffect(() => {
        // update formState
        setValue("name", editItem?.inventoryItem?.name);
        setValue("sku", editItem?.inventoryItem?.sku);
        const cents = editItem?.inventoryItem?.costPerUnit.amount || 0;
        const display = Dinero({ amount: cents }).toUnit();
        setValue("costPerUnitCents", display);
    }, [editItem?.inventoryItem.id]);

    const _onSubmit = async (data: any) => {
        try {
            if (!data.name) {
                toast.error("Item name must not be blank");
                return;
            }
            const dollars = parseFloat(data.costPerUnitCents);
            if (isNaN(dollars) || dollars < 0) {
                toast.error("Invalid price: must be a positive number");
                return;
            }
            await api.inventory.update(editItem?.inventoryItem?.id || "", {
                costPerUnitCents: Math.round(dollars * 100),
                name: data.name,
                sku: data.sku,
            });
            onHide();
            fetchInventory();
        } catch (err: any) {
            toast.error("Error submitting item.", {
                description: err?.response?.message ?? err?.message,
            });
        }
    };
    const _onDelete = async () => {
        try {
            await api.inventory.update(editItem?.inventoryItem?.id || "", {
                isHidden: true,
            });
            onHide();
            dispatch(setInventoryIsFetching(true));
            setTimeout(() => {
                fetchInventory();
            }, 500);
        } catch (err: any) {
            toast.error("Error deleting item.", {
                description: err?.response?.message ?? err?.message,
            });
        }
    };

    const _onClickDelete = async () => {
        const isActiveIngredient = productsUsingItem.length > 0;

        setDeleteWarningMessage(
            isActiveIngredient
                ? `This item is currently in use by ${productsUsingItem.length} product(s). Hiding it will remove it from all products.`
                : "This will hide this item from your inventory",
        );
        setIsDeleteModalOpen(true);
    };

    const removeIngredientFromProducts = async () => {
        try {
            const { id = "" } = editItem?.inventoryItem || {};

            for (const product of productsUsingItem) {
                const { inventoryItems = [] } = product;
                const { data } = await api.products.update(product._id, {
                    inventoryItems: inventoryItems.filter(
                        (item) => item.inventoryItemId !== id,
                    ),
                });
                dispatch(updateLegacyProduct(data.product));
            }
        } catch (error) {
            sendError(error);
            toast.error("Failed to remove ingredient from products");
        }
    };

    const onConfirmDelete = async () => {
        const isActiveIngredient = productsUsingItem.length > 0;

        isActiveIngredient && (await removeIngredientFromProducts());
        void _onDelete();
    };

    return (
        <Modal onHide={onHide} show={show}>
            <ConfirmDeleteAlert
                message={deleteWarningMessage}
                open={isDeleteModalOpen}
                close={() => setIsDeleteModalOpen(false)}
                onContinue={onConfirmDelete}
                deleteText="Hide"
            />
            <Modal.Header closeButton>
                <Modal.Title>
                    <Text bold large>
                        Edit Item
                    </Text>
                </Modal.Title>
            </Modal.Header>
            <form
                onSubmit={(e) => {
                    void handleSubmit(_onSubmit)(e);
                }}
            >
                <Modal.Body>
                    <Label label="Item Name" />
                    <input style={styles.input} {...register("name")} />
                    <br />
                    <Label label="SKU" />
                    <input style={styles.input} {...register("sku")} />
                    <br />
                    <Label label="UOM (cannot edit)" />
                    <p style={{ marginLeft: 5 }} className="mb-4">
                        {editItem?.inventoryItem?.baseUnit}
                    </p>
                    <Label label="Price" />
                    <input
                        type="text"
                        {...register("costPerUnitCents")}
                        style={styles.input}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ justifyContent: "space-between" }}>
                        <button
                            type="button"
                            className="-snackpass-btn"
                            onClick={() => {
                                void _onClickDelete();
                            }}
                            style={{
                                backgroundColor: SystemColors.v1.apricot50,
                                color: SystemColors.v1.white,
                                marginRight: 15,
                            }}
                        >
                            Hide
                        </button>
                        <button
                            className="-snackpass-btn"
                            type="submit"
                            style={{
                                backgroundColor: SystemColors.v1.candy50,
                                color: SystemColors.v1.white,
                            }}
                        >
                            Update
                        </button>
                    </div>
                    <br />
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const styles = {
    input: {
        borderRadius: 56,
        margin: 0,
        paddingVertical: 5,
    },
    label: {
        fontSize: 14,
        fontWeight: 800,
        marginBottom: 0,
        marginLeft: 5,
        marginTop: 15,
    } as CSSProperties,
};
