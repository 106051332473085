/**
 * The MenuAutomation class can be used to check the health
 * of a menu by going through the entire menu and applying a
 * series of checks to various aspects.
 *
 * You can pass it a list of checks which must implement IMenuAutomationCheck interface.
 * You can specify which items you want to test by making a class
 * with itemToCheck() { return "all-products" } etc...
 * and the checkItem(...) or checkItems(...) will be passed the relevant data depending
 * on the type to check.
 *
 * Please see the ProductNameCheck class for an example.
 *
 */
import { IProduct, IProductCategory, IStore } from "@snackpass/snackpass-types";

import { AdjustedAddon, AdjustedAddonGroup } from "./adjusted-addon";
import {
    AddonGroupNameCheck,
    AddonGroupOptionsCheck,
    AddonNameCheck,
    MenuHasDrinksCheck,
    MenuHasOneUpsellCheck,
    ProductCategoryNameCheck,
    ProductDescriptionCheck,
    ProductHoursCheck,
    ProductNameCheck,
    ProductNameRequireAddonsCheck,
    ProductUniqueModifierGroupCheck,
    ProductPriceCheck,
    RequiredGroupsHaveFreeAddonsCheck,
    TypicalUpsellsCheck,
} from "./checks";
import { DataAggregator } from "./data-aggregator";
import { IMenuAutomationCheck, MenuIssue } from "./interfaces";

//==== TYPES ====//
type MenuIssueMapping<T> = {
    [key: string]: MenuIssue<T>[];
};

//==== TYPES ====//

class MenuAutomation {
    // lists of items to check
    private _store: IStore;
    private _products: IProduct[];
    private _productCategories: IProductCategory[];
    private _addons: AdjustedAddon[];
    private _addonGroups: AdjustedAddonGroup[];

    // metrics on menu health
    private _dataAggregator: DataAggregator;

    // checks to apply
    private _checks: IMenuAutomationCheck<any>[];

    // list of general errors
    private _storeIssues: MenuIssue<IStore>[];

    // mappings for errors
    private _nameToProductCategoryMapping: MenuIssueMapping<IProductCategory>;
    private _productToCategoryMapping: MenuIssueMapping<IProduct>;
    private _productIdMapping: MenuIssueMapping<IProduct>;
    private _addonIdMapping: MenuIssueMapping<AdjustedAddon>;
    private _addonGroupIdMapping: MenuIssueMapping<AdjustedAddonGroup>;

    constructor(store: IStore, products: IProduct[]) {
        // store lists of products, addons, and addongroup
        // so it is O(n) where n is size of largest list
        // when the check method is called
        this._store = store;
        this._products = products;
        this._productCategories = [
            ...(store.productCategories ?? []),
            ...(store.catering?.productCategories ?? []),
        ];
        const { addons, addonGroups } = this._buildLists(products);
        this._addons = addons;
        this._addonGroups = addonGroups;

        // initialize variables
        this._dataAggregator = new DataAggregator();
        this._storeIssues = [];
        this._productToCategoryMapping = {};
        this._nameToProductCategoryMapping = {};
        this._productIdMapping = {};
        this._addonIdMapping = {};
        this._addonGroupIdMapping = {};

        // ADD CHECKS HERE
        this._checks = [
            new ProductNameCheck(store),
            new ProductCategoryNameCheck(store),
            new ProductPriceCheck(store),
            new AddonGroupNameCheck(store),
            new AddonGroupOptionsCheck(store),
            new AddonNameCheck(store),
            new ProductDescriptionCheck(store),
            new ProductNameRequireAddonsCheck(store),
            new ProductUniqueModifierGroupCheck(store),
            new ProductHoursCheck(store),
            new RequiredGroupsHaveFreeAddonsCheck(store),
            new MenuHasOneUpsellCheck(store),
            new MenuHasDrinksCheck(store),
            new TypicalUpsellsCheck(store),
        ];
    }

    get _storeChecks(): IMenuAutomationCheck<IStore>[] {
        return this._checks.filter((check) => check.itemToCheck === "store");
    }

    get _eachProductCategoryChecks(): IMenuAutomationCheck<IProductCategory>[] {
        return this._checks.filter(
            (check) => check.itemToCheck === "product-category",
        );
    }

    get _eachProductChecks(): IMenuAutomationCheck<IProduct>[] {
        return this._checks.filter((check) => check.itemToCheck === "product");
    }

    get _eachAddonChecks(): IMenuAutomationCheck<AdjustedAddon>[] {
        return this._checks.filter((check) => check.itemToCheck === "addon");
    }

    get _eachAddonGroupChecks(): IMenuAutomationCheck<AdjustedAddonGroup>[] {
        return this._checks.filter(
            (check) => check.itemToCheck === "addonGroup",
        );
    }

    get _allProductChecks(): IMenuAutomationCheck<IProduct>[] {
        return this._checks.filter(
            (check) => check.itemToCheck === "all-products",
        );
    }

    get _allAddonChecks(): IMenuAutomationCheck<AdjustedAddon>[] {
        return this._checks.filter(
            (check) => check.itemToCheck === "all-addons",
        );
    }

    get _allAddonGroupChecks(): IMenuAutomationCheck<AdjustedAddonGroup>[] {
        return this._checks.filter(
            (check) => check.itemToCheck === "all-addonGroups",
        );
    }

    /**
     * Data aggregator object with various
     * stats like number of issues / number of checks performed
     */
    get menuHealth(): DataAggregator {
        return this._dataAggregator;
    }

    // get general issues issues
    issuesForStore(): MenuIssue<IStore>[] {
        return this._storeIssues || [];
    }

    // get specific issues using mapping to get in O(1) time
    issuesByProductCategory(
        productCategory: string,
    ): MenuIssue<IProduct | IProductCategory>[] {
        const productCategoryErrors =
            this._nameToProductCategoryMapping[productCategory] || [];
        return [...productCategoryErrors];
    }

    issuesByProductId(productId: string): MenuIssue<IProduct>[] {
        return this._productIdMapping[productId] || [];
    }

    issuesByAddonGroupId(
        addonGroupId: string,
    ): MenuIssue<AdjustedAddonGroup>[] {
        return this._addonGroupIdMapping[addonGroupId] || [];
    }

    issuesByAddonId(addonId: string): MenuIssue<AdjustedAddon>[] {
        return this._addonIdMapping[addonId] || [];
    }

    //==== HELPERS ====//

    _addIssueToMapping = <T>(
        mapping: any,
        key: string,
        issue: MenuIssue<T> | null,
    ) => {
        if (!issue) return;
        if (!mapping[key]) {
            mapping[key] = [issue];
        } else {
            // Allow multiple issues of same type
            mapping[key].push(issue);
        }
    };

    _buildLists = (
        products: IProduct[],
    ): { addons: AdjustedAddon[]; addonGroups: AdjustedAddonGroup[] } => {
        const addonIds = new Set();
        const addons: AdjustedAddon[] = [];
        const addonGroups: AdjustedAddonGroup[] = [];
        for (const product of products) {
            for (const addonGroup of product.addonGroups) {
                addonGroups.push({
                    ...addonGroup,
                    product,
                });
                for (const addon of addonGroup.addons) {
                    // Don't push a duplicate addonId
                    // into the addons list (an addon can be in multiple addonGroups)
                    if (addonIds.has(addon._id)) {
                        continue;
                    }
                    addons.push({
                        ...addon,
                        addonGroup,
                        product,
                    });
                    addonIds.add(addon._id);
                }
            }
        }
        return { addons, addonGroups };
    };

    // check store for issues
    async _checkStore(
        aggregator: DataAggregator,
        storeIssues: MenuIssue<IStore>[],
    ) {
        for (const check of this._storeChecks) {
            const issue: MenuIssue<IStore> | null = await check.checkItem(
                this._store,
            );
            if (issue) storeIssues.push(issue);
            aggregator.addCheck();
            aggregator.addIssue(issue);
        }
    }

    async _checkEachProductCategory(
        aggregator: DataAggregator,
        productCategoryNameMapping: MenuIssueMapping<IProductCategory>,
    ) {
        // check all product categories for issues
        for (const productCategory of this._productCategories) {
            for (const check of this._eachProductCategoryChecks) {
                const issue = await check.checkItem(productCategory);
                // add to product category issues
                this._addIssueToMapping(
                    productCategoryNameMapping,
                    productCategory.name,
                    issue,
                );
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async _checkEachProduct(
        aggregator: DataAggregator,
        productCategoryMapping: MenuIssueMapping<IProduct>,
        productIdMapping: MenuIssueMapping<IProduct>,
    ) {
        // check all products for issues
        for (const product of this._products) {
            for (const check of this._eachProductChecks) {
                const issue = await check.checkItem(product);
                // add to product category issues
                this._addIssueToMapping(
                    productCategoryMapping,
                    product.category,
                    issue,
                );
                this._addIssueToMapping(productIdMapping, product._id, issue);
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async _checkEachAddonGroup(
        aggregator: DataAggregator,
        productCategoryMapping: MenuIssueMapping<IProduct>,
        productIdMapping: MenuIssueMapping<IProduct>,
        addonGroupIdMapping: MenuIssueMapping<AdjustedAddonGroup>,
    ) {
        // check all addon groups for issues
        for (const addonGroup of this._addonGroups) {
            for (const check of this._eachAddonGroupChecks) {
                const issue = await check.checkItem(addonGroup);
                this._addIssueToMapping(
                    productCategoryMapping,
                    addonGroup.product.category,
                    issue,
                );
                this._addIssueToMapping(
                    productIdMapping,
                    addonGroup.product._id,
                    issue,
                );
                this._addIssueToMapping(
                    addonGroupIdMapping,
                    addonGroup._id,
                    issue,
                );
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async _checkEachAddon(
        aggregator: DataAggregator,
        productCategoryMapping: MenuIssueMapping<IProduct>,
        productIdMapping: MenuIssueMapping<IProduct>,
        addonGroupIdMapping: MenuIssueMapping<AdjustedAddonGroup>,
        addonIdMapping: MenuIssueMapping<AdjustedAddon>,
    ) {
        for (const addon of this._addons) {
            for (const check of this._eachAddonChecks) {
                const issue = await check.checkItem(addon);
                this._addIssueToMapping(
                    productCategoryMapping,
                    addon.product.category,
                    issue,
                );
                this._addIssueToMapping(
                    productIdMapping,
                    addon.product._id,
                    issue,
                );
                this._addIssueToMapping(
                    addonGroupIdMapping,
                    addon.addonGroup._id,
                    issue,
                );
                this._addIssueToMapping(addonIdMapping, addon._id, issue);
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async _checkAllProducts(
        aggregator: DataAggregator,
        products: IProduct[],
        productCategoryMapping: MenuIssueMapping<IProduct>,
        productIdMapping: MenuIssueMapping<IProduct>,
    ) {
        for (const check of this._allProductChecks) {
            const issues = await check.checkItems(products);
            for (const issue of issues) {
                const item: IProduct = issue.item as IProduct;
                this._addIssueToMapping(
                    productCategoryMapping,
                    item.category,
                    issue,
                );
                this._addIssueToMapping(productIdMapping, item._id, issue);
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async _checkAllAddons(
        aggregator: DataAggregator,
        addons: AdjustedAddon[],
        productCategoryMapping: MenuIssueMapping<IProduct>,
        productIdMapping: MenuIssueMapping<IProduct>,
        addonGroupIdMapping: MenuIssueMapping<AdjustedAddonGroup>,
        addonIdMapping: MenuIssueMapping<AdjustedAddon>,
    ) {
        for (const check of this._allAddonChecks) {
            const issues = await check.checkItems(addons);
            for (const issue of issues) {
                const item: AdjustedAddon = issue.item as AdjustedAddon;
                this._addIssueToMapping(
                    productCategoryMapping,
                    item.product.category,
                    issue,
                );
                this._addIssueToMapping(
                    productIdMapping,
                    item.product._id,
                    issue,
                );
                this._addIssueToMapping(
                    addonGroupIdMapping,
                    item.addonGroup._id,
                    issue,
                );
                this._addIssueToMapping(addonIdMapping, item._id, issue);
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async _checkAllAddonGroups(
        aggregator: DataAggregator,
        addonGroups: AdjustedAddonGroup[],
        productCategoryMapping: MenuIssueMapping<IProduct>,
        productIdMapping: MenuIssueMapping<IProduct>,
        addonGroupIdMapping: MenuIssueMapping<AdjustedAddonGroup>,
    ) {
        for (const check of this._allAddonGroupChecks) {
            const issues = await check.checkItems(addonGroups);
            for (const issue of issues) {
                const item: AdjustedAddonGroup =
                    issue.item as AdjustedAddonGroup;
                this._addIssueToMapping(
                    productCategoryMapping,
                    item.product.category,
                    issue,
                );
                this._addIssueToMapping(
                    productIdMapping,
                    item.product._id,
                    issue,
                );
                this._addIssueToMapping(addonGroupIdMapping, item._id, issue);
                aggregator.addCheck();
                aggregator.addIssue(issue);
            }
        }
    }

    async checkForIssues() {
        // lists of issues
        const storeIssues: MenuIssue<IStore>[] = [];

        // mappings for issues
        const categoryToProductMapping: MenuIssueMapping<IProduct> = {};
        const productCategoryNameMapping: MenuIssueMapping<IProductCategory> =
            {};
        const productIdMapping: MenuIssueMapping<IProduct> = {};
        const addonIdMapping: MenuIssueMapping<AdjustedAddon> = {};
        const addonGroupIdMapping: MenuIssueMapping<AdjustedAddonGroup> = {};
        // to store info on types of issues
        const aggregator = new DataAggregator();

        // check for issues based on checks passed to
        // this class
        await this._checkStore(aggregator, storeIssues);

        await this._checkEachProductCategory(
            aggregator,
            productCategoryNameMapping,
        );

        await this._checkEachProduct(
            aggregator,
            categoryToProductMapping,
            productIdMapping,
        );

        await this._checkEachAddonGroup(
            aggregator,
            categoryToProductMapping,
            productIdMapping,
            addonGroupIdMapping,
        );

        await this._checkEachAddon(
            aggregator,
            categoryToProductMapping,
            productIdMapping,
            addonGroupIdMapping,
            addonIdMapping,
        );

        await this._checkAllProducts(
            aggregator,
            this._products,
            categoryToProductMapping,
            productIdMapping,
        );

        await this._checkAllAddonGroups(
            aggregator,
            this._addonGroups,
            categoryToProductMapping,
            productIdMapping,
            addonGroupIdMapping,
        );

        await this._checkAllAddons(
            aggregator,
            this._addons,
            categoryToProductMapping,
            productIdMapping,
            addonGroupIdMapping,
            addonIdMapping,
        );

        // update class fields
        this._storeIssues = storeIssues;
        // update mappings
        this._productToCategoryMapping = categoryToProductMapping;
        this._nameToProductCategoryMapping = productCategoryNameMapping;
        this._productIdMapping = productIdMapping;
        this._addonIdMapping = addonIdMapping;
        this._addonGroupIdMapping = addonGroupIdMapping;
        // update data aggregator
        this._dataAggregator = aggregator;
    }
}

export default MenuAutomation;
