import { Color } from "@tremor/react";

import colors from "#reusable/colors/colors.json";

export const BORDER_COLORS = [
    { hexColor: colors["success-light"], color: "bg-success-light" },
    { hexColor: colors["warning-light"], color: "bg-warning-light" },
    { hexColor: colors["purple-medium"], color: "bg-purple-medium" },
    {
        hexColor: colors["orange-light-medium"],
        color: "bg-orange-light-medium",
    },
    { hexColor: colors["teal-muted"], color: "bg-teal-muted" },
    { hexColor: colors["pink-medium-muted"], color: "bg-pink-medium-muted" },
    { hexColor: colors["green-neon"], color: "bg-green-neon" },
    { hexColor: colors["blue-medium-muted"], color: "bg-blue-medium-muted" },
    { hexColor: colors["orange-neon"], color: "bg-orange-neon" },
    { hexColor: colors["tan-muted"], color: "bg-tan-muted" },
    { hexColor: colors["green-dark-muted"], color: "bg-green-dark-muted" },
    { hexColor: colors["blue-light-muted"], color: "bg-blue-light-muted" },
    { hexColor: colors["brown-dark-muted"], color: "bg-brown-dark-muted" },
    { hexColor: colors["purple-light-muted"], color: "bg-purple-light-muted" },
    { hexColor: colors["brown-medium-muted"], color: "bg-brown-medium-muted" },
    { hexColor: colors["teal-neon"], color: "bg-teal-neon" },
    { hexColor: colors["pink-dark-muted"], color: "bg-pink-dark-muted" },
    { hexColor: colors["green-light-neon"], color: "bg-green-light-neon" },
    { hexColor: colors["blue-dark-muted"], color: "bg-blue-dark-muted" },
    { hexColor: colors["critical-light"], color: "bg-critical-light" },
    { hexColor: colors["brown-light-muted"], color: "bg-brown-light-muted" },
    { hexColor: colors["green-light-muted"], color: "bg-green-light-muted" },
    {
        hexColor: colors["blue-dark-dark-muted"],
        color: "bg-blue-dark-dark-muted",
    },
    { hexColor: colors["yellow-light-muted"], color: "bg-yellow-light-muted" },
    { hexColor: colors["purple-dark-muted"], color: "bg-purple-dark-muted" },
    { hexColor: colors["orange-light-muted"], color: "bg-orange-light-muted" },
    { hexColor: colors["green-medium-muted"], color: "bg-green-medium-muted" },
    { hexColor: colors["pink-light-muted"], color: "bg-pink-light-muted" },
    {
        hexColor: colors["green-medium-dark-muted"],
        color: "bg-green-medium-dark-muted",
    },
    {
        hexColor: colors["purple-medium-light-muted"],
        color: "bg-purple-medium-light-muted",
    },
    { hexColor: colors["red-dark-muted"], color: "bg-red-dark-muted" },
    { hexColor: colors["tan-light-muted"], color: "bg-tan-light-muted" },
    { hexColor: colors["red-medium-muted"], color: "bg-red-medium-muted" },
];

export const CHART_COLORS: { color: Color; className: string }[] = [
    { color: "sky", className: "bg-sky-500" },
    { color: "neutral", className: "bg-neutral-500" },
    { color: "red", className: "bg-red-500" },
    { color: "orange", className: "bg-orange-500" },
    { color: "purple", className: "bg-purple-500" },
    { color: "amber", className: "bg-amber-500" },
    { color: "rose", className: "bg-rose-500" },
    { color: "blue", className: "bg-blue-500" },
    { color: "yellow", className: "bg-yellow-500" },
    { color: "lime", className: "bg-lime-500" },
    { color: "fuchsia", className: "bg-fuchsia-500" },
    { color: "green", className: "bg-green-500" },
    { color: "emerald", className: "bg-emerald-500" },
    { color: "teal", className: "bg-teal-500" },
    { color: "cyan", className: "bg-cyan-500" },
    { color: "indigo", className: "bg-indigo-500" },
    { color: "violet", className: "bg-violet-500" },
    { color: "pink", className: "bg-pink-500" },
];

// Returns colors from the CHART_COLORS list in order, returning to the start of the list if the amount of stores exceed the amount of colors.
export const loopColorsToLength = (length: number): Color[] => {
    const colors: Color[] = [];
    for (let i = 0; i < length; i++) {
        colors.push(CHART_COLORS[i % CHART_COLORS.length].color);
    }
    return colors;
};
