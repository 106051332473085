import { PropsWithChildren, useEffect, useRef } from "react";
import { Canvas } from "fabric";

import { withCanvasDefaults } from "#table-editor/canvas/withCanvasDefaults";

import { useEditorStore } from "./EditorProvider";

type EditorCanvasProps = PropsWithChildren<{
    grid: {
        size?: number;
        snap?: { gridSize?: number; fraction?: number };
    };
}>;

export function EditorCanvas({ grid, children }: EditorCanvasProps) {
    const editor = useEditorStore();
    const canvasEl = useRef<HTMLCanvasElement | null>(null);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (canvasEl.current == null && ref.current) {
            // Create a canvas element manually to allow width and height calculation
            // before canvas is rendered
            canvasEl.current = document.createElement("canvas");
            const rect = ref.current.getBoundingClientRect();

            const width = rect.width;
            const height = window.innerHeight - rect.top;

            canvasEl.current.width = width;
            canvasEl.current.height = height;

            ref.current.prepend(canvasEl.current);

            const canvas = new Canvas(canvasEl.current, {
                width,
                height,
            });

            const unsubscribe = withCanvasDefaults(canvas, editor.getState, {
                snap: grid.snap,
                size: { width, height },
                gridSize: grid.size,
            });

            editor.getState().setCanvas(canvas);

            return () => {
                unsubscribe();
                void canvas.dispose();
                editor.getState().setCanvas(null);
            };
        }

        // Adding all deps will reinitialize canvas every save
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={ref} className="relative">
            {children}
        </div>
    );
}
