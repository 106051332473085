import { zodResolver } from "@hookform/resolvers/zod";
import { IStore } from "@snackpass/snackpass-types";
import { FormProvider, useForm } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Header } from "#settings/settings-fees/components/header";
import { MiscellaneousFormSchema } from "#settings/settings-fees/utils/schema";
import { MiscellaneousFormValues } from "#settings/settings-fees/utils/types";
import { FeeSwitch } from "#settings/settings-fees/components/fee-switch";
import { FeeInput } from "#settings/settings-fees/components/fee-input";
import { useUpdateMiscellaneous } from "#settings/settings-fees/utils/hooks/useUpdateMiscellaneous";
import { miscellaneousFormDefaults } from "#settings/settings-fees/forms/miscellaneous/miscellaneous-defaults";
import { MiscellaneousFooter } from "#settings/settings-fees/forms/miscellaneous/miscellaneous-footer";

export const MiscellaneousForm = ({ store }: { store: IStore }) => {
    const form = useForm<MiscellaneousFormValues>({
        defaultValues: miscellaneousFormDefaults(store),
        resolver: zodResolver(MiscellaneousFormSchema),
        mode: "all",
    });

    const updateStore = useUpdateMiscellaneous({
        storeId: store._id,
        reset: form.reset,
    });

    return (
        <>
            <form
                onSubmit={form.handleSubmit(async (values) =>
                    updateStore(values),
                )}
                className="relative h-full w-full overflow-hidden"
            >
                <FormProvider {...form}>
                    <Header
                        title="Miscellaneous Fees"
                        titleSubtext="Fee Policies"
                    />
                    <div className="my-2 flex w-full flex-col gap-4 sm:flex-row">
                        <FormField
                            control={form.control}
                            name="flat"
                            render={({ field }) => (
                                <FormItem className="mr-4 flex w-[200px] flex-col">
                                    <FormLabel>Bag Fee</FormLabel>
                                    <FormControl>
                                        <FeeInput
                                            value={field.value}
                                            prefix="$"
                                            step={0.01}
                                            placeholder="0.0"
                                            min={0}
                                            max={1}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseFloat(e.target.value),
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="isTaxable"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Taxable</FormLabel>
                                    <FormControl>
                                        <FeeSwitch
                                            checked={field.value}
                                            onChange={field.onChange}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>
                    <MiscellaneousFooter />
                </FormProvider>
            </form>
        </>
    );
};
