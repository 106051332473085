import { GraphQLClient } from "graphql-request";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";

import config from "#config";
import { firebaseAuth } from "#app/firebase";

const GQL = new GraphQLClient(config.gqlUrl);

onIdTokenChanged(firebaseAuth, async (user) => {
    const token = await user?.getIdToken();
    GQL.setHeaders({
        authorization: token ? `Bearer ${token}` : "",
    });
});

onAuthStateChanged(firebaseAuth, async (user) => {
    const token = await user?.getIdToken();
    GQL.setHeaders({
        authorization: token ? `Bearer ${token}` : "",
    });
});

export { GQL };
