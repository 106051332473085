import { IHoursSchema, ITimeRangeSchema } from "@snackpass/snackpass-types";
import _ from "lodash";
import moment from "moment";
import type { Moment } from "moment";
// import { TIME_RANGE_DAYS } from "@snackpass/accounting"; // not sure why I can't import this from accounting

// this is from snackpass accounting
const TIME_RANGE_DAYS = [
    60 * 24 * 0, // 1 -1439
    60 * 24 * 1, // 1440-2879  2
    60 * 24 * 2, //2880-4319  3
    60 * 24 * 3, //4320-5759 4
    60 * 24 * 4, //5760-7199  5
    60 * 24 * 5, //7200-8639  6
    60 * 24 * 6, //8640 7
];

export interface TimeRangeSchema {
    start: Moment | null | undefined;
    end: Moment | null | undefined;
}

export interface SpecialHoursItemType {
    id: number;
    dayOfWeek: number;
    time: TimeRangeSchema;
}

export const format12 = "hh:mm a";

export const defaultStartTime = moment("12:00 am", format12);
export const defaultEndTime = moment("11:59 pm", format12);

export const getDayRange = (time: ITimeRangeSchema) => {
    const startDay = formatDayOfWeek(time.start);
    const endDay = formatDayOfWeek(time.end) + 1;
    return startDay === endDay ? [startDay] : _.range(startDay, endDay);
};

export const hoursHasCrossedDayRanges = (time: ITimeRangeSchema) =>
    _.flatten(getDayRange(time)).length > 1;

/** Snackface handle store hours differently, each time range can be cross different days
 * e.g. Monday 9AM - Tuesday 1AM, in this case we will need to parse them based on
 * day of week, e.g. Monday 9AM-11:59PM & Tuesday 12AM-1AM
 **/
export const parseCrossDayRanges = (time: ITimeRangeSchema) => {
    const dayRanges = getDayRange(time);
    const parsedTime: ITimeRangeSchema[] = [];
    let start = time.start;
    const end = time.end;
    dayRanges.forEach((day) => {
        if (day < 7 && end >= TIME_RANGE_DAYS[day + 1]) {
            parsedTime.push({
                start: start,
                end: TIME_RANGE_DAYS[day + 1] - 1,
            });
            start = TIME_RANGE_DAYS[day + 1];
        } else {
            parsedTime.push({ start: start, end: end });
        }
    });
    return parsedTime;
};

export const formatDayOfWeek = (minutes: number): number =>
    moment.duration(minutes, "minutes").days();

export const formatTime = (minutes: number) => {
    const format = "hh:mm a";
    const time = moment().startOf("day").minutes(minutes).format(format);
    return moment(time, format);
};

export const getStoreOpenDays = (storeHours: IHoursSchema) => {
    if (!storeHours.local) {
        return [];
    } else {
        const openDays = _.flatten(
            _.uniq(storeHours.local.map((time) => getDayRange(time))),
        );
        return openDays;
    }
};

/** Here we are formatting a time string (e.g. "12:00 am") along
 ** with day of week(e.g. 0-6 representing Mon-Sun) to a representations
 ** of a time range as minutes from the beginning of the week and not a Unix time.
 **/
export const convertTimeStringToNumberFormat = (
    dayOfWeek: number,
    time: Moment,
) => {
    const day = dayOfWeek;
    const formattedTime =
        day * 24 * 60 + moment.duration(time.format("HH:mm")).asMinutes();
    return isNaN(formattedTime) ? 0 : formattedTime;
};

export const formatSpecialHours = (specialHours: SpecialHoursItemType[]) => {
    const newHours: ITimeRangeSchema[] = [];

    if (
        specialHours.length === 1 &&
        _.findIndex(specialHours, (item) => item.dayOfWeek === 7) >= 0 &&
        specialHours[0].time.start &&
        specialHours[0].time.end
    ) {
        // if everyday in the option, format everyday start and end time to the same
        const startTime = specialHours[0].time.start;
        const endTime = specialHours[0].time.end;
        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
            const formattedHours = {
                start: convertTimeStringToNumberFormat(dayOfWeek, startTime),
                end: convertTimeStringToNumberFormat(dayOfWeek, endTime),
            };
            newHours.push(formattedHours);
        }
    } else {
        specialHours &&
            specialHours.map((item) => {
                if (item.time.start && item.time.end) {
                    const formattedHours = {
                        start: convertTimeStringToNumberFormat(
                            item.dayOfWeek,
                            item.time.start,
                        ),
                        end: convertTimeStringToNumberFormat(
                            item.dayOfWeek,
                            item.time.end,
                        ),
                    };
                    newHours.push(formattedHours);
                }
            });
    }

    return newHours;
};

export const isEverydaySpecialHoursIsSame = (specialHours: IHoursSchema) => {
    if (_.some(specialHours.local, (item) => hoursHasCrossedDayRanges(item))) {
        return false;
    }
    // check if special hours has everyday and if the start and end time is the same
    if (specialHours.local.length !== 7) {
        return false;
    } else {
        let isSameTime = true;
        // set first element as reference
        const startTime = formatTime(specialHours.local[0].start);
        const endTime = formatTime(specialHours.local[0].end);
        const hours = specialHours.local;
        for (let i = 0; i < hours.length; i++) {
            if (
                !formatTime(hours[i].start).isSame(startTime) ||
                !formatTime(hours[i].end).isSame(endTime)
            ) {
                isSameTime = false;
                break;
            }
        }
        return isSameTime;
    }
};

export const DAY_OPTIONS = [
    {
        label: "Monday",
        value: 0,
    },
    {
        label: "Tuesday",
        value: 1,
    },
    {
        label: "Wednesday",
        value: 2,
    },
    {
        label: "Thursday",
        value: 3,
    },
    {
        label: "Friday",
        value: 4,
    },
    {
        label: "Saturday",
        value: 5,
    },
    {
        label: "Sunday",
        value: 6,
    },
    {
        label: "Everyday",
        value: 7,
    },
];
