import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

export const useCancelInvite = () => {
    const store = useSelector(getActiveStore);

    const { isPending, mutate, isSuccess, error } = useMutation({
        mutationFn: async (inviteId?: string) => {
            if (!store || !inviteId) return;

            return api.adminUserInvite.cancelInvite(store._id, inviteId);
        },
    });
    return {
        isLoading: isPending,
        mutate,
        isSuccess,
        error,
    };
};
