import React from "react";

export const SelectPlaceholder = (props: { name: string; imgUrl?: string }) => {
    const { name, imgUrl } = props;
    return (
        <div className="whitespace-nowrap font-bold text-neutral-950">
            <img src={imgUrl} className="-ml-2 h-8 w-8 rounded-full" /> {name}
        </div>
    );
};
