type DiscountType = "percent";

type ProductDiscount = {
    id: string;
    label: string;
    imageUrl: string | null;
};

export type DiscountTemplate = {
    expiresInDays: number;
    label: string;
    discount: {
        type: DiscountType;
        percentOff: number | null;
        dollarsOff: number | null;
        newPrice: number | null;
    };
    storewide: boolean;
    products: ProductDiscount[];
    subtext: string;
};

export const DISCOUNTS: DiscountTemplate[] = [
    {
        discount: {
            percentOff: 25,
            dollarsOff: null,
            newPrice: null,
            type: "percent",
        },
        expiresInDays: 7,
        label: "25% off 1 Item",
        subtext: "25% off",
        storewide: true,
        products: [],
    },
    {
        discount: {
            percentOff: 50,
            dollarsOff: null,
            newPrice: null,
            type: "percent",
        },
        expiresInDays: 7,
        label: "50% off 1 Item",
        subtext: "50% off",
        storewide: true,
        products: [],
    },
    {
        discount: {
            percentOff: 100,
            dollarsOff: null,
            newPrice: null,
            type: "percent",
        },
        expiresInDays: 7,
        label: "Free Item",
        subtext: "FREE",
        storewide: true,
        products: [],
    },
];
