import React, { MouseEventHandler } from "react";

import { Button } from "src/@/components/ui/button";

type Props = {
    children: React.ReactNode;
    href: string;
    target?: "_blank";
    onClick?: MouseEventHandler;
};

function ExternalButtonLink({ children, href, target, onClick }: Props) {
    return (
        <Button asChild>
            <a
                className="no-underline"
                href={href}
                target={target}
                onClick={onClick}
            >
                {children}
            </a>
        </Button>
    );
}

export default ExternalButtonLink;
