import React from "react";

import {
    PinnedProducts,
    SlideIntervalOption,
    HideSoldOut,
    HideDescription,
    EditFont,
} from "#devices/components/EditDrawer/Settings";

export const FullScreenGallery = () => (
    <>
        <PinnedProducts />
        <HideDescription />
        <HideSoldOut />
        <SlideIntervalOption />
        <EditFont />
    </>
);
