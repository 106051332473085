import { IAddress, ITimeRangeSchema } from "@snackpass/snackpass-types";
import { z } from "zod";

import {
    AddressSchema,
    CitySchema,
    CompanyLogoUrlSchema,
    CompanyNameSchema,
    CompanySlugSchema,
    CompanyTaxIdSchema,
    CountrySchema,
    EmailSchema,
    HoursSchema,
    Line1Schema,
    Line2Schema,
    PhoneSchema,
    PostalCodeSchema,
    StateSchema,
    KindSchema,
    TaxRateSchema,
    TimezoneSchema,
    UrlSchema,
    ValidNameRegex,
    urlParse,
} from "#onboarding/utils/validation/schema";

/**
 * Shared
 */
export const documentValidation = (value?: string) => {
    const { success } = z.string().safeParse(value);
    return success ? [] : ["Please provide a valid logo"];
};

export const url = (value = "", disabled = false) => {
    if (disabled) return [];
    const schemaSuccess = UrlSchema.safeParse(value).success;
    const validSuccess = urlParse(value);
    const success = schemaSuccess && validSuccess;
    return success ? [] : ["Please provide a valid website"];
};

export const phoneValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = PhoneSchema.safeParse(value);
    return success ? [] : ["Please provide a valid phone number"];
};

export const hoursValidation = (
    value?: ITimeRangeSchema[],
    disabled = false,
) => {
    if (disabled) return [];
    const { success } = HoursSchema.safeParse(value);
    return success ? [] : ["Please a valid set of hours"];
};

export const addressValidation = (
    value?: Partial<IAddress>,
    disabled = false,
) => {
    if (disabled) return [];
    const { success } = AddressSchema.safeParse(value);
    return success ? [] : ["Invalid address"];
};

export const line1Validation = (
    isAddressValid?: boolean,
    value?: string,
    disabled = false,
) => {
    if (disabled) return [];
    const { success } = Line1Schema.safeParse(value);
    return success && isAddressValid
        ? []
        : ["Please provide a valid street address"];
};

export const line2Validation = (value?: string | null, disabled = false) => {
    if (disabled) return [];
    const { success } = Line2Schema.safeParse(value);
    return success ? [] : ["Please provide a valid street address"];
};

export const cityValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CitySchema.safeParse(value);
    return success ? [] : ["Please provide a valid city"];
};

export const stateValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = StateSchema.safeParse(value);
    return success ? [] : ["Required"];
};

export const zipValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = PostalCodeSchema.safeParse(value);
    return success ? [] : ["Invalid code"];
};

export const countryValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CountrySchema.safeParse(value);
    return success ? [] : ["Invalid country"];
};

export const taxRateValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = TaxRateSchema.safeParse(parseFloat(value ?? "-1"));
    return success ? [] : ["Please provide a valid tax rate (0 - 20%)"];
};

/**
 * Company Validation
 */
export const companyNameValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyNameSchema.safeParse(value);
    return success ? [] : ["Please provide a valid company name"];
};

export const companySlugValidation = (
    value?: string,
    checking = false,
    available = false,
    disabled = false,
) => {
    const { success } = CompanySlugSchema.safeParse(value);
    if (!success) return ["Please provide a valid store username"];
    if (!value || disabled || checking) return [];
    if (!available) return ["That username is already in use"];
    return [];
};

export const companyLogoValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyLogoUrlSchema.safeParse(value);
    return success ? [] : ["Please provide a valid logo image"];
};

export const companyTaxIdValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyTaxIdSchema.safeParse(value);
    return success ? [] : ["Please provide a valid tax ID"];
};

/**
 * Representative
 */
export const firstNameValidation = (value?: string) => {
    const length = z.string().min(1).safeParse(value);
    if (!length.success) {
        return ["Please provide a valid first name"];
    }
    const characters = z.string().regex(ValidNameRegex).safeParse(value);
    if (!characters.success) {
        return ["Must contain valid characters"];
    }
    return [];
};

export const lastNameValidation = (value?: string) => {
    const length = z.string().min(1).safeParse(value);
    if (!length.success) {
        return ["Please provide a valid last name"];
    }
    const characters = z.string().regex(ValidNameRegex).safeParse(value);
    if (!characters.success) {
        return ["Must contain valid characters"];
    }
    return [];
};

export const emailValidation = (value?: string) => {
    const { success } = EmailSchema.safeParse(value);
    return success ? [] : ["Please provide a valid email"];
};

export const timezoneValidation = (value?: string) => {
    const { success } = TimezoneSchema.safeParse(value);
    return success ? [] : ["Please select a valid timezone"];
};

export const kindValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = KindSchema.safeParse(value);
    return success ? [] : ["Please provide a valid store kind"];
};
