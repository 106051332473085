import { PayoutPolicy } from "@snackpass/snackpass-types";

type PayoutPolicyType = PayoutPolicy["__t"];

export const readablePayoutPolicyType = (
    type: PayoutPolicyType | undefined,
): string => {
    switch (type) {
        case "WeeklyPayoutPolicy":
            return "Weekly";
        case "DailyPayoutPolicy":
            return "Daily";
        case "ExpeditedDailyPayoutPolicy":
            return "Expedited";
        default:
            return "None";
    }
};
