import { useMemo, useState } from "react";
import { FabricObject } from "fabric";

import { useCanvasEffect } from "#table-editor/components/Editor/hooks/useCanvasEffect";
import { LayoutProperty } from "#table-editor/types";
import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { Element } from "#table-editor/canvas/Element";

type ElementSelection = {
    id: string;
    properties: LayoutProperty;
    x: number;
    y: number;
    width: number;
    height: number;
};

export function useModifyElementPosition() {
    const [selectedElement, setSelectedElement] =
        useState<ElementSelection | null>(null);

    const editor = useEditorStore();

    useCanvasEffect((canvas) => {
        const selectElement = (obj: FabricObject) => {
            if (obj.isType(Element.type)) {
                const element = obj as Element;

                const selectedElement = editor
                    .getState()
                    .elements.find((el) => el.id === element.id);

                if (selectedElement) {
                    setSelectedElement({
                        id: element.id,
                        properties: selectedElement.properties,
                        x: element.left,
                        y: element.top,
                        width: element.getScaledWidth(),
                        height: element.getScaledHeight(),
                    });
                }
            }
        };

        const created = canvas.on("selection:created", (e) => {
            if (canvas.getActiveObjects()?.length === 1) {
                selectElement(e.selected[0]);
            } else {
                setSelectedElement(null);
            }
        });

        const updated = canvas.on("selection:updated", (e) => {
            if (canvas.getActiveObjects()?.length === 1) {
                selectElement(e.selected[0]);
            } else {
                setSelectedElement(null);
            }
        });

        const cleared = canvas.on("selection:cleared", (e) => {
            if (canvas.getActiveObjects()?.length === 0) {
                setSelectedElement(null);
            }
        });

        const resize = canvas.on("object:scaling", (e) => {
            if (canvas.getActiveObjects()?.length === 1) {
                const activeObject = canvas.getActiveObject();

                if (activeObject && e.target === activeObject) {
                    selectElement(activeObject);
                }
            }
        });

        const moving = canvas.on("object:moving", (e) => {
            setSelectedElement(null);
        });

        const mouseUp = canvas.on("mouse:up", (e) => {
            if (canvas.getActiveObjects()?.length === 1) {
                const activeObject = canvas.getActiveObject();

                if (activeObject && e.target === activeObject) {
                    selectElement(activeObject);
                }
            }
        });

        return () => {
            created();
            updated();
            cleared();
            resize();
            moving();
            mouseUp();
        };
    }, []);

    return useMemo(() => selectedElement, [selectedElement]);
}
