import { useCallback, useMemo } from "react";
import { toast } from "sonner";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { MenuForm } from "#menu-manager/components";
import {
    formValuesToInputType,
    storeMenuToFormValues,
} from "#menu-manager/components/menu-form/lib";
import { MenuFormValues } from "#menu-manager/components/menu-form/schema";
import { useUpdateMenu, useMenus } from "#menu-manager/hooks";
import { MenuManagerRoutes } from "#menu-manager/routes";
import { Skeleton } from "src/@/components/ui/skeleton";

type EditMenuParams = {
    id: string;
};

export function EditMenuScreen() {
    const { data, loading } = useMenus();
    const { updateMenu, loading: updating } = useUpdateMenu();
    const history = useHistory();
    const { id } = useParams<EditMenuParams>();

    const onSubmit = useCallback(
        async (values: MenuFormValues) =>
            updateMenu({
                input: formValuesToInputType(values),
                id,
            })
                .then((result) => {
                    toast.success(
                        `Successfully updated ${result.data?.updateStoreMenu.name}`,
                    );
                    history.push(MenuManagerRoutes.INDEX);
                })
                .catch((e) =>
                    toast.error("Error updating menu", {
                        description: e?.message,
                    }),
                ),
        [updateMenu, id, history],
    );

    const formValues = useMemo(() => {
        const navigatedMenu = data?.find((e) => e.id === id);
        return navigatedMenu ? storeMenuToFormValues(navigatedMenu) : null;
    }, [data, id]);

    if (loading) return <Skeleton className="h-96 w-full" />;
    if (!formValues) return <Redirect to={MenuManagerRoutes.INDEX} />;

    return (
        <MenuForm
            onSubmit={onSubmit}
            headerText="Edit Menu"
            submitting={updating}
            additionalActionButtons={undefined}
            defaultValues={formValues}
        />
    );
}
