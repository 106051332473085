import { IProductCategory, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class ProductCategoryNameCheck
    implements IMenuAutomationCheck<IProductCategory>
{
    public store: IStore;
    public productCategoryNames: Set<string>;

    constructor(store: IStore) {
        this.store = store;
        const productCategoryNames = store.productCategories
            ? store.productCategories.map((category) => category.name)
            : [];
        this.productCategoryNames = new Set(productCategoryNames);
    }

    get name() {
        return "ProductCategoryNameCheck";
    }

    get itemToCheck(): ItemType {
        return "product-category";
    }

    checkItems() {
        return [];
    }

    checkItem(item: IProductCategory): MenuIssue<IProductCategory> | null {
        const pattern = new RegExp(/\(Catering\)$/);
        const hasCateringName = pattern.test(item.name);
        const isNonCateringCategory = this.productCategoryNames.has(item.name);
        if (isNonCateringCategory) {
            // Non-catering product category
            if (hasCateringName) {
                return new MenuIssue(
                    "InvalidProductCategoryName",
                    "Non-catering product category name cannot end with '(Catering)'",
                    "critical",
                    item,
                );
            }

            return null;
        } else {
            // Catering product category
            if (!hasCateringName) {
                return new MenuIssue(
                    "InvalidProductCategoryName",
                    "Catering product category name must end with '(Catering)'",
                    "critical",
                    item,
                );
            }

            return null;
        }
    }
}

export { ProductCategoryNameCheck };
