import {
    IStore,
    ITimeRangeSchema,
    StoreKind,
} from "@snackpass/snackpass-types";
import { Geolocation, IAddress } from "@snackpass/snackpass-types/src/v1";

export type RegularHours = {
    weekday: number;
    range: ITimeRangeSchema;
};

export type SpecialHours = {
    date: Date;
    isClosed: boolean;
    range: ITimeRangeSchema;
};

export type BusinessInfoValues = {
    name: string;
    initialStoreName: string;
    address: string | null | undefined;
    phoneNumber: string | null | undefined;
    email: string[] | null;
    local: RegularHours[];
    special: SpecialHours[];
    addressComponents: IAddress | null | undefined;
    isAddressValid: boolean;
    timezone: string;
    geolocation: Geolocation | null;
    stores: IStore[];
    kind: StoreKind | null | undefined;
};

export type PartialTestSchema<T> = {
    [P in keyof T]: T[P] extends (infer U)[]
        ? PartialTestSchema<U>[] | undefined
        : T[P] extends object
          ? PartialTestSchema<T[P]>
          : T[P] | undefined;
};

export enum FormFieldEnum {
    name = "name",
    phoneNumber = "phoneNumber",
    local = "local",
    special = "special",
    email = "email",
    addressComponents = "addressComponents",
    isAddressValid = "isAddressValid",
    address = "address",
    timezone = "timezone",
    geolocation = "geolocation",
    kind = "kind",
}
