/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    AddonGroup,
    FulfillmentTypeEnum,
    IHoursSchema,
    InventoryUnitType,
    IProduct,
    ITimeRangeSchema,
    ProductIngredientT,
    ScreenState,
    TaxPolicy,
    TaxPolicyEventType,
    WeightUnitTypes,
} from "@snackpass/snackpass-types";
import _, { differenceWith, isEqual } from "lodash";
import { compose, map, sortBy } from "lodash/fp";
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Spin,
} from "antd";
import styled from "styled-components";
import { PartialDeep } from "type-fest";
import { SystemColors } from "@snackpass/design-system";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";

import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import { ReactComponent as Duplicate } from "src/assets/icons/duplicate.svg";
import {
    removeLegacyProduct,
    updateLegacyProduct,
    setActiveStore,
    InventoryItem,
} from "src/redux/slices";
import { DropdownOption } from "#core";

import "antd/dist/antd.css";

import CheckboxButton from "#reusable/buttons/default-button";
import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import api from "src/api/rest";
import {
    getActiveStore,
    getActiveStoreIs3PIntegrated,
    getInventoryItems,
    getLegacyProducts,
    getUser,
    selectActiveMenu,
} from "src/redux/selectors";
import Switch from "#reusable/input/toggle-input";
import DropDownSelect, { Options } from "#reusable/select/dropdown";
import { SpecialHourRows } from "#menu-editor/mobile-friendly/items/special-hours-rows";
import constants from "#core/constants";
import { getStoreTimezone } from "#utils/helpers";
import Ingredients from "#menu-editor/mobile-friendly/items/ingredient-select";
import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import { isServerGeneratedId } from "#menu-editor/mobile-friendly/helpers/utils";
import {
    newAddonGroupIds,
    newAddonIds,
    isCategoryNameValid,
    isCategoryNameTaken,
    CopiedAddonGroup,
} from "#menu-editor/mobile-friendly/helpers/menu-helpers";
import { useAppDispatch } from "src/redux/hooks";
import {
    useChannelVisibilitySettings,
    useMultiMenusEnabled,
} from "#navigation/utils";
import { useDeletedAddons } from "#menu-editor/mobile-friendly/hooks/use-deleted-addons";
import { sendError } from "src/utils/errors";
import { Text } from "#reusable/text";
import colors from "#reusable/colors/colors.json";
import DeleteProductModal from "#menu-editor/mobile-friendly/items/delete-product-modal";
import ProductImage from "#menu-editor/mobile-friendly/items/product-image";
import {
    AddonGroupEdit,
    ProductAddonGroupUpdate,
    SyncAddonGroupsModal,
} from "#menu-editor/mobile-friendly/items/sync-addon-groups-modal";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { Routes } from "#navigation/routes";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { UpdateModifiersParam } from "#menu-editor/multi-menus/redux/thunk-helpers";

import {
    convertTimeStringToNumberFormat,
    format12,
    formatDayOfWeek,
    formatTime,
    getStoreOpenDays,
    SpecialHoursItemType,
} from "../helpers/date-time-helper";
import {
    ProductEditSharedContext,
    MenuTopLevelContext,
} from "../helpers/context";

import CopyModifierGroups from "./copy-modifier-groups";
import Modifiers from "./modifier-table/modifiers";
import ModifierGroup from "./modifier-group";

import moment from "moment";
import momentTZ from "moment-timezone";

import ModifierBottomDrawer from "./modifier-bottom-drawer";
import SoldOutModal from "./sold-out-modal";

import { apply } from "json-logic-js";
import { Link } from "react-router-dom";

const DESKTOP_MIN_WIDTH = 992;

enum MenuTypeEnum {
    REGULAR = "regular",
    CATERING = "catering",
}

enum PriceOptionEnum {
    FLAT = "Flat",
    WEIGHT = "Weight",
}

const PRICE_OPTIONS = [
    {
        label: PriceOptionEnum.FLAT,
        value: PriceOptionEnum.FLAT,
    },
    {
        label: PriceOptionEnum.WEIGHT,
        value: PriceOptionEnum.WEIGHT,
    },
];

const findPolicyRate = (
    fulfillment: FulfillmentTypeEnum,
    taxPolicies: TaxPolicy[],
) => {
    const policy = ([...taxPolicies] || [])
        .sort((a, b) => b.priority - a.priority)
        .find((policy) =>
            // eslint-disable-next-line
            apply(policy.conditions, {
                fulfillment,
            }),
        );
    if (!policy) return undefined;
    const event = policy.events.find(
        (event) => event.type === TaxPolicyEventType.setTaxRate,
    );
    if (!event) return undefined;
    return event.taxInfo.rate;
};

const buildTaxPolicy = (rate: number, fulfillment: FulfillmentTypeEnum) => {
    const policy: TaxPolicy = {
        priority: 0,
        conditions: { "==": [{ var: "fulfillment" }, fulfillment] },
        events: [
            {
                type: TaxPolicyEventType.setTaxRate,
                taxInfo: { rate },
            },
        ],
    };
    return policy;
};

const WEIGHT_UNITS: WeightUnitTypes[] = ["oz", "lb", "mg", "g", "kg"];
const PAY_BY_WEIGHT_OPTIONS = WEIGHT_UNITS.map((unit) => ({
    value: unit,
    label: unit,
}));

type ProductEditProps = {
    menuAutomation: MenuAutomation;
    productIdToPromotionsMap: Record<string, string[]>;
    parentDrawerZIndex: number;
};

const isCopiedAddonGroup = (
    group: AddonGroupEdit["edited"],
): group is CopiedAddonGroup => Object.hasOwn(group, "copiedFrom");
const stripNonComparisonFields = (
    group: AddonGroup | AddonGroupEdit["edited"],
) => ({
    ...group,
    _id: undefined,
    copiedFrom: undefined,
    addons: group.addons.map((e) => ({ ...e, _id: undefined })),
});
const isEdited = (original: AddonGroup, edited: AddonGroupEdit["edited"]) =>
    !isEqual(
        stripNonComparisonFields(original),
        stripNonComparisonFields(edited),
    );

const ProductEdit: React.FC<ProductEditProps> = ({
    menuAutomation,
    productIdToPromotionsMap,
    parentDrawerZIndex,
}) => {
    const {
        closeProductEditSlideOver,
        productInEdit,
        storeHours,
        categoryOptions,
        modifierGroupsOptions,
        setProductInDraft,
        openNewProductSlideOver,
        setMenuReorderingChanges,
        setShowTableSpinner,
        showNewModifierForm,
        setShowNewModifierForm,
    } = useContext(MenuTopLevelContext);
    const dispatch = useDispatch();
    const thunkDispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);
    const activeMenu = useSelector(selectActiveMenu);
    const products = useSelector(getLegacyProducts) as IProduct[];
    const multiMenusEnabled = useMultiMenusEnabled();
    const [storeOpenDays, setStoreOpenDays] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModifierGroupInEdit, setIsModifierGroupInEdit] =
        useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [pun, setPun] = useState<string>("");
    const [category, setCategory] = useState<Options | null>(null);
    const [image, setImage] = useState<string | null | undefined>();
    const [price, setPrice] = useState<number>(0);
    const [taxRate, setTaxRate] = useState<number | undefined>();
    const [pickupRate, setPickupRate] = useState<number | undefined>();
    const [deliveryRate, setDeliveryRate] = useState<number | undefined>();
    const [dineInRate, setDineInRate] = useState<number | undefined>();
    const [taxPolicies, setTaxPolicies] = useState<TaxPolicy[]>([]);
    const [points, setPoints] = useState<number | undefined>();
    const [soldOut, setSoldOut] = useState<boolean>(false);
    const [showFulfillmentTaxRate, setShowFulfillmentTaxRate] =
        useState<boolean>(false);
    const [showTaxRateOverride, setShowTaxRateOverride] =
        useState<boolean>(false);
    const [isPickup, setIsPickup] = useState<boolean>(false);
    const [isDineIn, setIsDineIn] = useState<boolean>(false);
    const [isDelivery, setIsDelivery] = useState<boolean>(false);

    // XXX: Sets channel visibilities for the new product
    //      i.e. show product on app? show product on kiosk? etc.
    const channelVisibilityEnabled = useChannelVisibilitySettings();
    const [isAppVisible, setIsAppVisible] = useState<boolean>(true);
    const [isKioskVisible, setIsKioskVisible] = useState<boolean>(true);
    const [isRegisterVisible, setIsRegisterVisible] = useState<boolean>(true);
    const [isOnlineOrderingVisible, setIsOnlineOrderingVisible] =
        useState<boolean>(true);

    const [isPayByWeight, setIsPayByWeight] = useState<{
        label: string;
        value: string;
    }>(PRICE_OPTIONS[0]);
    const [unit, setUnit] = useState<{
        label: WeightUnitTypes;
        value: WeightUnitTypes;
    }>(PAY_BY_WEIGHT_OPTIONS[0]);
    const user = useSelector(getUser);
    const hasStoreSettingsEditPermission = useMemo(() => {
        if (!activeStore?._id) return false;
        return (
            user?.permissions?.hasSettingsWrite?.includes(activeStore._id) ||
            user?.permissions?.isOwner?.includes(activeStore._id)
        );
    }, [activeStore, user]);
    const [perUnit, setPerUnit] = useState<number>(0);
    const [upsell, setUpsell] = useState<boolean>(false);
    const [showSpecialHour, setShowSpecialHour] = useState<boolean>(false);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [specialHours, setSpecialHours] = useState<SpecialHoursItemType[]>(
        [],
    );
    const [modifierGroups, setModifierGroups] = useState<
        PartialDeep<AddonGroup>[]
    >([]);
    const [deletedAddonGroups, setDeletedAddonGroups] = useState<string[]>([]);
    const [deletedAddons, setDeletedAddons, addDeletedAddons] =
        useDeletedAddons();
    const [showBottomDrawer, setShowBottomDrawer] = useState<boolean>(false);
    const [soldOutDates, setSoldOutDates] = useState<{
        from: Date | string | null;
        until: Date | string | null;
    }>({ from: null, until: null });
    const timezone = getStoreTimezone(activeStore);
    const [isMarkProductSoldOutModalOpen, setIsMarkProductSoldOutModalOpen] =
        useState(false);
    const [modifierGroupInEdit, setModifierGroupInEdit] = useState<
        PartialDeep<AddonGroup> | undefined
    >();
    const [formattedOptions, setFormattedOptions] = useState<
        DropdownOption<InventoryItem>[]
    >([]);
    const [productInventoryItems, setProductInventoryItems] = useState<
        ProductIngredientT[] | undefined
    >([]);
    const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] =
        useState(false);

    const [syncAddonGroupsModalOpen, setSyncAddonGroupsModalOpen] =
        useState<boolean>(false);

    const isCatering = activeMenu === MenuTypeEnum.CATERING;
    const errorMsg = isCatering
        ? "Catering product category name must end with '(Catering)'"
        : "Non-catering product category name cannot end with '(Catering)'";

    const menuIssues = productInEdit
        ? menuAutomation.issuesByProductId(productInEdit._id)
        : null;

    const allItems = useSelector(getInventoryItems);
    const inventoryItems = useMemo(
        () =>
            differenceWith(
                allItems,
                productInventoryItems ?? [],
                (a, b) => a.id === b.inventoryItemId,
            ),
        [allItems, productInventoryItems],
    );

    useMemo(() => {
        const sortedInventory = compose(
            map((item: InventoryItem) => ({
                label: item.name,
                value: item.id,
                data: item,
            })),
            sortBy("name"),
        )(inventoryItems);

        setFormattedOptions(sortedInventory);
    }, [allItems, productInventoryItems]);

    useEffect(() => {
        if (activeStore && activeStore.hours) {
            const openDay = getStoreOpenDays(activeStore.hours);
            setStoreOpenDays(openDay);
        }
    }, [activeStore]);
    useEffect(() => {
        if (productInEdit) {
            setName(productInEdit.name);
            setDescription(
                productInEdit.description ? productInEdit.description : "",
            );
            setPun(productInEdit.pun ? productInEdit.pun : "");
            setPoints(productInEdit.points);
            setIsPayByWeight(
                productInEdit.priceByWeight
                    ? PRICE_OPTIONS[1]
                    : PRICE_OPTIONS[0],
            );
            setImage(productInEdit.image);
            setPrice(productInEdit.price);
            if (productInEdit?.priceByWeight) {
                setPerUnit(productInEdit.priceByWeight?.perUnit);
                setUnit(
                    PAY_BY_WEIGHT_OPTIONS.filter(
                        (option) =>
                            option.label === productInEdit?.priceByWeight?.unit,
                    )[0],
                );
            }
            setSoldOut(productInEdit.soldOut);
            setSoldOutDates({
                from: productInEdit.soldOutDates?.from
                    ? productInEdit.soldOutDates?.from
                    : null,
                until: productInEdit.soldOutDates?.until
                    ? productInEdit.soldOutDates?.until
                    : null,
            });
            setUpsell(productInEdit.upsell);
            setIsTemplate(productInEdit.isTemplate ?? false);

            setIsPickup(productInEdit.fulfillmentMethods.isPickup);
            setIsDelivery(productInEdit.fulfillmentMethods.isDelivery);
            setIsDineIn(productInEdit.fulfillmentMethods.isDineIn);

            setIsAppVisible(productInEdit.channelVisibility?.app ?? true);
            setIsKioskVisible(productInEdit?.channelVisibility?.kiosk ?? true);
            setIsRegisterVisible(
                productInEdit?.channelVisibility?.register ?? true,
            );
            setIsOnlineOrderingVisible(
                productInEdit?.channelVisibility?.onlineOrdering ?? true,
            );
            setShowSpecialHour(productInEdit.hours !== null);
            setShowTaxRateOverride(productInEdit.taxInfo?.rate !== undefined);
            setTaxPolicies(productInEdit.taxPolicies ?? []);
            setTaxRate(productInEdit.taxInfo?.rate);
            if (productInEdit.hours) {
                const specialHours: SpecialHoursItemType[] =
                    productInEdit.hours.local.map((item, i) => ({
                        id: i,
                        dayOfWeek: formatDayOfWeek(item.start),
                        time: {
                            start: formatTime(item.start),
                            end: formatTime(item.end),
                        },
                    }));
                setSpecialHours(specialHours);
            } else {
                setSpecialHours([
                    {
                        id: 0,
                        dayOfWeek: storeOpenDays[0],
                        time: {
                            start: moment("12:00 am", format12),
                            end: moment("11:59 pm", format12),
                        },
                    },
                ]);
            }
            setModifierGroups(productInEdit.addonGroups);
            setProductInventoryItems(
                productInEdit ? productInEdit?.inventoryItems : [],
            );
            setDeletedAddonGroups([]);
            setDeletedAddons({});
            setIsLoading(false);
        }
    }, [productInEdit]);

    useEffect(() => {
        if (productInEdit) {
            categoryOptions &&
                setCategory(
                    categoryOptions.filter(
                        (option) => option.label === productInEdit.category,
                    )[0],
                );
        }
    }, [categoryOptions, productInEdit]);

    useEffect(() => {
        setShowFulfillmentTaxRate(!!taxPolicies.length);
        setPickupRate(findPolicyRate(FulfillmentTypeEnum.Pickup, taxPolicies));
        setDeliveryRate(
            findPolicyRate(FulfillmentTypeEnum.Delivery, taxPolicies),
        );
        setDineInRate(findPolicyRate(FulfillmentTypeEnum.DineIn, taxPolicies));
    }, [taxPolicies]);

    useEffect(() => {
        if (pickupRate !== undefined) {
            setIsPickup(true);
        }
        if (dineInRate !== undefined) {
            setIsDineIn(true);
        }
    }, [showFulfillmentTaxRate, pickupRate, dineInRate]);

    useEffect(() => {
        form.setFieldsValue({
            name: name,
            category: category,
            points: points,
            price: price,
            isPayByWeight: isPayByWeight,
            unit: unit,
            perUnit: perUnit,
            image: image,
            description: description,
            pun: pun,
            isPickup: isPickup,
            isDelivery: isDelivery,
            isDineIn: isDineIn,
            hours: specialHours,
            soldOut: soldOut,
            upsell: upsell,
            modifierGroups: modifierGroups,
            pickupRate: pickupRate,
            deliveryRate: deliveryRate,
            dineInRate: dineInRate,
            taxInfo: taxRate,
        });
    }, [
        name,
        category,
        points,
        price,
        isPayByWeight,
        unit,
        perUnit,
        image,
        description,
        pun,
        isDineIn,
        isDelivery,
        isPickup,
        specialHours,
        soldOut,
        soldOutDates,
        upsell,
        modifierGroups,
        pickupRate,
        deliveryRate,
        dineInRate,
        taxRate,
        form,
    ]);

    const handleUpdate = () => {
        const policies: TaxPolicy[] = [];
        if (pickupRate !== undefined)
            policies.push(
                buildTaxPolicy(pickupRate, FulfillmentTypeEnum.Pickup),
            );
        if (deliveryRate !== undefined)
            policies.push(
                buildTaxPolicy(deliveryRate, FulfillmentTypeEnum.Delivery),
            );
        if (dineInRate !== undefined)
            policies.push(
                buildTaxPolicy(dineInRate, FulfillmentTypeEnum.DineIn),
            );
        setTaxPolicies(policies);
    };

    const formatSpecialHours = useCallback(() => {
        const newHours: ITimeRangeSchema[] = [];

        specialHours &&
            specialHours.map((item) => {
                if (item.time.start && item.time.end) {
                    const formattedHours = {
                        start: convertTimeStringToNumberFormat(
                            item.dayOfWeek,
                            item.time.start,
                        ),
                        end: convertTimeStringToNumberFormat(
                            item.dayOfWeek,
                            item.time.end,
                        ),
                    };
                    newHours.push(formattedHours);
                }
            });

        return newHours;
    }, [specialHours]);

    const _isProductNameTaken = (
        products: IProduct[],
        newProductName: string,
    ) => {
        let hasSameNameUnderSameCategory = false;
        products.map((product) => {
            if (
                product.name === newProductName &&
                product.category === category?.label
            ) {
                hasSameNameUnderSameCategory = true;
                return;
            }
        });
        return hasSameNameUnderSameCategory;
    };

    const handleAddProduct = useCallback(
        async (
            /**
             * Additional modifiers updates that the multiMenusThunk would need to know about that occured as a side effect of the product edit
             * i.e. the bulk addon edit
             */
            updateModifierParams: UpdateModifiersParam[] = [],
        ) => {
            if (!activeStore) {
                return;
            }
            setShowTableSpinner(true);
            if (productInEdit) {
                const specialHours: IHoursSchema = {
                    zone: activeStore.hours.zone,
                    local: formatSpecialHours(),
                };
                const newAddonNames: {
                    [key: string]: { addonNames: string[] };
                } = {};
                const newAddonGroupNames: string[] = [];
                // Ideally the type should be PartialDeep<IProduct>, but I put object because some new created AddonGroups has no _id property, and "api.product.update" requires the body is Partial<IProduct> type, but if put any type it accept it, strange behavior
                const post: object = {
                    name: name,
                    description: description,
                    pun: pun,
                    category: category?.label,
                    hours:
                        showSpecialHour && !_.isEmpty(specialHours)
                            ? {
                                  zone: activeStore.hours.zone,
                                  local: formatSpecialHours(),
                              }
                            : null,
                    isCatering: isCatering,
                    minimumQuantity: 1,
                    price:
                        isPayByWeight.value === PriceOptionEnum.FLAT
                            ? price
                            : 0,
                    soldOutDates: {
                        from: soldOutDates.from,
                        until: soldOutDates.until,
                    },
                    soldOut: soldOut,
                    priceByWeight:
                        isPayByWeight.value === PriceOptionEnum.FLAT
                            ? null
                            : { unit: unit.value, perUnit: perUnit },
                    points: points ?? 0,
                    upsell: upsell,
                    image: image ? image : null,
                    //Remove _id in new created addonGroups and new created addons(new created has Date.now().toString() as temporary _id which has length of 13), server-side will generate unique ObjectId(length of 24) for new created addon
                    addonGroups: modifierGroups.map((item) => {
                        if (
                            item &&
                            item._id &&
                            isServerGeneratedId(item?._id)
                        ) {
                            item = {
                                name: item?.name,
                                limit: item?.limit,
                                addons: item?.addons,
                                required: item?.required,
                                supportsMultiple: item?.supportsMultiple,
                            };
                            if (item?.name) newAddonGroupNames.push(item.name);
                        }
                        if (item.addons) {
                            const addonsWithoutId = item.addons.map((addon) => {
                                if (
                                    addon &&
                                    addon._id &&
                                    isServerGeneratedId(addon?._id)
                                ) {
                                    if (item._id) {
                                        if (!newAddonNames[item._id])
                                            newAddonNames[item._id] = {
                                                addonNames: [],
                                            };
                                        newAddonNames[item._id].addonNames.push(
                                            addon.name as string,
                                        );
                                    }
                                    return {
                                        name: addon?.name,
                                        price: addon?.price,
                                        soldOutDates: addon?.soldOutDates,
                                        soldOut: addon?.soldOut,
                                        inventoryItems: addon?.inventoryItems,
                                    };
                                }
                                return addon;
                            });
                            return { ...item, addons: addonsWithoutId };
                        }
                        return item;
                    }),
                    fulfillmentMethods: {
                        isDelivery: isDelivery,
                        isDineIn: isDineIn,
                        isPickup: isPickup,
                    },
                    channelVisibility: {
                        app: isAppVisible,
                        kiosk: isKioskVisible,
                        register: isRegisterVisible,
                        onlineOrdering: isOnlineOrderingVisible,
                    },
                    taxPolicies: showFulfillmentTaxRate ? taxPolicies : [],
                    taxInfo:
                        taxRate !== undefined && showTaxRateOverride
                            ? { rate: taxRate }
                            : null,
                    isTemplate: isTemplate,
                    inventoryItems: productInventoryItems,
                };
                try {
                    const { data } = await api.products.update(
                        productInEdit?._id,
                        post,
                    );
                    dispatch(updateLegacyProduct(data.product));
                    if (multiMenusEnabled) {
                        const newAddons = newAddonIds(
                            data.product,
                            newAddonNames,
                        );
                        const newAddonGroups = newAddonGroupIds(
                            data.product,
                            newAddonGroupNames,
                        );
                        await thunkDispatch(
                            multiMenuThunks.updateModifiers([
                                {
                                    newAddons,
                                    newAddonGroups,
                                    productId: data.product._id,
                                    deletedAddonGroups,
                                    deletedAddons,
                                },
                                ...updateModifierParams,
                            ]),
                        );
                    }
                    const response = await api.stores.getOne(activeStore._id);
                    dispatch(setActiveStore(response.data.store));
                    setShowTableSpinner(false);
                } catch (err) {
                    sendError(err);
                    toast.error("Failed to update products");
                    setShowTableSpinner(false);
                }
            }
            setMenuReorderingChanges(0);
        },
        [
            activeStore,
            category?.label,
            deletedAddonGroups,
            deletedAddons,
            description,
            dispatch,
            formatSpecialHours,
            image,
            isAppVisible,
            isCatering,
            isDelivery,
            isDineIn,
            isKioskVisible,
            isOnlineOrderingVisible,
            isPayByWeight.value,
            isPickup,
            isRegisterVisible,
            isTemplate,
            modifierGroups,
            multiMenusEnabled,
            name,
            perUnit,
            points,
            price,
            productInEdit,
            productInventoryItems,
            pun,
            setMenuReorderingChanges,
            setShowTableSpinner,
            showFulfillmentTaxRate,
            showSpecialHour,
            showTaxRateOverride,
            soldOut,
            soldOutDates.from,
            soldOutDates.until,
            taxPolicies,
            taxRate,
            thunkDispatch,
            unit.value,
            upsell,
        ],
    );

    const changedAddonGroups = useMemo(() => {
        if (!productInEdit?.addonGroups || !modifierGroups.length) return [];
        return modifierGroups
            .map<AddonGroupEdit["edited"]>((group) => ({
                ...group,
                addons:
                    group.addons?.map((addon) => {
                        if (
                            addon &&
                            addon._id &&
                            isServerGeneratedId(addon?._id)
                        ) {
                            return {
                                name: addon?.name,
                                price: addon?.price,
                                soldOutDates: addon?.soldOutDates,
                                soldOut: addon?.soldOut,
                                soldOutToday: addon?.soldOutToday,
                                inventoryItems: addon?.inventoryItems,
                            };
                        }
                        return addon;
                    }) ?? [],
            }))
            .map((group) => {
                if (isCopiedAddonGroup(group))
                    return {
                        edited: group,
                        original: group.copiedFrom,
                    };
                return {
                    edited: group,
                    original: productInEdit.addonGroups.find(
                        (e) => e._id === group._id,
                    ),
                };
            })
            .filter((e): e is AddonGroupEdit => {
                if (!e.original) return false;
                return isEdited(e.original, e.edited);
            });
    }, [modifierGroups, productInEdit?.addonGroups]);

    /**
     * Addon groups on other products that exactly match (name of addon group, name/price of addons) addons that have been updated.
     * Used for propagating changes to addons to other products.
     */
    const matchingAddonUpdates: ProductAddonGroupUpdate[] = useMemo(() => {
        if (!productInEdit?.addonGroups || !changedAddonGroups) return [];

        const productsExcludingEditing = [
            ...products
                .filter((e) => e._id !== productInEdit._id)
                .toSorted((a, b) => {
                    const c = a.category.localeCompare(b.category);
                    return c === 0 ? a.name.localeCompare(b.name) : c;
                }),
        ];

        return [
            // ensure the currently editing product's addon groups are at the top
            ...changedAddonGroups.map((e) => ({
                addonGroup: e.original,
                product: productInEdit,
                matchingChangedAddonGroup: e,
            })),
            ...productsExcludingEditing.flatMap((product) =>
                product.addonGroups
                    .map((addonGroup) => ({
                        addonGroup,
                        product,
                        matchingChangedAddonGroup: changedAddonGroups.find(
                            (changedGroup) => {
                                const { original } = changedGroup;
                                // check if name is the same
                                if (addonGroup.name !== original.name)
                                    return false;
                                // and every addon has the same price/name
                                return (
                                    addonGroup.addons.length ===
                                        original.addons.length &&
                                    addonGroup.addons.every((addon) =>
                                        original.addons.some(
                                            (e) =>
                                                e.name === addon.name &&
                                                e.price === addon.price,
                                        ),
                                    )
                                );
                            },
                        ),
                    }))
                    .filter(
                        (e): e is ProductAddonGroupUpdate =>
                            !!e.matchingChangedAddonGroup,
                    ),
            ),
        ];
    }, [changedAddonGroups, productInEdit, products]);

    const _onFinish = useCallback(
        async (updateMultiMenuModifiersParams?: UpdateModifiersParam[]) => {
            void handleAddProduct(updateMultiMenuModifiersParams);
            closeProductEditSlideOver();
        },
        [closeProductEditSlideOver, handleAddProduct],
    );

    const onFinish = useCallback(() => {
        if (
            matchingAddonUpdates.some(
                (e) => e.product._id !== productInEdit?._id,
            )
        ) {
            setSyncAddonGroupsModalOpen(true);
        } else {
            void _onFinish();
        }
    }, [_onFinish, matchingAddonUpdates, productInEdit?._id]);

    const onFinishFailed = () => {
        toast.error(
            "Failed to update product, please check all the required fields",
        );
    };

    const handlePressDuplicate = () => {
        if (!productInEdit) {
            toast.error("You're trying to duplicate a non-existent product");
        }
        closeProductEditSlideOver();
        const post = {
            storeId: activeStore?._id,
            ...productInEdit,
            name: `${productInEdit?.name}`,
            _id: undefined,
        };
        setIsLoading(true);
        setProductInDraft(post);
        setIsLoading(false);
        openNewProductSlideOver();
    };

    const handleDeleteItem = () => {
        if (!activeStore?._id) return;
        const productId: string = productInEdit?._id ? productInEdit._id : "";
        if (productId) {
            setShowTableSpinner(true);
            setIsDeleteProductModalOpen(false);
            closeProductEditSlideOver();
            toast.info("Removing Item...", { duration: 3000 });
            api.products
                .remove(productId)
                .then(async () => {
                    dispatch(removeLegacyProduct(productId));
                    return api.stores.getOne(activeStore._id);
                })
                .then(({ data }) => {
                    dispatch(setActiveStore(data.store));
                    toast.success("Item removed successfully");
                })
                .catch((err) => {
                    toast.error("Could not remove product");
                    sendError(err);
                })
                .finally(() => {
                    setShowTableSpinner(false);
                    setMenuReorderingChanges(0);
                });
        }
    };
    const validateMessages = {
        required: "${label} is required!",
        types: {
            name: "${label} is not valid",
            number: "${label} is not a valid number!",
        },
        number: {
            min: "${label} cannot be less than ${min}",
        },
    };

    return showNewModifierForm ? (
        <ProductEditSharedContext.Provider
            value={{
                showBottomDrawer,
                setShowBottomDrawer,
                modifierGroups,
                setModifierGroups,
                setDeletedAddonGroups,
                addDeletedAddons,
                setShowNewModifierForm,
                isModifierGroupInEdit,
                modifierGroupInEdit,
                modifierGroupsOptions,
                setIsModifierGroupInEdit,
                showNewModifierForm,
                setModifierGroupInEdit,
            }}
        >
            <ModifierGroup
                isProductInEditMode={true}
                menuAutomation={menuAutomation}
            ></ModifierGroup>
        </ProductEditSharedContext.Provider>
    ) : (
        <ProductEditSharedContext.Provider
            value={{
                showBottomDrawer,
                setShowBottomDrawer,
                modifierGroups,
                setModifierGroups,
                setDeletedAddonGroups,
                addDeletedAddons,
                setShowNewModifierForm,
                isModifierGroupInEdit,
                modifierGroupInEdit,
                modifierGroupsOptions,
                setIsModifierGroupInEdit,
                showNewModifierForm,
                setModifierGroupInEdit,
            }}
        >
            <Body>
                <SyncAddonGroupsModal
                    open={syncAddonGroupsModalOpen}
                    close={() => setSyncAddonGroupsModalOpen(false)}
                    onSubmit={_onFinish}
                    matchingAddonGroupUpdates={matchingAddonUpdates}
                />
                <HeaderRow>
                    <span className="header-text">
                        <Chevron
                            onClick={closeProductEditSlideOver}
                            fill={SystemColors.v1.sesame}
                            className="clickable-icon"
                        />
                        <span className="head-text-span">
                            {thirdPartyEnabled ? "Item Details" : "Edit Item"}
                        </span>
                        {!thirdPartyEnabled && (
                            <>
                                <span className="iconWrapper">
                                    <Duplicate
                                        className="duplicate-icon"
                                        onClick={() => {
                                            handlePressDuplicate();
                                        }}
                                    />
                                </span>
                                <span className="iconWrapper">
                                    <Delete
                                        className="delete-icon"
                                        onClick={() =>
                                            setIsDeleteProductModalOpen(true)
                                        }
                                    />
                                </span>
                            </>
                        )}
                    </span>
                </HeaderRow>
                <Divider />
                {isLoading ? (
                    <div className="spinnerWrapper">
                        <Spin tip="Loading..." />
                    </div>
                ) : (
                    <>
                        {menuIssues?.map((issue, i) => (
                            <Alert
                                key={i}
                                className={classNames({
                                    banner: true,
                                    warning: issue.severity !== "critical",
                                    error: issue.severity === "critical",
                                })}
                                message={issue.description}
                                type={
                                    issue.severity === "critical"
                                        ? "error"
                                        : "warning"
                                }
                                showIcon
                                closable
                            />
                        ))}
                        <Form
                            form={form}
                            layout="vertical"
                            name="product-edit"
                            requiredMark={true}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            validateMessages={validateMessages}
                        >
                            <Row className="flexWrap f1 mt12">
                                <Col
                                    span={width >= DESKTOP_MIN_WIDTH ? 14 : 24}
                                >
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                                async validator(_, value) {
                                                    const isTaken =
                                                        _isProductNameTaken(
                                                            products,
                                                            value,
                                                        );
                                                    if (
                                                        isTaken &&
                                                        value !== name
                                                    ) {
                                                        return Promise.reject(
                                                            "This product name is already taken",
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={thirdPartyEnabled}
                                            size="large"
                                            placeholder="Item Name"
                                            value={name}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                            ) => setName(e.target.value)}
                                        />
                                    </Form.Item>

                                    <Row>
                                        <Col span={16}>
                                            <Form.Item
                                                name="category"
                                                label="Category"
                                                className="category-row"
                                                hasFeedback
                                                validateFirst
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                    {
                                                        async validator() {
                                                            const isTaken =
                                                                isCategoryNameTaken(
                                                                    category?.value.toString() ||
                                                                        "",
                                                                    products,
                                                                );
                                                            if (isTaken) {
                                                                return Promise.reject(
                                                                    "This category name is already taken",
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                    {
                                                        async validator(
                                                            _,
                                                            value,
                                                        ) {
                                                            const categoryNameValid =
                                                                isCategoryNameValid(
                                                                    value.label,
                                                                    isCatering,
                                                                );
                                                            if (
                                                                !categoryNameValid
                                                            ) {
                                                                return Promise.reject(
                                                                    errorMsg,
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <DropDownSelect
                                                    disabled={thirdPartyEnabled}
                                                    isCreatable
                                                    square
                                                    options={categoryOptions}
                                                    value={category}
                                                    onChange={(
                                                        selectedOption: Options | null,
                                                    ) =>
                                                        setCategory(
                                                            selectedOption,
                                                        )
                                                    }
                                                    defaultValue={
                                                        categoryOptions?.filter(
                                                            (option) =>
                                                                option.label ===
                                                                productInEdit?.category,
                                                        )[0]
                                                    }
                                                    placeholder="Select or create a category"
                                                    formatCreateLabel="Create "
                                                    height="40px"
                                                ></DropDownSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={7}>
                                            <Form.Item
                                                name="points"
                                                label="Points"
                                                required
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    disabled={thirdPartyEnabled}
                                                    className="input-number"
                                                    size="large"
                                                    value={points}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setPoints(e);
                                                    }}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>,
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            span={
                                                isPayByWeight.value ===
                                                PriceOptionEnum.WEIGHT
                                                    ? 8
                                                    : 12
                                            }
                                        >
                                            <Form.Item
                                                name="isPayByWeight"
                                                label="Price Type"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <DropDownSelect
                                                    disabled={thirdPartyEnabled}
                                                    square
                                                    options={PRICE_OPTIONS}
                                                    value={isPayByWeight}
                                                    onChange={(
                                                        selectedOption: React.SetStateAction<{
                                                            label: string;
                                                            value: string;
                                                        }>,
                                                    ) => {
                                                        setIsPayByWeight(
                                                            selectedOption,
                                                        );
                                                    }}
                                                    defaultValue={
                                                        productInEdit?.priceByWeight
                                                            ? PRICE_OPTIONS[1]
                                                            : PRICE_OPTIONS[0]
                                                    }
                                                    placeholder="Select"
                                                    height="40px"
                                                ></DropDownSelect>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}></Col>

                                        {isPayByWeight.value ===
                                        PriceOptionEnum.WEIGHT ? (
                                            <>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name="unit"
                                                        label="Unit"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <DropDownSelect
                                                            disabled={
                                                                thirdPartyEnabled
                                                            }
                                                            square
                                                            options={
                                                                PAY_BY_WEIGHT_OPTIONS
                                                            }
                                                            value={unit}
                                                            onChange={(
                                                                selectedOption: React.SetStateAction<{
                                                                    label: WeightUnitTypes;
                                                                    value: WeightUnitTypes;
                                                                }>,
                                                            ) => {
                                                                setUnit(
                                                                    selectedOption,
                                                                );
                                                            }}
                                                            placeholder="Select"
                                                            height="40px"
                                                        ></DropDownSelect>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col>
                                                    <Form.Item
                                                        name="perUnit"
                                                        label="Per Unit"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                            {
                                                                type: "number",
                                                                min: 0,
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            disabled={
                                                                thirdPartyEnabled
                                                            }
                                                            formatter={(
                                                                value,
                                                            ) =>
                                                                // Use tolocaleString to format the number with commas visually for thousands place.
                                                                parseFloat(
                                                                    //@ts-expect-error value is incorrectly typed in antd as a number when it is actually a string
                                                                    value || 0,
                                                                ).toLocaleString()
                                                            }
                                                            precision={2}
                                                            className="input-number"
                                                            size="large"
                                                            prefix={
                                                                <Text
                                                                    weight="thin"
                                                                    size="s"
                                                                >
                                                                    $
                                                                </Text>
                                                            }
                                                            value={perUnit}
                                                            onChange={(e) =>
                                                                setPerUnit(
                                                                    e || 0,
                                                                )
                                                            }
                                                            onKeyDown={(
                                                                e: React.KeyboardEvent<HTMLInputElement>,
                                                            ) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    e.currentTarget.blur();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                <Col span={11}>
                                                    <Form.Item
                                                        name="price"
                                                        label="Price"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                            {
                                                                type: "number",
                                                                min: 0,
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            disabled={
                                                                thirdPartyEnabled
                                                            }
                                                            // Use tolocaleString to format the number with commas visually for thousands place.
                                                            formatter={(
                                                                value,
                                                            ) =>
                                                                parseFloat(
                                                                    //@ts-expect-error value is incorrectly typed in antd as a number when it is actually a string
                                                                    value ||
                                                                        "0",
                                                                ).toLocaleString()
                                                            }
                                                            className="input-number"
                                                            precision={2}
                                                            size="large"
                                                            value={price}
                                                            prefix={
                                                                <Text
                                                                    weight="thin"
                                                                    size="s"
                                                                >
                                                                    $
                                                                </Text>
                                                            }
                                                            onChange={(num) =>
                                                                setPrice(
                                                                    num || 0,
                                                                )
                                                            }
                                                            onKeyDown={(
                                                                e: React.KeyboardEvent<HTMLInputElement>,
                                                            ) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    e.currentTarget.blur();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Col>
                                {width > DESKTOP_MIN_WIDTH ? (
                                    <>
                                        <Col span={2}></Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="image"
                                                label="Image"
                                            >
                                                <ProductImage
                                                    imageURL={image}
                                                    setImage={setImage}
                                                    disableEdit={
                                                        thirdPartyEnabled
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : null}
                            </Row>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea
                                    disabled={thirdPartyEnabled}
                                    placeholder="Add a optional description for this item. Include details like ingredients, flavors, textures or spice"
                                    size="large"
                                    value={description}
                                    autoSize={{ minRows: 2, maxRows: 10 }}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            {width <= DESKTOP_MIN_WIDTH ? (
                                <Col span={24}>
                                    <Form.Item label="Photo">
                                        <ProductImage
                                            imageURL={image}
                                            setImage={setImage}
                                            disableEdit={thirdPartyEnabled}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : null}
                            <Form.Item
                                label={
                                    <div className="flex items-center space-x-1">
                                        Fulfillment
                                        <ReportsTooltip
                                            className="!fill-black"
                                            body={
                                                <>
                                                    Ensure the appropriate
                                                    fulfillments are enabled in{" "}
                                                    <Link
                                                        to={
                                                            Routes.SettingsPlatforms
                                                        }
                                                    >
                                                        settings.
                                                    </Link>
                                                </>
                                            }
                                        />
                                    </div>
                                }
                                name="fulfillment"
                                rules={[
                                    {
                                        async validator() {
                                            if (
                                                !(
                                                    isPickup ||
                                                    isDelivery ||
                                                    isDineIn
                                                )
                                            ) {
                                                return Promise.reject(
                                                    "Please select at least 1 fulfillment method",
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Row className="fulfillment">
                                    <Col span={8}>
                                        <Form.Item
                                            name="isPickup"
                                            label=""
                                            className="fulfillmentMethod"
                                        >
                                            <Form.Item noStyle>
                                                <CheckboxButton
                                                    disabled={thirdPartyEnabled}
                                                    variant="outline"
                                                    squareBtn
                                                    fullWidth
                                                    checkBox
                                                    checked={isPickup}
                                                    onClick={() => {
                                                        if (!thirdPartyEnabled)
                                                            setIsPickup(
                                                                !isPickup,
                                                            );
                                                    }}
                                                    mr={4}
                                                    ml={4}
                                                >
                                                    Pickup
                                                </CheckboxButton>
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            name="isDelivery"
                                            label=""
                                            className="fulfillmentMethod"
                                        >
                                            <Form.Item noStyle>
                                                <CheckboxButton
                                                    disabled={thirdPartyEnabled}
                                                    variant="outline"
                                                    squareBtn
                                                    fullWidth
                                                    mr={4}
                                                    ml={4}
                                                    onClick={() => {
                                                        if (!thirdPartyEnabled)
                                                            setIsDelivery(
                                                                !isDelivery,
                                                            );
                                                    }}
                                                    checked={isDelivery}
                                                    checkBox
                                                >
                                                    Delivery
                                                </CheckboxButton>
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            name="isDineIn"
                                            label=""
                                            className="fulfillmentMethod"
                                        >
                                            <Form.Item noStyle>
                                                <CheckboxButton
                                                    disabled={thirdPartyEnabled}
                                                    variant="outline"
                                                    squareBtn
                                                    fullWidth
                                                    onClick={() => {
                                                        if (!thirdPartyEnabled)
                                                            setIsDineIn(
                                                                !isDineIn,
                                                            );
                                                    }}
                                                    checkBox
                                                    checked={isDineIn}
                                                    mr={4}
                                                    ml={4}
                                                >
                                                    Dine In
                                                </CheckboxButton>
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            {channelVisibilityEnabled ? (
                                <Form.Item label="Channels" name="channels">
                                    <Row style={{ gap: 8, flexWrap: "wrap" }}>
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                disabled={thirdPartyEnabled}
                                                style={{ flexGrow: 1 }}
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                checkBox
                                                checked={isAppVisible}
                                                onClick={() => {
                                                    if (!thirdPartyEnabled)
                                                        setIsAppVisible(
                                                            !isAppVisible,
                                                        );
                                                }}
                                            >
                                                App
                                            </CheckboxButton>
                                        </Form.Item>
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                disabled={thirdPartyEnabled}
                                                style={{ flexGrow: 1 }}
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                checkBox
                                                checked={isKioskVisible}
                                                onClick={() => {
                                                    if (!thirdPartyEnabled)
                                                        setIsKioskVisible(
                                                            !isKioskVisible,
                                                        );
                                                }}
                                            >
                                                Kiosk
                                            </CheckboxButton>
                                        </Form.Item>
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                disabled={thirdPartyEnabled}
                                                style={{ flexGrow: 1 }}
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                checkBox
                                                checked={isRegisterVisible}
                                                onClick={() => {
                                                    if (!thirdPartyEnabled)
                                                        setIsRegisterVisible(
                                                            !isRegisterVisible,
                                                        );
                                                }}
                                            >
                                                Register
                                            </CheckboxButton>
                                        </Form.Item>
                                        <Form.Item noStyle>
                                            <CheckboxButton
                                                disabled={thirdPartyEnabled}
                                                style={{ flexGrow: 1 }}
                                                variant="outline"
                                                squareBtn
                                                fullWidth
                                                checkBox
                                                checked={
                                                    isOnlineOrderingVisible
                                                }
                                                onClick={() => {
                                                    if (!thirdPartyEnabled)
                                                        setIsOnlineOrderingVisible(
                                                            !isOnlineOrderingVisible,
                                                        );
                                                }}
                                            >
                                                Online Ordering
                                            </CheckboxButton>
                                        </Form.Item>
                                    </Row>
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                label={
                                    <div className="flex-row items-center justify-center">
                                        Sales Tax
                                        <ReportsTooltip
                                            className="!fill-black"
                                            body={
                                                <>
                                                    You need Store Settings Edit
                                                    permissions in order to edit
                                                    sales tax rates.
                                                </>
                                            }
                                        />
                                    </div>
                                }
                                className="sales-tax"
                            >
                                <Form.Item className="taxRateOverride">
                                    <RowForSwitch>
                                        <Label>Tax Rate Override</Label>
                                        <Form.Item
                                            name="taxRateOverride"
                                            noStyle
                                        >
                                            <Switch
                                                disabled={
                                                    thirdPartyEnabled ||
                                                    !hasStoreSettingsEditPermission
                                                }
                                                id="product-edit-taxRateOverride"
                                                onChange={() => {
                                                    if (showTaxRateOverride)
                                                        setTaxRate(undefined);

                                                    if (!showTaxRateOverride) {
                                                        setShowFulfillmentTaxRate(
                                                            false,
                                                        );
                                                        setPickupRate(
                                                            undefined,
                                                        );
                                                        setDineInRate(
                                                            undefined,
                                                        );
                                                        setDeliveryRate(
                                                            undefined,
                                                        );
                                                    }
                                                    setShowTaxRateOverride(
                                                        !showTaxRateOverride,
                                                    );
                                                }}
                                                isOn={showTaxRateOverride}
                                            ></Switch>
                                        </Form.Item>
                                    </RowForSwitch>
                                </Form.Item>
                                {showTaxRateOverride ? (
                                    <Form.Item
                                        name="taxInfo"
                                        className="taxInfo"
                                        rules={[
                                            {
                                                type: "number",
                                                min: 0,
                                                max: 20,
                                                message:
                                                    "Invalid tax rate. Please enter a numeric value between 0 and 20.",
                                            },
                                            {
                                                required: showTaxRateOverride,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            disabled={
                                                thirdPartyEnabled ||
                                                !hasStoreSettingsEditPermission
                                            }
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ",",
                                                )
                                            }
                                            className="input-number tax"
                                            size="large"
                                            value={taxRate}
                                            onChange={(e) => {
                                                if (e !== null) setTaxRate(e);
                                            }}
                                            onKeyDown={(
                                                e: React.KeyboardEvent<HTMLInputElement>,
                                            ) => {
                                                if (e.key === "Enter") {
                                                    e.currentTarget.blur();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                ) : null}
                                <RowForSwitch>
                                    <Label>
                                        Fulfillment-Specific Tax Rates
                                    </Label>
                                    <Form.Item
                                        name="fulfillmentTaxRate"
                                        noStyle
                                    >
                                        <Switch
                                            disabled={
                                                thirdPartyEnabled ||
                                                !hasStoreSettingsEditPermission
                                            }
                                            id="product-edit-fulfillmentTaxRate"
                                            onChange={() => {
                                                if (showFulfillmentTaxRate) {
                                                    setPickupRate(undefined);
                                                    setDineInRate(undefined);
                                                    setDeliveryRate(undefined);
                                                }

                                                if (!showFulfillmentTaxRate) {
                                                    setShowTaxRateOverride(
                                                        false,
                                                    );
                                                    setTaxRate(undefined);
                                                }

                                                setShowFulfillmentTaxRate(
                                                    !showFulfillmentTaxRate,
                                                );
                                            }}
                                            isOn={showFulfillmentTaxRate}
                                        />
                                    </Form.Item>
                                </RowForSwitch>
                                <Extra>
                                    Set individual tax rates for each
                                    fulfillment method
                                </Extra>

                                {showFulfillmentTaxRate ? (
                                    <Row className="fulfillmentTaxRateRow">
                                        <Col span={6}>
                                            <Form.Item
                                                name="pickupRate"
                                                className="fulfillmentTaxRate"
                                                label="Pickup"
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                        max: 20,
                                                        message:
                                                            "Invalid tax rate. Please enter a numeric value between 0 and 20.",
                                                    },
                                                    {
                                                        required:
                                                            showFulfillmentTaxRate,
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    disabled={
                                                        thirdPartyEnabled ||
                                                        !hasStoreSettingsEditPermission
                                                    }
                                                    formatter={(value) =>
                                                        `${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ",",
                                                        )
                                                    }
                                                    className="input-number tax"
                                                    size="large"
                                                    value={pickupRate}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setPickupRate(e);
                                                    }}
                                                    onBlur={handleUpdate}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>,
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} />
                                        <Col span={6}>
                                            <Form.Item
                                                name="deliveryRate"
                                                label="Delivery"
                                                className="fulfillmentTaxRate"
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                        max: 20,
                                                        message:
                                                            "Invalid tax rate. Please enter a numeric value between 0 and 20.",
                                                    },
                                                    {
                                                        required:
                                                            showFulfillmentTaxRate,
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    disabled={
                                                        thirdPartyEnabled ||
                                                        !hasStoreSettingsEditPermission
                                                    }
                                                    formatter={(value) =>
                                                        `${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ",",
                                                        )
                                                    }
                                                    className="input-number tax"
                                                    size="large"
                                                    value={deliveryRate}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setDeliveryRate(e);
                                                    }}
                                                    onBlur={handleUpdate}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>,
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} />
                                        <Col span={6}>
                                            <Form.Item
                                                name="dineInRate"
                                                label="Dine-in"
                                                className="fulfillmentTaxRate"
                                                rules={[
                                                    {
                                                        type: "number",
                                                        min: 0,
                                                        max: 20,
                                                        message:
                                                            "Invalid tax rate. Please enter a numeric value between 0 and 20.",
                                                    },
                                                    {
                                                        required:
                                                            showFulfillmentTaxRate,
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    disabled={
                                                        thirdPartyEnabled ||
                                                        !hasStoreSettingsEditPermission
                                                    }
                                                    formatter={(value) =>
                                                        `${value}`.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ",",
                                                        )
                                                    }
                                                    className="input-number tax"
                                                    size="large"
                                                    value={dineInRate}
                                                    onChange={(e) => {
                                                        if (e !== null)
                                                            setDineInRate(e);
                                                    }}
                                                    onBlur={handleUpdate}
                                                    onKeyDown={(
                                                        e: React.KeyboardEvent<HTMLInputElement>,
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.currentTarget.blur();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ) : null}
                            </Form.Item>
                            <Divider />
                            <Form.Item label="Additional Options">
                                <RowForSwitch>
                                    <Label>Sold Out</Label>
                                    <Form.Item name="soldOut" noStyle>
                                        <Switch
                                            disabled={thirdPartyEnabled}
                                            id="product-edit-sold-out"
                                            onChange={() => {
                                                if (
                                                    !soldOut &&
                                                    soldOutDates.until === null
                                                ) {
                                                    setIsMarkProductSoldOutModalOpen(
                                                        true,
                                                    );
                                                } else {
                                                    setSoldOutDates({
                                                        from: null,
                                                        until: null,
                                                    });
                                                    setSoldOut(false);
                                                }
                                            }}
                                            isOn={soldOut}
                                        ></Switch>
                                    </Form.Item>
                                </RowForSwitch>
                                {soldOutDates.until ? (
                                    <Extra>
                                        {`Sold out until ${momentTZ(
                                            soldOutDates.until,
                                        )
                                            .tz(timezone)
                                            .format("LLL")}`}
                                    </Extra>
                                ) : null}
                                <div className="mb12" />
                                <RowForSwitch>
                                    <Label>Show as Upsell</Label>
                                    <Form.Item name="upsell" noStyle>
                                        <Switch
                                            disabled={thirdPartyEnabled}
                                            id="product-edit-up-sell"
                                            onChange={() => {
                                                setUpsell(!upsell);
                                            }}
                                            isOn={upsell}
                                        ></Switch>
                                    </Form.Item>
                                </RowForSwitch>
                                <Extra>
                                    Product will show up on checkout screen in
                                    "People also get" section if product has
                                    image and in stock.
                                </Extra>
                                <div className="mb12" />
                                <RowForSwitch>
                                    <Label>Use As Template</Label>
                                    <Form.Item name="template" noStyle>
                                        <Switch
                                            disabled={thirdPartyEnabled}
                                            id="product-edit-template"
                                            onChange={() => {
                                                setIsTemplate(!isTemplate);
                                            }}
                                            isOn={isTemplate}
                                        ></Switch>
                                    </Form.Item>
                                </RowForSwitch>
                                <div className="mb12" />
                                <RowForSwitch>
                                    <Label>Special Hours</Label>
                                    <Form.Item name="hours" noStyle>
                                        <Switch
                                            disabled={thirdPartyEnabled}
                                            id="product-edit-special-hour"
                                            onChange={() => {
                                                setShowSpecialHour(
                                                    !showSpecialHour,
                                                );
                                            }}
                                            isOn={showSpecialHour}
                                        ></Switch>
                                    </Form.Item>
                                </RowForSwitch>
                                <Extra>
                                    Product is sold only during these hours
                                </Extra>
                                <div className="mb12" />
                                <Form.Item name="hours" noStyle>
                                    <SpecialHourRows
                                        show={showSpecialHour}
                                        specialHours={specialHours}
                                        setSpecialHours={setSpecialHours}
                                        setShowSpecialHour={setShowSpecialHour}
                                        storeHours={storeHours}
                                        storeOpenDays={storeOpenDays}
                                    />
                                </Form.Item>
                                <div className="mb12" />
                            </Form.Item>
                            <Divider />
                            {width < constants.TABLET_MIN_WIDTH ? (
                                <>
                                    <Form.Item
                                        className="customize-item"
                                        label={
                                            thirdPartyEnabled
                                                ? "Modifier Groups"
                                                : "Customize Item"
                                        }
                                    >
                                        <Modifiers
                                            isProductInEditMode={true}
                                        ></Modifiers>
                                    </Form.Item>
                                    {!thirdPartyEnabled && (
                                        <NewModifiers
                                            onClick={() => {
                                                setShowBottomDrawer(true);
                                            }}
                                        >
                                            <span className="new-modifier-group">
                                                New Modifier Groups
                                            </span>
                                            <span className="add">+</span>
                                            <span className="new-modifier-description">
                                                Select or create a new modifier
                                                group
                                            </span>
                                        </NewModifiers>
                                    )}
                                </>
                            ) : (
                                <>
                                    {" "}
                                    <Form.Item
                                        hidden={thirdPartyEnabled}
                                        name="modifierGroups"
                                        label={
                                            !thirdPartyEnabled &&
                                            "Customize Item"
                                        }
                                        className="customize-item"
                                    >
                                        <CopyModifierGroups
                                            modifierGroups={modifierGroups}
                                            setModifierGroups={
                                                setModifierGroups
                                            }
                                            showNewModifierForm={
                                                showNewModifierForm
                                            }
                                            setShowNewModifierForm={
                                                setShowNewModifierForm
                                            }
                                            modifierGroupsOptions={
                                                modifierGroupsOptions
                                            }
                                            setIsModifierGroupInEdit={
                                                setIsModifierGroupInEdit
                                            }
                                        ></CopyModifierGroups>
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            thirdPartyEnabled &&
                                            "Modifier Groups"
                                        }
                                        className="customize-item"
                                    >
                                        <Modifiers
                                            isProductInEditMode={true}
                                        ></Modifiers>
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item
                                hidden={thirdPartyEnabled}
                                className="ingredients"
                                label="Ingredients"
                            >
                                <Ingredients
                                    formattedOptions={formattedOptions}
                                    productInventoryItems={
                                        productInventoryItems
                                    }
                                    setProductInventoryItems={
                                        setProductInventoryItems
                                    }
                                    handleAddIngredients={(
                                        newIngredient: DropdownOption<InventoryItem>,
                                    ) => {
                                        const formatIngredient: ProductIngredientT =
                                            {
                                                inventoryItemId:
                                                    newIngredient.value,
                                                unitsConsumed: {
                                                    amount: 0,
                                                    unit:
                                                        (newIngredient.data
                                                            ?.baseUnit as InventoryUnitType) ??
                                                        "",
                                                    precision: 3,
                                                },
                                            };
                                        const newProductInventoryItems: ProductIngredientT[] =
                                            productInventoryItems
                                                ? productInventoryItems.slice()
                                                : [];
                                        newProductInventoryItems?.push(
                                            formatIngredient,
                                        );
                                        setProductInventoryItems(
                                            newProductInventoryItems,
                                        );
                                    }}
                                    handleUpdateIngredients={(
                                        ingredientId: string,
                                        newPerUnit: number,
                                    ) => {
                                        const newProductInventoryItems:
                                            | ProductIngredientT[]
                                            | undefined =
                                            productInventoryItems?.map(
                                                (item) => {
                                                    if (
                                                        item.inventoryItemId ===
                                                        ingredientId
                                                    ) {
                                                        return {
                                                            inventoryItemId:
                                                                item.inventoryItemId,
                                                            unitsConsumed: {
                                                                ...item.unitsConsumed,
                                                                amount: newPerUnit,
                                                            },
                                                        };
                                                    }
                                                    return item;
                                                },
                                            );

                                        setProductInventoryItems(
                                            newProductInventoryItems,
                                        );
                                    }}
                                    handleRemoveIngredients={(
                                        ingredientId: string,
                                    ) => {
                                        const newProductInventoryItems: ProductIngredientT[] =
                                            productInventoryItems
                                                ? productInventoryItems.slice()
                                                : [];
                                        productInventoryItems?.map(
                                            (item, i) => {
                                                if (
                                                    item.inventoryItemId ===
                                                    ingredientId
                                                ) {
                                                    newProductInventoryItems.splice(
                                                        i,
                                                        1,
                                                    );
                                                }
                                            },
                                        );
                                        setProductInventoryItems(
                                            newProductInventoryItems,
                                        );
                                    }}
                                />
                            </Form.Item>
                            {!thirdPartyEnabled && (
                                <Row className="save-btn-bg">
                                    <Button
                                        type="primary"
                                        className="save-btn"
                                        htmlType="submit"
                                        shape="round"
                                    >
                                        Save Item
                                    </Button>
                                </Row>
                            )}
                        </Form>
                    </>
                )}
                <ModifierBottomDrawer isProductInEditMode={true} />
                <SoldOutModal
                    onHide={() => setIsMarkProductSoldOutModalOpen(false)}
                    handleOk={({ soldOut, soldOutDates }) => {
                        setSoldOut(soldOut);
                        setSoldOutDates(soldOutDates);
                        setIsMarkProductSoldOutModalOpen(false);
                    }}
                    isMarkProductSoldOutModalOpen={
                        isMarkProductSoldOutModalOpen
                    }
                    storeTimeZone={timezone}
                />
                <DeleteProductModal
                    isDeleteProductModalOpen={isDeleteProductModalOpen}
                    setIsDeleteProductModalOpen={setIsDeleteProductModalOpen}
                    handleDeleteItem={handleDeleteItem}
                    productIdToPromotionsMap={productIdToPromotionsMap}
                    productInEdit={productInEdit}
                />
            </Body>
        </ProductEditSharedContext.Provider>
    );
};

const Body = styled.div`
    width: 100%;
    overflow-x: "auto";
    margin-bottom: 10rem;
    position: relative;
    z-index: 5;
    font-family: "Inter" !important;
    .spinnerWrapper {
        margin-top: 50%;
        text-align: center;
    }

    input {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .banner {
        margin-top: 10px;
        border-left: 6px solid ${SystemColors.v1.candy80};
        border-radius: 8px;
    }
    .banner.error {
        margin-top: 10px;
        color: ${SystemColors.v1.melon50};
        border-left: 6px solid ${SystemColors.v1.melon50};
        border-radius: 8px;
    }
    .banner.warning {
        margin-top: 10px;
        color: ${SystemColors.v1.macaroni40};
        border-left: 6px solid ${SystemColors.v1.macaroni40};
        border-radius: 8px;
    }

    @media ${ScreenState.MOBILE} {
        margin-bottom: 10rem;
    }

    .clickable-icon {
        cursor: pointer;
        margin-right: 8px;
    }

    .mt12 {
        margin-top: 12px;
    }
    .mb12 {
        margin-bottom: 12px;
    }

    .mt18 {
        margin-top: 18px;
    }

    .ml24 {
        margin-left: 24px;
    }

    .align-start {
        align-items: flex-start;
        justify-content: flex-start;
    }
    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-col.ant-form-item-label {
        padding-bottom: 10px;
        padding-top: 5px;
    }
    .w100 {
        width: 100%;
    }

    .input-number {
        border-radius: 8px;
        width: 100%;
    }
    .tax::after {
        content: "%";
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 16px;
    }

    .ant-input-number-handler-wrap {
        display: none;
    }

    input {
        border-radius: 8px;
        width: 100%;
    }
    .f2 {
        flex: 2;
    }
    .f1 {
        flex: 1 !important;
    }

    .flexWrap {
        flex-wrap: wrap;
    }

    .choose-photo {
        align-self: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }

    .ant-form label {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #282d32;
        font-size: 16px;
    }

    .ant-row .ant-form-item {
        font-size: 16px;
    }
    .fulfillmentMethod > .ant-col.ant-form-item-label {
        display: none;
    }
    .fulfillmentTaxRate > .ant-col.ant-form-item-label label {
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .fulfillmentTaxRate > .ant-col.ant-form-item-label {
        padding-bottom: 5px;
    }

    .fulfillmentTaxRate > .ant-col.ant-form-item-label {
        padding-bottom: 5px;
    }

    .taxInfo {
        margin-top: 1px;
    }

    .taxRateOverride {
        margin-top: 12px;
    }

    .ant-row.ant-form-item.taxRateOverride {
        margin-bottom: 6px;
    }
    .ant-row.ant-form-item.fulfillmentTaxRate {
        margin: 0px;
    }
    .ant-row.ant-form-item.sales-tax {
        margin: 0px;
    }
    .sales-tax > .ant-col.ant-form-item-label {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .ant-row.ant-form-item.fulfillmentMethod {
        margin: 0px;
    }

    .fulfillmentTaxRateRow {
        justify-content: start;
    }
    input {
        font-size: 16px;
    }
    .ant-input {
        border-radius: 8px;
        ::placeholder {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .fulfillment {
        justify-content: start;
    }

    .category-row {
        margin-bottom: 0;
    }

    .customize-item {
        margin-bottom: 0;
    }

    .ingredients {
        margin-top: 1rem;
    }

    .price {
        position: relative;
    }
    .price input {
        padding-left: 15px;
    }
    .prefix {
        top: 10px;
        left: 4px;
        position: absolute;
        color: #a0adba;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .save-btn {
        flex: auto;
        background: ${SystemColors.v1.candy50};
        color: ${SystemColors.v1.white};
        padding: 2px 14px;
        cursor: pointer;
        height: 39px;
        white-space: nowrap;
        display: inline-block;
        width: 375px;
        flex: 1;
        position: fixed;
        bottom: 10px;
        @media ${ScreenState.MOBILE} {
            bottom: 85px;
            width: 80%;
        }
    }

    .save-btn-bg {
        background-color: ${SystemColors.v2.salt10.light};
        position: sticky;
        position: -webkit-sticky;
        bottom: -30px;
        right: 0px;
        left: 0px;
        padding-top: 20px;
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        @media ${ScreenState.MOBILE} {
            bottom: 50px;
        }
    }

    .ant-btn-primary {
        &:hover {
            color: ${SystemColors.v1.white} !important;
            background: ${SystemColors.v1.candy50} !important;
        }
    }

    .ant-input-number-input {
        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .add {
        font-size: 2rem;
        grid-column: 3;
        padding-left: 1.5rem;
        grid-row: 1;
    }
    .new-modifier-group {
        grid-column: 1;
        margin-left: 1.4rem;
        grid-row: 1;
    }
    .new-modifier-description {
        grid-column: 1/2;
        grid-row: 1;
        margin-left: 1.4rem;
        font-size: 16px;
        padding-top: 3rem;
        color: #606c76;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const Row = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const Label = styled.div`
    display: flex;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #282d32;
`;

const Extra = styled.span`
    color: rgba(96, 108, 118, 1);
    font-family: Inter;
`;

const RowForSwitch = styled(Row)`
    justify-content: space-between;
`;

const HeaderRow = styled.div`
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
    }
    .iconWrapper {
        display: flex;
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 50%;
        text-align: center;
        justify-content: center;
        height: 1.8rem;
        width: 1.8rem;
        margin-left: 5px;
    }

    .head-text-span {
        align-self: center;
        flex: 1;
    }

    .delete-icon {
        height: 1rem;
        width: 1rem;
        align-self: center;
    }
    .duplicate-icon {
        height: 1rem;
        width: 1rem;
        align-self: center;
    }

    .delete-icon path {
        fill: #ff3929;
    }
    .duplicate-icon path {
        fill: #282d32;
    }
`;

const NewModifiers = styled.div`
    font-size: 16px;
    font-family: "Inter";
    display: grid;
    grid-template-columns: auto 1fr 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export default ProductEdit;
