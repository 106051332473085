import { Card, CardContent } from "src/@/components/ui/card";

type Props = {
    children: React.ReactNode;
};

function CampaignCardContent({ children }: Props) {
    return (
        <Card>
            <CardContent className="p-4 lg:p-6">{children}</CardContent>
        </Card>
    );
}

export default CampaignCardContent;
