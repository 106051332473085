import React from "react";
import styled from "styled-components";

import colors from "#reusable/colors/colors.json";
import Text from "#devices/components/Text";

type SettingsSectionProps = {
    label: string;
    description: string;
    children: React.ReactNode;
    disabled?: boolean;
};

const SettingsSectionWrapper = styled.div<{ disabled?: boolean }>`
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin-top: ${({ theme }) => theme.spacing.base};
    margin-bottom: ${({ theme }) => theme.spacing.base};

    :last-child {
        border-bottom: none;
    }
`;

const SettingsFormControlWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SettingsSection = ({
    label,
    description,
    children,
    disabled,
}: SettingsSectionProps): JSX.Element => (
    <SettingsSectionWrapper disabled={disabled}>
        <StyledRow>
            <StyledCol>
                <Text.Label>{label}</Text.Label>
                <Text.Body>{description}</Text.Body>
            </StyledCol>
            <StyledCol>
                <SettingsFormControlWrapper>
                    {children}
                </SettingsFormControlWrapper>
            </StyledCol>
        </StyledRow>
    </SettingsSectionWrapper>
);

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

const StyledCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export default SettingsSection;
