import { SystemColors } from "@snackpass/design-system";
import {
    IProductCategory,
    IPurchase,
    PurchaseReportData,
    PurchaseStatus,
} from "@snackpass/snackpass-types";
import moment from "moment";
import fp from "lodash/fp";

export const sortCateringPurchases = (
    a: PurchaseReportData,
    b: PurchaseReportData,
) => {
    const getDate = (p: PurchaseReportData) =>
        new Date(p.scheduledFor || p.dateReceived);
    return getDate(a).getTime() - getDate(b).getTime();
};

const dateToStr = (date?: Date, includeTime?: boolean) => {
    let result = moment(date).fromNow();
    if (includeTime) {
        result += ", " + moment(date).format("h:mm A");
    }
    return result;
};

export const humanizePurchaseStatus = (
    purchase?: IPurchase,
    includeTime?: boolean,
): { text: string; color: string } | null => {
    if (!purchase) return null;

    if (!purchase?.status?.length)
        return {
            text: `Received ${dateToStr(new Date(purchase.createdAt))}`,
            color: SystemColors.v1.candy50,
        };

    const lastStatus = purchase.status[purchase.status.length - 1];
    if (purchase.refund && !purchase.partialRefund) {
        const date = purchase.refunds.length
            ? purchase.refunds[0].refundedAt
            : lastStatus.createdAt;
        return {
            text: `Refunded ${dateToStr(date, includeTime)}`,
            color: SystemColors.v1.melon50,
        };
    }
    if (lastStatus.type === PurchaseStatus.canceled) {
        return {
            text: `Canceled ${dateToStr(lastStatus.createdAt)}`,
            color: SystemColors.v1.melon50,
        };
    }
    if (purchase.catering.isCatering && purchase.catering.status === "rejected")
        return {
            text: `Rejected ${dateToStr(lastStatus.createdAt)}`,
            color: SystemColors.v1.melon50,
        };
    if (lastStatus.type === PurchaseStatus.completed) {
        return {
            text: `Completed ${dateToStr(lastStatus.createdAt)}`,
            color: SystemColors.v1.parsley50,
        };
    }

    const getTitle = () => {
        let result = "";
        switch (purchase.fulfillment) {
            case "PICKUP":
                result += "Pickup ";
                break;
            case "DELIVERY":
                if (purchase.deliveryInfo?.provider === "store") {
                    result += "Delivery arrives "; // based on the text message to the user (this is the time the delivery arrives)
                } else {
                    result += "Delivery driver en route";
                }
                break;
            case "DINE_IN":
                result += "Dine-in ";
                break;
            default:
                break;
        }
        result += dateToStr(
            purchase.scheduledDate ||
                purchase.pickupTime ||
                (purchase.createdAt as Date),
        );
        return result;
    };

    return {
        text: getTitle(),
        color:
            lastStatus?.type === PurchaseStatus.started
                ? SystemColors.v1.macaroni50
                : SystemColors.v1.candy50,
    };
};

/**
 * Sorts purchase items w.r.t. the order of appearance of their categories in the menu, as well as
 * the order of appearance of the items within their respective categories. This is the same logic that
 * exists server-side for sorting receipt and label items.
 *
 * NB: This function uses `.sort()`, which sorts the array in-place, so the input data will be mutated.
 */
export function sortItemsAccordingToMenu(
    categories: IProductCategory[],
    items: IPurchase["items"],
) {
    return items.toSorted((a, b) => {
        if (!fp.prop("product", a) || !fp.prop("product", b)) {
            return 0;
        }

        const productAID = a.product._id;
        const productBID = b.product._id;

        // Determine if products are in different categories.
        // If so, the product with the category that appears first in the menu should come first.
        const categoryAOrdinal = getCategoryOrdinal(productAID, categories);
        const categoryBOrdinal = getCategoryOrdinal(productBID, categories);
        if (categoryAOrdinal !== categoryBOrdinal) {
            return categoryAOrdinal > categoryBOrdinal ? 1 : -1;
        }

        // If products are in the same category, the product that appears first in the category should come first.
        const productAOrdinal = getProductOrdinal(
            productAID,
            categoryAOrdinal,
            categories,
        );
        const productBOrdinal = getProductOrdinal(
            productBID,
            categoryAOrdinal,
            categories,
        );

        // Preserve original ordering if the category happens to be missing/undefined.
        return productAOrdinal === productBOrdinal
            ? 0
            : productAOrdinal > productBOrdinal
              ? 1
              : -1;
    });
}

function getCategoryOrdinal(productID: string, categories: IProductCategory[]) {
    return categories.findIndex((category) =>
        category.productIds.map((id) => id.toString()).includes(productID),
    );
}

function getProductOrdinal(
    productID: string,
    categoryOrdinal: number,
    categories: IProductCategory[],
) {
    return categories[categoryOrdinal]?.productIds
        .map((id) => id.toString())
        .indexOf(productID);
}
