import produce from "immer";
import React, { useContext } from "react";
import styled from "styled-components";

import Select from "#devices/components/FormControls/Select";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    Layout,
    DEFAULT_CHANNEL_OPTIONS,
    LAYOUT_OPTIONS,
    ChannelType,
} from "#devices/utils/deviceTypes/SnackTVDevice";

const SelectWrapper = styled.div`
    margin-bottom: 20px;
`;

export const EditLayout = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel,
    );

    const value =
        channelOptions?.options?.layout ?? DEFAULT_CHANNEL_OPTIONS.layout;

    return (
        <SelectWrapper>
            <Select
                id="layoutChannelOption"
                label="Layout"
                value={value as string}
                options={LAYOUT_OPTIONS}
                onChange={(e) => {
                    const updatedOptions = produce(deviceOptions, (draft) => {
                        if (draft) {
                            const channelOption = draft.find(
                                (o) => o.channel === channel,
                            );
                            if (!channelOption) {
                                draft.push({
                                    channel: channel as ChannelType,
                                    options: {
                                        layout: e.target.value as Layout,
                                    },
                                });
                            } else {
                                channelOption.options.layout = e.target
                                    .value as Layout;
                            }
                        }
                    });
                    updateDeviceField("channelOptions", updatedOptions);
                }}
            />
        </SelectWrapper>
    );
};
