import { getActiveStore } from "@snackpass/accounting";
import { useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";

import { useGlobalDate } from "#hooks";
import api from "src/api/rest";
import { getStoreTimezone } from "#utils/helpers";
import { DefinedDates } from "#date-picker/lib";
import {
    ActionType,
    payoutHistoryInitialState,
    payoutHistoryReducer,
} from "#financial-reports/screens/payout-history/hooks/usePayoutHistoryReducer";
import { cleanPayoutTableData } from "#financial-reports/screens/payout-history/lib";

export const usePayoutHistoryData = () => {
    const { setGlobalDuration, startDate, endDate } = useGlobalDate();
    const activeStore = useSelector(getActiveStore);
    const timezone = getStoreTimezone(activeStore);
    const [state, dispatch] = useReducer(
        payoutHistoryReducer,
        payoutHistoryInitialState,
    );

    const fetchPayoutData = useCallback(
        async (storeId: string) => {
            try {
                // controller.abort() is to handle the race condition if a
                // request has already been sent.
                // This is due to startDate and endDate not stabilizing on
                // initial render, which would cause multiple requests to be
                // sent.
                if (state.controller) {
                    state.controller.abort();
                }
                const controller = new AbortController();
                dispatch({ type: ActionType.GET_DATA, controller });

                const payouts = await api.stores.getPayoutHistory(
                    storeId,
                    startDate.toDate(),
                    endDate.toDate(),
                    { signal: controller.signal },
                );

                const data = cleanPayoutTableData(payouts, {
                    startDate,
                    endDate,
                    timezone,
                });

                dispatch({ type: ActionType.SET_DATA, data });
            } catch (e: unknown) {
                const axiosError = e as { cause: AxiosError };

                if (
                    axiosError.cause.name !== "CanceledError" &&
                    axiosError.cause.code !== "ERR_CANCELED"
                ) {
                    const errorMessage =
                        axiosError.cause?.message ||
                        "Failed to fetch payout history";
                    dispatch({ type: ActionType.ERROR, errorMessage });
                }
            }
        },
        [
            activeStore?._id,
            startDate.format("MM/DD/YYYY"),
            endDate.format("MM/DD/YYYY"),
        ],
    );

    // Enforce a date range of 30 days when the user first loads the page.
    useEffect(() => {
        setGlobalDuration(DefinedDates.LAST30DAYS);
        dispatch({ type: ActionType.IS_GLOBAL_DURATION_SET });

        return () => {
            setGlobalDuration(DefinedDates.LAST7DAYS);
        };
    }, []);

    // Fetch the data, when global duration has been set OR the user changes
    // the date range.
    useEffect(() => {
        if (activeStore?._id && state.isGlobalDurationSet) {
            void fetchPayoutData(activeStore?._id);
        }
    }, [
        activeStore?._id,
        startDate.format("MM/DD/YYYY"),
        endDate.format("MM/DD/YYYY"),
        state.isGlobalDurationSet,
    ]);

    return state;
};
