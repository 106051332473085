import { Col, InputNumber } from "antd";
import React, { useCallback } from "react";

import { Divider } from "#reusable/divider";
import { Text } from "#reusable/text/index";
import { StyledField, StyledRow } from "#settings/components/shared-component";

import { OrdersFormFieldEnum, RowProps } from "../types";

export const PickupTimeSpecificRow = ({
    setFieldTouched,
    setFieldValue,
    value,
}: RowProps<"defaultPickupTime">) => {
    const onDefaultPickupTimeChange = useCallback(
        (value: number) => {
            setFieldTouched(OrdersFormFieldEnum.defaultPickupTime);
            setFieldValue(OrdersFormFieldEnum.defaultPickupTime, value);
        },
        [setFieldValue, setFieldTouched],
    );

    return (
        <>
            <StyledRow>
                <Col>
                    <Text size="m">Pickup time in minutes</Text>
                </Col>
                <Col span={4}>
                    <StyledField
                        name="defaultPickupTime"
                        type="number"
                        min={0}
                        component={InputNumber}
                        size="large"
                        value={value ?? 15}
                        onChange={onDefaultPickupTimeChange}
                    />
                </Col>
            </StyledRow>
            <Divider />
        </>
    );
};
