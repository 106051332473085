import EmojiPicker from "@emoji-mart/react";
import { FaceSmileIcon, TagIcon } from "@heroicons/react/24/outline";
import { useImperativeHandle, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SegmentedMessage } from "sms-segments-calculator";
import data from "@emoji-mart/data";
import { useRecoilValue } from "recoil";

import { Button } from "src/@/components/ui/button";
import { CardDescription } from "src/@/components/ui/card";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { CharacterCounterTextarea } from "src/@/components/ui/textarea";
import {
    MAX_MESSAGE_LENGTH,
    MAX_MESSAGE_WITH_EMOJI,
} from "#guestbook/screens/Campaigns/NewCampaign/newCampaignFormSchema";
import GuestbookTooltip from "#guestbook/shared-components/GuestbookTooltip";
import { storeDisplayNameAtom } from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";
import CampaignCardContent from "#guestbook/shared-components/CampaignCardContent";

function MessageCardInput() {
    const { control, register } = useFormContext();
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    // See https://react-hook-form.com/faqs "How to share ref usage?"
    const { ref } = register("message");
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    useImperativeHandle(ref, () => textareaRef.current);
    const storeDisplayName = useRecoilValue(storeDisplayNameAtom);
    return (
        <CampaignCardContent>
            <FormField
                control={control}
                name="message"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <p className="text-xl font-semibold">Message</p>
                        </FormLabel>
                        <CardDescription>
                            Your Display Name and required "Reply STOP to
                            unsubscribe" will not count toward your character
                            limit. Do not include URL link shorteners or
                            prohibitive content including alcohol, cannabis, or
                            CBD as this will cause your texts to not be
                            delivered.
                        </CardDescription>
                        <FormControl>
                            <div className="space-y-1 rounded-xl border p-2">
                                <div className="text-sm">
                                    {storeDisplayName}:
                                </div>
                                <CharacterCounterTextarea
                                    {...field}
                                    rows={4}
                                    maxLength={
                                        new SegmentedMessage(
                                            field.value,
                                        ).getNonGsmCharacters().length > 0
                                            ? MAX_MESSAGE_WITH_EMOJI
                                            : MAX_MESSAGE_LENGTH
                                    }
                                    placeholder="Tell customers about a new product, promotion, or any other announcement"
                                    ref={textareaRef}
                                />
                                <div className="text-sm">
                                    Reply STOP to unsubscribe.
                                </div>
                            </div>
                        </FormControl>
                        <FormMessage />
                        <div className="flex flex-row gap-2">
                            <Popover
                                open={emojiPickerOpen}
                                onOpenChange={setEmojiPickerOpen}
                            >
                                <PopoverTrigger asChild>
                                    <Button
                                        variant={"outline"}
                                        size="sm"
                                        type="button"
                                    >
                                        <FaceSmileIcon className="mr-2 h-4 w-4" />
                                        Emoji
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <EmojiPicker
                                        data={data}
                                        onEmojiSelect={(emoji: {
                                            native: string;
                                        }) => {
                                            // Respects the selection of the text area at the time
                                            // the emoji button was pressed. But since the popover blocks
                                            // the textarea anyway, it's unlikely anyone will be counting on this
                                            const textarea =
                                                textareaRef.current;
                                            const selectionStart =
                                                textarea?.selectionStart ??
                                                field.value.length;
                                            const selectionEnd =
                                                textarea?.selectionEnd ??
                                                field.value.length;
                                            field.onChange(
                                                field.value.substring(
                                                    0,
                                                    selectionStart,
                                                ) +
                                                    emoji.native +
                                                    field.value.substring(
                                                        selectionEnd,
                                                    ),
                                            );
                                            setEmojiPickerOpen(false);
                                        }}
                                    />
                                </PopoverContent>
                            </Popover>
                            <GuestbookTooltip
                                tooltip={
                                    "To add a discount, go to the Promos tab and create a promo code. Then copy and paste the promo code into your message. Promo codes can be used on the kiosk and Snackpass app"
                                }
                                className="text-sm"
                            >
                                <Button
                                    variant={"outline"}
                                    size="sm"
                                    type="button"
                                    className="hover:cursor-default"
                                >
                                    <TagIcon className="mr-2 h-4 w-4" />
                                    Promotion
                                </Button>
                            </GuestbookTooltip>
                        </div>

                        <CardDescription>
                            Please note that using emojis or special characters
                            will reduce the overall number of characters
                            available.
                        </CardDescription>
                    </FormItem>
                )}
            />
        </CampaignCardContent>
    );
}

export default MessageCardInput;
