import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

import { FlexRow } from "#menu-editor/multi-menus/styled-components/layout";

export const ButtonsWrapper = styled(FlexRow)`
    @media ${ScreenState.MOBILE} {
        margin-left: 0px;
        gap: 16px;
    }
    justify-content: end;
    margin-left: auto;
    gap: 8px;
`;
