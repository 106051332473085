import { useState } from "react";

type deletedAddons = {
    [key: string]: string[];
};

/**
 * Currently only used for multi menus
 * Although the hook is always used the calls to the thunk with all the
 * logic and calls to the multi menu endpoints are under a feature gate.
 *
 * @param initialState
 * @returns
 */
export const useDeletedAddons = (initialState = {}) => {
    const [deletedAddons, setDeletedAddons] =
        useState<deletedAddons>(initialState);

    const addDeletedAddons = (addonId: string[], addonGroupId: string) => {
        setDeletedAddons((prevDeletedAddons) => {
            const updatedAddonIds: deletedAddons = {
                ...prevDeletedAddons,
            };
            if (updatedAddonIds[addonGroupId])
                updatedAddonIds[addonGroupId].push(...addonId);
            else updatedAddonIds[addonGroupId] = addonId;

            return { ...updatedAddonIds };
        });
    };

    return [deletedAddons, setDeletedAddons, addDeletedAddons] as const;
};
