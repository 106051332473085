import React from "react";
import { Cross2Icon } from "@radix-ui/react-icons";

import { Button } from "src/@/components/ui/button";

type ModalCloseButtonProps = {
    onClose?: () => void;
};

const ModalCloseButton = (props: ModalCloseButtonProps): JSX.Element => (
    <Button
        variant="outline"
        size="icon"
        className="rounded-full p-0"
        onClick={props.onClose}
    >
        <Cross2Icon className="h-4 w-4" />
    </Button>
);

export default ModalCloseButton;
