import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Checkbox } from "src/@/components/ui/checkbox";
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import { SharedTwilioBrandFormSchema } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";
import { useSharedSelectedBrand } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/useSharedSelectedBrand";
import { CampaignRegInlineStripeBilling } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/InlineStripeForm/CampaignRegInlineStripeBilling";

function Subtitle({ text }: { text: string }) {
    return <p className="text-muted-foreground">{text}</p>;
}

function SharedBrandRegistrationReviewAndConfirm() {
    const { getValues, control } =
        useFormContext<z.infer<typeof SharedTwilioBrandFormSchema>>();
    const values = getValues();
    const sharedBrand = useSharedSelectedBrand();
    return (
        <>
            <div className="flex flex-col gap-6">
                <p className="pt-6 text-xl font-semibold">Review and Confirm</p>

                {values.desiredAreaCode ? (
                    <div>
                        <p className="font-semibold">Desired Area Code</p>
                        <Subtitle text={values.desiredAreaCode} />
                    </div>
                ) : (
                    <div>
                        <p className="font-semibold">Shared Phone Number</p>
                        <Subtitle text={sharedBrand!.phoneNumber!} />
                    </div>
                )}
                <div>
                    <p className="font-semibold">Sender Name</p>
                    <Subtitle text={values.displayName} />
                </div>
                <div>
                    <p className="font-semibold">
                        Price per SMS<sup>1</sup>
                    </p>
                    <Subtitle text={"$0.03"} />
                </div>
                <div className="text-xs text-muted-foreground">
                    <ol className="ps-3">
                        <li>
                            SMS messages are limited to 2 segments. Depending on
                            message content such as emojis and special
                            characters, each message can be up to 300 characters
                            including display name and opt-out text.
                        </li>
                    </ol>
                </div>
                <div className="w-full bg-gray-50 p-4 px-6 text-gray-500">
                    <FormField
                        control={control}
                        name="agreedToPricing"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                                <FormControl>
                                    <Checkbox
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                        ref={field.ref}
                                    />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                    I agree to pricing terms.
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <CampaignRegInlineStripeBilling />
            </div>
        </>
    );
}

export default SharedBrandRegistrationReviewAndConfirm;
