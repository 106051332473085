import { Tracker, TrackerProps } from "@tremor/react";
import moment from "moment-timezone";

import {
    NetworkReportStatus,
    NetworkReportPeriod,
} from "#devices/utils/deviceTypes";
import { useAppSelector } from "src/redux/hooks";

type TrackerBlockProps = TrackerProps["data"][number];

const toTrackerColor = (status: NetworkReportStatus): string | undefined => {
    switch (status) {
        case "unknown":
            return "white";
        case "poor":
            return "red";
        case "degraded":
            return "yellow";
        case "stable":
            return "green";
    }
};

const humanReadableStatus = (
    status: NetworkReportStatus,
): string | undefined => {
    switch (status) {
        case "unknown":
            return "No data";
        case "poor":
            return "Poor";
        case "degraded":
            return "Degraded";
        case "stable":
            return "Stable";
    }
};

/**
 * Maps an ISO date string to a relative label, e.g.
 * "2024-03-15T21:00:00.000Z" => "Wed 2pm"
 */
const toTrackerTooltip = (
    period: NetworkReportPeriod,
    timezone: string,
): string =>
    moment.utc(period.label).tz(timezone).format("ddd hA") +
    ` - ${humanReadableStatus(period.status)}`;

const toTrackerData =
    (timezone: string) =>
    (period: NetworkReportPeriod): TrackerBlockProps => ({
        color: toTrackerColor(period.status),
        tooltip: toTrackerTooltip(period, timezone),
    });

type Props = {
    report: NetworkReportPeriod[];
};

export const NetworkConnectivityHistoryChart = ({ report }: Props) => {
    const timezone = useAppSelector(
        (state) => state.activeStore?.hours?.zone ?? "",
    );
    return (
        <div className="mx-auto mt-12 rounded-none">
            <Tracker
                data={report.map(toTrackerData(timezone))}
                className="h-24 rounded-none"
            />
        </div>
    );
};
