import { SystemColors } from "@snackpass/design-system";
import React, { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";

import { setTopUpOnHand, InventoryReport } from "src/redux/slices";
import { getTopUpPersistence } from "src/redux/selectors/inventory";
import { regex } from "#inventory/lib";
import { useAppDispatch } from "src/redux/hooks";

type Props = {
    inventoryReport: InventoryReport;
};

export const TopUp = ({ inventoryReport }: Props) => {
    const getTopUp = useSelector(getTopUpPersistence);
    const dispatch = useAppDispatch();
    const itemId = inventoryReport.inventoryItem.id;
    const itemIdInt = parseInt(itemId, 10);
    const [amount, setAmount] = useState(
        String(getTopUp[itemIdInt] ? getTopUp[itemIdInt] : "0"),
    );

    const _setAmount = (value: string) => {
        if (!regex.test(value)) {
            return;
        }
        setAmount(value);
        dispatch(setTopUpOnHand({ topUp: parseFloat(value), item: itemId }));
    };

    return (
        <div style={styles.view}>
            <input
                value={amount}
                style={styles.topUpInput}
                onChange={(e) => {
                    _setAmount(e.target.value);
                }}
            />
        </div>
    );
};
const styles = {
    view: { flexDirection: "row", display: "flex" } as CSSProperties,
    rightView: {
        display: "flex",
        marginLeft: 5,
    } as CSSProperties,
    topUpInput: {
        width: 50,
        height: 30,
        borderRadius: 4,
        padding: 2,
    },
    btn: {
        backgroundColor: SystemColors.v1.candy60,
        color: SystemColors.v1.white,
        textAlign: "left",
        marginRight: 5,
    } as CSSProperties,
};
