import React from "react";
import styled from "styled-components";

import useWindowDimensions from "#hooks/use-window-dimensions";

const DrawerFooter = styled.div<{ isMobile: boolean }>`
    margin-bottom: ${({ isMobile }) => (isMobile ? "55px" : "0")};
`;

export const DetailsDrawerFooter = () => {
    const { isMobile } = useWindowDimensions();
    return <DrawerFooter isMobile={isMobile} />;
};
