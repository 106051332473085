import React, { useContext } from "react";

import { DeviceType } from "#devices/utils/deviceTypes";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

import OrderHubEdit from "./OrderHubEdit";
import KDSEdit from "./KDSEdit";
import KioskEdit from "./KioskEdit";
import StripeTerminalEdit from "./StripeTerminalEdit";
import SnackTvEdit from "./SnackTvEdit";
import PrinterEdit from "./PrinterEdit";
import UnknownEdit from "./UnknownEdit";

const DeviceEditView = (): JSX.Element => {
    const { device } = useContext(DevicesPageContext);

    switch (device?.deviceType) {
        case DeviceType.OrderHub:
            return <OrderHubEdit />;
        case DeviceType.KDS:
            return <KDSEdit />;
        case DeviceType.Kiosk:
            return <KioskEdit />;
        case DeviceType.StripeTerminal:
            return <StripeTerminalEdit />;
        case DeviceType.SnackTV:
            return <SnackTvEdit />;
        case DeviceType.Printer:
            return <PrinterEdit />;
        default:
            return <UnknownEdit device={device} />;
    }
};

export default DeviceEditView;
