import React from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import { ReactComponent as CheckIcon } from "src/assets/icons/checked.svg";

type ToggleProps = {
    disabled?: boolean;
    id: string;
    checked?: boolean;
    required?: boolean;
    onChange: (e: any) => void;
    bigTap?: boolean;
};

// hide the actual html input
const StyledToggle = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
`;

const StyledToggleLabel = styled.label<{
    checked?: boolean;
    disabled?: boolean;
    bigTap?: boolean;
}>`
    cursor: pointer;
    margin-bottom: ${({ theme }) => theme.spacing.double};
    width: ${({ theme }) => theme.spacing.triple};
    height: ${({ theme, bigTap }) => (bigTap ? theme.spacing.double : "28px")};
    background-color: ${({ checked }) =>
        checked ? SystemColors.v2.candy50.light : SystemColors.v2.salt50.light};
    display: inline-block;
    border-radius: 17px;
    position: relative;
    padding: 2px;
    user-select: none;
    transition: background-color 0.2s ease-in-out;

    /* Disabled state */
    ${(props) =>
        props.disabled &&
        `
          cursor: not-allowed;
          opacity: .65;
          pointer-events: none;
        `}
`;

const ToggleCircle = styled.span<{
    bigTap?: boolean;
    checked?: boolean;
}>`
    width: ${({ theme, bigTap }) =>
        bigTap ? "28px" : theme.spacing.baseAndAHalf};
    height: ${({ theme, bigTap }) =>
        bigTap ? "28px" : theme.spacing.baseAndAHalf};
    border-radius: ${({ theme }) => theme.spacing.baseAndAHalf};
    background: ${SystemColors.v2.salt10.light};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: ${({ checked }) =>
        checked ? "calc(100% - 2px)" : "calc(50% + 2px)"};
    transform: translateX(-100%);
    transition: left 0.2s ease-in-out;
`;

const Toggle = ({
    id,
    checked,
    disabled,
    onChange,
    bigTap,
}: ToggleProps): JSX.Element => (
    <>
        <StyledToggle
            id={id}
            checked={checked}
            type="checkbox"
            onChange={onChange}
        />
        <StyledToggleLabel
            htmlFor={id}
            disabled={disabled}
            checked={checked}
            bigTap={bigTap}
        >
            <ToggleCircle bigTap={bigTap} checked={checked}>
                <CheckIcon
                    fill={
                        checked
                            ? SystemColors.v2.candy50.light
                            : SystemColors.v2.salt10.light
                    }
                />
            </ToggleCircle>
        </StyledToggleLabel>
    </>
);

export default Toggle;
