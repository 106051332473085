import { IStore } from "@snackpass/snackpass-types";

/**
 * Depending on which type you use, certain data
 * gets passed into the checkItem function or the checkItems function.
 * See below for details, if singular checkItem is called, if plural checkItems is called.
 *
 * "store" -> checkItem(data: IStore)
 * "product" -> checkItem(data: IProduct)
 * "all-products" -> checkItems(data: IProduct[])
 * "addon" -> checkItem(data: Addon)
 * "all-addons" -> checkItems(data: Addon[])
 * "addonGroup" -> checkItem(data: AddonGroup)
 * "all-addonGroups" -> checkItems(addonGroups: AddonGroup[])
 *
 */

export type ItemType =
    | "store"
    | "product-category"
    | "product"
    | "all-products"
    | "addon"
    | "all-addons"
    | "addonGroup"
    | "all-addonGroups";

type Severity = "warning" | "critical";

export class MenuIssue<T> {
    public type: string;
    public description: string;
    public severity: Severity;
    public item: T;

    constructor(
        type: string,
        description: string,
        severity: Severity,
        item: T,
    ) {
        this.type = type;
        this.description = description;
        this.severity = severity;
        this.item = item;
    }
}

export interface IMenuAutomationCheck<T> {
    store: IStore;
    name: string;
    itemToCheck: ItemType;

    checkItem(
        // the data depends on the item type you specify see comment above ItemType
        // for details
        data: T,
    ): (MenuIssue<T> | null) | Promise<MenuIssue<T> | null>;

    checkItems(
        // the data depends on the item type you specify see comment above ItemType
        // for details
        data: T[],
    ): MenuIssue<T>[] | Promise<MenuIssue<T>[]>;
}
