import React, { useEffect, useMemo } from "react";
import { AccessorKeyColumnDef } from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { useGetAuditLogs } from "#guestbook/screens/audit-log/use-get-audit-logs";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { DataTable } from "src/@/components/ui/data-table/";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import { toast } from "sonner";
import { toDollarFormatted } from "#reports/sales-summary/lib";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
import { getActiveStore } from "@snackpass/accounting";
import { useSelector } from "react-redux";
import { getFormattedLongDate } from "#devices/components/DevicesTable/lib";

interface TableRow {
    action: string;
    editingUserName: string;
    time: Date;
}

const displayFieldChanged = (field: string) => {
    if (field === "pointsBalance") {
        return "point balance";
    } else if (field === "credit") {
        return "store credit";
    }
    return field;
};

const displayValue = (field: string, value: number) =>
    field === "credit" ? toDollarFormatted(value) : value;

const AuditLogTable = () => {
    const { data, isFetching, error } = useGetAuditLogs();
    const activeStore = useSelector(getActiveStore);
    const timezone = activeStore?.hours.zone ?? SnackpassTimezoneEnum.newYork;

    useEffect(() => {
        if (error) {
            toast.error(`Couldn't get audit logs right now. Try again later.`);
            console.error(error?.message);
        }
    }, [error]);

    const columns: AccessorKeyColumnDef<TableRow, string | number>[] = [
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-2"
                    column={column}
                    title="Action"
                />
            ),
            accessorKey: "action",
            id: "Action",
            cell: (props) => (
                <div className="min-w-max">{props.row.original.action}</div>
            ),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-96"
                    column={column}
                    title="Edited By"
                />
            ),
            accessorKey: "editingUserName",
            id: "Edited By",
            cell: (props) => (
                <div className="ml-96">
                    {props.row.original.editingUserName}
                </div>
            ),
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2"
                    column={column}
                    title="Time Modified"
                />
            ),
            accessorKey: "time",
            id: "Time Modified",
            cell: (props) => (
                <div>
                    {getFormattedLongDate(props.row.original.time, timezone)}
                </div>
            ),
        },
    ];

    const rows = useMemo(
        () =>
            isFetching
                ? []
                : data.map((item: any) => ({
                      action: `${item.punchcard.user.name}'s ${displayFieldChanged(item.field)} changed from ${displayValue(item.field, item.oldValue.value)} to ${displayValue(item.field, item.newValue.value)}.`,
                      editingUserName: item.user.name,
                      time: item.updatedAt,
                  })),
        [data, isFetching],
    );

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            global: true,
            placeholder: "Search...",
        },
    };

    return (
        <div className="mb-20">
            <div className="overflow-x-scroll">
                {!isFetching && (error || data) ? (
                    data ? (
                        <DataTable
                            columns={columns}
                            data={rows}
                            showPagination
                            toolbar={toolbarOptions}
                            hiddenColumns={[
                                "Editing User's ID",
                                "Modified User's ID",
                            ]}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default AuditLogTable;
