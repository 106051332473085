import { useAppSelector } from "src/redux/hooks";
import { getUserFeeSettingsPermission } from "src/redux/selectors";
import { Switch } from "src/@/components/ui/switch";

export const FeeSwitch = ({
    checked,
    onChange,
    disabled = false,
}: {
    checked: boolean;
    onChange: (c: boolean) => void;
    disabled?: boolean;
}) => {
    const writeAccess = useAppSelector(getUserFeeSettingsPermission);
    return (
        <Switch
            disabled={!writeAccess || disabled}
            checked={checked}
            onCheckedChange={onChange}
        />
    );
};
