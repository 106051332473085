import removeAccents from "remove-accents";
import { get } from "fast-levenshtein";
import { Color } from "@tremor/react";

import { CHART_COLORS } from "#reports/location-sales/lib";
import {
    LocationMenuGenericRow,
    LocationMenuItemRow,
    LocationMenuMatchedRow,
} from "#reports/location-menu-categories/types";

export const matchMenuRowsToColors = <
    T extends LocationMenuGenericRow[] | LocationMenuItemRow[],
>(rows: {
    [storeId: string]: T;
}) => {
    const colorByMatchedRowMap: {
        [name: string]: Color;
    } = {};

    const matchedRows: {
        [storeId: string]: LocationMenuMatchedRow[];
    } = Object.entries(rows).reduce(
        (acc, [storeId, categories]) => {
            acc[storeId] = categories.map((e) => {
                const matchingCategoryColorKey = Object.keys(
                    colorByMatchedRowMap,
                ).find((name) => matchRowNames(e.name, name));
                if (matchingCategoryColorKey) {
                    return {
                        ...e,
                        color: colorByMatchedRowMap[matchingCategoryColorKey],
                    };
                }
                const colorIndex = Object.values(colorByMatchedRowMap).length;
                colorByMatchedRowMap[e.name] =
                    CHART_COLORS[colorIndex % CHART_COLORS.length].color;
                return {
                    ...e,
                    color: colorByMatchedRowMap[e.name],
                };
            });
            return acc;
        },
        {} as { [storeId: string]: LocationMenuMatchedRow[] },
    );

    return {
        matchedRows,
        colorByMatchedRowMap,
    };
};

// if the # of character edits required is within this % of the length of the string, count it as a match.
const STRING_DISTANCE_THRESHOLD = 0.2;

// if the # of character edits exceeds this amount of characters, then disregard above threshold
const STRING_DISTANCE_THRESHOLD_FLAT = 5;

export const cleanStrings = (strings: string[]) =>
    strings.map((e) => {
        // Remove non alphanumeric characters
        const cleanedStr = removeAccents(e)?.replace(/\W/g, "");

        // lower case
        return cleanedStr.toLowerCase();
    });

export const doesQueryMatch = (word: string, query: string, fuzzy = false) => {
    if (!word) return false;

    // Clean the word and query strings
    const [cleanedWord, cleanedQuery] = cleanStrings([word, query]);

    if (!fuzzy) {
        return cleanedWord.includes(cleanedQuery);
    } else {
        // iterate through the word and check if substrings
        // that are the same length as the query have a Levenshtein distance
        // less than threshold
        const queryLength = cleanedQuery.length;
        for (let i = 0; i <= cleanedWord.length - queryLength; i++) {
            const substring = cleanedWord.slice(i, i + queryLength);
            if (matchRowNames(substring, cleanedQuery)) {
                return true;
            }
        }
        return false;
    }
};

const matchRowNames = (str1: string, str2: string): boolean => {
    // Remove accents
    const [normalizedStr1, normalizedStr2] = cleanStrings([str1, str2]);

    // Check if the strings are equal
    if (normalizedStr1 === normalizedStr2) {
        return true;
    }
    const length = Math.max(normalizedStr1.length, normalizedStr2.length);

    // the Levenshtein distance algorithm measures the # of substitutions / insertions / deletions required to turn
    // one string into another.
    const dist = get(normalizedStr1, normalizedStr2);
    return (
        dist / length <= STRING_DISTANCE_THRESHOLD &&
        dist < STRING_DISTANCE_THRESHOLD_FLAT
    );
};

export const aggregateItemsByLocationByCategory = (data: {
    [storeId: string]: LocationMenuItemRow[];
}) =>
    Object.entries(data).reduce(
        (aggregatedData, [storeId, menuItems]) => {
            const aggregatedCategories = aggregateItemsByCategory(menuItems);
            aggregatedData[storeId] = aggregatedCategories;
            return aggregatedData;
        },
        {} as { [storeId: string]: LocationMenuGenericRow[] },
    );

const aggregateItemsByCategory = (
    menuItems: LocationMenuItemRow[],
): LocationMenuGenericRow[] => {
    const categoryMap = menuItems.reduce(
        (map, menuItem) => {
            const { category, netSales, orders } = menuItem;

            if (category in map) {
                map[category].netSales += netSales;
                map[category].orders += orders;
            } else {
                map[category] = {
                    name: category,
                    netSales,
                    orders,
                };
            }

            return map;
        },
        {} as { [key: string]: LocationMenuGenericRow },
    );

    return Object.values(categoryMap);
};
