import { IStore } from "@snackpass/snackpass-types";

import { ConvenienceFormValues } from "#settings/settings-fees/utils/types";

export const convenienceFormDefaults = (
    store: IStore,
): ConvenienceFormValues => {
    const appPolicy = store.convenienceFeePolicies.find(
        (p) => p.transactionSource === "app",
    );
    const kioskPolicy = store.convenienceFeePolicies.find(
        (p) => p.transactionSource === "kiosk",
    );
    const onlinePolicy = store.convenienceFeePolicies.find(
        (p) => p.transactionSource === "online",
    );

    return {
        app: appPolicy ? appPolicy.value : 0,
        kiosk: kioskPolicy ? kioskPolicy.value : 0,
        online: onlinePolicy ? onlinePolicy.value : 0,
    };
};
