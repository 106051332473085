import { useMemo } from "react";

import { useAppSelector } from "src/redux/hooks";
import { getLegacyProducts } from "src/redux/selectors";
import { ProductEssentials } from "#menu/domain";

/**
 * Provides essential product data from the currently viewed menu.
 */
export function useProductEssentials() {
    const legacyProducts = useAppSelector(getLegacyProducts);

    const legacyProductsProjection = useMemo(
        () =>
            legacyProducts.map((product) => ({
                ...product,
                categoryName: product.category,
            })),
        [legacyProducts],
    );

    return useMemo(
        () =>
            legacyProductsProjection.reduce<ProductEssentials[]>(
                (acc, product) => {
                    if (!product.categoryId) return acc;
                    acc.push({
                        id: product._id,
                        name: product.name,
                        categoryId: product.categoryId,
                        categoryName: product.categoryName,
                    });
                    return acc;
                },
                [],
            ),
        [legacyProductsProjection],
    );
}
