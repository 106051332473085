import React, { useContext } from "react";
import { PlusIcon } from "@radix-ui/react-icons";
import styled from "styled-components";

import { Button } from "src/@/components/ui/button";
import Text from "#devices/components/Text";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";

const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 30vh;
`;

export const EmptyState = (): JSX.Element => {
    const { setAddNewDevicesModalOpen, currentFilters } =
        useContext(DevicesPageContext);

    const handleAddDeviceClick = () => {
        setAddNewDevicesModalOpen(true);
    };

    const { isMobile } = useWindowDimensions();

    return (
        <EmptyStateWrapper>
            <div>
                <Text.Title2>
                    {currentFilters.deviceType.map(
                        (dt) => `No ${getDeviceTypeName(dt)}s`,
                    )}
                </Text.Title2>
                <Text.LargeBody>
                    Devices you add like Kiosks or Order Hubs will appear here.
                </Text.LargeBody>
                {!isMobile && (
                    <Button
                        className="rounded-full sm:rounded-md"
                        onClick={handleAddDeviceClick}
                    >
                        <PlusIcon className="h-5 w-5 fill-white" />
                        <span className="hidden: ml-2  hidden sm:block">
                            Add Device
                        </span>
                    </Button>
                )}
            </div>
        </EmptyStateWrapper>
    );
};
