import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import { Input } from "src/@/components/ui/input";
import {
    useEditorState,
    useEditorStore,
} from "#table-editor/components/Editor/EditorProvider";
import { Button } from "src/@/components/ui/button";

type NewSectionDialogProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
};

export function NewSectionDialog({
    open,
    onOpenChange,
}: NewSectionDialogProps) {
    const editor = useEditorStore();

    const serviceAreas = useEditorState((state) => state.serviceAreas);

    const existingServiceAreas = useMemo(
        () => new Set(serviceAreas.map((area) => area.name)),
        [serviceAreas],
    );

    const schema = z.object({
        name: z
            .string()
            .min(1)
            .refine(
                (val) => !existingServiceAreas.has(val),
                "Section name must be unique",
            ),
    });

    const { formState, register, handleSubmit } = useForm({
        resolver: zodResolver(schema),
        values: {
            name: "",
        },
        mode: "onChange",
    });

    const submit = handleSubmit(({ name }) => {
        editor.getState().addServiceArea(name);
        onOpenChange(false);
    });

    return (
        <Dialog modal open={open} onOpenChange={onOpenChange}>
            <DialogContent className="p-0">
                <DialogHeader className="px-6 pt-6">
                    <DialogTitle>New Section</DialogTitle>
                    <DialogDescription>
                        Sections define groups of tables within your floor plan
                    </DialogDescription>
                </DialogHeader>
                <div className="px-6">
                    <Input
                        {...register("name")}
                        placeholder="Name (ex. Patio)"
                        className="w-full text-black placeholder:text-neutral-500"
                    />
                    {formState.errors.name ? (
                        <p className="text-small text-critical-a11y-light">
                            {formState.errors.name.message}
                        </p>
                    ) : null}
                </div>
                <DialogFooter className="border-t border-t-neutral-400 px-6 py-3">
                    <Button type="button" variant="default" onClick={submit}>
                        Create Section
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
