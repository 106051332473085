import styled from "@emotion/styled";
import { ScreenState } from "@snackpass/snackpass-types";
import { useFormContext } from "react-hook-form";

import { FormNumberInput } from "#promotion/components/shared/form-number-input";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import { FIELD_NAMES } from "#promotion/utils/types";
import colors from "#reusable/colors/colors.json";

type Props = {
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
};

type ContainerProps = {
    divider: boolean;
};

export const LimitTotalUses = ({
    required,
    recommended,
    autofilled,
}: Props) => {
    const { watch } = useFormContext();
    const limitTotalUsesEnabled = watch(FIELD_NAMES.LIMIT_USES);

    return (
        <Container>
            <ToggleContainer divider={limitTotalUsesEnabled}>
                <FormToggle
                    name="Limit Total Uses"
                    descriptor="Limit how many times this promo can be used"
                    fieldName={FIELD_NAMES.LIMIT_USES}
                    required={required}
                    recommended={recommended}
                    autofilled={autofilled}
                />
            </ToggleContainer>
            {limitTotalUsesEnabled ? (
                <InputContainer>
                    <FormNumberInput
                        name="Total Uses"
                        fieldName={FIELD_NAMES.TOTAL_USES}
                        required={required}
                        recommended={recommended}
                        autofilled={autofilled}
                    />
                </InputContainer>
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin-bottom: 24px;
    @media ${ScreenState.MOBILE} {
        margin-bottom: 0;
    }
`;

const ToggleContainer = styled.div<ContainerProps>`
    ${({ divider }) =>
        divider &&
        `margin-bottom: 24px;
    border-bottom: 1px solid ${colors["neutral-400"]};`}
`;

const InputContainer = styled.div`
    margin-bottom: 24px;
`;
