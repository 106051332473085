import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Legend } from "@tremor/react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType,
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import {
    formatHour,
    formatNumber,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import { ReportsContext } from "#app/reports-context-provider";
import { TopLocationsTooltip } from "#reports/location-sales/components/TopLocationsTooltip";
import { loopColorsToLength } from "#reports/location-sales/lib";
import { calculateChartWidth } from "#utils/helpers";
import { ChartConfig, ChartContainer } from "src/@/components/ui/chart";

import { ShowLegendSwitch } from "./ShowLegendSwitch";

const chartConfig = {} satisfies ChartConfig;

const LocationSalesHourlyBarChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { locationReportsData, filter, stores } = reportsState;
    const data = locationReportsData?.salesHourlyAggregateReportData;

    const [graphType, setGraphType] = useState(ChartType.NET_SALES);
    const [showLegend, setShowLegend] = useState(false);

    const valueFormatter =
        graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    const colors = useMemo(
        () => loopColorsToLength(filter.storeIds.length),
        [filter],
    );

    const storeIdToName = useMemo(
        () =>
            stores.reduce<Record<string, string>>((acc, store) => {
                acc[store._id] = store.name;
                return acc;
            }, {}),
        [stores],
    );

    const categories = useMemo(
        () => filter.storeIds.map((id) => storeIdToName[id] ?? ""),
        [filter, stores],
    );

    const chartData = useMemo(
        () =>
            Object.entries(data || {})
                .filter(
                    ([id, _]) =>
                        filter.storeIds.includes(id) ||
                        filter.storeIds.length == 0,
                )
                .reduce<Record<string, PayloadType>[]>(
                    (acc, [id, storeData]) => {
                        const storeName = storeIdToName[id] ?? "";
                        storeData.forEach((e, idx) => {
                            const value =
                                graphType == ChartType.NET_SALES
                                    ? e.netSales
                                    : e.orders;
                            const hourData = acc[idx] ?? {
                                label: formatHour(idx),
                            };
                            acc[idx] = {
                                ...hourData,
                                [storeName]: value,
                                // passing in the value formatter here so the tooltip has access to it
                                valueFormatter,
                            };
                        });

                        return acc;
                    },
                    [],
                ),
        [data, graphType, valueFormatter, filter, storeIdToName],
    );

    const yWidth = (): number => {
        let maxValue = -Infinity;

        chartData.forEach((obj) => {
            Object.values(obj).forEach((value) => {
                if (typeof value === "number") {
                    maxValue = Math.max(value, maxValue);
                }
            });
        });

        return calculateChartWidth(maxValue, true);
    };

    return (
        <div className="my-10">
            <div
                className={`flex items-center justify-between ${
                    !showLegend ? "mb-4" : ""
                }`}
            >
                <div className="flex flex-col justify-center sm:flex-row">
                    <h4 className="text-large sm:mr-5">
                        Time of Day by Location
                    </h4>
                    <ShowLegendSwitch
                        value={showLegend}
                        onChange={setShowLegend}
                    />
                </div>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            {showLegend ? (
                <Legend
                    categories={categories}
                    colors={colors}
                    className="mb-6 p-0"
                />
            ) : null}
            <div className="h-96 w-full overflow-x-scroll">
                {!locationReportsData?.salesReportDataLoading ? (
                    locationReportsData?.salesHourlyAggregateReportData ? (
                        <ChartContainer
                            className="h-full w-fit md:w-full"
                            config={chartConfig}
                        >
                            <BarChart accessibilityLayer data={chartData}>
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={valueFormatter}
                                    width={yWidth()}
                                />
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="label"
                                    tickLine={false}
                                    axisLine={false}
                                    interval={1}
                                />
                                <Tooltip content={TopLocationsTooltip} />
                                {categories.map((category, idx) => (
                                    <Bar
                                        stackId="stack"
                                        key={category}
                                        dataKey={category}
                                        // These colors are in our safelist!
                                        className={`fill-${colors[idx]}-500`}
                                        /* Fill is necessary here because the tooltip
                                        needs it to be set in order to access the `color` attribute */
                                        fill={colors[idx]}
                                        strokeWidth={2}
                                        radius={4}
                                    />
                                ))}
                            </BarChart>
                        </ChartContainer>
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
            <h6 className="mt-3 text-small text-neutral-500">
                Time shown is in store's time zone
            </h6>
        </div>
    );
};

type PayloadType = number | string | ((e: number) => string);

export default LocationSalesHourlyBarChart;
