import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { getActiveStore } from "@snackpass/accounting";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { useState } from "react";
import { ArrowLeftIcon, ReloadIcon } from "@radix-ui/react-icons";
import { NumberInput } from "@tremor/react";
import { parsePhoneNumber } from "libphonenumber-js";

import api from "src/api/rest";
import { Input } from "src/@/components/ui/input";
import { Button } from "src/@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";

const CustomerSchema = z
    .object({
        name: z.string().min(1, { message: "Must enter a name" }),
        phoneNumber: z.string().min(1, { message: "Phone number is required" }),
        email: z.string(),
        pointsBalance: z.coerce.number({
            invalid_type_error: "Must enter a point balance value",
        }),
    })
    .refine(
        (data) => {
            try {
                const phone = parsePhoneNumber(data.phoneNumber || "", "US");
                return data.phoneNumber !== "" && phone.isValid();
            } catch (err) {
                console.error(err);
            }
            // phone number is required
            return false;
        },
        {
            message: "Phone number invalid",
            path: ["phoneNumber"],
        },
    );

const defaultValues = {
    name: "",
    phoneNumber: "",
    email: "",
    pointsBalance: 0,
    birthday: "",
};

export const AddCustomerPage = ({
    setPage,
}: {
    setPage: (show: boolean) => void;
}) => {
    const activeStore = useSelector(getActiveStore);
    const [isLoading, setIsLoading] = useState(false);

    const customerForm = useForm<z.infer<typeof CustomerSchema>>({
        resolver: zodResolver(CustomerSchema),
        defaultValues: defaultValues,
    });

    const submitCustomer = async (values: z.infer<typeof CustomerSchema>) => {
        try {
            setIsLoading(true);
            await api.punchCards.createPunchcard(
                activeStore?._id ?? "",
                values,
            );
            toast.success("Customer added successfully.");
        } catch (e) {
            console.error(e);
            toast.error(
                // @ts-expect-error error is untyped
                `Could not add customer. ${e.cause.response.data.message}`,
            );
        } finally {
            setIsLoading(false);
            resetForm();
        }
    };

    const resetForm = () => {
        customerForm.reset(defaultValues);
    };

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...customerForm}>
                <form
                    onSubmit={customerForm.handleSubmit(submitCustomer)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Add Customer
                        </CardTitle>
                        <Button
                            className="mt-2"
                            variant="outline"
                            type="button"
                            onClick={() => {
                                setPage(false);
                            }}
                        >
                            <ArrowLeftIcon className="mr-2" /> Go Back
                        </Button>
                        <hr className="border-gray-300" />
                    </div>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Name</CardTitle>
                            <CardDescription>
                                This is the name that will be displayed on their
                                profile and in marketing messages.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={customerForm.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <FormControl>
                                                <div>
                                                    <Input
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="Customer name..."
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                        <div className="flex items-center justify-between rounded-b-xl border-t border-neutral-400 bg-neutral-100 px-6 py-4">
                            <div className="text-small text-neutral-600">
                                When a customer creates an account, we’ll update
                                the name with the one they provide.
                            </div>
                        </div>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Contact Information
                            </CardTitle>
                            <CardDescription>
                                Please enter the contact information that you
                                want to associate with this account.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={customerForm.control}
                                name="phoneNumber"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Phone Number
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Phone number is required.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-2">
                                                    <Input
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="Phone..."
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={customerForm.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="pt-4 text-base font-medium">
                                                Email
                                            </CardTitle>
                                            <FormControl>
                                                <div className="pt-0.5">
                                                    <Input
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="Email..."
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                        <div className="flex items-center justify-between rounded-b-xl border-t border-neutral-400 bg-neutral-100 px-6 py-4">
                            <div className="text-small text-neutral-600">
                                You will not be able to change contact
                                information. If you need to, contact Snackpass
                                Support.
                            </div>
                        </div>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Point Balance
                            </CardTitle>
                            <CardDescription>
                                This is the number of loyalty points this
                                customer will start out with.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={customerForm.control}
                                name="pointsBalance"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <FormControl>
                                                <div>
                                                    <NumberInput
                                                        placeholder="0"
                                                        min={0}
                                                        className="flex-1 [&>input]:pl-2"
                                                        icon={() => (
                                                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                points
                                                            </div>
                                                        )}
                                                        value={field.value}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                Number.parseFloat(
                                                                    e.target
                                                                        .value ??
                                                                        0,
                                                                ),
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                        <div className="flex items-center justify-between rounded-b-xl border-t border-neutral-400 bg-neutral-100 px-6 py-4">
                            <div className="text-small text-neutral-600">
                                You can adjust this from a customer profile at
                                any time.
                            </div>
                        </div>
                    </Card>
                    {customerForm.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-gray-50 p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};
