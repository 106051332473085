import { IProduct, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

const alwaysUpsold: string[] = [
    "Chips and Salsa",
    "Chips And Salsa",
    "Chips and Guacamole",
    "Chips And Guac",
    "House Coffee",
    "Fries",
];

class TypicalUpsellsCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "TypicalUpsellsCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems(): MenuIssue<IProduct>[] {
        return [];
    }

    checkItem(product: IProduct): MenuIssue<IProduct> | null {
        for (let i = 0; i < alwaysUpsold.length; i++) {
            if (
                product.name.includes(alwaysUpsold[i]) &&
                product.upsell === false
            ) {
                return new MenuIssue<IProduct>(
                    "MissingTypicalUpsells",
                    `${alwaysUpsold[i]} should always be upsold`,
                    "warning",
                    product,
                );
            }
        }
        return null;
    }
}

export { TypicalUpsellsCheck };
