import { Col, Row } from "antd";
import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { IncludesTypes } from "#menu-editor/multi-menus/redux/types";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import colors from "#reusable/colors/colors.json";

import { PriceAdjustmentType } from "../types";

interface Props {
    setAdjustment: Dispatch<SetStateAction<number>>;
    adjustment: number;
    setIncludes: Dispatch<SetStateAction<IncludesTypes>>;
    includes: IncludesTypes;
    setAmount: Dispatch<SetStateAction<number | null>>;
    amount: number | null;
    isMobile: boolean;
}

export const DropDownSelectAdjustment: React.FC<Props> = ({
    adjustment,
    setAdjustment,
    includes,
    setIncludes,
    amount,
    setAmount,
    isMobile,
}) => (
    <SelectWrapper>
        <Row className="mt16" gutter={isMobile ? [8, 8] : [16, 16]}>
            <Col span={isMobile ? 24 : 8}>
                <FormControl fullWidth className="form-selector">
                    <InputLabel className="materia-label">
                        Adjustment
                    </InputLabel>
                    <Select
                        disableUnderline
                        className="materia-selector"
                        value={adjustment}
                        label="Adjustment"
                        onChange={(e) =>
                            setAdjustment(e.target.value as number)
                        }
                        IconComponent={ChevronDown}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        <MenuItem
                            className="selector-item"
                            value={PriceAdjustmentType.Markup}
                        >
                            Markup
                        </MenuItem>
                        <MenuItem
                            className="selector-item"
                            value={PriceAdjustmentType.Markdown}
                        >
                            Markdown
                        </MenuItem>
                    </Select>
                </FormControl>
            </Col>
            <Col span={isMobile ? 24 : 8}>
                <FormControl fullWidth className="form-selector">
                    <InputLabel className="materia-label">Includes</InputLabel>
                    <Select
                        disableUnderline
                        className="materia-selector"
                        value={includes}
                        label="Include"
                        onChange={(e) =>
                            setIncludes(e.target.value as IncludesTypes)
                        }
                        IconComponent={ChevronDown}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        <MenuItem className="selector-item" value={"items"}>
                            Items
                        </MenuItem>
                        <MenuItem className="selector-item" value={"modifiers"}>
                            Modifiers
                        </MenuItem>
                        <MenuItem className="selector-item" value={"both"}>
                            Both
                        </MenuItem>
                    </Select>
                </FormControl>
            </Col>
            <Col span={isMobile ? 24 : 8}>
                <FormControl
                    fullWidth
                    variant="standard"
                    className="form-selector"
                >
                    <InputLabel className="materia-label">Amount</InputLabel>
                    <Input
                        className={`materia-selector ${
                            amount === null || isNaN(amount) ? "no-input" : ""
                        }`}
                        disableUnderline
                        type="number"
                        value={amount}
                        onChange={(e) => {
                            let value = parseFloat(e.target.value);
                            if (isNaN(value)) {
                                setAmount(null);
                                return;
                            }
                            if (
                                adjustment === PriceAdjustmentType.Markup &&
                                value > 999
                            )
                                value = 999;
                            if (
                                adjustment === PriceAdjustmentType.Markdown &&
                                value > 100
                            )
                                value = 100;
                            if (value < 0) value = 0;
                            setAmount(value);
                        }}
                        endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                        }
                    />
                </FormControl>
            </Col>
        </Row>
    </SelectWrapper>
);

const SelectWrapper = styled.div`
    background: #ffffff;

    .MuiInputBase-root {
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 8px;
        font-family: "Inter";
        font-style: normal;
    }
    .MuiSelect-icon {
        margin-right: 16px;
        margin-top: 8px;
    }
    .MuiInput-formControl {
        margin-top: 8px;
    }
    .MuiSelect-select:focus {
        background: rgba(0, 0, 0, 0);
    }
    .MuiFormLabel-root.Mui-focused {
        color: #0077ff;
    }
    .materia-label {
        margin: 8px 0px 0px 16px;
    }
    .materia-selector {
        margin-top: 0px;
        padding: 8px 16px 0px 16px;
    }
    .MuiInputBase-input {
        margin-top: 8px;
        padding: 8px 16px 8px 0px;
    }
    .selector-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .no-input {
        border-color: #ff3929;
    }
    input:focus {
        border: 0px;
        box-shadow: 0px 0px;
    }
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
`;
