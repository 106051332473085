import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { ScreenState } from "@snackpass/snackpass-types";
import { MobileHeader } from "#reusable/layout/header";
import { ScreenLayout } from "#reusable/layout/screen-layout";
import { Text } from "#reusable/text/index";
import constants from "#core/constants";

import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { ExternalLink } from "lucide-react";
import { Button } from "src/@/components/ui/button";

const SettingsCateringScreen = () => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });

    return (
        <div className="h-[100%]">
            <ScreenLayout
                header={
                    isMobile ? (
                        <MobileHeader title="Catering" />
                    ) : (
                        <div className="flex flex-row items-center gap-3">
                            <Text size="xl" weight="x-bold" className="w-fit">
                                Catering
                            </Text>
                        </div>
                    )
                }
                description={null}
                content={
                    <Container>
                        <div className="mb-4 mt-6">
                            <Alert
                                variant="default"
                                className="bg-neutral-50 border-neutral-400"
                            >
                                <AlertDescription className="flex items-center justify-between text-neutral-950">
                                    <div>
                                        <span className="font-bold">
                                            First Party Catering:{" "}
                                        </span>
                                        Learn how to set up a dedicated catering
                                        account where customers can place
                                        scheduled catering orders for pick up or
                                        delivery.
                                    </div>
                                    <Button
                                        variant="default"
                                        className="ml-4 bg-black text-white hover:bg-black/90"
                                        asChild
                                    >
                                        <a
                                            href="https://support.snackpass.co/en/articles/10054799-catering-2-0"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Setup
                                            <ExternalLink className="ml-2 h-4 w-4" />
                                        </a>
                                    </Button>
                                </AlertDescription>
                            </Alert>
                            <br />
                            <Alert
                                variant="default"
                                className="mb-4 bg-neutral-50 border-neutral-400"
                            >
                                <AlertDescription className="text-neutral-950">
                                    <span className="font-bold">Note: </span>
                                    If you just want to enable customers to
                                    schedule large pickup orders in advance, you
                                    can use regular online ordering. Configure
                                    schedule ahead in your{" "}
                                    <a
                                        href="/settings-online-orders"
                                        className="underline"
                                    >
                                        online order settings
                                    </a>
                                    .
                                </AlertDescription>
                            </Alert>
                        </div>
                    </Container>
                }
            />
        </div>
    );
};

export default SettingsCateringScreen;

const Container = styled.div`
    overflow-y: scroll;
    padding-bottom: 50px;
    padding-right: 20%;
    height: 100%;
    @media ${ScreenState.MOBILE} {
        padding-left: 20px;
        padding-right: 30px;
    }
    @media ${ScreenState.TABLET} {
        padding-right: 50px;
    }
    width: -webkit-fill-available;
    width: -moz-available;
`;
