import * as yup from "yup";

import { FIELD_NAMES, HappyHourPromo } from "#promotion/utils/types";
import { scheduleEnabledValidation } from "#promotion/utils/validation/field-schemas";
import {
    GenericDefaultValues,
    GenericPromoValidations,
} from "#promotion/utils/validation/form-schemas/generic";

export const HappyHourDefaultValues = {
    ...GenericDefaultValues,
    // Type Overrides
    [FIELD_NAMES.SCHEDULE_ENABLED]: true,
} as HappyHourPromo;

export const HappyHourValidationSchema = yup.object().shape({
    ...GenericPromoValidations,

    // Validation Overrides
    [FIELD_NAMES.SCHEDULE_ENABLED]: scheduleEnabledValidation
        .isTrue("Schedule must be enabled for Happy Hour promotion")
        .required(),
});
