import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";

import { ReportsContext } from "#app/reports-context-provider";
import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";

const LocationSummaryTiles = () => {
    const { reportsState } = useContext(ReportsContext);
    const { locationReportsData } = reportsState;
    const data = locationReportsData?.salesSummaryData;
    const isLoading =
        locationReportsData?.salesSummaryDataLoading ||
        (!locationReportsData?.salesSummaryData &&
            !locationReportsData?.salesSummaryDataFailed);

    const formatValue = (value: string | undefined) =>
        toDollarFormatted(Number.parseFloat(value || "0"));

    return (
        <div className="my-10">
            <h4 className="text-large sm:mr-5">Total Location Stats</h4>
            <div className="my-5 grid grid-cols-1 justify-center gap-4 md:grid-cols-2">
                {!isLoading ? (
                    data ? (
                        <>
                            <SummaryTile
                                value={formatValue(data?.netSales)}
                                title="Net Sales"
                            />
                            <SummaryTile
                                value={
                                    data?.orders
                                        ? formatNumber(
                                              Number.parseFloat(data.orders),
                                          )
                                        : "--"
                                }
                                title="Orders"
                            />
                            <SummaryTile
                                value={
                                    data?.items
                                        ? formatNumber(
                                              Number.parseFloat(data.items),
                                          )
                                        : "--"
                                }
                                title="Items"
                            />
                            <SummaryTile
                                value={formatValue(data?.averageOrderSize)}
                                title="Average Order Size"
                            />
                        </>
                    ) : (
                        Array.from({ length: 4 }, (_, i) => (
                            <ErrorChart key={i} className="h-32" />
                        ))
                    )
                ) : (
                    Array.from({ length: 4 }, (_, i) => (
                        <Skeleton key={i} className="h-32" />
                    ))
                )}
            </div>
        </div>
    );
};

type SummaryTileProps = {
    title: string;
    value: string;
};
const SummaryTile = ({ title, value }: SummaryTileProps) => (
    <div className="rounded-xl border border-neutral-400 p-6">
        <p className="text-small">{title}</p>
        <h1 className="mb-0 text-xlarge">{value}</h1>
    </div>
);

export default LocationSummaryTiles;
