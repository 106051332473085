import { toast } from "sonner";

export const notifications = {
    error: (message: string) =>
        toast.error(message, {
            position: "top-center",
            duration: 5000,
            closeButton: true,
        }),
    success: (message: string) =>
        toast.success(message, {
            position: "top-center",
            duration: 5000,
            closeButton: true,
        }),
};
