import { RefObject, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { isCategory, isProduct, Row } from "#menu-editor/multi-menus/helpers";

export const useResumeTableState = (
    rowRefs: RefObject<HTMLTableRowElement>[],
    rows: Row[],
    rowsById: any,
) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const productId = query.get("productId");
    const addonId = query.get("addonId");
    const firstTimeRef = useRef<boolean>();

    useEffect(() => {
        if (!rowRefs.length || firstTimeRef?.current || !productId) return;
        const itemId = addonId ?? productId;
        const index = rows.findIndex((row) => row.original._id === itemId);
        if (index > -1 && rowRefs.length === rows.length) {
            const rowRef = rowRefs[index]?.current;
            if (rowRef) {
                rowRef.scrollIntoView({ behavior: "smooth" });
                firstTimeRef.current = true;
            }
        }
    }, [productId, addonId, rowRefs.length, rows.length]);

    const shouldExpandRow = (row: Row) => {
        if (firstTimeRef?.current || !productId) return false;
        if (
            isCategory(row.original) &&
            row.original._id === rowsById[productId].original.parentId
        )
            return true;
        if (
            addonId &&
            isProduct(row.original) &&
            row.original._id === productId
        )
            return true;
        return false;
    };

    return shouldExpandRow;
};
