import { useRef } from "react";

const useFocus = () => {
    const htmlElRef: any = useRef<HTMLDivElement>(null);
    const setFocus = () => htmlElRef?.current?.focus?.();

    return { htmlElRef, setFocus };
};

export { useFocus };
