import React, { useState, useMemo, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import {
    ChainGiftCardPayoutType,
    IProduct,
    SpecialProductCategory,
    SpecialProductType,
} from "@snackpass/snackpass-types";
import equal from "fast-deep-equal";
import { Link } from "react-router-dom";
import { toast } from "sonner";

import api from "src/api/rest";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
} from "src/@/components/ui/form";
import { Switch } from "src/@/components/ui/switch";
import { refreshStoreProducts } from "#menu-editor/utils";
import {
    getActiveStore,
    getLegacySpecialTypeProducts,
} from "src/redux/selectors";
import { sendError } from "src/utils/errors";
import { setActiveStore } from "src/redux/slices";
import { Button } from "src/@/components/ui/button";
import { Checkbox } from "src/@/components/ui/checkbox";
import { ScreenLayout } from "#reusable/layout";
import useGiftCardChainStores from "#hooks/use-gift-card-chain-stores";
import useGetChainConfig from "#hooks/use-get-chain-config";
import { RadioGroup, RadioGroupItem } from "src/@/components/ui/radio-group";
import { filterNulls } from "src/utils/filterNulls";
import OrderGiftCardAccessoryButton from "#settings/settings-gift-card/components/order-gift-card-accessory-button";
import { Routes } from "#navigation/routes";
import GiftCardConfirmModal from "#settings/settings-gift-card/components/GiftCardConfirmModal";

const FormSchema = z.object({
    digitalGiftCards: z.boolean().default(false),
    physicalGiftCards: z.boolean().default(false),
    payoutMethod: z
        .enum([
            ChainGiftCardPayoutType.Pooling,
            ChainGiftCardPayoutType.Separate,
        ])
        .default(ChainGiftCardPayoutType.Separate),
});

type StoreUpdateData = {
    hasGiftCardsEnabled?: boolean;
    hasChainWideGiftCard?: boolean;
};

const GiftCardSettings = (): JSX.Element => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState<(() => void) | null>(
        null,
    );
    const activeStore = useSelector(getActiveStore);
    // getSpecialTypeProducts is cached for 1 hr, while active store is not. Therefore, if the gift card product
    // is deleted we actually need to verify if it's on the store itself.
    const liveProductIdsFromStore = new Set(
        activeStore?.productCategories
            ?.filter(
                (pc) =>
                    pc.specialProductCategory ===
                    SpecialProductCategory.GiftCard,
            )
            ?.flatMap((category) => category.productIds),
    );
    const products = useSelector(getLegacySpecialTypeProducts);
    const physicalGiftCardProduct = products.find(
        (p) =>
            p.specialProductType === SpecialProductType.PhysicalGiftCard &&
            liveProductIdsFromStore.has(p._id),
    );
    const digitalGiftCardProduct = products.find(
        (p) =>
            p.specialProductType === SpecialProductType.DigitalGiftCard &&
            liveProductIdsFromStore.has(p._id),
    );
    const { giftCardChainStores, isLoadingGiftCardChainStores } =
        useGiftCardChainStores(activeStore?.chainId, activeStore);
    const effectiveGiftCardChainStores = useMemo(
        () =>
            // You are always part of your own chain, mainly just for display purposes
            giftCardChainStores.length === 0
                ? filterNulls([activeStore]).map((store) => ({
                      id: store._id,
                      name: store.name,
                  }))
                : giftCardChainStores,
        [giftCardChainStores, activeStore],
    );

    const { chainConfig, isLoadingChainConfig } = useGetChainConfig(
        activeStore?.chainId,
    );
    const initialFormValues = useMemo(
        () => ({
            physicalGiftCards: physicalGiftCardProduct != null,
            digitalGiftCards: digitalGiftCardProduct != null,
            validLocations: effectiveGiftCardChainStores,
            payoutMethod:
                chainConfig?.giftCardPayoutType ||
                ChainGiftCardPayoutType.Separate, // default to Separate if chain config is undefined
        }),
        [
            physicalGiftCardProduct,
            digitalGiftCardProduct,
            effectiveGiftCardChainStores,
            chainConfig?.giftCardPayoutType,
        ],
    );

    const rootStoreName = useMemo(
        () =>
            chainConfig?.rootStore == null
                ? undefined
                : giftCardChainStores.find(
                      (store) => store.id === chainConfig?.rootStore,
                  )?.name,
        [chainConfig, giftCardChainStores],
    );

    const onAPIFailure = (err: Error) => {
        sendError(err);
        toast.error("Failed to save gift card settings. Please try again.");
    };
    const createGiftCard = async ({ isDigital }: { isDigital: boolean }) => {
        if (!activeStore) return;
        await api.products.createGiftCard(activeStore._id, isDigital);
        await refreshStoreProducts(dispatch, activeStore._id);
    };

    const deleteGiftCard = async (physicalGiftCardProduct?: IProduct) => {
        if (!activeStore || !physicalGiftCardProduct) return;
        await api.products.remove(physicalGiftCardProduct._id);
        await refreshStoreProducts(dispatch, activeStore._id);
    };

    const updateStore = async (
        storeId: string,
        updateData: StoreUpdateData,
    ) => {
        if (!storeId) return;
        const { data } = await api.stores.update(storeId, updateData);
        if (data.store._id === activeStore?._id) {
            dispatch(setActiveStore(data.store));
        }
    };

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
    });

    useEffect(() => {
        form.reset(initialFormValues);
    }, [form, initialFormValues]);

    const onReset = () => {
        form.reset();
    };

    const onSubmit = async (formValues: z.infer<typeof FormSchema>) => {
        if (!activeStore) return;
        setIsLoading(true);

        const initialValue = form.formState.defaultValues;
        try {
            if (
                !equal(
                    formValues.physicalGiftCards,
                    initialValue?.physicalGiftCards,
                )
            ) {
                if (
                    formValues.physicalGiftCards &&
                    physicalGiftCardProduct == null
                ) {
                    await createGiftCard({ isDigital: false });
                } else {
                    await deleteGiftCard(physicalGiftCardProduct);
                }
            }

            if (
                !equal(
                    formValues.digitalGiftCards,
                    initialValue?.digitalGiftCards,
                )
            ) {
                if (
                    formValues.digitalGiftCards &&
                    digitalGiftCardProduct == null
                ) {
                    await createGiftCard({ isDigital: true });
                }
                if (!formValues.digitalGiftCards) {
                    await deleteGiftCard(digitalGiftCardProduct);
                }
                await updateStore(activeStore?._id, {
                    hasGiftCardsEnabled: formValues.digitalGiftCards,
                });
            }
            form.reset(formValues);
        } catch (err) {
            onAPIFailure(err as Error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <GiftCardConfirmModal
                setShowConfirmModal={setShowConfirmModal}
                showConfirmModal={showConfirmModal}
                confirmAction={confirmAction}
            />
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="relative h-full w-full space-y-6 overflow-hidden"
                >
                    <ScreenLayout
                        header={
                            <div className="pb-2 text-xlarge font-semibold">
                                Gift Cards
                            </div>
                        }
                        isLoading={
                            isLoading ||
                            isLoadingGiftCardChainStores ||
                            isLoadingChainConfig
                        }
                        description={
                            <div className="text-body text-[#585B5F] md:pr-[20%]">
                                Sell digital gift cards to customers on online
                                ordering. Customize and order traditional
                                physical gift cards to sell from your register.{" "}
                                <span
                                    className="cursor-pointer text-[#0099FF]"
                                    onClick={() => {
                                        setConfirmAction(null);
                                        setShowConfirmModal(true);
                                    }}
                                >
                                    View Terms
                                </span>
                            </div>
                        }
                        content={
                            <div className="h-[100%] overflow-auto pb-8 [-ms-overflow-style:'none'] [scrollbar-width:'none'] md:pr-[20%] [&::-webkit-scrollbar]:hidden">
                                <div className="py-6 text-xlarge font-semibold leading-6">
                                    Sell Gift Cards
                                </div>
                                <FormField
                                    control={form.control}
                                    name="digitalGiftCards"
                                    render={({ field }) => (
                                        <FormItem className="flex flex-row items-center justify-between space-x-2 border-b pb-6">
                                            <div className="space-y-0.5">
                                                <FormLabel className="text-body font-semibold">
                                                    Digital Gift Cards
                                                </FormLabel>
                                                <FormDescription className="text-[#585B5F]">
                                                    Customers can purchase
                                                    digital gift cards on Online
                                                    Ordering
                                                </FormDescription>
                                            </div>
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={(
                                                        newValue,
                                                    ) => {
                                                        if (newValue) {
                                                            setConfirmAction(
                                                                () => () =>
                                                                    field.onChange(
                                                                        newValue,
                                                                    ),
                                                            );
                                                            setShowConfirmModal(
                                                                true,
                                                            );
                                                        } else {
                                                            field.onChange(
                                                                newValue,
                                                            );
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="physicalGiftCards"
                                    render={({ field }) => (
                                        <div className="flex flex-col border-b">
                                            <FormItem className="flex flex-row items-center justify-between space-x-2 py-6">
                                                <div className="space-y-0.5">
                                                    <FormLabel className="text-body font-semibold">
                                                        Physical Gift Cards
                                                    </FormLabel>
                                                    <FormDescription className="text-[#585B5F]">
                                                        Traditional plastic gift
                                                        cards for customers to
                                                        purchase at the Register
                                                    </FormDescription>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={(
                                                            newValue,
                                                        ) => {
                                                            if (newValue) {
                                                                setConfirmAction(
                                                                    () => () =>
                                                                        field.onChange(
                                                                            newValue,
                                                                        ),
                                                                );
                                                                setShowConfirmModal(
                                                                    true,
                                                                );
                                                            } else {
                                                                field.onChange(
                                                                    newValue,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                            {physicalGiftCardProduct ? (
                                                <div className="flex flex-row gap-4 pb-6">
                                                    <OrderGiftCardAccessoryButton
                                                        link="https://www.snackpass.co/order-physical-giftcards"
                                                        title="Order Cards"
                                                    />
                                                    <OrderGiftCardAccessoryButton
                                                        link="https://store.snackpass.co/products/gift-card-scanner"
                                                        title="Order Scanner"
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                />
                                <div className="flex flex-col border-b">
                                    <div className="space-y-0.5 py-6 pt-2">
                                        <FormLabel className="pb-1 pt-6 text-xlarge font-semibold leading-6">
                                            Promotions
                                        </FormLabel>
                                        <FormDescription className="text-neutral-600">
                                            Create and edit existing promotions
                                            on gift cards on the Gift Card
                                            shortcut in{" "}
                                            <Link to={Routes.Promotion}>
                                                Promotions
                                            </Link>
                                            .
                                        </FormDescription>
                                    </div>
                                </div>
                                <FormField
                                    name="validLocations"
                                    render={() => (
                                        <FormItem>
                                            <div className="space-y-0.5 pb-4 pt-2">
                                                <FormLabel className="pb-1 pt-6 text-xlarge font-semibold leading-6">
                                                    Participating Locations
                                                </FormLabel>
                                                <FormDescription className="text-[#585B5F]">
                                                    Locations where gift cards
                                                    can be purchased and used.
                                                    Please contact support to
                                                    update gift card locations
                                                    and turn on chain gift card.
                                                </FormDescription>
                                            </div>
                                            <div className="rounded-lg border">
                                                {effectiveGiftCardChainStores.map(
                                                    (location) => (
                                                        <div
                                                            className="flex flex-row border-b py-[19px] last:border-none"
                                                            key={location.id}
                                                        >
                                                            <Checkbox
                                                                className="ml-4 cursor-default"
                                                                checked={true}
                                                                disabled
                                                            />

                                                            <FormLabel className="pl-4 text-sm font-normal">
                                                                {location.name}
                                                            </FormLabel>
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    name="payoutMethod"
                                    render={() => (
                                        <FormItem className="pt-8">
                                            <FormLabel className="text-xlarge font-semibold leading-6">
                                                Payout Method
                                            </FormLabel>
                                            <FormDescription className="flex flex-row text-[#585B5F]">
                                                <div>
                                                    For Gift Card liability and
                                                    pooled transaction history
                                                    see{" "}
                                                    <Link to="/gift-card-liabilities">
                                                        Reports
                                                    </Link>
                                                </div>
                                            </FormDescription>
                                            <RadioGroup
                                                defaultValue={
                                                    initialFormValues.payoutMethod
                                                }
                                                className="flex flex-col space-y-1 pt-2"
                                                disabled
                                            >
                                                <FormItem className="flex items-start space-x-3 space-y-0">
                                                    <RadioGroupItem
                                                        value={
                                                            ChainGiftCardPayoutType.Pooling
                                                        }
                                                    />
                                                    <div>
                                                        <FormLabel
                                                            className="text-body font-semibold"
                                                            tooltip="Please contact support to change payout method."
                                                            hideTooltipIcon
                                                        >
                                                            Single Account
                                                        </FormLabel>
                                                        <FormDescription className="cursor-default text-[#585B5F]">
                                                            Gift Card purchases
                                                            (less processing)
                                                            are paid out
                                                            immediately to the
                                                            specified bank
                                                            account
                                                            {`${
                                                                rootStoreName !=
                                                                    null &&
                                                                form.getValues()
                                                                    .payoutMethod ===
                                                                    ChainGiftCardPayoutType.Pooling
                                                                    ? ` (${rootStoreName})`
                                                                    : ""
                                                            }`}
                                                            . Snackpass is not
                                                            responsible for
                                                            transferring funds
                                                            between locations.
                                                        </FormDescription>
                                                    </div>
                                                </FormItem>
                                                <FormItem className="flex items-start space-x-3 space-y-0">
                                                    <RadioGroupItem
                                                        value={
                                                            ChainGiftCardPayoutType.Separate
                                                        }
                                                    />
                                                    <div>
                                                        <FormLabel
                                                            className="text-body font-semibold"
                                                            tooltip="Please contact support to change payout method."
                                                            hideTooltipIcon
                                                        >
                                                            Purchase Location
                                                        </FormLabel>
                                                        <FormDescription className="cursor-default text-[#585B5F]">
                                                            Gift card purchases
                                                            (less processing)
                                                            are paid out
                                                            immediately to the
                                                            location where the
                                                            gift card was
                                                            purchased. Snackpass
                                                            is not responsible
                                                            for transferring
                                                            funds between
                                                            locations.
                                                        </FormDescription>
                                                    </div>
                                                </FormItem>
                                            </RadioGroup>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        }
                        footer={
                            form.formState.isDirty && !isLoading ? (
                                <div className="flex w-[100%] flex-row justify-end gap-2 md:pr-[20%]">
                                    <Button
                                        type="button"
                                        onClick={onReset}
                                        disabled={isLoading}
                                        variant="outline"
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                    />
                </form>
            </Form>
        </>
    );
};

export default GiftCardSettings;
