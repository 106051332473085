import { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { ReportsContext } from "#app/reports-context-provider";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { GiftCardTransactionRow } from "#reports/gift-card-transactions/types";
import {
    GiftCardTransactionType,
    columns,
    columnsForExport,
    formatRowsForExport,
    getMainActorStoreName,
    getLabelForGiftCardEventType,
    getGiftCardPurchaseMethod,
} from "#reports/gift-card-transactions/lib";
import { SKIPPED_PHONE } from "#reports/gift-card-balances/lib";

const GiftCardTransactionTable = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { giftCardTransactionReportData } = reportsState;

    const isLoading =
        giftCardTransactionReportData?.giftCardTransactionsDataLoading;
    const hasFailed =
        giftCardTransactionReportData?.giftCardTransactionsDataFailed;

    const rows: GiftCardTransactionRow[] = useMemo(() => {
        const data = giftCardTransactionReportData?.giftCardTransactionsData;
        if (!data) return [];

        return data.map((row) => ({
            storeName: getMainActorStoreName(row),
            date: row.date,
            type: row.type,
            code: row.code,
            purchaseStoreName: row.purchaseStoreName,
            usageStoreName: row.usageStoreName ?? "-",
            payoutStoreName: row.payoutStoreName ?? "-",
            amount: row.amount,
            amountRemaining: row.amountRemaining,
            purchaseCustomerEmail:
                row.purchaseCustomerEmail === SKIPPED_PHONE
                    ? "-"
                    : row.purchaseCustomerEmail,
            usageCustomerEmail: row.usageCustomerEmail ?? "-",
            usageCustomerPhone: row.usageCustomerPhone ?? "-",
            giftCardPurchaseMethod: getGiftCardPurchaseMethod(row),
        }));
    }, [giftCardTransactionReportData]);

    const storeNamesSet = new Set<string>(rows.map((row) => row.storeName));
    const storeFilterOptions: { label: string; value: string }[] = [];
    storeNamesSet.forEach((storeName) => {
        storeFilterOptions.push({
            label: storeName,
            value: storeName,
        });
    });

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            placeholder: "Search by Code or Phone",
            global: true,
        },
        filters: [
            {
                title: "Type",
                key: "Type",
                options: Object.values(GiftCardTransactionType).map((type) => ({
                    label: getLabelForGiftCardEventType(type),
                    value: getLabelForGiftCardEventType(type),
                })),
            },
        ],
    };

    return (
        <div className="mb-20">
            <div className="mb-4 flex items-center justify-between">
                <DownloadButton
                    rows={formatRowsForExport(rows)}
                    columns={columnsForExport}
                    filename={`${activeStore?.name}`}
                />
            </div>
            <div>
                {!isLoading &&
                (hasFailed ||
                    giftCardTransactionReportData?.giftCardTransactionsData) ? (
                    giftCardTransactionReportData?.giftCardTransactionsData ? (
                        <DataTable
                            toolbar={toolbarOptions}
                            showPagination
                            columns={columns}
                            data={rows}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default GiftCardTransactionTable;
