import { useMutation } from "@tanstack/react-query";
import { UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { setActiveStore } from "src/redux/slices";
import api from "src/api/rest";
import {
    ConvenienceFormValues,
    FeesSettingsMutation,
} from "#settings/settings-fees/utils/types";
import { convenienceFormDefaults } from "#settings/settings-fees/forms/convenience/convenience-defaults";

export const useUpdateConvenience = ({
    storeId,
    reset,
}: {
    storeId: string;
    reset: UseFormReset<ConvenienceFormValues>;
}) => {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: [FeesSettingsMutation.Convenience, storeId],
        async mutationFn(values: ConvenienceFormValues) {
            const response = await api.stores.updateConvenienceFeePolicy(
                storeId,
                [
                    { transactionSource: "app", value: values.app },
                    { transactionSource: "kiosk", value: values.kiosk },
                    { transactionSource: "online", value: values.online },
                ],
            );
            dispatch(setActiveStore(response.data.store));
            reset(convenienceFormDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved",
            });
        },
        onError() {
            toast.error(`Update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed",
            });
        },
    });
    return mutateAsync;
};
