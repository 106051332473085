import { useCallback } from "react";
import { TrashIcon } from "@radix-ui/react-icons";

import { useEditorStore } from "#table-editor/components/Editor/EditorProvider";
import { Button } from "src/@/components/ui/button";

export function ModifyBarrier({ id }: { id: string }) {
    const editor = useEditorStore();

    const onClickDelete = useCallback(() => {
        editor.getState().removeSelected(id);
    }, [editor, id]);

    return (
        <div className="flex w-80 flex-col gap-3 p-3">
            <Button
                type="button"
                variant="destructive"
                onClick={onClickDelete}
                className="gap-2"
            >
                <TrashIcon className="h-4 w-4" />
                <span className="select-none">Delete</span>
            </Button>
        </div>
    );
}
