export const REFUND_REASONS = [
    "The store closed early",
    "There is a problem in the restaurant",
    "The store is out of an item",
    "The customer cancelled order",
    "Out of delivery range",
    "Other reason"
];

export const REFUND_INTERNAL_REASONS = ["Dispute resolution"];
