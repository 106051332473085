import { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Location, UnregisterCallback } from "history";

type UnsavedChangesPromptReturn = {
    showModal: boolean;
    handleConfirmNavigationClick: () => void;
    handleCancelNavigationClick: () => void;
};

export const useUnsavedChangesPrompt = (
    isFormDirty: boolean,
    message = "Are you sure you want to quit without saving your changes?",
): UnsavedChangesPromptReturn => {
    const history = useHistory();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [nextLocation, setNextLocation] = useState<Location | null>(null);
    const [unblock, setUnblock] = useState<UnregisterCallback | null>(null);

    const handleConfirmNavigationClick = useCallback(() => {
        setShowModal(false);
        unblock?.();

        if (nextLocation) {
            // go to next page
            history.push(nextLocation.pathname);
        }
    }, [nextLocation, history, unblock]);

    const handleCancelNavigationClick = useCallback(() => {
        setShowModal(false);
        setNextLocation(null);
    }, []);

    const handleBlockedNavigation = useCallback(
        (nextLocation: Location) => {
            if (isFormDirty) {
                setShowModal(true);
                setNextLocation(nextLocation);
                return false; // returns false to block navigation when unsaved
            }
            return;
        },
        [isFormDirty],
    );

    // handles what happens when navigation is blocked within react router
    useEffect(() => {
        // NOTE: this works but might be worth refactoring
        if (isFormDirty && !unblock) {
            const unblockHandler = history.block(handleBlockedNavigation);
            setUnblock(() => unblockHandler);
        }

        return () => {
            if (unblock) {
                unblock();
                setUnblock(null);
            }
        };
    }, [history, handleBlockedNavigation, isFormDirty, unblock]);

    const onCloseTab = useCallback(
        (event: BeforeUnloadEvent) => {
            if (!isFormDirty) {
                return; // no changes to save
            }

            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            event.preventDefault();
            event.returnValue = message;
            return message; // returns message when there are unsaved changes
        },
        [isFormDirty, message],
    );

    // handles what happens if user tries to close or refresh the tab
    useEffect(() => {
        window.addEventListener("beforeunload", onCloseTab);
        return () => {
            window.removeEventListener("beforeunload", onCloseTab);
        };
    }, [onCloseTab]);

    return {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick,
    };
};
