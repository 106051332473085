import { useSortable } from "@dnd-kit/sortable";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";

import { Button } from "src/@/components/ui/button";

/**
 * A drag handle for rows in the data table component.
 * Requires a valid "dragAndDropOptions" property to be provided in the data table to work properly.
 * XXX: this component seems to break DND functionality when it is re-rendered, maybe as a result of the listener handlers being called again?
 */
export const RowDragHandle = ({
    uniqueRowId,
    className,
}: {
    uniqueRowId: string;
    className?: string;
}) => {
    const { attributes, listeners } = useSortable({
        id: uniqueRowId,
    });
    return (
        <Button
            className={clsx("bg-transparent", className)}
            variant={"ghost"}
            size={"sm"}
            type="button"
            {...attributes}
            {...listeners}
        >
            <DragHandleDots2Icon />
        </Button>
    );
};
