import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

import { Button } from "src/@/components/ui/button";

export const IntercomButton = () => (
    <Button
        onClick={() => {
            window.Intercom("show");
        }}
        variant="default"
    >
        <ChatBubbleLeftEllipsisIcon className="mr-2 h-4 w-4" />
        Chat With Us
    </Button>
);
