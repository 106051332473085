import React, { useContext, useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DevicesModal } from "#devices/components/Modal";
import { CenteredSpin } from "#devices/components/Loading";

type DeleteDeviceModalProps = {
    handleClose: (b: boolean) => void;
    handleDelete: () => void;
    isOpen: boolean;
};

const FormControlStyles = styled.div`
    input::placeholder {
        opacity: 0.5;
    }
`;

const DeleteConfirmationText = "delete this device";

const DeleteDeviceModal = ({
    handleClose,
    handleDelete,
    isOpen,
}: DeleteDeviceModalProps): JSX.Element => {
    const { deletingDevice } = useContext(DevicesPageContext);

    const [confirmText, setConfirmText] = useState<string>("");

    const returnToEdit = () => {
        setConfirmText("");
        handleClose(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmText(event.target.value);
    };

    const isValueValid = confirmText.toLowerCase() === DeleteConfirmationText;

    const disabled = !isValueValid || deletingDevice;

    return (
        <DevicesModal
            visible={isOpen}
            handleClose={returnToEdit}
            footer={
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Button
                                variant="outline"
                                className="w-full"
                                onClick={returnToEdit}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                className="w-full"
                                onClick={handleDelete}
                                disabled={disabled}
                            >
                                {deletingDevice ? (
                                    <CenteredSpin small />
                                ) : (
                                    "Delete"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
        >
            <Text.Title2>Delete Device</Text.Title2>
            <Text.Body>
                {`Type "${DeleteConfirmationText.toUpperCase()}" to remove this device.`}
            </Text.Body>
            <FormControlStyles>
                <Form.Group controlId="deleteMe" className="mb-4">
                    <Form.Control
                        autoFocus
                        size="lg"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        placeholder={DeleteConfirmationText}
                        required
                        value={confirmText}
                        onChange={handleChange}
                        minLength={DeleteConfirmationText.length}
                        maxLength={DeleteConfirmationText.length}
                        isValid={isValueValid}
                        isInvalid={!isValueValid}
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() === "enter" && !disabled) {
                                handleDelete();
                            }
                        }}
                    />
                </Form.Group>
            </FormControlStyles>
            <Text.Body>
                Are you sure you want to delete this device? This action cannot
                be undone.
            </Text.Body>
        </DevicesModal>
    );
};

export default DeleteDeviceModal;
