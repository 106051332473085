import { descriptions } from "#onboarding/utils/descriptions";
import { IStep, OnboardingStep } from "#onboarding/utils/types";

export const OnboardingSteps: IStep[] = [
    {
        name: OnboardingStep.Location,
        title: descriptions.summary.location.title,
        subtitle: descriptions.summary.location.subtitle,
        optional: false,
    },
    {
        name: OnboardingStep.Business,
        title: descriptions.summary.business.title,
        subtitle: descriptions.summary.business.subtitle,
        optional: false,
    },
    // {
    //     name: OnboardingStep.Billing,
    //     title: descriptions.summary.billing.title,
    //     subtitle: descriptions.summary.billing.subtitle,
    //     optional: false
    // },
    // {
    //     name: OnboardingStep.Representative,
    //     title: descriptions.summary.representative.title,
    //     subtitle: descriptions.summary.representative.subtitle,
    //     optional: false
    // }
].map((s, i) => ({ ...s, number: i + 1, completed: false }));
