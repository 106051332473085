import React, { ReactNode } from "react";
import styled from "styled-components";

import { ReactComponent as SearchSign } from "src/assets/icons/search.svg";

type Props = {
    value: string;
    onChange: Function;
    children?: ReactNode;
    placeHolder?: string;
    searchBarRef?: React.RefObject<HTMLInputElement>;
    style?: SearchBarStyleProps;
};

export type SearchBarStyleProps = Partial<{
    fontWeight: number;
    fontSize: string;
    width: number | string;
    color: string;
    lineHeight: string;
    placeholderColor: string;
}>;

const IconInputStyle = styled.span<SearchBarStyleProps>`
    .wrapper {
        position: relative;
    }

    .icon {
        height: 1.5rem;
        width: 1.5rem;
        padding: 4px;
        position: absolute;
        box-sizing: border-box;
        top: 50%;
        left: 0.5rem;
        transform: translateY(-50%);
    }

    .input {
        box-sizing: border-box;
        padding-left: 2.2rem;
        border-radius: 16px;
        width: ${({ width = "11rem" }) =>
            typeof width === "number" ? `${width}px` : width};
        margin-top: 1px;
        height: 36px;
        font-family: "Inter";
        font-style: normal;
        font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
        font-size: ${({ fontSize = "16px" }) => fontSize};
        line-height: ${({ lineHeight = "20px" }) => lineHeight};
        color: ${({ color = "rgba(40, 45, 50, 1)" }) => color};
    }

    input::placeholder {
        color: ${({ placeholderColor = "rgba(40, 45, 50, 1)" }) =>
            placeholderColor};
    }
`;

const SearchBar: React.FC<Props> = ({
    value,
    children,
    onChange,
    placeHolder,
    searchBarRef,
    style = {},
}) => (
    <IconInputStyle {...style}>
        <span className="wrapper">
            <SearchSign className="icon" style={{ marginRight: 8 }} />
            <input
                className="input"
                value={value || ""}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeHolder}
                ref={searchBarRef}
            >
                {children}
            </input>
        </span>
    </IconInputStyle>
);

export default SearchBar;
