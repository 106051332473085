import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import { IStore } from "@snackpass/snackpass-types";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { Routes } from "#navigation/routes";
import api from "src/api/rest";
import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import { sendError } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { setActiveStore } from "src/redux/slices";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { getActiveStoreId, getUserTeamPermission } from "src/redux/selectors";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";
import { useCateringEnabled } from "#navigation/utils";

const productsValidationSchema = z.object({
    onlineOrderingEnabled: z.boolean(),
    kioskEnabled: z.boolean(),
    kdsEnabled: z.boolean(),
    registerEnabled: z.boolean(),
    snackpassAppEnabled: z.boolean(),
    hasGiftCardsEnabled: z.boolean(),
    paymentsEnabled: z.boolean(),
    customerPickupScreenEnabled: z.boolean(),
    websiteEnabled: z.boolean(),
    cateringEnabled: z.boolean(),
    customBrandedAppEnabled: z.boolean(),
    deliveryMarketplaceIntegrationEnabled: z.boolean(),
    timeAndAttendanceEnabled: z.boolean(),
    dynamicMenuBoardEnabled: z.boolean(),
    allowAlcoholSales: z.boolean().optional(),
});

const storeToFormValues = (
    store: Partial<IStore> | undefined | null,
): FormValues => ({
    onlineOrderingEnabled: !!store?.onlineOrderingEnabled,
    kioskEnabled: !!store?.kioskEnabled,
    kdsEnabled: !!store?.kdsEnabled,
    registerEnabled: !!store?.registerEnabled,
    snackpassAppEnabled: !!store?.snackpassAppEnabled,
    hasGiftCardsEnabled: !!store?.hasGiftCardsEnabled,
    cateringEnabled: !!store?.cateringEnabled,
    paymentsEnabled: !!store?.paymentsEnabled,
    customerPickupScreenEnabled: !!store?.customerPickupScreenEnabled,
    websiteEnabled: !!store?.websiteEnabled,
    customBrandedAppEnabled: !!store?.customBrandedAppEnabled,
    deliveryMarketplaceIntegrationEnabled:
        !!store?.deliveryMarketplaceIntegrationEnabled,
    timeAndAttendanceEnabled: !!store?.timeAndAttendanceEnabled,
    dynamicMenuBoardEnabled: !!store?.dynamicMenuBoardEnabled,
    allowAlcoholSales:
        store?.payoutChannel?.__t === "StripePayoutChannel"
            ? store?.payoutChannel.allowAlcoholSales
            : false,
});

type FormValues = z.infer<typeof productsValidationSchema>;

const SettingsProductsScreen = () => {
    const viewOnly = !useHasEditSettingsForActiveStore();
    const isSnackpassEmployee = useSelector(getUserTeamPermission);
    const inputsDisabled = !isSnackpassEmployee || viewOnly;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const activeStoreId = useSelector(getActiveStoreId);

    const storeValues = useAppSelector((state) =>
        storeToFormValues(state.activeStore),
    );
    const cateringEnabled = useCateringEnabled();

    const form = useForm<FormValues>({
        resolver: zodResolver(productsValidationSchema),
        defaultValues: {
            onlineOrderingEnabled: false,
            kioskEnabled: false,
            kdsEnabled: false,
            registerEnabled: false,
            snackpassAppEnabled: false,
            hasGiftCardsEnabled: false,
            paymentsEnabled: false,
            cateringEnabled: false,
            customerPickupScreenEnabled: false,
            websiteEnabled: false,
            customBrandedAppEnabled: false,
            deliveryMarketplaceIntegrationEnabled: false,
            timeAndAttendanceEnabled: false,
            dynamicMenuBoardEnabled: false,
            allowAlcoholSales: false,
        },
    });

    const resetForm = () => form.reset(storeValues);

    useEffect(() => {
        if (activeStoreId) {
            resetForm();
        }
    }, [activeStoreId]);

    const onSubmit = useCallback(
        async (values: FormValues) => {
            if (!activeStoreId || viewOnly) return;
            try {
                setIsLoading(true);
                const { data } = await api.stores.update(activeStoreId, values);
                dispatch(setActiveStore(data.store));
                form.reset(storeToFormValues(data.store));
                toast.success("Store updated successfully");
            } catch (e) {
                sendError(e);
                const errorMessage =
                    axios.isAxiosError(e) &&
                    "userFacingErrorMessage" in e.response?.data
                        ? `Could not update store settings: ${e.response?.data?.userFacingErrorMessage}`
                        : "Cannot update the store, please try again later";
                toast.error(errorMessage);
            } finally {
                setIsLoading(false);
            }
        },
        [activeStoreId, viewOnly, dispatch, form.reset],
    );

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick,
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Products
                        </CardTitle>
                        <div className="text-gray-600">
                            Enable Snackpass products for your store.
                            {inputsDisabled && (
                                <p className="text-red-500">
                                    Please contact support to adjust your
                                    store’s product settings
                                </p>
                            )}
                        </div>
                        <hr className="border-gray-300" />
                    </div>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Front of House
                            </CardTitle>
                            <CardDescription>
                                Give your customers more ways to order.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="kioskEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Kiosk
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Streamline ordering with
                                                    large screen self-service
                                                    kiosks.{" "}
                                                    <a
                                                        href="https://www.snackpass.co/kiosk"
                                                        target="_blank"
                                                        className="no-underline"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Learn more
                                                    </a>
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="registerEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Register
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Process payments and manage
                                                    your menu efficiently from
                                                    our POS system.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="onlineOrderingEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Online Ordering
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Order online with our
                                                    customizable, mobile
                                                    friendly, web-based
                                                    platform.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            {cateringEnabled || isSnackpassEmployee ? ( // only show catering setting if it's already enabled, or you are a SP employee
                                <>
                                    <hr />
                                    <FormField
                                        control={form.control}
                                        name="cateringEnabled"
                                        render={({ field }) => (
                                            <FormItem>
                                                <div className="flex justify-between">
                                                    <div>
                                                        <div className="flex flex-row items-center gap-3">
                                                            <CardTitle className="text-base font-medium">
                                                                Catering
                                                            </CardTitle>
                                                        </div>
                                                        <CardDescription className="pt-0.5">
                                                            Allow customers to
                                                            place scheduled
                                                            catering orders
                                                            online or on
                                                            Snackpass Register.
                                                            {isSnackpassEmployee &&
                                                            !cateringEnabled ? (
                                                                <div className="font-semibold">
                                                                    Note: This
                                                                    setting is
                                                                    only visible
                                                                    to Snackpass
                                                                    employees if
                                                                    it is
                                                                    disabled.
                                                                </div>
                                                            ) : null}
                                                        </CardDescription>
                                                    </div>
                                                    <div className="my-auto">
                                                        <FormControl>
                                                            <Switch
                                                                checked={
                                                                    field.value
                                                                }
                                                                onCheckedChange={
                                                                    field.onChange
                                                                }
                                                                disabled={
                                                                    // we don't want non SP employees to be able to turn this field back on.
                                                                    inputsDisabled ||
                                                                    (!field.value &&
                                                                        !isSnackpassEmployee)
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </div>
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </>
                            ) : null}
                            <hr />
                            <FormItem>
                                <div className="flex justify-between">
                                    <div>
                                        <CardTitle className="text-base font-medium">
                                            Invoices
                                        </CardTitle>
                                        <CardDescription className="pt-0.5">
                                            Create, send, and manage invoices
                                            that your customers can easily pay
                                            online.{" "}
                                            <Link
                                                to={Routes.Invoices}
                                                className="no-underline"
                                            >
                                                Learn more
                                            </Link>
                                        </CardDescription>
                                    </div>
                                    <div className="my-auto">
                                        <FormControl>
                                            <Switch disabled={inputsDisabled} />
                                        </FormControl>
                                        <FormMessage />
                                    </div>
                                </div>
                            </FormItem>
                            <hr />
                            <FormField
                                control={form.control}
                                name="customBrandedAppEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Custom Branded App
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    An ordering app just for
                                                    your customers, designed to
                                                    match your store’s brand.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="snackpassAppEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Snackpass App
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Reach more customers by
                                                    adding your store to the
                                                    Snackpass marketplace App.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="customerPickupScreenEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Pickup Board
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Notify customers when their
                                                    orders are ready for pickup
                                                    with a live display.{" "}
                                                    <a
                                                        href="https://www.snackpass.co/customer-pickup-screen"
                                                        target="_blank"
                                                        className="no-underline"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Learn more
                                                    </a>
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="dynamicMenuBoardEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Menu Board
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Highlight featured menu
                                                    items and promotions with a
                                                    dynamic menu screen that
                                                    adapts to your needs.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Back of House
                            </CardTitle>
                            <CardDescription>
                                Keep your kitchen in sync.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="kdsEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Kitchen Display System (KDS)
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Manage order tickets and
                                                    optimize your kitchen
                                                    workflow without a printer.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="deliveryMarketplaceIntegrationEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Delivery Marketplace
                                                    Integration
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Connect orders from
                                                    Doordash, Uber Eats, and
                                                    more directly to your POS.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="timeAndAttendanceEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Simple Clock In
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Track employee hours and
                                                    manage payroll with our time
                                                    and attendance system.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Marketing
                            </CardTitle>
                            <CardDescription>
                                Reach customers and grow your business.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="hasGiftCardsEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Gift Cards
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Offer digital and physical
                                                    gift cards, an ideal gifting
                                                    solution for your valued
                                                    customers.{" "}
                                                    <Link
                                                        to={
                                                            Routes.SettingsGiftCard
                                                        }
                                                        className="no-underline"
                                                    >
                                                        Set up
                                                    </Link>
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="websiteEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Website
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    A lightweight, branded
                                                    website perfect for adding
                                                    to social media profiles.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Payments
                            </CardTitle>
                            <CardDescription>
                                Ensure smooth, secure, and versatile
                                transactions.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="paymentsEnabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Payments
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Seamlessly accept and
                                                    process payments for a
                                                    smooth transaction
                                                    experience.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="allowAlcoholSales"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Alcohol Payment Processing
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Changes Payment Processor
                                                    configuration as described
                                                    in the{" "}
                                                    <a
                                                        href="https://legal.snackpass.co/snackpass-restaurant-terms-of-service"
                                                        target="_blank"
                                                    >
                                                        Restaurant Terms of
                                                        Service
                                                    </a>
                                                    .
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            inputsDisabled
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};

export default SettingsProductsScreen;
