import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

type Props = {
    children: React.ReactNode;
};

export const FormBody = ({ children }: Props) => (
    <Container>{children}</Container>
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 768px;
    @media ${ScreenState.MOBILE} {
        width: 100%;
    }
`;
