import { ReactNode, useCallback } from "react";
import { UsersIcon } from "@heroicons/react/24/outline";
import { useSetRecoilState } from "recoil";

import { CardTitle } from "src/@/components/ui/card";
import { Button, ButtonProps } from "src/@/components/ui/button";
import GuestbookTooltip from "#guestbook/shared-components/GuestbookTooltip";
import {
    NewCampaignStep,
    newCampaignStepAtom,
} from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";
import StackedStickyHeader from "#navigation/StackedStickyHeader";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import { SegmentEvents } from "#utils/segment";

type Props = {
    children?: ReactNode;
    button: ReactNode;
};

type NewCampaignButtonProps = ButtonProps & {
    disabledTooltip?: string;
    billingDisabled?: boolean;
};

export function NewCampaignButton({
    disabledTooltip = "Register your brand to create a new campaign",
    billingDisabled,
    ...props
}: NewCampaignButtonProps) {
    const trackCampaignEvent = useTrackCampaignSegmentEvent();
    const setNewCampaignStep = useSetRecoilState(newCampaignStepAtom);

    const clickHandler = useCallback(() => {
        setNewCampaignStep(NewCampaignStep.SMSForm);
    }, [setNewCampaignStep]);

    return (
        <GuestbookTooltip
            tooltip={props.disabled ? disabledTooltip : undefined}
        >
            <div
                onClick={
                    props.disabled
                        ? () => {
                              trackCampaignEvent(
                                  SegmentEvents.Guestbook.Campaigns.NEW_CAMPAIGN
                                      .FAIL_PRE_REG,
                              );
                          }
                        : undefined
                }
            >
                <Button
                    type="button"
                    disabled={props.disabled}
                    onClick={billingDisabled ? undefined : clickHandler}
                    asChild={billingDisabled}
                >
                    <>
                        <UsersIcon className="mr-2 h-4 w-4" />
                        New Campaign
                    </>
                </Button>
            </div>
        </GuestbookTooltip>
    );
}

export function CampaignScreenHeader({
    children = "Campaigns",
    button,
}: Props) {
    return (
        <StackedStickyHeader>
            <div className="flex flex-row items-center justify-between gap-10 border-b p-4">
                <CardTitle className="flex flex-row items-center gap-3 text-2xl font-bold">
                    {children}
                </CardTitle>
                {button}
            </div>
        </StackedStickyHeader>
    );
}
