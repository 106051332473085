import { Col, InputNumber } from "antd";
import React, { useCallback } from "react";

import { Divider } from "#reusable/divider";
import { Text } from "#reusable/text/index";
import { StyledField, StyledRow } from "#settings/components/shared-component";

import { OrdersFormFieldEnum, RowProps } from "../types";

export const PickupTimeMaxRow = ({
    setFieldTouched,
    setFieldValue,
    value,
    disabled,
}: RowProps<"defaultPickupMaxTime">) => {
    const onDefaultPickupMaxTimeChange = useCallback(
        (value: number) => {
            setFieldTouched(OrdersFormFieldEnum.defaultPickupMaxTime);
            setFieldValue(OrdersFormFieldEnum.defaultPickupMaxTime, value);
        },
        [setFieldValue, setFieldTouched],
    );

    return (
        <>
            <StyledRow>
                <Col>
                    <Text size="m">Upper bound</Text>
                    <Text size="s" color="light-grey" weight="thin">
                        In minutes
                    </Text>
                </Col>
                <Col span={4}>
                    <StyledField
                        name="defaultPickupMaxTime"
                        type="number"
                        min={0}
                        component={InputNumber}
                        size="large"
                        value={value ?? 15}
                        onChange={onDefaultPickupMaxTimeChange}
                        disabled={disabled}
                    />
                </Col>
            </StyledRow>
            <Divider />
        </>
    );
};
