import { IPromotion } from "@snackpass/snackpass-types";
import { useState, useMemo } from "react";

export const useCheckMenuItemHasPromo = () => {
    const [promotions, setPromotions] = useState<IPromotion[]>([]);

    const productIdToPromotionsMap = useMemo(() => {
        const newMap: Record<string, string[]> = {};
        promotions.forEach((promotion) => {
            promotion.productIds.forEach((productId) => {
                if (newMap[productId]) {
                    newMap[productId].push(promotion.name);
                } else {
                    newMap[productId] = [promotion.name];
                }
            });
        });
        return newMap;
    }, [promotions.length]);

    return { promotions, setPromotions, productIdToPromotionsMap };
};
