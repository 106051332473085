"use client";

import {
    MessageSquare,
    Bell,
    Globe,
    Store,
    Smartphone,
    Gift,
    Tag,
    CreditCard,
} from "lucide-react";
import { Routes } from "#navigation/routes";
import { OverviewLayout } from "src/@/components/ui/overview-layout";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";

export const MarketingOverview = () => {
    const store = useSelector(getActiveStore);

    const marketingShortcuts = [
        {
            title: "Customer Engagement",
            description: "Connect with your customers.",
            icon: <MessageSquare className="h-5 w-5" />,
            items: [
                {
                    name: "SMS Campaigns",
                    description:
                        "Reach your audience with text message campaigns.",
                    path: Routes.GuestbookCampaignsSMS,
                    icon: <MessageSquare className="h-4 w-4" />,
                },
                {
                    name: "Push Notifications",
                    description:
                        "Send notifications to Snackpass App customers to stay top of mind.",
                    path: Routes.GuestbookCampaignsPush,
                    icon: <Bell className="h-4 w-4" />,
                },
            ],
        },
        {
            title: "Digital Presence",
            description: "Manage your online storefront and brand.",
            icon: <Globe className="h-5 w-5" />,
            items: [
                {
                    name: "Online Ordering",
                    description: "Customize your online ordering experience.",
                    path: `https://order.snackpass.co/${store?.slug}`,
                    icon: <Store className="h-4 w-4" />,
                    external: true,
                },
                {
                    name: "Website",
                    description:
                        "Create a full website with custom domain in minutes.",
                    path: "https://app.snackpass.site/",
                    icon: <Globe className="h-4 w-4" />,
                    external: true,
                },
                {
                    name: "Custom App",
                    description: "Get started with your branded mobile app.",
                    path: "https://airtable.com/appy8eQbMkVKrsnZ4/paglW8OzypSkSbqep/form",
                    icon: <Smartphone className="h-4 w-4" />,
                    external: true,
                },
            ],
        },
        {
            title: "Promotions",
            description: "Drive sales with special offers.",
            icon: <Gift className="h-5 w-5" />,
            items: [
                {
                    name: "Create a Promo",
                    description:
                        "Set up new promotions and offers like happy hour or first time discount.",
                    path: Routes.Promotion,
                    icon: <Tag className="h-4 w-4" />,
                },
                {
                    name: "Gift Cards",
                    description: "Manage digital and physical gift cards.",
                    path: Routes.SettingsGiftCard,
                    icon: <CreditCard className="h-4 w-4" />,
                },
            ],
        },
    ];

    return (
        <OverviewLayout
            title="Marketing"
            description="Tools to grow your business and engage customers"
            sections={marketingShortcuts}
        />
    );
};
