import {
    Addon,
    FulfillmentTypeEnum,
    IProduct,
} from "@snackpass/snackpass-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Col, Row } from "antd";
import { Dispatch, SetStateAction } from "react";
import {
    ClickAwayListener,
    TextField,
    styled as muiStyled,
    TextFieldProps,
} from "@material-ui/core";
import { flow } from "lodash";

import { isNotUndefined } from "#core";
import {
    extractTaxPolicies,
    fulfillmentPropertyMap,
    getFulfillmentTaxes,
    isAddon,
    isProduct,
} from "#menu-editor/multi-menus/helpers";
import { getActiveStore } from "src/redux/selectors";
import { TaxesText } from "#menu-editor/multi-menus/shared-components/taxes-text";
import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import { PriceOverrideInput } from "#menu-editor/multi-menus/shared-components/price-override-input";
import { TaxOverrideInput } from "#menu-editor/multi-menus/shared-components/tax-override-input";
import { PriceText } from "#menu-editor/multi-menus/shared-components/price-text";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { getCurrentMultiMenuId } from "#menu-editor/multi-menus/redux/selectors";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";

type OverridesCellProps = {
    overrideCellStates: Record<string, { price: boolean; taxes: boolean }>;
    togglePriceOverrideCell: () => void;
    toggleTaxesOverrideCell: () => void;
    originalRow: IProductCategoryWithProducts | IProduct | Addon;
    setOverrideCellStates: Dispatch<
        SetStateAction<Record<string, { price: boolean; taxes: boolean }>>
    >;
};

export const OverridesCell: React.FC<OverridesCellProps> = ({
    originalRow,
    overrideCellStates,
    togglePriceOverrideCell,
    toggleTaxesOverrideCell,
    setOverrideCellStates,
}) => {
    const activeStore = useSelector(getActiveStore);
    const currentMenuId = useSelector(getCurrentMultiMenuId);
    const dispatch = useDispatch();

    const closeCell = (cellId: string) => () => {
        setOverrideCellStates((prev) =>
            prev[cellId]?.price || prev[cellId]?.taxes
                ? {
                      ...prev,
                      [cellId]: { price: false, taxes: false },
                  }
                : prev,
        );
    };

    const getProductBaseTaxes = (product: IProduct) =>
        !isNotUndefined(product.taxInfo)
            ? activeStore?.taxRate ?? 0
            : product.taxInfo.rate;

    if (!isProduct(originalRow) && !isAddon(originalRow)) return null;

    if (
        !overrideCellStates[originalRow._id]?.price &&
        !overrideCellStates[originalRow._id]?.taxes
    ) {
        return (
            <Row>
                <Col onClick={togglePriceOverrideCell} span={8}>
                    <Row justify="end">
                        <PriceText
                            itemId={originalRow._id}
                            itemPrice={originalRow.price}
                            itemType={
                                isProduct(originalRow) ? "products" : "addons"
                            }
                        />
                    </Row>
                </Col>

                <Col
                    onClick={
                        isProduct(originalRow)
                            ? toggleTaxesOverrideCell
                            : undefined
                    }
                    span={16}
                >
                    {isProduct(originalRow) ? (
                        <Row justify="end">
                            <TaxesText
                                productId={originalRow._id}
                                productBaseTaxes={getProductBaseTaxes(
                                    originalRow,
                                )}
                                {...extractTaxPolicies(originalRow)}
                            />
                        </Row>
                    ) : null}
                </Col>
            </Row>
        );
    }

    const onClickAway = flow([
        () =>
            dispatch(
                multiMenuActions.selectMultiMenu({
                    id: currentMenuId,
                }),
            ),
        closeCell(originalRow._id),
    ]);

    if (
        overrideCellStates[originalRow._id].price ||
        overrideCellStates[originalRow._id].taxes
    ) {
        return (
            <ClickAwayListener onClickAway={onClickAway}>
                <Row style={commonStyles.fillParentHeight}>
                    {overrideCellStates[originalRow._id].price ? (
                        <InputWrapper span={8}>
                            <PushToBottom>
                                <PriceOverrideInput
                                    item={originalRow}
                                    inputComponent={StyledTextField}
                                    inputComponentProps={styledTextFieldProps}
                                />
                            </PushToBottom>
                        </InputWrapper>
                    ) : null}

                    {overrideCellStates[originalRow._id].taxes &&
                    isProduct(originalRow)
                        ? Object.values(FulfillmentTypeEnum).map(
                              (fulfillment, index) => (
                                  <InputWrapper
                                      span={8}
                                      borderRight={index !== 2}
                                  >
                                      <PushToBottom>
                                          <TaxOverrideInput
                                              item={{
                                                  ...originalRow,
                                                  taxInfo: {
                                                      rate: getFulfillmentTaxes(
                                                          getProductBaseTaxes(
                                                              originalRow,
                                                          ),
                                                          extractTaxPolicies(
                                                              originalRow,
                                                          ),
                                                          fulfillment,
                                                      ),
                                                  },
                                              }}
                                              fulfillment={fulfillmentPropertyMap(
                                                  fulfillment,
                                              )}
                                              inputComponent={StyledTextField}
                                              inputComponentProps={
                                                  styledTextFieldProps
                                              }
                                          />
                                      </PushToBottom>
                                  </InputWrapper>
                              ),
                          )
                        : null}
                </Row>
            </ClickAwayListener>
        );
    }

    return null;
};

const InputWrapper = styled(Col)<{ borderRight?: boolean }>`
    display: flex;
    border-right: ${({ borderRight }) =>
        borderRight ? "1px solid #e1e3e6" : "none"};
`;

const PushToBottom = styled.div`
    margin-top: auto;
`;

const StyledTextField = muiStyled(TextField)({
    "& .MuiFilledInput-root": {
        background: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
        background: "transparent",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        background: "transparent",
    },
    "& .MuiFilledInput-underline:before": {
        borderBottom: "none",
        marginBottom: "-1px",
    },
    "& .MuiFilledInput-underline:after": {
        borderBottom: "2px solid #282D32",
        marginBottom: "-1px",
    },
    "& .MuiInputBase-input": {
        "&:focus": {
            boxShadow: "none",
            border: "none",
        },
    },
    "& .MuiFormLabel-root.Mui-focused": {
        color: "#0077FF",
    },
});

const styledTextFieldProps = {
    InputLabelProps: {
        shrink: true,
    },
    variant: "filled",
} as TextFieldProps;
