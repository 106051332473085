import { ActiveSelection, Canvas, FabricObject } from "fabric";

import { Element } from "#table-editor/canvas/Element";
import { EditorAction, EditorState } from "#table-editor/store";

export function withIsCanvasDirty(
    canvas: Canvas,
    editor: () => EditorState & EditorAction
) {
    const markDirty = (obj?: FabricObject) => {
        if (!editor().isReady) {
            editor().setDirty(false);
            return;
        }

        if (obj?.isType(Element.type)) {
            editor().setDirty(true);
        }

        if (
            obj?.isType(ActiveSelection.type) &&
            (obj as ActiveSelection).getObjects(Element.type).length > 0
        ) {
            editor().setDirty(true);
        }
    };

    const scaling = canvas.on("object:scaling", (e) => markDirty(e.target));
    const moving = canvas.on("object:moving", (e) => markDirty(e.target));
    const added = canvas.on("object:added", (e) => markDirty(e.target));
    const removed = canvas.on("object:removed", (e) => markDirty(e.target));

    return () => {
        scaling();
        moving();
        added();
        removed();
    };
}
