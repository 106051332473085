import clsx from "clsx";

import { Switch } from "src/@/components/ui/switch";

type Props = {
    checked?: boolean;
    onCheckedChange?: (x: boolean) => void;
    title: string;
    description?: React.ReactNode;
    disabled?: boolean;
    ariaLabel?: string;
    className?: string;
};

export function ToggleRow({
    checked,
    onCheckedChange,
    title,
    ariaLabel,
    description,
    disabled,
    className,
}: Props) {
    return (
        <div
            className={clsx(
                "flex items-center justify-between border-b border-neutral-400 py-6",
                className,
            )}
        >
            <div>
                <span className="block text-small font-semibold">{title}</span>
                {description && (
                    <span className="mt-1 block text-small text-neutral-600">
                        {description}
                    </span>
                )}
            </div>
            <Switch
                aria-label={ariaLabel}
                disabled={disabled}
                onCheckedChange={onCheckedChange}
                checked={checked}
            />
        </div>
    );
}
