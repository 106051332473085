import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "sonner";

import { CreatePromoButton } from "#promotion/components/create-promo-button";
import PromoTable from "#promotion/components/promos-table";
import { FormSubmitState } from "#promotion/utils/types";
import { Separator } from "src/@/components/ui/separator";

interface PromotionsProps extends Omit<RouteComponentProps, "location"> {
    location: {
        state: FormSubmitState;
    };
}

const Promotions = ({ location: { state } }: PromotionsProps) => {
    // Only run once on load
    useEffect(() => {
        if (state) {
            if (state.success) {
                toast.success(
                    `Promotion Successfully ${
                        state.formUsage == "Create" ? "Created" : "Edited"
                    }`,
                );
            } else {
                toast.error(`${state.formUsage} Promotion Failure`);
            }

            // Clear state after toasting
            window.history.replaceState({}, "");
        }
    }, []);

    return (
        <div>
            <div className="p-6 md:px-24">
                <div className="flex items-center justify-between">
                    <h3 className="m-0  font-semibold leading-10">Promos</h3>
                    <CreatePromoButton />
                </div>
            </div>
            <Separator className="" />
            <div className="mb-8 flex w-full flex-col space-y-12 p-6 md:px-24">
                <PromoTable />
            </div>
        </div>
    );
};

export default Promotions;
