import React, { ReactNode } from "react";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { TooltipTrigger } from "@radix-ui/react-tooltip";

import { ReactComponent as CheckMark } from "src/assets/icons/checked.svg";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
} from "src/@/components/ui/tooltip";

type Props = {
    onChange: (newStatus: boolean) => void;
    children?: ReactNode;
    isOn: boolean;
    id: string;
    disabled?: boolean;
    disabledMessage?: string;
};

type SwitchProps = {
    disabled: boolean;
};

const SwitchStyles = styled.span<SwitchProps>`
    .react-switch-checkbox {
        height: 0;
        width: 0;
        display: none;
    }

    .react-switch-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 45px;
        height: 28px;
        background: grey;
        border-radius: 100px;
        position: relative;
        transition: background-color 0.2s;
        ${({ disabled }) =>
            disabled &&
            `cursor: not-allowed; background-color: ${SystemColors.v1.gray60};`}
    }

    .react-switch-label .react-switch-button {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        width: 24px;
        height: 24px;
        border-radius: 45px;
        transition: all 0.25s ease-in-out;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        overflow: hidden;
    }

    .check-mark {
        position: absolute;
        top: 30%;
        left: 30%;
        height: 10px;
        width: 10px;
        color: #0077ff;
    }

    .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        ${({ disabled }) => disabled && `cursor: not-allowed; opacity: 0.5;`}
    }
`;

const Switch: React.FC<Props> = ({
    onChange,
    isOn,
    id,
    disabled = false,
    disabledMessage,
}) => (
    <SwitchStyles disabled={disabled}>
        <input
            checked={isOn}
            onChange={() => onChange && onChange(!isOn)}
            className="react-switch-checkbox"
            id={id + isOn}
            type="checkbox"
            disabled={disabled}
        />
        {disabled ? (
            <TooltipProvider delayDuration={100}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <label
                            style={{ background: isOn ? " #0077FF" : "" }}
                            className="react-switch-label"
                            htmlFor={id + isOn}
                        >
                            <span className={`react-switch-button`}>
                                {isOn ? (
                                    <CheckMark className="check-mark" />
                                ) : (
                                    <></>
                                )}
                            </span>
                        </label>
                    </TooltipTrigger>

                    <TooltipContent
                        className="z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-600 px-3 py-2 text-center text-micro font-normal text-neutral-50"
                        sideOffset={5}
                    >
                        {disabledMessage}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        ) : (
            <label
                style={{ background: isOn ? " #0077FF" : "" }}
                className="react-switch-label"
                htmlFor={id + isOn}
            >
                <span className={`react-switch-button`}>
                    {isOn ? <CheckMark className="check-mark" /> : <></>}
                </span>
            </label>
        )}
    </SwitchStyles>
);

export default Switch;
