import { Col, Row } from "antd";
import { Fab } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { ScreenState, Menu as MultiMenu } from "@snackpass/snackpass-types";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "src/assets/icons/plus.svg";
import { MenuTableRow } from "#menu-editor/multi-menus/screens/menus-desktop/components/menu-table-row";
import {
    FilterEnum,
    FilterType,
} from "#menu-editor/multi-menus/screens/menus-desktop/types";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import colors from "#reusable/colors/colors.json";

type Props = {
    activeMenus: MultiMenu[];
    inactiveMenus: MultiMenu[];
    filter: FilterType;
    isMobile: boolean;
    navigateToNewMenu: () => void;
};

const titles = ["Menu", "Time", "Platform", "Items"];

export const MenusTable: React.FC<Props> = ({
    activeMenus,
    inactiveMenus,
    filter,
    isMobile,
    navigateToNewMenu,
}) => (
    <BodyWrapper>
        {!isMobile && (
            <Row justify="space-between" style={styles.headerRow}>
                {titles.map((title) => (
                    <Col span={6} key={title}>
                        <Text style={styles.headerLabel} type="body-bold">
                            {title}
                        </Text>
                    </Col>
                ))}
            </Row>
        )}

        {filter !== FilterEnum.Inactive ? (
            <>
                <Row style={styles.enabledRow}>
                    <Text
                        type="body-bold"
                        color={isMobile ? "light-grey" : "black"}
                        style={styles.enabledText}
                    >
                        Active
                    </Text>
                </Row>

                {activeMenus.map((menu) => (
                    <MenuTableRow
                        menu={menu}
                        key={menu.id}
                        isMobile={isMobile}
                    />
                ))}
            </>
        ) : null}

        {filter !== FilterEnum.Active ? (
            <>
                <Row style={styles.enabledRow}>
                    <Text
                        type="body-bold"
                        color={isMobile ? "light-grey" : "black"}
                        style={styles.enabledText}
                    >
                        Inactive
                    </Text>
                </Row>

                {inactiveMenus.map((menu) => (
                    <InactiveRowWrapper key={menu.id}>
                        <MenuTableRow menu={menu} isMobile={isMobile} />
                    </InactiveRowWrapper>
                ))}
            </>
        ) : null}

        {isMobile && (
            <Fab
                color="inherit"
                aria-label="add"
                style={styles.fab}
                onClick={navigateToNewMenu}
            >
                <PlusIcon fill="#FFF" />
            </Fab>
        )}
    </BodyWrapper>
);

const styles = {
    headerLabel: {
        paddingLeft: "16px",
    } as CSSProperties,

    headerRow: {
        paddingBottom: "10px",
        borderBottom: `1px solid ${colors["neutral-400"]}`,
    } as CSSProperties,

    enabledRow: {
        backgroundColor: "#F1F5F8",
        borderBottom: `1px solid ${colors["neutral-400"]}`,
    } as CSSProperties,

    enabledText: {
        padding: "8px 16px",
    } as CSSProperties,

    fab: {
        position: "absolute",
        bottom: 100,
        right: 16,
        backgroundColor: "#0077FF",
    } as CSSProperties,
};

const InactiveRowWrapper = styled.div`
    * {
        color: #a0adba !important;
    }
`;

const BodyWrapper = styled.div`
    @media ${ScreenState.MOBILE} {
        padding: 0px;
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    padding: 24px 0px;
    overflow-y: scroll;
`;
