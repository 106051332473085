import { InfoCircledIcon, PlusIcon } from "@radix-ui/react-icons";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { NewMenuDialog, MenuTable } from "#menu-manager/components";
import { Button } from "src/@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Routes } from "#navigation/routes";

/**
 * XXX: This screen will exist only for the intermediate period between milestone 2 and 3 of menus v3, where we'll have store menus existing with the old menu editor.
 * Once the old menu editor is complete, we can delete this screen and its routing.
 */
export function SpecialMenuScreen() {
    const [newMenuDialogOpen, setNewMenuDialogOpen] = useState(false);
    const open = useCallback(() => setNewMenuDialogOpen(true), []);
    const close = useCallback(() => setNewMenuDialogOpen(false), []);

    return (
        <div>
            <Alert className="m-3 w-fit">
                <AlertTitle className="text-base">
                    <InfoCircledIcon className="mr-2" />
                    Special Menus
                </AlertTitle>
                <AlertDescription className="text-sm">
                    Use special menus to create and display a subsection of your
                    menu with optional price adjustment. Create these menus to
                    show during special events or specific times of day. These
                    menus will show across all Snackpass channels, but will not
                    affect your <Link to={Routes.MultiMenus}>third party</Link>{" "}
                    menus. Read{" "}
                    <a
                        target="_blank"
                        href="https://support.snackpass.co/en/articles/10023457-special-menus"
                    >
                        here
                    </a>{" "}
                    for more information.
                </AlertDescription>
            </Alert>
            <div className="flex items-center justify-between px-8 py-4">
                <NewMenuDialog open={newMenuDialogOpen} close={close} />
                <span className="text-large font-semibold">Special Menus</span>
                <div className="flex items-center">
                    <Button size="sm" onClick={open}>
                        <PlusIcon className="mr-1" />
                        New Menu
                    </Button>
                </div>
            </div>
            <MenuTable />
        </div>
    );
}
