/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITimeRangeSchema } from "@snackpass/snackpass-types";
import _ from "lodash";

const DAYS_OF_WEEK = ["1", "2", "3", "4", "5", "6", "7"] as const;

const DAY_NUMBER_TO_LABEL = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
] as const;

export type DayOfWeek = (typeof DAYS_OF_WEEK)[number];

export type HoursAndMinutes = {
    hour: number;
    minutes: number;
};

export type HoursAndMinutesByDay = Partial<{
    [key in DayOfWeek]: HoursAndMinutes[][];
}>;

export const getHoursByDay = (
    local: ITimeRangeSchema[],
): HoursAndMinutesByDay => {
    const hoursByDay: HoursAndMinutesByDay = {};
    local.forEach((range) => {
        const startDay = Math.floor(range.start / (60 * 24)) + 2;
        const startHour = Math.floor((range.start % (60 * 24)) / 60);
        const startHourMinutes = Math.floor((range.start % (60 * 24)) % 60);
        const endDay = Math.floor(range.end / (60 * 24)) + 2;
        const endHour = Math.floor((range.end % (60 * 24)) / 60);
        const endHourMinutes = Math.floor((range.end % (60 * 24)) % 60);

        for (let i = startDay; i <= endDay; i++) {
            // This is required to avoid TS complaining when indexing hoursByDay
            const stringedI = i.toString() as DayOfWeek;

            if (!hoursByDay[stringedI]) hoursByDay[stringedI] = [];
            const lowerBound =
                i > startDay
                    ? { hour: 0, minutes: 0 }
                    : { hour: startHour, minutes: startHourMinutes };
            const upperBound =
                i < endDay
                    ? { hour: 23, minutes: 59 }
                    : { hour: endHour, minutes: endHourMinutes };
            hoursByDay[stringedI]?.push([lowerBound, upperBound]);
        }
    });

    return mapObject(hoursByDay, ([dayOfWeek, hoursAndMinutes]) => [
        dayOfWeek === "8" ? "1" : dayOfWeek, // Remap 8 (Sunday) to 1
        hoursAndMinutes,
    ]);
};

/**
 * Return an object after applying the function to each key-value pair of the given object.
 *
 * @param {Object} obj
 * @param {(key: string, value: any) => [string, any]} fn
 */
export function mapObject(
    obj: Record<string, any>,
    fn: (key: string, value: any) => [string, any],
): Record<string, any> {
    //@ts-ignore
    return _.fromPairs(Object.entries(obj).map(fn));
}

export const getTimeLabel = (hours: HoursAndMinutesByDay): string => {
    const hoursArray = Object.keys(hours).map((hour) => parseInt(hour));

    // This shouldn't ever happen, but will include it just to be safe
    if (!hoursArray.length) return "Never";

    if (hoursArray.length === 7) return "Everyday";

    let isContinuous = true;
    let previousDayNumber = hoursArray[0] - 1;

    for (const dayNumber of hoursArray) {
        if (dayNumber - 1 !== previousDayNumber) {
            isContinuous = false;
            break;
        }

        previousDayNumber = dayNumber;
    }

    if (isContinuous && hoursArray.length > 1) {
        const start = DAY_NUMBER_TO_LABEL[hoursArray[0] - 1];
        const end = DAY_NUMBER_TO_LABEL[hoursArray[hoursArray.length - 1] - 1];

        return `${start} - ${end}`;
    }

    return hoursArray
        .map((dayNumber) => DAY_NUMBER_TO_LABEL[dayNumber - 1])
        .join(", ");
};

export const getDisplayHours = (hours: HoursAndMinutesByDay): string[] => {
    const hoursArray = Object.values(hours);

    // This shouldn't ever happen, but will include it just to be safe
    if (!hoursArray.length) return ["Never"];

    const someHours = hoursArray[0];

    const isMixedHours = hoursArray.some((hours) => {
        if (hours.length !== someHours.length) {
            return true;
        }

        return hours.some((interval, indx) => {
            const start = interval[0];
            const end = interval[1];

            const someStart = someHours[indx][0];
            const someEnd = someHours[indx][1];

            return (
                start.hour !== someStart.hour ||
                start.minutes !== someStart.minutes ||
                end.hour !== someEnd.hour ||
                end.minutes !== someEnd.minutes
            );
        });
    });

    if (isMixedHours) return ["Mixed Hours"];

    return someHours.map((interval) => {
        const start = interval[0];
        const end = interval[1];

        const startLabel =
            start.hour === 0 && start.minutes === 0
                ? "Open"
                : `${start.hour > 12 ? start.hour - 12 : start.hour}:${
                      start.minutes < 10 ? `0${start.minutes}` : start.minutes
                  } ${start.hour < 12 ? "AM" : "PM"}`;
        const endLabel =
            end.hour === 23 && end.minutes === 59
                ? "Close"
                : `${end.hour > 12 ? end.hour - 12 : end.hour}:${
                      end.minutes < 10 ? `0${end.minutes}` : end.minutes
                  } ${end.hour < 12 ? "AM" : "PM"}`;

        return `${startLabel} - ${endLabel}`;
    });
};
