import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { useFormContext } from "react-hook-form";

import { ReactComponent as ChevronRightIcon } from "src/assets/icons/chevron-right.svg";
import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FIELD_NAMES } from "#promotion/utils/types";

type Props = {
    children: React.ReactNode;
    fields: FIELD_NAMES[];
};

type OpenProps = {
    isOpen: boolean;
};

export const AdvancedContainer = ({ children, fields }: Props) => {
    const { watch } = useFormContext();
    const [showAdvanced, setShowAdvanced] = useState(false);

    const requiredPurchaseEnabled =
        fields.includes(FIELD_NAMES.REQUIRED_PURCHASE) &&
        watch(FIELD_NAMES.REQUIRED_PURCHASE);
    const onePerCartEnabled =
        fields.includes(FIELD_NAMES.ONE_PER_CART) &&
        watch(FIELD_NAMES.ONE_PER_CART);
    const singleUseEnabled =
        fields.includes(FIELD_NAMES.SINGLE_USE) &&
        watch(FIELD_NAMES.SINGLE_USE);
    const limitCustomersEnabled =
        fields.includes(FIELD_NAMES.LIMIT_USES) &&
        watch(FIELD_NAMES.LIMIT_USES);
    const promoCodeEnabled =
        fields.includes(FIELD_NAMES.PROMO_CODE_ENABLED) &&
        watch(FIELD_NAMES.PROMO_CODE_ENABLED);
    const scheduleEnabled =
        fields.includes(FIELD_NAMES.SCHEDULE_ENABLED) &&
        watch(FIELD_NAMES.SCHEDULE_ENABLED);
    const durationEnabled =
        fields.includes(FIELD_NAMES.DURATION_ENABLED) &&
        watch(FIELD_NAMES.DURATION_ENABLED);

    const enabledToggles = useMemo((): string => {
        const enabled: string[] = [];

        if (requiredPurchaseEnabled) enabled.push("Required Purchase");
        if (onePerCartEnabled) enabled.push("One Per Cart");
        if (singleUseEnabled) enabled.push("Single Use");
        if (limitCustomersEnabled) enabled.push("Limit Total Customers");
        if (promoCodeEnabled) enabled.push("Promo Code");
        if (scheduleEnabled) enabled.push("Schedule");
        if (durationEnabled) enabled.push("Duration");

        return enabled.length ? "Enabled: " + enabled.join(", ") : "";
    }, [
        requiredPurchaseEnabled,
        onePerCartEnabled,
        singleUseEnabled,
        limitCustomersEnabled,
        promoCodeEnabled,
        scheduleEnabled,
        durationEnabled,
    ]);

    return (
        <Container>
            <HeaderContainer onClick={() => setShowAdvanced(!showAdvanced)}>
                <LabelContainer>
                    <FormFieldName name={"Advanced Settings"} size="lg" />
                    <ChevronRight isOpen={showAdvanced}>
                        <ChevronRightIcon
                            width={16}
                            height={16}
                            fill={SystemColors.v1.gray10}
                        />
                    </ChevronRight>
                </LabelContainer>
                {showAdvanced ? null : (
                    <FormFieldDescriptor descriptor={enabledToggles} />
                )}
            </HeaderContainer>
            <AdvancedContentContainer isOpen={showAdvanced}>
                {children}
            </AdvancedContentContainer>
        </Container>
    );
};

const Container = styled.div`
    padding-top: 24px;
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 24px 0;
`;

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const ChevronRight = styled.div<OpenProps>`
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-bottom: 4px;
    transition: all 0.1s ease-in-out;
    ${(props) =>
        props.isOpen ? `transform: rotate(90deg);` : `transform: rotate(0deg);`}
`;

const AdvancedContentContainer = styled.div<OpenProps>`
    ${(props) => !props.isOpen && `display: none`}
`;
