import { useFormContext } from "react-hook-form";

import { CardDescription } from "src/@/components/ui/card";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import CampaignCardContent from "#guestbook/shared-components/CampaignCardContent";

function CampaignNameCardInput() {
    const { control } = useFormContext();
    return (
        <CampaignCardContent>
            <FormField
                control={control}
                name="campaignName"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <p className="text-xl font-semibold">
                                Campaign Name
                            </p>
                        </FormLabel>
                        <CardDescription>
                            Used to identify your Campaign in the Dashboard and
                            Reports. Customers will not see this.
                        </CardDescription>
                        <FormControl>
                            <Input {...field} placeholder="Campaign name" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </CampaignCardContent>
    );
}

export default CampaignNameCardInput;
