"use client";

import {
    Store,
    Monitor,
    QrCode,
    Plug,
    UtensilsCrossed,
    FileText,
} from "lucide-react";
import { Routes } from "#navigation/routes";
import { OverviewLayout } from "src/@/components/ui/overview-layout";

export const BusinessToolsOverview = () => {
    const businessToolsShortcuts = [
        {
            title: "Hardware",
            description: "Add and manage the devices at your store.",
            icon: <Store className="h-5 w-5" />,
            items: [
                {
                    name: "Buy Hardware",
                    description:
                        "Purchase devices like additional tablets, kiosks, and more.",
                    path: "https://store.snackpass.co/",
                    icon: <Store className="h-4 w-4" />,
                    external: true,
                },
                {
                    name: "Devices",
                    description: "Manage your existing devices.",
                    path: Routes.Devices,
                    icon: <Monitor className="h-4 w-4" />,
                },
            ],
        },
        {
            title: "Digital Tools",
            description: "Enhance your digital presence.",
            icon: <QrCode className="h-5 w-5" />,
            items: [
                {
                    name: "QR Codes",
                    description:
                        "Generate and manage QR codes for your business.",
                    path: Routes.SettingsQrCode,
                    icon: <QrCode className="h-4 w-4" />,
                },
                {
                    name: "Integrations",
                    description: "Connect with delivery services and more.",
                    path: Routes.SettingsIntegrations,
                    icon: <Plug className="h-4 w-4" />,
                },
            ],
        },
        {
            title: "Services",
            description: "Tools for events and large orders.",
            icon: <UtensilsCrossed className="h-5 w-5" />,
            items: [
                {
                    name: "Catering",
                    description: "Manage catering orders and events.",
                    path: Routes.SettingsCatering,
                    icon: <UtensilsCrossed className="h-4 w-4" />,
                },
                {
                    name: "Invoices",
                    description: "Create and send invoices.",
                    path: Routes.Invoices,
                    icon: <FileText className="h-4 w-4" />,
                },
            ],
        },
    ];

    return (
        <OverviewLayout
            title="Business Tools"
            description="Essential tools for running your business"
            sections={businessToolsShortcuts}
        />
    );
};
