import { useFormContext } from "react-hook-form";

import { Card } from "src/@/components/ui/card";
import { FormControl, FormField } from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import CampaignFormItemLayout from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/CampaignFormItemLayout";

function AreaCodeCardInput() {
    const { control } = useFormContext();
    return (
        <Card>
            <FormField
                control={control}
                name="desiredAreaCode"
                render={({ field }) => (
                    <CampaignFormItemLayout
                        label="Desired Area Code"
                        description={
                            <>
                                The number you use to send messages will start
                                with these three numbers. Subject to area code
                                availability.
                            </>
                        }
                        subDescription={
                            <>
                                If there are no more phone numbers that exist
                                with this area code, we will attempt to choose a
                                phone number whose area code is close to your
                                business address.
                            </>
                        }
                    >
                        <FormControl>
                            <Input {...field} placeholder="555" />
                        </FormControl>
                    </CampaignFormItemLayout>
                )}
            />
        </Card>
    );
}

export default AreaCodeCardInput;
