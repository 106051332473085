import { IAddress, IUser } from "@snackpass/snackpass-types";
import _ from "lodash";
import fp from "lodash/fp";

import { SelectOptionStore, OptionType } from "#store-selector/types";
import { filterNulls } from "src/utils/filterNulls";

export const _showStoreSelector = (user: IUser) => {
    const isTeam = fp.get("snackpassPermissions.isSnackpassEmployee")(user);
    const hasMultipleStores =
        fp.prop("snackpassPermissions.storeIds.length")(user) > 1;

    return hasMultipleStores || isTeam;
};

const _isDefault = (component?: string | null) =>
    /default/.test(component ?? "");

const _defaultAddress = (address: IAddress) =>
    _isDefault(address?.line1) ||
    _isDefault(address?.city) ||
    _isDefault(address?.state);

export const _formatOption = (store: SelectOptionStore): OptionType => {
    const label = store.name.trim();
    const address = store.addressComponents;

    const hasLocation = !_.isNil(
        address?.line1 && address.city && address.state,
    );

    const defaultLocation = _defaultAddress(address);

    const subtitle =
        hasLocation && !defaultLocation
            ? `${address.line1} ${address.city}, ${address.state}`
            : undefined;

    return {
        label,
        subtitle,
        value: store._id,
    };
};

export const filterOption = (input: string, value?: string) =>
    value?.toLowerCase().includes(input.toLowerCase()) ?? false;

export const filterSort = (a = "", b = "") =>
    a?.toLowerCase().localeCompare(b.toLowerCase());

// see https://github.com/krisk/Fuse/issues/235
// Split the provided string by spaces (ignoring spaces within "quoted text")
export const _tokeniseStringWithQuotesBySpaces = (string: string): string[] =>
    string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];

export const _filterArchivedStores = (
    stores: (SelectOptionStore | null)[],
): SelectOptionStore[] =>
    filterNulls(stores).filter((store) => store.isArchived !== true);
