import { z } from "zod";
import _ from "lodash";

export const ITimeRangeSchema = z.object({
    start: z.number(),
    end: z.number(),
});

export const RegularHoursSchema = z.object({
    weekday: z.number(),
    range: ITimeRangeSchema,
});

export const SpecialHoursSchema = z.object({
    date: z.date(),
    isClosed: z.boolean(),
    range: ITimeRangeSchema,
});

export const ObjectIdSchema = z.string();

export const IAddressSchema = z
    .object({
        line1: z.string(),
        line2: z.string().nullable(),
        city: z.string(),
        county: z.string().nullable(),
        state: z.string(),
        zip: z.string(),
        country: z.string().length(3),
        full: z.string(),
    })
    .refine((data) => data.line1.length > 0, {
        message: "Address is required",
    })
    .refine((data) => data.city.length > 0, {
        message: "City is required",
    })
    .refine((data) => data.state.length === 2, {
        message: "State must be valid and required",
    })
    .refine((data) => data.zip.length === 5, {
        message: "Zip code must be valid and required",
    });

export const GeolocationSchema = z.object({
    type: z.string(),
    coordinates: z
        .tuple([z.number(), z.number()])
        .refine(
            (coords) =>
                !_.isNil(coords) && _.isArray(coords) && coords.length === 2,
        ),
});

export const StoreKindSchema = z.union([
    z.literal("food_truck"),
    z.literal("brick_and_mortar"),
    z.literal("other"),
]);
