import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import {
    Break,
    BreakType,
    IPayPeriod,
    ScreenState,
} from "@snackpass/snackpass-types";
import moment, { Moment } from "moment-timezone";
import { DatePicker, notification as AntdNotification, Select } from "antd";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Maybe } from "@snackpass/snackpass-types/src/utils/types";
import { useMutation } from "@apollo/client";
import { z } from "zod";

import api from "src/api/rest";
import {
    CreateShiftByAdmin,
    DeleteShiftByAdmin,
    UpdateShiftByAdmin,
} from "src/api/graphql/apollo";
import { getActiveStore } from "src/redux/selectors";
import { EmployeeData } from "#core/types";
import { ReactComponent as BackArrow } from "src/assets/icons/chevron-left.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/pencil-over-paper.svg";
import { ReactComponent as CalendarSVG } from "src/assets/icons/calendar.svg";
import { ReactComponent as PersonSVG } from "src/assets/icons/person.svg";
import { ReactComponent as ClockSVG } from "src/assets/icons/clock.svg";
import { ReactComponent as AlertSVG } from "src/assets/icons/alert.svg";
import { ReactComponent as LeftArrowSVG } from "src/assets/icons/left-arrow-v2.svg";
import { ReactComponent as RightArrowSVG } from "src/assets/icons/right-arrow-v2.svg";
import UpDownArrowsSVG from "src/assets/icons/up-down-arrows.svg";
import { ReactComponent as TrashCanSVG } from "src/assets/icons/trash-can.svg";
import { TimecardData } from "#time-and-attendance/types";
import "./antd.custom.css";
import { getStoreTimezone } from "#utils/helpers";

import { PickerProps } from "antd/lib/date-picker/generatePicker";

import { logAndSendError } from "src/utils/errors";

enum ShiftMetrics {
    ClockedInAt = "Clocked In At",
    ClockedOutAt = "Clocked Out At",
    CashTips = "Cash Tips",
    BreakType = "Break Type",
    BreakStart = "Break Start",
    BreakEnd = "Break End",
}

type EmployeeOption = {
    label: string;
    value: string;
};

type EditableTimecardData = Omit<
    TimecardData,
    "totalShifts" | "clockedInAt" | "clockedOutAt"
> & {
    clockedInAt: TimecardData["clockedInAt"] | null;
    clockedOutAt: TimecardData["clockedOutAt"] | null;
};

type EditableBreak = Omit<
    Break,
    "type" | "isDeleted" | "start" | "actualDuration"
> & { type: Maybe<BreakType>; start: Maybe<Date> };

type TimeCardViewEditProps = {
    isCreating: boolean;
    onHide: () => void;
    timecard: Maybe<TimecardData>;
    shifts: any[];
    currentShiftIndex: Maybe<number>;
    setSelectShiftIndex: Dispatch<SetStateAction<Maybe<number>>>;
    onMoveOneShift: ({ advance }: { advance: boolean }) => void;
    onShiftChange: () => void;
    selectedPayPeriodId?: IPayPeriod["id"];
};

const BREAKS_LIMIT = 5;

const TimeCardViewEdit = ({
    isCreating,
    onHide,
    timecard,
    shifts,
    currentShiftIndex,
    setSelectShiftIndex,
    onMoveOneShift,
    onShiftChange,
    selectedPayPeriodId,
}: TimeCardViewEditProps) => {
    const timecardData: EditableTimecardData = timecard || {
        id: "",
        employeeId: "-1",
        employeeName: "",
        employeeRole: "",
        clockedInAt: null,
        clockedOutAt: null,
        breaks: [],
        regularHours: 0,
        overtimeHours: 0,
        doubleOvertimeHours: 0,
        totalHours: 0,
        cashTips: 0,
        wage: 0,
        note: "",
    };
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [employees, setEmployees] = useState<EmployeeOption[]>([]);
    const [employeeId, setEmployeeId] = useState<TimecardData["employeeId"]>(
        timecardData.employeeId,
    );
    const [clockedInAt, setClockedInAt] = useState<Maybe<Date>>(
        timecardData.clockedInAt,
    );
    const [clockedOutAt, setClockedOutAt] = useState<
        TimecardData["clockedOutAt"]
    >(timecardData.clockedOutAt);
    const [cashTips, setCashTips] = useState<TimecardData["cashTips"]>(
        timecardData.cashTips,
    );
    const [breaks, setBreaks] = useState<EditableBreak[]>(timecardData.breaks);
    const [nextBreakId, setNextBreakId] = useState<number>(-2);
    const [note, setNote] = useState<TimecardData["note"]>(
        timecardData.note || "",
    );
    const activeStore = useSelector(getActiveStore);
    const [createShiftByAdmin] = useMutation(CreateShiftByAdmin);
    const [updateShiftByAdmin] = useMutation(UpdateShiftByAdmin);
    const [deleteShiftByAdmin] = useMutation(DeleteShiftByAdmin);
    const clockedInMoment = moment(timecardData.clockedInAt);
    const clockedOutMoment = moment(timecardData.clockedOutAt ?? new Date());
    const isMultipleDaysShift =
        clockedInMoment.dayOfYear() !== clockedOutMoment.dayOfYear();
    const storeTimezone = getStoreTimezone(activeStore);
    moment.tz.setDefault(storeTimezone);

    const wage = timecardData.wage;
    const dateFormat = "ddd, MMM D \\at h:mm a";
    const timeFormat = "h:mm a";

    const DEFAULT_BREAK: EditableBreak = {
        id: nextBreakId.toString(),
        type: null,
        start: null,
        end: null,
        expectedDuration: 0,
    };

    const formatTotalHours = (totalHours: number): string => {
        const hours = Math.trunc(totalHours);
        const minutes = ((totalHours % 1) * 60).toFixed(0);
        return `${hours}h ${minutes}m`;
    };

    const formatTime = (time: Maybe<Date>): string =>
        time ? moment(time).format("h:mm A") : "ongoing";

    const handleBack = (): void => {
        if (isEditing && !isCreating) {
            setIsEditing(false);
        } else {
            onHide();
        }
    };

    const handleAddEmptyBreak = (): void => {
        const hasNoBreaks = breaks.length <= 0;
        const isUnderLimit = breaks.length < BREAKS_LIMIT;
        const hasAllValidBreaks = breaks.every(
            (breakItem) => breakItem.type && breakItem.start && breakItem.end,
        );

        if (hasNoBreaks || (isUnderLimit && hasAllValidBreaks)) {
            setBreaks([...breaks, DEFAULT_BREAK]);
            setNextBreakId((currentBreakId) => currentBreakId - 1);
        }
    };

    enum KeyToChange {
        Type = "type",
        Start = "start",
        End = "end",
    }

    const handleBreakChange = (
        breakId: Break["id"],
        keyToChange: KeyToChange,
        newValue: any,
    ): void => {
        const newBreaks = [...breaks];
        newBreaks.forEach((breakItem) => {
            if (breakItem.id === breakId) {
                if (
                    keyToChange === KeyToChange.Start ||
                    keyToChange === KeyToChange.End
                ) {
                    newValue = newValue
                        ? moment(newValue).startOf("minute").toDate()
                        : newValue;
                }
                breakItem[keyToChange] = newValue;
                setBreaks(newBreaks);
                handleAddEmptyBreak();
            }
        });
    };

    type HandleChangeProps = {
        type: ShiftMetrics;
        time?: Moment | null;
        timeString?: string;
        breakId?: Break["id"];
        breakType?: Maybe<BreakType>;
    };

    const handleChange = ({
        type,
        time,
        breakId,
        breakType,
    }: HandleChangeProps): void => {
        const newTime = time?.toDate() ?? null;

        switch (type) {
            case ShiftMetrics.ClockedInAt:
                setClockedInAt(newTime);
                break;
            case ShiftMetrics.ClockedOutAt:
                setClockedOutAt(newTime);
                break;
            case ShiftMetrics.BreakType:
                if (breakId) {
                    handleBreakChange(breakId, KeyToChange.Type, breakType);
                }
                break;
            case ShiftMetrics.BreakStart:
                if (breakId) {
                    handleBreakChange(breakId, KeyToChange.Start, newTime);
                }
                break;
            case ShiftMetrics.BreakEnd:
                if (breakId) {
                    handleBreakChange(breakId, KeyToChange.End, newTime);
                }
                break;
        }
    };

    const handleDeleteBreak = (breakId: Break["id"]): void => {
        const newBreaks = [...breaks].filter(
            (breakItem) => breakItem.id !== breakId,
        );
        setBreaks(newBreaks);
    };

    const getValidatedBreaks = (): EditableBreak[] => {
        const validatedBreaks = breaks
            .filter(
                (breakItem) =>
                    breakItem.type && breakItem.start && breakItem.end,
            )
            .map((aBreak) => ({
                ...aBreak,
                expectedDuration: 0,
            }));

        return validatedBreaks;
    };

    const isValidShift = (): boolean => {
        if (!employeeId || !clockedInAt) {
            return false;
        }
        return true;
    };

    const handleCreateShift = async (): Promise<void> => {
        if (activeStore && selectedPayPeriodId) {
            try {
                const request = {
                    storeId: activeStore._id,
                    employeeId: employeeId,
                    payPeriodId: selectedPayPeriodId,
                    clockedInAt,
                    breaks: getValidatedBreaks(),
                    clockedOutAt,
                    cashTips,
                    note,
                };

                const response = await createShiftByAdmin({
                    variables: request,
                });

                if (response.data) {
                    AntdNotification.success({
                        message: "Shift created successfully",
                    });
                    onShiftChange();
                    onHide();
                    setIsEditing(false);
                }
            } catch (error: any) {
                logAndSendError(error);
                AntdNotification.error({
                    message: "Error creating shift",
                    description: error.message,
                });
            }
        }
    };

    const handleEditShift = async (): Promise<void> => {
        if (activeStore) {
            try {
                const request = {
                    storeId: activeStore._id,
                    shiftId: timecardData.id,
                    clockedInAt,
                    breaks: getValidatedBreaks(),
                    clockedOutAt,
                    cashTips,
                    wage,
                    note,
                };

                const response = await updateShiftByAdmin({
                    variables: request,
                });

                if (response.data) {
                    AntdNotification.success({
                        message: "Shift updated successfully",
                    });
                    onShiftChange();
                    onHide();
                    setIsEditing(false);
                }
            } catch (error: any) {
                logAndSendError(error);
                AntdNotification.error({
                    message: "Error updating shift",
                    description: error.message,
                });
            }
        }
    };

    const handleDeleteShift = async (): Promise<void> => {
        if (activeStore) {
            try {
                const request = {
                    storeId: activeStore._id,
                    shiftId: timecardData.id,
                };

                const response = await deleteShiftByAdmin({
                    variables: request,
                });

                if (response.data) {
                    AntdNotification.success({
                        message: "Shift deleted successfully",
                    });
                    onShiftChange();
                    onHide();
                }
            } catch (error) {
                logAndSendError(error);
                const parseResult = z
                    .object({ message: z.string() })
                    .safeParse(error);
                AntdNotification.error({
                    message: "Error deleting shift",
                    description: parseResult.success
                        ? parseResult.data.message
                        : undefined,
                });
            }
        }
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
    };

    const shiftMetrics = [
        {
            type: ShiftMetrics.ClockedInAt,
            label: "Clock In",
            value: clockedInAt,
        },
        {
            type: ShiftMetrics.ClockedOutAt,
            label: "Clock Out",
            value: clockedOutAt,
        },
        {
            type: ShiftMetrics.CashTips,
            label: "Cash Tips",
            value: cashTips,
        },
    ];

    useEffect(() => {
        setEmployeeId(timecardData.employeeId);
        setClockedInAt(timecardData.clockedInAt);
        setClockedOutAt(timecardData.clockedOutAt);
        setCashTips(timecardData.cashTips);
        setBreaks(timecardData.breaks);
        setNote(timecardData.note || "");
    }, [timecardData.id]);

    useEffect(() => {
        if (isCreating) {
            setIsEditing(true);
        }
    }, [isCreating]);

    useEffect(() => {
        handleAddEmptyBreak();
    }, [breaks.length]);

    useEffect(() => {
        const fetchEmployees = async () => {
            if (activeStore && isCreating) {
                try {
                    const response = await api.stores.getEmployees(
                        activeStore._id,
                    );
                    const employeesOptions = response.data.employees.map(
                        (employee: EmployeeData) => ({
                            label: `${employee.firstName} ${employee.lastName}`,
                            value: employee.id,
                        }),
                    );
                    setEmployees(employeesOptions);
                } catch (err) {
                    logAndSendError(err);
                }
            }
        };
        void fetchEmployees();
    }, [activeStore?._id, isCreating]);

    const isBackDisabled = currentShiftIndex === 0;
    const isForwardDisabled = currentShiftIndex === shifts.length - 1;
    const disabledDate = (current: Moment, type: ShiftMetrics): boolean => {
        switch (type) {
            case ShiftMetrics.ClockedInAt:
                return current.isAfter(clockedOutAt, "second");
            case ShiftMetrics.ClockedOutAt:
                return current.isBefore(clockedInAt, "second");
            default:
                return false;
        }
    };

    return (
        <>
            <Container>
                {isEditing ? (
                    <>
                        <Header>
                            <HeaderLeft onClick={handleBack}>
                                <BackIcon>
                                    <BackArrow fill={SystemColors.v1.sesame} />
                                </BackIcon>
                                {isCreating ? "New Timecard" : "Edit Timecard"}
                            </HeaderLeft>
                            {!isCreating && (
                                <DeleteButton onClick={handleDeleteShift}>
                                    <TrashCanSVG
                                        fill={SystemColors.v1.melon50}
                                    />
                                    Delete
                                </DeleteButton>
                            )}
                        </Header>
                        {isCreating && (
                            <>
                                <HeaderLabel>Employee</HeaderLabel>
                                <EmployeeSelect
                                    showSearch
                                    size="large"
                                    notFoundContent={"No Employee Found"}
                                    placeholder="Employee"
                                    optionFilterProp="label"
                                    filterOption={(input, option) => {
                                        if (option && option.label) {
                                            const index = option.label
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase());
                                            return index >= 0;
                                        }
                                        return false;
                                    }}
                                    filterSort={(optionA, optionB) =>
                                        optionA.label
                                            .toLowerCase()
                                            .localeCompare(
                                                optionB.label.toLowerCase(),
                                            )
                                    }
                                    options={employees}
                                    onChange={(id: any) => setEmployeeId(id)}
                                />
                            </>
                        )}
                        <EditShiftDetailsWrapper>
                            {shiftMetrics.map((shiftMetric, index) => {
                                const { type, label, value } = shiftMetric;

                                const dateValue: PickerProps<Moment>["value"] =
                                    value ? moment(value) : null;
                                const defaultPickerValue: PickerProps<Moment>["defaultPickerValue"] =
                                    clockedInAt
                                        ? moment(clockedInAt)
                                        : undefined;

                                return (
                                    <InputWrapper key={index}>
                                        {type === ShiftMetrics.CashTips ? (
                                            <>
                                                <InputLabel>
                                                    Cash Tips
                                                </InputLabel>
                                                <NumberFormat
                                                    value={
                                                        cashTips === 0
                                                            ? ""
                                                            : cashTips
                                                    }
                                                    placeholder="$0.00"
                                                    customInput={TextInput}
                                                    prefix="$"
                                                    onValueChange={({
                                                        value,
                                                    }) => {
                                                        setCashTips(
                                                            Number(value),
                                                        );
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <InputLabel>{label}</InputLabel>
                                                {/* @ts-ignore `DatePicker`has TS conflict with moment-timezone. */}
                                                <DatePicker
                                                    format={dateFormat}
                                                    disabled={
                                                        type ===
                                                            ShiftMetrics.ClockedOutAt &&
                                                        !clockedInAt
                                                    }
                                                    disabledDate={(current) =>
                                                        disabledDate(
                                                            current,
                                                            type,
                                                        )
                                                    }
                                                    showNow={false}
                                                    defaultPickerValue={
                                                        defaultPickerValue
                                                    }
                                                    showTime={{
                                                        format: timeFormat,
                                                        defaultValue:
                                                            type ===
                                                            ShiftMetrics.ClockedOutAt
                                                                ? moment(
                                                                      clockedInAt,
                                                                  )
                                                                : undefined,
                                                    }}
                                                    onChange={(
                                                        time,
                                                        timeString,
                                                    ) =>
                                                        handleChange({
                                                            type,
                                                            time,
                                                            timeString,
                                                        })
                                                    }
                                                    size="large"
                                                    style={
                                                        styles.datePickerStyles
                                                    }
                                                    value={dateValue}
                                                />
                                            </>
                                        )}
                                    </InputWrapper>
                                );
                            })}
                        </EditShiftDetailsWrapper>
                        <HeaderLabel>Breaks</HeaderLabel>
                        <EditBreaksWrapper>
                            {breaks?.map((breakData) => {
                                const { id, start, end, type } = breakData;
                                const startTime = start ? moment(start) : null;
                                const endTime = end ? moment(end) : null;

                                const label = type
                                    ? type == BreakType.PaidBreak
                                        ? "Paid"
                                        : "Unpaid"
                                    : undefined;

                                const breakTypeOptions = [
                                    {
                                        value: BreakType.PaidBreak,
                                        label: "Paid",
                                    },
                                    {
                                        value: BreakType.UnpaidBreak,
                                        label: "Unpaid",
                                    },
                                ];

                                const isRemovable = !!(
                                    type &&
                                    startTime &&
                                    endTime
                                );

                                return (
                                    <BreakInputRow key={id}>
                                        <BreakInputWrapper>
                                            <BreakTypeWrapper>
                                                <BreakSelect
                                                    allowClear
                                                    size="large"
                                                    defaultValue={
                                                        isCreating
                                                            ? undefined
                                                            : label
                                                    }
                                                    placeholder="Break Type"
                                                    options={breakTypeOptions}
                                                    onChange={(value: any) =>
                                                        handleChange({
                                                            type: ShiftMetrics.BreakType,
                                                            breakId: id,
                                                            breakType: value,
                                                        })
                                                    }
                                                    onClear={() => {
                                                        handleChange({
                                                            type: ShiftMetrics.BreakType,
                                                            breakId: id,
                                                            breakType: null,
                                                        });
                                                    }}
                                                />
                                            </BreakTypeWrapper>
                                            <BreakRange>
                                                <DatePicker
                                                    disabledDate={(current) =>
                                                        current.isAfter(
                                                            endTime,
                                                            "second",
                                                        )
                                                    }
                                                    placeholder="Start time"
                                                    format={
                                                        isMultipleDaysShift
                                                            ? dateFormat
                                                            : timeFormat
                                                    }
                                                    showNow={false}
                                                    showTime={{
                                                        format: timeFormat,
                                                    }}
                                                    onChange={(
                                                        time,
                                                        timeString,
                                                    ) =>
                                                        handleChange({
                                                            type: ShiftMetrics.BreakStart,
                                                            time,
                                                            timeString,
                                                            breakId: id,
                                                        })
                                                    }
                                                    size="large"
                                                    style={
                                                        styles.datePickerStyles
                                                    }
                                                    value={startTime}
                                                />
                                                <DashSeparator>-</DashSeparator>
                                                {/* @ts-ignore `DatePicker`has TS conflict with moment-timezone. */}
                                                <DatePicker
                                                    disabled={!startTime}
                                                    disabledDate={(current) =>
                                                        current.isBefore(
                                                            startTime ??
                                                                undefined,
                                                            "second",
                                                        )
                                                    }
                                                    placeholder="End time"
                                                    format={
                                                        isMultipleDaysShift
                                                            ? dateFormat
                                                            : timeFormat
                                                    }
                                                    showNow={false}
                                                    defaultPickerValue={
                                                        startTime ?? undefined
                                                    }
                                                    showTime={{
                                                        format: timeFormat,
                                                        defaultValue:
                                                            startTime ??
                                                            undefined,
                                                    }}
                                                    onChange={(
                                                        time,
                                                        timeString,
                                                    ) =>
                                                        handleChange({
                                                            type: ShiftMetrics.BreakEnd,
                                                            time,
                                                            timeString,
                                                            breakId: id,
                                                        })
                                                    }
                                                    size="large"
                                                    style={
                                                        styles.datePickerStyles
                                                    }
                                                    value={endTime}
                                                />
                                            </BreakRange>
                                        </BreakInputWrapper>
                                        <DeleteIconWrapper
                                            onClick={() => {
                                                if (!isRemovable) return;
                                                handleDeleteBreak(id);
                                            }}
                                            isRemovable={isRemovable}
                                        >
                                            <TrashCanSVG
                                                fill={SystemColors.v1.sesame}
                                            />
                                        </DeleteIconWrapper>
                                    </BreakInputRow>
                                );
                            })}
                        </EditBreaksWrapper>
                        <MetricsContainer className="w-full">
                            <MetricsHeader>Note</MetricsHeader>
                            <textarea
                                onChange={handleNotesChange}
                                value={note}
                            ></textarea>
                        </MetricsContainer>
                    </>
                ) : (
                    <>
                        <Header>
                            <HeaderLeft onClick={onHide}>
                                <BackIcon>
                                    <BackArrow fill={SystemColors.v1.sesame} />
                                </BackIcon>
                                {timecardData.employeeName}
                            </HeaderLeft>
                            <EditButton onClick={() => setIsEditing(true)}>
                                <EditIcon fill={SystemColors.v1.sesame} />
                                Edit
                            </EditButton>
                        </Header>
                        <ShiftDetailsWrapper>
                            <ShiftColumn>
                                <HeaderLabel>Shift Details</HeaderLabel>
                                <ShiftDetailsRow>
                                    <CalendarSVG
                                        fill={SystemColors.v1.sesame}
                                        width={16}
                                    />
                                    {moment(timecardData.clockedInAt).format(
                                        "dddd, MMM D",
                                    )}
                                </ShiftDetailsRow>
                                {isMultipleDaysShift && (
                                    <ShiftDetailsRow>
                                        <CalendarSVG
                                            fill={SystemColors.v1.sesame}
                                            width={16}
                                        />
                                        {timecardData.clockedOutAt
                                            ? moment(
                                                  timecardData.clockedOutAt,
                                              ).format("dddd, MMM D")
                                            : "Ongoing"}
                                    </ShiftDetailsRow>
                                )}
                                <ShiftDetailsRow>
                                    <PersonSVG fill={SystemColors.v1.sesame} />
                                    {timecardData.employeeRole}
                                </ShiftDetailsRow>
                            </ShiftColumn>
                            <TimeColumn>
                                <HeaderLabel>Time</HeaderLabel>
                                <ShiftDetailsRow>
                                    <ClockSVG fill={SystemColors.v1.sesame} />
                                    Total:{" "}
                                    {formatTotalHours(timecardData.totalHours)}
                                </ShiftDetailsRow>
                                <ShiftDetailsRow>
                                    <AlertSVG
                                        fill={SystemColors.v1.sesame}
                                        width={16}
                                    />
                                    Overtime (All Types):{" "}
                                    {formatTotalHours(
                                        timecardData.overtimeHours +
                                            timecardData.doubleOvertimeHours,
                                    )}
                                </ShiftDetailsRow>
                            </TimeColumn>
                        </ShiftDetailsWrapper>
                        <MetricsWrapper>
                            <MetricsContainer>
                                <MetricsHeader>Clock In</MetricsHeader>
                                <MetricsData>
                                    {formatTime(timecardData.clockedInAt)}
                                    {isMultipleDaysShift && (
                                        <>
                                            <br />
                                            <MetricsAdditionalInfo>
                                                {clockedInMoment.format(
                                                    "dddd, MMM D",
                                                )}
                                            </MetricsAdditionalInfo>
                                        </>
                                    )}
                                </MetricsData>
                            </MetricsContainer>
                            <MetricsContainer>
                                <MetricsHeader>Clock Out</MetricsHeader>
                                <MetricsData>
                                    {formatTime(timecardData.clockedOutAt)}
                                    {isMultipleDaysShift && (
                                        <>
                                            <br />
                                            <MetricsAdditionalInfo>
                                                {clockedOutMoment.format(
                                                    "dddd, MMM D",
                                                )}
                                            </MetricsAdditionalInfo>
                                        </>
                                    )}
                                </MetricsData>
                            </MetricsContainer>
                            <MetricsContainer>
                                <MetricsHeader>Cash Tips</MetricsHeader>
                                <MetricsData>
                                    ${timecardData.cashTips}
                                </MetricsData>
                            </MetricsContainer>
                        </MetricsWrapper>
                        {timecardData.breaks.length > 0 && (
                            <BreakItem breaks={timecardData.breaks} />
                        )}
                        <MetricsContainer className="w-full">
                            <MetricsHeader>Note</MetricsHeader>
                            <div>{timecardData.note}</div>
                        </MetricsContainer>
                    </>
                )}
            </Container>
            {isEditing ? (
                <SaveButton
                    onClick={isCreating ? handleCreateShift : handleEditShift}
                    disabled={!isValidShift()}
                >
                    Save
                </SaveButton>
            ) : (
                <PaginationWrapper>
                    <PaginationButton
                        disabled={isBackDisabled}
                        onClick={() => onMoveOneShift({ advance: false })}
                    >
                        <LeftArrowSVG
                            fill={
                                isBackDisabled
                                    ? SystemColors.v1.gray50
                                    : SystemColors.v1.sesame
                            }
                        />
                        <PaginationText>Previous</PaginationText>
                    </PaginationButton>
                    <ShiftSelector
                        value={currentShiftIndex ?? 0}
                        onChange={(e) => {
                            setSelectShiftIndex(Number(e.target.value));
                        }}
                    >
                        {shifts.map((shift, index) => (
                            <option key={index} value={index}>
                                {index + 1} of {shifts.length}
                            </option>
                        ))}
                    </ShiftSelector>
                    <PaginationButton
                        disabled={isForwardDisabled}
                        onClick={() => onMoveOneShift({ advance: true })}
                    >
                        <PaginationText>Next</PaginationText>
                        <RightArrowSVG
                            fill={
                                isForwardDisabled
                                    ? SystemColors.v1.gray50
                                    : SystemColors.v1.sesame
                            }
                        />
                    </PaginationButton>
                </PaginationWrapper>
            )}
        </>
    );
};

export default TimeCardViewEdit;

const BreakItem = ({ breaks }: { breaks: Break[] }): JSX.Element => (
    <BreaksWrapper>
        <HeaderLabel>Breaks</HeaderLabel>
        {breaks?.map((breakData: Break, index: number) => {
            const { id, start, end, type } = breakData;
            const startTime = moment(start);
            const endTime = moment(end ?? new Date());
            const format = "hh:mm a";

            const range = `${startTime.format(format)} - ${
                end ? endTime.format(format) : "Ongoing"
            }`;
            const duration = endTime.diff(startTime, "minutes");
            const breakType = type === BreakType.PaidBreak ? "paid" : "unpaid";

            return (
                <BreakRow key={id} showDivider={index !== breaks.length - 1}>
                    {/* TODO: Should we calculate duration on the server?*/}
                    <BreakInfo>{`${duration} min ${breakType} ${
                        end ? "" : "(so far)"
                    }`}</BreakInfo>
                    <BreakInfo>{`${range} (${duration} min)`}</BreakInfo>
                </BreakRow>
            );
        })}
    </BreaksWrapper>
);

const { MOBILE } = ScreenState;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    padding: 0px 10px 0px 5px;

    @media ${MOBILE} {
        height: 85%;
        overflow-y: auto;
        padding: 0;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 16px;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    gap: 8px;
`;

const EditButton = styled.button`
    border: 1px solid ${SystemColors.v1.gray80};
    display: flex;
    align-items: center;
    height: 36px;
    border-radius: 56px;
    padding: 8px 12px;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    gap: 8px;
    background-color: ${SystemColors.v1.white};
    &:hover {
        background-color: ${SystemColors.v1.gray100};
    }
`;

const BackIcon = styled.div`
    cursor: pointer;
`;

const ShiftDetailsWrapper = styled.div`
    display: flex;
`;

const ShiftColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const TimeColumn = styled(ShiftColumn)``;

const HeaderLabel = styled.span`
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
`;

const ShiftDetailsRow = styled.div`
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 36px;
    gap: 8px;
    margin-bottom: 8px;
`;

const MetricsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 24px 0;

    @media ${MOBILE} {
        flex-direction: column;
        gap: 16px;
    }
`;

const MetricsContainer = styled.div`
    border: 1px solid ${SystemColors.v1.gray80};
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    width: 32%;
    padding: 16px;
    gap: 8px;

    @media ${MOBILE} {
        width: 100%;
    }
`;

const MetricsHeader = styled.header`
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const MetricsData = styled.span`
    font-family: "Inter";
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
`;

const MetricsAdditionalInfo = styled.span`
    font-family: "Inter";
    font-size: 18px;
    color: ${SystemColors.v1.gray40};
`;

const BreaksWrapper = styled.div``;

const BreakRow = styled.div<{ showDivider: boolean }>`
    display: flex;
    justify-content: space-between;
    height: 40px;
    border-bottom: ${({ showDivider }) =>
        showDivider ? `1px solid ${SystemColors.v1.gray80}` : "none"};
    margin-top: 16px;
    @media ${MOBILE} {
        gap: 8px;
    }
`;

const BreakInfo = styled.span`
    font-family: "Inter";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    @media ${MOBILE} {
        font-size: 1rem;
    }
`;

const DeleteButton = styled(EditButton)`
    color: ${SystemColors.v1.melon50};
`;

const EditShiftDetailsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    @media ${MOBILE} {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 32%;
    @media ${MOBILE} {
        width: 100%;
        margin-bottom: 24px;
    }
`;

const InputLabel = styled.div`
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
`;

const TextInput = styled.input`
    border-radius: 8px;
    border: 1px solid ${SystemColors.v1.gray70};
    font-family: "Inter";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    height: 48px;
`;

const EditBreaksWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const BreakInputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const BreakInputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 95%;
    @media ${MOBILE} {
        flex-wrap: wrap;
    }
`;

const BreakTypeWrapper = styled.div`
    width: 30%;
    @media ${MOBILE} {
        width: 100%;
    }
`;

const BreakRange = styled.div`
    display: flex;
    width: 70%;
    gap: 8px;
    align-items: center;
    @media ${MOBILE} {
        width: 100%;
    }
`;

const DashSeparator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
`;

const DeleteIconWrapper = styled.div<{ isRemovable: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;
    background-color: ${SystemColors.v1.gray90};
    border-radius: 8px;
    margin-left: 8px;
    cursor: ${({ isRemovable }) => (isRemovable ? "pointer" : "not-allowed")};
    @media ${MOBILE} {
        height: 104px;
    }
`;

const SaveButton = styled(EditButton)<{ disabled: boolean }>`
    width: 100%;
    color: ${SystemColors.v1.white};
    justify-content: center;
    height: 48px;
    &:hover {
        background-color: ${({ disabled }) =>
            disabled ? SystemColors.v1.gray50 : SystemColors.v1.candy60};
    }
    background-color: ${({ disabled }) =>
        disabled ? SystemColors.v1.gray50 : SystemColors.v1.candy50};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const PaginationWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const PaginationButton = styled(EditButton)<{ disabled: boolean }>`
    width: 30%;
    height: 48px;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    color: ${({ disabled }) =>
        disabled ? SystemColors.v1.gray50 : SystemColors.v1.black};
    @media ${MOBILE} {
        min-width: 48px;
        max-width: 48px;
    }
`;

const PaginationText = styled.span`
    @media ${MOBILE} {
        display: none;
    }
`;

const ShiftSelector = styled.select`
    border-radius: 56px;
    height: 40px;
    padding-left: 1em;
    padding-right: 2em;
    border-color: #e1e3e6;
    border-width: 1px;
    appearance: none;
    cursor: pointer;
    background-image: url(${UpDownArrowsSVG});
    background-position: calc(50% + 30px) 50%;
    background-size:
        15px 15px,
        15px 15px;
    background-repeat: no-repeat;
    font-weight: 600;
    font-size: 14px;
    outline: 0;
    width: 40%;
    height: 48px;
    text-align: center;

    @media ${MOBILE} {
        min-width: 214px;
        width: 80%;
    }
`;

const EmployeeSelect = styled(Select)`
    width: 100%;
    margin-bottom: 24px;

    .ant-select {
        height: 48px;
    }
    .ant-select-selector {
        height: 48px !important;
        border-radius: 8px !important;
        display: flex !important;
        align-items: center !important;
    }

    .ant-select-selection-placeholder {
        margin-top: 4px !important;
    }

    .ant-select-selection-search {
        display: flex !important;
        align-items: center !important;
    }

    input:focus {
        box-shadow: none;
    }
`;

const BreakSelect = styled(EmployeeSelect)`
    margin-bottom: 0;
`;

const styles = {
    datePickerStyles: {
        borderRadius: "8px",
        border: `1px solid ${SystemColors.v1.gray70}`,
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "24px",
        height: "48px",
        width: "100%",
    } satisfies CSSProperties,
};
