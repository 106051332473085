import { css } from "@emotion/react";
import { SystemColors } from "@snackpass/design-system";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { ReactComponent as Logo } from "src/assets/icons/snackpass-logo.svg";
import colors from "#reusable/colors/colors.json";
import {
    RouteDefinition,
    useNavMap,
    matchPathWithChildren,
} from "#navigation/navigations-routes";
import { WelcomeItem } from "#onboarding/components/navigation/WelcomeItem";
import { getActiveStore } from "src/redux/selectors";
import { cn } from "src/@/lib/utils";
import { useEmployeeOnboardingAccess } from "#navigation/guards/useEmployeeOnboardingAccess";
import { GlobalSearchCommandPalette } from "#global-search-command-palette";
import { ReactComponent as Search } from "src/assets/icons/search.svg";

import { SidebarItemDesktop } from "./sidebar-item-desktop";

const NavBarStyle = css`
    background-color: ${SystemColors.v2.salt10.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 240px;
    height: 100%;
    z-index: 2;

    .sidebar-container {
        display: flex;
        background-color: ${SystemColors.v2.salt10.light};
        overflow-y: auto;

        flex-direction: column;
        justify-content: space-between;
        min-width: 240px;
        height: 100%;
        border-right: 1px solid ${colors["neutral-400"]};
        position: fixed;
    }
    @media (max-width: 768px) {
        .sidebar-top {
            overflow-y: auto !important;
        }
    }
    .sidebar-top {
        flex: 1;
        overflow-y: hidden;
        &:hover {
            overflow-y: auto;
        }
        scroll-behavior: auto;
        background:
            /* Shadow Cover TOP */
            linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
            /* Shadow Cover BOTTOM */
                linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
            /* Shadow TOP */
                radial-gradient(
                    farthest-side at 50% 0,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0)
                )
                center top,
            /* Shadow BOTTOM */
                radial-gradient(
                    farthest-side at 50% 100%,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0)
                )
                center bottom;

        background-repeat: no-repeat;
        background-size:
            100% 40px,
            100% 40px,
            100% 14px,
            100% 14px;
        background-attachment: local, local, scroll, scroll;
        padding: 10px 0px;
        padding-bottom: 100px;

        &.smooth {
            scroll-behavior: smooth;
        }
    }
    .sidebar-footer-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
    .sidebar-footer {
        margin: 10px 10px 65px 10px;
    }
    .sidebar-logo {
        margin-right: 8px;
        height: 20px;
    }
`;

export const NavigationSideBar = () => {
    const location = useLocation();
    const navMapping = useNavMap();

    const store = useSelector(getActiveStore);

    const onboardingComplete = store?.onboardingComplete ?? false;

    const employeeOnboardingAccess = useEmployeeOnboardingAccess();

    const scrollRootRef = useRef<HTMLDivElement>(null);

    const [smooth, setSmooth] = useState(false);

    const [commandOpen, setCommandOpen] = useState<boolean>(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSmooth(true);
        }, 100);
        return () => clearTimeout(timeout);
    }, []);

    function generateSidebarItems(
        items: RouteDefinition[],
        isOnboardingComplete: boolean,
        depth = 0,
    ): JSX.Element[] {
        const matchRoute = matchPathWithChildren(location.pathname);
        const hasActiveSiblings = items.some(matchRoute);

        return items.map((item) => {
            const key = `${item.name}${depth}${item.path}`;
            const placeholder = <div key={key} />;

            if (item.condition === false) {
                return placeholder;
            }

            const isActive = matchRoute(item);
            if (depth > 0 && !isActive && !hasActiveSiblings) {
                return placeholder;
            }

            if (
                !employeeOnboardingAccess &&
                !item.availableBeforeOnboardingComplete &&
                !isOnboardingComplete
            ) {
                return placeholder;
            }

            return (
                <div key={key}>
                    <SidebarItemDesktop
                        title={item.name}
                        icon={item.icon}
                        link={item.path}
                        isActive={isActive}
                        onClick={item.onClick}
                        isSubItem={depth === 1}
                        isNestedSubItem={depth >= 2}
                        rightComponent={item.rightComponent}
                        scrollRootRef={scrollRootRef}
                        hasChildren={
                            !!item.children?.filter((e) => e.condition).length
                        }
                    />
                    {item.children &&
                        generateSidebarItems(
                            item.children,
                            isOnboardingComplete,
                            depth + 1,
                        )}
                </div>
            );
        });
    }

    return (
        <div css={NavBarStyle}>
            <div className="sidebar-container">
                <div
                    ref={scrollRootRef}
                    className={cn("sidebar-top", smooth && "smooth")}
                >
                    <WelcomeItem
                        complete={onboardingComplete}
                        scrollRootRef={scrollRootRef}
                    />
                    <div className="hidden lg:block">
                        <SidebarItemDesktop
                            title={"Search"}
                            icon={<Search />}
                            link={location.pathname}
                            isActive={false}
                            onClick={() => {
                                setCommandOpen(true);
                            }}
                            isSubItem={false}
                            isNestedSubItem={false}
                            rightComponent={<></>}
                            scrollRootRef={scrollRootRef}
                            hasChildren={false}
                        />
                    </div>
                    <GlobalSearchCommandPalette
                        isOpen={commandOpen}
                        onClose={() => {
                            setCommandOpen(false);
                        }}
                    />
                    {generateSidebarItems(navMapping, onboardingComplete)}
                </div>

                {!window.PartnerAppJsApi && (
                    <div className="sidebar-footer">
                        <div className="sidebar-footer-logo">
                            <Logo
                                fill={SystemColors.v2.salt80.light}
                                className="sidebar-logo"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
