import { useFormContext } from "react-hook-form";

import { FormDropdownSelect } from "#settings-integrations/components/shared/form-dropdown-select";
import { FormToggle } from "#settings-integrations/components/shared/form-toggle";
import { MarkupComment } from "#settings-integrations/components/markup-comment";
import { MarkupType } from "#settings-integrations/components/markup-type";
import {
    DOLLAR_OPTIONS,
    INTEGRATION_FIELD_NAMES,
    MARKUP_TYPES,
    PERCENT_OPTIONS,
} from "#settings-integrations/utils/types";

export const ShouldMarkupPricesContainer = () => {
    const { watch } = useFormContext();

    const shouldMarkupPrices: boolean = watch(
        INTEGRATION_FIELD_NAMES.SHOULD_MARKUP_PRICES,
    );

    const markupType: MARKUP_TYPES = watch(INTEGRATION_FIELD_NAMES.MARKUP_TYPE);

    return (
        <div className="flex w-full flex-col py-5">
            <FormToggle
                name="Do you want your 3rd party delivery prices to be higher than your Snackpass prices?"
                descriptor="Ex: $10 on Snackpass, but $11 on 3rd party delivery channels"
                fieldName={INTEGRATION_FIELD_NAMES.SHOULD_MARKUP_PRICES}
            />
            {shouldMarkupPrices && (
                <>
                    <MarkupType />
                    {markupType === MARKUP_TYPES.PERCENT && (
                        <FormDropdownSelect
                            name="Markup Amount (%)"
                            descriptor="This is the amount you want your items to be marked up."
                            fieldName={INTEGRATION_FIELD_NAMES.MARKUP_PERCENT}
                            options={PERCENT_OPTIONS}
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `${val}%`}
                        />
                    )}
                    {markupType === MARKUP_TYPES.DOLLARS && (
                        <FormDropdownSelect
                            name="Markup Amount ($)"
                            descriptor="This is the amount you want your items to be marked up."
                            fieldName={INTEGRATION_FIELD_NAMES.MARKUP_DOLLARS}
                            options={DOLLAR_OPTIONS}
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                        />
                    )}
                    <MarkupComment />
                </>
            )}
        </div>
    );
};
