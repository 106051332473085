import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Form, ErrorMessage } from "formik";
import { ScreenState } from "@snackpass/snackpass-types";
import _ from "lodash";
import { Button, Col, InputNumber } from "antd";
import { omit } from "radash";
import { toast } from "sonner";
import { Link } from "react-router-dom";

import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { StyledField, StyledRow } from "#settings/components/shared-component";
import Switch from "#reusable/input/toggle-input";
import { Divider } from "#reusable/divider";
import {
    AlertLevel,
    ValidatorMessage,
} from "#reusable/validators/validator-message";
import { ManagementSubForm } from "#settings/settings-orders/ManagementSubForm";

import { OrdersFormFieldEnum, OrdersType } from "./types";
import { ToggleRow } from "#settings/settings-orders/rows";
import { sendError } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";

import { Routes } from "#navigation/routes";

import constants from "#core/constants";
import { useAppDispatch } from "src/redux/hooks";
import { Text } from "#reusable/text/index";
import { getActiveStore } from "src/redux/selectors";

import { ScreenLayout } from "../../reusable/layout/screen-layout";
import { MobileHeader } from "../../reusable/layout/header";
import {
    ordersInitialValues,
    ordersValidationSchema,
    isAutoComplete,
    buildOrderStatusConfiguration,
    isAutoAccept,
} from "./helper";

const SettingsOrdersScreen = () => {
    const viewOnly = !useHasEditSettingsForActiveStore();
    const dispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`,
    });
    const [initialData, setInitialData] =
        useState<OrdersType>(ordersInitialValues);

    useEffect(() => {
        if (activeStore) {
            // XXX: Fix for any store documents where the max time value is less than the min time value
            const defaultPickupMaxTime =
                activeStore.defaultPickupMaxTime &&
                activeStore.defaultPickupMaxTime >=
                    (activeStore.defaultPickupMinTime ?? 0)
                    ? activeStore.defaultPickupMaxTime
                    : activeStore.defaultPickupMinTime;

            setInitialData({
                autoAccept: isAutoAccept(activeStore.orderStatusConfiguration),
                autoComplete: isAutoComplete(
                    activeStore.orderStatusConfiguration,
                ),
                allowCustomNotes: !activeStore.noNotes,
                initialReceiptNumber: activeStore.initialReceiptNumber || 100,
                defaultBagInCart: activeStore.defaultBagInCart,
                defaultPickupTime: activeStore.defaultPickupTime,
                defaultPickupMinTime: activeStore.defaultPickupMinTime,
                defaultPickupMaxTime,
                hasEmployeeAuditTracking:
                    activeStore.hasEmployeeAuditTracking ?? false,
                // XXX: Store#pickupTimeType is only defined as string
                pickupTimeType:
                    (activeStore.pickupTimeType as "specific" | "range") ??
                    "specific",
            });
        }
    }, [activeStore]);

    const onSubmit = async (values: OrdersType) => {
        if (!activeStore || viewOnly) return;

        try {
            setIsLoading(true);
            const { data } = await api.stores.update(activeStore._id, {
                orderStatusConfiguration: buildOrderStatusConfiguration(
                    values.autoAccept,
                    values.autoComplete,
                ),
                noNotes: !values.allowCustomNotes,
                ...omit(values, [
                    "allowCustomNotes",
                    "autoAccept",
                    "autoComplete",
                ]),
            });
            dispatch(setActiveStore(data.store));
        } catch (e) {
            sendError(e);
            toast.error("Can not update the store, please try again later");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={initialData}
            onSubmit={onSubmit}
            validationSchema={ordersValidationSchema}
            enableReinitialize
            validateOnchange
        >
            {({
                errors,
                touched,
                values,
                setFieldValue,
                setFieldTouched,
                handleReset,
            }) => (
                <Form style={{ height: "100%" }}>
                    <ScreenLayout
                        header={
                            isMobile ? (
                                <MobileHeader title="  Orders (Legacy)" />
                            ) : (
                                <Text size="xl" weight="x-bold">
                                    Orders (Deprecated)
                                </Text>
                            )
                        }
                        isLoading={isLoading}
                        description={
                            isMobile ? null : (
                                <Text size="s" color="light-grey" weight="thin">
                                    Use
                                    <Link
                                        to={Routes.SettingsAllOrders}
                                        className="px-1 no-underline"
                                    >
                                        All Orders
                                    </Link>
                                    and
                                    <Link
                                        to={Routes.SettingsBackOfHouse}
                                        className="px-1 no-underline"
                                    >
                                        Back of House
                                    </Link>
                                </Text>
                            )
                        }
                        content={
                            <ContentRow>
                                <ManagementSubForm
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    values={values}
                                    disabled={viewOnly}
                                />
                                <Text size="l" weight="bold" spacing="xl">
                                    Preferences
                                </Text>
                                <ToggleRow
                                    field={OrdersFormFieldEnum.allowCustomNotes}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    value={values.allowCustomNotes}
                                    disabled={viewOnly}
                                />
                                <StyledRow>
                                    <Col span={20}>
                                        <Text size="m">
                                            Automatically include bag
                                        </Text>
                                        <Text
                                            size="s"
                                            color="light-grey"
                                            weight="thin"
                                        >
                                            A bag will be added to the cart for
                                            all purchases
                                        </Text>
                                    </Col>
                                    <Switch
                                        id="orders-default-bag-in-cart"
                                        onChange={() => {
                                            setFieldValue(
                                                OrdersFormFieldEnum.defaultBagInCart,
                                                values.defaultBagInCart === 0
                                                    ? 1
                                                    : 0,
                                            );
                                            setFieldTouched(
                                                OrdersFormFieldEnum.defaultBagInCart,
                                            );
                                        }}
                                        isOn={values.defaultBagInCart === 1}
                                        disabled={viewOnly}
                                    />
                                </StyledRow>
                                <Divider />
                                <StyledRow>
                                    <Col span={20}>
                                        <Text size="m">
                                            Receipt start number
                                        </Text>
                                        <Text
                                            size="s"
                                            color="light-grey"
                                            weight="thin"
                                        >
                                            By default, receipts start at 100.
                                            Changes affect the following day
                                        </Text>
                                    </Col>
                                    <Col span={4}>
                                        <StyledField
                                            name="initialReceiptNumber"
                                            type="number"
                                            min={0}
                                            component={InputNumber}
                                            size="large"
                                            value={
                                                values.initialReceiptNumber ??
                                                100 // default setting
                                            }
                                            status={
                                                values.initialReceiptNumber <= 0
                                                    ? "error"
                                                    : null
                                            }
                                            onChange={(value: number) => {
                                                setFieldTouched(
                                                    OrdersFormFieldEnum.initialReceiptNumber,
                                                );
                                                setFieldValue(
                                                    OrdersFormFieldEnum.initialReceiptNumber,
                                                    value,
                                                );
                                            }}
                                            disabled={viewOnly}
                                        />
                                    </Col>
                                </StyledRow>
                                <ErrorMessage
                                    name="initialReceiptNumber"
                                    render={(description) => (
                                        <ValidatorMessage
                                            type={AlertLevel.error}
                                            description={description}
                                        />
                                    )}
                                />
                            </ContentRow>
                        }
                        footer={
                            !_.isEmpty(touched) && !viewOnly ? (
                                <ActionButtonsContainer>
                                    <StyledButton
                                        onClick={() => {
                                            handleReset();
                                        }}
                                    >
                                        Cancel
                                    </StyledButton>
                                    <StyledButton
                                        type="primary"
                                        htmlType="submit"
                                        disabled={!_.isEmpty(errors)}
                                    >
                                        Save
                                    </StyledButton>
                                </ActionButtonsContainer>
                            ) : (
                                <></>
                            )
                        }
                    />
                </Form>
            )}
        </Formik>
    );
};

const ContentRow = styled.div`
    overflow-y: scroll;
    padding-bottom: 50px;
    padding-right: 20%;
    height: 100%;
    @media ${ScreenState.MOBILE} {
        padding-left: 20px;
        padding-right: 30px;
    }
    @media ${ScreenState.TABLET} {
        padding-right: 50px;
    }
    width: -webkit-fill-available;
    width: -moz-available;

    .ant-input-number {
        border-radius: 8px;
        input {
            border-radius: 8px;
        }
    }
    .ant-input-number-handler-wrap {
        border-radius: 8px;
    }
`;

const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @media ${ScreenState.MOBILE} {
        justify-content: center;
    }
`;

const StyledButton = styled(Button)`
    border-radius: 8px;
    margin: 0px 8px;
    @media ${ScreenState.MOBILE} {
        justify-content: center;
        display: flex;
        flex: 1;
    }
`;
export default SettingsOrdersScreen;
