import { match } from "ts-pattern";

// Stripe requirement to form field
//
// e.g., `company.name` on Stripe Account object
//                        to
//       `name` in the form field name
export const fieldMapper = (field: string) =>
    match(field)
        .with("company.name", () => "name")
        .with("company.tax_id", () => "taxId")
        .with("company.address", () => "address")
        .with("company.address.line1", () => "address")
        .with("company.phone", () => "phone")
        .with("company.mcc", () => "mcc")
        .otherwise(() => "not-set");
