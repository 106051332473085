import { Col, Row, Space } from "antd";

import { Button } from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

type HeaderProps = {
    toMenuSettings: () => void;
    toAddItems: () => void;
};

export const Header: React.FC<HeaderProps> = ({
    toAddItems,
    toMenuSettings,
}) => (
    <Row justify="space-between">
        <Col>
            <Text type="title">Menu Outline</Text>
        </Col>
        <Col>
            <Space size="middle">
                <Button type="tertiary" onClick={toMenuSettings}>
                    Menu Settings
                </Button>
                <Button type="primary" onClick={toAddItems}>
                    Edit Items
                </Button>
            </Space>
        </Col>
    </Row>
);
