import React from "react";
import clsx from "clsx";

import { ReactComponent as CheckIcon } from "src/assets/icons/checked.svg";

type Props = {
    title: string;
    onClick: () => void;
    isChecked: boolean | undefined;
    indented?: boolean;
    disabled?: boolean;
    tooltip?: string | JSX.Element;
};

const CheckBox = ({ title, onClick, isChecked = false, disabled }: Props) => (
    <div className="my-2 flex items-center justify-between border-neutral-400">
        <div className="flex items-start justify-center">
            <div>{title}</div>
        </div>
        <div
            className={clsx(
                "flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 p-1",
                isChecked
                    ? disabled
                        ? "bg-neutral-500"
                        : "bg-success-light"
                    : "bg-white",
            )}
            onClick={onClick}
        >
            {isChecked ? (
                <CheckIcon fill="#FFFFFF" width={12} height={9} />
            ) : null}
        </div>
    </div>
);

export default CheckBox;
