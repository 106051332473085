import React from "react";

import { ReactComponent as CloseIcon } from "src/assets/icons/close-x.svg";
import theme from "#onboarding/utils/theme";

import IconButton from "./IconButton";

type CloseButtonProps = {
    onClose: () => void;
};

const CloseButton = (props: CloseButtonProps): JSX.Element => (
    <IconButton variant="tertiary" size="small" onClick={props.onClose}>
        <CloseIcon width={theme.spacing.base} height={theme.spacing.base} />
    </IconButton>
);

export default CloseButton;
