import { Radio, Space } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormFieldName } from "#promotion/components/shared/form-field-name";
import {
    INTEGRATION_FIELD_NAMES,
    MARKUP_TYPES,
} from "#settings-integrations/utils/types";
import { FormFieldDescriptor } from "#settings-integrations/components/shared/form-field-descriptor";

export const MarkupType = () => {
    const { control } = useFormContext();

    return (
        <div className="mb-4 pb-6">
            <FormFieldName name="Markup Type" />
            <FormFieldDescriptor descriptor="Choose how items are marked up" />
            <Controller
                control={control}
                name={INTEGRATION_FIELD_NAMES.MARKUP_TYPE}
                render={({ field: { onChange, value } }) => (
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            <Radio value={MARKUP_TYPES.PERCENT}>
                                Percent Markup (%)
                            </Radio>
                            <Radio value={MARKUP_TYPES.DOLLARS}>
                                Dollar Markup ($)
                            </Radio>
                        </Space>
                    </Radio.Group>
                )}
            />
        </div>
    );
};
