import { IProduct, IStore, ITimeRangeSchema } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class ProductHoursCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductHoursCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems() {
        return [];
    }

    checkItem(product: IProduct): MenuIssue<IProduct> | null {
        const localStoreHours: ITimeRangeSchema[] = this.store.hours.local;

        if (product.hours === null) {
            return null;
        }
        // The current product has special hours.
        // We'll perform a pair of comparisons between these hours and the store hours.
        const localProductHours: ITimeRangeSchema[] = product.hours.local;
        for (let i = 0; i < localProductHours.length; i++) {
            // For each set of product hours, compare the product hours to each set of store hours.
            const currentProductHours = localProductHours[i];
            // A flag that will be set to true when a match with the store hours is found.
            let matchFound = false;
            for (let j = 0; j < localStoreHours.length; j++) {
                const currentStoreHours = localStoreHours[j];
                if (
                    currentProductHours.start >= currentStoreHours.start &&
                    currentProductHours.end <= currentStoreHours.end
                ) {
                    matchFound = true;
                }
            }
            // Once the nested loop is finised running,
            // the value of the matchFound flag determines whether or not there is an hours conflict.
            if (!matchFound) {
                return new MenuIssue(
                    "ConflictingHours",
                    `This product has special hours that conflict with the store hours.`,
                    "critical",
                    product,
                );
            }
        }
        return null;
    }
}

export { ProductHoursCheck };
