import React, { useContext, useMemo } from "react";

import { Button } from "src/@/components/ui/button";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReportsContext } from "#app/reports-context-provider";
import { granularityOptions } from "#reports/sales-summary/lib";
import { GranularityType } from "#reports/sales-summary/types";

export const GranularityFilterPicker = () => {
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { granularity } = reportsState;

    const selectedValues = useMemo(
        () => new Set([granularity as string]),
        [granularity],
    );

    return (
        <FilterSelect
            title="Granularity"
            customButton={
                <Button variant="outline" size="sm">
                    {
                        granularityOptions.find((e) => e.value === granularity)
                            ?.label
                    }
                </Button>
            }
            selectedValues={selectedValues}
            options={granularityOptions}
            onOptionSelected={(value: string) => {
                setReportsState((prevState) => ({
                    ...prevState,
                    granularity: value as GranularityType,
                }));
            }}
        />
    );
};
