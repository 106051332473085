/** @jsxImportSource @emotion/react */
import React from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";

const ErrorPage = () => (
    <Centered>
        <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
    </Centered>
);

const Centered = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ErrorPage;
