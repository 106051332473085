import { SystemColors } from "@snackpass/design-system";
import React, { CSSProperties } from "react";
import { css } from "@emotion/css";

type ButtonProps = {
    label: string;
    onClick: () => void;
    icon?: JSX.Element;
    style?: CSSProperties;
    className?: string;
};

function Button({ className, label, icon, onClick, style }: ButtonProps) {
    return (
        <button
            type="button"
            onClick={onClick}
            className={styles + " " + className}
            style={{
                ...style,
            }}
        >
            {icon && <div style={{ marginRight: 5 }}>{icon}</div>}
            <div>{label}</div>
        </button>
    );
}

const styles = css`
    background-color: ${SystemColors.v1.candy50};
    color: ${SystemColors.v1.white};
    border-radius: 25px;
    display: flex;
    padding: 8px 20px;
    width: auto;
    text-align: center;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    justify-content: center;

    &:hover {
        background-color: ${SystemColors.v1.candy50};
        opacity: 0.7;
    }
`;

export { Button };
