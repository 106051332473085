import React, { CSSProperties } from "react";
import { Checkbox } from "pretty-checkbox-react";
import { useSelector } from "react-redux";
import { AddonGroup, IProduct } from "@snackpass/snackpass-types";
import styled from "styled-components";
import { Row } from "antd";
import { truncate } from "lodash";

import { RootState } from "src/redux/store";
import { ReactComponent as CheckRoundSVG } from "src/assets/icons/check-round.svg";
import { ReactComponent as CheckMinusSVG } from "src/assets/icons/minus-rounded.svg";
import NoImage from "src/assets/images/no-img.png";
import {
    CellProps,
    isAddonGroup,
    isCategory,
    isProduct,
    stopPropagation,
    WithParentId,
} from "#menu-editor/multi-menus/helpers";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import {
    allChildrenSelectedAddonGroup,
    allChildrenSelectedCategory,
    allChildrenSelectedProduct,
    isAddonGroupSelected,
    isAddonSelected,
    isCategorySelected,
    isProductSelected,
} from "#menu-editor/multi-menus/redux/selectors";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { FlexRow } from "#pickers/ui";
import { FlexColumn } from "#menu-editor/multi-menus/styled-components/layout";
import { PriceText } from "#menu-editor/multi-menus/shared-components/price-text";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";
import { optimizedImageURL } from "src/utils/image";
import { useAppDispatch } from "src/redux/hooks";
interface SelectItemProps<T> {
    getActionArgs: () => T;
    discardAction: (args: T) => void;
    selectAction: (args: T) => void;
    isSelectedSelector: (state: RootState) => boolean;
    /** Defaults to true if no selector provided. */
    allChildrenSelectedSelector?: (state: RootState) => boolean;
    children: React.ReactElement;
    allowUnSelect?: boolean;
}

export const selectItemFactory = ({
    row,
    rowsById,
    isMobile,
}: CellProps & { isMobile: boolean }) => {
    const originalRow = row.original;
    const itemId = originalRow._id;
    if (isCategory(originalRow)) {
        return (
            <SelectItem
                selectAction={multiMenuActions.selectCategory}
                discardAction={multiMenuActions.discardCategory}
                getActionArgs={() => ({ categoryWithProducts: originalRow })}
                isSelectedSelector={isCategorySelected(itemId)}
                allChildrenSelectedSelector={allChildrenSelectedCategory(
                    originalRow,
                )}
            >
                <FlexColumn>
                    <Text type="body-bold">{originalRow.name}</Text>
                    {!isMobile ? (
                        <Text type="body-medium">{`${originalRow.productIds.length} Items`}</Text>
                    ) : null}
                </FlexColumn>
            </SelectItem>
        );
    } else if (isProduct(originalRow)) {
        return (
            <SelectItem
                selectAction={multiMenuActions.selectProduct}
                discardAction={multiMenuActions.discardProduct}
                getActionArgs={() => ({
                    product: originalRow,
                    categoryId: originalRow.parentId ?? "no-set",
                })}
                isSelectedSelector={isProductSelected(itemId)}
                allChildrenSelectedSelector={allChildrenSelectedProduct(
                    originalRow,
                )}
            >
                <FlexRow style={{ alignItems: "center" }}>
                    <ImageWrapper
                        style={{
                            backgroundImage: `url("${optimizedImageURL(
                                originalRow.image || NoImage,
                                {
                                    size: { w: 40 },
                                },
                            )}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: 40,
                            height: 40,
                        }}
                    ></ImageWrapper>
                    {!isMobile ? (
                        <Text type="body-medium">{originalRow.name}</Text>
                    ) : (
                        <div style={styles.flexColumn}>
                            <Text type="body-medium">
                                {truncate(originalRow.name, { length: 20 })}
                            </Text>
                            <PriceText
                                itemId={originalRow._id}
                                itemPrice={originalRow.price}
                                itemType="products"
                            />
                        </div>
                    )}
                </FlexRow>
            </SelectItem>
        );
    } else if (isAddonGroup(originalRow)) {
        const product = rowsById[originalRow.parentId ?? ""]
            ?.original as WithParentId<IProduct>;
        return (
            <>
                <SelectItem
                    selectAction={multiMenuActions.selectAddonGroup}
                    discardAction={multiMenuActions.discardAddonGroup}
                    getActionArgs={() => ({
                        addonGroup: originalRow,
                        product,
                        categoryId: product.parentId ?? "",
                    })}
                    isSelectedSelector={isAddonGroupSelected(itemId)}
                    allChildrenSelectedSelector={allChildrenSelectedAddonGroup(
                        originalRow,
                    )}
                    allowUnSelect={!originalRow.required}
                >
                    <Text type="body-regular">{originalRow.name}</Text>
                </SelectItem>
                {isMobile && originalRow.required ? (
                    <Row justify="end" style={commonStyles.fillAllWidth}>
                        <Text
                            type="body-regular"
                            color="white"
                            style={styles.requiredText}
                        >
                            Required
                        </Text>
                    </Row>
                ) : null}
            </>
        );
    } else {
        const addonGroup = rowsById[originalRow.parentId ?? ""]
            .original as WithParentId<AddonGroup>;
        const product = rowsById[addonGroup.parentId ?? ""]
            .original as WithParentId<IProduct>;
        const categoryId = product.parentId ?? "";
        return (
            <SelectItem
                selectAction={multiMenuActions.selectAddon}
                discardAction={multiMenuActions.discardAddon}
                getActionArgs={() => ({
                    addon: originalRow,
                    addonGroup,
                    product,
                    categoryId,
                })}
                isSelectedSelector={isAddonSelected(itemId, addonGroup._id)}
                allowUnSelect={!addonGroup.required}
            >
                {!isMobile ? (
                    <Text type="body-regular">{originalRow.name}</Text>
                ) : (
                    <div style={styles.flexColumn}>
                        <Text type="body-medium">{originalRow.name}</Text>
                        <PriceText
                            itemId={originalRow._id}
                            itemPrice={originalRow.price}
                            itemType="addons"
                        />
                    </div>
                )}
            </SelectItem>
        );
    }
};

export const SelectItem = <T,>({
    isSelectedSelector,
    discardAction,
    selectAction,
    getActionArgs,
    children,
    allChildrenSelectedSelector = () => true,
    allowUnSelect = true,
}: SelectItemProps<T>) => {
    const dispatch = useAppDispatch();
    const isSelected = useSelector(isSelectedSelector);
    const allChildrenSelected = useSelector(allChildrenSelectedSelector);
    const onChange = isSelected
        ? // @ts-expect-error not sure what the intended type is. Should be some kind of action
          () => dispatch(discardAction(getActionArgs()))
        : // @ts-expect-error Same here
          () => dispatch(selectAction(getActionArgs()));

    const icon = allChildrenSelected ? (
        <CheckRoundSVG className="svg" data-type="svg" />
    ) : (
        <CheckMinusSVG className="svg" data-type="svg" />
    );
    return (
        <>
            <Checkbox
                variant="fill"
                shape="curve"
                plain
                bigger
                onClick={stopPropagation}
                disabled={isSelected ? !allowUnSelect : false}
                checked={isSelected}
                onChange={onChange}
                icon={icon}
            ></Checkbox>

            {children}
        </>
    );
};

const ImageWrapper = styled.div`
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-right: 8px;
    border-radius: 8px;
    &:hover {
        opacity: 0.8;
    }
`;

const styles = {
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        gap: "3px",
    } as CSSProperties,
    requiredText: {
        backgroundColor: "#181B1E",
        padding: "4px 12px",
        borderRadius: "56px",
        marginRight: "8px",
    } as CSSProperties,
};
