import { usePurchaseReports } from "src/api/rest/usePurchaseReports";
import moment from "moment-timezone";
import { orderBy } from "lodash/fp";
import { useDashboardContext } from "./use-dashboard-context";
import { Granularity } from "../types";

interface SalesMetricsReport {
    days: Metrics[];
    weeks: Metrics[];
    months: Metrics[];
    payouts: Metrics[];
    total: Metrics;
}

interface Metrics {
    actualPayout: number;
    allCustomerToStoreFees: number;
    amountDueToStore: number;
    amountPaidByCustomer: number;
    commission: number;
    count: number;
    customDiscountsTotal: number;
    customFees: number;
    customSurchargeTotal: number;
    customerFees: number;
    customerToSnackpass3PDeliveryFee: number;
    customerToSnackpassFees: number;
    customerToSnackpassServiceFee: number;
    customerToStoreFees: number;
    date: string; // ISO date string
    delivery1PFee: number;
    delivery3PFee: number;
    earnings: number;
    endDate: string; // ISO date string
    expected3PPayout: number;
    expectedCashCollected: number;
    expectedInStoreCollections: number;
    expectedPayout: number;
    faxFee: number;
    giftCardCredit: number;
    granularity: string; // e.g., "payout"
    grossSalesAmount: number;
    isTestPurchase: number; // 0 or 1, likely a boolean indicator
    netSales: number;
    payoutAdjustments: number;
    processingFee: number;
    snackpassContribution: number;
    snackpassCredit: number;
    snackpassFees: number;
    store3PTaxesToRemit: number;
    storeCredit: number;
    storeFeesCredited: number;
    storeFeesDebited: number;
    storeTaxesToRemit: number;
    storeTaxesWithheld: number;
    subtotal: number;
    thirdPartyDiscount: number;
    thirdPartyFees: number;
    tip: number;
    tip3P: number;
    // Add more fields as required
}

export const useSalesMongo = () => {
    const {
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
        transactionChannels,
        transactionSources,
        paymentProviders,
        granularity,
        currentPeriod,
    } = useDashboardContext();

    // Only fetch data if current period is TODAY
    // replace when we fix big query
    // const shouldFetchData = currentPeriod === CurrentPeriod.TODAY;
    const shouldFetchData = true;

    // Get current period data
    const { data: currentReport, isLoading: isCurrentReportLoading } =
        usePurchaseReports(
            shouldFetchData
                ? {
                      since: currentPeriodStartEnd?.startDate?.toDate(),
                      until: currentPeriodStartEnd?.endDate?.toDate(),
                      transactionChannels,
                      transactionSources,
                      paymentProviders,
                      noRows: true,
                  }
                : { noRows: true, storeId: "skip" },
        ) as { data: SalesMetricsReport | undefined; isLoading: boolean };

    // Get comparison period data
    const { data: lastReport, isLoading: isLastReportLoading } =
        usePurchaseReports(
            shouldFetchData
                ? {
                      since: comparisonPeriodStartEnd?.startDate?.toDate(),
                      until: comparisonPeriodStartEnd?.endDate?.toDate(),
                      transactionChannels,
                      transactionSources,
                      paymentProviders,
                      noRows: true,
                  }
                : { noRows: true, storeId: "skip" },
        ) as { data: SalesMetricsReport | undefined; isLoading: boolean };

    const getSalesDataForGranularity = (
        report: SalesMetricsReport | undefined,
    ) => {
        if (!report) return [];

        switch (granularity) {
            case Granularity.DAY:
                return report.days || [];
            case Granularity.WEEK:
                return report.weeks || [];
            case Granularity.MONTH:
                return report.months || [];
            default:
                return report.days || [];
        }
    };

    const salesData = orderBy(
        ["date"],
        ["desc"],
        getSalesDataForGranularity(currentReport),
    );

    const lastSalesData = orderBy(
        ["date"],
        ["desc"],
        getSalesDataForGranularity(lastReport),
    );

    // Calculate current period totals from the total field
    const report = {
        netSales: currentReport?.total?.netSales || 0,
        count: currentReport?.total?.count || 0,
    };

    const lastNetSales = lastReport?.total?.netSales || 0;
    const lastCount = lastReport?.total?.count || 0;

    // Calculate percentage changes
    const calculatePercentageChange = (current: number, previous: number) => {
        if (!previous) return 0;
        return Math.round(((current - previous) / previous) * 100);
    };

    const netSalesChange = calculatePercentageChange(
        report.netSales,
        lastNetSales,
    );

    const ordersChange = calculatePercentageChange(report.count, lastCount);

    const currentAvgTicket = !report.netSales
        ? 0
        : report.netSales / report.count;
    const previousAvgTicket = !lastNetSales ? 0 : lastNetSales / lastCount;
    const avgTicketChange = calculatePercentageChange(
        currentAvgTicket,
        previousAvgTicket,
    );

    // Update chart data formatting based on granularity
    const formatChartDate = (date: string) => {
        const momentDate = moment(date);
        switch (granularity) {
            case Granularity.DAY:
                return momentDate.format("MMM D");
            case Granularity.WEEK:
                return `Week of ${momentDate.format("MMM D")}`;
            case Granularity.MONTH:
                return momentDate.format("MMM YYYY");
            default:
                return momentDate.format("MMM D");
        }
    };

    // Prepare chart data with exact date matching
    const chartData = salesData
        .slice()
        .map((day) => {
            const currentDate = moment(day.date);
            let comparisonDate = moment(day.date).subtract(
                currentPeriodStartEnd?.startDate?.diff(
                    comparisonPeriodStartEnd?.startDate,
                    "days",
                ),
                "days",
            );

            // For weekly granularity, ensure we're comparing the same week day
            if (granularity === Granularity.WEEK) {
                comparisonDate = comparisonDate.startOf("week");
            } else if (granularity === Granularity.MONTH) {
                comparisonDate = comparisonDate.startOf("month");
            }

            const previousData = lastSalesData.find((d) => {
                const lastDate = moment(d.date);
                if (granularity === Granularity.WEEK) {
                    return lastDate
                        .startOf("week")
                        .isSame(comparisonDate, "day");
                } else if (granularity === Granularity.MONTH) {
                    return lastDate
                        .startOf("month")
                        .isSame(comparisonDate, "month");
                }
                return lastDate.isSame(comparisonDate, "day");
            });

            return {
                period: formatChartDate(day.date),
                current: day.netSales,
                previous: previousData?.netSales || 0,
            };
        })
        .reverse();

    return {
        report,
        isLoading: shouldFetchData
            ? isCurrentReportLoading || isLastReportLoading
            : false,
        error: false,
        salesData,
        lastReport,
        lastNetSales,
        netSalesChange,
        ordersChange,
        currentAvgTicket,
        avgTicketChange,
        chartData,
    };
};
