import React, { useState } from "react";
import { Popover } from "antd";
import { SystemColors } from "@snackpass/design-system";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";

import { getMessageInputAudience } from "../../redux";

import { TargetAudience } from "./target-audience";

const SearchBar = () => {
    const [visible, setVisible] = useState(false);
    const audience = useSelector(getMessageInputAudience);

    const onSelectAudience = () => {
        setVisible(false);
    };

    return (
        <div className={styles}>
            <div className="to-container">To:</div>

            <Popover
                placement="bottomLeft"
                trigger="click"
                overlayStyle={{
                    width: 600,
                    padding: 0,
                    borderRadius: 20,
                }}
                visible={visible}
                onVisibleChange={setVisible}
                style={{
                    borderRadius: 20,
                    overflow: "hidden",
                }}
                overlayClassName="snackpass__search-bar-audience-overflow"
                content={<TargetAudience onSelectAudience={onSelectAudience} />}
            >
                <SearchAudience onClick={() => setVisible(true)} />
            </Popover>
        </div>
    );
};

const SearchAudience = ({ onClick }: { onClick: () => void }) => {
    const audience = useSelector(getMessageInputAudience);

    return (
        <div className="audience-container" onClick={onClick}>
            {audience?.label && (
                <div className="audience-label">{audience?.label}</div>
            )}

            <div
                contentEditable={true}
                className="search-bar"
                placeholder="Search for stuff..."
            />
        </div>
    );
};

const styles = css`
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    position: sticky;
    border-bottom: 1px solid ${SystemColors.v1.gray90};

    .to-container {
        font-weight: bold;
        margin-right: 15px;
    }

    .audience-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .audience-label {
        background-color: ${SystemColors.v1.candy90};
        color: ${SystemColors.v1.candy50};
        border-radius: 20px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: bold;
    }

    .search-bar {
        border: none;
        caret-color: ${SystemColors.v1.candy50};
    }
`;

export { SearchBar };
