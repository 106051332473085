import { IPurchase } from "@snackpass/snackpass-types";
import get from "lodash/get";
import React, { useState } from "react";
import { Printer, Loader2 } from "lucide-react";
import { toast } from "sonner";

import API from "src/api/rest";
import { printHTML } from "#utils/print-html";
import { Button } from "src/@/components/ui/button";

type Props = {
    purchase: IPurchase;
};

export const PrintButton = ({ purchase }: Props) => {
    const [isLoading, setLoading] = useState(false);

    const printReceipt = async () => {
        if (!purchase) return;
        setLoading(true);
        try {
            const { data } = await API.purchases.htmlReceipt(purchase._id, {
                userId: null,
            });
            printHTML(data.html);
        } catch (err) {
            toast.error(
                get(err, "response.data.message") ||
                    "An error occurred printing.",
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            variant="outline"
            onClick={printReceipt}
            className="flex items-center gap-2"
            disabled={isLoading}
        >
            {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
                <Printer className="h-4 w-4" />
            )}
            Print Order
        </Button>
    );
};
