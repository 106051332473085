import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

import { FilterSelect } from "src/@/components/ui/filter-select";
// eslint-disable-next-line no-restricted-imports
import { ReportsContext } from "#app/reports-context-provider";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { Button } from "src/@/components/ui/button";
import { getActiveStore } from "src/redux/selectors";
import { filterNulls } from "src/utils/filterNulls";

export const LocationFilterPicker = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { filter, stores } = reportsState;

    // Adds session store to location list - for snackpass employees so that we don't need to add ourselves as an admin to every store
    const storeList = useMemo(
        () => [
            ...stores.filter((store) => store._id !== activeStore?._id),
            activeStore,
        ],
        [stores, activeStore],
    );

    const toggleAllStores = useCallback(
        () =>
            setReportsState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    storeIds:
                        filter.storeIds.length === storeList.length
                            ? []
                            : filterNulls(storeList.map((e) => e?._id)),
                },
            })),
        [filter, storeList, setReportsState],
    );

    const storeOptions = useMemo(
        () => [
            {
                label: "All Locations",
                value: "",
                onClickOverride: toggleAllStores,
                selectedOverride: filter.storeIds.length === storeList.length,
            },
            ...storeList.map((e) => ({
                label: e?.name ?? "",
                value: e?._id ?? "",
            })),
        ],
        [filter.storeIds.length, storeList, toggleAllStores],
    );

    const selectedValues = useMemo(
        () => new Set(filter.storeIds),
        [filter.storeIds],
    );

    return (
        <FilterSelect
            title={"Locations"}
            selectedValues={selectedValues}
            options={storeOptions}
            search
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className={clsx(
                        "flex items-center space-x-2",
                        selectedValues.size > 0 && "border-neutral-800",
                    )}
                >
                    <FilterIcon className="h-4 w-4" />
                    <span>Locations</span>
                </Button>
            }
            onOptionSelected={(value: string) => {
                if (selectedValues.has(value)) {
                    selectedValues.delete(value);
                } else {
                    selectedValues.add(value);
                }
                setReportsState((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        storeIds: Array.from(selectedValues),
                    },
                }));
            }}
            onClearOptions={() =>
                setReportsState((prevState) => ({
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        storeIds: [],
                    },
                }))
            }
        />
    );
};
