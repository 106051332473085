/** @jsxImportSource @emotion/react */
import React, { MouseEventHandler, RefObject, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { css } from "@emotion/react";
import { ChevronDownIcon, ChevronRightIcon } from "@radix-ui/react-icons";

const MenuItem = styled.div<{ isActive?: boolean }>`
    padding: 7px;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    color: ${SystemColors.v1.sesame};
    background-color: ${({ isActive }) =>
        isActive ? SystemColors.v2.salt90.light : ""};
    cursor: pointer;

    &:hover {
        background-color: ${({ isActive }) =>
            isActive
                ? SystemColors.v2.salt100.light
                : SystemColors.v2.salt20.light} !important;
    }
`;

const MenuItemStyle = css`
    text-decoration: none;

    .menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 8px;
        margin: 10px;
    }
    .menu-item-disabled {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 8px;
        margin: 10px;
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }
    .sub-menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;
        margin-left: 18px;
    }
    .nested-menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;
        margin-left: 18px;
    }
    .nested-menu-item h3 {
        margin-left: 24px;
    }
    .icon {
        width: 30px;
        text-align: center;
    }
    .title {
        font-weight: medium;
        font-size: 15px;
        margin-bottom: 1px;
        margin-left: 5px;
    }
`;

type SidebarItemProps = {
    onClick?: MouseEventHandler<HTMLElement>;
    link: string;
    title: string;
    rightComponent?: JSX.Element;
    icon?: React.ReactElement;
    isSubItem?: boolean;
    isActive: boolean;
    isNestedSubItem?: boolean;
    scrollRootRef: RefObject<HTMLElement>;
    hasChildren: boolean;
};

export const SidebarItemDesktop = (props: SidebarItemProps) => {
    const {
        onClick,
        link,
        title,
        rightComponent,
        icon,
        isActive,
        scrollRootRef,
        hasChildren,
    } = props;

    const theColor = isActive ? SystemColors.v1.white : SystemColors.v1.sesame;

    let finalIcon = icon;
    if (icon != null) {
        finalIcon = React.cloneElement(icon, {
            fill: theColor,
            height: 16,
            width: 16,
        });
    }

    return (
        <Link to={link} onClick={onClick} css={MenuItemStyle}>
            {props.isSubItem ? (
                <SubItem
                    active={isActive}
                    color={SystemColors.v1.sesame}
                    title={title}
                    rightComponent={rightComponent}
                    scrollRootRef={scrollRootRef}
                    hasChildren={hasChildren}
                />
            ) : props.isNestedSubItem ? (
                <NestedSubItem
                    active={isActive}
                    color={SystemColors.v1.sesame}
                    title={title}
                    rightComponent={rightComponent}
                    scrollRootRef={scrollRootRef}
                    hasChildren={hasChildren}
                />
            ) : (
                <NormalItem
                    active={isActive}
                    color={theColor}
                    title={title}
                    icon={finalIcon}
                    rightComponent={rightComponent}
                    scrollRootRef={scrollRootRef}
                    hasChildren={hasChildren}
                />
            )}
        </Link>
    );
};

type Props = {
    active?: boolean;
    color: string;
    icon?: React.ReactElement;
    rightComponent?: React.ReactElement;
    title: string;
    welcome?: boolean; // distinguishes "welcome" nav, where most things are disabled
    scrollRootRef: RefObject<HTMLElement>;
    hasChildren: boolean;
};

const NormalItem = (props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const {
        active,
        color,
        icon,
        title,
        rightComponent,
        welcome,
        scrollRootRef,
        hasChildren,
    } = props;

    useEffect(() => {
        // Scroll item into view when it becomes active
        // Account for 10px margin of `.menu-item`
        if (hasChildren && active && ref.current && scrollRootRef.current) {
            scrollRootRef.current.scrollTop = Math.max(
                0,
                ref.current.offsetTop - 10,
            );
        }
    }, [hasChildren, active, scrollRootRef]);

    // Normal functioning nav items after onboarding complete
    if (!welcome) {
        return (
            <MenuItem ref={ref} isActive={active} className="menu-item">
                <div style={{ color: color }} className="icon">
                    {icon}
                </div>
                <h3 style={{ color: color }} className="title">
                    {title}
                </h3>
                {rightComponent}
            </MenuItem>
        );
    }

    // Return less functional nav item during onboarding
    return (
        <MenuItem isActive={active} className="menu-item-disabled">
            <div style={{ color: color }} className="icon">
                {icon}
            </div>
            <h3 style={{ color: color }} className="title">
                {title}
            </h3>
        </MenuItem>
    );
};

const SubItem = (props: Props) => {
    const { active, color, title, rightComponent, hasChildren } = props;
    return (
        <MenuItem
            style={{
                borderLeft: active ? "2px solid #0088FF" : "2px solid #E1E3E6",
            }}
            className="sub-menu-item"
        >
            <h3
                style={{ color: color, fontWeight: active ? "bold" : "normal" }}
                className="title  flex items-center"
            >
                {title}{" "}
                {hasChildren ? (
                    active ? (
                        <ChevronDownIcon className="ml-1 h-4 w-4" />
                    ) : (
                        <ChevronRightIcon className="ml-1 h-4 w-4" />
                    )
                ) : null}
            </h3>
            {rightComponent}
        </MenuItem>
    );
};

const NestedSubItem = (props: Props) => {
    const { active, color, title, rightComponent } = props;
    return (
        <MenuItem
            style={{
                borderLeft: active ? "2px solid #0088FF" : "2px solid #E1E3E6",
            }}
            className="nested-menu-item"
        >
            <h3
                style={{ color: color, fontWeight: active ? "bold" : "normal" }}
                className="title"
            >
                {title}
            </h3>
            {rightComponent}
        </MenuItem>
    );
};
