import { Link } from "react-router-dom";

import { Routes } from "#navigation/routes";
import { useMultiStoreSalesSummary } from "#navigation/utils";
import FilterHeader from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportType } from "#reports/sales-summary/types";

import {
    SalesSummaryTiles,
    SalesHourlyChart,
    SalesByPeriodChart,
    SalesDailyChart,
} from "./components";
import { Alert, AlertDescription } from "src/@/components/ui/alert";

const Reports = () => {
    const multiStoreSalesSummaryEnabled = useMultiStoreSalesSummary();
    return (
        <div>
            <FilterHeader
                reportType={ReportType.SALES_SUMMARY}
                needsComparison
                showLocationFilter={multiStoreSalesSummaryEnabled}
            />
            <div className="px-6 md:px-24">
                <SalesSummaryTiles />
                <SalesByPeriodChart />
                <SalesHourlyChart />
                <SalesDailyChart />
                <Alert variant="warning" className="mb-4">
                    <AlertDescription>
                        ⚠️ "Sales Breakdown" has moved. You can now find it in{" "}
                        <Link to={Routes.FinancialReportsEssentials}>
                            Financials -&gt; Essentials
                        </Link>
                        .
                    </AlertDescription>
                </Alert>
                <br />
            </div>
        </div>
    );
};

export default Reports;
