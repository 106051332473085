import { BaseStoreDevice } from "./deviceTypes";

const MIN_LENGTH = 5;
const MAX_LENGTH = 30;

const validSyntax = (name: string) =>
    name.length >= MIN_LENGTH && name.length <= MAX_LENGTH;

const formatName = (name: string) => name.toLowerCase().trim();

export const checkValidName = (
    storeDevices: BaseStoreDevice[] = [],
    name = "",
    deviceId = "",
) => {
    if (!storeDevices) return { valid: true }; // nothing to conflict with
    const nameToCheck = formatName(name);

    const unique = !storeDevices
        .filter((d: BaseStoreDevice) => d.id !== deviceId)
        .map((d: BaseStoreDevice) => formatName(d.name))
        .includes(nameToCheck);

    const syntax = validSyntax(nameToCheck);

    let error = "";
    if (!syntax) {
        error = `Device name must be between ${MIN_LENGTH} and ${MAX_LENGTH} characters`;
    } else if (!unique) {
        error = "A device with that name already exists";
    }

    return { valid: unique && syntax, error };
};
