import { useContext } from "react";

import { MenuServiceContext } from "../MenuServiceProvider";

/**
 * Sources the menu service from the `MenuServiceContext`.
 */
export function useMenuService() {
    return useContext(MenuServiceContext);
}
