import { Button, Modal } from "react-bootstrap";
import React, { Dispatch, SetStateAction } from "react";
import { match } from "ts-pattern";

import {
    GenericPromo,
    GiftCardPromo,
    RewardPromo,
} from "#promotion/utils/types";
import {
    isGenericPromo,
    isRewardPromo,
    isGiftCardPromo,
} from "#promotion/utils/guards";
import { useProductEssentialsMap } from "#menu/hooks";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    onClick: () => Promise<void>;
    state: GenericPromo | RewardPromo | GiftCardPromo;
    formUsage: string;
};

const toTitleCase = (str: string) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

export const ReviewModal = ({
    isModalOpen,
    setIsModalOpen,
    onClick,
    state,
    formUsage,
}: ModalProps) => {
    const productsMap = useProductEssentialsMap();

    const getProductNames = (ids: string[]) => {
        if (!ids) return null;
        if (ids.length === 0) return "Any Item";
        return ids
            .map((id) => productsMap[id]?.name ?? "Unknown Product")
            .join(", ");
    };

    const discountMessage = match(state?.DISCOUNT_TYPE)
        .with(
            "PERCENT_OFF",
            () => `Percentage Off (%) - ${state?.DISCOUNT_AMOUNT_PERCENT}%`,
        )
        .with(
            "DOLLARS_OFF",
            () => `Amount Off ($) - $${state?.DISCOUNT_AMOUNT_DOLLARS}`,
        )
        .with("FREE_ITEM", () => "Free Item")
        .with(
            "NEW_PRICE",
            () => `New Price ($) - $${state?.DISCOUNT_NEWPRICEAMOUNT_DOLLARS}`
        )
        .otherwise(() => "");

    return (
        <Modal
            show={isModalOpen}
            dialogClassName="modal-width"
            centered
            size="lg"
            scrollable={true}
        >
            <Modal.Header>
                <Modal.Title className="text-2xl font-bold text-gray-800">
                    Review Changes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="mb-4">
                    <h5 className="text-lg font-semibold text-gray-800">
                        Name:
                    </h5>
                    <p className="text-base text-gray-600">{state?.NAME}</p>
                </div>
                <div className="mb-4">
                    <h5 className="text-lg font-semibold text-gray-800">
                        Discount Type and Amount:
                    </h5>
                    <p className="text-base text-gray-600">{discountMessage}</p>
                </div>
                {(isGenericPromo(state) || isRewardPromo(state)) && (
                    <div className="mb-4">
                        <h5 className="text-lg font-semibold text-gray-800">
                            Discounted Items:
                        </h5>
                        <p className="text-base text-gray-600">
                            {getProductNames(
                                state?.DISCOUNTED_ITEMS[0].products,
                            )}
                        </p>
                    </div>
                )}
                {isRewardPromo(state) && (
                    <>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Points Required:
                            </h5>
                            <p className="text-base text-gray-600">
                                {state?.POINTS_REQUIRED}
                            </p>
                        </div>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Apply Discount to Add-ons:
                            </h5>
                            <p className="text-base text-gray-600">
                                {state?.DISCOUNT_ADDONS ? "Yes" : "No"}
                            </p>
                        </div>
                    </>
                )}
                {isGiftCardPromo(state) && (
                    <>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Minimum Load:
                            </h5>
                            <p className="text-base text-gray-600">
                                {state?.MINIMUM_PRICE
                                    ? state?.MINIMUM_PRICE
                                    : "N/A"}
                            </p>
                        </div>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Maximum Discount:
                            </h5>
                            <p className="text-base text-gray-600">
                                {state?.MAXIMUM_DISCOUNT
                                    ? "$" + state?.MAXIMUM_DISCOUNT
                                    : "N/A"}
                            </p>
                        </div>
                    </>
                )}
                {isGenericPromo(state) && !isGiftCardPromo(state) && (
                    <>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Cart Size:
                            </h5>
                            <p className="text-base text-gray-600">
                                Minimum -{" "}
                                {state?.CART_MINIMUM
                                    ? `$${state?.CART_MINIMUM}`
                                    : "none selected"}
                            </p>
                            <p className="text-base text-gray-600">
                                Maximum -{" "}
                                {state?.CART_MAXIMUM
                                    ? `$${state?.CART_MAXIMUM}`
                                    : "none selected"}
                            </p>
                        </div>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                One per Cart:
                            </h5>
                            <p className="text-base text-gray-600">
                                {state?.ONE_PER_CART ? "Yes" : "No"}
                            </p>
                        </div>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Customer Rules:
                            </h5>
                            <p className="text-base text-gray-600">
                                Audience:{" "}
                                {state?.AUDIENCE === "firstTime"
                                    ? "First-time customers"
                                    : state?.AUDIENCE}
                            </p>
                            <p className="text-base text-gray-600">
                                Single-use: {state?.SINGLE_USE ? "Yes" : "No"}
                            </p>
                            <p className="text-base text-gray-600">
                                Limit Total Uses:{" "}
                                {state?.LIMIT_USES
                                    ? `Yes - ${state?.TOTAL_USES} uses`
                                    : "No"}
                            </p>
                        </div>
                        <div className="mb-4">
                            <h5 className="text-lg font-semibold text-gray-800">
                                Availability:
                            </h5>
                            <p className="text-base text-gray-600">
                                Channel:{" "}
                                {state?.PLATFORMS.includes("only")
                                    ? toTitleCase(
                                          state?.PLATFORMS.split("only")[1],
                                      )
                                    : toTitleCase(
                                          state?.PLATFORMS.split("And").join(
                                              ", ",
                                          ),
                                      )}
                            </p>
                            <p className="text-base text-gray-600">
                                Fulfillment Methods:{" "}
                                {state?.FULFILLMENT_METHODS ===
                                "pickupAndDineIn"
                                    ? "Pick Up, Dine In"
                                    : toTitleCase(state?.FULFILLMENT_METHODS)}
                            </p>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer className="flex justify-end p-4">
                <Button
                    variant="secondary"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                >
                    Back to Edit
                </Button>
                <Button className="px-6" onClick={onClick}>
                    {formUsage === "Edit" ? "Save Promo" : "Create Promo"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
