import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import {
    BrandRegistrationPage,
    smsBrandRegistrationPageSelector,
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import CampaignBrandRegistrationGetStarted from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationGetStarted";
import CampaignBrandRegistrationPendingReview from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationPendingReview";
import CampaignBrandRegistrationTextMessageForm from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/CampaignBrandRegistrationTextMessageForm";
import SharedBrandRegistrationSMSForm from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationSMSForm";

function CampaignBrandRegistration() {
    const page = useRecoilValue(smsBrandRegistrationPageSelector);
    useEffect(() => {
        // Reset scroll position when page changes
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, [page]);
    return (
        <>
            {
                {
                    [BrandRegistrationPage.GetStarted]: (
                        <CampaignBrandRegistrationGetStarted />
                    ),
                    [BrandRegistrationPage.ShareSMSBrandForm]: (
                        <SharedBrandRegistrationSMSForm />
                    ),
                    [BrandRegistrationPage.SMSForm]: (
                        <CampaignBrandRegistrationTextMessageForm />
                    ),
                    [BrandRegistrationPage.PendingTwilioReview]: (
                        <CampaignBrandRegistrationPendingReview />
                    ),
                }[page]
            }
        </>
    );
}

export default CampaignBrandRegistration;
