import React, { useContext } from "react";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import StripeTerminalDetails from "#devices/components/DetailsDrawer/DeviceDetails/StripeTerminalDetails";
import { DeviceType } from "#devices/utils/deviceTypes";

import OrderHubDetails from "./OrderHubDetails";
import KDSDetails from "./KDSDetails";
import KioskDetails from "./KioskDetails";
import SnackTvDetails from "./SnackTvDetails";
import PrinterDetails from "./PrinterDetails";
import UnknownDetails from "./UnknownDetails";

const DeviceDetailView = (): JSX.Element => {
    const { device } = useContext(DevicesPageContext);

    switch (device?.deviceType) {
        case DeviceType.OrderHub:
            return <OrderHubDetails />;
        case DeviceType.KDS:
            return <KDSDetails />;
        case DeviceType.Kiosk:
            return <KioskDetails />;
        case DeviceType.SnackTV:
            return <SnackTvDetails />;
        case DeviceType.Printer:
            return <PrinterDetails />;
        case DeviceType.StripeTerminal:
            return <StripeTerminalDetails />;
        default:
            return <UnknownDetails device={device} />;
    }
};

export default DeviceDetailView;
