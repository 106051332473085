import React from "react";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";

import { ReactComponent as Error } from "src/assets/icons/rules-alert.svg";
import { ReactComponent as Warning } from "src/assets/icons/warn-schedule-ahead-ability.svg";

export enum AlertLevel {
    warning = "warning",
    error = "error",
}

export type MessageProps = {
    type: "warning" | "error" | undefined;
    description: string | undefined;
    paddingVertical?: string | undefined;
    noIcon?: boolean;
};

export const ValidatorMessage: React.FC<MessageProps> = ({
    type,
    description,
    paddingVertical,
    noIcon,
}) => (
    <ValidationMessage paddingVertical={paddingVertical}>
        {noIcon ? null : type && type === "error" ? (
            <Error className={`icon ${type}`} />
        ) : (
            <Warning className={`icon ${type}`} />
        )}

        <div className={`warning-text ${type}`}>{description}</div>
    </ValidationMessage>
);

const ValidationMessage = styled.div<{
    paddingVertical: string | undefined;
}>`
    display: flex;
    align-content: center;
    height: fit-content;
    padding-top: ${(props) =>
        props.paddingVertical ? props.paddingVertical : "8px"};
    padding-bottom: ${(props) =>
        props.paddingVertical ? props.paddingVertical : "8px"};
    .icon {
        align-self: center;
    }

    .warning-text {
        padding-left: 6px;
        font-size: 14px;
        line-height: 16px;
    }

    .warning {
        color: ${SystemColors.v1.macaroni30};
    }
    .warning path {
        fill: ${SystemColors.v1.macaroni50};
    }
    .error {
        color: ${SystemColors.v1.melon50};
    }
`;
