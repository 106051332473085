/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChannelMetadataObject, ObjectCustom } from "pubnub";
import { useLocation } from "react-router-dom";

import { GuestbookCampaignGrid } from "#guestbook/styles/grid";
import { ConversationsHomeHeader } from "#guestbook/screens/conversation-home/Conversation-home-header";
import { ConversationsList } from "#guestbook/components/conversations-list";
import { ConversationDetails } from "#guestbook";
import { getActiveStore } from "src/redux/selectors";
import colors from "#reusable/colors/colors.json";
import { getActiveStoreNumUnreads } from "src/redux/slices";

export const ConversationHome = () => {
    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id ?? "";
    const numUnreads = useSelector(getActiveStoreNumUnreads);
    const location = useLocation<{
        channel?: ChannelMetadataObject<ObjectCustom>;
        channelUserId: string;
    }>();

    const [activeChannelId, setActiveChannelId] = useState<string>("");
    const [activeChatChannel, setActiveChatChannel] = useState<
        ChannelMetadataObject<ObjectCustom> | undefined
    >();
    const [showConversation, setShowConversation] = useState<boolean>(false);

    useEffect(() => {
        const channel = location.state?.channel;
        if (channel) {
            setActiveChannelId(location.state.channelUserId);
            setActiveChatChannel(channel);
            setShowConversation(true);
        }
    }, [location]);

    return (
        <GuestbookCampaignGrid>
            <ConversationsHomeHeader showConversation={showConversation} />
            <ConversationsHomeBodyWrapper>
                <SidebarContainer showConversation={showConversation}>
                    <ConversationsList
                        activeChannelId={activeChannelId}
                        setActiveChannelId={setActiveChannelId}
                        setActiveChatChannel={setActiveChatChannel}
                        setShowConversation={setShowConversation}
                        numUnreads={numUnreads}
                    />
                </SidebarContainer>
                <ContentContainer showConversation={showConversation}>
                    {showConversation ? (
                        <ConversationDetails
                            storeId={storeId}
                            userId={activeChannelId}
                            activeChatChannel={activeChatChannel}
                            setShowConversation={setShowConversation}
                        />
                    ) : (
                        <InitialStateContainer>
                            <div className="title">Select a Message</div>
                            <div className="sub-message">
                                Choose a conversation to chat, give gifts, or
                                resolve issues with your customers
                            </div>
                        </InitialStateContainer>
                    )}
                </ContentContainer>
            </ConversationsHomeBodyWrapper>
            <ConversationsHomeFooterWrapper />
        </GuestbookCampaignGrid>
    );
};

// empty footer with a set height on home page to add padding bottom
const ConversationsHomeFooterWrapper = styled.div`
    grid-area: Footer;
    display: flex;
    flex-direction: row;
    height: 0px;
    z-index: 10;
`;

const ConversationsHomeBodyWrapper = styled.div`
    grid-area: Body;
    display: flex;
    flex-direction: row;
`;

type SidebarContainerProps = {
    showConversation: boolean;
};

const SidebarContainer = styled.div<SidebarContainerProps>`
    width: 30%;
    min-width: 250px;
    height: 100%;
    border-right: 1px solid ${colors["neutral-400"]};
    position: relative;

    @media ${ScreenState.MOBILE} {
        width: 100%;
        display: ${({ showConversation }) =>
            showConversation ? "none" : "flex"};
    }
`;

type ContentContainerProps = {
    showConversation: boolean;
};

const ContentContainer = styled.div<ContentContainerProps>`
    width: 70%;
    height: calc(100vh - 129px);
    @media ${ScreenState.MOBILE} {
        width: 100%;
        display: ${({ showConversation }) =>
            showConversation ? "flex" : "none"};
        height: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const InitialStateContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }

    .sub-message {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${SystemColors.v2.salt60.dark};
        width: 40%;
        text-align: -webkit-center;
    }
`;
