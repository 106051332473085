import { IStore } from "@snackpass/snackpass-types";

import { MinimumFormValues } from "#settings/settings-fees/utils/types";

const DEFAULT_VALUE = 50; // fifty cents

export const minimumFormDefaults = (store: IStore): MinimumFormValues => {
    const minimumChargeAmountCents =
        store?.minimumChargeAmountCents ?? DEFAULT_VALUE;
    return { minimumChargeAmountCents };
};
