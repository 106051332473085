import React, { useContext } from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { BaseStoreDevice } from "#devices/utils/deviceTypes";

type DeviceNameProps = {
    device: BaseStoreDevice;
};

const StyledSpan = styled.span`
    color: ${SystemColors.v2.candy50.light};
    cursor: pointer;
`;

const DeviceName = ({ device }: DeviceNameProps): JSX.Element => {
    const { setIsDetailsDrawerVisible, setDeviceToView } =
        useContext(DevicesPageContext);

    const handleClick = () => {
        setDeviceToView(device);
        setIsDetailsDrawerVisible(true);
    };

    const deviceName =
        device.name.length > 20
            ? device.name.slice(0, 17).concat("...")
            : device.name;

    return <StyledSpan onClick={handleClick}>{deviceName}</StyledSpan>;
};

export default DeviceName;
