import React, { useMemo, useState, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Legend } from "@tremor/react";
import clsx from "clsx";
import {
    NameType,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from "recharts";

import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType,
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { ShowLegendSwitch } from "#reports/location-sales/components/ShowLegendSwitch";
import {
    LocationMenuGenericRow,
    LocationMenuMatchedRow,
} from "#reports/location-menu-categories/types";
import { ReportsContext } from "#app/reports-context-provider";
import { matchMenuRowsToColors } from "#reports/location-menu-categories/lib";
import { CHART_COLORS } from "#reports/location-sales/lib";
import { calculateChartWidth } from "#utils/helpers";
import { ChartConfig, ChartContainer } from "src/@/components/ui/chart";

const chartConfig = {} satisfies ChartConfig;

type Props = {
    data?: { [storeId: string]: LocationMenuGenericRow[] };
    loading?: boolean;
    failed?: boolean;
    sortAscending?: boolean;
    title: string;
};

// the number of items we display per store
const ITEMS_PER_STORE = 5;

// the height of one bar in our bar chart
const BAR_HEIGHT = 50;

const LocationMenuRowChart = ({
    title,
    sortAscending,
    data,
    failed,
    loading,
}: Props) => {
    const { reportsState } = useContext(ReportsContext);
    const { stores, filter } = reportsState;

    const [graphType, setGraphType] = useState(ChartType.NET_SALES);
    const [showLegend, setShowLegend] = useState(false);

    const valueFormatter = useMemo(
        () =>
            graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber,
        [graphType],
    );

    const { matchedRows, colorByMatchedRowMap } = useMemo(() => {
        const compare = sortAscending
            ? (a: number, b: number) => a - b
            : (a: number, b: number) => b - a;
        const sortedDataSliced: {
            [storeId: string]: LocationMenuGenericRow[];
        } = {};

        for (const storeId in data) {
            const rows = data[storeId];
            sortedDataSliced[storeId] = rows
                .sort((a, b) =>
                    graphType == ChartType.NET_SALES
                        ? compare(a.netSales, b.netSales)
                        : compare(a.orders, b.orders),
                )
                .slice(0, ITEMS_PER_STORE);
        }
        return matchMenuRowsToColors(sortedDataSliced);
    }, [data, graphType, sortAscending]);

    const flattenedItems = Object.values(matchedRows)
        .reduce((prev, curr) => prev.concat(curr), [])

        // remove duplicates
        .reduce((acc, item) => {
            if (!acc.some((i) => i.name === item.name)) {
                acc.push(item);
            }
            return acc;
        }, [] as LocationMenuMatchedRow[])

        // sort by statistic
        .sort((a, b) =>
            graphType == ChartType.NET_SALES
                ? b.netSales - a.netSales
                : b.orders - a.orders,
        );

    const chartData = useMemo(() => {
        if (!data) return [];

        return Object.entries(matchedRows).map(([storeId, rows], index) => ({
            storeName: stores.find((e) => e._id === storeId)?.name || "",

            // passing in values here so the tooltip has access to them
            valueFormatter,
            index,
            totalStores: filter.storeIds.length,
            total: data[storeId].reduce(
                (prev, curr) =>
                    graphType === ChartType.NET_SALES
                        ? prev + curr.netSales
                        : prev + curr.orders,
                0,
            ),

            // the shape required is { itemName: value }
            ...rows.reduce((prev, curr) => {
                const value =
                    graphType === ChartType.NET_SALES
                        ? curr.netSales
                        : curr.orders;
                return {
                    ...prev,
                    [curr.name]: value,
                };
            }, {}),
        }));
    }, [matchedRows, graphType]);

    // calculate by store name length
    const yWidth = (): number => {
        const maxStoreNameLength = Math.max(
            ...chartData.map((item) => item.storeName.length),
        );
        return calculateChartWidth(maxStoreNameLength, false);
    };

    return (
        <div className="my-10">
            <div
                className={`flex items-center justify-between ${
                    !showLegend ? "mb-4" : ""
                }`}
            >
                <div className="flex flex-col justify-center sm:flex-row">
                    <h4 className="text-large sm:mr-5">{title}</h4>
                    <ShowLegendSwitch
                        value={showLegend}
                        onChange={setShowLegend}
                    />
                </div>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            {showLegend && (
                <div className="mb-6">
                    <Legend
                        className="p-0"
                        colors={Object.values(colorByMatchedRowMap)}
                        categories={Object.keys(colorByMatchedRowMap)}
                    />
                </div>
            )}
            <div
                className="w-full"
                style={{
                    // This chart maintains a fixed height and scales its bars down rather than taking up more height.
                    // Using the style prop here because tailwind would not like a dynamically constructed class with the store filter length.
                    // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
                    height: BAR_HEIGHT * filter.storeIds.length,
                }}
            >
                {!loading && (failed || data) ? (
                    data ? (
                        <ChartContainer
                            className="h-full w-full"
                            config={chartConfig}
                        >
                            <BarChart
                                accessibilityLayer
                                data={chartData}
                                layout="vertical"
                            >
                                <XAxis
                                    type="number"
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={valueFormatter}
                                />
                                <CartesianGrid vertical={false} />
                                <YAxis
                                    type="category"
                                    dataKey="storeName"
                                    axisLine={false}
                                    width={yWidth()}
                                />
                                <Tooltip content={CustomTooltip} />
                                {flattenedItems.map((item) => (
                                    <Bar
                                        stackId="stack"
                                        key={item.name}
                                        dataKey={item.name}
                                        // These colors are in our safelist!
                                        className={`fill-${item.color}-500`}
                                        /* Fill is necessary here because the tooltip
                                        needs it to be set in order to access the `color` attribute */
                                        fill={item.color}
                                        strokeWidth={2}
                                        radius={4}
                                    />
                                ))}
                            </BarChart>
                        </ChartContainer>
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-full rounded-md" />
                )}
            </div>
        </div>
    );
};

const CustomTooltip = ({
    payload,
    active,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload || !payload[0]) return null;

    return (
        <div className="z-[9999] flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            <p className="font-semibold">{label}</p>
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div
                        className={clsx(
                            "flex w-1 flex-col rounded",
                            CHART_COLORS.find((e) => e.color === category.color)
                                ?.className,
                        )}
                    />
                    <div className="w-full">
                        <div className="flex w-full justify-between">
                            <div className="text-neutral-600">
                                {category.name}
                            </div>
                            <div className="ml-2 font-medium text-neutral-800">
                                {category.payload["valueFormatter"](
                                    category.value,
                                )}
                            </div>
                        </div>
                        <div className="text-micro text-neutral-600">
                            {calculatePercent(
                                (category.value ?? 0) as number,
                                category.payload.total,
                            )}{" "}
                            of all sales
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const calculatePercent = (numerator: number, denominator: number): string => {
    if (denominator === 0) {
        return "0%";
    }
    const percent = (numerator / denominator) * 100;
    return percent.toFixed(percent % 1 === 0 ? 0 : 2) + "%";
};

export default LocationMenuRowChart;
