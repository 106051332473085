import React, { useContext } from "react";
import styled from "styled-components";
import { Pencil2Icon } from "@radix-ui/react-icons";
import { ScreenState } from "@snackpass/snackpass-types";

import { Button } from "src/@/components/ui/button";
import Text from "#devices/components/Text";
import colors from "#reusable/colors/colors.json";
import DrawerBackButton from "#devices/components/DrawerBackButton";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { copyAndNotify } from "#utils/helpers";
import { DeviceType } from "#devices/utils/deviceTypes";

const DrawerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin: ${({ theme }) =>
        `-${theme.spacing.baseAndAHalf} -${theme.spacing.baseAndAHalf} ${theme.spacing.baseAndAHalf} -${theme.spacing.baseAndAHalf}`};
    padding: ${({ theme }) => theme.spacing.baseAndAHalf};

    & h3 {
        margin-bottom: 0;
    }

    @media ${ScreenState} {
        padding-top: ${({ theme }) => theme.spacing.base};
        padding-bottom: ${({ theme }) => theme.spacing.base};
    }
`;

type DetailsDrawerHeaderProps = {
    onClose: () => void;
    deviceName: string;
    deviceType: DeviceType | undefined;
    isLoading: boolean;
};

const DetailsDrawerHeader = ({
    onClose,
    deviceName,
    deviceType,
    isLoading,
}: DetailsDrawerHeaderProps): JSX.Element => {
    const { setIsDetailsDrawerVisible, setIsEditDrawerVisible } =
        useContext(DevicesPageContext);

    const { isMobile } = useWindowDimensions();

    const handleEditClick = () => {
        setIsDetailsDrawerVisible(false);
        setIsEditDrawerVisible(true);
    };

    const EditButton = () => {
        if (isMobile) {
            return (
                <Button
                    onClick={handleEditClick}
                    className="rounded-full p-0"
                    size="icon"
                >
                    <Pencil2Icon className="h-4 w-4" />
                </Button>
            );
        }

        return (
            <Button
                onClick={handleEditClick}
                disabled={isLoading}
                className="px-3"
            >
                <Pencil2Icon className="mr-1" />
                Edit
            </Button>
        );
    };

    return (
        <DrawerHeader>
            <DrawerBackButton onClose={onClose} />
            <StyledDeviceName
                onClick={() =>
                    copyAndNotify({ value: deviceName, field: "Device Name" })
                }
            >
                {deviceName}
            </StyledDeviceName>
            <EditButton />
        </DrawerHeader>
    );
};

const StyledDeviceName = styled(Text.Title3)`
    cursor: pointer;
`;

export default DetailsDrawerHeader;
