import React, { useContext } from "react";
import produce from "immer";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import Toggle from "#devices/components/FormControls/Toggle";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType,
    DEFAULT_CHANNEL_OPTIONS,
} from "#devices/utils/deviceTypes/SnackTVDevice";

export const FirstInitial = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel,
    );

    const checked =
        channelOptions?.options?.onlyShowFirstInitial ??
        DEFAULT_CHANNEL_OPTIONS.onlyShowFirstInitial;

    return (
        <SettingsSection
            label="Customer First Initial"
            description="Show only the first initial for customer orders"
        >
            <Toggle
                id="customerFirstInitialToggle"
                checked={checked}
                onChange={(e) => {
                    const updatedOptions = produce(deviceOptions, (draft) => {
                        if (draft) {
                            const channelOption = draft.find(
                                (o) => o.channel === channel,
                            );
                            if (!channelOption) {
                                draft.push({
                                    channel: channel as ChannelType,
                                    options: {
                                        onlyShowFirstInitial: e.target.checked,
                                    },
                                });
                            } else {
                                channelOption.options.onlyShowFirstInitial =
                                    e.target.checked;
                            }
                        }
                    });
                    updateDeviceField("channelOptions", updatedOptions);
                }}
            />
        </SettingsSection>
    );
};
