import { IAddress, IStore } from "@snackpass/snackpass-types";
import { Col, Form, Row } from "antd";
import { useCallback, useState } from "react";
import PlacesAutocomplete, {
    getLatLng,
    geocodeByPlaceId,
} from "react-places-autocomplete";

import { descriptions } from "#onboarding/utils/descriptions";
import { normalizeAddress } from "#settings/settings-business-info/utils/address";

import { FormSelect } from "./FormSelect";
import { FormInput } from "./FormInput";

export type FormAddressProps = {
    value?: Partial<IAddress>;
    label: string;
    subtitle?: string;
    required?: boolean;
    disabled?: boolean;
    onChange: (
        update: Partial<
            IAddress & {
                geolocation: IStore["geolocation"];
                isAddressValid: boolean;
            }
        >,
    ) => void;
    pretext?: string;
};

export const FormAddress = ({
    value,
    label,
    subtitle,
    required = false,
    disabled = false,
    onChange,
}: FormAddressProps) => {
    const [fetching, setFetching] = useState(false);
    const [sessionToken, setSessionToken] = useState(
        new google.maps.places.AutocompleteSessionToken(),
    );

    /**
     * When a user selects an address from the dropdown or updates the address,
     * we geocode and normalize the address for storage in the database.
     */
    const onSelectAddress = useCallback(
        async (address: string, placeId: string) => {
            // Prevent multiple in flight requests
            if (fetching) return;
            setFetching(true);

            try {
                // Channel and geocode the address
                const [place] = await geocodeByPlaceId(placeId);
                const latLng = await getLatLng(place);

                // Normalize the address into `IAddress` format
                const addressObj: IAddress = {
                    ...normalizeAddress(
                        place.address_components ?? [],
                        address,
                    ),
                    line2: value?.line2 ?? null,
                    full: place.formatted_address,
                };

                onChange({
                    ...addressObj,
                    geolocation: {
                        type: "Point",
                        coordinates: [latLng.lng, latLng.lat],
                    },
                    isAddressValid: true,
                });
            } catch (error) {
                // TODO: Handle / surface error here.
                onChange({ isAddressValid: false });
            } finally {
                setFetching(false);
            }
        },
        [fetching, value, onChange],
    );

    return (
        <Col span={24}>
            <Row>
                <FormSelect
                    required={required}
                    disabled={true}
                    className="full"
                    field="country"
                    label={label}
                    subtitle={subtitle}
                    placeholder={descriptions.address.country}
                    options={[{ value: "USA", label: "United States" }]}
                />
            </Row>
            <Row>
                <Form.Item name="line1" className="w-full">
                    <PlacesAutocomplete
                        value={value?.line1 ?? ""}
                        onChange={(line1) => onChange({ line1 })}
                        onSelect={(...args) => {
                            setSessionToken(
                                new google.maps.places.AutocompleteSessionToken(),
                            );
                            void onSelectAddress(...args);
                        }}
                        searchOptions={{
                            componentRestrictions: { country: "us" },
                            sessionToken,
                        }}
                    >
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                        }) => (
                            <>
                                <input
                                    {...getInputProps({
                                        placeholder: "Enter address...",
                                        disabled: disabled,
                                    })}
                                    className="mb-2 h-[32px] w-full rounded-lg"
                                />
                                <div className="relative w-full">
                                    <div className="absolute inset-x-0 top-0 z-[1000] w-full">
                                        {loading && (
                                            <div className="bg-white">
                                                Loading...
                                            </div>
                                        )}
                                        {suggestions.map((suggestion) => {
                                            const addProps = suggestion.active
                                                ? {
                                                      className:
                                                          "suggestion-item--active",
                                                      style: {
                                                          backgroundColor:
                                                              "#fafafa",
                                                          cursor: "pointer",
                                                      },
                                                  }
                                                : {
                                                      className:
                                                          "suggestion-item",
                                                      style: {
                                                          backgroundColor:
                                                              "#ffffff",
                                                          cursor: "pointer",
                                                      },
                                                  };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        addProps,
                                                    )}
                                                    className="border border-neutral-200 py-1 pl-2"
                                                    key={suggestion.placeId}
                                                >
                                                    <span>
                                                        {suggestion.description}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </PlacesAutocomplete>
                </Form.Item>
            </Row>
            <Row>
                <FormInput
                    disabled={true}
                    noLabel
                    field="line2"
                    className="compact bg-white"
                    placeholder={descriptions.address.line2}
                />
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item className="compact">
                        <Row>
                            <FormInput
                                disabled={true}
                                noLabel
                                span={12}
                                field="city"
                                className="left bg-white text-neutral-800"
                                placeholder={descriptions.address.city}
                            />
                            <FormInput
                                noLabel
                                span={6}
                                className="center rounded-none bg-white text-neutral-800"
                                field="state"
                                label={label}
                                subtitle={subtitle}
                                placeholder={descriptions.address.state}
                                disabled={true}
                            />
                            <FormInput
                                disabled={true}
                                noLabel
                                span={6}
                                field="postalCode"
                                className="right bg-white text-neutral-800"
                                placeholder={descriptions.address.postalCode}
                            />
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    );
};
