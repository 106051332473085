import { Canvas } from "fabric";
import { match, P } from "ts-pattern";

/**
 * Subscribes to the `object:moving` event and forces objects to snap to the nearest grid tile
 * @param canvas Fabric canvas instance
 * @param gridSize The size of a single grid tile, assuming a `gridSize x gridSize` tile
 * @param snap The step size within one grid tile. This will split up one `gridSize x gridSize` tile into `snap` steps.
 */
export function withSnapToGrid(canvas: Canvas, gridSize = 30, snap = 1) {
    const moving = canvas.on("object:moving", (e) => {
        if (
            Math.round((e.target.left / gridSize) * snap) % snap === 0 &&
            Math.round((e.target.top / gridSize) * snap) % snap === 0
        ) {
            e.target.set({
                left: snapToGrid(e.target.left, gridSize),
                top: snapToGrid(e.target.top, gridSize),
            });
            e.target.setCoords();
        }
    });

    const mouse = canvas.on("mouse:up", (e) => {
        if (e.target != null) {
            e.target.set({
                left: snapToGrid(e.target.left, gridSize),
                top: snapToGrid(e.target.top, gridSize),
            });
            e.target.setCoords();
        }
    });

    const scaling = canvas.on("object:scaling", (e) => {
        const active = canvas.getActiveObject();

        if (!active) return;

        const scaledWidth = active.getScaledWidth();
        const scaledHeight = active.getScaledHeight();

        // Algorithm adapted from https://stackoverflow.com/a/70673823
        match(e.transform.corner)
            .with(P.union("tl", "ml", "bl"), () => {
                const left = snapToGrid(active.left, gridSize);
                active.scaleX =
                    (scaledWidth + active.left - left) /
                    (active.width + active.strokeWidth);
                active.left = left;
            })
            .with(P.union("tr", "mr", "br"), () => {
                const right = snapToGrid(active.left + scaledWidth, gridSize);
                active.scaleX =
                    (right - active.left) / (active.width + active.strokeWidth);
            })
            .otherwise(() => null);

        match(e.transform.corner)
            .with(P.union("tl", "mt", "tr"), () => {
                const top = snapToGrid(active.top, gridSize);
                active.scaleY =
                    (scaledHeight + active.top - top) /
                    (active.height + active.strokeWidth);
                active.top = top;
            })
            .with(P.union("bl", "mb", "br"), () => {
                const bottom = snapToGrid(active.top + scaledHeight, gridSize);
                active.scaleY =
                    (bottom - active.top) /
                    (active.height + active.strokeWidth);
            })
            .otherwise(() => null);

        // Avoid scaling down until the object disappears
        active.scaleX =
            (active.scaleX >= 0 ? 1 : -1) *
            Math.max(Math.abs(active.scaleX), 0.001);
        active.scaleY =
            (active.scaleY >= 0 ? 1 : -1) *
            Math.max(Math.abs(active.scaleY), 0.001);
    });

    return () => {
        moving();
        mouse();
        scaling();
    };
}

function snapToGrid(pos: number, gridSize: number) {
    return Math.round(pos / gridSize) * gridSize;
}
