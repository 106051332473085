export enum RotationState {
    AUTO = "AUTO",
    PORTRAIT_ONLY = "PORTRAIT_ONLY",
    LANDSCAPE_ONLY = "LANDSCAPE_ONLY",
    INVERTED_PORTRAIT_ONLY = "INVERTED_PORTRAIT_ONLY",
    INVERTED_LANDSCAPE_ONLY = "INVERTED_LANDSCAPE_ONLY",
}

export type DeviceStats = {
    batteryPercent: number;
    networkConnectionType: "cellular" | "ethernet" | "other" | "wifi" | "none";
    networkName: string;
    isReachable?: boolean;
    appVersion: string | null;
    rotationState: RotationState;
};
