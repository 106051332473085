import { Addon, AddonGroup, IProduct } from "@snackpass/snackpass-types";

import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";

export type RowInstance = WithParentId<
    IProductCategoryWithProducts | IProduct | AddonGroup | Addon
>;

export type Row = {
    original: RowInstance;
    getToggleRowExpandedProps: Function;
    depth: number;
    canExpand: boolean;
    isExpanded: boolean;
};

export type WithParentId<T> = T & { parentId?: string };

export type CellProps = { row: Row; rowsById: Record<string, Row> };

export type HeaderProps = {
    getToggleAllRowsExpandedProps: Function;
    state: { expanded: Record<string, boolean> };
    isAllRowsExpanded: boolean;
};

export enum MenuOutlineCellTypeEnum {
    Item = "Item",
    Overrides = "Overrides",
    Actions = "Actions",
}
