import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import { Input } from "src/@/components/ui/input";
import { useEditorState } from "#table-editor/components/Editor/EditorProvider";
import { Button } from "src/@/components/ui/button";

type RenameSectionDialogProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    initialName: string;
    onSubmit(name: string): void;
};

export function RenameSectionDialog({
    open,
    onOpenChange,
    initialName,
    onSubmit,
}: RenameSectionDialogProps) {
    const serviceAreas = useEditorState((state) => state.serviceAreas);
    const activeServiceArea = useEditorState(
        (state) => state.activeServiceArea,
    );

    const existingServiceAreas = useMemo(
        () =>
            new Set(
                serviceAreas
                    .filter(
                        (area) =>
                            area.name !== initialName &&
                            area.id !== activeServiceArea?.id,
                    )
                    .map((area) => area.name),
            ),
        [activeServiceArea, initialName, serviceAreas],
    );

    const schema = z.object({
        name: z
            .string()
            .min(1)
            .refine(
                (val) => !existingServiceAreas.has(val),
                "Section name must be unique",
            ),
    });

    const { formState, register, handleSubmit } = useForm({
        resolver: zodResolver(schema),
        values: {
            name: initialName,
        },
        mode: "onChange",
    });

    const submit = handleSubmit(({ name }) => {
        onSubmit(name);
    });

    return (
        <Dialog modal open={open} onOpenChange={onOpenChange}>
            <DialogContent className="p-0">
                <DialogHeader className="px-6 pt-6">
                    <DialogTitle>Rename Section</DialogTitle>
                </DialogHeader>
                <div className="px-6">
                    <Input
                        {...register("name")}
                        placeholder="Name (ex. Patio)"
                        className="w-full text-black placeholder:text-neutral-500"
                    />
                    {formState.errors.name ? (
                        <p className="text-sm text-critical-a11y-light">
                            {formState.errors.name.message}
                        </p>
                    ) : null}
                </div>
                <DialogFooter className="border-t border-t-neutral-400 px-6 py-3">
                    <Button type="button" variant="default" onClick={submit}>
                        Rename Section
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
