import { useMemo } from "react";
import { GuardedRoute } from "react-router-guards";

import EmployeeLaborCost from "#employees/employee-labor-cost";
import EmployeeTimeCards from "#employees/employee-time-cards";
import Billing from "#billing";
import Dashboard from "#dashboard";
import { Inventory } from "#inventory";
import MenuEditor from "#menu-editor/mobile-friendly/items";
import { OrderDetailsRoute } from "#order-details";
import Orders from "#orders";
import QrCode from "#qr-code";
import AttendanceSettings from "#time-and-attendance/settings/AttendanceSettings";
import { PayoutHistoryReports } from "#financial-reports/screens/payout-history/payout-history";
import Devices from "#devices";
import Reports from "#reports/sales-summary";
import Channels from "#reports/sales-channels";
import ItemInsights from "#reports/menu-item-insights";
import CategoryInsights from "#reports/menu-category-insights";
import CashReportLayout from "#financial-reports/screens/cash-report";
import { TaxForms } from "#tax-forms";
import PayoutsSettings from "#payouts";
import LegalSettings from "#settings/settings-legal";
import LocationSalesReports from "#reports/location-sales";
import LocationMenuCategoryReports from "#reports/location-menu-categories";
import LocationMenuItemReports from "#reports/location-menu-items";
import { WhatsNew } from "#whatsNew";
import { EssentialsLayout } from "#financial-reports/screens/essentials-report";
import PromotionsReport from "#reports/promotions";
import { Routes } from "#navigation/routes";
import SettingsProductsScreen from "#settings/settings-products";
import SettingsBusinessInfoScreen from "#settings/settings-business-info";
import SettingsPlatformsScreen from "#settings/settings-platforms";
import SettingsTippingsScreen from "#settings-tipping";
import SettingsOrdersScreen from "#settings/settings-orders";
import { GenericPromotionForm } from "#promotion/forms/generic";
import { RewardPromotionForm } from "#promotion/forms/reward";
import { HappyHourPromotionForm } from "#promotion/forms/happy-hour";
import { BogoPromotionForm } from "#promotion/forms/bogo";
import { DealDropPromotionForm } from "#promotion/forms/deal-drop";
import { AudienceSpecificPromotionForm } from "#promotion/forms/audience-specific";
import { PromoCodePromotionForm } from "#promotion/forms/promo-code";
import PromotionsScreen from "#promotion";
import { SettingsBrandAndAppearanceScreen } from "#settings/settings-brand-and-appearance";
import GiftCardPurchaseReport from "#reports/gift-card-purchases";
import GiftCardBalanceReport from "#reports/gift-card-balances";
import GiftCardTransactionReport from "#reports/gift-card-transactions";
import { Onboarding } from "#onboarding";
import GiftCardLiabilitiesReport from "#reports/gift-card-liabilities";
import { SettingsAllOrders } from "#settings/settings-all-orders";
import { SettingsBackOfHouse } from "#settings/settings-back-of-house";
import { SettingsPermissionsScreen } from "#settings/settings-permissions/admins-table";
import { SettingsTaxScreen } from "#settings/settings-tax";
import { SettingsOnlineOrders } from "#settings/settings-online-orders";
import { GiftCardPromotionForm } from "#promotion/forms/gift-card";
import NoAccessPage from "#utility-pages/no-access-page";
import { Integrations } from "#settings-integrations";
import AccountDetails from "#settings/components/account-details";
import { MyTeams } from "#my-teams";
import { SignOut } from "#sign-out";
import { PrepStationsPage } from "#prep-stations";
import CustomerLoyalty from "#reports/customer-directory-insights";
import CustomerPurchaseReport from "#reports/customer-directory-insights/CustomerPurchaseReport";
import { PayoutPolicyManagement } from "#payouts/components/PolicyManagement";
import { ReportsOverview } from "#reports/overview";
import { MarketingOverview } from "#marketing/overview";
import { BusinessToolsOverview } from "#business-tools/overview";
import { TablesEditor } from "#table-editor";
import { menuManagerRoutes } from "#menu-manager/routes";
import { MenuManager } from "#menu-manager";

import { useConditionalGuardedRoutes } from "./useConditionalGuardedRoutes";
import GuestbookNavigation from "./guestbook";
import SettingsCateringScreen from "#settings-catering";

/**
 * This function produces the final list of <GuardedRoutes> to render on main-page.
 *
 * If a route should only be shown based on some condition it should be added to `useConditionalGuardedRoutes`.
 *
 * If it can be shown with no conditions it should be added below.
 *
 * We return an array of route elements because we can't have conditional logic, or non-route elements inside <Switch></Switch>
 * https://v5.reactrouter.com/web/api/Switch
 * https://stackoverflow.com/questions/66463284/react-router-warning-route-elements-should-not-change-from-controlled-to-unco
 */
export function useGuardedRoutes() {
    const conditionalRoutes = useConditionalGuardedRoutes();

    return useMemo(() => {
        const routes = [
            <GuardedRoute
                exact
                path={Routes.NotAllowed}
                component={NoAccessPage}
            />,
            <GuardedRoute exact path={Routes.Index} component={Dashboard} />,
            <GuardedRoute
                exact
                path={Routes.Onboarding}
                component={Onboarding}
            />,
            <GuardedRoute exact path={Routes.WhatsNew} component={WhatsNew} />,
            <GuardedRoute exact path={Routes.BetaOrders} component={Orders} />,
            <GuardedRoute
                exact
                path={Routes.ReportsSalesSummary}
                component={Reports}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsSalesChannels}
                component={Channels}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsMenuItemInsights}
                component={ItemInsights}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsMenuCategoryInsights}
                component={CategoryInsights}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsLocationSales}
                component={LocationSalesReports}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsPromotions}
                component={PromotionsReport}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsGiftCardPurchases}
                component={GiftCardPurchaseReport}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsGiftCardBalances}
                component={GiftCardBalanceReport}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsGiftCardTransactions}
                component={GiftCardTransactionReport}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsGiftCardLiabilities}
                component={GiftCardLiabilitiesReport}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsLocationMenuCategories}
                component={LocationMenuCategoryReports}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsLocationMenuItems}
                component={LocationMenuItemReports}
            />,
            <GuardedRoute
                exact
                path={Routes.FinancialReportsCash}
                component={CashReportLayout}
            />,
            <GuardedRoute
                exact
                path={Routes.FinancialReportsEssentials}
                component={EssentialsLayout}
            />,
            <GuardedRoute
                exact
                path={Routes.FinancialReportsPayoutHistory}
                component={PayoutHistoryReports}
            />,
            <GuardedRoute exact path={Routes.Devices} component={Devices} />,
            <GuardedRoute
                exact
                path={Routes.PrepStations}
                component={PrepStationsPage}
            />,
            <GuardedRoute
                exact
                path={[Routes.Inventory, Routes.MenuInventoryManager]}
                component={Inventory}
            />,
            <GuardedRoute
                exact
                path={[Routes.Menu, Routes.MenuEditor]}
                component={MenuEditor}
            />,
            <GuardedRoute
                exact
                path={[Routes.MenuManager, ...menuManagerRoutes]}
                component={MenuManager}
            />,
            <GuardedRoute
                exact
                path={[Routes.OrdersDetails, Routes.PurchaseDetails]}
                component={OrderDetailsRoute}
            />,
            <GuardedRoute
                exact
                path={Routes.EmployeeLaborCost}
                component={EmployeeLaborCost}
            />,
            <GuardedRoute
                exact
                path={Routes.EmployeeTimeCards}
                component={EmployeeTimeCards}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsPermissions}
                component={SettingsPermissionsScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsEmployees}
                component={AttendanceSettings}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsAccount}
                component={AccountDetails}
            />,
            <GuardedRoute exact path={Routes.Logout} component={SignOut} />,
            <GuardedRoute
                exact
                path={Routes.SettingsIntegrations}
                component={Integrations}
            />,
            <GuardedRoute
                exact
                path={Routes.Payouts}
                component={PayoutsSettings}
            />,
            <GuardedRoute
                exact
                path={Routes.PayoutsSchedule}
                component={PayoutPolicyManagement}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsCatering}
                component={SettingsCateringScreen}
            />,
            <GuardedRoute exact path={Routes.TaxForms} component={TaxForms} />,
            <GuardedRoute
                exact
                path={Routes.SettingsLegal}
                component={LegalSettings}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsProducts}
                component={SettingsProductsScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsQrCode}
                component={QrCode}
            />,
            <GuardedRoute
                exact
                path={[
                    Routes.GuestbookConversations,
                    Routes.GuestbookCampaignsSMS,
                    Routes.GuestbookCampaignsSMSDetails,
                    Routes.GuestbookCampaignsPush,
                    Routes.GuestbookCampaignsPushDetails,
                    Routes.GuestbookAuditLog,
                ]}
                component={GuestbookNavigation}
            />,
            <GuardedRoute
                exact
                path={Routes.GuestbookCustomers}
                component={CustomerLoyalty}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsCustomerDirectoryInsights}
                component={CustomerPurchaseReport}
            />,
            <GuardedRoute exact path={Routes.Billing} component={Billing} />,
            <GuardedRoute
                exact
                path={Routes.SettingsBusinessInfo}
                component={SettingsBusinessInfoScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsPlatforms}
                component={SettingsPlatformsScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsTipping}
                component={SettingsTippingsScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsOrders}
                component={SettingsOrdersScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsAllOrders}
                component={SettingsAllOrders}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsOnlineOrders}
                component={SettingsOnlineOrders}
            />,

            <GuardedRoute
                exact
                path={Routes.SettingsTax}
                component={SettingsTaxScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsBackOfHouse}
                component={SettingsBackOfHouse}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionGeneric}
                component={GenericPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionReward}
                component={RewardPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionHappyHour}
                component={HappyHourPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionBogo}
                component={BogoPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionDealDrop}
                component={DealDropPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionStudents}
                component={AudienceSpecificPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionFirstTime}
                component={AudienceSpecificPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionPromoCode}
                component={PromoCodePromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.CreatePromotionGiftCard}
                component={GiftCardPromotionForm}
            />,
            <GuardedRoute
                exact
                path={Routes.Promotion}
                component={PromotionsScreen}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsBrandAndAppearance}
                component={SettingsBrandAndAppearanceScreen}
            />,

            <GuardedRoute
                exact
                path={Routes.MyAccountTeams}
                component={MyTeams}
            />,
            <GuardedRoute
                exact
                path={Routes.ReportsOverview}
                component={ReportsOverview}
            />,
            <GuardedRoute
                exact
                path={Routes.MarketingOverview}
                component={MarketingOverview}
            />,
            <GuardedRoute
                exact
                path={Routes.BusinessToolsOverview}
                component={BusinessToolsOverview}
            />,
            <GuardedRoute
                exact
                path={Routes.SettingsStoreTablesLayout}
                component={TablesEditor}
            />,
        ];
        routes.push(...conditionalRoutes);
        return routes;
    }, [conditionalRoutes]);
}
