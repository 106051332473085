import { useContext, useEffect } from "react";
import { match } from "ts-pattern";

import { Business } from "#onboarding/components/steps/Business";
import { Location } from "#onboarding/components/steps/Location";
import { Summary } from "#onboarding/components/steps/Summary";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { OnboardingStep } from "#onboarding/utils/types";

export const Steps = () => {
    const { resetState, step } = useContext(OnboardingContext);

    useEffect(() => {
        const handleEsc = (e: KeyboardEvent) =>
            /Escape/i.test(e.key) ? resetState() : null;
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    return match(step)
        .with(OnboardingStep.Summary, () => <Summary />)
        .with(OnboardingStep.Business, () => <Business />)
        .with(OnboardingStep.Location, () => <Location />)
        .exhaustive();
};
