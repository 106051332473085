import { IProduct, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class ProductPriceCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductPriceCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems() {
        return [];
    }

    checkItem(item: IProduct): MenuIssue<IProduct> | null {
        const { price, priceByWeight } = item;

        // at least one price must be set
        if ((priceByWeight?.perUnit || 0) <= 0 && price <= 0) {
            return new MenuIssue(
                "InvalidProductPrice",
                "Either price or price per unit must be > $0.",
                "critical",
                item,
            );
        }
        return null;
    }
}

export { ProductPriceCheck };
