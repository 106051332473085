import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";

import theme from "#payouts/utils/theme";

/** Component to overwrite some basic styling of the ant components */
const ModalStyles = (): JSX.Element => (
    <>
        <style type="text/css">
            {`
                .ant-modal {
                    top: ${theme.spacing.double};
                    margin-bottom: ${theme.spacing.triple};

                    @media ${ScreenState.TABLET}, ${ScreenState.MOBILE} {
                        top: ${theme.spacing.half};
                        margin-bottom: 80px;
                    }

                    .ant-modal-content {                        
                        border-radius: ${theme.spacing.base};
                    }
                    .ant-modal-body {
                        margin-bottom: 0;
                        padding-bottom: ${theme.spacing.quarter};
                    }
                    .ant-modal-header {
                        border-radius: ${theme.spacing.base};
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    .ant-modal-footer {
                        bottom: ${theme.spacing.double};
                        border-radius: ${theme.spacing.base};
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
                .ant-divider,
                .ant-divider-horizontal {
                    margin: ${theme.spacing.double} auto;
                }
                .ant-input,
                .ant-input-number {
                    border-radius: ${theme.spacing.half};
                }
                .ant-select-selection-placeholder,
                .ant-input-number::placeholder,
                .ant-input::placeholder {
                    color: #a0a2a7;
                    font-size: 14px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
                .ant-input.left {
                    border-radius: 0;
                    border-top-left-radius: ${theme.spacing.half};
                    border-bottom-left-radius: ${theme.spacing.half};
                    border-right: none;
                }
                .ant-input.right {
                    border-radius: 0;
                    border-top-right-radius: ${theme.spacing.half};
                    border-bottom-right-radius: ${theme.spacing.half};
                    border-left: none;
                }
                .ant-input-number-group-addon,
                .ant-input-group-addon {
                    border-top-left-radius: ${theme.spacing.half};
                    border-bottom-left-radius: ${theme.spacing.half};
                }
                .ant-checkbox-inner {
                    margin: 1px;
                    padding: 4px;
                    border: 1px solid #a0a2a7;
                    border-radius: 4px;
                }
                .ant-checkbox + span {
                    font-size: 16px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
                .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                    margin: 0;
                }
                .ant-divider,
                .ant-divider-horizontal {
                    margin: 10px 0;
                }
                .ant-input-number-handler-wrap {
                    border-top-right-radius: ${theme.spacing.half};
                    border-bottom-right-radius: ${theme.spacing.half};
                }
                .ant-form-item {
                    margin-bottom: ${theme.spacing.base};
                }
                .ant-form-item.compact {
                    margin-bottom: ${theme.spacing.half};
                }
                ${exclude([full, center, right].join(","))} {
                    border-top-left-radius: ${theme.spacing.half};
                    border-bottom-left-radius: ${theme.spacing.half};
                    border-right: none;
                }
                ${exclude([full, left, right].join(","))} {
                    border-radius: 0;
                }
                ${exclude([full, center, left].join(","))} {
                    border-left: none;
                }
                ${exclude([full, left, center, right].join(","))} {
                    border-radius: ${theme.spacing.half};
                }
                ${exclude([left, center, right].join(","))} {
                    border-radius: ${theme.spacing.half};
                }
                ${exclude([left, center].join(","))} {
                    border-top-right-radius: ${theme.spacing.half};
                    border-bottom-right-radius: ${theme.spacing.half};
                }
                .ant-upload-list-item-list-type-text {
                    min-height: ${theme.spacing.double};
                    border-radius: ${theme.spacing.base};
                }
                .ant-upload-list-item.ant-upload-list-item-undefined.ant-upload-list-item-list-type-text {
                    border-radius: ${theme.spacing.half};
                }
                .ant-collapse,
                .ant-collapse-header,
                .ant-collapse-item.ant-collapse-no-arrow {
                    border: none;
                    background-color: rgba(0, 0, 0, 0);
                }
                .ant-collapse-item.ant-collapse-no-arrow {
                    border-top: 1px solid #DFE0E2;
                }
                .ant-collapse-item.ant-collapse-no-arrow:first-child {
                    border-top: none;
                }
                .ant-collapse-header,
                .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
                    border-radius: ${theme.spacing.half};
                }
                .ant-collapse-header:hover {
                    background-color: rgba(0,0,0,0.05);
                }
                .ant-collapse-content {
                    font-size: 16px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
                .ant-dropdown-menu {
                    border-radius: ${theme.spacing.half};
                    padding-top: 0;
                    padding-bottom: 0;
                }
                .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
                .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled
                {
                    transition: background-color 0.1s ease-in-out;
                    border-radius: ${theme.spacing.half};
                }
                `}
        </style>
    </>
);

const full = ".full";
const left = ".left";
const center = ".center";
const right = ".right";

const exclude = (classes: string) =>
    `.ant-select:not(${[".ant-select-customize-input", classes].join(
        ",",
    )}) .ant-select-selector`;

export default ModalStyles;
