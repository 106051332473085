import { FormTextInput } from "#settings-integrations/components/shared/form-text-input";
import { INTEGRATION_FIELD_NAMES } from "#settings-integrations/utils/types";
import { FormFieldName } from "#settings-integrations/components/shared/form-field-name";

export const MarkupComment = () => (
    <>
        <FormFieldName name="Markup Comments" optional />
        <FormTextInput
            inputLabel="Comments"
            fieldName={INTEGRATION_FIELD_NAMES.MARKUP_COMMENTS}
            placeholder="Ex: $0.25 more just for toppings"
            height={52}
            isTextArea
        />
    </>
);
