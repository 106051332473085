import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import FilterHeader, {
    FilterType,
} from "#reports/sales-summary/shared-components/FilterHeader";
import api from "src/api/rest";
import {
    LocationReportsDataType,
    ReportsContext,
} from "#app/reports-context-provider";
import { getActiveStore } from "src/redux/selectors";
import { ReportType } from "#reports/sales-summary/types";
import LocationSalesDailyChart from "#reports/location-sales/components/LocationSalesDailyChart";
import LocationSalesReportTable from "#reports/location-sales/components/LocationSalesReportTable";
import LocationSalesHourlyChart from "#reports/location-sales/components/LocationSalesHourlyChart";
import LocationNetSalesChart from "#reports/location-sales/components/LocationNetSalesChart";
import LocationSummaryTiles from "#reports/location-sales/components/LocationSummaryTiles";
import LocationSalesWChannelByPeriodChart from "#reports/location-sales/components/LocationSalesWChannelByPeriodChart";
import { getStoreTimezone } from "#utils/helpers";
import { logAndSendError } from "src/utils/errors";

const LocationSalesReports = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, filter, stores } = reportsState;

    const abortControllerSummary = useRef<AbortController | null>(null);
    const abortControllerReport = useRef<AbortController | null>(null);
    const abortControllerAggregateReport = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.locationReportsData) {
            setReportsState((reportsState) => ({
                ...reportsState,
                locationReportsData: {
                    salesSummaryDataLoading: true,
                    salesSummaryDataFailed: false,
                    salesSummaryData:
                        reportsState.locationReportsData?.salesSummaryData,

                    salesReportAggregateDataLoading: true,
                    salesReportAggregateDataFailed: false,
                    salesReportAggregateData:
                        reportsState.locationReportsData
                            ?.salesReportAggregateData,

                    salesReportDataLoading: true,
                    salesReportDataFailed: false,
                    salesReportData:
                        reportsState.locationReportsData?.salesReportData,
                    salesHourlyAggregateReportData:
                        reportsState.locationReportsData
                            ?.salesHourlyAggregateReportData,
                    salesDailyAggregateReportData:
                        reportsState.locationReportsData
                            ?.salesDailyAggregateReportData,
                },
            }));
            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        if (abortControllerReport.current) {
            abortControllerReport.current.abort();
        }

        if (abortControllerSummary.current) {
            abortControllerSummary.current.abort();
        }

        if (abortControllerAggregateReport.current) {
            abortControllerAggregateReport.current.abort();
        }

        abortControllerReport.current = new AbortController();
        abortControllerSummary.current = new AbortController();
        abortControllerAggregateReport.current = new AbortController();

        const storeIds = stores.length ? filter.storeIds : [activeStore?._id];
        const params = {
            storeId: activeStore?._id,
            storeIds: JSON.stringify(storeIds),
            startDate: dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: dateRanges[0][1].format("YYYY-MM-DD"),
            comparedToStartDate: dateRanges[1][0].format("YYYY-MM-DD"),
            comparedToEndDate: dateRanges[1][1].format("YYYY-MM-DD"),

            channel: JSON.stringify(filter.channel),
            source: JSON.stringify(filter.source),
            fulfillment: JSON.stringify(filter.fulfillment),
            timezone: getStoreTimezone(activeStore),
        };

        if (storeIds.length === 0) {
            // if no stores are inputted, we want to show the "no data" failed state.
            setReportsState((reportsState) => ({
                ...reportsState,
                locationReportsData: {
                    salesSummaryDataLoading: false,
                    salesSummaryDataFailed: true,
                    salesSummaryData: undefined,

                    salesReportAggregateDataLoading: false,
                    salesReportAggregateDataFailed: true,
                    salesReportAggregateData: undefined,

                    salesReportDataLoading: false,
                    salesReportDataFailed: true,
                    salesReportData: undefined,
                    salesHourlyAggregateReportData: undefined,
                    salesDailyAggregateReportData: undefined,
                },
            }));
            return;
        }

        api.reports
            .getSalesSummary(params, abortControllerSummary.current.signal)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationReportsData: {
                        ...(reportsState.locationReportsData as LocationReportsDataType),
                        salesSummaryDataLoading: false,
                        salesSummaryDataFailed: false,
                        salesSummaryData: res.data?.salesSummary,
                    },
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationReportsData: {
                        ...(reportsState.locationReportsData as LocationReportsDataType),
                        salesSummaryDataLoading: false,
                        salesSummaryDataFailed: true,
                        salesSummaryData: undefined,
                    },
                }));
            });

        api.reports
            .getLocationSalesReport(params, abortControllerReport.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationReportsData: {
                        ...(reportsState.locationReportsData as LocationReportsDataType),
                        salesReportDataLoading: false,
                        salesReportDataFailed: false,
                        salesReportData: res.data?.salesTotalReport,
                        salesHourlyAggregateReportData:
                            res.data?.salesHourlyAggregateReport,
                        salesDailyAggregateReportData:
                            res.data?.salesDailyAggregateReport,
                    },
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationReportsData: {
                        ...(reportsState.locationReportsData as LocationReportsDataType),
                        salesReportDataLoading: false,
                        salesReportDataFailed: true,
                        salesReportData: undefined,
                        salesHourlyAggregateReportData: undefined,
                        salesDailyAggregateReportData: undefined,
                    },
                }));
            });

        api.reports
            .getSalesReport(
                params,
                abortControllerAggregateReport.current.signal,
            )
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationReportsData: {
                        ...(reportsState.locationReportsData as LocationReportsDataType),
                        salesReportAggregateDataLoading: false,
                        salesReportAggregateDataFailed: false,
                        salesReportAggregateData: res.data?.salesReport,
                    },
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    locationReportsData: {
                        ...(reportsState.locationReportsData as LocationReportsDataType),
                        salesReportAggregateDataLoading: false,
                        salesReportAggregateDataFailed: true,
                        salesReportAggregateData: undefined,
                    },
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);

    const fetchDataDebounced = useCallback(
        debounce(() => fetchDataRef.current?.(), 600),
        [],
    );

    return (
        <div>
            <FilterHeader
                reportType={ReportType.LOCATION_SALES_REPORT}
                showLocationFilter
                hideFilters={[FilterType.GRANULARITY]}
            />
            <div className="px-6 md:px-24">
                <LocationSummaryTiles />
                <LocationSalesWChannelByPeriodChart />
                <LocationNetSalesChart />
                <LocationSalesDailyChart />
                <LocationSalesHourlyChart />
                <LocationSalesReportTable />
            </div>
        </div>
    );
};

export default LocationSalesReports;
