import { useMemo } from "react";
import { match } from "ts-pattern";

import { ChartType } from "#reports/sales-summary/shared-components/ChartTypeSelect";

export const useDisplayedCategories = (
    shouldCompare: boolean,
    graphType: ChartType,
): string[] => {
    const displayedCategories = useMemo(
        () =>
            match({ shouldCompare, graphType })
                .with(
                    { shouldCompare: false, graphType: ChartType.NET_SALES },
                    () => ["netSales"],
                )
                .with(
                    { shouldCompare: false, graphType: ChartType.ORDERS },
                    () => ["orders"],
                )
                .with(
                    { shouldCompare: true, graphType: ChartType.NET_SALES },
                    () => ["netSales", "compareToNetSales"],
                )
                .with(
                    { shouldCompare: true, graphType: ChartType.ORDERS },
                    () => ["orders", "compareToOrders"],
                )
                .otherwise(() => []),
        [shouldCompare, graphType],
    );

    return displayedCategories;
};
