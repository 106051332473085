import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Button } from "src/@/components/ui/button";
import { Download, Plus, X } from "lucide-react";
import { Badge } from "src/@/components/ui/badge";

import {
    getInventoryEndDate,
    getInventoryTableRows,
    getTopUpPersistence,
} from "src/redux/selectors/inventory";
import { clearTopUpOnHand, InventoryReport } from "src/redux/slices";
import { FeatureBadge } from "#navigation/FeatureBadge";

import { EditItemForm } from "./components/edit-item-form";
import { InventoryTable } from "./components/inventory-table";
import { NewItemForm } from "./components/new-item-form";
import { useGetStartDate, useInitializeInventory } from "./lib";
import TopUpButton from "./components/top-up-button";

const momentFormat = "MMMM Do YYYY, h:mm a";

export const Inventory = () => {
    const [showNewItemModal, setShowNewItemModal] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [editItem, setEditItem] = useState<InventoryReport | null>(null);
    const [showTopUp, setShowTopUp] = useState(false);
    const [exportCSV, isFetching] = useInitializeInventory();
    const endDate = useSelector(getInventoryEndDate);
    const startDate = useGetStartDate(endDate || new Date());
    const rows = useSelector(getInventoryTableRows);

    const dispatch = useDispatch();
    const topUp = useSelector(getTopUpPersistence);
    const [isTopUpChanged, setIsTopUpChanged] = useState(false);

    useEffect(() => {
        setIsTopUpChanged(Object.keys(topUp).length > 0);
    }, [Object.keys(topUp).length]);

    const toggleTopUp = () => {
        if (showTopUp) {
            dispatch(clearTopUpOnHand());
        }
        setShowTopUp(!showTopUp);
    };

    return (
        <div className="p-6">
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                    <div>
                        <CardTitle className="flex items-center gap-2">
                            Ingredients
                            <FeatureBadge variant="beta" />
                        </CardTitle>
                        <p className="text-sm text-muted-foreground">
                            {moment(startDate).format(momentFormat)} -{" "}
                            {moment(endDate).format(momentFormat)}
                        </p>
                    </div>
                    <div className="flex items-center gap-2">
                        {!showTopUp && (
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={exportCSV}
                            >
                                <Download className="mr-2 h-4 w-4" />
                                Export
                            </Button>
                        )}

                        {showTopUp && isTopUpChanged && (
                            <>
                                <Badge variant="outline">Changes made</Badge>
                                <TopUpButton
                                    topUp={topUp}
                                    toggleTopUp={toggleTopUp}
                                />
                            </>
                        )}

                        <Button
                            variant="outline"
                            size="sm"
                            onClick={toggleTopUp}
                        >
                            {showTopUp ? (
                                <>
                                    <X className="mr-2 h-4 w-4" />
                                    Cancel
                                </>
                            ) : (
                                <>
                                    <Plus className="mr-2 h-4 w-4" />
                                    Top Up
                                </>
                            )}
                        </Button>

                        {!showTopUp && (
                            <Button
                                size="sm"
                                onClick={() => setShowNewItemModal(true)}
                            >
                                <Plus className="mr-2 h-4 w-4" />
                                New Item
                            </Button>
                        )}
                    </div>
                </CardHeader>
                <CardContent>
                    <InventoryTable
                        showTopUp={showTopUp}
                        onClickEdit={(item) => {
                            setEditItem(item);
                            setShowEditItemModal(true);
                        }}
                        inventory={rows}
                        loading={isFetching}
                    />
                </CardContent>
            </Card>

            <NewItemForm
                show={showNewItemModal}
                onHide={() => setShowNewItemModal(false)}
            />
            <EditItemForm
                show={showEditItemModal}
                onHide={() => setShowEditItemModal(false)}
                editItem={editItem}
            />
        </div>
    );
};
