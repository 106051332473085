import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import React from "react";

import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";

type Props = {
    handleHide?: () => void;
};

export function PageHeader({ handleHide }: Props) {
    const history = useHistory();

    const handleClick = () => {
        handleHide ? handleHide() : history.goBack();
    };

    return (
        <Container>
            <ChevronContainer>
                <Chevron fill={SystemColors.v1.sesame} onClick={handleClick} />
            </ChevronContainer>
            <Header>Order Details</Header>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    margin-bottom: 16px;
`;

const ChevronContainer = styled.div`
    cursor: pointer;
    margin-right: 15px;
    display: flex;
    align-items: center;
`;

const Header = styled.div`
    font-size: 24px;
    @media ${ScreenState.TABLET} {
        font-size: 20px;
    }
`;
