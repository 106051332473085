import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { EditorState } from "draft-js";
import { IProduct } from "@snackpass/snackpass-types";

import { CampaignAudienceType } from "src/api/graphql/generated/types";
import { DiscountTemplate } from "#guestbook/components/incentives/constants";

export enum TemplateVariableType {
    Product = "PRODUCT",
    Reward = "REWARD",
    Text = "TEXT",
}

type Audience = {
    label: string;
    type: CampaignAudienceType;
    numberOfUsers: number;
};

export type ProductData = {
    type: "product";
    label: string;
    product: IProduct;
};

export type DiscountData = {
    type: "discount";
    label: string;
    template: DiscountTemplate;
};

type MessageVariableData = DiscountData | ProductData;

export type MessageVariable = {
    data: MessageVariableData | null;
    type: TemplateVariableType;
    placeholder: string;
    label: string;
    markup: string;
    id: string;
    // if the message variable is invisible / does not show up
    // in the template (ex. a gift added at the end)
    isInvisible: boolean;
};

type MessageInputState = {
    title: string;
    message: EditorState | null;
    audience: Audience | null;
    variables: MessageVariable[];
};

const initialState: MessageInputState = {
    title: "",
    message: null,
    audience: null,
    variables: [],
};

export const setMessageInput =
    createAction<Pick<MessageInputState, "message" | "variables">>(
        "SET_MESSAGE_INPUT",
    );

export const setMessageInputMessage = createAction<EditorState>(
    "SET_MESSAGE_INPUT_MESSAGE",
);

export const setMessageInputAudience = createAction<Audience>(
    "SET_MESSAGE_INPUT_AUDIENCE",
);

export const addMessageInputVariable = createAction<MessageVariable>(
    "ADD_MESSAGE_INPUT_VARIABLE",
);

export const updateMessageInputVariableData = createAction<{
    id: string;
    data: MessageVariableData | null;
}>("UPDATE_MESSAGE_INPUT_VARIABLE");

export const removeMessageInputVariable = createAction<{
    id: string;
}>("REMOVE_MESSAGE_INPUT_VARIABLE");

export const clearMessageInput = createAction("CLEAR_MESSAGE_INPUT");

export const setMessageInputTitle = createAction<string>(
    "SET_MESSAGE_INPUT_TITLE",
);

export const messageInputReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setMessageInputTitle, (state, action) => {
            state.title = action.payload;
        })
        .addCase(setMessageInput, (state, action) => {
            state.message = action.payload.message;
            state.variables = action.payload.variables;
        })
        .addCase(setMessageInputMessage, (state, action) => {
            state.message = action.payload;
        })
        .addCase(setMessageInputAudience, (state, action) => {
            state.audience = action.payload;
        })
        .addCase(updateMessageInputVariableData, (state, action) => {
            state.variables = state.variables.map((v) => {
                // update the variable data
                if (v.id === action.payload.id) {
                    return {
                        ...v,
                        data: action.payload.data,
                    };
                }

                return v;
            });
        })
        .addCase(addMessageInputVariable, (state, action) => {
            state.variables = [...state.variables, action.payload];
        })
        .addCase(removeMessageInputVariable, (state, action) => {
            state.variables = state.variables.filter(
                (v) => v.id !== action.payload.id,
            );
        })
        .addCase(clearMessageInput, (state) => {
            state.message = null;
            state.title = "";
            state.audience = null;
            state.variables = [];
        });
});

export const getFullMessageInput = (state: any): MessageInputState =>
    state.messageInput;

export const getMessageInput = createSelector(
    getFullMessageInput,
    (m) => m.message,
);

export const getMessageInputTitle = createSelector(
    getFullMessageInput,
    (m) => m.title,
);

export const getMessageInputVariables = createSelector(
    getFullMessageInput,
    (m) => m.variables,
);

export const getMessageInputAudience = createSelector(
    getFullMessageInput,
    (m) => m.audience,
);
