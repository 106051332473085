import React, { useContext } from "react";

import { ReportsContext } from "#app/reports-context-provider";
import TopItemsChart from "#reports/menu-item-insights/shared-components/TopItemsChart";

const ItemSalesChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { menuInsightsData } = reportsState;

    return (
        <TopItemsChart
            data={menuInsightsData?.itemInsightsData}
            isLoading={
                menuInsightsData?.itemInsightsDataLoading ||
                (!menuInsightsData?.itemInsightsData &&
                    !menuInsightsData?.itemInsightsDataFailed)
            }
            title={"Top Items"}
        />
    );
};

export default ItemSalesChart;
