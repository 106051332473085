import React from "react";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import Switch from "#reusable/input/toggle-input";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import colors from "#reusable/colors/colors.json";

type Props = {
    name: string;
    descriptor: string;
    fieldName: string;
    divider?: boolean;
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    disabled?: boolean;
    disabledMessage?: string;
};

type ContainerProps = {
    divider: boolean;
};

export const FormToggle = ({
    name,
    descriptor,
    fieldName,
    divider = false,
    required = false,
    recommended = false,
    autofilled = false,
    disabled = false,
    disabledMessage = "Must have specific discounted items",
}: Props) => {
    const { control, getFieldState } = useFormContext();
    const { error } = getFieldState(fieldName);

    return (
        <Container divider={divider}>
            <LabelContainer>
                <FormFieldName
                    name={name}
                    required={required}
                    recommended={recommended}
                    autofilled={autofilled}
                    disabled={disabled}
                />
                <FormFieldDescriptor
                    descriptor={descriptor}
                    error={error?.message as string}
                    disabled={disabled}
                />
            </LabelContainer>
            <Controller
                control={control}
                name={fieldName}
                render={({ field: { onChange, value } }) => (
                    <Switch
                        id={fieldName}
                        isOn={value}
                        onChange={onChange}
                        disabled={disabled}
                        disabledMessage={disabledMessage}
                    />
                )}
            />
        </Container>
    );
};

const Container = styled.div<ContainerProps>`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    ${(props) =>
        props.divider &&
        `padding-bottom: 24px; border-bottom: 1px solid ${colors["neutral-400"]};`}
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
`;
