import { ContentState, EditorState } from "draft-js";
import React, { useCallback, useState } from "react";
import { chatChannel } from "@snackpass/conversations.types";
import { SystemColors } from "@snackpass/design-system";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { css } from "@emotion/css";
import moment from "moment-timezone";
import { notification as AntdNotification } from "antd";
import ReactLoading from "react-loading";
import { toast } from "sonner";

import {
    clearConversationInput,
    getConversationInput,
    getConversationInputVariables,
    setConversationInputMessage,
} from "#guestbook/redux";
import { TemplateVariableType } from "#guestbook/components/templates/constants";
import api from "src/api/rest";
import {
    MessageActionsProps,
    useConversationValidator,
} from "#guestbook/components/conversation-input/libs";
import { Button } from "#guestbook/shared-components/button";
import { ReactComponent as GiftIcon } from "src/assets/icons/gift-black.svg";
import { ReactComponent as MagicIcon } from "src/assets/icons/sparkle.svg";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { logAndSendError } from "src/utils/errors";

import { Input } from "./input";
import { SendButton } from "./send-button";

type ConversationInputProps = {
    publishMessage: (input: string) => void;
    storeId: string;
    storeName: string;
    userId: string;
};

export const ConversationInput = ({
    publishMessage,
    storeId,
    storeName,
    userId,
}: ConversationInputProps) => {
    const dispatch = useDispatch();
    const message = useSelector(getConversationInput);
    const variables = useSelector(getConversationInputVariables);
    const [isLoading, setIsLoading] = useState(false);
    const validation = useConversationValidator();

    const handleGenerateResponse = async () => {
        if (isLoading) return;

        setIsLoading(true);

        trackSegmentEvent(
            SegmentEvents.Guestbook.Conversations.CLICKED_MAGIC_RESPOND,
            {
                store_id: storeId,
                store_name: storeName,
            },
        );

        void api.conversations
            .generateResponse(chatChannel({ storeId, userId }))
            .then((response) => {
                if (response) {
                    dispatch(
                        setConversationInputMessage(
                            EditorState.createWithContent(
                                ContentState.createFromText(response),
                            ),
                        ),
                    );
                }
            })
            .catch((error) => {
                logAndSendError(error);
                toast.error("Could not generate a response, sorry!");
            })
            .finally(() => setIsLoading(false));
    };

    const _onSubmit = async () => {
        setIsLoading(true);

        if (!validation.isValid) {
            AntdNotification.error({
                message: validation.message || "Error occurred!",
            });
            return;
        }

        if (!message) {
            return;
        }

        const messageString = message.getCurrentContent().getPlainText();
        if (messageString.length !== 0) {
            publishMessage(messageString);
        }

        if (variables.length) {
            // Create specified gifts
            variables.map((variable) => {
                if (variable.type !== TemplateVariableType.Reward) {
                    // TODO: Support other types
                    return;
                }

                // @ts-expect-error TODO: Fix this with a type guard
                const template = variable.data?.template;

                if (!template) {
                    return;
                }

                void api.gifts.createStoreGift({
                    toUserId: userId,
                    storeId,
                    message: template.label,
                    rewardTemplate: {
                        name: template.label,
                        nameForStore: template.label,
                        expirationDate: moment()
                            .add(template.expiresInDays, "days")
                            .toDate(),
                        // FIXME: should type fix wwhen template is fixed
                        productIds: template.products.map((p: any) => p.id),
                        categories: [],
                        storewide: template.storewide,
                        discount: { percentOff: template.discount.percentOff },
                    },
                    hideOnClient: true,
                });
            });
        }

        dispatch(clearConversationInput());

        setIsLoading(false);
    };

    return (
        <div className={styles}>
            <div className="input-container">
                <Input isLoading={isLoading} _onSubmit={_onSubmit} />
            </div>
            <MessageActions
                isLoading={isLoading}
                _onSubmit={_onSubmit}
                handleGenerateResponse={handleGenerateResponse}
            />
        </div>
    );
};

const MessageActions = ({
    _onSubmit,
    isLoading,
    handleGenerateResponse,
}: MessageActionsProps) => {
    const dispatch = useDispatch();

    const onPickReward = useCallback(() => {
        dispatch(show("GiftsDrawer"));
    }, []);

    return (
        <>
            <Button
                text="Choose Gifts"
                variant="outline"
                mobileIconBtn
                prefixIcon={
                    <GiftIcon
                        width={18}
                        height={18}
                        fill={SystemColors.v1.black}
                    />
                }
                fontSize={15}
                height="40px"
                width="140px"
                className="gift-button"
                onClick={onPickReward}
            />
            <Button
                tooltip="Generate a response based on your previous messages."
                text="Magic Respond"
                variant="outline"
                mobileIconBtn
                prefixIcon={
                    isLoading ? (
                        <ReactLoading
                            height={18}
                            width={18}
                            color={SystemColors.v1.black}
                            type="spin"
                        />
                    ) : (
                        <MagicIcon
                            width={18}
                            height={18}
                            fill={SystemColors.v1.black}
                        />
                    )
                }
                fontSize={15}
                height="40px"
                width="180px"
                className="gift-button"
                onClick={handleGenerateResponse}
            />
            <SendButton _onSubmit={_onSubmit} isLoading={isLoading} />
        </>
    );
};

const styles = css`
    display: flex;
    flex-direction: row;
    padding: 10px 25px;
    justify-content: center;
    align-items: flex-end;
    height: 60px;

    .input-container {
        flex: 1;
        margin-right: 10px;
        height: auto;
    }

    .gift-button {
        margin: 3px 3px;
    }
`;
