import { CashEventType, PopulatedCashEvent } from "@snackpass/snackpass-types";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { client } from "src/api/rest/client";
import { useGlobalDate } from "#hooks";
import { getActiveStore } from "src/redux/selectors";
import { sendError } from "src/utils/errors";

type CashEventsQuery = [
    {
        storeId: string;
        cashDrawer?: string;
        employee?: string;
        eventType?: CashEventType;
        sinceDate?: Date;
        untilDate?: Date;
    },
];

const fetchCashEvents = async ({
    queryKey: [
        { storeId, cashDrawer, employee, eventType, sinceDate, untilDate },
    ],
}: QueryFunctionContext<CashEventsQuery>): Promise<PopulatedCashEvent[]> =>
    client
        .get(`/cash-drawers/store/${storeId}/events`, {
            params: {
                sinceDate,
                untilDate,
                cashDrawer,
                employee,
                eventType,
            },
        })
        .then((res) => res.data.cashEvents);

export const useCashEvents = (cashDrawer?: string) => {
    const store = useSelector(getActiveStore);
    const { startDate, endDate } = useGlobalDate();

    const key = useMemo(
        () => ({
            cashDrawer,
            storeId: store?._id || "",
            sinceDate: startDate.toDate(),
            untilDate: endDate.toDate(),
        }),
        [cashDrawer, store?._id, startDate, endDate],
    );

    const query = useQuery({
        queryKey: [key],
        queryFn: fetchCashEvents,
    });

    useEffect(() => {
        if (query.isError) {
            sendError(query.error);
        }
    }, [query.isError, query.error]);

    return {
        ...query,
        data: query.data ?? [],
    };
};

const pendingInvitesRaw = [{ inviteNonce: "aa" }, { inviteNonce: "aa" }];
const pendingInvites = pendingInvitesRaw.map(
    ({ inviteNonce: _inviteNonce, ...rest }) => rest,
);
