import { useRef, useState } from "react";
import { toast } from "sonner";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu";
import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger,
} from "src/@/components/ui/HybridTooltip";
import { useOnClickOutside } from "#hooks/use-on-click-outside";
import api from "src/api/rest";
import { ChannelLink } from "#settings-integrations/modules/deliverect";

interface RequestRemovalButtonProps {
    channel: ChannelLink;
    storeName: string;
    storeId: string;
}

export const RequestRemovalDropdown = ({
    channel,
    storeName,
    storeId,
}: RequestRemovalButtonProps) => {
    const settingsRef = useRef(null);

    const [requestRemovalOpened, setRequestRemovalOpened] = useState(false);

    useOnClickOutside(settingsRef, () => setRequestRemovalOpened(false));

    const [requestRemovalDisabled, setRequestRemovalDisabled] = useState(false);

    const handleRequestRemovalPressed = () => {
        if (!requestRemovalDisabled) {
            api.integrations.request
                .requestIntegration({
                    name: `Partner from store ${storeName}`,
                    storeId: storeId,
                    message: `User is requesting removal of integration ${channel.name} from store ${storeName} with id:${storeId}`,
                })
                .then(({ data: { success } }) => {
                    success
                        ? toast.success(
                              `Successfully requested removal for ${channel.name} integration!`,
                              { duration: 10000 },
                          )
                        : toast.error(
                              `Could not request removal for integration ${channel.name}. Please contact support.`,
                              { duration: 10000 },
                          );
                })
                .catch(() => {
                    toast.error(
                        `Could not request removal for integration ${channel.name}. Please contact support.`,
                        { duration: 10000 },
                    );
                })
                .finally(() => {
                    setRequestRemovalOpened(false);
                    setRequestRemovalDisabled(true);
                });
        }
    };

    return (
        <div>
            <DropdownMenu
                open={requestRemovalOpened}
                onOpenChange={setRequestRemovalOpened}
                modal={false}
            >
                <DropdownMenuTrigger asChild>
                    <DotsHorizontalIcon />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <TooltipProvider delayDuration={100}>
                        <HybridTooltip>
                            <HybridTooltipTrigger asChild>
                                <DropdownMenuItem
                                    onClick={() =>
                                        handleRequestRemovalPressed()
                                    }
                                >
                                    Request Removal
                                </DropdownMenuItem>
                            </HybridTooltipTrigger>
                            <HybridTooltipContent
                                className={
                                    "z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                                }
                                sideOffset={5}
                                side="bottom"
                            >
                                {requestRemovalDisabled ? (
                                    <p>
                                        You have already requested removal for
                                        this integration. Please contact support
                                        with further questions.
                                    </p>
                                ) : (
                                    <p>{`Request removal for your ${channel.name} integration.`}</p>
                                )}
                            </HybridTooltipContent>
                        </HybridTooltip>
                    </TooltipProvider>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
};
