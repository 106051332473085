import React, { useState } from "react";

import ConfirmModal from "#shared-components/ConfirmModal";
import { Switch } from "src/@/components/ui/switch";

type Props = {
    checked?: boolean;
    onCheckedChange: (checked: boolean) => void;
    title: string;
    body: React.ReactNode;
};

function ForceTermsConfirmToToggleOn({
    checked,
    onCheckedChange,
    title,
    body,
}: Props) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    return (
        <>
            <ConfirmModal
                setShowConfirmModal={setShowConfirmModal}
                showConfirmModal={showConfirmModal}
                confirmAction={() => onCheckedChange(true)}
                title={title}
                body={body}
            />
            <Switch
                checked={checked ?? false}
                onCheckedChange={(newValue) => {
                    if (newValue) {
                        setShowConfirmModal(true);
                    } else {
                        // Allow turning off without viewing the terms
                        onCheckedChange(newValue);
                    }
                }}
            />
        </>
    );
}

export default ForceTermsConfirmToToggleOn;
