import styled from "@emotion/styled";
import { Radio, Space, Tooltip } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { SystemColors } from "@snackpass/design-system";

import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FIELD_NAMES, PlatformValues } from "#promotion/utils/types";
import { ReactComponent as InfoIcon } from "src/assets/icons/Info.svg";
import colors from "#reusable/colors/colors.json";

const getDefaultOptions = (isPromoCode: boolean) => [
    {
        label: isPromoCode
            ? "Only available on Snackpass App"
            : "Only show on Snackpass App and Online Ordering",
        value: PlatformValues.OnlyApp,
    },
    {
        label: isPromoCode
            ? "Only available on Snackpass Register"
            : "Only show on Snackpass Register",
        value: PlatformValues.OnlyRegister,
    },
    {
        label: isPromoCode
            ? "Available on Snackpass App and Kiosk"
            : "Show on Snackpass App and Kiosk",
        value: PlatformValues.AppAndKiosk,
    },
    {
        label: isPromoCode
            ? "Available on Snackpass App, Kiosk, and Register"
            : "Show on Snackpass App, Online Ordering, Kiosk, and Register",
        value: PlatformValues.AppAndKioskAndRegister,
    },
];

type PlatformProps = {
    include?: PlatformValues[];
    isPromoCode?: boolean;
};

export const Platforms = ({ include, isPromoCode = false }: PlatformProps) => {
    const { control } = useFormContext();

    const defaultOptions = getDefaultOptions(isPromoCode);
    const options = include
        ? defaultOptions.filter((o) => include.includes(o.value))
        : defaultOptions;
    const hasTooltip = useCallback(
        (value: PlatformValues) =>
            value == PlatformValues.OnlyRegister ||
            value == PlatformValues.AppAndKioskAndRegister,
        [],
    );

    return (
        <Container>
            <FormFieldName name="Platforms" />
            <Controller
                control={control}
                name={FIELD_NAMES.PLATFORMS}
                render={({ field: { onChange, value } }) => (
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            {options.map(({ value, label }, index) => (
                                <Radio value={value} key={`${value}-${index}`}>
                                    <RadioContent>
                                        {label}
                                        {hasTooltip(value) ? (
                                            <Tooltip title="Promos with a required purchase or multiple items discounted are not yet available for Register">
                                                <InfoIcon
                                                    height={14}
                                                    width={14}
                                                    fill={
                                                        SystemColors.v1.gray20
                                                    }
                                                />
                                            </Tooltip>
                                        ) : null}
                                    </RadioContent>
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                )}
            />
        </Container>
    );
};

const Container = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin-bottom: 24px;
`;

const RadioContent = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
