import { useMemo } from "react";

import { location } from "#onboarding/utils/validation/completed";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStore, getUser } from "src/redux/selectors";

export function useEmployeeOnboardingAccess() {
    const activeStore = useAppSelector(getActiveStore);
    const activeUser = useAppSelector(getUser);

    const isSnackpassEmployee =
        activeUser?.snackpassPermissions.isSnackpassEmployee ?? false;

    return useMemo(() => {
        // #inc-175-card-readers-not-being-discovered-either-on-bt-or-usb-2024-05-02
        if (isSnackpassEmployee && location(activeStore)) {
            return true;
        }
        return false;
    }, [activeStore, isSnackpassEmployee]);
}
