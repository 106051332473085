import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

export const Page = ({
    children,
    noVerticalMargin,
}: {
    children: JSX.Element | JSX.Element[];
    noVerticalMargin?: boolean;
}) => (
    <Container className={noVerticalMargin ? "my-0" : undefined}>
        {children}
    </Container>
);

const Container = styled.div`
    ${({ theme }) => `
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;
        max-width: 600px;

        margin: ${theme.spacing.base} ${theme.spacing.quadruple};
        
        @media ${ScreenState.TABLET}, ${ScreenState.MOBILE} {
            margin: 0;
            padding: ${theme.spacing.half} ${theme.spacing.base};
            max-width: 100%;
            width: 100%;
        }

        @media ${ScreenState.TABLET} {
            margin-bottom: ${theme.spacing.triple};
        }
        

        & .container-fluid {
            padding: 0;
        }
    `}
`;
