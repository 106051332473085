import { Col, Row, Space } from "antd";
import _ from "lodash";
import { CSSProperties } from "react";
import {
    TransactionSourceTypeEnum,
    Menu as MultiMenu,
} from "@snackpass/snackpass-types";

import {
    getDisplayHours,
    getHoursByDay,
    getTimeLabel,
} from "#menu-editor/multi-menus/helpers";
import { ReactComponent as RightIcon } from "src/assets/icons/chevron-menu-right.svg";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { UnstyledLink } from "#menu-editor/multi-menus/styled-components/unstyled-link";
import { isThirdPartyMenu } from "#menu-editor/multi-menus/screens/multi-menu-settings/utils/platform-helpers";
import { isNotUndefined } from "#core";
import colors from "#reusable/colors/colors.json";

type Props = {
    menu: MultiMenu;
    isMobile: boolean;
};

const DotSpan: React.FC<{ enabled: boolean }> = ({ enabled }) => (
    <div style={styles.dotWrapper}>
        <span style={{ ...styles.dot(enabled) }}></span>
    </div>
);

export const MenuTableRow: React.FC<Props> = ({ menu, isMobile }) => {
    const hours = isNotUndefined(menu.hours)
        ? getHoursByDay(menu.hours.local)
        : {};
    const label = isThirdPartyMenu(menu.platforms)
        ? "N/A"
        : getTimeLabel(hours);
    const displayHours = isThirdPartyMenu(menu.platforms)
        ? ["N/A"]
        : getDisplayHours(hours);
    const itemsLength = menu.categories.flatMap(
        (category) => category.productIds,
    ).length;
    const menuPlatforms = menu.platforms
        .map((platform) => {
            if (platform === TransactionSourceTypeEnum.ThirdParty)
                return "Third Party";

            return _.capitalize(platform.toString());
        })
        .join(", ");

    return isMobile ? (
        <UnstyledLink to={`/multi-menus/${menu.id}`}>
            <Row
                justify="space-between"
                align="middle"
                style={{
                    ...styles.mainRow(menu.enabled),
                    ...styles.mobilePadding,
                }}
            >
                <Col span={22}>
                    <Row>
                        <Text type="body-bold">{menu.name}</Text>
                    </Row>
                    <Row style={styles.textPadding}>
                        <Text color="light-grey" type="body-regular">
                            {`${itemsLength} ${
                                itemsLength === 1 ? "item" : "items"
                            }`}
                        </Text>
                        <DotSpan enabled={menu.enabled} />
                        <Text color="light-grey" type="body-regular">
                            {label}
                        </Text>
                        {displayHours.map((displayHour, indx) => (
                            <>
                                <DotSpan enabled={menu.enabled} />
                                <Text
                                    color="light-grey"
                                    type="body-regular"
                                    key={indx}
                                >
                                    {displayHour}
                                </Text>
                            </>
                        ))}
                    </Row>
                    <Row style={styles.textPadding}>
                        <Text color="light-grey" type="body-regular">
                            {menuPlatforms}
                        </Text>
                    </Row>
                </Col>
                <Col span={2}>
                    <RightIcon fill="#A0ADBA" width={40} height={15} />
                </Col>
            </Row>
        </UnstyledLink>
    ) : (
        <UnstyledLink to={`/multi-menus/${menu.id}`}>
            <Row
                justify="space-between"
                align="middle"
                style={{ ...styles.mainRow(menu.enabled) }}
            >
                <Col span={6}>
                    <Text style={styles.padding} type="body-bold">
                        {menu.name}
                    </Text>
                </Col>
                <Col span={6}>
                    <Row style={styles.padding}>
                        <Space direction="vertical">
                            <Col span={24}>
                                <Text type="body-bold">
                                    {isThirdPartyMenu(menu.platforms)
                                        ? "N/A"
                                        : label}
                                </Text>
                            </Col>
                            <Col span={24}>
                                {!isThirdPartyMenu(menu.platforms) ? (
                                    <Space direction="vertical">
                                        {displayHours.map(
                                            (displayHour, indx) => (
                                                <Text
                                                    type="body-regular"
                                                    key={indx}
                                                >
                                                    {displayHour}
                                                </Text>
                                            ),
                                        )}
                                    </Space>
                                ) : null}
                            </Col>
                        </Space>
                    </Row>
                </Col>
                <Col span={6}>
                    <Text style={styles.padding} type="body-regular">
                        {menuPlatforms}
                    </Text>
                </Col>
                <Col span={6}>
                    <Text style={styles.padding} type="body-regular">
                        {itemsLength}
                    </Text>
                </Col>
            </Row>
        </UnstyledLink>
    );
};

const styles = {
    padding: {
        padding: "28px 16px",
    } as CSSProperties,

    mobilePadding: {
        padding: "16px",
    } as CSSProperties,

    textPadding: {
        padding: "4px 0px",
    } as CSSProperties,

    dot: (active: boolean): CSSProperties => ({
        height: "2px",
        width: "2px",
        backgroundColor: active ? "#606C76" : "#A0ADBA",
        borderRadius: "50%",
    }),

    dotWrapper: {
        display: "grid",
        placeContent: "center",
        padding: "4px",
    } as CSSProperties,

    mainRow: (active: boolean): CSSProperties => ({
        borderBottom: `1px solid ${colors["neutral-400"]}`,
        color: active ? undefined : "#A0ADBA",
    }),
};
