import React, { useContext, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { AccessorKeyColumnDef } from "@tanstack/react-table";

import { getActiveStore } from "src/redux/selectors";
import { getStoreTimezone } from "#utils/helpers";
import {
    formatNumber,
    formatRange,
    toDollarFormatted,
} from "#reports/sales-summary/lib";
import { ReportsContext } from "#app/reports-context-provider";
import api from "src/api/rest";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { formatForTableExport } from "#reports/menu-category-insights/lib";
import { GranularityType } from "#reports/sales-summary/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { MENU_SALES_TOOLTIP_COPY } from "#reports/menu-item-insights/lib";
import { logAndSendError } from "src/utils/errors";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";

import { MenuItem, MenuTableRow } from "../types";

interface TableRow extends MenuItem {
    index: number;
    name: string;
    categoryName: string;
    orders: number;
    sales: number;
}

interface RowMap {
    [key: number]: boolean;
}

const MODIFIER_NET_SALES_TOOLTIP_COPY =
    "Modifier net sales reflect only the modifiers’ sales and will not add up to the item’s total net sales.";

const ItemPerformanceTable = () => {
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { filter, dateRanges, menuInsightsData } = reportsState;
    const activeStore = useSelector(getActiveStore);
    const data = menuInsightsData?.itemInsightsData;
    const modifiersData = menuInsightsData?.itemTableModifierData;

    const [expandedRows, setExpandedRows] = useState<RowMap>({});
    const [loadingModifierRows, setLoadingModifierRows] = useState<RowMap>({});

    useEffect(() => {
        if (!modifiersData) {
            setExpandedRows({});
            setLoadingModifierRows({});
        }
    }, [modifiersData]);

    const fetchItemModifiers = (item: MenuItem) => {
        if (!activeStore?._id || !dateRanges || !data) {
            return;
        }
        const params = {
            storeId: activeStore?._id,
            productId: item.id,

            startDate: dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: dateRanges[0][1].format("YYYY-MM-DD"),

            timezone: getStoreTimezone(activeStore),

            channel: JSON.stringify(filter.channel),
            source: JSON.stringify(filter.source),
            fulfillment: JSON.stringify(filter.fulfillment),
        };

        setReportsState((reportsState) => ({
            ...reportsState,
            menuInsightsData: {
                itemInsightsDataLoading: false,
                itemInsightsData:
                    reportsState.menuInsightsData?.itemInsightsData,
                itemTableModifierData: {
                    ...reportsState?.menuInsightsData?.itemTableModifierData,
                    // flush out the refresh element if it exists
                    [item.id]: undefined,
                },
            },
        }));

        api.reports
            .getMenuItemModifierInsights(params)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    menuInsightsData: {
                        itemInsightsDataLoading:
                            reportsState.menuInsightsData
                                ?.itemInsightsDataLoading,
                        itemInsightsData:
                            reportsState.menuInsightsData?.itemInsightsData,
                        itemTableModifierData: {
                            ...reportsState?.menuInsightsData
                                ?.itemTableModifierData,
                            [item.id]: res.data.items,
                        },
                    },
                }));
            })
            .catch((e) => {
                logAndSendError(e);
                // set to show refresh element on failure
                setReportsState((reportsState) => ({
                    ...reportsState,
                    menuInsightsData: {
                        itemInsightsDataLoading: false,
                        itemInsightsData:
                            reportsState.menuInsightsData?.itemInsightsData,
                        itemTableModifierData: {
                            ...reportsState?.menuInsightsData
                                ?.itemTableModifierData,
                            [item.id]: [
                                {
                                    name: "Refresh Element",
                                    categoryName: "",
                                    orders: 0,
                                    sales: 0,
                                    isRefreshElement: true,
                                },
                            ],
                        },
                    },
                }));
            });
    };

    const toggleExpanded = (index: number | undefined) => {
        if (index == undefined) return;
        setExpandedRows({ ...expandedRows, [index]: !expandedRows[index] });
    };

    const columns = useColumns({ expandedRows });

    const columnsForExport = [
        {
            title: "",
            dataIndex: "index",
        },
        {
            title: "Item",
            dataIndex: "name",
        },
        {
            title: "Category",
            dataIndex: "categoryName",
        },
        {
            title: "Orders",
            dataIndex: "orders",
        },
        {
            title: "Net Sales",
            dataIndex: "sales",
        },
    ];

    const sortedItems = useMemo(() => {
        if (!data) return [];

        return data
            .reduce((prev: MenuItem[], currentRow) => {
                currentRow.items.forEach((item) => {
                    item.modifiers =
                        modifiersData &&
                        modifiersData[item.id]?.filter((e) => e.name);
                    const itemInArray = prev.find((e) => e.id == item.id);
                    if (!itemInArray) {
                        prev.push({ ...item });
                    } else {
                        // aggregate to get totals
                        itemInArray.sales += item.sales;
                        itemInArray.orders += item.orders;
                    }
                });
                return prev;
            }, [])
            .sort((a, b) => b.sales - a.sales);
    }, [data, modifiersData]);

    const addModifierRows = (
        newRows: MenuTableRow[],
        item: MenuItem,
        index: number,
    ) => {
        // The modifiers have not been loaded yet, so render skeleton components and begin a fetch if you haven't yet
        if (!item.modifiers) {
            for (let i = 0; i < 3; i++)
                newRows.push({
                    id: `${item.id}-loading-${i}`,
                    name: <Skeleton className="w-3/4" />,
                    categoryName: <Skeleton className="w-3/4" />,
                    sales: <Skeleton className="w-16" />,
                    orders: <Skeleton className="w-16" />,
                });

            if (!loadingModifierRows[index]) {
                fetchItemModifiers(item);
                setLoadingModifierRows({
                    ...loadingModifierRows,
                    [index]: true,
                });
            }
            return;
        }

        // The modifiers have been fetched, but there are no modifiers on this item.
        if (item.modifiers.length == 0) {
            newRows.push({
                id: `${item.id}-no-modifiers`,
                name: (
                    <span className="italic text-neutral-600">
                        No modifiers
                    </span>
                ),
                categoryName: <></>,
                sales: <></>,
                orders: <></>,
            });
            return;
        }

        // There was an error fetching modifiers, so we render a refresh element in the modifiers
        if (item.modifiers[0].isRefreshElement) {
            newRows.push({
                id: `${item.id}-should-refresh`,
                name: (
                    <>
                        Error fetching modifiers.{" "}
                        <button
                            onClick={() => {
                                fetchItemModifiers(item);
                            }}
                        >
                            Try Again
                        </button>
                    </>
                ),
                categoryName: <></>,
                sales: <></>,
                orders: <></>,
            });
            return;
        }

        // Normal case, render modifiers in the modifier list.
        item.modifiers.forEach((modifier) => {
            newRows.push({
                id: `${item.id}-${modifier.name}`,
                name: modifier.name,
                categoryName: modifier.categoryName || "",
                sales: modifier.sales,
                orders: modifier.orders,
            });
        });
    };

    const rows = useMemo(() => {
        const newRows: TableRow[] = [];
        sortedItems.forEach((item, idx) => {
            newRows.push({ ...item, index: idx + 1 });
            if (expandedRows[idx + 1]) {
                addModifierRows(newRows, item, idx);
            }
        });
        return newRows;
    }, [sortedItems, expandedRows]);

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            placeholder: "Search by Name or Category",
            global: true,
            defaultSearchValue: "",
        },
    };

    return (
        <div className="mb-20 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Item Performance</h4>
                <DownloadButton
                    rows={formatForTableExport(rows)}
                    columns={columnsForExport}
                    filename={`${
                        activeStore?.name
                    } Item Performance ${formatRange(
                        dateRanges[0],
                        GranularityType.DAILY,
                    )}`}
                />
            </div>
            <div>
                {!menuInsightsData?.itemInsightsDataLoading &&
                (menuInsightsData?.itemInsightsData ||
                    menuInsightsData?.itemInsightsDataFailed) ? (
                    menuInsightsData?.itemInsightsData ? (
                        <DataTable
                            columns={columns}
                            data={rows}
                            toolbar={toolbarOptions}
                            getRowClickListener={(row) => () =>
                                toggleExpanded(row.original.index)
                            }
                            customPageSize={99999} // To ensure that all rows show
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

const useColumns = ({ expandedRows }: { expandedRows: RowMap }) =>
    useMemo<AccessorKeyColumnDef<TableRow, string | number>[]>(
        () => [
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        className="my-2 ml-2"
                        column={column}
                        title="#"
                    />
                ),
                accessorKey: "index",
                id: "index",
                cell: ({ row }) => (
                    <div className="ml-2">{row.original.index}</div>
                ),
                enableSorting: false, // Current implementation has issues with subrows moving around
                enablePinning: false, // Disable clicking the header for now
                enableHiding: false,
                enableGlobalFilter: false,
                size: 40,
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        className="my-2 ml-2"
                        column={column}
                        title="Item"
                    />
                ),
                accessorKey: "name",
                cell: ({ row }) => (
                    <Name
                        name={row.original.name}
                        isParent={!!row.original.index}
                        isExpanded={expandedRows[row.original.index]}
                    />
                ),
                id: "itemName",
                enableSorting: false,
                enablePinning: false, // Disable clicking the header for now
                enableHiding: false,
                enableGlobalFilter: true,
                size: 400,
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        className="my-2 ml-2"
                        column={column}
                        title="Category"
                    />
                ),
                accessorKey: "categoryName",
                id: "categoryName",
                cell: ({ row }) => (
                    <div className="ml-2">{row.original.categoryName}</div>
                ),
                enableSorting: false,
                enablePinning: false, // Disable clicking the header for now
                enableHiding: false,
                enableGlobalFilter: true,
                size: 200,
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        className="my-2 ml-2"
                        column={column}
                        title="Orders"
                    />
                ),
                accessorKey: "orders",
                id: "orders",
                cell: ({ row }) => (
                    <div className="ml-2">
                        {isNaN(row.original.orders)
                            ? row.original.orders
                            : formatNumber(row.original.orders)}
                    </div>
                ),
                enableSorting: false, // Current implementation has issues with subrows moving around
                enablePinning: false, // Disable clicking the header for now
                enableHiding: false,
                enableGlobalFilter: false,
                size: 80,
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        className="my-2 ml-2"
                        column={column}
                        title={
                            <>
                                Net Sales
                                <ReportsTooltip
                                    body={MENU_SALES_TOOLTIP_COPY}
                                    className="mx-3"
                                />
                            </>
                        }
                    />
                ),
                accessorKey: "sales",
                id: "sales",
                cell: ({ row }) => (
                    <div className="ml-2">
                        <NetSales
                            row={row.original}
                            expandedRows={expandedRows}
                        />
                    </div>
                ),
                enableSorting: false, // Current implementation has issues with subrows moving around
                enablePinning: false, // Disable clicking the header for now
                enableHiding: false,
                enableGlobalFilter: false,
                size: 120,
            },
        ],
        [expandedRows],
    );

const Name = ({
    name,
    isExpanded,
    isParent,
}: {
    name: string;
    isExpanded: boolean;
    isParent: boolean;
}) => (
    <div>
        {isParent && (
            <span className="mx-2 bg-transparent shadow-none">
                {isExpanded ? (
                    <i className="fas fa-chevron-down" />
                ) : (
                    <i className="fas fa-chevron-right" />
                )}
            </span>
        )}
        {name}
    </div>
);

const NetSales = ({
    row,
    expandedRows,
}: {
    row: TableRow;
    expandedRows: RowMap;
}) => (
    <div className="flex items-center">
        {isNaN(row.sales) ? row.sales : toDollarFormatted(row.sales)}
        {row.index && expandedRows[row.index] ? (
            <ReportsTooltip body={MODIFIER_NET_SALES_TOOLTIP_COPY} />
        ) : (
            // spacer
            <div className="mx-1 w-3"></div>
        )}
    </div>
);

export default ItemPerformanceTable;
