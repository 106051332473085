import { Canvas } from "fabric";

export function withDisableObjectControls(canvas: Canvas) {
    const created = canvas.on("selection:created", () => {
        canvas.getActiveObject()?.setControlsVisibility({ mtr: false });
        canvas.getActiveObject()?.set({
            lockSkewingX: true,
            lockSkewingY: true
        });
    });

    const updated = canvas.on("selection:updated", () => {
        canvas.getActiveObject()?.setControlsVisibility({ mtr: false });
        canvas.getActiveObject()?.set({
            lockSkewingX: true,
            lockSkewingY: true
        });
    });

    return () => {
        created();
        updated();
    };
}
