import React, { useContext, useState } from "react";
import { match } from "ts-pattern";
import { toast } from "sonner";

import api from "src/api/rest";
import { EsperScreenRotationOption } from "src/api/rest/esper/types";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { RotationState } from "#devices/utils/deviceTypes/DeviceStats";

const rotationOptionFrom = (
    rotationState: RotationState,
): EsperScreenRotationOption =>
    match(rotationState)
        .with(RotationState.AUTO, () => 0)
        .with(RotationState.PORTRAIT_ONLY, () => 1)
        .with(RotationState.LANDSCAPE_ONLY, () => 2)
        .with(RotationState.INVERTED_PORTRAIT_ONLY, () => 3)
        .with(RotationState.INVERTED_LANDSCAPE_ONLY, () => 4)
        .exhaustive() as EsperScreenRotationOption;

const rotationStateFrom = (rotationOption: number) =>
    match(rotationOption)
        .with(1, () => RotationState.PORTRAIT_ONLY)
        .with(2, () => RotationState.LANDSCAPE_ONLY)
        .with(3, () => RotationState.INVERTED_PORTRAIT_ONLY)
        .with(4, () => RotationState.INVERTED_LANDSCAPE_ONLY)
        .otherwise(() => RotationState.AUTO);

export const EditDeviceRotation = () => {
    const { device } = useContext(DevicesPageContext);

    const [screenRotation, setScreenRotation] = useState(
        // XXX: DO NOT USE `??` here, `rotationState` can be an empty string and is being set incorrectly on server.
        rotationOptionFrom(device?.stats?.rotationState || RotationState.AUTO),
    );

    const rotateScreen = async () => {
        if (device) {
            let rotateTo: EsperScreenRotationOption;

            // see notes for EsperScreenRotationOption for more information,
            // but basically we are looping through options 1-4 here
            if (screenRotation >= 4) {
                rotateTo = 1;
            } else {
                rotateTo = (screenRotation + 1) as EsperScreenRotationOption;
            }

            return api.storeDevices
                .setRotationState(
                    device.mdmDeviceId,
                    rotationStateFrom(rotateTo),
                )
                .then(() => {
                    setScreenRotation(rotateTo);
                    toast.success(
                        "Rotation command sent, please wait up to 1 minute for it to take effect.",
                    );
                })
                .catch(() => {
                    toast.error(
                        "Rotate command failed to send to device, please try again later",
                    );
                });
        }
    };

    return (
        <Button
            variant="outline"
            className="w-full text-base"
            onClick={rotateScreen}
        >
            Rotate Device
        </Button>
    );
};
