import { match } from "ts-pattern";

import { ChannelStatus } from "#settings-integrations/modules/deliverect";

export const ChannelStatusIndicator = ({ status }: { status: number }) => {
    const [label, statusColor] = match(status)
        .with(ChannelStatus.INACTIVE, () => ["Inactive", "bg-red-500"])
        .with(ChannelStatus.SUSPENDED, () => ["Suspended", "bg-red-500"])
        .with(ChannelStatus.TESTING, () => ["Testing", "bg-gray-500"])
        .with(ChannelStatus.SUBSCRIBED, () => ["Active", "bg-active-light"])
        .with(ChannelStatus.ONBOARDING, () => ["Onboarding", "bg-yellow-400"])
        .otherwise(() => ["Inactive", "bg-red-500"]);

    return (
        <div>
            <div
                className={`mr-2 mt-[5px] inline-block h-2 w-2 flex-none grow-0 rounded ${statusColor}`}
            >
                {" "}
            </div>
            <span className="py-[6px] text-body font-normal text-neutral-500">
                {label}
            </span>
        </div>
    );
};

export const Divider = () => (
    <div className="mx-0 my-3 w-full border-b border-solid border-neutral-400" />
);

export const DividerNoTopMargin = () => (
    <div className="mb-3 w-full border-b border-solid border-neutral-400" />
);

export const RequiredLabel = () => (
    <div className="ml-2 rounded border bg-yellow-light px-2 py-1 text-micro font-semibold text-neutral-50">
        Required
    </div>
);

export const RecommendedLabel = () => (
    <div className=" ml-2 rounded bg-neutral-600 px-2 py-1 text-micro font-semibold text-neutral-300">
        Recommended
    </div>
);

export const AutofilledLabel = () => (
    <div className=" ml-2 rounded bg-yellow-light px-2 py-1 text-micro font-semibold text-neutral-50">
        Autofilled
    </div>
);

export const OptionalLabel = () => (
    <div className="ml-2 flex flex-row justify-center rounded-lg border bg-neutral-300 px-2 py-1 text-micro font-semibold text-neutral-950">
        Optional
    </div>
);
