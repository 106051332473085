import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from "src/assets/icons/left-arrow.svg";
import colors from "#reusable/colors/colors.json";

export const BackButton = () => {
    const history = useHistory();
    return (
        <Container onClick={() => history.goBack()}>
            <BackIcon width={16} height={16} />
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: calc(50% - 20px);
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 11px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${colors["neutral-400"]};

    &:hover {
        cursor: pointer;
    }
`;
