"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    ReloadIcon,
    CopyIcon,
    ExclamationTriangleIcon,
    Cross2Icon,
    ImageIcon,
    Link2Icon,
} from "@radix-ui/react-icons";
import { useGate } from "statsig-react";
import { generateTextOverlayVideoUrl } from "node_modules/@snackpass/images/dist";
import { IStore } from "@snackpass/snackpass-types";
import { TwitterPicker } from "react-color";
import { CopyToClipboard } from "react-copy-to-clipboard";
import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

import useWindowDimensions from "#hooks/use-window-dimensions";
import { submitStoreUpdate } from "#settings/settings-brand-and-appearance/lib";
import { Button } from "src/@/components/ui/button";
import { Alert, AlertDescription } from "src/@/components/ui/alert";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormMessage,
} from "src/@/components/ui/form";
import { FormCard } from "src/@/components/form-card";
import config from "#config";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import { sendError } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setActiveStore, getActiveStoreId } from "src/redux/slices";
import { Input } from "src/@/components/ui/input";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "src/@/components/ui/avatar";
import { Textarea } from "src/@/components/ui/textarea";
import colors from "#reusable/colors/colors.json";
import constants from "#core/constants";
import { useCloudinaryUploadWidget } from "#settings/settings-brand-and-appearance/upload-widget";
import { optimizedImageURL } from "src/utils/image";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { getActiveStore, getUserTeamPermission } from "src/redux/selectors";

const brandSchema = z.object({
    logoUrl: z.string(),
    coverPhotoUrl: z.string(),
    emoji: z.string(),
    brandColor: z.string(),
    storeSlug: z.string(),
    customerPickupScreenMarketingUrl: z.string(),
    brandSplashUrl: z.string(),
    kioskSplashVideoUrl: z.string(),
    loyaltyEmoji: z.string(),
    loyaltyCustomName: z.string(),
    loyaltyCardBackgroundImage: z.string(),
    hasBrandSplashOverlayText: z.boolean(),
    hideBrandingImagesOnOO: z.boolean(),
    useCorporateMap: z.boolean(),
    mapIcon: z.string(),
    aboutUs: z.string(),
});

const MB20 = 20971520; // 20MB
const MB30 = 31457280; // 30MB
const MB50 = 52428800; // 50MB

const VIDEO_FORMATS = ["mp4", "mov"];
const PRESET_COLORS = [
    // Row 1
    colors["red-medium"],
    colors["orange-medium"],
    colors["yellow-medium"],
    colors["brown-medium"],
    colors["green-medium"],
    colors["light-blue-medium"],
    colors["blue-medium"],
    colors["purple-medium"],
    "#9748ff",
    "#f0339e",
    // Row 2
    "#ff3d1f",
    "#FF7733",
    "#ffc933",
    "#a15a2b",
    "#47d75f",
    "#66ccff",
    "#3377ff",
    "#6647ff",
    "#ae70ff",
    "#ed5eaf",
];

const Video = ({ value }: { value: string }) => (
    <div className="relative flex h-52 w-full items-center justify-center overflow-hidden rounded-2xl bg-neutral-200 bg-cover bg-center bg-no-repeat">
        <video
            className="absolute left-0 top-0 h-full w-full object-cover"
            controls
            muted
            loop
            playsInline
            src={value}
        ></video>
    </div>
);

const Image = ({ value }: { value: string }) => (
    <div
        className="flex h-52 w-full items-center justify-center rounded-2xl bg-neutral-200 bg-cover bg-center bg-no-repeat"
        style={{
            backgroundImage: `url("${optimizedImageURL(value)}")`,
        }}
    >
        {!value ? <ImageIcon className="h-8 w-8 fill-neutral-600" /> : null}
    </div>
);

export const SettingsBrandAndAppearanceScreen = () => {
    const dispatch = useAppDispatch();
    const canEdit = useHasEditSettingsForActiveStore();
    const [isLoading, setIsLoading] = useState(false);
    const activeStoreId = useSelector(getActiveStoreId);
    const isSPEmployee = useSelector(getUserTeamPermission);
    const activeStore = useAppSelector(getActiveStore);
    const { isMobile } = useWindowDimensions();

    const updateStore = useCallback(
        (store: IStore) => {
            dispatch(setActiveStore(store));
        },
        [dispatch],
    );

    const defaultValues = useMemo(
        () => ({
            logoUrl: activeStore?.logoUrl ?? "",
            coverPhotoUrl: activeStore?.thumbnailUrl ?? "",
            emoji: activeStore?.emoji ?? "",
            brandColor: activeStore?.brandColor ?? "",
            storeSlug: activeStore?.slug ?? "",
            customerPickupScreenMarketingUrl:
                activeStore?.brandCustomerPickupScreenMarketingUrl ?? "",
            brandSplashUrl: activeStore?.brandSplashUrl ?? "",
            kioskSplashVideoUrl:
                activeStore?.kioskPreferences.attractScreenUrl ?? "",
            loyaltyEmoji: activeStore?.loyaltyEmoji ?? "",
            loyaltyCustomName: activeStore?.loyaltyCustomName ?? "",
            loyaltyCardBackgroundImage:
                activeStore?.loyaltyCardBackgroundImage ?? "",
            hasBrandSplashOverlayText: !!activeStore?.hasBrandSplashOverlayText,
            hideBrandingImagesOnOO: !!activeStore?.hideBrandingImagesOnOO,
            useCorporateMap: !!activeStore?.useCorporateMap,
            mapIcon: activeStore?.mapIcon ?? "",
            aboutUs: activeStore?.aboutUs ?? "",
        }),
        [activeStore],
    );

    const allowDuoSplashScreen = useGate(
        config.featureGates.duoSplashScreen,
    ).value;

    const form = useForm<z.infer<typeof brandSchema>>({
        resolver: zodResolver(brandSchema),
        defaultValues: {
            logoUrl: "",
            coverPhotoUrl: "",
            emoji: "🍕",
            brandColor: SystemColors.v1.snackpassPrint,
            storeSlug: "",
            customerPickupScreenMarketingUrl: "",
            brandSplashUrl: "",
            kioskSplashVideoUrl: "",
            loyaltyEmoji: "",
            loyaltyCustomName: "",
            loyaltyCardBackgroundImage: "",
            hasBrandSplashOverlayText: false,
            hideBrandingImagesOnOO: false,
            useCorporateMap: false,
            mapIcon: "",
            aboutUs: "",
        },
    });

    const onSubmit = async (values: z.infer<typeof brandSchema>) => {
        if (!activeStore) {
            return;
        }

        if (!canEdit) {
            toast.error("You do not have permission to edit this store");
            return;
        }
        try {
            setIsLoading(true);
            //@ts-ignore API reponse isn't strongly typed
            const { data } = await submitStoreUpdate(activeStore, values);
            updateStore(data.store);
            toast.success("Store updated successfully");
        } catch (e) {
            sendError(e);
            toast.error("Can not update the store, please try again later");
        } finally {
            setIsLoading(false);
        }
    };
    const resetForm = useCallback(() => {
        form.reset(defaultValues);
    }, [form, defaultValues]);

    // Update form default values when activeStore is loaded
    useEffect(() => {
        if (activeStore) {
            resetForm();
        }
    }, [activeStore, resetForm]);

    // Keeping these upload buttons here because they must be inside react component

    const { UploadButton } = useCloudinaryUploadWidget({
        preset: "images-store",
        folder: "store-images",
        clientAllowedFormats: ["jpg", "png"],
        maxImageFileSize: MB20,
    });

    const { UploadButton: UploadVideoButton } = useCloudinaryUploadWidget({
        preset: "rdb-kiosk-attract-screen",
        folder: "rdb-assets",
        clientAllowedFormats: ["mp4", "mov"],
        maxVideoFileSize: MB50,
    });

    const { UploadButton: UploadMediaButton } = useCloudinaryUploadWidget({
        preset: "rdb-duo-register-splash-screen",
        folder: "rdb-assets",
        clientAllowedFormats: ["jpg", "png", "mp4", "mov"],
        maxImageFileSize: MB30,
    });

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick,
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Branding
                        </CardTitle>
                        <div className="text-gray-600">
                            Customize the look and feel of your brand.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader className="pb-4">
                            <CardTitle className="text-large">
                                Brand Color
                            </CardTitle>
                            <CardDescription>
                                This color is used for buttons on your kiosk and
                                your punchcard in the Snackpass App.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="brandColor"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <div
                                                className="m-auto flex h-12 w-12 items-center justify-center rounded-full text-white"
                                                style={{
                                                    backgroundColor:
                                                        field.value,
                                                }}
                                            >
                                                Aa
                                            </div>
                                        </div>
                                        <FormControl>
                                            <div className="pt-2">
                                                <StyledTwitterPicker
                                                    className="m-auto"
                                                    width={
                                                        isMobile
                                                            ? "150px"
                                                            : "384px"
                                                    }
                                                    triangle={"hide"}
                                                    color={field.value}
                                                    onChange={(
                                                        color: {
                                                            hex: string;
                                                        }, // There is more info for rgb and hsl, etc. but we just pull hex out
                                                    ) => {
                                                        if (canEdit) {
                                                            field.onChange(
                                                                color.hex,
                                                            );
                                                        }
                                                    }}
                                                    colors={PRESET_COLORS}
                                                />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Logo</CardTitle>
                            <CardDescription>
                                Help customers recognize your brand in the app,
                                kiosk, and online ordering.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="logoUrl"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <div className="flex flex-row">
                                                <Avatar className="m-auto h-24 w-24">
                                                    <AvatarImage
                                                        src={value}
                                                        alt="Logo URL"
                                                    />
                                                    <AvatarFallback>
                                                        <ImageIcon className="h-8 w-8 fill-neutral-600" />
                                                    </AvatarFallback>
                                                </Avatar>

                                                <div className="flex w-full items-center gap-2">
                                                    <div className="flex items-center justify-center pl-6 [&_.cloudinary-button]:whitespace-nowrap [&_.cloudinary-button]:text-small [&_.cloudinary-button]:font-semibold [&_.cloudinary-button]:text-neutral-950">
                                                        <UploadButton
                                                            // @ts-ignore className exists for stylings
                                                            className="items-center"
                                                            type="button"
                                                            onUpload={(info) =>
                                                                onChange(
                                                                    info.secure_url,
                                                                )
                                                            }
                                                            cropping
                                                            disabled={!canEdit}
                                                        >
                                                            {value
                                                                ? "Change Image"
                                                                : "Upload Image"}
                                                        </UploadButton>
                                                    </div>
                                                    {value ? (
                                                        <>
                                                            <Button
                                                                type="button"
                                                                className="cursor-pointer"
                                                                variant="outline"
                                                                onClick={() =>
                                                                    onChange("")
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            >
                                                                <Cross2Icon className="h-4 w-4" />
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Bio</CardTitle>
                            <CardDescription>
                                Describe your store to help customers learn
                                about your unique offerings and atmosphere.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="aboutUs"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Textarea
                                                className="resize-none outline-none [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden"
                                                value={field.value}
                                                onChange={field.onChange}
                                                rows={3}
                                                maxLength={140}
                                                disabled={!canEdit}
                                            />
                                        </FormControl>
                                        <FormDescription className="text-xs">
                                            Characters: {field.value.length}/140
                                        </FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Emoji</CardTitle>
                            <CardDescription>
                                Choose an emoji that best represents your
                                cuisine.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="emoji"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <div className="flex">
                                                <Avatar className="h-24 w-24">
                                                    <AvatarFallback className="text-6xl">
                                                        {value}
                                                    </AvatarFallback>
                                                </Avatar>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <div className="flex items-center justify-center pl-6">
                                                            <Button
                                                                className="items-center"
                                                                variant={
                                                                    "outline"
                                                                }
                                                                type="button"
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            >
                                                                Change Emoji
                                                            </Button>
                                                        </div>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="h-full w-full">
                                                        <div className="overflow-hidden">
                                                            <EmojiPicker
                                                                data={data}
                                                                onEmojiSelect={(emoji: {
                                                                    native: string;
                                                                }) => {
                                                                    if (
                                                                        canEdit
                                                                    ) {
                                                                        onChange(
                                                                            emoji.native,
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </PopoverContent>
                                                </Popover>
                                            </div>
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <div className="max-w-4xl border-neutral-400">
                        <FormCard
                            title={"Cover Photo"}
                            subtitle={
                                "Appears at the top of your app and online ordering page."
                            }
                            footer={"Recommended ratio of 3:1 width to height"}
                        >
                            <FormField
                                control={form.control}
                                name="coverPhotoUrl"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <div className="relative flex max-w-sm flex-col items-center">
                                                <div
                                                    className="relative w-full"
                                                    style={{
                                                        paddingTop: "33.33%",
                                                    }}
                                                >
                                                    <div
                                                        className="absolute inset-0 flex items-center justify-center rounded-lg border-2 border-gray-300 bg-cover bg-center bg-no-repeat"
                                                        style={{
                                                            backgroundImage: `url("${optimizedImageURL(value)}")`,
                                                        }}
                                                    >
                                                        {!value && (
                                                            <ImageIcon className="h-8 w-8 fill-neutral-600" />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex w-full items-center justify-between gap-2 pt-4">
                                                    <div className="flex flex-1 flex-col space-y-2 [&_.cloudinary-button]:whitespace-nowrap [&_.cloudinary-button]:text-small [&_.cloudinary-button]:font-semibold [&_.cloudinary-button]:text-neutral-950">
                                                        <UploadButton
                                                            disabled={!canEdit}
                                                            onUpload={(info) =>
                                                                onChange(
                                                                    info.secure_url,
                                                                )
                                                            }
                                                            cropping
                                                        >
                                                            {value
                                                                ? "Change Image"
                                                                : "Upload Image"}
                                                        </UploadButton>
                                                    </div>
                                                    {value ? (
                                                        <>
                                                            {/* Using asChild here to avoid the buttons submitting the form. */}
                                                            <CopyToClipboard
                                                                text={value}
                                                                onCopy={() => {
                                                                    toast.success(
                                                                        "Copied image URL to clipboard.",
                                                                    );
                                                                }}
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    className="cursor-pointer"
                                                                    variant="outline"
                                                                >
                                                                    <Link2Icon />
                                                                </Button>
                                                            </CopyToClipboard>
                                                            <Button
                                                                type="button"
                                                                className="cursor-pointer"
                                                                variant="outline"
                                                                onClick={() =>
                                                                    onChange("")
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            >
                                                                <Cross2Icon />
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </FormCard>
                    </div>

                    <div className="max-w-4xl border-neutral-400">
                        <FormCard
                            title={"Digital Receipt Image"}
                            subtitle={
                                "Add a custom background for your virtual loyalty card that appears on receipts and your custom branded app."
                            }
                            footer={
                                "Recommended ratio of 5:3 width to height. "
                            }
                        >
                            <FormField
                                control={form.control}
                                name="loyaltyCardBackgroundImage"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <div className="relative flex max-w-sm flex-col items-center">
                                                <div
                                                    className="flex h-52 w-full items-center justify-center rounded-2xl bg-neutral-200 bg-cover bg-center bg-no-repeat"
                                                    style={{
                                                        backgroundImage: `url("${optimizedImageURL(value)}")`,
                                                    }}
                                                >
                                                    {!value ? (
                                                        <ImageIcon className="h-8 w-8 fill-neutral-600" />
                                                    ) : null}
                                                </div>
                                                <div className="flex w-full items-center justify-between gap-2 pt-4">
                                                    <div className="flex flex-1 flex-col space-y-2 [&_.cloudinary-button]:whitespace-nowrap [&_.cloudinary-button]:text-small [&_.cloudinary-button]:font-semibold [&_.cloudinary-button]:text-neutral-950">
                                                        <UploadButton
                                                            onUpload={(info) =>
                                                                onChange(
                                                                    info.secure_url,
                                                                )
                                                            }
                                                            disabled={!canEdit}
                                                            cropping
                                                        >
                                                            {value
                                                                ? "Change Image"
                                                                : "Upload Image"}
                                                        </UploadButton>
                                                    </div>
                                                    {value ? (
                                                        <>
                                                            {/* Using asChild here to avoid the buttons submitting the form. */}
                                                            <CopyToClipboard
                                                                text={value}
                                                                onCopy={() => {
                                                                    toast.success(
                                                                        "Copied image URL to clipboard.",
                                                                    );
                                                                }}
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    className="cursor-pointer"
                                                                    variant="outline"
                                                                >
                                                                    <Link2Icon />
                                                                </Button>
                                                            </CopyToClipboard>
                                                            <Button
                                                                type="button"
                                                                className="cursor-pointer"
                                                                variant="outline"
                                                                onClick={() =>
                                                                    onChange("")
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            >
                                                                <Cross2Icon />
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </FormCard>
                    </div>

                    <div className="max-w-4xl border-neutral-400">
                        <FormCard
                            title={"Kiosk Splash Screen"}
                            subtitle={"Play a custom video when Kiosk is idle."}
                            footer={"MP4 Video, 50mb or less. "}
                        >
                            <FormField
                                control={form.control}
                                name="kioskSplashVideoUrl"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <div className="relative flex max-w-sm flex-col items-center">
                                                <div className="relative flex h-52 w-full items-center justify-center overflow-hidden rounded-2xl bg-neutral-200 bg-cover bg-center bg-no-repeat">
                                                    <video
                                                        className="absolute left-0 top-0 h-full w-full object-cover"
                                                        controls
                                                        muted
                                                        loop
                                                        playsInline
                                                        src={
                                                            value
                                                                ? generateTextOverlayVideoUrl(
                                                                      value,
                                                                      form.watch(
                                                                          "hasBrandSplashOverlayText",
                                                                      ),
                                                                  )
                                                                : constants.DEFAULT_ATTRACT_SCREEN_URL
                                                        }
                                                    ></video>
                                                </div>
                                                <div className="flex w-full items-center justify-between gap-2 pt-4">
                                                    <div className="flex flex-1 flex-col space-y-2 [&_.cloudinary-button]:whitespace-nowrap [&_.cloudinary-button]:text-small [&_.cloudinary-button]:font-semibold [&_.cloudinary-button]:text-neutral-950">
                                                        <UploadVideoButton
                                                            disabled={!canEdit}
                                                            onUpload={(info) =>
                                                                onChange(
                                                                    info.secure_url,
                                                                )
                                                            }
                                                            cropping
                                                        >
                                                            {value
                                                                ? "Change Video"
                                                                : "Upload Video"}
                                                        </UploadVideoButton>
                                                    </div>
                                                    {value ? (
                                                        <>
                                                            {/* Using asChild here to avoid the buttons submitting the form. */}
                                                            <CopyToClipboard
                                                                text={value}
                                                                onCopy={() => {
                                                                    toast.success(
                                                                        "Copied image URL to clipboard.",
                                                                    );
                                                                }}
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    className="cursor-pointer"
                                                                    variant="outline"
                                                                >
                                                                    <Link2Icon />
                                                                </Button>
                                                            </CopyToClipboard>
                                                            <Button
                                                                type="button"
                                                                className="cursor-pointer"
                                                                variant="outline"
                                                                onClick={() =>
                                                                    onChange("")
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            >
                                                                <Cross2Icon />
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </FormControl>
                                        <CardTitle className="pt-2 text-base">
                                            Recommended Format:
                                        </CardTitle>
                                        <CardDescription>
                                            • Vertical and horizontal displays:
                                            1920x1920 resolution. <br />•
                                            Horizontal only: 1920x1080 <br />•
                                            Vertical only: 1080x1920
                                        </CardDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="hasBrandSplashOverlayText"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Overlay Text
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Add “Tap to Order” text
                                                    overlay to the Kiosk Splash
                                                    Screen.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </FormCard>
                    </div>

                    {allowDuoSplashScreen && (
                        <div className="max-w-4xl border-neutral-400">
                            <FormCard
                                title={"Duo Register Splash Screen"}
                                subtitle={
                                    "Add an image or video for your Duo Register."
                                }
                                footer={"1920x1080, 30mb or less."}
                            >
                                <FormField
                                    control={form.control}
                                    name="brandSplashUrl"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <FormItem>
                                            <FormControl>
                                                <div className="relative flex max-w-sm flex-col items-center">
                                                    {VIDEO_FORMATS.some(
                                                        (format) =>
                                                            value?.includes(
                                                                format,
                                                            ),
                                                    ) ? (
                                                        <Video value={value} />
                                                    ) : (
                                                        <Image value={value} />
                                                    )}
                                                    <div className="flex w-full items-center justify-between gap-2 pt-4">
                                                        <div className="flex flex-1 flex-col space-y-2 [&_.cloudinary-button]:whitespace-nowrap [&_.cloudinary-button]:text-small [&_.cloudinary-button]:font-semibold [&_.cloudinary-button]:text-neutral-950">
                                                            <UploadMediaButton
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                                onUpload={(
                                                                    info,
                                                                ) =>
                                                                    onChange(
                                                                        info.secure_url,
                                                                    )
                                                                }
                                                                cropping
                                                            >
                                                                {value
                                                                    ? "Change Media"
                                                                    : "Upload Media"}
                                                            </UploadMediaButton>
                                                        </div>
                                                        {value ? (
                                                            <>
                                                                {/* Using asChild here to avoid the buttons submitting the form. */}
                                                                <CopyToClipboard
                                                                    text={value}
                                                                    onCopy={() => {
                                                                        toast.success(
                                                                            "Copied image URL to clipboard.",
                                                                        );
                                                                    }}
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        className="cursor-pointer"
                                                                        variant="outline"
                                                                    >
                                                                        <Link2Icon />
                                                                    </Button>
                                                                </CopyToClipboard>
                                                                <Button
                                                                    type="button"
                                                                    className="cursor-pointer"
                                                                    variant="outline"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            "",
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                >
                                                                    <Cross2Icon />
                                                                </Button>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormDescription />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </FormCard>
                        </div>
                    )}

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Username
                            </CardTitle>
                            <CardDescription>
                                Your store’s username is used in the URL for
                                your online ordering and custom website.
                            </CardDescription>
                            <Alert variant="warning">
                                <AlertDescription className="text-sm">
                                    <ExclamationTriangleIcon className="mr-2" />
                                    If you change the username, please update
                                    anywhere you have posted your online
                                    ordering or website links.
                                </AlertDescription>
                            </Alert>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="storeSlug"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Username
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Only alphanumeric symbols
                                                allowed.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="py-4">
                                                    <Input
                                                        placeholder="Username"
                                                        maxLength={275}
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <CardTitle className="text-base font-medium">
                                                Custom Website
                                            </CardTitle>
                                            <div className="flex py-4">
                                                <Input
                                                    placeholder="Website"
                                                    value={`https://snack.site/${field.value}`}
                                                    disabled
                                                />
                                                <CopyToClipboard
                                                    text={`https://snack.site/${field.value}`}
                                                    // @ts-ignore className exists for stylings
                                                    className="ml-2"
                                                    onCopy={() => {
                                                        toast.success("Copied");
                                                    }}
                                                >
                                                    <Button
                                                        type="button"
                                                        variant={"outline"}
                                                    >
                                                        <CopyIcon className="h-4 w-4" />
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="pt-2">
                                            <CardTitle className="text-base font-medium">
                                                Custom Website Permanent Link
                                            </CardTitle>
                                            <div className="flex py-4">
                                                <Input
                                                    placeholder="Website"
                                                    value={`https://snack.site/$${activeStoreId}`}
                                                    disabled
                                                />
                                                <CopyToClipboard
                                                    text={`https://snack.site/$${activeStoreId}`}
                                                    // @ts-ignore className exists for stylings
                                                    className="ml-2"
                                                    onCopy={() => {
                                                        toast.success("Copied");
                                                    }}
                                                >
                                                    <Button
                                                        type="button"
                                                        variant={"outline"}
                                                    >
                                                        <CopyIcon className="h-4 w-4" />
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="pt-2">
                                            <CardTitle className="text-base font-medium">
                                                Online Ordering Link
                                            </CardTitle>
                                            <div className="flex py-4">
                                                <Input
                                                    placeholder="Website"
                                                    value={`https://order.snackpass.co/${field.value}`}
                                                    disabled
                                                />
                                                <CopyToClipboard
                                                    text={`https://order.snackpass.co/${field.value}`}
                                                    // @ts-ignore className exists for stylings
                                                    className="ml-2"
                                                    onCopy={() => {
                                                        toast.success("Copied");
                                                    }}
                                                >
                                                    <Button
                                                        type="button"
                                                        variant={"outline"}
                                                    >
                                                        <CopyIcon className="h-4 w-4" />
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <CardTitle className="text-base font-medium">
                                                Online Ordering Permanent Link
                                            </CardTitle>
                                            <div className="flex py-4">
                                                <Input
                                                    placeholder="Website"
                                                    value={`https://order.snackpass.co/$${activeStoreId}`}
                                                    disabled
                                                />
                                                <CopyToClipboard
                                                    text={`https://order.snackpass.co/$${activeStoreId}`}
                                                    // @ts-ignore className exists for stylings
                                                    className="ml-2"
                                                    onCopy={() => {
                                                        toast.success("Copied");
                                                    }}
                                                >
                                                    <Button
                                                        type="button"
                                                        variant={"outline"}
                                                    >
                                                        <CopyIcon className="h-4 w-4" />
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>

                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Corporate Settings
                            </CardTitle>
                            <CardDescription>
                                Manage your corporate map and icon settings.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="useCorporateMap"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Corporate Map
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Use Corporate Map for Online
                                                    Ordering.
                                                    {!isSPEmployee ? (
                                                        <ReportsTooltip
                                                            body={
                                                                "You must be a Snackpass Employee to toggle this setting on."
                                                            }
                                                        />
                                                    ) : null}
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            !canEdit ||
                                                            !isSPEmployee
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="mapIcon"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Map Icon
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Map icon that displays on online
                                                ordering.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="flex w-52 flex-col space-y-4 pt-4">
                                                    <Avatar className="m-auto h-24 w-24">
                                                        <AvatarImage
                                                            src={value}
                                                            alt="Logo URL"
                                                        />
                                                        <AvatarFallback>
                                                            <ImageIcon className="h-8 w-8 fill-neutral-600" />
                                                        </AvatarFallback>
                                                    </Avatar>

                                                    <div className="flex w-full items-center justify-between gap-2">
                                                        <div className="flex flex-1 flex-col space-y-2 [&_.cloudinary-button]:whitespace-nowrap [&_.cloudinary-button]:text-small [&_.cloudinary-button]:font-semibold [&_.cloudinary-button]:text-neutral-950">
                                                            <UploadButton
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                                onUpload={(
                                                                    info,
                                                                ) =>
                                                                    onChange(
                                                                        info.secure_url,
                                                                    )
                                                                }
                                                                cropping
                                                            >
                                                                {value
                                                                    ? "Change Image"
                                                                    : "Upload Image"}
                                                            </UploadButton>
                                                        </div>
                                                        {value ? (
                                                            <>
                                                                {/* Using asChild here to avoid the buttons submitting the form. */}
                                                                <CopyToClipboard
                                                                    text={value}
                                                                    onCopy={() => {
                                                                        toast.success(
                                                                            "Copied image URL to clipboard.",
                                                                        );
                                                                    }}
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        className="cursor-pointer"
                                                                        variant="outline"
                                                                    >
                                                                        <Link2Icon />
                                                                    </Button>
                                                                </CopyToClipboard>
                                                                <Button
                                                                    type="button"
                                                                    className="cursor-pointer"
                                                                    variant="outline"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            "",
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                >
                                                                    <Cross2Icon />
                                                                </Button>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormDescription />
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};

const StyledTwitterPicker = styled(TwitterPicker)`
    div > input {
        padding: 1px;
    }
`;

export default SettingsBrandAndAppearanceScreen;
