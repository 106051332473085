import clsx from "clsx";
import React from "react";

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";
import { ReactComponent as CheckIcon } from "src/assets/icons/checked.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/Info.svg";
import { useHasEditAdminForActiveStore } from "#hooks/use-has-edit-admin-for-active-store";

type Props = {
    title: string;
    onClick: () => void;
    isChecked: boolean | undefined;
    indented?: boolean;
    disabled?: boolean;
    tooltip?: string | JSX.Element;
};

// This checkbox is specific to admin permissions viewing/editing as it will be disabled if the user doesn't have editing permission
const CheckBox = ({
    title,
    onClick,
    isChecked = false,
    indented,
    disabled,
    tooltip,
}: Props) => {
    const canEdit = useHasEditAdminForActiveStore();

    const tooltipContent = tooltip ? (
        <TooltipProvider delayDuration={100}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <InfoIcon
                        className={clsx("mx-2 mt-1.5 h-3 w-3 cursor-pointer")}
                    />
                </TooltipTrigger>

                <TooltipContent
                    className={clsx(
                        "z-50 w-96 whitespace-normal rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50",
                    )}
                    sideOffset={5}
                >
                    {tooltip}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    ) : null;

    return (
        <div className="my-2 flex items-center justify-between border-b border-neutral-400 pb-4 pt-2">
            <div className="flex items-start justify-center">
                <div className={clsx(indented && "ml-6")}>{title}</div>
                {tooltipContent}
            </div>
            <div
                className={clsx(
                    "flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 p-1",
                    isChecked
                        ? disabled
                            ? "bg-neutral-500"
                            : "bg-success-light"
                        : "bg-white",
                )}
                onClick={() => {
                    if (canEdit) onClick();
                }}
            >
                {isChecked ? (
                    <CheckIcon fill="#FFFFFF" width={12} height={9} />
                ) : null}
            </div>
        </div>
    );
};

export default CheckBox;
